import AWS, {DynamoDB} from 'aws-sdk';
import {async} from 'q';

import jwt from 'jsonwebtoken';

import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import App from './App';

import Axios from 'axios';
import axiosRetry from 'axios-retry';
import { default as _ } from 'lodash';
import { PromiseResult } from 'aws-sdk/lib/request';

// if ((window.location.pathname !== '/login' && window.location.pathname !== '/user/register' && window.location.pathname !== '/user/resetPassword')) {
//   const poolData = {
//     UserPoolId: App.USER_POOL_ID,
//     ClientId: App.COGNITO_CLIENT_ID,
//   };
//   const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
//   const cognitoUser = userPool.getCurrentUser();

//   if (!cognitoUser) {
//     window.location.href = '/login';
//   }
// }


type ConstructorType = "S3" | "DDB" | "LAMBDA" | undefined;

let session: AmazonCognitoIdentity.CognitoUserSession | null | undefined;
let cognitoUser: AmazonCognitoIdentity.CognitoUser | null | undefined;

let initialized = false;

class AWSModuleInternal {
    S3?: AWS.S3;
    Ddb?: AWS.DynamoDB.DocumentClient;
    Lambda?: AWS.Lambda;
    id?: string;

    constructor() {
        const relogin = async () => {
            // console.log("RELOGIN!!");

            if (cognitoUser) {
                // cognitoUser.getSession(async (err: any, session: any) => {
                // if (session.getIdToken().getExpiration() < (new Date().valueOf() / 1000 - 600)) {

                try {
                    cognitoUser!.refreshSession(session!.getRefreshToken(), (e, r) => {
                        session = r;
                    });
                    // }
    
                    // @ts-ignore
                    // if (!AWS.config.credentials || AWS.config.credentials.expired) {
                    AWS.config.region = 'ap-northeast-2';
                    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                        IdentityPoolId: App.IDENTITY_POOL_ID,
                        Logins: {
                            [`cognito-idp.ap-northeast-2.amazonaws.com/${App.USER_POOL_ID}`]: session!.getIdToken().getJwtToken()!,
                        }
                    });
    
                    await new Promise((resolve, reject) => {
                        // @ts-ignore
                        AWS.config.credentials.refresh(async (error) => {
                            if (error) {
                                reject(error);
                            } else {
                                resolve();
                            }
                        });
                    });
                    // }
    
                    this.Ddb = new AWS.DynamoDB.DocumentClient();
                    this.S3 = new AWS.S3();
                    await new Promise(resolve => {
                        this.S3!.getSignedUrl('getObject', {
                            Bucket: "test",
                            Key: "test",
                        }, resolve);
                    });
                    this.Lambda = new AWS.Lambda();
    
                    this.id = jwt.decode(session!.getIdToken().getJwtToken()!)!.sub;
                    App.jwt = session!.getIdToken().getJwtToken();
    
                    App.API_Axios = Axios.create({
                        baseURL: App.API_URL,
                        headers: {
                            'Authorization': `Bearer ${App.jwt}`,
                        },
                    });
    
                    axiosRetry(App.API_Axios, {
                        retries: 2,
                        retryDelay: axiosRetry.exponentialDelay, 
                        retryCondition: (e) => {
                            if (e.response!.status.toString().startsWith('5')) {
                                return true;
                            }
    
                            return false;
                        }
                    });
                } catch (e) {
                    window.location.reload(false);
                }

                
            }
        }

        const refresh = async () => {
            while (!App) {
                await new Promise((resolve) => setTimeout(resolve, 100));
            }

            const noSessionPaths = [
                '/login',
                '/user/register',
                '/user/resetPassword',
                '/user/unconfirmed',
                '/sample',
                '/sampleVisualizer',
                '/landing',
                '/termofservice',
            ]

            if (noSessionPaths.findIndex(path => path === window.location.pathname) > -1) {
                return;
            }

            try {
                if (!session) {
                    const poolData = {
                        UserPoolId: App.USER_POOL_ID,
                        ClientId: App.COGNITO_CLIENT_ID,
                    };
    
                    const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
                    cognitoUser = userPool.getCurrentUser();
    
                    if (!cognitoUser) {
                        if (window.location.pathname !== '/designerdownload') {
                            window.location.href = '/landing';
                        }
                        return;
                    }
    
                    session = cognitoUser.getSignInUserSession();
    
                    if (!session) {
                        await new Promise((resolve, reject) => {
                            cognitoUser!.getSession(resolve);
                        });
    
                        session = cognitoUser.getSignInUserSession();
                    }
    
                    if (!session) {
                        if (window.location.pathname !== '/designerdownload') {
                            window.location.href = '/landing';
                        }
                        return;
                    }
                    if (!initialized) {
                        initialized = true;
                        await relogin();
                    } else {
                        this.id = jwt.decode(session!.getIdToken().getJwtToken()!)!.sub;
                    }
                }
            } catch (e) {
                window.location.reload(false);
            }

            
        };

        refresh();

        setInterval(relogin, 1500000);
    }

    connect = async () => {
        while (true) {

            if (this.id) {
                break;
            }

            await new Promise(resolve => setTimeout(resolve, 100));
        }

        return this;
    }

}

export default class AWSModule {
  S3?: AWS.S3;
  Ddb?: AWS.DynamoDB.DocumentClient;
  Lambda?: AWS.Lambda;
  id?: string;

  constructor(type?: ConstructorType) {}

  connect = async () => {
    const m = await module.connect();

    this.S3 = m.S3;
    this.Ddb = m.Ddb;
    this.Lambda = m.Lambda;
    this.id = m.id;

    return this;
  };

  lambda = async (fncName: string, table: string, query: object, from: number = 0, size: number = 0) => {
    if (this.Lambda) {
      const r = await this.Lambda.invoke({
        FunctionName: fncName,
        Payload: JSON.stringify({
          table: table,
          query: query,
          from: from,
          size: size,
        }),
      }).promise();

      return JSON.parse(r.Payload as string);
    }
  };

  batchGet = async (RequestItems: DynamoDB.DocumentClient.BatchGetItemInput) => {
    const MAX_REQUEST_ITEMS_LENGTH = 100; // batch get limit
    let requestItemsWrapper: Array<any> = [];
    let result: any = undefined;

    const tableName = Object.keys(RequestItems.RequestItems)[0];
    
    let keys = RequestItems.RequestItems[tableName].Keys;
    let len = keys.length;
    
    for (let i = 0; i < len; i += MAX_REQUEST_ITEMS_LENGTH) {
      let requestItems = _.cloneDeep(RequestItems);
      
      requestItems.RequestItems[tableName].Keys = keys.slice(i, i + MAX_REQUEST_ITEMS_LENGTH);
      requestItemsWrapper.push(requestItems);
    }
    

    this.Ddb = new AWS.DynamoDB.DocumentClient();
    for (let i = 0; i < requestItemsWrapper.length; i++) {
      let ri = _.cloneDeep(requestItemsWrapper[i]);
      let existUk = 0;

      do {
        const r = await this.Ddb.batchGet(ri).promise();
        // @ts-ignore
        existUk = Object.keys(r.UnprocessedKeys).length;
        if (existUk) {
          // @ts-ignore
          ri = { RequestItems: r.UnprocessedKeys };
        }

        if (result === undefined) {
          result = _.cloneDeep(r);
        } else {
          if (result.Responses !== undefined && r.Responses !== undefined) {
            const tableName = Object.keys(r.Responses)[0];
            r.Responses[tableName].map((rr) => result.Responses![tableName].push(rr));
            result.UnprocessedKeys = {};
          }
        }
      } while (existUk);
    }

    return result;
  };

  dbatchGet = async (table: string, stage: string, key: string, valueList: string[], slice: number = 10, fieldList?: string[]) => {
    if (this.Ddb) {
      const len = valueList.length;
      let r = [] as any[];

      for (let i = 0; i < len; i += slice) {
        const r = await this.Ddb.batchGet({
          RequestItems: {
            table: {
              ConsistentRead: true,
              Keys: valueList.map((val: string) => ({
                key: val,
              })),
              AttributesToGet: fieldList && fieldList.map((f) => f),
            },
          },
        }).promise();
      }
    }
  };

  Reload = async () => {
    initialized = false;
    module = new AWSModuleInternal();
  };

  getSession = () => {
    return session!.getRefreshToken();
  };
}

export interface BatchGetRequestItems {

}

let module = new AWSModuleInternal();
