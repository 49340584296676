

import React, { Component } from 'react';
import Tree from 'rc-tree';
import '../css/CADConverter/Rc-tree.scss';
import '../css/CADConverter/BlockTreeList.scss';
import { BuildingPart, ConverterBuilding } from './BuildingPart';
import { DataNode, EventDataNode, Key, IconType } from '../../node_modules/rc-tree/lib/interface';
import { setBlockOpacity } from './CoreAndHouseController';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ExpandMore, ChevronRight, SpeakerNotes } from '@material-ui/icons';
import { Polygon } from './DataTypes';
import {ReactComponent as BlockIcon} from '../img/icon/blockIcon.svg';
import { Button } from '@material-ui/core';
import Tooltip from '../Tooltip';
import { ErrorList } from './Error';

interface BlockTreeListProps {
  parsingOutput: any,
  components: Array<BuildingCoreUnit | BuildingHouseUnit | ConverterBuilding | BuildingPart >,
  houses: Array<BuildingHouseUnit>,
  cores: Array<BuildingCoreUnit>,
  clickComponent: (buildingPart: BuildingPart | ConverterBuilding) => void,
  clickBuildingPart: any,
  currentLog: string,
  errorList: ErrorList,
 handleCurLogById: (id: string) => void,
  handleCurLog: Function,
  // handleCurrentLog: () => void,
}

const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  box-sizing: border-box;
  transition: all .3s;
  overflow-y: hidden;
  overflow-x: hidden;
}
`;

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: () => ({ height: 0 }),
  onAppearActive: (node: any) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: any) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 }),
};

interface BlockTreeListState{
  switcherOnOff: boolean; // icon fold unfold
  icons: any; //{key: string, icon: IconType}[];
}
class BlockTreeList extends Component<BlockTreeListProps> {
  treeRef: React.RefObject<any> = React.createRef(); //LegacyRef<Tree> | undefined = React.createRef();

  state: BlockTreeListState = {
    switcherOnOff: true,
    icons: new Map(),
  };

  componentWillMount() {
    this.props.parsingOutput.buildings.forEach((b: any) => {
      this.state.icons.set(b.uuid, false);
      b.parts.forEach((p: any)=>{
        this.state.icons.set(p.uuid, false);
        p.parts.forEach((pp: any)=>{
          this.state.icons.set(pp.uuid, false);
        })
      })
    })      
  }


  setBlockNameCnt(block: any, hash: Map<any, any>) {
    
    let name = block.name;
    let title:React.ReactNode;
    let value = hash.get(name);
    
    if (value !== undefined && value.isMultiple) { // 이름이 중복
      value.order = value.order + 1;
      hash.set(name, value);
      title = 
        <div className="title">
          <div className="wrap-name">
            <span className="name" onClick={e => {
            }
            }>{name}</span>
            <div className="block-number">{value.order}</div>
          </div>
          <Tooltip msg="오류 정보 확인" arrowOn={false} place={"right"}>
          <Button
            id={block.uuid}
            className={`btn bg-navy btn-primary icon-wrap showBlockError
             ${this.props.errorList.getErrorById(block.uuid).length === 0 ? "disabled" : ""}
              ${this.props.currentLog === block.uuid ? "active" : ""}`}
            onClick={(e) => {
              //  e.stopPropagation();
              if (this.props.currentLog === block.uuid) {
                //TODO props 수정
                this.props.handleCurLog();
              }
              else {
                //TODO props 수정
                this.props.handleCurLogById(block.uuid);
              }
            }}
          >
            <SpeakerNotes className={`icon speaker-icon ${this.props.errorList.getErrorById(block.uuid).length === 0 ? "disabled" : ""} `} />
          </Button>
          </Tooltip>

        </div>;
      // title = <div className="title">
      //   <div className="wrap-name">

      //   <BlockIcon />
      //   <span className="name" onClick={e => {
      //     // this.treeRef.current.setExpandedKeys(this.treeRef.current.state.selectedKeys[0]);
      //     // this.treeRef.current && console.log(this.treeRef.current.state.selectedKeys);
      //     // this.treeRef.current.state.selectedKeys && this.treeRef.current.setExpandedKeys([this.treeRef.current.state.selectedKeys]);
      //   }
      //   }>{name}</span>

      //   <div className="block-number">{value.order}</div>
      //   </div>

      //   <Button
      //     id={block.uuid}
      //     className={`btn bg-navy btn-primary icon-wrap showBlockError`}
      //   // ${this.errorList.getErrorById(block.uuid).length === 0 ? "disabled" : ""}
      //   // ${this.state.currentLog === block.uuid ? "active" : ""}
      //   // `}
      //   // onClick={(e) => {
      //   //   //  e.stopPropagation();
      //   //   if (this.state.currentLog === f.uuid) {
      //   //     this.setState({
      //   //       errorLogs: this.errorList.getError(),
      //   //       currentLog: "ALL"
      //   //     })
      //   //   }
      //   //   else {
      //   //     this.setState({
      //   //       errorLogs: this.errorList.getErrorById(f.uuid),
      //   //       currentLog: f.uuid
      //   //     })
      //   //   }
      //   // }}
      //   >
      //     <SpeakerNotes className={`icon speaker-icon `} />
      //     {/* //${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}`} /> */}
      //   </Button>

      // </div>;
      return title;
    }
    else {
      return<>
      {name}          
      <Tooltip msg="오류 정보 확인" arrowOn={false} place={"right"}>
      <Button
        id={block.uuid}
        className={`btn bg-navy btn-primary icon-wrap showBlockError`}
        onClick={(e) => {
          //  e.stopPropagation();
          if (this.props.currentLog === block.uuid) {
            //TODO props 수정
            this.props.handleCurLog();
          }
          else {
            //TODO props 수정
            this.props.handleCurLogById(block.uuid);
          }
        }}
      >
        <SpeakerNotes className={`icon speaker-icon `} />
      </Button>
      </Tooltip>
</>;
    }
  }

  getTreeData = () => {
    let blockNameHash = new Map();

    let list = [...this.props.parsingOutput.buildings, ...this.props.components,];
    list.forEach(building => {
      if (blockNameHash.get(building.name) !== undefined) {
        blockNameHash.set(building.name, { cnt: blockNameHash.get(building.name).cnt + 1, isMultiple: true, order: 0 });
      }
      else {
        blockNameHash.set(building.name, { cnt: 1, isMultiple: false, order: 0 });
      }
    })

    //@ts-ignore
    let buildings = this.props.parsingOutput.buildings;
    let result: DataNode[] = [];
    buildings.forEach((building: ConverterBuilding) => {
      
      let blockD: DataNode = {
        key: building.uuid,
        title: this.setBlockNameCnt(building, blockNameHash),
        children: [],
        switcherIcon: <>{!this.state.icons.get(building.uuid) ? <ExpandMore className="icon"/> : <ChevronRight className="icon"/>}<BlockIcon className="icon blockIcon"/></>,
        className: "blockD",
      }
      building.parts.forEach((part: BuildingPart) => {
        let blockB: DataNode = {
          key: part.uuid,
          title: this.setBlockNameCnt(part, blockNameHash),
          children: [],
          switcherIcon: <>{!this.state.icons.get(part.uuid) ? <ExpandMore className="icon"/> : <ChevronRight className="icon"/>}<BlockIcon className="icon blockIcon"/></>,
          className: "blockB",

        }
        part.parts.forEach((component) => {
          let componentNode: DataNode = {
            key: component.uuid,
            title: this.setBlockNameCnt(component, blockNameHash),
            children: [],
            switcherIcon: <>{!this.state.icons.get(component.uuid) ? <ExpandMore className="icon"/> : <ChevronRight className="icon"/>}<BlockIcon className="icon blockIcon"/></>,
            className: "component"
          }
          //@ts-ignore
          if (component.componentType === "house") {
            //@ts-ignore
            let win1 = component.polygon.filter((polygon: Polygon) => polygon.layer.toUpperCase() === "WIN1");
            //@ts-ignore
            let win2 = component.polygon.filter((polygon: Polygon) => polygon.layer.toUpperCase() === "WIN2");

            let windowNode1: DataNode;
            let windowNode2: DataNode;
            
            if (win1.length > 0) {
              windowNode1 = {
                key: `${component.uuid}+${win1[0].lineMesh.uuid}`,
                title: "WIN1",
                children: [],
                switcherIcon: '-',
                isLeaf: true,
                className: "window",
                selectable: false,
              }
              //@ts-ignore
              componentNode.children.push(windowNode1);
            }
            if (win2.length > 0) {
              windowNode2 = {
                key: `${component.uuid}+${win2[0].lineMesh.uuid}`,
                title: "WIN2",
                children: [],
                switcherIcon: '-',
                isLeaf: true,
                className: "window",
                selectable: false,
              }
              //@ts-ignore
              componentNode.children.push(windowNode2);
            }
          }
          //@ts-ignore
          blockB.children.push(componentNode);
        })
        //@ts-ignore
        blockD.children.push(blockB);
      })
      result.push(blockD);
    })
    
    return result;
  }

  onSelectNode(node: EventDataNode) {
    let find = this.props.components.filter(block => (block.uuid === node.key));

    if (find.length > 0) {
      this.props.clickComponent(find[0]);      
      return;
    }
    this.props.parsingOutput.buildings.forEach((building: ConverterBuilding) => {
      if (building.uuid === node.key) {
        this.props.clickComponent(building);
        return;
      }
      building.parts.forEach(part => {
        if (part.uuid === node.key) {
          this.props.clickComponent(part);
          return;
        }
      })
    })
  }

  onExpand(key: Key[], info: any) {  
    this.state.icons.set(info.node.key, !this.state.icons.get(info.node.key));
    this.setState({ switcherOnOff: !this.state.switcherOnOff });
  }

  findUUIDBlock(uuid: string) {
    let result: any = undefined;
    for (let i = 0; i < this.props.parsingOutput.buildings.length; i++) {
      checkUUID(this.props.parsingOutput.buildings[i]);
      if (result) return result;
    }

    function checkUUID(block: any) {
      if (block.uuid === uuid) {
        result = block;
        return;
      }
      else {
        if (block.parts) {
          for (let i = 0; i < block.parts.length; i++) {
            checkUUID(block.parts[i]);
            if (result) break;
          }
        } 
      }
    }
  }

  render() {   
    return (
      <div className="animation BlockTreeList">
        <style dangerouslySetInnerHTML={{ __html: STYLE }} />
        <div style={{ display: 'flex' }}>
          <div style={{ flex: '1 1 50%' }}>
            <Tree
              ref={this.treeRef}
              defaultExpandAll={true}
              autoExpandParent={true}
              defaultExpandParent={true}
              // defaultExpandedKeys={defaultExpandedKeys}
              motion={motion}
              onMouseEnter={({ event, node }) => {
                //@ts-ignore
                let uuid = node.key.split('+')[0];
                let findBlock = this.findUUIDBlock(uuid);
                setBlockOpacity(this.props.parsingOutput.buildings, 0.2);
                if (node.className === "window") setBlockOpacity([], 1, findBlock.polygon.filter((p: any) => p.layer === node.title));
                else setBlockOpacity([findBlock], 1);
              }}
              onMouseLeave={({ event, node }) => {
                setBlockOpacity(this.props.parsingOutput.buildings, 1);
              }}
              treeData={this.getTreeData()}
              onSelect={(key, info) => {
                this.onSelectNode(info.node);
              }}
              onExpand={(key, info) => this.onExpand(key, info)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default BlockTreeList;