import { Button, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { NaverLatLng, NaverMapManager } from './NaverMapManager';
import { FieldType } from './Field';
import { jstsPolygontoWKT, tm2latlng } from './SceneManager';
import { BlockParsingData } from './BuildingPart';
import * as THREE from '@teneleven/three';
import { checkFileName, checkSpecialSymbolInName, getRoadLine} from './DBManager';
import { Link } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';
import '../css/CADConverter/MySiteBlockSaveModal.scss';
import { ConverterType } from './DataTypes';
import { SaveViewerModal } from './SaveViewerModal';
import { buttonNum } from './Modal';
import _, { debounce } from 'lodash';
import * as jsts from 'jsts';
import { wkt2LatLngs } from './CoreAndHouseController';
import App from '../App';
import wkx, { Geometry } from 'wkx';
import DrawingManager2 from '../DrawingManager2';
import { ExpandMore, Info } from '@material-ui/icons'; 
import Tooltip from '../Tooltip';
import {ReactComponent as LevelIcon} from '../img/icon/levelIcon.svg';
import { TextField } from '@material-ui/core';

type SiteAreaType = { // 사업영역
  siteArea: string,
  // roadArea: string,
  // vacancyOutsideArea: string,
  // vacancyInsideArea: string,
  // elevationCnt: number,
  
  address: string,
  elevationMin: string,
  elevationMax: string,
}

type TypeAreaType = { // 동평면
  totalHouseholds: number,
  totalExclusiveArea: string,
  totalCommonWallArea: string,
  totalCoreArea: string,
  totalBuildingArea: string,
}

type PlanAreaType = { // 배치안
  siteArea: string,
  buildingCnt: number,
  totalBuildingArea: string,
  totalGroundArea: string,
  exclusiveAverageArea: string,
}

interface MySiteBlockSaveModalProps {
  converterType: ConverterType,
  showModal: boolean,
  siteArea?: SiteAreaType,
  typeArea?: TypeAreaType,
  planArea?: PlanAreaType,
  fileName: string,
  isSaved: boolean,
  parsingOutput: BlockParsingData,
  userId: string,
  DBTableName: string,
  centerOfRoad?: (value: string) => void,
  onShowSaveModal: () => void,
  onSave: () => Promise<boolean>,
  onChangeFileName: (fileName: string) => void,
  buildingObject?: () => THREE.Group,
  showModalAlert?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void;
  recalculateArea?: () => void; 
}

interface MySiteBlockSaveModalState {
  msg: string,
  loadingPage: boolean,
  saveNameCheckMsg: string,
  isValueChanged: {
    site: boolean,
    elevationMax: boolean,
    elevationMin: boolean,
//    topography: boolean,
  },
}

class MySiteBlockSaveModal extends Component<MySiteBlockSaveModalProps, MySiteBlockSaveModalState> {
  state: MySiteBlockSaveModalState = {
    msg: "사업영역을 저장하시겠습니까?",
    loadingPage: false,
    saveNameCheckMsg: "",
    isValueChanged: {
      site: false,
      elevationMax: false,
      elevationMin: false,
  
 //     topography: false,
    },
  }
  mapManager = new NaverMapManager();
  link = "";
  centerOfRoadWKT = '';
  //DBTableName = 'platform-buildit-my-site-v2';

  componentDidMount() {
    this.checkSaveState = _.debounce(this.checkSaveState, 500);

    switch(this.props.converterType) {
      case ConverterType.myType:
        this.link = "/myPage/file/myType";
        break;
      case ConverterType.mySite:
        this.link = "/myPage/file/mySite";
        break;
      default: // 배치안
        this.link = "/myPage/file/myPlan";
        break;
    }

    // this.mount!.appendChild(this.sceneManager.canvasElement);
    if (this.props.converterType !== ConverterType.myType) {
      this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);

      this.mapManager.naverMap.Event.addListener(this.mapManager.getMap(), 'zoom_changed', () => {
        const markers = this.mapManager.getTopologyMarker();
        
        const topoFields = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.topography);
     
        markers.forEach((marker, i) => {
          let zoom = this.mapManager.getZoom();
          let fontSize = 15;
          if (zoom >= 18) fontSize = 20;
          else if (zoom === 17) fontSize = 14;
          else fontSize = 10;
          
          let inputHeight = topoFields[i].defaultInput;
 
          let content = inputHeight !== topoFields[i].getHeight() ?
          `<div style="color: #95E4B3; font-size: ${fontSize}px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
            <img class="level-icon" src="/img/icon/level.png" style=""/><span>${topoFields[i].getHeight()}</span>
          </div>`
            :
            `<div style="color: white; font-size: ${fontSize}px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
            <img class="level-icon" src="/img/icon/level.png"/><span>${topoFields[i].getHeight()}</span>
          </div>`;

          // let content = 
          // `<div style="color: white; font-size: ${fontSize}px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
          //   <img class="level-icon" src="/img/icon/level.png"/><span>${topoFields[i].getHeight()}</span>
          // </div>`;

          let icon = {
            content: content,
          }

          marker.setIcon(icon);
        })

      });
    }
    else {
    }
  }

  async componentDidUpdate(prevProps: MySiteBlockSaveModalProps) {
    if (this.props.showModal && prevProps.showModal !== this.props.showModal) { // open
      await this.checkSaveState(false);
      this.addPolygonOnMap();      
      this.checkSaveState = _.debounce(this.checkSaveState, 300);
    }
    else if (!this.props.showModal && prevProps.showModal !== this.props.showModal) { // close
      this.checkSaveState = _.debounce(this.checkSaveState, 0);
    }
    if (this.props.isSaved && prevProps.isSaved !== this.props.isSaved) {
      this.setState({ loadingPage: false });
    }
  }

  
  addPolygonOnMap = async () => {
    this.setState({
      isValueChanged: {
        site: false,
        elevationMax: false,
        elevationMin: false,
      },
    });
    if (this.props.parsingOutput.fields.length > 0) {
      let sitePolygon = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      //this.props.parsingOutput.fields[0].getUnionJstsPolygon();
      // this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();//
      let unionPolygon = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();//this.props.parsingOutput.fields[0].getUnionJstsPolygon();
      let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];
      
      let originalElevationMin = 100000000000;
      let originalElevationMax = 0;
  
      this.props.parsingOutput.fields.forEach(f => {
        
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0); // ! 폴리곤 두개로 쪼개짐
          let cPoint = sitePolygon.getCentroid();
          center.set(cPoint.getX(), cPoint.getY());
          // @ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
          
          if (f.defaultInput !== f.getArea()) {
            this.setState({ isValueChanged: { ...this.state.isValueChanged, site: true }});
          }
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {  
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
      //    this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
        }
        else if (f.typeName === FieldType.topography) {
          topographyLatLngs = topographyLatLngs.concat(f.getLatLngList());
          if (originalElevationMax < f.defaultInput) originalElevationMax = f.defaultInput;
          if (originalElevationMin > f.defaultInput) originalElevationMin = f.defaultInput;

          // if (f.defaultInput !== f.getHeight()) {
          //   this.setState({ isValueChanged: { ...this.state.isValueChanged, site: true }});
          // }
      }
    })

      if (this.props.siteArea) {
        if (originalElevationMax !== Number(this.props.siteArea.elevationMax.split('m')[0])) {
          this.setState({ isValueChanged: { ...this.state.isValueChanged, elevationMax: true } });
        }
        if (originalElevationMin !== Number(this.props.siteArea.elevationMin.split('m')[0])) {
          this.setState({ isValueChanged: { ...this.state.isValueChanged, elevationMin: true } });
        }
      }

      this.mapManager.setNaverSitePolygon(SiteLatlngs);
      this.mapManager.setNaverRoadPolygon(RoadLatlngs);
      this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
      this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
      this.mapManager.setNaverTopographyPolygon(topographyLatLngs, this.props.parsingOutput.fields.filter(f=>f.typeName === FieldType.topography));

      // if (centerRoadlatlngs)
      //   this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);

      
      let centerInLL = tm2latlng(center);
      this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);
      this.props.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon()) {
          //@ts-ignore
          unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
        }
      })
      
      if (centerRoadlatlngs.length > 0) {
        this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
      }
      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          // //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })
          this.mapManager.setCenterofRoadPolygon(newC);
        }
        catch (e) {
          App.stage !== "prod" &&  console.log('저장에러', e)
        }
      }
      //!
      // 폴리곤이 지도밖으로 나가면 지도 축소
      let minLatlng = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER };
      let maxLatlng = { x: Number.MIN_SAFE_INTEGER, y: Number.MIN_SAFE_INTEGER };

      let fieldsLatLngs = [SiteLatlngs, RoadLatlngs, centerRoadlatlngs, outsideLatLngs, insideLatLngs];
      fieldsLatLngs.forEach(field => {
        field.forEach(latLngs => {
          latLngs.forEach((latLng: number[]) => {
            if (latLng[0] < minLatlng.x) {
              minLatlng.x = latLng[0];
            }
            if (latLng[1] < minLatlng.y) {
              minLatlng.y = latLng[1];
            }
            if (latLng[0] > maxLatlng.x) {
              maxLatlng.x = latLng[0];
            }
            if (latLng[1] > maxLatlng.y) {
              maxLatlng.y = latLng[1];
            }
          })
        })
      })

      let bounds = this.mapManager.getMap().bounds;
      let zoom = this.mapManager.getZoom();

      while (maxLatlng.y > bounds._max._lat || minLatlng.y < bounds._min._lat || maxLatlng.x > bounds._max._lng || minLatlng.x < bounds._min._lng) {
        this.mapManager.setZoom(--zoom);
        bounds = this.mapManager.getMap().bounds;
        if (zoom === 15) break;
      }



  
      if (this.props.recalculateArea)
        this.props.recalculateArea();
    }
    this.props.centerOfRoad && this.props.centerOfRoad(this.centerOfRoadWKT);
  }

  checkSaveState = async (isSave?: boolean) => {
    let fn = this.props.fileName === '' ? 'my_site' : this.props.fileName;
    if (checkSpecialSymbolInName(fn)) {
      this.setState({
        saveNameCheckMsg: `파일 이름에는 다음 문자를 사용할 수 없습니다. ₩/:*?“<>|`,
      });
    }
    else {
      let isSaveAvailable = await checkFileName(fn, this.props.userId, this.props.DBTableName);
      if (isSaveAvailable && isSave) {
        this.setState({ loadingPage: true });
        if (!await this.props.onSave()) {
          this.setState({ loadingPage: false });
        }
      }
      else if (isSaveAvailable) {
        this.setState({
          saveNameCheckMsg: "",
        });
      }
      else {
        this.setState({
          saveNameCheckMsg: "중복되는 이름의 파일이 있습니다. 다른 이름으로 저장해주세요.",
        });
      }
    }
  }

  // addTopographyMarker = () => {
  //   const topoFields = this.props.parsingOutput.fields.filter(f => f.typeName === FieldType.topography);

  //   let topographyLatLngs: any[] = [];
  //   topoFields.forEach(f => {
  //     topographyLatLngs = topographyLatLngs.concat(f.getLatLngList());
  //   });

  //   topographyLatLngs.forEach((latlng: any, i: number) => {
  //     let content = 
  //     `<div style="color: white; font-size: 14px; font-weight: bold; display: flex; align-items: center; justify-content: center;">
  //       <img class="place-icon" src="/img/icon/level.png"/><span>${topoFields[i].getHeight()}</span>
  //     </div>`;

  //     const markerOptions = {
  //       draggable: false,
  //       icon: {
  //         content
  //       }
  //     };

  //     let geoFac = new jsts.geom.GeometryFactory();
  //     let coords: jsts.geom.Coordinate[] = [];
  //     latlng.forEach((coord: any) => {
  //       coords.push(new jsts.geom.Coordinate(coord[0], coord[1]));
  //     })

  //     let linearRing = geoFac.createLinearRing(coords);
  //     let polygon = geoFac.createPolygon(linearRing, [])
  //     let center = polygon.getCentroid();
  //     let centerCoord = { x: center.getX(), y: center.getY() };

  //     this.mapManager.addMarker([centerCoord.x, centerCoord.y], {
  //       ...markerOptions,
  //       icon: {
  //         content: markerOptions.icon.content,
  //         anchor: { x: 20, y: 7 }
  //       }
  //     }, false);
  //   })
  // }

  render() {
    let saveInfo = <div></div>;
    if (this.props.converterType === ConverterType.mySite) {
      saveInfo = (
        <>
          <div className="m-t-sm border"></div>
          <div className="m-t-md input-summary">
            <span className="m-r-md font font-secondary font-14px text">입력 개요</span> 
            <li className={`edited ${(this.state.isValueChanged.site || this.state.isValueChanged.elevationMax || this.state.isValueChanged.elevationMin) ? "display" : "display-none"}`}><span>수정된 값</span></li>
          </div>
          <div className="m-t-sm key-value-wrapper">
          <div className="key-value width-250px">
              <div className="key">주소</div>
              <div className='value'>{this.props.siteArea!.address}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지영역 면적</div>
              <div className={`value ${this.state.isValueChanged.site ? "font-green" : ""}`}>{this.props.siteArea!.siteArea!}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지 레벨 최저 높이</div>
              <div className={`value ${this.state.isValueChanged.elevationMin ? "font-green" : ""}`}>{this.props.siteArea!.elevationMin}</div>
            </div>
            <div className="key-value">
              <div className="key"> 대지 레벨 최고 높이</div>
              <div className={`value ${this.state.isValueChanged.elevationMax ? "font-green" : ""}`}>{this.props.siteArea!.elevationMax!}</div>
            </div>
          </div>
        </>
      )
    }
    else if (this.props.converterType === ConverterType.myType) {
      saveInfo = (
        <>
        <div className="border"></div>
        <div className="title-wrapper">
          <span className="title">입력 개요</span>
          {/* <span className="more">더보기<ExpandMore/></span> */}
        </div>
        <div className="key-value-wrapper">
          <div className="key-value">
            <div className="key">총 세대수</div>
            <div className="value">{this.props.typeArea!.totalHouseholds}세대</div>
          </div>
          <div className="key-value">
            <div className="key">총 전용 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalExclusiveArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">총 벽체공용 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalCommonWallArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">총 코어 면적<Info className="icon info-icon"/></div>
            <div className="value">{this.props.typeArea!.totalCoreArea}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">건축 면적</div>
            <div className="value">{this.props.typeArea!.totalBuildingArea}㎡</div>
          </div>
        </div>
        {/* <div className="border"></div>
        <div className="title-wrapper">
          <span className="title">선택 사항</span>
        </div>
        <div className="key-value-wrapper">
          <div className="key-value">
            <div className="key">베이 수</div>
            <div className="wrap-value">
            <Button className="btn btn-primary bg-navy">2</Button>
            <Button className="btn btn-primary bg-navy">3</Button>
            <Button className="btn btn-primary bg-navy">4</Button>
            <Button className="btn btn-primary bg-navy">4.5</Button>
            <Button className="btn btn-primary bg-navy">5~</Button>
            </div>
            
          </div>
          <div className="key-value">
            <div className="key">동평면 유형</div>
            <div className="value"><TextField>선택</TextField></div>
          </div>

        </div> */}
        </>
      );
    }
    else { // 배치안
      saveInfo = (
        <div className="key-value-wrapper">
          <div className="key-value">
            <div className="key"> 대지영역 면적</div>
            <div className="value">{this.props.planArea!.siteArea}</div>
          </div>
          <div className="key-value">
            <div className="key">동타입 수</div>
            <div className="value">{this.props.planArea!.buildingCnt}</div>
          </div>
          <div className="key-value">
            <div className="key">건축면적</div>
            <div className="value">{this.props.planArea!.totalBuildingArea}</div>
          </div>
          <div className="key-value">
            <div className="key">바닥면적</div>
            <div className="value">{this.props.planArea!.totalGroundArea}</div>
          </div>
          <div className="key-value">
            <div className="key">평균전용 면적</div>
            <div className="value">{this.props.planArea!.exclusiveAverageArea}</div>
          </div>
        </div>
      )
    }


    return (
      <div className={`MySiteBlockSaveModal ${this.props.showModal ? "" : 'hidden'}`}>
        {/* <div className="loading-wrap">
        <LoadingPage 
      //  show={true}
          show={this.state.loadingPage}//props.showLoadingPage}//loadingPage} 
          loadingMsg={`나의 ${this.props.converterType}을 저장 중 입니다.`} 
          isModal={true}
        />
        </div> */}
        {/* {
          this.state.loadingPage ? 
          <div className={`modal-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
          <div className={`header long ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
            <div className="top-bar">
              <div className="title">{`나의 ${this.props.converterType} 저장`}</div>
              <IconButton className="icon-button" onClick={() => {
                this.props.onShowSaveModal();
              }}><Close className={`close-icon ${this.state.loadingPage ? "hidden" : ""}`}></Close></IconButton>
            </div>
           
        </div>
        </div>
          
          :  */}
          <div className={`modal-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
          {
            this.state.loadingPage && 
                    <LoadingPage 
                        show={this.state.loadingPage}//props.showLoadingPage}//loadingPage} 
                        loadingMsg={`나의 ${this.props.converterType}을 저장 중 입니다.`} 
                        isModal={true}
                      />
          }
          <div className={`header long ${this.props.converterType === ConverterType.myType ? "myType" : ""}`}>
            <div className="top-bar">
              <div className="title">{`나의 ${this.props.converterType} 저장`}</div>
              <IconButton className="icon-button" onClick={() => {
                this.props.onShowSaveModal();
              }}><Close className={`close-icon ${this.state.loadingPage ? "hidden" : ""}`}></Close></IconButton>
            </div>
            <div className={`msg font font-14px ${this.props.isSaved ? "font-special" : ""}`}>{!this.props.isSaved ? `${this.props.converterType}을 저장하시겠습니까?` : 
              `${this.props.converterType}이 저장되었습니다. 저장된 ${this.props.converterType}은 '파일 관리' 메뉴에서 확인 가능합니다.`}
            </div>
            <div className="title">{`${this.props.converterType} 파일 이름 (.dxf)`}</div>
            <div className="file-name-wrapper">
              <input
                type="text"
                className="file-name"
                maxLength={255}
                onChange={(e) => {
                  this.props.onChangeFileName(e.target.value);
                  this.setState({ saveNameCheckMsg: "" });
                  this.checkSaveState();
                }
                }
                value={this.props.fileName}
              />
              <div className="button-wrapper">
                {
                  this.props.isSaved ? 
                  // 저장 후
                  <> 
                    <Link to={this.link} className="router-link">
                      <Button className="btn bg-navy btn-cancel font font-12 cancle-btn" onClick={() => this.props.onShowSaveModal()}>바로 가기</Button>
                    </Link>
                    <Button className={`btn bg-navy btn-primary font font-12 save-btn`} onClick={() => { this.props.onShowSaveModal() }}>확인</Button>
                  </>
                  : // 저장 전
                  <> 
                    <Button className="btn bg-navy btn-cancel cancle-btn" onClick={() => this.props.onShowSaveModal()}>취소</Button>
                    <Button className={`btn bg-navy btn-primary save-btn ${this.state.saveNameCheckMsg !== "" ? 'disabled' : ""}`} onClick={() => {
                      this.checkSaveState(true);
                      // this.props.onSave();
                    }}>저장</Button>
                  </>
                }
              </div>
            </div>
            <div className="font font-error font-12px saveNameMsg">
              {this.state.saveNameCheckMsg}
            </div>
            {saveInfo}
          </div>
          {
            this.props.converterType !== ConverterType.myType ?
            <section className={`map-wrapper  ${this.state.loadingPage ? "background-none" : ""} ${this.state.loadingPage? "display-none" : ""}`}>
              <div className="scene">
                <div ref="map" style={{ width: `640px`, height: `380px` }} />
              </div>
            </section> 
            : 
              <section className={`map-wrapper ${this.props.converterType === ConverterType.myType ? "myType" : ""} ${this.state.loadingPage ? "background-none" : ""}`}>
                <div className="scene" style={{ width: `604px`, height: `320px`}}>
                  {
                    //@ts-ignore
                    <SaveViewerModal open={this.props.showModal} buildingObject={this.props.buildingObject} positive={this.checkSaveState} />
                  }
                </div>
              </section>
          }
        </div>
        {/* } */}

      </div>
    );
  }
}

export default MySiteBlockSaveModal;