import React, { Component, Fragment, ReactNode } from "react";
import SessionComponent from "./SessionComponent";
import BuilditTemplate from "./BuilditTemplate";
import BuilditSelect from "./BuilditSelect";

import { default as _ } from "lodash";

import "./css/ProjectList.scss";
import App from "./App";
import { Project, ProjectStatus, BuildingType, ProjectType } from "./model/Project";
import AWSModule from "./AWSModule";
import queryString from "query-string";
import Modal, { ModalOptions } from "./Modal";
import ProjectListHeader from "./ProjectListHeader";
import ProjectListBody from "./ProjectListBody";
import { Button, Table, TableHead, TableCell, TableBody, TableRow, InputAdornment, IconButton, Checkbox, Select, InputLabel, MenuItem, ClickAwayListener, FormControl } from "@material-ui/core";
import ProjectIcon from "./ProjectIcon";
import ProjectTypeTag from "./ProjectTypeTag";
import Switch from "./Switch";
import { project } from "@teneleven/protocols-ts-web";
import DiscountTag from "./DiscountTag";
import { List_Element } from "./BuilditSelect";
import Tooltip from "./Tooltip";
import ReactText from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import HelpRounded from "@material-ui/icons/HelpRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { AutoScalingPlans } from "aws-sdk";

export type TagType = "NOCONFIG" | ProjectType;
export type ModalType = "COPY" | "DELETE" | "DISCARD" | "RESTORE" | "ALL_DELETE";

export const ProjectStatusLabel = {
  CONFIG: "설정중",
  WAITING: "분석중",
  RUNNING: "분석중",
  FINISHED: "완료",
  FAILED: "확인필요",
};

const defaultQuery: ProjectListQuery = {
  sort: {
    field: "created_at",
    order: "desc",
  },
  query: [
    { field: "deleted", content: false },
    { field: "discarded", content: false },
  ],
  tag: "NOCONFIG",
};

const defaultSort: SortOptions = {
  created_at: "desc",
  floor_area_ratio: "desc",
  project_site_area: "desc",
  user_project_id: "desc",
};

export type ProjectListType = ProjectStatus | "FAVORITE" | "DISCARDED" | "LATEST" | "ALL" | "REGION";
export type TableType = "TABLE" | "TABLE_DETAIL" | "CARD";
export type TableBuildingType = "전체" | BuildingType;
export interface ProjectListQuery {
  query?: Array<{
    field: keyof Project | string;
    content: string | number | boolean;
  }>;
  search?: {
    field: "project_name" | "user_project_id";
    content: string | number | boolean;
  };
  sort?: {
    field: keyof SortOptions;
    order: "asc" | "desc";
  };
  type?: ProjectListType;
  tag?: TagType;
  building_type?: TableBuildingType;
}

export interface SortOptions {
  user_project_id: "asc" | "desc";
  project_site_area: "asc" | "desc";
  floor_area_ratio: "asc" | "desc";
  created_at: "asc" | "desc";
}

export interface ProjectListProps {}

export interface ProjectListState {
  projects?: Array<Project>;
  totalNum: number;
  totalHaveNum: number;
  curPageNum: number;
  viewNum: number;
  searchField: "project_name" | "user_project_id";
  searchText: string;
  qString: { page?: number; status?: string; favorite?: boolean; discarded?: boolean; sort?: string; searchText?: string; region?: string } | undefined;
  projectListType: ProjectListType;
  tableType: TableType;
  selectedProject: Array<{
    user_project_id: number;
    checked: boolean;
    discountable: boolean;
    modal_checked: boolean;
  }>;
  checkAll: boolean;
  modalCheckAll: boolean;
  modalOptions?: ModalOptions;
  openModal: boolean;
  counter: {
    ALL: number;
    CONFIG: number;
    WAITING: number;
    RUNNING: number;
    FAILED: number;
    FINISHED: number;
    FAVORITE: number;
    DISCARDED: number;
  };
  reigonAddress: string;
  loading: boolean;
  sort: SortOptions;
  curSort: keyof SortOptions;
  tagType: TagType;
  building_type: TableBuildingType;
  convert_project_type: Array<List_Element>;
  convert_project_type_checked: string;
}

// table 내부 select
const theme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: "#161a24 !important",
      },
    },
    MuiSelect: {
      icon: {
        color: "#ccc",
      },
      selectMenu: {
        marginLeft: "2px",
        paddingRight: "12px",
      },
    },
    MuiInput: {
      underline: {
        "&:hover": {
          ":&before": {
            borderBottom: "1px solid #ccc",
            margin: "0 !important",
          },
          borderBottom: "1px solid #ccc",
          margin: "0 !important",
        },
        "&:after": {
          borderBottom: "1px solid #ccc",
        },
      },
    },
  },
});
// table 외부 select
const darkTheme = createMuiTheme({
  overrides: {
    MuiList: {
      root: {
        backgroundColor: "#161a24 !important",
      },
    },
    MuiMenuItem: {
      root: {
        marginRight: 5,
      },
    },
    MuiSelect: {
      root: {
        backgroundColor: "#0C111B !important",
        padding: "7px",
      },
      selectMenu: {
        paddingRight: "12px",
      },
      icon: {
        color: "#ccc",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#ccc",
        fontSize: "12px",
        marginTop: -3,
      },
      focused: {
        display: "none",
      },
      shrink: {
        display: "none",
      },
    },
    MuiInput: {
      underline: {
        "&:hover": {
          ":&before": {
            borderBottom: "1px solid #ccc",
          },
          margin: "0 !important",
          borderBottom: "1px solid #ccc",
        },
        "&:after": {
          borderBottom: "1px solid #ccc",
        },
      },
    },
  },
});

export default class ProjectList extends SessionComponent<ProjectListProps, ProjectListState> {
  state: ProjectListState = {
    projects: [],
    totalNum: 1,
    totalHaveNum: 1,
    curPageNum: 1,
    tableType: "TABLE",
    viewNum: 10,
    qString: queryString.parse(location.search),
    projectListType: "ALL",
    selectedProject: [],
    checkAll: false,
    modalCheckAll: true,
    counter: {
      ALL: 0,
      CONFIG: 0,
      WAITING: 0,
      RUNNING: 0,
      FAILED: 0,
      FINISHED: 0,
      FAVORITE: 0,
      DISCARDED: 0,
    },
    openModal: false,
    searchField: "project_name",
    searchText: "",
    reigonAddress: "",
    loading: false,
    sort: defaultSort,
    curSort: "user_project_id",
    tagType: "NOCONFIG",
    building_type: "전체",
    convert_project_type: [],
    convert_project_type_checked: "NOTSELECTED",
  };

  componentWillMount = () => {
    this.getQueryString(true);
    // this.getQueryString = _.throttle(this.getQueryString, 100);
  };
  componentDidMount = () => {};
  componentDidUpdate = (pp: Readonly<ProjectListProps>, ps: Readonly<ProjectListState>) => {
    console.log('Projects', this.state.projects)

    if (_.isEqual(queryString.parse(location.search), this.state.qString) === false) {
      this.getQueryString();
    }
    // list 초기화될때 상단 check 해제
    if (this.state.checkAll) {
      if (this.state.selectedProject.length === 0) {
        this.setState({ checkAll: false });
      } else {
        for (let i = 0; i < this.state.selectedProject.length; i++) {
          if (this.state.selectedProject[i].checked === false) {
            this.setState({ checkAll: false });
            return;
          }
        }
      }
    }
  };

  render() {
    return (
      <BuilditTemplate footer={true} {...this.props}>
        <div className="ProjectList">
          <ProjectListHeader projectListType={this.state.projectListType} setProjectListType={this.setProjectListType} {...this.props} />
          <ProjectListBody
            {...this.state}
            {...this.props}
            projects={this.state.projects}
            setPaginationValue={this.setPaginationValue}
            setProjectFavorite={this.setProjectFavorite}
            setSearchField={this.setSearchField}
            setSearchText={this.setSearchText}
            tableType={this.state.tableType}
            setTableType={this.setTableType}
            setSelectedProject={this.setSelectedProject}
            setSort={this.setSortOptions}
            resetSort={this.resetSortOptions}
            setModal={this.setModal}
            setLoading={this.setLoading}
            getQueryString={this.getQueryString}
            setProjectTypeSelect={this.setProjectTypeSelect}
            setProjectListType={this.setProjectListType}
            setQuery={this.setQuery}
            setBuildingType={this.setBuildingType}
            setDiscountProject={this.setDiscountProject}
            onCreateProject={this.onCreateProject}
            getModalOption={this.getModalOption}
          />
        </div>
        {this.state.openModal && (
          <Modal
            open={this.state.openModal}
            type={(this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type) || "SIMPLE"}
            positive={this.state.modalOptions && this.state.modalOptions.positive}
            negative={this.state.modalOptions && this.state.modalOptions.negative}
            title={this.state.modalOptions && this.state.modalOptions.title}
            negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
            positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
            titleHover={this.state.modalOptions && this.state.modalOptions.titleHover}
            additionalInfo={this.state.modalOptions && this.state.modalOptions.additionalInfo}
            content={this.state.modalOptions && this.state.modalOptions.content}
            color={(this.state.modalOptions && this.state.modalOptions.color) || "DARK"}
          />
        )}
      </BuilditTemplate>
    );
  }

  getQueryString = async (isMount?: boolean) => {
    let query = _.cloneDeep(defaultQuery);
    query!.query!.push({ field: "email.keyword", content: App.session.email });

    const parsed = queryString.parse(location.search);
    let tableType = this.state.tableType;
    let projectListType = this.state.projectListType;
    let viewNum = this.state.viewNum;
    let curPageNum = this.state.curPageNum;
    let searchField = this.state.searchField;
    let searchText = this.state.searchText;
    let sort = this.state.sort;
    let curSort = this.state.curSort;
    let tagType = this.state.tagType;
    let building_type = this.state.building_type;
    let q: ProjectListQuery;
    let s: ProjectListQuery;
    if (Object.keys(parsed).length <= 0) {
      //this.setState({tableType: "TABLE", viewNum: 10});
      tableType = "TABLE";
      viewNum = 10;
    }

    // table type
    if (parsed && parsed.table) {
      if (this.state.tableType !== parsed.table) {
        // this.setState({tableType: parsed.table as TableType});
        tableType = parsed.table as TableType;
      }
    } else {
      tableType = "TABLE";
      // this.setState({tableType: "TABLE"});
    }

    // type 관련
    if (parsed && parsed.type) {
      if (this.state.projectListType !== parsed.type) {
        // this.setState({projectListType: parsed.type as ProjectListType});
        projectListType = parsed.type as ProjectListType;
      }
    } else {
      // this.setState({ projectListType: "ALL" })
      projectListType = "ALL";
    }

    let defaultViewNum: number = 10;
    switch (parsed.table) {
      case "TABLE":
      case "TABLE_DETAIL":
        defaultViewNum = 10;
        break;
      case "CARD":
        defaultViewNum = 8;
        break;
      default:
        defaultViewNum = 10;
        break;
    }

    if (isMount === true) {
      if (parsed && parsed.view && parsed.page) {
        // this.setState({viewNum: defaultViewNum, curPageNum: parseInt(parsed.page.toString())});
        viewNum = defaultViewNum;
        curPageNum = parseInt(parsed.page.toString());
      }
      this.setState({
        viewNum: defaultViewNum,
      });
    } else {
      if (parsed && parsed.view && parsed.page) {
        if (this.state.viewNum !== parseInt(parsed.view.toString())) {
          // this.setState({viewNum: parseInt(parsed.view.toString()), curPageNum: 1});
          viewNum = parseInt(parsed.view.toString());
          curPageNum = 1;
        } else {
          // this.setState({curPageNum: parseInt(parsed.page.toString())});
          curPageNum = parseInt(parsed.page.toString());
        }
      } else {
        // this.setState({viewNum: defaultViewNum, curPageNum: 1});
        viewNum = defaultViewNum;
        curPageNum = 1;
      }
      this.setState({
        viewNum: defaultViewNum,
      });
    }

    // search 관련
    if (parsed && parsed.q) {
      q = JSON.parse(parsed.q as string);
      if (q.search) {
        if (this.state.searchField !== q.search.field) {
          // this.setState({ searchField: q.search.field });
          searchField = q.search.field;
        }

        if (q.search.content && this.state.searchText !== q.search.content) {
          // this.setState({ searchText: q.search.content.toString()})
          searchText = q.search.content.toString();
        }
      }
    } else {
      // this.setState({ searchField:"project_name", searchText: "" });
      searchField = "project_name";
      searchText = "";
    }

    //sort 관련
    if (parsed && parsed.sort) {
      s = JSON.parse(parsed.sort as string);
      if (s.sort) {
        if (this.state.curSort !== s.sort.field || this.state.sort[this.state.curSort] !== s.sort.order) {
          // this.setState({curSort: s.sort.field, sort: {...defaultSort, [s.sort.field]:s.sort.order}});
          curSort = s.sort.field;
          sort = { ...defaultSort, [s.sort.field]: s.sort.order };
        }
      }
    } else {
      // this.setState({ curSort: "created_at", sort: {...defaultSort}});
      curSort = "created_at";
      sort = { ...defaultSort };
    }

    //tag 관련
    if (parsed && parsed.tag) {
      if (this.state.tagType !== parsed.tag) {
        // this.setState({tagType: parsed.tag as TagType});
        tagType = parsed.tag as TagType;
      }
      query.tag = parsed.tag as TagType;
    } else {
      // this.setState({tagType: "NOCONFIG"});
      tagType = "NOCONFIG";
    }

    // buildingtype
    if (parsed && parsed.building_type) {
      if (this.state.building_type !== parsed.building_type) {
        // this.setState({building_type: parsed.building_type as TableBuildingType});
        building_type = parsed.building_type as TableBuildingType;
      }
      query.building_type = parsed.building_type as TableBuildingType;
    } else {
      // this.setState({building_type: "전체"});
      building_type = "전체";
    }

    this.setState(
      {
        qString: parsed,
        tableType: tableType,
        projectListType: projectListType,
        viewNum: viewNum,
        curPageNum: curPageNum,
        searchField: searchField,
        searchText: searchText,
        sort: sort,
        curSort: curSort,
        tagType: tagType,
        building_type: building_type,
      },
      async () => {
        if (q) {
          q.search && (query.search = q.search);
          if (q.query && q.query.length > 0) {
            q.query.map((qq) => {
              const index = query.query!.findIndex((qqq) => qqq.field === qq.field);
              if (qq.field === "status" && qq.content === "ALL") {
                return;
              }
              if (index === -1) {
                query.query!.push(qq);
              } else {
                query.query![index].content = qq.content;
              }
            });
          }
        }

        if (s) {
          s.sort && (query.sort = s.sort);
        }
        // sigungu 관련
        // if (query && query.query && query.query.length > 0)  {
        //   const index = query.query.findIndex(e => e.field === "sigungu");
        //   if (index !== -1) {
        //     const sigunguCode = query.query[index].content;
        //     const ddb = await new AWSModule("DDB").connect();
        //     const ar = await ddb.Ddb!.get({
        //       TableName: "platform-buildit-global-dev-SigunguTable-15JSYMQH1KVEF",
        //       ConsistentRead: true,
        //       Key: { id: sigunguCode }
        //     }).promise();

        //     if (ar.$response.error) {
        //       console.log(ar.$response.error);
        //     } else {
        //       this.setState({ reigonAddress: ar.Item!.full_name });
        //     }
        //   }
        // }
        await this.executeQuery(query);
      }
    );
  };

  executeQuery = async (query: ProjectListQuery) => {
    // 동일벨류 있는지 확인하고 덮어쓰기

    if (query.query) {
      let lq = [`stage.keyword:${App.tempStage}`];
      for (let i = 0; i < query.query.length; i++) {
        const field = query.query[i].field;
        const content = query.query[i].content;
        // waiting 일때 running도 같이 찾아야함
        if (field === "status" && content === "WAITING") {
          lq.push(`${field}:(WAITING OR RUNNING)`);
        } else {
          lq.push(`${field}:${content}`);
        }
      }

      // let lq = query.query.map(q => `${q.field}:${q.content}`);
      if (query.search && query.search.content !== "") {
        if (query.search.field === "project_name") {
          lq.push(
            `project_name.keyword: ${query.search.content
              .toString()
              .trim()
              .replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, "\\$&")} OR project_name.keyword: *${query.search.content
              .toString()
              .trim()
              .replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, "\\$&")}*`
          );
        } else {
          lq.push(`${query.search.field}:"${query.search.content.toString().replace(/[()\*\[\]{}:=\'\"%\&\?\+]/g, "\\$&")}"`);
        }
      }

      if (query.tag) {
        if (query.tag !== "NOCONFIG") {
          if (query.tag === "DESIGNER") {
            lq.push(`project_type:(DESIGNER OR SITE_PLAN)`);
          } else {
            lq.push(`project_type:${query.tag}`);
          }
        }
      }

      if (query.building_type) {
        if (query.building_type !== "전체") {
          lq.push(`building_type:"${query.building_type}"`);
        }
      }

      lq.push(`NOT hidden:true`);
      let r;
      try {
        const qsq = lq.map((x) => `(${x})`).join(" AND ");
        r = await App.search({
          table: "platform-buildit-project",
          query: {
            query_string: {
              query: qsq,
            },
          },
          from: this.state.viewNum! * (this.state.curPageNum! - 1),
          size: this.state.viewNum,
          sort: query.sort && [{ [query.sort.field]: query.sort.order }],
        });
      } catch (e) {
        // @ts-ignore
        this.props.history.push("/project/list");
        return;
      }

      const rr = r.data;
      if (rr.hits && rr.hits.hits) {
        // // totalCount에 ',' 넣기
        // if (rr.hits.total >= 1000){
        //   rr.hits.total = rr.hits.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        // }

        const pidList = rr.hits.hits.map((p: any) => ({ project_id: p._source.project_id, stage: App.tempStage }));
        if (pidList.length > 0) {
          const ddb = new AWSModule();
          const dr = await ddb.batchGet({
            RequestItems: {
              "platform-buildit-project": {
                ConsistentRead: true,
                Keys: pidList,
                // AttributesToGet: App.projectFieldWithoutWKT,
              },
            },
          });
          const projects = dr.Responses["platform-buildit-project"];
          if (projects && projects.length > 0 && query.sort) {
            const sortMuliplier = query.sort.order === "asc" ? 1 : -1;
            projects.sort((a: Project, b: Project) => {
              switch (query.sort!.field) {
                case "user_project_id":
                  if (a.user_project_id && b.user_project_id) {
                    return (a.user_project_id - b.user_project_id) * sortMuliplier;
                  }
                  break;
                case "created_at":
                  if (a.created_at && b.created_at) {
                    // @ts-ignore
                    return (new Date(a.created_at) - new Date(b.created_at)) * sortMuliplier;
                  }
                  break;
                case "floor_area_ratio":
                  if (a.floor_area_ratio && b.floor_area_ratio) {
                    return (a.floor_area_ratio - b.floor_area_ratio) * sortMuliplier;
                  }
                  break;
                case "project_site_area":
                  if (a.project_site_area && b.project_site_area) {
                    return (a.project_site_area - b.project_site_area) * sortMuliplier;
                  }
                  break;

                default:
                  break;
              }
              // @ts-ignore
              return new Date(b.created_at) - new Date(a.created_at);
            });
          }

          this.setState(
            {
              //projects: rr.hits.hits && rr.hits.hits.map((p: any) => p._source) || [],
              projects: (projects && projects) || [],
              selectedProject:
                (rr.hits.hits &&
                  rr.hits.hits.map((p: any) => {
                    return {
                      user_project_id: Number(p._source.user_project_id),
                      checked: false,
                      discountable: this.isDiscountable(p._source) ? true : false,
                      modal_checked: false,
                    };
                  })) ||
                [],
              modalCheckAll: true,
              totalNum: rr.hits.total,
              totalHaveNum: query.search ? this.state.totalHaveNum : rr.hits.total,
              // projectListType: type as ProjectListType,
            },
            () => {
              // console.log('final state & projects', this.state)
              // this.getProjectListTypeCounter();
            }
          );
        } else {
          this.setState({
            //projects: rr.hits.hits && rr.hits.hits.map((p: any) => p._source) || [],
            projects: [],
            selectedProject: [],
            totalNum: 0,
            // projectListType: type as ProjectListType,
          });
        }
      }
    }
  };

  setProjectFavorite = async (project: Project, favorite: boolean) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    const ddb = await new AWSModule("DDB").connect();
    const dr = await ddb
      .Ddb!.get({
        ConsistentRead: true,
        TableName: "platform-buildit-project",
        Key: {
          project_id: project.project_id,
          stage: App.tempStage,
        },
      })
      .promise();

    if (dr.Item) {
      const p = _.cloneDeep(dr.Item);
      p.favorite = favorite;
      p.modified_at = new Date().toISOString();

      const r = await lambda
        .Lambda!.invoke({
          FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
          Payload: JSON.stringify([
            {
              TableName: "platform-buildit-project",
              Item: {
                stage: App.tempStage,
                ...p,
              },
            },
          ]),
        })
        .promise();

      const rr = JSON.parse(r.Payload as string);

      if (rr.success) {
        this.getQueryString();
      } else {
        // console.log(rr.reason);
      }
      return;
    } else {
      console.log("err");
    }
  };

  setQuery = (
    ListType: ProjectListType,
    viewNum: number,
    curPageNum: number,
    tagType: TagType,
    sortField: keyof SortOptions,
    sortValue: "asc" | "desc",
    searchField: "project_name" | "user_project_id",
    searchText: string,
    tableType?: TableType,
    building_type?: TableBuildingType
  ) => {
    const options: string[] = [];

    let q: Array<{ field: keyof Project | string; content: string | number | boolean }> = [];

    switch (ListType) {
      case "ALL":
        // do nothing
        break;
      case "WAITING":
        q.push({ field: "status", content: "WAITING" });
        break;
      case "FAVORITE":
        q.push({ field: "favorite", content: true });
        break;
      case "DISCARDED":
        q.push({ field: "discarded", content: true });
        break;
      default:
        q.push({ field: "status", content: ListType as string });
        break;
    }

    const s = {
      field: searchField,
      content: searchText,
    };

    const query: ProjectListQuery = {
      query: q,
      search: s,
    };

    const sort: ProjectListQuery = {
      sort: {
        field: sortField,
        order: sortValue,
      },
    };

    options.push(`table=${tableType ? tableType : this.state.tableType}`);
    options.push(`type=${ListType}`);
    options.push(`page=${curPageNum}`);
    options.push(`view=${viewNum}`);
    options.push(`sort=${encodeURIComponent(JSON.stringify(sort))}`);
    options.push(`tag=${tagType}`);
    options.push(`building_type=${building_type ? building_type : this.state.building_type}`);
    options.push(`q=${encodeURIComponent(JSON.stringify(query))}`);

    // @ts-ignore
    this.props.history.push(`${location.pathname}?${options.join("&")}`);
    this.setState({ searchText: searchText });
  };

  setSearchText = (text: string, callback?: Function) => {
    this.setState({ searchText: text }, () => {
      callback && callback();
    });
  };
  setSearchField = (field: "project_name" | "user_project_id") => {
    if (this.state.searchField === field) {
      return;
    }

    this.setQuery(this.state.projectListType, this.state.viewNum, 1, this.state.tagType, "user_project_id", "desc", field, "");
  };

  setProjectListType = (type: ProjectListType, callback?: Function) => {
    if (type === this.state.projectListType) {
      return;
    }

    this.setQuery(type, this.state.viewNum, 1, this.state.tagType, "user_project_id", "desc", this.state.searchField, this.state.searchText);

    if (callback) {
      callback();
    }
  };

  setTableType = (type: TableType, callback?: Function) => {
    localStorage.setItem("tableType", type);

    if (this.state.tableType === type) {
      return;
    }

    let defaultViewNum: number = 10;
    switch (type) {
      case "TABLE":
      case "TABLE_DETAIL":
        defaultViewNum = 10;
        break;
      case "CARD":
        defaultViewNum = 8;
        break;
      default:
        defaultViewNum = 10;
        break;
    }
    this.setQuery(this.state.projectListType, defaultViewNum, 1, this.state.tagType, "user_project_id", "desc", this.state.searchField, this.state.searchText, type);
  };

  setPaginationValue = (viewNum: number, curPageNum: number, callback?: Function) => {
    if (this.state.viewNum === viewNum && this.state.curPageNum === curPageNum) {
      return;
    }

    this.setQuery(this.state.projectListType, viewNum, curPageNum, this.state.tagType, this.state.curSort, this.state.sort[this.state.curSort], this.state.searchField, this.state.searchText);

    if (callback) {
      callback();
    }

    // this.setState({ viewNum: viewNum ,curPageNum: curPageNum });
  };

  setSelectedProject = (user_project_id: number[], allCheck?: boolean) => {
    const list = _.cloneDeep(this.state.selectedProject);
    user_project_id.map((p) => {
      const index = list.findIndex((e) => e.user_project_id === p);
      if (index > -1) {
        if (allCheck !== undefined) {
          list[index].checked = allCheck;
          list[index].modal_checked = allCheck;
        } else {
          list[index].modal_checked = !list[index].checked;
          list[index].checked = !list[index].checked;
        }
      }
    });

    let allChecked = true;
    for (let i = 0; i < list.length; i++) {
      if (list[i].checked === false) {
        allChecked = false;
      }
    }
    this.setState({ selectedProject: list, checkAll: allChecked });
  };

  setDiscountProject = (user_project_id: number) => {
    const list = _.cloneDeep(this.state.selectedProject);
    const index = list.findIndex((e) => e.user_project_id === user_project_id);
    if (index > -1) {
      list[index].discountable = !list[index].discountable;
    }

    this.setState({ selectedProject: list }, () => {
      this.setModal(true, this.getModalOption("COPY"));
    });
  };

  setModalChecked = (user_project_id: number[], modal_type: ModalType, allCheck?: boolean) => {
    const list = _.cloneDeep(this.state.selectedProject);
    user_project_id.map((p) => {
      const index = list.findIndex((e) => e.user_project_id === p);
      if (index > -1) {
        if (list[index].checked) {
          if (allCheck !== undefined) {
            list[index].modal_checked = allCheck;
          } else {
            list[index].modal_checked = !list[index].modal_checked;
          }
        }
      }
    });

    let allChecked = true;
    for (let i = 0; i < list.length; i++) {
      if (list[i].checked) {
        if (list[i].modal_checked === false) {
          allChecked = false;
        }
      }
    }

    this.setState({ selectedProject: list, modalCheckAll: allChecked }, () => {
      this.setModal(true, this.getModalOption(modal_type));
    });
  };

  syncCheckedProject = () => {
    const list = _.cloneDeep(this.state.selectedProject);
    const return_list = list.map((e) => {
      return {
        user_project_id: e.user_project_id,
        checked: e.checked && e.modal_checked,
        discountable: e.discountable,
        modal_checked: e.checked && e.modal_checked,
      };
    });

    this.setState({ selectedProject: return_list, modalCheckAll: true });
  };

  setSortOptions = (field: keyof SortOptions, value: "asc" | "desc") => {
    if (this.state.curSort === field && this.state.sort[this.state.curSort] === value) {
      return;
    }

    this.setQuery(this.state.projectListType, this.state.viewNum, this.state.curPageNum, this.state.tagType, field, value, this.state.searchField, this.state.searchText);
  };

  resetSortOptions = () => {
    this.setSortOptions("user_project_id", "desc");
  };

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options,
    });
  };

  setLoading = (on: boolean, callback?: Function) => {
    this.setState({ loading: on }, () => callback && callback());
  };

  setProjectTypeSelect = (type: TagType) => {
    if (this.state.tagType === type) {
      return;
    }
    this.setQuery(this.state.projectListType, this.state.viewNum, 1, type, "user_project_id", "desc", this.state.searchField, this.state.searchText);
  };

  setBuildingType = (type: TableBuildingType) => {
    if (this.state.building_type === type) {
      return;
    }
    this.setQuery(this.state.projectListType, this.state.viewNum, 1, this.state.tagType, "user_project_id", "desc", this.state.searchField, this.state.searchText, this.state.tableType, type);
  };

  onCreateProject = async (callback: Function) => {
    const ddb = await new AWSModule("DDB").connect();
    const project_id = await ddb
      .Ddb!.update({
        TableName: "platform-buildit-counter",
        Key: { name: `${App.tempStage}/project_id` },
        ExpressionAttributeNames: { "#counter": "counter" },
        ExpressionAttributeValues: { ":counter": 1 },
        UpdateExpression: "SET #counter = #counter + :counter",
        ReturnValues: "UPDATED_OLD",
      })
      .promise();

    const is_exist_user_project_id = await ddb
      .Ddb!.get({
        TableName: "platform-buildit-counter",
        Key: {
          name: `${App.tempStage}/project_id/${App.session.email}`,
        },
      })
      .promise();

    // counter db update at creating first project
    if (is_exist_user_project_id.Item === undefined) {
      await ddb
        .Ddb!.put({
          TableName: "platform-buildit-counter",
          Item: {
            name: `${App.tempStage}/project_id/${App.session.email}`,
            counter: 1,
          },
        })
        .promise();
    }

    const user_project_id = await ddb
      .Ddb!.update({
        TableName: "platform-buildit-counter",
        Key: { name: `${App.tempStage}/project_id/${App.session.email}` },
        ExpressionAttributeNames: { "#counter": "counter" },
        ExpressionAttributeValues: { ":counter": 1 },
        UpdateExpression: "SET #counter = #counter + :counter",
        ReturnValues: "UPDATED_OLD",
      })
      .promise();

    if (!project_id.Attributes || !user_project_id.Attributes) {
      // error
      return;
    }

    callback(Number(project_id.Attributes.counter), Number(user_project_id.Attributes.counter));
  };

  getPaymentPoint = (project: Project): number => {
    const base = 1000;
    if (project.project_type === "AI") {
      return base + Math.floor(project.project_site_area! * 1);
    } else if (project.project_type === "DESIGNER" || project.project_type === "SITE_PLAN") {
      return base + Math.floor(project.project_site_area! * 0.5);
    }
    return 0;
  };

  isDiscountable = (project: Project): boolean => {
    if (project.project_type === "AI" || project.project_type === "DESIGNER" || project.project_type === "SITE_PLAN") {
      if (project.status === "FINISHED") {
        return true;
      } else if (project.discountable === true) {
        return true;
      }
    }

    return false;
  };
  initSelectProjectType = (len: number) => {
    this.setState({
      convert_project_type: Array(len).fill({ label: <></>, value: "" }, 0, len), // len 크기에 맞춰 초기화
    });
  };

  updateSelectProjectType = (index: number, label: JSX.Element, value: string, list: Array<{ project: Project; discountable: boolean; modal_checked: boolean }>) => {
    let updateProjectType = this.state.convert_project_type;
    updateProjectType[index] = { label, value };
    this.setState(
      {
        convert_project_type: updateProjectType,
      },
      () => {
        this.setModal(true, this.getModalOption("COPY"));
      }
    );
  };

  getModalOption = (type: ModalType) => {
    let option: ModalOptions | undefined;

    switch (type) {
      case "COPY":
        {
          let list: Array<{ project: Project; discountable: boolean; modal_checked: boolean }> = [];
          const project_type_list: Array<[JSX.Element, string]> = [
            [<ProjectTypeTag background="DARK" type="NOTSELECTED" />, "NOTSELECTED"],
            [<ProjectTypeTag background="DARK" type="AI" />, "AI"],
            [<ProjectTypeTag background="DARK" type="DESIGNER" />, "DESIGNER"],
          ];
          for (let i = 0; i < this.state.selectedProject.length; i++) {
            if (this.state.selectedProject[i].checked && this.state.projects![i].project_type !== "CAL") {
              list.push({
                project: this.state.projects![i],
                discountable: this.state.selectedProject[i].discountable,
                modal_checked: this.state.selectedProject[i].modal_checked,
              });
            }
          }

          if (list.length === 0 && this.state.selectedProject.map((r) => Number(r.checked)).reduce((a, b) => a + b, 0)) {
            option = {
              color: "DARK",
              type: "SIMPLE",
              positive: () => {
                this.setModal(false);
              },
              negative: "hidden",
              content: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProjectTypeTag background="DARK" type="CAL" />
                  &nbsp;프로젝트는 복사할 수 없습니다.
                </div>
              ),
            };
          } else {
            if (this.state.convert_project_type.length === 0) {
              this.initSelectProjectType(list.length); // convert_project_type 배열 초기화
            }
            option = {
              positive: async () => {
                // modal_check한 내용만 복사할 때 사용하는 코드
                // if (this.state.selectedProject.map((r) => (r.modal_checked && 1) || 0).reduce((a: any, b: any) => a + b) === 0) {
                //   alert("복사할 프로젝트가 설정되지 않았습니다.");
                //   this.syncCheckedProject();
                //   this.setModal(false);
                //   return;
                // }
                this.setLoading(true, async () => {
                  const keys = [];
                  for (const p of list) {
                    if (p.modal_checked) {
                      keys.push({
                        project_id: p.project.project_id,
                        stage: App.tempStage,
                      });
                    }
                  }
                  const ddb = await new AWSModule("DDB").connect();
                  const dr = await ddb.batchGet({
                    RequestItems: {
                      "platform-buildit-project": {
                        ConsistentRead: true,
                        Keys: keys,
                      },
                    },
                  });
                  const projects = dr.Responses && dr.Responses["platform-buildit-project"];

                  if (projects) {
                    const lambda = await new AWSModule("LAMBDA").connect();
                    const updates: any[] = [];
                    for (let i = 0; i < projects.length; i++) {
                      await this.onCreateProject((project_id: number, user_project_id: number) => {
                        const curProject = _.cloneDeep(projects[i]);
                        const newProject: Project = {};
                        Object.keys(curProject).forEach((e) => {
                          if (curProject[e as keyof Project] !== "" && curProject[e as keyof Project] !== undefined) {
                            // @ts-ignore
                            newProject[e as keyof Project] = curProject[e as keyof Project];
                          }
                        });
                        // project_type변환 체크
                        // 선언
                        let new_project_type: ProjectType | undefined;
                        let new_building_type: BuildingType | undefined;
                        let new_skyline_circle;
                        let new_skyline_line;

                        // projects와 list의 순서가 맞지 않음. -> 싱크 맞추기
                        const projectId = curProject.project_id;
                        const findInList = list.filter((item) => {
                          return item.project.project_id === projectId;
                        });
                        const listIndex = list.indexOf(findInList[0]); // list에서 동일 project의 index값
                        if (list[listIndex].project.project_type !== this.state.convert_project_type[listIndex].value && this.state.convert_project_type[listIndex].value !== "") {
                          // 변환 O
                          if (list[listIndex].project.project_type === "AI") {
                            // AI => DESIGNER
                            new_project_type = "DESIGNER";
                            new_building_type = "아파트";
                            new_skyline_circle = [];
                            new_skyline_line = [];
                          } else {
                            // DESIGNER => AI
                            new_project_type = "AI";
                            new_building_type = undefined;
                            new_skyline_circle = [];
                            new_skyline_line = [];
                          }
                        } else {
                          // 변환 X 시 기존 curProject 기본값
                          new_project_type = curProject.project_type;
                          new_building_type = curProject.building_type;
                          new_skyline_circle = curProject.skyline_circle;
                          new_skyline_line = curProject.skyline_line;
                        }

                        const index = list.findIndex((e) => e.project.user_project_id === curProject.user_project_id);
                        if (index > -1) {
                          newProject.discountable = list[index].discountable;
                        } else {
                          newProject.discountable = false;
                        }
                        let newProjectName = `${curProject.project_name} - 복사본`;
                        newProject.project_name = (newProjectName.length > 100 && newProjectName.slice(0, 100)) || newProjectName;
                        newProject.project_id = project_id;
                        newProject.user_project_id = user_project_id;
                        newProject.copy_project_id = curProject.user_project_id;
                        newProject.favorite = false;
                        // newProject.project_type = curProject.project_type === "SITE_PLAN" && "SITE_PLAN" || "UNDEFINED";
                        newProject.status = "CONFIG";
                        newProject.created_at = new Date().toISOString();
                        newProject.modified_at = new Date().toISOString();
                        newProject.finished_at = undefined;
                        newProject.canceled_at = undefined;
                        newProject.deleted_at = undefined;
                        newProject.queued_at = undefined;
                        newProject.viewed_at = undefined;
                        newProject.run_at = undefined;
                        newProject.finished_ai = undefined;
                        newProject.auto_name = false;
                        // 프로젝트 타입 변환 파트
                        newProject.project_type = new_project_type;
                        newProject.building_type = new_building_type;
                        newProject.skyline_circle = new_skyline_circle;
                        newProject.skyline_line = new_skyline_line;

                        updates.push({
                          TableName: "platform-buildit-project",
                          Item: {
                            stage: App.tempStage,
                            ...newProject,
                          },
                        });
                      });
                    }
                    const r = await lambda
                      .Lambda!.invoke({
                        FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                        Payload: JSON.stringify(updates),
                      })
                      .promise();

                    const r_res = JSON.parse(r.Payload as string);

                    if (r_res.success) {
                      this.getQueryString();
                    } else {
                      // console.log(r_res.reason);
                    }
                  }
                  this.setModal(false);
                  this.setLoading(false);
                  // 복사 update완료 후 state값 초기화 (안그러면 이전에 복사했던 기록 남아있음.)
                  this.setState({
                    convert_project_type: [],
                    convert_project_type_checked: "NOTSELECTED",
                  });
                });
              },
              negative: () => {
                this.syncCheckedProject();
                this.setModal(false);
                this.setState({
                  convert_project_type: [],
                  convert_project_type_checked: "NOTSELECTED",
                });
              },
              positiveTitle: "복사하기",
              negativeTitle: "취소",
              title: "프로젝트 복사",
              additionalInfo: <span style={{ color: "#48DDF2" }}>{list.length}개의 프로젝트를 복사합니다.</span>,
              titleHover: (
                <Tooltip
                  className="m-l-lg"
                  place="right"
                  msg={
                    <>
                      <div className="info">* 완료된 프로젝트는 할인 적용이 가능합니다..</div>
                      <div className="info">* 할인적용 시, 대지 영역은 수정이 불가능합니다.</div>
                      <div className="info">* 할인률은 정상 가격 기준입니다.</div>
                      <div className="info">* 타입 변경을 선택하지 않으면 동일한 프로젝트로 복사됩니다.</div>
                      <div className="info">
                        *&nbsp;
                        <span style={{ color: "#4287f5" }}>
                          <strong>AI.cal 프로젝트</strong>
                        </span>
                        는 복사할 수 없습니다.
                      </div>
                    </>
                  }
                >
                  <HelpRounded style={{ color: "#ccc", width: "16px", height: "16px", alignItems: "center" }} className="help-icon" />
                </Tooltip>
              ),
              color: "WHITE",
              content: (
                <div className="copy-modal">
                  <div className="table-wrapper">
                    <Table className="table">
                      <TableHead className="table-head">
                        <TableRow className="table-row">
                          <TableCell className="table-cell checkbox">
                            <Checkbox
                              className={`checkbox ${(this.state.modalCheckAll && "checked") || ""}`}
                              checked={this.state.modalCheckAll}
                              onClick={(e) => {
                                this.state.projects &&
                                  this.setModalChecked(
                                    this.state.projects.map((p) => p.user_project_id!),
                                    "COPY",
                                    !this.state.modalCheckAll
                                  );
                              }}
                            />
                          </TableCell>
                          <TableCell className="table-cell project-id">
                            <div>번호</div>
                          </TableCell>
                          <TableCell className="table-cell project-type">
                            <div>타입</div>
                          </TableCell>
                          <TableCell className="table-cell project-type-change">
                            <div>타입 변경</div>
                          </TableCell>
                          <TableCell className="table-cell project-status">
                            <div>상태</div>
                          </TableCell>
                          <TableCell className="table-cell project-name bigger">
                            <div className="head">프로젝트명</div>
                          </TableCell>
                          <TableCell className="table-cell project-price">
                            <div>정상가격</div>
                          </TableCell>
                          <TableCell className="table-cell discount-rate">
                            <div>할인률</div>
                          </TableCell>
                          <TableCell className="table-cell discount-select">
                            <div>할인적용</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {list.map((p, i) => {
                          return (
                            <TableRow className="table-row">
                              <TableCell className="table-cell checkbox">
                                <Checkbox
                                  className={`checkbox ${
                                    (this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked && "checked") || ""
                                  }`}
                                  checked={this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked}
                                  onClick={(e) => {
                                    this.setModalChecked([p.project.user_project_id!], "COPY");
                                  }}
                                />
                              </TableCell>
                              <TableCell className="table-cell project-id">
                                <div>{p.project.user_project_id}</div>
                              </TableCell>
                              <TableCell className="table-cell project-type">{<ProjectTypeTag type={p.project.project_type!} background="DARK" />}</TableCell>
                              <TableCell className="table-cell project-type-change">
                                <MuiThemeProvider theme={theme}>
                                  <FormControl>
                                    <InputLabel id="convert-project-type"></InputLabel>
                                    <Select
                                      id="convert-project-type"
                                      className="convert-project-type"
                                      classes={{ root: "MenuItem" }}
                                      IconComponent={ExpandMoreIcon}
                                      style={{ minWidth: 90, alignItems: "center", marginTop: 1 }}
                                      inputProps={{
                                        id: "convert_project_type",
                                      }}
                                      value={(this.state.convert_project_type[i] && this.state.convert_project_type[i].value) || p.project.project_type}
                                      onChange={(e: any) => {
                                        e.target.value
                                          ? this.updateSelectProjectType(i, <ProjectTypeTag type={"AI"} background="DARK" />, e.target.value, list) // UI상태를 변경하기 위한 상태값 update
                                          : this.updateSelectProjectType(i, <ProjectTypeTag type={"DESIGNER"} background="DARK" />, e.target.value, list); // SITE_PLAN이어도 DESIGNER로
                                      }}
                                    >
                                      {project_type_list.slice(1, 3).map((label, idx) => (
                                        <MenuItem key={idx + 1} value={label[1]} selected classes={{ root: "MenuItem", selected: "selected" }}>
                                          {label[0]}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </MuiThemeProvider>
                              </TableCell>
                              <TableCell className="table-cell project-status">{<ProjectIcon status={p.project.status} />}</TableCell>
                              <TableCell className="table-cell project-name bigger">
                                <div className="row bigger">
                                  {p.project.discountable && (
                                    <span style={{ fontSize: "12px", marginRight: "5px", display: "flex" }}>
                                      <DiscountTag />
                                    </span>
                                  )}
                                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: p.project.discountable ? "545px" : "570px" }}>{p.project.project_name}</div>
                                </div>
                              </TableCell>
                              <TableCell className="table-cell project-price">
                                <div>{`${this.getPaymentPoint(p.project).toLocaleString()}P`}</div>
                              </TableCell>
                              <TableCell className="table-cell discount-rate">
                                <div>{this.isDiscountable(p.project) ? "90%" : "-"}</div>
                              </TableCell>
                              <TableCell className="table-cell discount-select">
                                <div>
                                  {this.isDiscountable(p.project) && (
                                    <Switch
                                      className="select-switch"
                                      checked={p.discountable}
                                      name="적용"
                                      onClick={(checked) => {
                                        this.setDiscountProject(p.project.user_project_id!);
                                      }}
                                    />
                                  )}
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {list.length === 0 && (
                          <TableRow className="table-row">
                            <TableCell colSpan={5} className="table-cell no-data">
                              {"선택된 프로젝트가 없습니다"}
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  <div className="info-wrap">
                    <div className="info">* 선택한 프로젝트의 타입을 </div>
                    <div className="info">
                      <MuiThemeProvider theme={darkTheme}>
                        <FormControl variant="filled">
                          <InputLabel id="convert-project-type-check" shrink={false}></InputLabel>
                          <Select
                            id="convert-project-type-check"
                            className="convert-project-type-check"
                            IconComponent={ExpandMoreIcon}
                            classes={{ root: "MenuItem" }}
                            style={{ minWidth: 95, marginTop: 1 }}
                            value={this.state.convert_project_type_checked}
                            onChange={(e: any) => {
                              this.setState({ convert_project_type_checked: e.target.value }, () => {
                                this.setModal(true, this.getModalOption("COPY"));
                              });
                            }}
                          >
                            {project_type_list.map((label, idx) => (
                              <MenuItem key={idx} value={label[1]} classes={{ root: "MenuItem", selected: "selected" }}>
                                {label[0]}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </MuiThemeProvider>
                    </div>
                    <div className="info">로</div>
                    <div className="info modal-action">
                      <Button
                        className="btn positive-btn"
                        disableRipple={true}
                        onClick={() => {
                          // 1. modal_check true인 값
                          const modalCheckedList = list.map((listItem, idx) => {
                            if (listItem.modal_checked === true) {
                              return idx;
                            }
                          });
                          // 2. state값 변경
                          modalCheckedList.map((item, idx) => {
                            if (item !== undefined) {
                              const convertType = this.state.convert_project_type_checked;
                              if (convertType === "AI") {
                                this.updateSelectProjectType(idx, <ProjectTypeTag type={"AI"} background="DARK" />, convertType, list);
                              } else if (convertType === "DESIGNER") {
                                this.updateSelectProjectType(idx, <ProjectTypeTag type={"DESIGNER"} background="DARK" />, convertType, list);
                              }
                            }
                          });
                        }}
                      >
                        변경하기
                      </Button>
                    </div>
                  </div>
                </div>
              ),
              type: "BIG",
            };
          }
        }
        break;

      case "DISCARD":
        {
          let list: Array<{ project: Project; discountable: boolean; modal_checked: boolean }> = [];
          let message: string[] = [];
          type MessageType = {
            "분석중 프로젝트": boolean;
            "즐겨찾기 프로젝트": boolean;
            "완료된 프로젝트": boolean;
            "확인요 프로젝트": boolean;
          };
          let messageType = {
            "분석중 프로젝트": false,
            "즐겨찾기 프로젝트": false,
            "완료된 프로젝트": false,
            "확인요 프로젝트": false,
          };

          if (this.state.projects === undefined) {
            return undefined;
          }

          // DEV 분석중 삭제 가능
          if (App.stage !== "prod") {
            for (let i = 0; i < this.state.selectedProject.length; i++) {
              if (this.state.selectedProject[i].checked) {
                list.push({
                  project: this.state.projects![i],
                  discountable: this.state.selectedProject[i].discountable,
                  modal_checked: this.state.selectedProject[i].modal_checked,
                });
              }
            }
          } else {
            for (let i = 0; i < this.state.selectedProject.length; i++) {
              if (this.state.selectedProject[i].checked) {
                if (this.state.projects[i].status === "WAITING" || this.state.projects[i].status === "RUNNING") {
                  messageType["분석중 프로젝트"] = true;
                } else {
                  list.push({
                    project: this.state.projects![i],
                    discountable: this.state.selectedProject[i].discountable,
                    modal_checked: this.state.selectedProject[i].modal_checked,
                  });
                }
              }
            }
          }

          Object.keys(messageType).forEach((e) => {
            messageType[e as keyof MessageType] && message.push(e);
          });
          option = {
            positive: () => {
              this.setLoading(true, async () => {
                await this.discard(list.filter((e) => e.modal_checked).map((e) => e.project.user_project_id!));
                this.setModal(false);
                this.setLoading(false);
              });
            },
            negative: () => {
              this.syncCheckedProject();
              this.setModal(false);
            },
            title: "삭제 안내",
            color: "DARK",
            type: "NORMAL",
            positiveTitle: "삭제",
            content: (
              <div className="copy-modal">
                {(list.length !== 0 && (
                  <div className="table-wrapper">
                    <Table className="table">
                      <TableHead className="table-head">
                        <TableRow className="table-row">
                          <TableCell className="table-cell checkbox">
                            <Checkbox
                              className={`checkbox ${(this.state.modalCheckAll && "checked") || ""}`}
                              checked={this.state.modalCheckAll}
                              onClick={(e) => {
                                this.state.projects &&
                                  this.setModalChecked(
                                    this.state.projects.map((p) => p.user_project_id!),
                                    "DISCARD",
                                    !this.state.modalCheckAll
                                  );
                              }}
                            />
                          </TableCell>
                          <TableCell className="table-cell project-id">
                            <div>번호</div>
                          </TableCell>
                          <TableCell className="table-cell project-type">
                            <div>타입</div>
                          </TableCell>
                          <TableCell className="table-cell project-status">
                            <div>상태</div>
                          </TableCell>
                          <TableCell className="table-cell project-name">
                            <div className="head">프로젝트명</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className="table-body">
                        {list.map((p, i) => {
                          return (
                            <TableRow className="table-row">
                              <TableCell className="table-cell checkbox">
                                <Checkbox
                                  className={`checkbox ${
                                    (this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked && "checked") || ""
                                  }`}
                                  checked={this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked}
                                  onClick={(e) => {
                                    this.setModalChecked([p.project.user_project_id!], "DISCARD");
                                  }}
                                />
                              </TableCell>
                              <TableCell className="table-cell project-id">
                                <div>{p.project.user_project_id}</div>
                              </TableCell>
                              <TableCell className="table-cell project-type">{<ProjectTypeTag type={p.project.project_type!} background="DARK" />}</TableCell>
                              <TableCell className="table-cell project-status">{<ProjectIcon status={p.project.status} />}</TableCell>
                              <TableCell className="table-cell project-name">
                                <div className="row">
                                  {p.project.discountable && (
                                    <span style={{ fontSize: "12px", marginRight: "5px", display: "flex" }}>
                                      <DiscountTag />
                                    </span>
                                  )}
                                  <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: p.project.discountable ? "370px" : "390px" }}>{p.project.project_name}</div>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )) || <div>선택된 프로젝트가 없습니다.</div>}

                <div className="info-wrap">{App.stage !== "dev" && <div className="info">* 분석 중인 프로젝트는 삭제하실 수 없습니다.</div>}</div>
              </div>
            ),
          };
        }
        break;

      case "RESTORE":
        {
          let list: Array<{ project: Project; discountable: boolean; modal_checked: boolean }> = [];

          if (this.state.projects === undefined) {
            return undefined;
          }

          for (let i = 0; i < this.state.selectedProject.length; i++) {
            if (this.state.selectedProject[i].checked) {
              list.push({
                project: this.state.projects![i],
                discountable: this.state.selectedProject[i].discountable,
                modal_checked: this.state.selectedProject[i].modal_checked,
              });
            }
          }
          option = {
            positive: () => {
              this.setLoading(true, async () => {
                const updates: any[] = [];
                list.map((p, i) => {
                  if (this.state.projects && p.modal_checked) {
                    const project = _.cloneDeep(this.state.projects[i]);
                    updates.push({
                      TableName: "platform-buildit-project",
                      Item: {
                        stage: App.tempStage,
                        ...project,
                        discarded: false,
                      },
                    });
                  }
                });
                this.setLoading(true, async () => {
                  const lambda = await new AWSModule("LAMBDA").connect();
                  const r = await lambda
                    .Lambda!.invoke({
                      FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                      Payload: JSON.stringify(updates),
                    })
                    .promise();

                  if (JSON.parse(r.Payload as string).success) {
                    this.getQueryString();
                  } else {
                    console.log(JSON.parse(r.Payload as string).reason);
                  }
                  this.setModal(false);
                  this.setLoading(false);
                });
              });
            },
            negative: () => {
              this.syncCheckedProject();
              this.setModal(false);
            },
            title: "되돌리기 안내",
            color: "DARK",
            type: "NORMAL",
            positiveTitle: "되돌리기",
            content: (
              <div className="copy-modal">
                <div className="table-wrapper">
                  <Table className="table">
                    <TableHead className="table-head">
                      <TableRow className="table-row">
                        <TableCell className="table-cell checkbox">
                          <Checkbox
                            className={`checkbox ${(this.state.modalCheckAll && "checked") || ""}`}
                            checked={this.state.modalCheckAll}
                            onClick={(e) => {
                              this.state.projects &&
                                this.setModalChecked(
                                  this.state.projects.map((p) => p.user_project_id!),
                                  "RESTORE",
                                  !this.state.modalCheckAll
                                );
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell project-id">
                          <div>번호</div>
                        </TableCell>
                        <TableCell className="table-cell project-type">
                          <div>타입</div>
                        </TableCell>
                        <TableCell className="table-cell project-status">
                          <div>상태</div>
                        </TableCell>
                        <TableCell className="table-cell project-name">
                          <div className="head">프로젝트명</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {list.map((p, i) => {
                        return (
                          <TableRow className="table-row">
                            <TableCell className="table-cell checkbox">
                              <Checkbox
                                className={`checkbox ${
                                  (this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked && "checked") || ""
                                }`}
                                checked={this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked}
                                onClick={(e) => {
                                  this.setModalChecked([p.project.user_project_id!], "RESTORE");
                                }}
                              />
                            </TableCell>
                            <TableCell className="table-cell project-id">
                              <div>{p.project.user_project_id}</div>
                            </TableCell>
                            <TableCell className="table-cell project-type">{<ProjectTypeTag type={p.project.project_type!} background="DARK" />}</TableCell>
                            <TableCell className="table-cell project-status">{<ProjectIcon status={p.project.status} />}</TableCell>
                            <TableCell className="table-cell project-name">
                              <div className="row">
                                {p.project.discountable && (
                                  <span style={{ fontSize: "12px", marginRight: "5px", display: "flex" }}>
                                    <DiscountTag />
                                  </span>
                                )}
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: p.project.discountable ? "370px" : "390px" }}>{p.project.project_name}</div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {list.length === 0 && (
                        <TableRow className="table-row">
                          <TableCell colSpan={5} className="table-cell no-data">
                            {"선택된 프로젝트가 없습니다"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <div className="info-wrap"></div>
              </div>
            ),
          };
        }
        break;

      case "DELETE":
        {
          let list: Array<{ project: Project; discountable: boolean; modal_checked: boolean }> = [];
          const updates: any[] = [];

          if (this.state.projects === undefined) {
            return undefined;
          }

          for (let i = 0; i < this.state.selectedProject.length; i++) {
            if (this.state.selectedProject[i].checked) {
              list.push({
                project: this.state.projects![i],
                discountable: this.state.selectedProject[i].discountable,
                modal_checked: this.state.selectedProject[i].modal_checked,
              });
            }
          }

          this.state.selectedProject.map((p, i) => {
            if (p.checked && this.state.projects && p.modal_checked) {
              const project = _.cloneDeep(this.state.projects[i]);
              updates.push({
                TableName: "platform-buildit-project",
                Item: {
                  stage: App.tempStage,
                  ...project,
                  deleted: true,
                  deleted_at: new Date().toISOString(),
                  modified_at: new Date().toISOString(),
                },
              });
            }
          });

          option = {
            positive: () => {
              this.setLoading(true, async () => {
                const lambda = await new AWSModule("LAMBDA").connect();
                const r = await lambda
                  .Lambda!.invoke({
                    FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                    Payload: JSON.stringify(updates),
                  })
                  .promise();

                if (JSON.parse(r.Payload as string).success) {
                  this.getQueryString();
                } else {
                  console.log(JSON.parse(r.Payload as string).reason);
                }
                this.setModal(false);
                this.setLoading(false);
              });
            },
            negative: () => {
              this.syncCheckedProject();
              this.setModal(false);
            },
            title: "프로젝트 영구삭제",
            color: "DARK",
            type: "NORMAL",
            positiveTitle: "영구삭제",
            content: (
              <div className="copy-modal">
                <div className="table-wrapper">
                  <Table className="table">
                    <TableHead className="table-head">
                      <TableRow className="table-row">
                        <TableCell className="table-cell checkbox">
                          <Checkbox
                            className={`checkbox ${(this.state.modalCheckAll && "checked") || ""}`}
                            checked={this.state.modalCheckAll}
                            onClick={(e) => {
                              this.state.projects &&
                                this.setModalChecked(
                                  this.state.projects.map((p) => p.user_project_id!),
                                  "DELETE",
                                  !this.state.modalCheckAll
                                );
                            }}
                          />
                        </TableCell>
                        <TableCell className="table-cell project-id">
                          <div>번호</div>
                        </TableCell>
                        <TableCell className="table-cell project-type">
                          <div>타입</div>
                        </TableCell>
                        <TableCell className="table-cell project-status">
                          <div>상태</div>
                        </TableCell>
                        <TableCell className="table-cell project-name">
                          <div className="head">프로젝트명</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {list.map((p, i) => {
                        return (
                          <TableRow className="table-row">
                            <TableCell className="table-cell checkbox">
                              <Checkbox
                                className={`checkbox ${
                                  (this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked && "checked") || ""
                                }`}
                                checked={this.state.selectedProject[this.state.selectedProject.findIndex((e) => e.user_project_id === p.project.user_project_id)].modal_checked}
                                onClick={(e) => {
                                  this.setModalChecked([p.project.user_project_id!], "DELETE");
                                }}
                              />
                            </TableCell>
                            <TableCell className="table-cell project-id">
                              <div>{p.project.user_project_id}</div>
                            </TableCell>
                            <TableCell className="table-cell project-type">{<ProjectTypeTag type={p.project.project_type!} background="DARK" />}</TableCell>
                            <TableCell className="table-cell project-status">{<ProjectIcon status={p.project.status} />}</TableCell>
                            <TableCell className="table-cell project-name">
                              <div className="row">
                                {p.project.discountable && (
                                  <span style={{ fontSize: "12px", marginRight: "5px", display: "flex" }}>
                                    <DiscountTag />
                                  </span>
                                )}
                                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: p.project.discountable ? "370px" : "390px" }}>{p.project.project_name}</div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {list.length === 0 && (
                        <TableRow className="table-row">
                          <TableCell colSpan={5} className="table-cell no-data">
                            {"선택된 프로젝트가 없습니다"}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <div className="info-wrap">
                  <div className="info">* 프로젝트를 영구삭제 하시면 되돌릴 수 없습니다. 그래도 삭제 하시겠습니까?</div>
                </div>
              </div>
            ),
          };
        }
        break;
      case "ALL_DELETE":
        option = {
          positiveTitle: "전체 영구 삭제",
          positive: async () => {
            const r = await App.search({
              table: "platform-buildit-project",
              query: `discarded:true AND deleted:false`,
              from: 0,
              size: 10000,
              sort: [{ finished_at: "desc", created_at: "desc" }],
            });

            const rr = r.data;
            let updates: any[] = [];
            if (rr.hits && rr.hits.hits) {
              const projects = rr.hits.hits.map((p: any) => p._source);
              projects.map((p: Project) => {
                updates.push({
                  stage: App.tempStage,
                  TableName: "platform-buildit-project",
                  Item: {
                    ...p,
                    deleted_at: new Date().toISOString(),
                    deleted: true,
                  },
                });
              });
              this.setLoading(true, async () => {
                const lambda = await new AWSModule("LAMBDA").connect();
                const r = await lambda
                  .Lambda!.invoke({
                    FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                    Payload: JSON.stringify(updates),
                  })
                  .promise();
                this.setLoading(false);
                this.getQueryString();
              });
            }

            this.setModal(false);
          },
          negative: () => {
            this.setModal(false);
          },
        };

        break;
      default:
        option = undefined;
        break;
    }

    return option;
  };

  discard = async (list: number[]) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    const updates = [];

    if (!this.state.projects) {
      App.stage !== "prod" && console.log("projects 가 없습니다.");
      return;
    }

    for (let i = 0; i < list.length; i++) {
      const index = this.state.selectedProject.findIndex((p) => p.user_project_id === list[i]);
      if (index > -1) {
        const project = _.cloneDeep(this.state.projects[index]);
        project.discarded = true;
        project.modified_at = new Date().toISOString();

        updates.push({
          TableName: "platform-buildit-project",
          Item: {
            stage: App.tempStage,
            ...project,
          },
        });
      }
    }

    // TODO: 속도문제 loading
    const r = await lambda
      .Lambda!.invoke({
        FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
        Payload: JSON.stringify(updates),
      })
      .promise();

    const r_res = JSON.parse(r.Payload as string);
    if (r_res.success) {
    } else {
      console.log(r_res.reason);
    }

    this.getQueryString();
  };
}
