import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import '../css/CADConverter/ConverterInputBox.scss';
import { ClickAwayListener } from '@material-ui/core';
import { buttonNum } from './Modal';
import { BuildingPart } from './BuildingPart';
import { Info, Refresh } from '@material-ui/icons';
import Tooltip from '../Tooltip';
interface InputBoxProp {
  name: string,
  value: number,
  disable?: boolean,
  step?: number
  unit?: string,
  buildingPart?: BuildingPart,
  defaultValue?: number,
  valueOnChange: (value: number) => void,
  valueUpdate?: () => void,
  showModal?: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void,
}

interface InputBoxState {
  focus: boolean,
  hover: boolean,
  value: number,
  step: number,
  disable: boolean
}

export class ConverterInputBox extends Component<InputBoxProp, InputBoxState> {
  state: InputBoxState = {
    focus: false,
    hover: false,
    value: this.props.value,
    disable: false,
    step: 1,
  }


  componentDidMount = async () => {
    if (this.props.disable !== null) {
      this.setState({ disable: this.props.disable! })
    }

    if (this.props.step) {
      this.setState({ step: this.props.step })
    }
  }

  componentDidUpdate = (previousProps: Readonly<InputBoxProp>, previousState: Readonly<InputBoxState>) => {
    if (previousProps.disable !== this.props.disable) {
      this.setState({
        disable: this.props.disable!,
      })
    }

    if (previousProps.value !== this.props.value || this.state.value !== this.props.value) {
      this.setState({
        value: this.props.value,
      })
    }
    if (this.props.step && previousProps.step !== this.props.step || this.state.step !== this.props.step) {
      this.setState({
        step: this.props.step!
      })
    }
  }

  addEvent = () => {
    this.changeValue((this.state.value + this.state.step).toString());
  }

  decreaseEvent = () => {
    this.changeValue((this.state.value - this.state.step).toString());
  }

  onClickAway = () => {
    this.setState({
      focus: false,
    })
  }

  changeValue = (value: string) => {
    let v = Number(value);

    // * 소수점 제한
    if (v < 0) { v = 0 }
    let s = 1 / this.state.step;    
    v = Math.round(v * s) / s;
    // if (this.props.showModal){
    //   this.props.showModal('알림', ['면적 값이 수정 되었습니다. 계속 진행하시겠습니까?'], buttonNum.twoButton, () => {
    //     this.setValue(v);
    //   });
    // }
    // else {
    //   this.setValue(v);
    // }
    this.setValue(v);
        
  }
  setValue = (v: number) => {
    // if (this.props.name === "층고(m)" && this.state.checkFloorHeights && this.props.buildingPart) {
    //   this.props.buildingPart.parts.forEach(h=>{
    //     if (h.buildingType === "component" && (h as BuildingComponent).componentType === "house") {
    //       (h as BuildingHouseUnit).SetLevelHeight(v);
    //     }
    //   })
    // }

    this.props.valueOnChange(v);
    if(this.props.valueUpdate)
      this.props.valueUpdate();
    this.setState({ value: v });
  }


  render = () => {
    return (
      <div className='inputBody'>
        <div className="label-wrap">
          <div className='label'>
            <span className={`font font-12px name `}>{this.props.name}</span>
            <Tooltip place={"right"} arrowOn={false} msg="초깃값 되돌리기" >
              <span onClick={() => { 
                if (this.props.defaultValue && this.props.value !== this.props.defaultValue) {
                  this.changeValue(String(this.props.defaultValue));
                  if (this.props.valueUpdate) {                    
                    this.props.valueUpdate();
                  }
                } 
              }}><Refresh className={`refresh-icon ${this.props.defaultValue && this.props.value !== this.props.defaultValue ? "reset-icon-on" : ""}`}/></span>
            </Tooltip>
          </div>
        </div>
   

        <ClickAwayListener onClickAway={this.onClickAway}>
          <div className={`${(this.state.disable && 'box-disable') || 'box'}`} onFocus={() => this.setState({ focus: true })} onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
           
            <input
              type='number'
              className={`value ${(this.state.disable) && 'disable'}`}
              value={this.state.value}
              onChange={e => this.changeValue(e.target.value)}
              disabled={this.state.disable}
              step='0.0001'
            />
            
            {this.props.unit && <span className='unit'>{this.props.unit}</span>}
            <RemoveIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.decreaseEvent}
            />
            <AddIcon
              className={`icon ${(!this.state.focus && !this.state.hover) && 'hide'} ${(this.state.disable) && 'disable'}`}
              onClick={this.addEvent}
            />
          </div> 
        </ClickAwayListener>
        {
          this.props.defaultValue && this.props.value !== this.props.defaultValue &&
          <div className="label-bottom">
            <Info className="icon active icon-info"></Info><span className="input-changed">변경됨</span>
          </div>
        }
        {/* <BuilditInput
          select={false}
          type="number"
          className=""
          value={this.state.value}
          step={0.0001}

          onChange={(e) => {
            this.changeValue(e.toString())
          }}
          startAdornment={
            <>
              <IconButton className={`icon-btn dec-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`}
                onClick={e => {
                  this.addEvent();
                  //      this.handleValue("DEC") 
                }}
              >
                <DecIcon className="icon" />
              </IconButton>
            <IconButton className={`icon-btn inc-btn ${(!this.state.focus && !this.state.hover) && "visibility-hidden" || ""}`}
              //onClick={e => this.handleValue("INC")}
              onClick={e => {
                this.decreaseEvent();
              }}
              >
                <IncIcon className="icon" />
              </IconButton>
            </>
          }
          endAdornment={
            <>
              <div className="unit" style={{ padding: "0px 10px 0px 0px" }}>㎡</div>
            </>
          }
        //    list={this.props.unitList}
        /> */}

        {/* <div className="bottom-label"><Info className="icon info-icon"/>변경됨</div> */}
      </div>
    )
  }
}