
import React, { Component } from 'react';
import { SceneManager } from './SceneManager';
import { Button, IconButton, Select } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/MapDownloadPreview.scss';
import { LoadingPage } from './LoadingPage';


interface MapDownloadPreviewProps {
  dxf: any;
  showModal: boolean;
  downLink: React.ReactNode;
  clickCnt: number;
  downloadFileName: string[];
  onCompleteDownload: () => void;
  handleShow: () => void;
}

interface MapDownloadPreviewState {
  screenWidth: number;
  screenHeight: number;
  downFileName: string;
  showLoading: boolean;
}

class MapDownloadPreview extends Component<MapDownloadPreviewProps, MapDownloadPreviewState> {
  state: MapDownloadPreviewState = {
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
    downFileName: "파일 이름",
    showLoading: false,
  }
  
  sceneManager = new SceneManager();
  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();
  bbox = new THREE.Box3();
  objectSize = new THREE.Vector3(0);

  componentDidUpdate(p: Readonly<MapDownloadPreviewProps>) {

    if (this.props.showModal !== p.showModal) {
     
      if (this.props.showModal) {    
        this.setState({ showLoading: true });  
        this.sceneManager.renderer.setSize(604, 341);
      }
      else {
        console.log('exit');
        this.setState({ showLoading: false });  
        this.mount!.removeChild(this.sceneManager.canvasElement);
      }
    }
        
    if (this.props.dxf !== p.dxf) {
      if (this.props.downloadFileName[0] !== this.state.downFileName) {
        this.setState({ downFileName: `${this.props.downloadFileName[0]}` });
      }
      if (this.props.dxf.length > 0) {
        this.bbox = new THREE.Box3();
        this.sceneManager.scene.remove(this.polygon2DGroup);
        this.sceneManager.scene.dispose();
        this.polygon2DGroup = new THREE.Group();

        this.props.dxf.forEach((poly: any) => {
          this.polygon2DGroup.add(poly.lineMesh);
          poly.verts.forEach((v: any) => {
            this.bbox.expandByPoint(v);
          });
        })
      }

      let center = new THREE.Vector3(0);
      // let size = new THREE.Vector3(0);
      this.bbox.getCenter(center);
      this.bbox.getSize(this.objectSize);
      let aspect = this.state.screenWidth / this.state.screenHeight;

      let frustumSize = this.objectSize.x / 2 * 1.1;
      if (aspect > this.objectSize.x / this.objectSize.y) {
        let height = this.objectSize.y / 2 * 1.1;
        frustumSize = height * aspect;
      }

      //      console.log(this.props.clickCnt, 'click count')
      if (this.props.clickCnt > 1) {
        frustumSize = this.objectSize.y / aspect;
        if (this.objectSize.x > this.objectSize.y) {
          frustumSize = (this.objectSize.y / 2) * aspect;
        }
        else {
          frustumSize = (this.objectSize.x / 2);// * aspect;
        }
      }
      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
      this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
      this.sceneManager.orthoCamera.zoom = 1;
      this.sceneManager.canvasElement.width = 604;
      this.sceneManager.canvasElement.height = 341;
      this.sceneManager.orthoCamera.updateProjectionMatrix();
      this.sceneManager.addObjectToScene(this.polygon2DGroup);
      this.animate();
      this.setState({ showLoading: false })
    }

    //@ts-ignore
    this.mount!.appendChild(this.sceneManager.canvasElement);
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentWillUnmount = () => {  
    if (this.mount && this.mount.childNodes.length > 0 && this.sceneManager.canvasElement)
      this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  componentDidMount() {
    this.sceneManager.renderer.setSize(604, 341);
  }

  render() {
    return (
      <div className={`MapDownloadPreview ${this.props.showModal ? "" : "hidden"}`}>
        <div className="modal-wrapper">
          <div className="header">
            <div className="top-bar">
              <div className="title">지적도 다운로드</div>
              <IconButton className="icon-button" onClick={() => {
                this.props.handleShow();
              }}><Close className="close-icon"></Close></IconButton>
            </div>

            <LoadingPage   
              //show={true}
              show={this.state.showLoading}
              loadingMsg="지적도를 받아오는 중입니다."
              isModal={true}/>

            <div className="msg">선택 영역을 저장하시겠습니까?</div>
            <div className="title">선택 영역 파일 이름 (.dxf)</div>
            <div className="file-name-wrapper">
              <input 
                type="text"
                className="file-name"
                onChange={(e) => {                   
                  this.setState({downFileName: e.target.value})}
                }
                value={`${this.state.downFileName}`}
              />
              <div className="button-wrapper font font-noto">
                <Button className="btn bg-navy btn-cancel cancle-btn" onClick={() => {
                  this.props.handleShow();
                }}>취소</Button>
                <Button className="btn bg-navy btn-primary save-btn" onClick={() => {
                  this.props.onCompleteDownload();

                  //@ts-ignore
                  this.props.downLink.download = `${this.state.downFileName}.dxf`; 
                  // this.props.handleShow();

                  //@ts-ignore
                  this.props.downLink.click();
           //      this.props.showModal
                }}>저장</Button>
              </div>
            </div>
          </div>
          <section className={this.state.showLoading ? `bg-modal-color` : "display-none"}></section>
          <section className={`scene-wrapper`}>
            <div className="scene">
              <div className='Canvas' style={{ width: '604px', height: '341px' }} ref={(mount) => { this.mount = mount }}></div>
            </div>
          </section>
        </div>
      </div>

    );
  }
}

export default MapDownloadPreview;