import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '@teneleven/protocols-ts-web';
import '../css/BuilditUI.scss';
import { ConverterLayer, siteStruct, Unit, ConverterType, } from './DataTypes';
import { asyncFileRead, blockParsing } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, getRoadLine } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng } from './NaverMapManager';
import { jstsPolygontoWKT, latlng2tm, SceneManager, tm2latlng, } from './SceneManager';
import { Field, FieldType } from './Field';
import { brightenAllField, brightenAllLayer, checkPolygonError, darkenAllField, darkenAllLayer, fieldToTHREEGeo, JSTSGeoToTHREEGeo, setBlockOpacity, wkt2LatLngs } from './CoreAndHouseController';
import { shapeInfoData } from './ProjectDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter, timeScale } from '../Utils';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { ErrorLogCell2, ErrorType } from './ErrorLog';
import { DXFMaker } from './DXFMaker';
import { BlockParsingData, ConverterField } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { ConverterInputBox } from './ConverterInputBox';
import {ReactComponent as BlockIcon} from '../img/icon/blockIcon.svg';
import {ReactComponent as HierarchyIcon} from '../img/icon/hierarchyIcon.svg';
import * as jsts from 'jsts';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'

import wkx from 'wkx'
import Axios from 'axios';
import App from '../App';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { LoadingPage } from './LoadingPage';
import { checkSiteError } from './CheckSiteBlockError';
import { ErrorLogBlock } from './ErrorLogBlock';
import { ChevronLeft, Info, NavigateNext, SpeakerNotes, Warning } from '@material-ui/icons';
import { getCurveErrorCircleField, makePolygonApartLine } from './MeshMaker';
import FieldPartUI from './FieldPartUI';
import Tooltip from '../Tooltip';
import { ErrorList, ErrorItem, SiteError } from './Error';
import {ReactComponent as LevelIcon} from '../img/icon/levelIcon.svg';
import { makePlane } from '../Viewer/MeshManager';
import BlockColorInfo from './BlockColorInfo';

export interface MySiteProps {

}

export interface MySiteState {
  layers: ConverterLayer[];
  handle: number;
  isCad: boolean;
  isCadastral: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  selectLayer: ConverterLayer | null;
  siteArea: number,
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  elevationMax: number,
  elevationMin: number,
  settingID: string,
  userID: string,
  showModal: boolean,
  loadFile: boolean,
  dragCover: boolean,
  fileUnit: Unit,
  showSettingModal: boolean,
  showAutoSetting: boolean,
  showSample: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  showSaveModal: boolean,
  isSaved: boolean,
  loadingPage: boolean,
  clickedField: ConverterField | null, 
  canvasMode: CanvasMode,
  showBlockInfo: boolean,
  field: {
    site: ConverterField[],
    road: ConverterField[],
    roadCenterLine: ConverterField[],
    vacancyInside: ConverterField[],
    vacancyOutside: ConverterField[],
    topography: ConverterField[],
  },
  errorLogs: any[];
  currentLog: string;
  is2D: boolean;
}

enum CanvasMode {
  CAD = 'CAD',
  MAP = 'MAP',
  VIEW_3D = 'VIEW_3D',
}


const earcut = require('earcut');
export class Scene extends Component<MySiteProps, MySiteState> {
  state: MySiteState = {
    layers: [],
    handle: 0,
    isCad: true,
    isCadastral: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    address: '',
    canvasAlpha: 50,
    fileName: '선택된 파일 없음',
    saveName: '',
    showBlock: false,
    selectLayer: null,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    elevationMax: 0,
    elevationMin: 0,
    settingID: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showAutoSetting: false,
    loadFile: false,
    dragCover: true,
    fileUnit: Unit.Millimeters,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    showSample: true,
    showSaveModal: false, // 사업영역 저장 모달
    isSaved: false, // 저장완료
    loadingPage: false,
    clickedField: null,
    canvasMode: CanvasMode.CAD,
    showBlockInfo: false,
    field: {
      site: [],
      road: [],
      roadCenterLine: [],
      vacancyInside: [],
      vacancyOutside: [],
      topography: [],
    },
    errorLogs: [],
    currentLog: 'ALL',
    is2D: true,
  };

  classifyLogs = new Map();
  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  DBTableName = 'platform-buildit-my-site-v2';
  S3BucketName = 'teneleven-platform-my-site-v2';
  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;
  sumAllArea = 0; // 모든 면적 합
  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [], cadastralMap: [] }
  block: ConverterBlock[] = [];
  centerOfRoadWKT = '';

  settingData = new userSettingData(App.tempStage, App.session.email);

  errorLogs: ErrorLogCell2[][] = [];
  loadFileErrorLogs: ErrorLogCell2[] = [];
  globalErrorLogs: ErrorLogCell2[] = [];

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }

  errorList = new ErrorList();
  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
    this.state.field.topography.forEach(topo => {
      this.updateInfoPosition(topo);
    })
  }

  make3DField = () => {

    // const geometry = new THREE.Geometry();
    // geometry.vertices.push(
    //   new THREE.Vector3(-1, -1,  1),  // 0
    //   new THREE.Vector3( 1, -1,  1),  // 1
    //   new THREE.Vector3(-1,  1,  1),  // 2
    //   new THREE.Vector3( 1,  1,  1),  // 3
    //   new THREE.Vector3(-1, -1, -1),  // 4
    //   new THREE.Vector3( 1, -1, -1),  // 5
    //   new THREE.Vector3(-1,  1, -1),  // 6
    //   new THREE.Vector3( 1,  1, -1),  // 7
    // );

    // geometry.faces.push(
    //   // 앞쪽
    //   new THREE.Face3(0, 3, 2),
    //   new THREE.Face3(0, 1, 3),
    //   // 오른쪽
    //   new THREE.Face3(1, 7, 3),
    //   new THREE.Face3(1, 5, 7),
    //   // 뒷쪽
    //   new THREE.Face3(5, 6, 7),
    //   new THREE.Face3(5, 4, 6),
    //   // 왼쪽
    //   new THREE.Face3(4, 2, 6),
    //   new THREE.Face3(4, 0, 2),
    //   // 상단
    //   new THREE.Face3(2, 7, 6),
    //   new THREE.Face3(2, 3, 7),
    //   // 하단
    //   new THREE.Face3(4, 1, 0),
    //   new THREE.Face3(4, 5, 1),
    // );
    // this.polygon3DGroup.add(new THREE.Mesh(geometry,  new THREE.MeshBasicMaterial({ color: new THREE.Color(1, 0, 0) })))

    this.state.field.topography.forEach(topo => {
      const geometry = new THREE.Geometry();
          this.polygon3DGroup.add(fieldToTHREEGeo(topo));

 
      });




    // this.parsingOutput.fields.forEach(field => {
    //   let verts: THREE.Vector3[] = [];
    //   let fieldGeo = new THREE.Geometry();
    //   field.parts.forEach(part => {
    //     const verts = part.polygon.vertices;
    //     for (let i = 0; i < part.polygon.vertices.length - 1; i++) {
    //       fieldGeo.vertices.push(new THREE.Vector3(verts[i].x, 0, verts[i].y).applyMatrix4(field.renderGroup.matrixWorld));
    //     }
    //   })
    //   console.log(fieldGeo, 'field geop');
      
    //   let material = new THREE.MeshPhongMaterial({ color: '#ffffff' });
    //   group.add(new THREE.Mesh(fieldGeo, material));

    //   // l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };

    //   // let verts = [];
    //   // verts.push(new THREE.Vector3(start.x, roomHeight, start.y));
    //   // verts.push(new THREE.Vector3(start.x, roomHeight + wallHeight, start.y));
    // })
   // this.polygon3DGroup.add(group)

  }

  Switch2D3D = (is2D: boolean) => {
    // if (this.state.errorSize > 0) {
    //   this.showModal('알림', ['사업영역 세팅을 완성해 주세요.'], buttonNum.oneButton, () => { });
    //   return;
    // }

    this.sceneManager.switchRenderCamera(!is2D);

    if (is2D) {
      this.polygon3DGroup.children = [];
      // this.setState({
      //   showBlock: false,
      // })

  this.make3DField();
      let bbox = new THREE.Box3();
      bbox.expandByObject(this.polygon3DGroup);
      
      let bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      this.sceneManager.set3DViewerCameraPosition(bboxCenter);
    }

    this.polygon2DGroup.children.forEach(c => {
      c.visible = !is2D;
    })

    this.polygon3DGroup.children.forEach(c => {
      c.visible = is2D;
    })

    this.setState({
      is2D: !is2D,
    })
  }

  updateInfoPosition = (field: ConverterField) => {
    let s = document.querySelector(`.TT${field.uuid}`) as HTMLInputElement;
    if (s) {
      if (this.state.showBlockInfo) {
        s.style.visibility = 'visible';
        field.renderGroup.updateWorldMatrix(true, true);
        let matrixWorld = field.renderGroup.matrixWorld;

        field.parts.forEach(part => {
          // part.renderGroup.updateWorldMatrix(true, true);
         // part.renderGroup.updateWorldMatrix(true, true);


          let coords: jsts.geom.Coordinate[] = [];

          part.polygon.vertices.forEach(v => {
            let newV = v.clone().applyMatrix4(matrixWorld);
            coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
          })
          let geoFac = new jsts.geom.GeometryFactory();
          let linearRing = geoFac.createLinearRing(coords);


          //@ts-ignore
          let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);

          let center = new THREE.Vector3(newPolygon.getCentroid().getX(), newPolygon.getCentroid().getY(), 0);
          let c = this.sceneManager.getScreenPosition(center);

          let rect = s.getBoundingClientRect();
          let min = new THREE.Vector2(c.x - rect.width / 2, c.y - rect.height / 2);
          let max = new THREE.Vector2(c.x + rect.width / 2, c.y + rect.height / 2);

          s.style.top = c.y.toString() + 'px';
          s.style.left = c.x.toString() + 'px';
        })
      }
      else {
        s.style.visibility = 'hidden';
       
      }

    }
  }
  constructor(props:MySiteProps) {
    super(props);
  //  this.changeSaveFileName = this.changeSaveFileName.bind(this);
  }

  componentDidMount = async () => { 
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);
    
    this.sceneManager.SceneInit();
    this.sceneManager.renderer.sortObjects = false;

    
    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);

    this.animate();
    await this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //TODO this.load
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/mySiteblock');
        }
      })
    }

   }

  componentDidUpdate = (previousProps: Readonly<MySiteProps>, previousState: Readonly<MySiteState>) => {
  
    
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    // if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
    //   if (this.state.isCad) {
    //     this.sceneManager.setRendererAlpha(1);
    //   }
    //   else {
    //     this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
    //   }
    // }

    if (previousState.canvasMode !== this.state.canvasMode || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.canvasMode === CanvasMode.CAD || this.state.canvasMode === CanvasMode.VIEW_3D) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(0); //this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    if (this.mount)
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }
  
  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }
  
  setCenterOfRoad = (value: string) => {
    this.centerOfRoadWKT = value;
  }

  onWindowResize = () => { 
    
//    this.mapManager.setMapCenter(this.bbox.);
    this.setState({
      screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0), //- (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 282 : 26) + (!this.state.loadFile ? 26 : 0),
    });
    this.setMapOnRenderGroup();

  }

  dataInitialize = () => {    
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length  > 0) {
        value.splice(0, 1);
      }
    })

    this.polygon2DGroup.children = [];

    this.bbox = new THREE.Box3();
    this.mapManager.clearAllPolygon();
    this.errorList.resetError();

    this.setState({
      layers: [],
      showBlock: false,
      clickedField: null,
      loadFile: false,
      isSaved: false,
      currentLog: "ALL",
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
    })
    this.sumAllArea = 0;
  }

  setMapOnRenderGroup = () => {
    // this.bbox.makeEmpty();
    // this.bbox.setFromObject(this.polygon2DGroup);
    // let center = new THREE.Vector3(0);
    // let size = new THREE.Vector3(0);

    // this.bbox.getCenter(center);
    
    // this.bbox.getSize(size);
    let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
    let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;
    
    const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
    this.mapManager.setMapCenter(centerCoord.x, centerCoord.y);
  }

  loadFilebyFile = async (file: any) => {
    this.setState({ loadingPage: true });

    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    this.dataInitialize();
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }
    
    let parsingError: {errorNameLayers: Array<any>, pointError: Array<any>} = {
      errorNameLayers: [],
      pointError: []
    };
    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit, ConverterType.mySite, this.errorList, parsingError,);
    App.stage !== "prod" && console.log(this.parsingOutput);


    const projectSiteWKT: any[] = [];
    this.parsingOutput.fields.forEach(f => {

      switch (f.typeName) {
        case FieldType.site:
          this.state.field.site.push(f);
          f.renderGroup.updateWorldMatrix(true, true);
          f.parts.forEach(p => {
            if (!p.unused) {
              const polygon = p.getJSTSPolygon(f.renderGroup.matrixWorld);
              const coords = polygon.getCoordinates();
              //@ts-ignore
              if (coords[0] !== coords[coords.length - 1] && polygon._geometries && polygon._geometries.length > 1) {
                this.errorList.addError(new SiteError({
                  type: ErrorType.Error,
                  title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                  msg: `${f.name}의 폭이 충분하지 않습니다.`,
                  id: [f.uuid],
                  targetFields: [f],
                }))
              }
              else {
                projectSiteWKT.push(jstsPolygontoWKT(polygon));
              }
              //              projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(f.renderGroup.matrixWorld)));
            }
          })
          break;
        case FieldType.road:
          this.state.field.road.push(f);
          break;
        case FieldType.centerLineOfRoad:
          this.state.field.roadCenterLine.push(f);
          break;
        case FieldType.vacancyInside:
          this.state.field.vacancyInside.push(f);
          break;
        case FieldType.vacancyOutside:
          this.state.field.vacancyOutside.push(f);
          break;
        case FieldType.topography:
          this.state.field.topography.push(f);
          break;
      }
    })
    this.recalculateArea();
    
// //    let address = await getAddressByProjectSite(projectSiteWKT);
    if (projectSiteWKT.length > 0)
      this.setState({ address: await getAddressByProjectSite(projectSiteWKT) });

    this.parsingOutput.fields.forEach((field) => {
      this.sumAllArea += field.calcArea;
    })




    // 점이 존재
    if (parsingError.pointError.length > 0) {

      parsingError.pointError.forEach(blockName => {
        let error = new SiteError(
          {
            title: `[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.`,
            msg: `${blockName} 의 불필요한 Point 가 자동 삭제 되었습니다.`,
            id: [],//[f.uuid],
            type: ErrorType.Info,
            //targetFields: [f],
          });
        this.errorList.addError(error);
      })
    }

    //* 에러체크
    checkSiteError(this.parsingOutput, this.loadFileErrorLogs, ConverterType.mySite, this.errorList);

    this.setState({
      errorLogs: this.errorList.getError(),
    }) 

    // this.errorLogs.forEach(errors => {
    //   errors.forEach(error=>{ 
    //     if (!this.classifyLogs.get(error.Information)) this.classifyLogs.set(error.Information, [error]);
    //     else this.classifyLogs.set(error.Information, this.classifyLogs.get(error.Information).concat(error));
    //   })
    // })

    { 
      this.parsingOutput.fields.forEach(field => {
        this.polygon2DGroup.add(field.ErrorPolygonGroup);
        // field.parts.forEach(part=>{
        //   this.polygon2DGroup.add(part.errorPolygonGroup)
        // })
      })
  
    }
    this.setState({
      warningSize: this.errorList.getWarningSize(),
      errorSize: this.errorList.getErrorSize(),
      infoSize: this.errorList.getInfoSize(),
    })

    if (this.state.errorSize > 0 || this.state.warningSize > 0 || this.state.infoSize > 0) {
      this.setState({ errorLog : true});
    }
    


    this.parsingOutput.fields.forEach(f => {
      this.polygon2DGroup.add(f.renderGroup);
    });

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3(0);
    let size = new THREE.Vector3(0);

    this.bbox.getCenter(center);
    this.bbox.getSize(size);
    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
    let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;
  
    this.setState({loadingPage: false});

    this.loadFileErrorLogs.forEach(lfel => {
      this.polygon2DGroup.add(lfel.hilightPolygon);
    })

    
    this.setState({
      fileName: file[0].name.split('.dxf')[0],
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
      showBlock: true,
      clickedField: this.state.field.site.length > 0 ? this.state.field.site[0] : null,
      showAutoSetting: true,
      isCad: true,
    }, () => {
      this.onWindowResize();
      this.AutoAdd2Map();
    })
  }


  
  AutoAdd2Map = async () => {

    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      // let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];

      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0);
          // let cPoint = sitePolygon.getCentroid();
          // center.set(cPoint.getX(), cPoint.getY());

          //@ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);

          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad && !f.unused) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
        else if (f.typeName === FieldType.topography) {
          topographyLatLngs = topographyLatLngs.concat(f.getLatLngList());
        }
      })

      this.mapManager.setNaverSitePolygon(SiteLatlngs);
      this.mapManager.setNaverRoadPolygon(RoadLatlngs);
      this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
      this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
      this.mapManager.setNaverTopographyPolygon(topographyLatLngs);

      // let centerInLL = tm2latlng(center);
      // this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      this.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon()) {
          //@ts-ignore
          unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
        }
      })
      
      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })
          this.mapManager.setCenterofRoadPolygon(newC);
        }
        catch (e) {
          App.stage !== "prod" && console.log('auto add error', e)
        }

        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      else {
        this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
      }
      let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
      let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;
      
      const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      this.mapManager.setMapCenter(centerCoord.x, centerCoord.y);

      this.setState({
        siteArea: Number(sitePolygon!.getArea().toFixed(2)),
      })
    }
  }
  

  saveInputBlocks = async () => {
  
  try{
    let fn = this.state.saveName === '' ? 'my_site' : this.state.saveName;
    
    let { globalId: globalID, userId: privateID } = await incrementCounter("my_site_id", this.state.userID);

    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;
    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let date = new Date().toISOString();

    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible && p.selected;
        })
      })
      resolve(0);
    })
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];
    //let centerOfRoadWKT: string[] = []; // ?
    let topographyLines: { polygon: string[], height: number }[] = [];
    let topographyLinesPlatform: { polygon: string[], height: number }[] = [];

    this.parsingOutput.fields.forEach(f => {
      let matrix = f.renderGroup.matrixWorld;
      if (f.typeName === FieldType.site && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.road && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyInside && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
        f.parts.forEach(p => {
          if (!p.unused)
          vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
      }
      else if (f.typeName === FieldType.topography && !f.unused) {
        let topographyWKT: string[] = [];

        f.parts.forEach(p =>{
          if (!p.unused)
            topographyWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
        })
        topographyLinesPlatform.push({ polygon: [topographyWKT[0]], height: f.getHeight() });
        topographyLines.push({ polygon: [...topographyWKT], height: f.getHeight() })
      }
    })

    // if (projectSiteWKT.length === 0) {
    //   this.showModal('알림', ['사업영역이 없습니다.'], buttonNum.oneButton, () => console.log('saving'));
    //   return;
    // }

    // if (roadSiteWKT.length === 0 || this.centerOfRoadWKT.length === 0) {
    //   this.showModal('알림', ['도로영역이사 인접대지 경계선이 있어야 합니다.'], buttonNum.oneButton, () => console.log('saving'));
    //   return;
    // }

    // this.showModal('알림', ['나의 사업영역을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    let captureBbox = new THREE.Box3();
    captureBbox.expandByObject(this.polygon2DGroup);

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    //image 
    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    let shapeInfo: shapeInfoData = {
      boundaryLine: [],
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [],
      road: roadSiteWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      setbackLineApartment: [],
      setbackLineMultiHouse: [],
      setbackLineOfficetel: [],
      setbackLineRowHouse: [],
      skylineCircle: [],
      skylineLine: [],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: topographyLines,//topographyWKT,//[],
    }
    App.stage !== "prod" && console.log(shapeInfo);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');

    let dbItem: siteStruct = {
      stage: App.tempStage,
      global_id: globalID,
      user_id: privateID,
      name: fn,
      email: this.state.userID,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: this.state.roadArea,
      road_site: roadSiteWKT,
      vacancy_outside_area: this.state.vacancyOusideArea,
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: this.state.vacancyInsideArea,
      vacancy_inside: vacancyInsideWKT,
      centerOfRoad: [this.centerOfRoadWKT],
      img_path: `s3://${S3SavePath}/${imageName}`,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      topography_lines: topographyLinesPlatform,//topographyWKT,
      created_at: date,
      modified_at: date,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem, 'dbItem');
    
    App.stage !== "prod" && console.log(dbItem);
      saveDataToDynamoDB(dbItem, this.DBTableName);
      this.setState({ isSaved: true });
    //  this.showModal('알림', ['나의 사업영역을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
      return true;
    }
  catch (e) {
    // this.showModal('알림', ['나의 사업영역을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
    //   App.stage !== "prod" && console.log('save fail', e)
    // }
    // );
    return false;

  }
  
 
  }
  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  recalculateArea = () => {


    let siteArea = 0;
    let roadArea = 0;
    let vacancyInsideArea = 0;
    let vacancyOusideArea = 0;

    let elevationMax = 0;
    let elevationMin = 100000000000;

    
    this.parsingOutput.fields.forEach(f => {
      if (Number(f.calcArea.toFixed(4)) !== Number(f.getArea().toFixed(4))) { // .toFixed(2)
        if (f.parts[0].shape && !f.parts[0].polygon.hasCurve) {
          this.errorList.delErrorByTitle(`[면적 오류] ${f.name}의 실제폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다. 입력 면적으로 반영되오니 원치 않을 시 수정 후 다시 진행해주세요.`);
          

          let error = new SiteError(
            {
              title: `[면적 오류] ${f.name}의 실제폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다. 입력 면적으로 반영되오니 원치 않을 시 수정 후 다시 진행해주세요.`,
              msg: `폴리곤 전체 입력면적: ${f.getArea().toFixed(4)}㎡, 계산 면적: ${f.calcArea.toFixed(4)}㎡, 차이: ${Math.abs(Number(f.getArea().toFixed(4)) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`,
              id: [f.uuid],
              type: ErrorType.Warning,
              targetFields: [f],
            });
          this.errorList.addError(error);
        
          // console.log(this.errorList, '3339');
          
          // this.globalErrorLogs.push(
          //   makeWarningInformation2(`[면적 오류] ${f.name}의 실제폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다. 
          //   입력 면적으로 반영되오니 원치 않을 시 수정 후 다시 진행해주세요.`,
          //   `폴리곤 전체 입력면적: ${f.getArea().toFixed(4)}㎡, 계산 면젹: ${f.calcArea.toFixed(4)}㎡, 차이: ${Math.abs(Number(f.getArea().toFixed(4)) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`, new THREE.Group(),
          //   undefined, undefined, {field: [f], fields: this.parsingOutput.fields}));
        }
      }

      switch (f.typeName) {
        case FieldType.site:
          siteArea += f.getArea();
          break;        
        case FieldType.road:
          roadArea += f.getArea();
          break;
        case FieldType.vacancyInside:
          vacancyInsideArea += f.getArea();
          break;
        case FieldType.vacancyOutside:
          vacancyOusideArea += f.getArea();
          break;
        case FieldType.topography:
          if (elevationMax < f.getHeight()) {
            elevationMax = f.getHeight();
          }
          if (elevationMin > f.getHeight()) {
            elevationMin = f.getHeight();
          }
          break;
        default:
          break;
      }
    })
    this.setState({
      elevationMax,
      elevationMin,
    });

    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();

    this.setState({
//      errorLogs: this.errorList.getError(),
      siteArea: siteArea,
      roadArea: roadArea,
      vacancyInsideArea: vacancyInsideArea,
      vacancyOusideArea: vacancyOusideArea,
      errorSize: error,
      warningSize: warning,
      errorLog: error + warning + info > 0 ? true : false,
    })
  }

  showLayerList = (id: string) => {
    this.setState({
      showBlock: true,
      settingID: id,
    })
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;
      default:
        break;
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // this.mapManager.changeMapType();
        // this.makeDXFFile();
        break;
      case 'b':
        // this.saveInputBlocks(this.state.fileName);
        break;
      default:
        break;
    }
  }

  makeDXFFile = async () => {
    let polygons = this.mapManager.getPolygonInDrawingManager();

    for (let polygon of Object.values(polygons)) {
      let bounds = (polygon as any).bounds;
      let leftTopTM = latlng2tm(new THREE.Vector2(bounds._min.x, bounds._min.y));
      let boxWidth = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._max.x, bounds._min.y)));
      let boxHeight = leftTopTM.distanceTo(latlng2tm(new THREE.Vector2(bounds._min.x, bounds._max.y)));
      // let latlng2tm
      let coords: any[] = [];
      coords.push([leftTopTM.x, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y + boxHeight]);
      coords.push([leftTopTM.x + boxWidth, leftTopTM.y]);
      coords.push([leftTopTM.x, leftTopTM.y]);
      let geoJson = {
        type: 'Polygon',
        coordinates: [coords],
      }
      let wkt = wkx.Geometry.parseGeoJSON(geoJson).toWkt();

      const r = await App.API_Axios.post('/GetFieldAndBuildingsAtWKT', {
        'rectWKT': wkt,
      }, {
        headers: {
          ...App.headers,
        },
      });
      let dxfMaker = new DXFMaker(wkt);
      let data = await Axios.get(r.data.url);
      
      data.data.fieldItems.forEach((field: any) => {
        // field.지목
        if (field.토지이용상황 !== '도로등')
            dxfMaker.AddPolyline(field.geom, field.지번);
        else
          dxfMaker.AddRoadPolyline(field.geom);
      })

      data.data.buildingItems.forEach((building: any) => {
        if (building.geom)
        dxfMaker.AddBuildingPolyline(building.geom);
      });

    //  dxfMaker.DonwloadDXF();
    }
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.MathUtils.degToRad(degree));
  }

  getFrustumSizeWithMapSize = () => {
    let mapProj = this.mapManager.getMapProjectionData();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(width / this.areaBias, aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });

  }

  canvasModeChanged = (canvasMode: CanvasMode) => { //(_isCAD: boolean) => {
    
    switch (canvasMode) {
      case CanvasMode.CAD:
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
        break;
      case CanvasMode.MAP:
        this.polygon3DGroup.children.forEach(child => {
          child.visible = false;
        });

        this.mapManager.getMap().autoResize();
        this.sceneManager.getControl().enableKeys = false;
        this.sceneManager.canvasElement.style.pointerEvents = 'none';
        (this.refs.map as HTMLDivElement).style.visibility = 'visible';
        this.mapManager.createDrawingManager();
        this.resizeCanvasResolution();
        break;
      case CanvasMode.VIEW_3D:
        this.polygon3DGroup.children.forEach(child => {
          child.visible = true;
        });
        this.sceneManager.getControl().enableKeys = true;
        this.sceneManager.canvasElement.style.pointerEvents = '';
        (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
  
        break;
    }
    this.setState({canvasMode:canvasMode})
    // if (_isCAD) {
    //   this.sceneManager.getControl().enableKeys = true;
    //   this.sceneManager.canvasElement.style.pointerEvents = '';
    //   (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    // }
    // else {
    //   this.mapManager.getMap().autoResize();
    //   this.sceneManager.getControl().enableKeys = false;
    //   this.sceneManager.canvasElement.style.pointerEvents = 'none';
    //   (this.refs.map as HTMLDivElement).style.visibility = 'visible';
    //   this.mapManager.createDrawingManager();
    //   this.resizeCanvasResolution();
    // }

    // this.setState({
    //   isCad: _isCAD,
    // });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);
    this.setState({ isCadastral: isCadastral });
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;
    this.setState({
      showModal: !this.state.showModal,
    })
  }

  getCurrentSelected = () => {
    return null;
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    }); 
  }
  
  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  reset = () => {
    this.setState({
      loadFile: false,
    })
    this.dataInitialize();
  }

  // addNewField = (fields: Field[], type: FieldType) => {
  //   let newField = new Field(fields.length.toString(), type);
  //   let uuid = newField.id;

  //   if (type === FieldType.site) {
  //     if (fields.length > 0) {
  //       this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
  //       return;
  //     }
  //   }

  //   fields.push(newField);
  //   this.errorLogs.push(newField.ErrorLogBlock);
  //   this.polygon2DGroup.add(newField.ErrorPolygonGroup);

  //   this.setState({
  //     settingID: uuid,
  //     showBlock: false,
  //   })

  //   this.recalculateArea();
  // }

  render = () => {    
    return (
      <div className="buildit-template">
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <LoadingPage show={this.state.loadingPage} loadingMsg="사업영역 파일을 불러오는 중입니다."/>
        <MySiteBlockSaveModal 
          DBTableName={this.DBTableName}
          converterType={ConverterType.mySite}
          showModal={this.state.showSaveModal}
          userId={this.state.userID}
          onShowSaveModal={() => this.showSaveModal()}
          recalculateArea={() => this.recalculateArea()}
          siteArea={{
            siteArea: `${this.state.siteArea.toFixed(4)}㎡`, // .toFixed(2)
            // roadArea: `${this.state.roadArea.toFixed(4)}㎡`, // .toFixed(2)
            // vacancyOutsideArea: `${this.state.vacancyOusideArea.toFixed(4)}㎡`, // .toFixed(2)
            // vacancyInsideArea: `${this.state.vacancyInsideArea.toFixed(4)}㎡`, // .toFixed(2)
            // elevationCnt: this.parsingOutput.fields.filter(f => f.typeName === FieldType.topography).length,
            address: this.state.address,
            elevationMax: `${this.state.elevationMax}m`,
            elevationMin: `${this.state.elevationMin}m`,
          }}
          fileName={this.state.saveName}
          onSave={async() => {
            const result = await this.saveInputBlocks(); 
            return result;
          }}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
          showModalAlert={this.showModal}
          centerOfRoad={this.setCenterOfRoad}
          
        />
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.mySite}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {this.reset()}}
          isFileOpen={this.state.loadFile}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => {
            if (this.state.warningSize > 0) {
              let msg: string[] = [];
              [...this.loadFileErrorLogs, ...this.globalErrorLogs].forEach(log => {
                if (log.Type === "Warning") msg.push(log.Information);
              });
              this.setState({ showSaveModal: true });
              // this.showModal('알림', [...msg, '계속 진행하시겠습니까?'], buttonNum.twoButton, 
              // () => { 
              //   this.setState({ showSaveModal: true }) });
            }
            else {
              this.setState({ showSaveModal: true });
            }
          }}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}          
        />
        <div className={`MainBody `}>
          <div className="siteInfo">
            {
              this.state.field.topography.map(topo => 
                <div className={`fieldText TT${topo.uuid}`}>
                  <LevelIcon className="icon levelIcon"></LevelIcon>
                  <div className={`font font-14px`}>{topo.getHeight()}</div>
                </div>
              )
            }
          </div>
          <div className='information'>
            {/* .toFixed(2) */}
            <div className='info font font-secondary font-12px'><div className='infoLabel'>대지영역 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.siteArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>인접도로 면적</div><div className='inforValue font font-emphasis font-14px'>{
            this.state.roadArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>공지영역 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.vacancyOusideArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>배치 제한 영역 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.vacancyInsideArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>대지 레벨 최저 높이</div><div className='inforValue font font-emphasis font-14px'>{this.state.elevationMin.toFixed(2)}m</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>대지 레벨 최고 높이</div><div className='inforValue font font-emphasis font-14px'>{this.state.elevationMax.toFixed(2)}m</div></div>
            <div>
            </div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && `${this.state.fileName}.dxf` || '선택된 파일이 없습니다.'}</span>
              {/* <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showBlock: !this.state.showBlock })}>블록 보기</span> */}
            </div>
          </div>

          <div className='RenderView'>
            {/* <div>
              <ul>
                <li>배치 제한 영역</li>
                <li>대지 영역</li>
                <li>인접 경계 라인</li>
                <li>인접 도로</li>
                <li>공지 영역</li>
                <li>고저차</li>
              </ul>
            </div> */}
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.mySite}
                  showSample={this.state.showSample}
                />
                <div ref="map" style={{ width: `${this.state.screenWidth}px`, height: `${this.state.screenHeight}px`, position: "absolute" }} />

                {/* width: "1500px", height: "593px", position: "absolute"}}/>
                                 */}
                                {/* `100%`, height: `100%`, position: "absolute" }} /> */}
              
                {
                  this.state.loadFile &&
                  <Button className="btn bg-navy btn-primary fold-btn" onClick={() => { this.setState({ showBlock: !this.state.showBlock }) }}>
                    {this.state.showBlock ?
                      <NavigateNext className="icon icon-next" /> :
                      <ChevronLeft className="icon icon-next" />
                    }
                  </Button>

                }

                <div className={`toolBar ${!this.state.loadFile && 'hidden'} ${this.state.showBlock ? '' : 'showBlock'}`
                    }>
                  {/* <div className='searchDiv' hidden={this.state.isCad}>
                    <input
                      type='text'
                      className='addressSearch'
                      placeholder='주소 검색'
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      onKeyUp={e => this.textInputKeyUp(e)}
                    />
                    <SearchIcon className='icon' onClick={() => this.mapManager.searchAddressToCoordinate(this.state.address)} />
                  </div> */}
                  <div className='rightButtons'>
                    <div className='switchTabs three-tabs'>
                      <Tooltip place='left' arrowOn={false} msg="캐드 도면으로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.CAD && 'enable'}`} onClick={e => 
                          {
                            this.Switch2D3D(false);
                            this.canvasModeChanged(CanvasMode.CAD)
                          }}>CAD</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.MAP && 'enable'}`} onClick={e => this.canvasModeChanged(CanvasMode.MAP)}>MAP</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="3D 모델로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.VIEW_3D && 'enable'}`} onClick={e => {
                          this.Switch2D3D(true);
                          this.canvasModeChanged(CanvasMode.VIEW_3D);
                          
                          }}>3D VIEW</Button>
                      </Tooltip>
                    </div>

                    <div className={`switchTabs ${this.state.canvasMode !== CanvasMode.MAP && 'hidden'}`}>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`} onClick={() => { this.setState({ showBlockInfo: !this.state.showBlockInfo }) }}>
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>


            <ErrorLogBlock
            //  allBuildings={[...this.houses, ...this.cores]}
            show={this.state.errorLog}
            closeLog={() => {              
              this.setState({ 
                errorLog: !this.state.errorLog,
              })
            }}
            logs={this.errorLogs}
            warningSize={this.state.warningSize}
            errorSize={this.state.errorSize}
            confirmSize={this.state.infoSize}
            classifyLogs={this.classifyLogs}
           errorList={this.state.errorLogs}
            showErrorByType={this.setErrorByType}
            currentLog={
              this.parsingOutput.fields && this.parsingOutput.fields.find(f => f.uuid === this.state.currentLog) ? 
              this.parsingOutput.fields.find(f => f.uuid === this.state.currentLog)!.name : 
              "ALL"
            }
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
            // currentLog={this.state.currentLog === "ALL" ? "ALL"
            
            // : this.parsingOutput.fields.find(f => f.uuid === this.state.currentLog).length ? .name}
            allFields={this.parsingOutput.fields}
          />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header"> 
                  <HierarchyIcon className="icon hierarchy-icon"/>                 
                  <span className="text">Hierarchy</span>
                </div>
                <div className="description">
                  {
                    this.parsingOutput.fields.map(f =>
                      <div
                        id={f.name}
                        className={`font field-item ${this.state.clickedField && f.name === this.state.clickedField.name ? "selected font-special": ""}`}
                        onMouseOver={() => {
                          setBlockOpacity(this.parsingOutput.fields, 0.2);
                          setBlockOpacity([f], 1);
                        }}
                        onMouseLeave={() => {
                          //                  setBlockOpacity(this.parsingOutput.fields, 1);
                          brightenAllField(this.parsingOutput.fields)
                        }}
                        onClick={(e) => {
                          this.setState({
                            clickedField: this.parsingOutput.fields.filter(f => f.name === e.currentTarget.id)[0],
                          });                          
                        }}
                      >
                        <div className="text" 
                        ><BlockIcon className="block-icon"/>{f.name}</div>
                      <Tooltip msg="오류 정보 확인" arrowOn={false} place={"right"}>
                        <Button 
                          id={f.uuid}
                          className={`btn bg-navy btn-primary icon-wrap ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}
                          ${this.state.currentLog === f.uuid ? "active" : ""}
                          `}
                          onClick={(e) => {
                            //  e.stopPropagation();
                            if (this.state.currentLog === f.uuid) {
                              this.setState({
                                errorLogs: this.errorList.getError(),
                                currentLog: "ALL"
                              })
                            }
                            else {
                              this.setState({
                                errorLogs: this.errorList.getErrorById(f.uuid),
                                currentLog: f.uuid
                              })
                            }
                          }}
                        ><SpeakerNotes className={`icon speaker-icon ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}`} /></Button>
                        </Tooltip>
                      </div>
                    )
                  }
                </div>
              </div>       

              <div className={`functionalLayout ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                  <div className="wrapper">
                    <Info className="icon info-icon font font-emphasis" />
                    <span className="font font-primary">Inspector</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="font font-emphasis font-14px selectedBlockName">
                    {
                      (this.state.clickedField &&
                        <div className="title">
                          <span><BlockIcon className="block-icon font font-emphasis block-icon" />{this.state.clickedField.name}</span>
                          <span className="font font-secondary font-12px field-type">{this.state.clickedField.typeName}</span>
                        </div>)
                    }
                </div>
                {
                  this.state.clickedField !== null &&
                  <FieldPartUI
                    field={this.state.clickedField}
                    updateArea={() =>
                      this.recalculateArea()
                    }
                  ></FieldPartUI>
                }
                </div>
                {/* <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.makeDXFFile()}>DXF파일 다운로드</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.saveInputBlocks)}>나의 사업영역 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div> */}
              </div>
            </div>
          </div>

{
  this.state.loadFile && 
  <BlockColorInfo
  fields={this.state.field}
  allFields={this.parsingOutput.fields}

/>

}
          {/* {
            this.state.loadFile &&
            <div className="marker-info">
              <ul>
                <li className="m-b-sm font font-14px field-marker vacancy-inside"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.vacancyInside, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />배치 제한 영역
                </li>
                <li className="m-b-sm font font-14px field-marker site"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.site, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />대지 영역
                </li>
                <li className="m-b-sm font font-14px field-marker center-of-road"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.roadCenterLine, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />인접대지경계선
                </li>
                <li className="m-b-sm font font-14px field-marker road"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.road, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />도로 영역
                </li>
                <li className="m-b-sm font font-14px field-marker vacancy-outside"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.vacancyOutside, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />공지 영역
                </li>
                <li className="m-b-sm font font-14px field-marker elevation"
                  onMouseEnter={() => {
                    setBlockOpacity(this.parsingOutput.fields, 0.4);
                    setBlockOpacity(this.state.field.topography, 1);
                  }}
                  onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" />대지 레벨
                </li>
              </ul>
            </div>
          } */}

        </div>
      </div>
    )
  }
}
