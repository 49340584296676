import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import BuilditTemplate from './BuilditTemplate';
import './css/ProjectResult.scss';
import moment  from 'moment';
import Tabs from './Tabs';
import { Button, Checkbox, Badge, Input, InputAdornment, Table, TableFooter, TableHead, TableRow, TableCell, TableBody, ClickAwayListener, CircularProgress } from '@material-ui/core';
import Tooltip from './Tooltip';
import { Project } from './model/Project';
import AWSModule from './AWSModule';
import App from './App';
import queryString from 'query-string';
import schema from '@teneleven/platform-buildit-schema'; // labmda schema
import ProjectValue from './ProjectValue';
import $ from 'jquery';
import { default as _ } from 'lodash';
import ProjectSummary from './ProjectSummary';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DiscardIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import ToListIcon from '@material-ui/icons/List';
import EditIcon from '@material-ui/icons/Edit'
import FavoriteProjectIconActive from '@material-ui/icons/Star';
import FavoriteProjectIcon from '@material-ui/icons/StarBorder';
import Modal, { ModalOptions } from './Modal';
import { Link } from 'react-router-dom';
import { WhiteSwitch } from './CustomSwitch';
import ProjectTypeTag from './ProjectTypeTag';
import { timeScale } from './Utils';
import S3Image from './S3Image';
import C3Chart from './C3Chart';
import HousingChart from './HousingChart';
import Clipboard from 'react-clipboard.js';
import BuilditInput from './BuilditInput';
import BuilditSelect from './BuilditSelect';
import { ProjectResultCard } from './ProjectResultCard';
import ProjectCardData from './model/ProjectCard';
import ProjectIcon from './ProjectIcon';
import Switch from './Switch';
import DownloadButton from './DownloadButton';
import ScrollTopIcon from '@material-ui/icons/KeyboardCapslock';
import DiscountTag from './DiscountTag';
import { roundTimeData } from './Viewer/DBManager';
import CopyProjectInfo from './CopyProjectInfo';
import  JSZip from 'jszip';
import FileSaver from 'file-saver';
import UpArrowIcon from '@material-ui/icons/KeyboardArrowUp';
import DownArrowIcon from '@material-ui/icons/KeyboardArrowDown';
import { AWSError } from 'aws-sdk';
import ReactText from 'react';
import { base_report_file_path } from './CADConverter/resultDataStruct';
import { CognitoAccessToken } from 'amazon-cognito-identity-js';
import { BuildingStoriesAvgType } from './model/Project'


/*
  TODO: result data 가공부분 함수화하기
  Dynamodb counter 호출부분 module화해서 쓰기
*/

interface errorElement {
  title: string,
  content: JSX.Element,
}

interface FloorSummary{
  value: Function,
  key: string,
  label: string,
  subKey?: string,
  unit?: string,
}
export const floor_summary: Array<FloorSummary> = [
  {
    value: (v:string) => v,
    key: 'name',
    label: '층',
    unit: '층',
  },
  {
    value: (v:string) => v ? v : '-',
    key: 'mainUsage',
    label: '주용도',
  },
  {
    value: (v:number) => v > 0 ? v.toFixed(2) : "-",
    key: 'areaInfoSum',
    label: '전용 면적',
    subKey: 'exclusiveArea',
    unit: '㎡',
  },
  {
    value: (v:number) =>  v > 0 ? v.toFixed(2) : "-",
    key: 'areaInfoSum',
    label: '공용 면적',
    subKey: 'commonArea',
    unit: '㎡',
  },
  {
    value: (v:number) =>  v > 0 ? v.toFixed(2) : "-",
    key: 'areaInfoSum',
    label: '분양 면적',
    subKey: 'salesArea',
    unit: '㎡',
  },
  {
    value: (v:number) =>  v > 0 ? v.toFixed(2) : "-",
    key: 'areaInfoSum',
    label: '서비스 면적',
    subKey: 'serviceArea',
    unit: '㎡',
  },
  {
    value: (v:number) =>  v > 0 ? v.toFixed(2) : "-",
    key: 'areaInfoSum',
    label: '공사용 면적',
    subKey: 'constructionArea',
    unit: '㎡',
  },
  {
    value: (v:number) =>  v > 0 ? v  : "-",
    key: 'households',
    label: '세대수',
    unit: '세대',
  },
]

export const calSum = (item: any, tableData: Array<any>, unit?: string) => {

  let result = 0;
  if (unit=== "㎡" || undefined) {
    result = tableData.map((data: any) => {
      // console.log('data!!', data)
      return item.subKey !== undefined ? Number(data[item.key][item.subKey].toFixed(2)) : data[item.key]
    })
      .reduce((a: number, b: number) => a + b, 0);
  }
  else {
    result = tableData.map((data: any) => {
      return item.subKey !== undefined ? Number((Number(data[item.key][item.subKey].toFixed(2))).toFixed(2)) : data[item.key]
    })
      .reduce((a: number, b: number) => Number(a + b))
  }
  if (item.key === "areaInfoSum") {
    return Number(result) > 0 ? `${result.toFixed(2)}${unit || "㎡"}` : '-';
  }

  return result + '세대';
}

export const convertArea = (v: number, unit?: string): string => {
  // console.log(v, typeof(v));
  if (v.toString() === '-') {
    return '-';
  }

  if (unit === "㎡" || undefined) {
    return Number(v).toFixed(2);
  }

  return Number(Number(v) / (1 / 0.3025)).toFixed(2);
}





const HouseholdsSummaryTable = (props: any) => {

  const householdData = props;
  // 합계
  const calSum = (mapData: any, model: any) => {
    let result = 0;
    if (props.unitState === "㎡" || undefined) {
      result = mapData.map((tableData: any, index: number) => {
        return model.subKey && Number(tableData.areaInfo[model.subKey].toFixed(2))
      }
      ).reduce((a: number, b: number) => a + b, 0)
    }
    else {
      result = mapData.map((tableData: any, index: number) => {
        return model.subKey && Number(Number(Number(tableData.areaInfo[model.subKey].toFixed(2))).toFixed(2)) // index < 5
      }
      ).reduce((a: number, b: number) => a + b, 0)
    }

    return result === 0 ? 0 : result
  }

  const convertArea = (v: number): string => {

    if (v.toString() === '-')
      return '-';

    if (props.unitState === "㎡" || undefined)
      return Number(v).toFixed(2);

    return Number(Number(v) / (1 / 0.3025)).toFixed(2);

  }

  const renderCell = (model: any) => {
    const result = calSum(householdData.props.householdAreaTables, model);

    if (props.unitState === undefined) {
      return result > 0 ? result.toFixed(2) + "㎡" : '-'
    }
    else {
      return result > 0 ? result.toFixed(2) + props.unitState : '-'
    }
  }


  const renderTableBody = (tableData: any, model: any) => {
    if (tableData.areaInfo[model.subKey]) {
      return <TableCell className="celldata" key={model.subKey}>
        {tableData.areaInfo[model.subKey].toFixed(2)}{"㎡"}
      </TableCell>
    } else if (tableData.areaInfo[model.subKey] === 0) {
      return <TableCell className="celldata" key={model.subKey}>{' - '}</TableCell>
    }
  }

  return (
    <div className="floor_content">
      {        
        <Table>
          <TableHead className="table-head">
            <TableRow className="table-row">
              <TableCell className="cellhead">층</TableCell>
              {floor_summary.map((data, index: number) => (
                index > 0 && data.subKey &&
                <TableCell className="cellhead" key={data.label + data.value}>{data.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {
              householdData.props.householdAreaTables.map((tableData: any) => (
                <TableRow className="table-row" key={tableData.name + 'table-body'}>
                  <TableCell className="cellfirst">{tableData.name}</TableCell>{ /* 201호 */}
                  {
                    floor_summary.map((model: any, index: number) =>
                      index > 0 &&
                      renderTableBody(tableData, model)
                    )
                  }
                </TableRow>
              ))
            }
          </TableBody>
          <TableFooter>
            <TableRow className="table-row">
              <TableCell className="totalfirst" >합계</TableCell>
              {
                floor_summary.map((model: any) =>
                  model.subKey &&
                  <TableCell className="totaldata" key={`${model.subKey}합계`}>{renderCell(model)}</TableCell>)
              }
            </TableRow>
          </TableFooter>
        </Table>
        
      }
    </div>
  );
};


export interface ProjectResultProps {}
export interface ProjectResultState {
  results: any[]; // 결과 리스트
  checkedList: boolean[]; // 선택된 리스트
  project: Project; // 해당 프로젝트 정보
  activeResult: number; // 상단 탭 active 번호
  openCompareTooltip: boolean;
  compareTooltipMsg: string;
  resultCompare: boolean; // 전체보기, 비교보기
  openModal: boolean;
  modalOptions?: ModalOptions;
  discount?: boolean; // 할인 여부
  loading: boolean;
  loadingContent?: JSX.Element | string;
  copyURL?: string;
  isGoBack: boolean;
}

export default class ProjectResult extends SessionComponent<ProjectResultProps, ProjectResultState> {
  state: ProjectResultState = {
    results: [],
    checkedList: [],
    project: {},
    activeResult: 0,
    openCompareTooltip: false,
    compareTooltipMsg: "",
    resultCompare: false,
    openModal: false,
    discount: false,
    loading: false,
    copyURL: '',
    isGoBack: true,
  }

  setLoading = (loading: boolean, loadingContent?: JSX.Element | string, callback?: Function) => {
    this.setState({ loading: loading, loadingContent: loadingContent }, () => {
      callback && callback();
    });
  }
  componentWillMount = async () => {

    // const lambda = await new AWSModule("LAMBDA").connect();
    // await lambda.Lambda!.invoke({
    //   FunctionName: "buildit-public-platform-MakePointTransaction-prod",
    //   Payload: JSON.stringify({
    //     'stage': "prod",
    //     'user_id': 'fc10d8de-3be8-44bc-ba37-f72374066aab',
    //     'type': 'point',
    //     'amount': 1500000
    //   })
    // }).promise();

    const ddb = await new AWSModule("DDB").connect();

    const qs = queryString.parse(location.search);
    if (qs && qs.pid) {
      const user_project_id = qs.pid;
      let project_id = 0;

      const r0 = await App.search({
        'table': "platform-buildit-project",
        'query': {
          "query_string" : {
            "query": `stage.keyword:${App.tempStage} AND user_project_id:${user_project_id} AND email.keyword:${App.session.email}`
          }
        }
      });
      const rr0 = r0.data;

      if (rr0.hits.hits && rr0.hits.hits.length > 0) {
        project_id = rr0.hits.hits[0]._source.project_id;
        const r4 = await ddb.Ddb!.get({
          TableName: "platform-buildit-project",
          Key: {
            stage: App.tempStage, //App.stage,
            project_id: project_id
          }
        }).promise();

        if (r4.Item) {
          this.setState({project: r4.Item, discount: this.isDiscountable(r4.Item)}, async () => {
            if (this.state.project.status === "FINISHED") {
              const r1 = await App.search({
                  "table": "engine-buildit-result",
                  "query": `stage.keyword:${App.tempStage} AND base_project_id:${project_id}`,
              });
              const r1_res = r1.data;
              let r2 = undefined;
//              console.log(r1_res.hits.hits,'r1',r1_res.hits.hits.map((r: any) => ({base_uuid: r._source.base_uuid})), )
              if(this.state.project.project_type === "DESIGNER" || this.state.project.project_type === "SITE_PLAN") {
                r2 = await ddb.batchGet({
                  RequestItems: {
                    'engine-buildit-result': {
                      ConsistentRead: true,
                      Keys: r1_res.hits.hits.map((r: any) => ({base_uuid: r._source.base_uuid})),
                    }
                  }
                })
              } else {
                const resultKeys = r1_res.hits.hits.filter((r: any) => r._source.real_report_number !== undefined).map((r: any) => ({ base_uuid: r._source.base_uuid }));
                r2 = await ddb.batchGet({
                  RequestItems: {
                    'engine-buildit-result': {
                      ConsistentRead: true,
                      Keys: resultKeys
                    }
                  }
                })
              }
             
              let results: any[];
              let smallResults;
              if (r2.Responses) {
                const responses: any = r2.Responses['engine-buildit-result'];
                results = responses.sort((a: any, b: any) => {
                  return a.real_report_number - b.real_report_number
                });
                
                App.stage !=="prod" && console.log('results', results);

                const resultSortKeys = results.sort((a: any, b: any) => { return Number(a.base_uuid.split('_')[2]) - Number(b.base_uuid.split('_')[2]) }).map((r: any) => ({ base_uuid: r.base_uuid }))
                // let allResults= [];
                if (this.state.project.building_type === "다세대주택") {
                  const r4 = await ddb.batchGet({
                    RequestItems: {
                      'engine-buildit-result-small': {
                        ConsistentRead: true,
                        Keys: resultSortKeys
                      }
                    }
                  });
                  if (r4.Responses && r4.Responses['engine-buildit-result-small']) {
                    smallResults = r4.Responses['engine-buildit-result-small'];
                    const sortedSmallResults = smallResults.sort((a: any, b: any) => { return Number(a.base_uuid.split('_')[2]) - Number(b.base_uuid.split('_')[2]) })
                    results = sortedSmallResults.map((r: any, i: number) => {                      
                      return results[i] = { ...results[i], ...r }
                    })
                  }
                }                
                this.setState({ results: results.sort((a: any, b: any) => {
                  return a.real_report_number - b.real_report_number
                }), checkedList: results.map(r => { return false }) });
                // console.log('RESULTS', results)
                // console.log('SMALL RESULTS', smallResults)
              }
            }
          });
        }
      }
    }
    this.saveProject = _.debounce(this.saveProject, 1000);
  }

  componentDidUpdate = (pp: Readonly<ProjectResultProps>, ps: Readonly<ProjectResultState>) => {
    
    if (!_.isEqual(ps.activeResult, this.state.activeResult)) {
      if (this.state.activeResult === 0) {
        this.scrollTo(0);
      } else {
        this.scrollTo(240);
      }
    }

    if (this.state.activeResult !== 0 && !this.state.isGoBack) {
      // @ts-ignore
      this.props.history.block((loc, action) => {
        if (action === 'POP' && !this.state.isGoBack) {
          this.setActiveResult(0);
          this.setState({ isGoBack: true });
          return false
        } 
      })
    }
  }

  componentWillUnmount = () => {
    this.state.activeResult = 0
  }

  scrollTo = (top: number) => {
    $('.buildit-template').animate({ scrollTop: top });
  }
  setResults = (results: any) => {
    this.setState({ results: results }, () => {
    });
  }

  render() {

    return (
      <BuilditTemplate footer={true}  {...this.props} loading={this.state.loading} loadingContent={this.state.loadingContent}>
        <div className="ProjectResult">
          <ProjectResultHeader
            activeResult={this.state.activeResult}
            results={this.state.results}
            project={this.state.project}
            setActiveResult={this.setActiveResult}
            setIsGoBack={this.setIsGoBack}
            updateProject={this.updateProject}
            setModal={this.setModal}
            copyProject={this.copyProject}
            discardProject={this.discardProject}
            saveProject={this.saveProject}
            copyURL={this.state.copyURL}
          />
          <ProjectResultBody
            loading={this.state.loading}
            setLoading={this.setLoading}
            activeResult={this.state.activeResult}
            results={this.state.results}
            project={this.state.project}
            setActiveResult={this.setActiveResult}
            setCheckedResult={this.setCheckedResult}
            checkedList={this.state.checkedList}
            openCompareTooltip={this.state.openCompareTooltip}
            compareTooltipMsg={this.state.compareTooltipMsg}
            resultCompare={this.state.resultCompare}
            setResultCompare={this.setResultCompare}
            setCompareTooltip={this.setCompareTooltip}
            setModal={this.setModal}
            setResults={this.setResults}
            updateProject={this.updateProject}
          />
          <Button disableRipple={true} className="bg-navy btn btn-primary scroll-top-btn" onClick={() => this.scrollTo(0)}>
            <ScrollTopIcon className="icon" />
          </Button>
          {
            this.state.activeResult === 0 &&
            <ProjectResultFooter
            setModal={this.setModal}
            copyProject={this.copyProject}
            discardProject={this.discardProject}
            project={this.state.project}
           />
          }

           {
             this.state.openModal &&
            <Modal
              open={this.state.openModal}
              type={this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type || "SIMPLE"}
              positive={this.state.modalOptions && this.state.modalOptions.positive}
              negative={this.state.modalOptions && this.state.modalOptions.negative}
              title={this.state.modalOptions && this.state.modalOptions.title}
              negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
              positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
              secondSelect={this.state.modalOptions && this.state.modalOptions.secondSelect}
              secondSelectTitle={this.state.modalOptions && this.state.modalOptions.secondSelectTitle}
              content={this.state.modalOptions && this.state.modalOptions.content}
              color={this.state.modalOptions && this.state.modalOptions.color || "DARK"}
            />
           }
        </div>
      </BuilditTemplate>
    );
  }

  setCompareTooltip = (on: boolean, msg: string, callback?: Function) => {
    this.setState({
      openCompareTooltip: on,
      compareTooltipMsg: msg
    }, () => {
      if (callback) {
        callback();
      }
    })
  }

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options
    });
  }

  copyProject = () => {
    this.setModal(true, {
      positiveTitle: "복사하기",
      negativeTitle: "취소",
      title: "프로젝트 복사",
      color: "WHITE",
      positive: () => {
        const curProject = _.cloneDeep(this.state.project);
        const newProject: Project = {};
        Object.keys(curProject).forEach(e => {
          if (curProject[e as keyof Project] !== "" && curProject[e as keyof Project] !== undefined) {
            // @ts-ignore
            newProject[e as keyof Project] = curProject[e as keyof Project];
          }
        });
        this.onCreateProject(async (project_id: number, user_project_id: number) => {
          let newProjectName = `${curProject.project_name} - 복사본`;
          newProject.project_name = newProjectName.length > 100 && newProjectName.slice(0, 100) || newProjectName;
          newProject.project_id = project_id;
          newProject.user_project_id = user_project_id;
          newProject.discountable = this.state.discount ? this.state.discount : false;
          newProject.copy_project_id = curProject.user_project_id;
          newProject.favorite = false;
          // newProject.project_type = curProject.project_type === "SITE_PLAN" && "SITE_PLAN" || "UNDEFINED";
          newProject.project_type = curProject.project_type;
          newProject.status = "CONFIG";
          newProject.created_at = new Date().toISOString();
          newProject.modified_at = new Date().toISOString();
          newProject.finished_at = undefined;
          newProject.canceled_at = undefined;
          newProject.deleted_at = undefined;
          newProject.queued_at = undefined;
          newProject.viewed_at = undefined;
          newProject.run_at = undefined;
          newProject.finished_ai = undefined;
          newProject.auto_name = false;

          const lambda = await new AWSModule("LAMBDA").connect();
          const r = await lambda.Lambda!.invoke({
            FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
            Payload: JSON.stringify([
              {
                TableName: "platform-buildit-project",
                Item: {
                  stage: App.tempStage, //App.stage,
                  ...newProject
                }
              }
            ]),
          }).promise();

          const r_res = JSON.parse(r.Payload as string);

          if (r_res.success) {
            // @ts-ignore
            this.props.history.push("/project/list");
          } else {
            console.log(r_res.reason);
          }

          this.setModal(false);
        })
      },
      negative: () => {
        this.setModal(false);
      },
      content:
      <div className="copy-modal">
        <div className="table-wrapper">
          <Table className="table">
            <TableHead className="table-head">
              <TableRow className="table-row">
                <TableCell className="table-cell project-id"><div>번호</div></TableCell>
                <TableCell className="table-cell project-type"><div>타입</div></TableCell>
                <TableCell className="table-cell project-name"><div>프로젝트명</div></TableCell>
                <TableCell className="table-cell project-price"><div>정상가격</div></TableCell>
                <TableCell className="table-cell discount-rate"><div>할인률</div></TableCell>
                <TableCell className="table-cell discount-select"><div>할인적용</div></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              <TableRow className="table-row">
                <TableCell className="table-cell project-id"><div title={App.stage !== "prod" ? (this.state.project.project_id || 0).toString() : ""}>{this.state.project.user_project_id}</div></TableCell>
                <TableCell className="table-cell project-type">
                {
                  <ProjectTypeTag background="DARK" type={this.state.project.project_type!} />
                }
                </TableCell>
                <TableCell className="table-cell project-name">
                  <div style={{height: "auto", display: "flex", flexDirection: "row", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: "300px"}}>
                    {this.state.project.discountable && <div style={{marginRight: "5px"}}>{ <DiscountTag /> }</div>}
                    <div style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", width: this.state.project.discountable ? "250px" : "300px"}}>{this.state.project.project_name}</div>
                  </div>
                </TableCell>
                <TableCell className="table-cell project-price"><div>{`${this.getPaymentPoint(this.state.project).toLocaleString()}P`}</div></TableCell>
                <TableCell className="table-cell discount-rate"><div>{this.isDiscountable(this.state.project) ? "90%" : "-"}</div></TableCell>
                <TableCell className="table-cell discount-select">
                  <div>
                  {
                    this.isDiscountable(this.state.project) &&
                    <Switch
                      className="select-switch"
                      name="적용"
                      checked={this.state.discount}
                      onClick={(checked) => {
                        this.setState({discount: !this.state.discount}, this.copyProject);
                      }}
                    />
                  }
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="info-wrap">
          <div className="info">* 완료된 프로젝트는 할인 적용이 가능합니다.</div>
          <div className="info">* 할인 적용시 대지영역은 수정이 불가능합니다.</div>
          <div className="info">*&nbsp;할인률은 정상 가격 기준입니다.</div>
        </div>
      </div>,
      type: "BIG"
    })
  }

  getPaymentPoint = (project: Project) : number => {
    const base = 1000;
    if (project.project_type === "AI") {
      return base + Math.floor(project.project_site_area! * 1);
    } else if (project.project_type === "DESIGNER" || project.project_type === "SITE_PLAN") {
      return base + Math.floor(project.project_site_area! * 0.5);
    }
    return 0;
  }

  isDiscountable = (project: Project) : boolean => {
    if (project.project_type === "AI" || project.project_type === "DESIGNER" || project.project_type === "SITE_PLAN") {
      if (project.status === "FINISHED") {
        return true;
      } else if (project.discountable === true) {
        return true;
      }
    }

    return false;
  }

  discardProject = () => {
    this.setModal(true, {
      color: "DARK",
      positive: async () => {
        const lambda = await new AWSModule("LAMBDA").connect();
        const r = await lambda.Lambda!.invoke({
          FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
          Payload: JSON.stringify([{
            TableName: "platform-buildit-project",
            Item: {
              stage: App.tempStage, 
              ...this.state.project,
              discarded: true,
              modified_at: new Date().toISOString()
            }
          }]),
        }).promise();

        const r_res = JSON.parse(r.Payload as string);
        if (r_res.success) {
          // @ts-ignore
          this.props.history.push('/project/list');
        } else {
          console.log(r_res.reason);
        }

        this.setModal(false);
      },
      negative: () => {
        this.setModal(false);
      },
      content: <div>
        삭제 하시겠습니까?
      </div>
    })
  }

  setResultCompare = (isCompare: boolean) => {
    if (isCompare) {
      if (this.state.checkedList.filter(r => r === true).length < 2) {
        this.setCompareTooltip(true, "2개 이상의 프로젝트를 선택해주세요", () => {
          setTimeout(() => {
            this.setCompareTooltip(false, "");
          }, 1500)
        })
      } else {
        this.setState({ resultCompare: isCompare })
      }
    } else {
      this.setState({ resultCompare: isCompare });
    }
  }

  updateProject = async (update: Project, callback?: Function) => {
    const newProject = new Project({
      ..._.cloneDeep(this.state.project),
      ..._.cloneDeep(update),
      modified_at: new Date().toISOString()
    });

    this.setState({
      project: newProject
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  saveProject = async (project: Project) => {
    const saveProject: Project = {};
    Object.keys(project).forEach(e => {
      if (project[e as keyof Project] !== "" && project[e as keyof Project] !== undefined) {
        // @ts-ignore
        saveProject[e as keyof Project] = project[e as keyof Project];
      }
    });

    const lambda = await new AWSModule("LAMBDA").connect();
    const r = await lambda.Lambda!.invoke({
      FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
      Payload: JSON.stringify([{
        TableName: "platform-buildit-project",
        Item: {
          stage: App.tempStage,
          ...saveProject,
        }
      }])
    }).promise();
  }

  setActiveResult = (n: number) => {
    this.setState({ activeResult: n}, () => {
      window.scrollTo(0, 0);
    });
  }

  setIsGoBack = (n: number) => {
    if (n !== 0) {
      this.setState({ isGoBack: false })
    }
  }

  setCheckedResult = (reportId: number) => {
      const n = this.state.results.findIndex(e => e.real_report_number === reportId);
      if (this.state.checkedList[n] === false) {
        if (this.state.checkedList.filter(e => e === true).length > 3) {
          this.setCompareTooltip(true, "프로젝트의 비교는 최대 4개까지 가능합니다", () => {
            setTimeout(() => {
              this.setCompareTooltip(false, "");
            }, 1500);
          })
          return;
        }
        this.setState({
          checkedList: this.state.checkedList.map((r, i) => { return i === n ? !r : r })
        }, () => {
          this.setCompareTooltip(true, "비교할 프로젝트가 선택되었습니다", () => {
            setTimeout(() => {
              this.setCompareTooltip(false, "");
            }, 1500);
          })
        });
      } else {
        this.setState({ checkedList: this.state.checkedList.map((r, i) => { return i === n ? !r : r }) });
      }
  }
  onCreateProject = async(callback: Function) => {
    const ddb = await new AWSModule("DDB").connect();
    const project_id = await ddb.Ddb!.update({
      TableName: 'platform-buildit-counter',
      Key: { 'name': `${App.tempStage}/project_id` },
      ExpressionAttributeNames: { '#counter' : 'counter' },
      ExpressionAttributeValues: { ':counter' : 1 },
      UpdateExpression: 'SET #counter = #counter + :counter',
      ReturnValues: 'UPDATED_OLD',
    }).promise();

    const is_exist_user_project_id = await ddb.Ddb!.get({
      TableName: 'platform-buildit-counter',
      Key: {
        name: `${App.tempStage}/project_id/${App.session.email}`
      }
    }).promise();

    // counter db update at creating first project
    if (is_exist_user_project_id.Item === undefined) {
      await ddb.Ddb!.put({
        TableName: "platform-buildit-counter",
        Item: {
          name: `${App.tempStage}/project_id/${App.session.email}`,
          counter: 1
         }
      }).promise();
    }

    const user_project_id = await ddb.Ddb!.update({
      TableName: 'platform-buildit-counter',
      Key: { 'name': `${App.tempStage}/project_id/${App.session.email}` },
      ExpressionAttributeNames: { '#counter' : 'counter' },
      ExpressionAttributeValues: { ':counter' : 1 },
      UpdateExpression: 'SET #counter = #counter + :counter',
      ReturnValues: 'UPDATED_OLD',
    }).promise();

    if (!project_id.Attributes || !user_project_id.Attributes) {
      // error
      return;
    }

    callback(Number(project_id.Attributes.counter), Number(user_project_id.Attributes.counter));
  }
}

export interface ProjectResultHeaderProps {
  results: any[];
  project: Project;
  activeResult: number;
  copyURL?: string;
  updateProject: (update: Project, callback?: Function) => void;
  saveProject: (project: Project) => void;
  setActiveResult: (n: number) => void;
  setIsGoBack: (n: number) => void;
  setModal: (open: boolean, options: ModalOptions) => void;
  copyProject: () => void;
  discardProject: () => void;
}
export interface ProjectResultHeaderState {
  projectNameFocus: boolean;
  editProjectDescription: boolean;
  onSave: boolean;
  saveTooltipMsg: string;
}

export class ProjectResultHeader extends Component<ProjectResultHeaderProps, ProjectResultHeaderState> {
  state: ProjectResultHeaderState = {
    projectNameFocus: false,
    editProjectDescription: false,
    onSave: false,
    saveTooltipMsg: "",
  }

  componentDidUpdate = (pp: Readonly<ProjectResultHeaderProps>, ps: Readonly<ProjectResultHeaderState>) => {
  }

  render() {
    return (
      <div className="ProjectResultHeader">
        <div className="wrapper">
          <div className="breadcrumb">
            HOME > 프로젝트 목록 > 프로젝트 결과
          </div>
          <div className="page-title bg-navy font font-primary">
          { this.props.project.discountable && <DiscountTag className="m-r-md" /> }
          {
            // (this.props.project.status === "CONFIG" || this.props.project.status === "FINISHED" || this.props.project.status === "FAILED") &&
              // 프로젝트 제목 수정 기존 조건 CONFIG일때만 가능하던 것 조건 풀어서 모든 상태에서 가능하도록 변경. (BUILDIT-2099)
            <Fragment>
            {
              this.state.projectNameFocus &&
              <ClickAwayListener onClickAway={() => {
                if (this.state.projectNameFocus) {
                  if (this.props.project.project_name!.length === 0) {
                    this.props.updateProject({ project_name: "제목없는 프로젝트" }, async() => await this.props.saveProject(this.props.project))
                  } else {
                    this.props.updateProject({ project_name: this.props.project.project_name },async() => await this.props.saveProject(this.props.project))
                  }
                }
                this.setState({projectNameFocus: false })
              }}>
                <BuilditInput
                  className={`project-name ${this.state.projectNameFocus && "focus" || ""}`}
                  placeholder="프로젝트 제목"
                  value={this.props.project.project_name}
                  onChange={e => {
                    this.props.updateProject({ project_name: e as string })
                  }}
                  onKeyUp={e => {
                    if (e.keyCode === 13) {
                      if (this.props.project.project_name!.length === 0) {
                        this.props.updateProject({ project_name: "제목없는 프로젝트" }, async() => await this.props.saveProject(this.props.project))
                      } else {
                        this.props.updateProject({ project_name: this.props.project.project_name },async() => await this.props.saveProject(this.props.project))
                      }
                      this.setState({ projectNameFocus: false })
                    }
                  }}
                  length={100}
                  type="text"
                  endAdornment={
                    <InputAdornment position="end">
                      <Button disableRipple={true} className="edit-btn" title={this.state.saveTooltipMsg}>
                        {
                          !this.state.projectNameFocus && <EditIcon className="icon" />
                        }
                        {
                          this.state.projectNameFocus &&
                          this.state.onSave &&
                          <CircularProgress className="icon" />
                        }
                      </Button>
                    </InputAdornment>
                  }
                />

              </ClickAwayListener>
              ||
              <Tooltip id="ProjectResult-ProjectName" msg="프로젝트명 수정">
                <div className="project-name-edit" onClick={() => this.setState({ projectNameFocus: true })}>
                  <div className="project-name">{this.props.project.project_name}</div>
                  <EditIcon className="icon m-l-md" />
                </div>
              </Tooltip>
            }
            </Fragment>
            // ||
            // <div className="project-name-cant-edit">
            //   <div className="project-name">{this.props.project.project_name}</div>
            // </div>
          }
            <div className="icon-group">
              {
                (this.props.project.status === "WAITING" || this.props.project.status === "RUNNING") &&
                <Tooltip msg="분석이 완료되기 전에 즐겨찾기 기능을 사용하실 수 없습니다." id="ProjectResult-Favorite" eventType="mouseover"  className="m-r-sm">
                  <Button
                    disabled={true}
                    onClick={e => {
                      this.props.updateProject({ favorite: !this.props.project.favorite }, async () => await this.props.saveProject(this.props.project));
                    }} disableRipple={true} className="btn favorite-btn disabled"
                  >
                    <FavoriteProjectIconActive className="icon" />
                  </Button>
                </Tooltip>
                ||
                <Tooltip id="ProjectResult-Favorite"
                eventType="mouseover"
                msg="즐겨찾기"
                >
                  <Button disableRipple={true} className="btn favorite-btn"
                    onClick={e => {
                      this.props.updateProject({ favorite: !this.props.project.favorite }, async () => await this.props.saveProject(this.props.project));
                    }}
                  >
                    {this.props.project.favorite && <FavoriteProjectIconActive className="icon active" /> || <FavoriteProjectIcon className="icon" />}
                  </Button>
                </Tooltip>
              }

              {
                (this.props.project.status === "WAITING" || this.props.project.status === "RUNNING") &&
                <Tooltip msg="분석이 완료되기 전에 삭제하실 수 없습니다." id="ProjectResult-Discard" eventType="mouseover"  className="m-r-sm">
                  <Button
                    disabled={true}
                    onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel disabled"
                  >
                    <DiscardIcon className="icon" />
                  </Button>
                </Tooltip> ||
                <Tooltip msg="삭제하기" id="ProjectResult-Discard" className="m-r-sm">
                  <Button
                    onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel"
                  >
                    <DiscardIcon className="icon" />
                  </Button>
                </Tooltip>
              }
              {
                this.props.project.project_type === "CAL" &&
                <Tooltip msg="CAL 프로젝트는 복사할 수 없습니다" id="ProjectResult-Copy" className="m-r-sm">
                  <Button disabled onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary disabled"><CopyIcon className="icon" /></Button>
                </Tooltip> ||
                <Tooltip msg="복사하기" id="ProjectResult-Copy" className="m-r-sm">
                  <Button onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary"><CopyIcon className="icon" /></Button>
                </Tooltip>
              }
              <Link to="/project/list">
                <Tooltip msg="목록으로" id="ProjectResult-List">
                  <Button disableRipple={true} className="bg-navy btn btn-primary"><ToListIcon className="icon" /></Button>
                </Tooltip>
              </Link>
            </div>
          </div>
          {
            this.props.project.copy_project_id !== undefined && this.props.project.project_type !== "CAL" &&
            <CopyProjectInfo copyProjectId={this.props.project.copy_project_id} showArrowIcon={true}></CopyProjectInfo>
          }
          <div className="project-info">
            <div className="id-type-status">
              <div className="user-project-id" title={App.stage !== "prod" ? (this.props.project.project_id || 0).toString() : ""}>{this.props.project.user_project_id}</div>
              <div className="project-type">
                <ProjectTypeTag background="DARK" type={this.props.project.project_type!} projectId={this.props.project.project_id || 0} />
              </div>
              <div className="project-status">
                <ProjectIcon status={this.props.project.status} onText={true} />
              </div>
              <div className="date m-l-md">
                <div className="created-at">생성일 {moment(this.props.project.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div className="modified-at">최근 수정일 {moment(this.props.project.modified_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                {
                  this.props.project.run_at &&
                  <div className="run-at">분석 시작일 {moment(this.props.project.run_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                }
                {
                  this.props.project.finished_at &&
                  <div className="finished-at">분석 완료일 {moment(this.props.project.finished_at).format('YYYY-MM-DD HH:mm:ss')}</div>
                }
              </div>
            </div>
            <div className="project-description">
              {
                // (this.props.project.status === "CONFIG" || this.props.project.status === "FINISHED" || this.props.project.status === "FAILED") &&
                <Fragment>
                  {
                    <Tooltip msg={"프로젝트 메모"} on={!this.state.editProjectDescription ? undefined : false}>
                      <ClickAwayListener onClickAway={() => {
                        if (this.state.editProjectDescription) {
                          this.props.updateProject({ project_explanation: this.props.project.project_explanation }, async () => await this.props.saveProject(this.props.project))
                          this.setState({ editProjectDescription: false })
                        }
                      }}>
                        <BuilditInput
                          onClick={() => {
                            this.setState({ editProjectDescription: true });
                          }}
                          key="project-description"
                          className={`project-description ${this.state.editProjectDescription === false && "no-edit"}`}
                          multiline={true}
                          placeholder="프로젝트에 대한 내용을 정리하세요"
                          value={this.props.project.project_explanation}
                          onChange={e => {
                            this.props.updateProject({ project_explanation: e as string });
                          }}
                          rows={this.props.project.project_explanation !== undefined ? this.props.project.project_explanation.split(/\r|r\n|\n/).length : 1}
                          type="text"
                          length={10000}
                        />
                      </ClickAwayListener>
                    </Tooltip>
                  }
                  {/* {
              this.state.editProjectDescription && !this.state.onSave && <SaveIcon className="icon save" />
            }
            {
              this.state.editProjectDescription && this.state.onSave && <CircularProgress className="icon on-save" />
            } */}
                  {
                    !this.state.editProjectDescription && <EditIcon className="icon edit" />
                  }
                </Fragment>
                ||
                <div className="project-description cant-edit">
                  {this.props.project.project_explanation}
                </div>
              }
            </div>
          </div>
          {
            this.props.project.status === "FINISHED" && (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL") &&
            <div className="result-reports">
              <Tabs className="tabs bg-navy">
                <Button
                disableRipple={true}
                className={`img-btn tab bg-navy tab-primary ${this.props.activeResult === 0 && "active" || ""}`}
                onClick={e => this.props.setActiveResult(0)}
                disabled={this.props.activeResult === 0}
                >
                  전체 결과
                </Button>
                {
                  this.props.results.map((r, i) => {
                    if (i < this.props.results.length) {
                      return (
                        <Button
                          disableRipple={true}
                          className={`img-btn tab bg-navy tab-primary
                            ${this.props.activeResult === this.props.results[i].real_report_number && "active" || ""}`
                          }
                          onClick={(e) => {
                            this.props.setActiveResult(this.props.results[i].real_report_number);
                            this.props.setIsGoBack(this.props.results[i].real_report_number);
                          }}
                          >
                          {`${this.props.results[i].real_report_number}번 결과`}
                        </Button>
                      )
                    } else {
                      return <Button className={`bg-navy tab tab-primary visibility-hidden`} disabled={true}></Button>
                    }
                  })
                }
              </Tabs>
            </div>
          }
        </div>
      </div>
    )
  }
}

export interface ProjectResultBodyProps {
  loading: boolean;
  results: any[];
  project: Project;
  activeResult: number;
  checkedList: boolean[];
  openCompareTooltip: boolean;
  compareTooltipMsg: string;
  resultCompare: boolean;
  setActiveResult: (n: number) => void;
  setCheckedResult: (n: number) => void;
  setResultCompare: (isCompare: boolean) => void;
  setCompareTooltip: (on: boolean, msg: string) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  setLoading: (loading: boolean, loadingContent?: JSX.Element | string, callback?: Function) => void;
  setResults: (results: any) => void;
  updateProject: (update: Project, callback?: Function) => void;
}
export interface ProjectResultBodyState {
  smallImgActive: number;
  resultChart: any[];
  resultChartCompare: any[];
  resultChartElement: any[];
  // designerCode: string;
  openDesginerTooltip: boolean;
  onDownload: boolean,
  errorList: errorElement[];
  moreview?: boolean;
  resultSort: { label: string, value: string };
  openTooltip: boolean;
  building_stories_avg_type: BuildingStoriesAvgType | undefined;
}
export class ProjectResultBody extends Component<ProjectResultBodyProps, ProjectResultBodyState> {

  state: ProjectResultBodyState = {
    resultChart: [],
    resultChartCompare: [],
    resultChartElement: [],
    smallImgActive: -1,
    // designerCode: "",
    openDesginerTooltip: false,
    onDownload: false,
    errorList: [],
    moreview: false,
    resultSort: { label: '결과 번호 순', value: 'real_report_number' },
    openTooltip: false,
    building_stories_avg_type: this.props.project.building_stories_avg_type,
  }
  setTimeout1: any;
  setTimeout2: any;
  setTimeout3: any;
  setTimeout4: any;

  mapViewErrorHandler = () => {
    if (!this.state.errorList.find(e => {
      return e.title === "MapView"
    })) {
      const tmp = _.cloneDeep(this.state.errorList);
      tmp.push({
        title: "MapView",
        content: <span>Polygon Error</span>
      });
      this.setState({errorList: tmp});
    }

  }

  getChartData = (type: any, resultVal: number) => {
    let chartVal = 0;
    if (type === "용적률") {
      const setVal = this.props.project!.floor_area_ratio!;
      const dif = setVal - resultVal;
      if (dif >= 0) {
        chartVal = resultVal / setVal * 100;
      } else {
        chartVal = (setVal - Math.abs(dif / 2)) / setVal * 100;
      }
    } else if (type === "건폐율") {
      const setVal = this.props.project!.building_land_ratio!;
      const dif = setVal - resultVal;
      if (dif >= 0) {
        chartVal = resultVal / setVal * 100;
      } else {
        chartVal = (setVal - Math.abs(dif / 2)) / setVal * 100;
      }
    } else if (type === "세대수") {
      const max = Math.max.apply(null, this.props.results.map(r => r.loc_total_household));
      const min = Math.min.apply(null, this.props.results.map(r => r.loc_total_household));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "평균 조망 점수") {
      const max = Math.max.apply(null, this.props.results.map(r => r.view_point_avg));
      const min = Math.min.apply(null, this.props.results.map(r => r.view_point_avg));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "2시간 미만 일조비율") {
      chartVal = resultVal;
    } else if (type === "평균 일조 시간") {
      const max = Math.max.apply(null, this.props.results.map(r => r.daylight_hours_avg));
      const min = Math.min.apply(null, this.props.results.map(r => r.daylight_hours_avg));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    } else if (type === "최빈 일조 시간") {
      const max = Math.max.apply(null, this.props.results.map(r => r.daylight_hours_mode));
      const min = Math.min.apply(null, this.props.results.map(r => r.daylight_hours_mode));
      if (max - min === 0) {
        chartVal = max !== 0 ? 100 : 1;
      } else {
        chartVal = resultVal !== 0 ? resultVal / max * 100 : 1;
      }
    }

    return chartVal === 0 ? 1 : chartVal;
  }

  setChartData = () => {
    this.setState({
      resultChart: [],
      resultChartElement: [],
      resultChartCompare: [],
    }, () => {

    });

    let resultChart: any[] = [];
    let resultChartCompare: any[] = [];
    let resultChartElement: any[] = [];

    if (this.props.project.building_type === "다세대주택") {
      resultChart.push(['x', '용적률', '건폐율', '세대수']);
      resultChartCompare.push(['x', '용적률', '건폐율']);
      this.props.results.map((r, i) => {
        resultChart.push(
          [
            `${this.props.results[i].real_report_number}번 결과`,
            this.getChartData('용적률', r.loc_floor_area_ratio),
            this.getChartData('건폐율', r.loc_building_land_ratio),
            this.getChartData('세대수', r.loc_total_household),
          ]
        )
      })
      console.log('results', this.props.results)
      this.setState({ resultChart: _.cloneDeep(resultChart)});

      this.props.results.map((r, i) => {
        this.props.checkedList[i] &&
        resultChartCompare.push(
          [
            `${this.props.results[i].real_report_number}번 결과`,
            this.getChartData('용적률', r.loc_floor_area_ratio),
            this.getChartData('건폐율', r.loc_building_land_ratio),
            this.getChartData('세대수', r.loc_total_household),
          ]
        )
      })
      this.setState({ resultChartCompare: _.cloneDeep(resultChartCompare)});
    } else {
      resultChart.push(['x', '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수']);
      resultChartCompare.push(['x', '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수']);
      this.props.results.map((r, i) => {
        resultChart.push(
          [
            `${this.props.results[i].real_report_number}번 결과`,
            this.getChartData('용적률', r.loc_floor_area_ratio),
            this.getChartData('건폐율', r.loc_building_land_ratio),
            this.getChartData('세대수', r.loc_total_household),
            this.getChartData('평균 일조 시간', r.daylight_hours_avg),
            this.getChartData('최빈 일조 시간', r.daylight_hours_mode),
            this.getChartData('2시간 미만 일조비율', r.daylight_hours_proportion_less_n_hours && r.daylight_hours_proportion_less_n_hours.length > 0 ? r.daylight_hours_proportion_less_n_hours[1] : 0),
            this.getChartData('평균 조망 점수', r.view_point_avg),
          ]
        )
      })

      this.setState({ resultChart: _.cloneDeep(resultChart)});

      this.props.results.map((r, i) => {
        this.props.checkedList[i] &&
        resultChartCompare.push(
          [
            `${this.props.results[i].real_report_number}번 결과`,
            this.getChartData('용적률', r.loc_floor_area_ratio),
            this.getChartData('건폐율', r.loc_building_land_ratio),
            this.getChartData('세대수', r.loc_total_household),
            this.getChartData('평균 일조 시간', r.daylight_hours_avg),
            this.getChartData('최빈 일조 시간', r.daylight_hours_mode),
            this.getChartData('2시간 미만 일조비율', r.daylight_hours_proportion_less_n_hours && r.daylight_hours_proportion_less_n_hours.length > 0 ? r.daylight_hours_proportion_less_n_hours[1] : 0),
            this.getChartData('평균 조망 점수', r.view_point_avg),
          ]
        )
      })
      this.setState({ resultChartCompare: _.cloneDeep(resultChartCompare)});
    }
  }

  componentDidUpdate = (pp: Readonly<ProjectResultBodyProps>, ps:Readonly<ProjectResultBodyState>) => {
    if (_.isEqual(pp, this.props) === false && this.props.project.project_type !== "DESIGNER") {
      this.setChartData();
    }

    // if(_.isEqual(ps.designerCode, this.state.designerCode) === false) {
    //   // @ts-ignore
    //   navigator.clipboard.writeText(this.state.designerCode);
    //   this.copyKeyCode();
    // }
  }

  componentWillMount = () => {
    this.setChartData();
  }

  getChartOptions = (cdata: Readonly<any>, results: Readonly<any>) => {

    // if (this.props.project.building_type! === "다세대주택") {
    //   cdata.map((r: any[]) => r.splice(4, 4))
    // }

    const resultChartColors = {
      '용적률': '#5aa2a6',
      '건폐율': '#6e748e',
      '세대수': '#8a6cc9',
      '2시간 미만 일조비율': '#eed784',
      '평균 일조 시간': '#e8afac',
      '최빈 일조 시간': '#c79477',
      '평균 조망 점수': '#b5ffe7',
    }
    const group = [ '용적률', '건폐율', '세대수', '평균 일조 시간', '최빈 일조 시간', '2시간 미만 일조비율', '평균 조망 점수'];


    const defaultOptions = {
      data: {
        x: 'x',
        rows: cdata,
        groups: [ group ],
        colors: resultChartColors,
        order: null,
      },
      type: 'bar',
      config: {
        size: {
          height: 50 + 25 * cdata.length,
        },
        tooltip: {
          order: null,
          format: {
            title: (index: any) => {
              return cdata[index + 1][0];
            },
            value: (val: any, ratio: any, id: any, index: any) => {
              let format = val;
              const reportId = Number(cdata[index + 1][0].split('번')[0]);
              const resultIndex = this.props.results.findIndex(e => e.real_report_number === reportId);
              
              if (id === "용적률") {
                format = results[resultIndex]!.loc_floor_area_ratio!.toFixed(2) + "%";
              } else if (id === "건폐율") {
                format = results[resultIndex]!.loc_building_land_ratio!.toFixed(2) + "%";
              } else if (id === "세대수") {
                format = results[resultIndex].loc_total_household! + "세대";
              } else if (id === "평균 일조 시간") {
                format = timeScale(results[resultIndex].daylight_hours_avg);
              } else if (id === "최빈 일조 시간") {
                format = timeScale(results[resultIndex].daylight_hours_mode);
              } else if (id === "2시간 미만 일조비율") {
                if (results[resultIndex].daylight_hours_proportion_less_n_hours) {
                  format = (results[resultIndex].daylight_hours_proportion_less_n_hours.length > 0 ? results[resultIndex].daylight_hours_proportion_less_n_hours[1].toFixed(2) : 0) + "%";
                }
              } else if (id === "평균 조망 점수") {
                format = results[resultIndex].view_point_avg + "점";
              }
              return format;
            }
          }
        },
        legend: {
          item: {
            onmouseover: (d: any) => {
              let resultChartElement = [];
              resultChartElement.push(['x', d]);

              cdata.map((r: any, i: number) => {
                if (i !== 0) {
                  resultChartElement.push([
                    r[0].split('번')[0],
                    cdata[i][cdata[0].findIndex((e: any) => e === d)]
                  ]);
                }
              });
              this.setState({ resultChartElement: resultChartElement })
            }
          }
        },
        bar: {
          width: 5
        },
        axis: {
          x: {
            type: 'category'
          },
          rotated: true,
        },
        padding: {
          left: 55,
        },
      }
    }

    return defaultOptions;
  }

  sortProjectResult = (value: any) => {
    const newResults = _.cloneDeep(this.props.results);
    let isReverse: boolean = false; // 역순인지 아닌지 판단 기준. 용적률은 내림차순, 결과번호순은 오름차순으로 각각 달라서 만들게 됨.
    let label = '';
    switch (value) {
      case "real_report_number":
        isReverse = false;
        label = '결과 번호 순';
        break
      case "loc_floor_area_ratio":
        isReverse = true;
        label = '용적률 높은 순';
        break
      case "loc_total_household":
        isReverse = true;
        label = '세대수 높은 순';
        break
      default:
        isReverse = false;
        label = '결과 번호 순';
        break
    }

    newResults.sort((a, b) => {
      let aa = a[`${value}`];
      let bb = b[`${value}`];
      return aa - bb;
    });

    const resultSort = {label, value}

    if (isReverse) {
      // 용적률순, 세대수순 등의 기준은 내림차순으로
      newResults.reverse();
    }
    
    this.setState({ resultSort: resultSort });
    this.props.setResults(newResults);
  }

  copyMailAddress = () => {
    this.setState(
      {
        openTooltip: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            openTooltip: false,
          });
        }, 1500);
      }
    );
  };

  setBuildingStoriesAvg = (input: any) => {
    this.setState({
      building_stories_avg_type: input
    })
  }

  render() {
    if (this.props.activeResult === 0) { // 전체결과
      const allChartOptions = this.getChartOptions(this.state.resultChart, this.props.results);
      const compareChartOptions = this.getChartOptions(this.state.resultChartCompare, this.props.results);
      const elementChartOptions = this.getChartOptions(this.state.resultChartElement, this.props.results);
      elementChartOptions.config.padding.left = 20;
      elementChartOptions.config.legend.item.onmouseover = () => {};
     
      return (
        <div className="ProjectResultBody ALL">
        {
          this.props.project.status === "CONFIG" &&
          <div className="project-status-info">
            <div className="bg-navy font font-special title">프로젝트 설정을 완료 후 실행해주세요</div>
            <Link to={`/project/edit?pid=${this.props.project.user_project_id}`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">설정하러 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "RUNNING" &&
          <div className="project-status-info">
            <div className="bg-navy font font-special  title">프로젝트가 분석중입니다.</div>
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "WAITING" &&
          <div className="project-status-info">
            <div className="bg-navy font font-special  title">
            {
              (this.props.project.project_type === "DESIGNER" || this.props.project.project_type === "SITE_PLAN") &&
              `프로젝트가 처리중입니다` || `프로젝트가 분석중입니다.`
            }
            </div>
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "FAILED" &&
          <div className="project-status-info">
            <div className="bg-navy font font-special title">프로젝트 분석에 문제가 발생했습니다</div>
                <div className="font-emphasis m-b-sm font font-14px m-t-md">문의 사항을 메일로 보내주시면 빠른 시간 안에 답변을 드리겠습니다.
                <Clipboard data-clipboard-text={"support@1011.co.kr"} className="email-copy-btn" onClick={this.copyMailAddress}>
                  <Tooltip on={this.state.openTooltip} msg="클립보드에 저장 되었습니다" className="tooltip" place="right">
                    <span className="email-text">support@1011.co.kr <FileCopyOutlinedIcon className="copy-btn"/></span>
                  </Tooltip>
                </Clipboard>
                </div>
            <div className="font-emphasis m-b-sm font font-14px"> ㆍ 문의 시 필수 기재 사항을 확인해주세요!</div>
            <div className="font-emphasis m-b-sm font font-14px"> &nbsp;  ㆍ 계정</div>
            <div className="font-emphasis m-b-sm font font-14px"> &nbsp;  ㆍ 프로젝트 번호</div>
            {
              this.state.errorList.length > 0 &&
              <Fragment>
                <span className="font-emphasis m-b-sm font font-14px m-t-md">오류 상세</span>
                {
                  this.state.errorList.map((e, i) => {
                    return (
                      <div className={`font-primary m-b-sm font font-14px ${i > 0 && "m-t-md"}`} >
                        <span>{`오류 #${i + 1}: ${e.title}`}</span>
                        <div>{e.content}</div>
                      </div>
                    )
                  })
                }
              </Fragment>

            }
            <Link to={`/project/list`}>
              <Button disableRipple={true} className="btn btn-primary bg-navy">목록으로 가기</Button>
            </Link>
          </div>
        }
        {
          this.props.project.status === "FINISHED" &&
          (this.props.project.project_type === "AI" || this.props.project.project_type === "CAL" ) &&
          <Fragment>
            <div className="main-title bg-navy font font-emphasis">전체 결과</div>
            <div className="result-list-wrap">
              <div className="header">
                <div className="title font font-special">결과 목록 </div>
                <div className="left-pannel">
                  <BuilditSelect
                    className={`select-field`}
                    type="Normal"
                    list={[
                        { label: "결과 번호 순", value: "real_report_number" },
                        { label: "용적률 높은 순", value: "loc_floor_area_ratio" },
                        { label: "세대수 높은 순", value: "loc_total_household" },
                      ]}
                    value={this.state.resultSort.value}
                    placeholder={this.state.resultSort.label!}
                    onChange={(value: React.ReactText) => this.sortProjectResult(value)} />
                </div>
                <div className="description m-l-md"></div>
                <div className="right-pannel">
                  {
                    this.props.checkedList.filter(r => r === true).length > 0 &&
                    <Link target="_blank" to={`/Visualizer?pid=${this.props.project.user_project_id}${
                      this.props.results.map((r: any, i: number) => {
                        if (this.props.checkedList[i]) {
                          return `&rid=${r.base_report_id}`
                        }
                      }).join().replace(/,/g, "")
                      }`} className="router-link">
                      <Button disableRipple={true} className={`btn bg-navy btn-small viewer-btn btn-run`}>3D VIEWER</Button>
                    </Link>
                  }
                    <div>
                      <Tabs className="tabs bg-navy tabs-small compare-tabs m-l-a">
                        <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare === false && "active" || ""}`} onClick={e => this.props.setResultCompare(false)}>전체보기</Button>
                        <Tooltip msg={this.props.compareTooltipMsg}
                          on={this.props.openCompareTooltip}
                          id="ProjectResult-bottom-Compare"
                          className="tooltip"
                        >
                          <Button
                            disableRipple={true}
                            className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} 
                            onClick={e => this.props.setResultCompare(true)}
                            onMouseOver={e=> { 
                              if (this.props.checkedList.filter(r => r === true).length < 2) {
                                this.props.setCompareTooltip(true, "2개 이상의 프로젝트를 선택해주세요.")
                              }
                            }}
                            onMouseLeave={e=>this.props.setCompareTooltip(false, "")}
                          >
                            비교보기
                          </Button>
                        </Tooltip>
                      </Tabs>
                    </div>
                </div>
              </div>
              <ProjectResultList
                project={this.props.project}
                setCheckedResult={this.props.setCheckedResult}
                checkedList={this.props.checkedList}
                results={this.props.results}
                isCompare={this.props.resultCompare}
                setActiveResult={this.props.setActiveResult}
                getAllResultZip={this.getAllResultZip}
              />
            </div>
            <div className="result-chart-wrap">
              <div className="result-chart">
                <div className="header font font-special bg-navy">
                  결과 그래프
                  <Tabs className="tabs bg-navy tabs-small compare-tabs m-l-a">
                    <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare === false && "active" || ""}`} onClick={e => this.props.setResultCompare(false)}>전체보기</Button>
                    <Tooltip msg={this.props.compareTooltipMsg}
                      on={this.props.openCompareTooltip}
                      id="ProjectResult-bottom-Compare"
                      className="tooltip"
                    >
                      <Button 
                        disableRipple={true}
                        className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} 
                        onClick={e => this.props.setResultCompare(true)}
                        onMouseOver={e=> { 
                          if (this.props.checkedList.filter(r => r === true).length < 2) {
                            this.props.setCompareTooltip(true, "2개 이상의 프로젝트를 선택해주세요.")
                          }
                        }}
                        onMouseLeave={e=>this.props.setCompareTooltip(false, "")}
                      >
                        비교보기
                      </Button>
                    </Tooltip>
                    {/* <Tooltip msg={this.props.compareTooltipMsg} id="ProjectResult-top-Compare" on={this.props.openCompareTooltip} >
                      <Button disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.props.resultCompare && "active" || ""}`} onClick={e => this.props.setResultCompare(true)}>비교보기</Button>
                    </Tooltip> */}
                  </Tabs>
                </div>
                <div className="content">
                  <div className="description">결과별 비교를 하시려면 결과 목록에서 선택 후 확인하세요.</div>
                  {
                    this.props.resultCompare === false &&
                    <div className="chart">
                    {
                      this.props.results.length > 0 &&
                      <C3Chart
                        type={allChartOptions.type}
                        data={allChartOptions.data}
                        config={allChartOptions.config}
                      />
                    }
                    </div> ||
                    <div className="chart">
                    {
                      this.props.results.length > 0 && this.props.checkedList.filter(r => r === true).length > 0 &&
                      <C3Chart
                        type={compareChartOptions.type}
                        data={compareChartOptions.data}
                        config={compareChartOptions.config}
                      />
                    }
                    </div>
                  }
                </div>
              </div>
              <div className="result-chart element">
                <div className="header font font-special bg-navy">요소 별 비교</div>
                <div className="content">
                  <div className="description">
                  {
                    this.state.resultChartElement.length === 0 &&
                    <Fragment>
                      <div className="msg">범례에 마우스를 올리시면<br />특정 요소만 비교 가능합니다.</div>
                      <div className="msg">범례를 클릭하여<br />특정 요소를 숨길 수 있습니다. </div>
                    </Fragment>
                  }
                  </div>
                  <div className="chart">
                    {
                      this.state.resultChartElement.length > 0 &&
                      <C3Chart
                        type={elementChartOptions.type}
                        data={elementChartOptions.data}
                        config={elementChartOptions.config}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </Fragment>

        }
        {
          (this.props.project.project_type === "DESIGNER" || this.props.project.project_type === "SITE_PLAN") && this.props.project.status === "FINISHED" &&
         <Fragment>
          <div className="download-wrap">
            <div className="header">결과 파일 다운로드</div>
            <div className="content">
              {
                this.props.results.length > 0 && 
                <Fragment>
                  <div className="code">
                    DESIGNER Code :
                    <span style={{color: "#ccc"}}>
                    {
                      (this.props.results.length > 0 && this.props.results[0].designer_code !== undefined) &&
                      this.props.results[0].designer_code.length > 0 && this.props.results[0].designer_code || "Designer-Code Error"
                    }
                    </span>
                    {
                      <Clipboard data-clipboard-text={this.props.results[0].designer_code}
                        className="project-result-code-copy-btn" onClick={this.copyKeyCode}>
                          <Tooltip on={this.state.openDesginerTooltip} msg="클립보드에 저장 되었습니다" className="tooltip">Copy Code</Tooltip>
                      </Clipboard> || ""
                    }

                  </div>
                  <div className="download-btns">
                    <DownloadButton
                      className="bg-navy btn btn-large btn-run m-r-md download-btn designer-download-btn"
                      onClick={async (callback: Function) => {
                        this.props.setLoading(true, "DESIGNER의 설치를 확인 중입니다.", async () => {
                          const aws = await new AWSModule().connect();
                          const token = aws.getSession().getToken();
                          let webSocket = new WebSocket(App.Designer_URL);

                          webSocket.onopen = (e) => {
                            let uri = "";
                            
                            switch (App.stage) {
                              case "prod":
                                uri = "buildit://run?code=" + this.props.results[0].designer_code + `&email=${App.session!.email}&token=${token}`
                                break;
                              case "test":
                                uri = "buildittest://run?code=" + this.props.results[0].designer_code + `&email=${App.session!.email}&token=${token}`;
                                break;
                              default:
                                uri = "builditdev://run?code=" + this.props.results[0].designer_code + `&email=${App.session!.email}&token=${token}`;
                                break;
                            }

                            window.location.href =  uri;
                            this.props.setLoading(false)
                            callback && callback();
                          }
                          webSocket.onerror = (e) => {
                            this.downloadDesigner();
                            this.props.setLoading(false)
                            console.log("designer doesn't exist");
                            callback && callback();
                          }
                        })
                      }}
                    >
                      <div>DESIGNER</div>
                      <span className="m-l-a width-full" style={{textTransform: "none"}}>exe</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadPriceReport(() => callback && callback())}
                      className="bg-navy btn btn-primary download-btn"
                    >
                      <div>사업성 분석자료</div>
                      <span className="m-l-a width-full" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                  </div>
                </Fragment>
              }
            </div>
          </div>
        </Fragment>
        }

          <ProjectSummary project={this.props.project} className="project-summary" mapViewErrorHandler={this.mapViewErrorHandler} updateProject={this.props.updateProject} />
        </div>
      )
    } else {
      const chartData: any = [];
      const pr = this.props.results[this.props.results.findIndex(e => e.real_report_number === this.props.activeResult)];
      if (this.props.results) {
        // if (this.props.results[this.props.activeResult - 1].loc_my_building_shape_type && this.props.project.my_building_shape_type.length > 0) {
        //   this.props.project.my_building_shape_type.map(r => chartData.push([`동평면 (${r.name})`, r.proportion]));
        // }
        if (pr.loc_housing_type_ratio && pr.loc_housing_type_ratio.length > 0) {
          // pr.loc_housing_type_ratio.map((r: any) => chartData.push([`${r.area}㎡/${r.bay}베이`, r.proportion]));
          pr.loc_housing_type_ratio.map((r: any) => chartData.push([`${r.area}㎡`, r.proportion]));
        }
      }

      const resultIndex = this.props.results.findIndex(e => e.real_report_number === this.props.activeResult);
      const resultNumber = this.props.results[resultIndex].real_report_number;
      // if (App.stage !== "prod") {
      //   console.log('activeResult!!', this.props.activeResult)
      //   console.log('resultIndex!!', resultIndex)
      //   console.log('!!resultNumber!!', resultNumber)
      // }

      const buildingType = this.props.results[resultIndex].building_type;
      // const householdData = (this.props.results[resultIndex].householdAreaTable || []).filter((item: any) => item.households !== 0) || [];
      return (
        <div className="ProjectResultBody DETAIL">
          <div className="main-title bg-navy font font-emphasis">
            <div className="title">{resultNumber}번 결과</div>
            <div className="solver-type">{buildingType}</div>
          </div>
          <div className="content">
            <div className="result-img-files">
              <div className="cad-img">
                {
                  
                  ((this.props.project.building_type! !== "다세대주택") && 
                    App.stage !== "prod" && ["AI", "CAL"].includes(this.props.project.project_type!)) &&                   
                  (this.state.smallImgActive === -1 &&
                    <S3Image s3={{
                      Bucket: "teneleven-engine-result",
                      Key: this.props.results[resultIndex].base_report_file_path.resImage.split("teneleven-engine-result/")[1],
                    }} /> ||
                    this.state.smallImgActive === 0 &&
                    <S3Image s3={{
                      Bucket: "teneleven-engine-result",
                      Key: this.props.results[resultIndex].base_report_file_path.parkingConvexOverlapImage.split("teneleven-engine-result/")[1],
                    }} /> 
                  ) ||

                  (this.props.project.building_type! !== "다세대주택" ) && 
                  <S3Image s3={{
                    Bucket: "teneleven-engine-result",
                    Key: this.props.results[resultIndex].base_report_file_path.resImage.split("teneleven-engine-result/")[1],
                  }} />

                }
                {
                  this.props.project.building_type! === "다세대주택" &&
                  (
                    (this.props.results![resultIndex].resImages && this.props.results![resultIndex].resImages.length > 0) &&
                    this.state.smallImgActive >= 0 &&
                    <S3Image s3={{
                      Bucket: "teneleven-engine-result",
                      Key: this.props.results![resultIndex].resImages[this.state.smallImgActive].split("teneleven-engine-result/")[1],
                    }} /> ||
                    <S3Image s3={{
                      Bucket: "teneleven-engine-result",
                      Key: this.state.smallImgActive === -1 && this.props.results[resultIndex].base_report_file_path.resImage.split("teneleven-engine-result/")[1] || this.props.results![resultIndex].tempImages[0].split("teneleven-engine-result/")[1]
                    }} />
                  )
                }
              </div>
              {
                this.props.project.building_type! !== "다세대주택" && App.stage !== "prod" && ["AI", "CAL"].includes(this.props.project.project_type!) &&
                this.props.results[resultIndex].base_report_file_path.resImage &&
                this.props.results[resultIndex].base_report_file_path.parkingConvexOverlapImage &&
                <div className="floor-btn-wrap">
                  <Tabs className="tabs bg-navy">
                    <Fragment>
                      <Button disableRipple={true} className={`bg-navy tab tab-primary ${this.state.smallImgActive === -1 && "active"}`} onClick={() => this.setState({ smallImgActive: -1 })}>배치</Button>
                      <Button disableRipple={true} className={`bg-navy tab tab-primary ${this.state.smallImgActive === 0 && "active"}`} onClick={() => this.setState({ smallImgActive: 0 })}>주차</Button>
                    </Fragment>
                  </Tabs>
                </div>
              }

              {
                this.props.project.building_type! === "다세대주택" &&
                (
                  (this.props.results![resultIndex].resImages && this.props.results![resultIndex].resImages.length > 0) &&
                  <div className="floor-btn-wrap">
                    <Tabs className="tabs bg-navy">
                      <Fragment>
                        <Button disableRipple={true} className={`bg-navy tab tab-primary ${this.state.smallImgActive === -1 && "active"}`} onClick={() => this.setState({ smallImgActive: -1 })}>배치</Button>
                      {
                        this.props.results![resultIndex].resImages.map((r: string, i: number) => {
                          if (r) {                            
                            return <Button disableRipple={true} className={`bg-navy tab tab-primary ${this.state.smallImgActive === i && "active"}`} onClick={() => this.setState({ smallImgActive: i })}>{r.split('_').pop()!.split('.')[0]}층</Button>
                          }
                        })
                      }
                        <Button disableRipple={true} className={`bg-navy tab tab-primary ${this.state.smallImgActive === -2 && "active"}`} onClick={() => this.setState({ smallImgActive: -2 })}>개요</Button>
                      </Fragment>
                    </Tabs>
                  </div>
                )
              }
              <Link target="_blank" to={`/Visualizer?pid=${this.props.project.user_project_id}&rid=${this.props.results[resultIndex].base_report_id}`}  style={{textDecoration: "none"}}>
                <Button disableRipple={true} className="bg-navy btn btn-run webgl-btn">3D VIEWER</Button>
              </Link>
              <div className="files">
                
                <div className="header">
                  <div className="row">
                    <div className="title font font-special bg-navy">결과 파일 다운로드</div>
                    <DownloadButton onClick={(callback: Function) => this.getAllResultZip(resultNumber, () => callback && callback())}
                      className={`bg-navy btn btn-primary download-btn m-l-a`}                      
                    >
                      <div>전체 다운로드</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>zip</span>
                    </DownloadButton>
                  </div>
                  
                </div>
                <div className="content">
                  { this.props.project.building_type !== '다세대주택' && (
                    <div className="designer">
                    {/* TODO: 클릭하면 복사되도록 */}
                    DESIGNER Code :
                    <span className="key">{this.props.results[resultIndex].designer_code}</span>
                    {
                      (this.props.results[resultIndex].designer_code !== undefined && this.props.results[resultIndex].designer_code.length > 0)
                      &&
                      <Clipboard data-clipboard-text={this.props.results[resultIndex].designer_code}
                        className="project-result-code-copy-btn" onClick={this.copyKeyCode}>
                          <Tooltip on={this.state.openDesginerTooltip} msg="클립보드에 저장 되었습니다" className="tooltip">Copy Code</Tooltip>
                      </Clipboard> || ""
                    }
                  </div>
                  )}
                  <div className="report-files">                   
                    <DownloadButton
                      className={`bg-navy btn ${this.props.project.building_type !== '다세대주택' ? 'btn-run designer-download-btn download-btn': 'btn-primary download-btn disabled'} `}
                      disabled={(this.props.project.building_type === '다세대주택')}
                      onClick={async (callback: Function) => {
                        this.props.setLoading(true, "DESIGNER의 설치를 확인 중입니다.", async () => {
                          const aws = await new AWSModule().connect();
                          const token = aws.getSession().getToken();
                          let webSocket = new WebSocket(App.Designer_URL);
                            webSocket.onopen = async (e) => {
                              let uri = "";
                              
                              switch (App.stage) {
                                case "prod":
                                  uri = "buildit://run?code=" + this.props.results[resultIndex].designer_code + `&email=${App.session!.email}&token=${token}`;
                                  break;
                                case "test":
                                  uri = "buildittest://run?code=" + this.props.results[resultIndex].designer_code + `&email=${App.session!.email}&token=${token}`;
                                  break;
                                default:
                                  uri = "builditdev://run?code=" + this.props.results[resultIndex].designer_code + `&email=${App.session!.email}&token=${token}`;
                                  break;
                              }

                              window.location.href =  uri;
                              this.props.setLoading(false);
                              callback && callback();
                            }
                            webSocket.onerror = (e) => {
                              this.downloadDesigner();
                              this.props.setLoading(false);
                              callback && callback();
                            }
                        })
                      }}
                    >
                      <div>DESIGNER</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>exe</span>
                    </DownloadButton>
                                    
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(resultNumber-1, "cad", "cad.dxf", () => callback && callback())}
                      className={`bg-navy btn btn-primary download-btn m-l-a`}
                    >
                      <div>사업영역 배치도</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>dxf</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadBuildingReport(resultNumber, () => callback && callback())}
                      className="bg-navy btn btn-primary download-btn"
                    >
                      <div>건축개요 보고서</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(resultNumber-1, "lightReport", "세대별 일조시간.xlsx", () => callback && callback())}
                      className={`bg-navy btn btn-primary download-btn m-l-a ${this.props.project.building_type! === "다세대주택" && "disabled"}`}
                      disabled={this.props.project.building_type! === "다세대주택"}
                    >
                      <div>세대 별 일조시간</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadReport(resultNumber-1, "viewReport", "세대별 조망점수.xlsx", () => callback && callback())}
                      className={`bg-navy btn btn-primary download-btn ${this.props.project.building_type! === "다세대주택" && "disabled"}`}
                      disabled={this.props.project.building_type! === "다세대주택"}
                    >
                      <div>세대 별 조망점수</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                    <DownloadButton onClick={(callback: Function) => this.downloadPriceReport(() => callback && callback())}
                      className={`bg-navy btn btn-primary download-btn m-l-a`}
                    >
                      <div>사업성 분석자료</div>
                      <span className="m-l-a" style={{textTransform: "none"}}>xlsx</span>
                    </DownloadButton>
                  </div>
                </div>
              </div>
            </div>
            <div className="result-summary">
              <div className="title font font-special">결과 개요</div>
              <div className="content">
                <div className="values-left">
                  <ProjectValue
                    className="value-lg"
                    title={"사업영역 면적"}
                    unit='㎡'
                    value={this.props.project && Number(Number(this.props.project.project_site_area).toFixed(2)).toLocaleString()}
                  />
                  <ProjectValue
                    className="value-lg"
                    title={"용적률 산정용 연면적"}
                    unit='㎡'
                    value={this.props.project && Number(Number(this.props.project.project_site_area! * this.props.results[resultIndex].loc_floor_area_ratio / 100).toFixed(2)).toLocaleString()}
                  />
                  <ProjectValue
                    className="value-lg"
                    title={"건축면적"}
                    unit='㎡'
                    value={this.props.project && Number(Number(this.props.results[resultIndex].loc_building_land_ratio * this.props.project.project_site_area! / 100).toFixed(2)).toLocaleString()}
                  />
                
                  {
                    this.props.project.building_type !== '다세대주택' && (
                      <ProjectValue
                        className="value-lg"
                        title={"추정 공사비"}
                        unit="(천)원"
                        tooltip="2020 한국부동산원 건물 신축단가표에 따른 공사비 예상단가입니다."
                        value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_construction_cost / 1000).toFixed(0)).toLocaleString()}
                      />
                    )
                  }
                  
                </div>
                <div className="values-right">
                  <ProjectValue
                    className="value-md"
                    unit="%"
                    title={"건폐율"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_land_ratio).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="%"
                    title={"용적률"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_floor_area_ratio).toFixed(2)).toLocaleString()}
                   />
                   <ProjectValue
                    className="value-md"
                    unit="동"
                    title={"건물 수"}
                    value={this.props.results[resultIndex] && this.props.results[resultIndex].loc_building_number}
                   />
                   <ProjectValue
                    className="value-md last"
                    unit="세대"
                    title={"총 세대수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_total_household).toFixed(2)).toLocaleString()}
                   />
                   {
                     this.props.project.building_type! === "아파트" &&
                    <ProjectValue
                      className="value-md"
                      unit="층"
                      title={"최저 층수"}
                      value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_min).toFixed(2)).toLocaleString()}
                    />

                   }
                   <ProjectValue
                    className="value-md"
                    unit="층"
                    title={"최고 층수"}
                    value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_max).toFixed(2)).toLocaleString()}
                   />
                   {
                      this.props.project.building_type! === "아파트" &&
                      <Fragment>
                        <ProjectValue className="value-md" title="평균 층수 타입"
                          value={
                            this.props.project.building_stories_avg_type
                          // this.props.project.building_stories_avg_type === "AREA" && "기준 면적" ||
                          // (this.props.project.building_stories_avg_type === "HOUSE" && "총 세대" || "산술 평균")
                        }
                          type="select"
                          setBuildingStoriesAvg={this.setBuildingStoriesAvg}
                        />
                        <ProjectValue
                          className="value-md last"
                          unit="층"
                          title={"평균 층수"}
                          value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_building_stories_avg[this.state.building_stories_avg_type! || this.props.project.building_stories_avg_type]).toFixed(2)).toLocaleString()}
                        />
                      </Fragment>
                    }
                    {
                      (this.props.project.building_type! === "오피스텔") && (
                        <Fragment>
                        <div style={{ paddingTop: '20px', width: '244px' }}></div>
                        <div className="value-md last"></div>
                        </Fragment>
                      )
                    }
                    {
                      this.props.project.building_type! !== "다세대주택" &&
                      <Fragment>
                        <ProjectValue
                          className="value-md"
                          unit="점"
                          title={"평균 조망 점수"}
                          value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].view_point_avg).toFixed(2)).toLocaleString()}
                        />
                        <ProjectValue
                          className="value-md"
                          title={"평균 일조시간"}
                          value={this.props.results[resultIndex] && moment(0).utc().add(moment.duration(roundTimeData(this.props.results[resultIndex].daylight_hours_avg), 'hours')).format('HH시간 mm분')}
                          //value={this.props.results[resultIndex] && timeScale(this.props.results[resultIndex].daylight_hours_avg)}
                        />
                        <ProjectValue
                          className={`value-md`}
                          title={"최빈 일조시간"}
                          value={this.props.results[resultIndex] && moment(0).utc().add(moment.duration(roundTimeData(this.props.results[resultIndex].daylight_hours_mode), 'hours')).format('HH시간 mm분')}
                          //value={this.props.results[resultIndex] && timeScale(this.props.results[resultIndex].daylight_hours_mode)}
                        />
                        <ProjectValue
                          className={`value-md lt-small ${this.props.project.building_type === "연립주택" ? '' : 'last'}`}
                          title={"2시간 미만 일조비율"}
                          unit="%"
                          value={
                            this.props.results[resultIndex] && this.props.results[resultIndex].daylight_hours_proportion_less_n_hours &&
                            this.props.results[resultIndex].daylight_hours_proportion_less_n_hours.length > 0 ?
                            Number(Number(this.props.results[resultIndex].daylight_hours_proportion_less_n_hours[1]).toFixed(2)).toLocaleString() : 0
                          }
                        />
                      </Fragment>
                    }
                    {
                      this.props.project.building_type! === "다세대주택" &&                      
                      <ProjectValue
                        className="value-md"
                        unit="대"
                        title={"주차 대수"}
                        value={this.props.results![resultIndex] && this.props.results![resultIndex].parkingNumber}
                      />
                    }
                    {
                      this.props.project.building_type === '다세대주택' && (
                        <Fragment>
                          <div style={{ paddingTop: '20px', width: '224px' }}></div>  
                          <ProjectValue
                            className="value-lg"
                            title={"추정 공사비"}
                            unit="(천)원"
                            tooltip="2020 한국부동산원 건물 신축단가표에 따른 공사비 예상단가입니다."
                            value={this.props.results[resultIndex] && Number(Number(this.props.results[resultIndex].loc_construction_cost / 1000).toFixed(0)).toLocaleString()}
                          />
                        </Fragment>
                      )
                    }
                    {/* {
                      this.props.project.building_type! !== "다세대주택" &&
                      <ProjectValue
                        className="value-md lt-small"
                        unit={this.props.results![resultIndex].num_parking !== undefined ? '대' : ''}
                        title={"주차 대수"}
                        value={(this.props.results![resultIndex].num_parking !== undefined) ?
                          this.props.results![resultIndex].num_parking.convex : '-'}
                      />
                    } */}
                    {/* {
                      this.props.project.building_type! === "아파트" &&
                      this.props.results![resultIndex] !== undefined &&
                      this.props.results![resultIndex].wind_info !== undefined &&
                      <Fragment>
                        <Fragment>
                          <ProjectValue
                            className="value-md value-sm"
                            unit=""
                            title={"주 풍향"}
                            value={
                              this.props.results![resultIndex].wind_info.direction_info &&
                              `${this.props.results![resultIndex].wind_info.direction_info.name} 풍(${this.props.results![resultIndex].wind_info.direction_info.angle.toFixed(2)}도)`
                            }
                          />
                        </Fragment>
                        <Fragment>
                          <ProjectValue
                            className="value-md"
                            unit=""
                            title={"주 풍향 산정기간"}
                            value={
                              this.props.results![resultIndex].wind_info.date_begin &&
                              `${this.props.results![resultIndex].wind_info.date_begin}~${this.props.results![resultIndex].wind_info.date_end}` || ""
                            }
                          />
                        </Fragment>
                        <Fragment>
                          <ProjectValue
                            className="value-md"
                            unit="%"
                            title={"통풍성(앞 면적 비)"}
                            value={this.props.results![resultIndex].wind_info.wind_face_area_ratio !== undefined && (Number(this.props.results![resultIndex].wind_info.wind_face_area_ratio) * 100).toFixed(2) || ''}
                          />
                        </Fragment>
                      </Fragment>
                    } */}
                    {
                      this.props.project.building_type !== '다세대주택' && (
                        <div className="housing-type-chart">
                          <div className="title">세대 비율</div>
                          <HousingChart
                            project={this.props.project}
                            result={this.props.results[resultIndex]}
                            legend="right"
                            id="project-result-c3-chart"
                            type="result"
                          />
                        </div>
                      )
                    }
                    
                </div>
              </div>
              <div className="floor_content">
              { 
                this.props.project.building_type === '다세대주택' && (
                  <div>                        
                    <div className="floortitle">층별 개요</div>                                      
                    <Table>
                      <TableHead >
                        <TableRow className="table-row">
                          {
                            floor_summary.map((data) => (
                              <TableCell key={`4-${data.label}`} className="cellhead" >{data.label}</TableCell>
                            ))
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          _.sortBy(this.props.results[resultIndex].householdAreaTable, 'name').reverse().map((data: any) => (
                            <TableRow className="table-row" key={`4-${data.name}_${data.households * Math.random()}`}>
                              {
                                floor_summary.map((item, index) => (
                                  <TableCell key={item.key + index} className={`${index===0 ? 'cellfirst': 'celldata'}`} >
                                    {item.subKey ?
                                      (item.value(data[item.key][item.subKey]) && item.value(data[item.key][item.subKey])) : item.value(data[item.key])}
                                    {item.subKey ? (item.value(data[item.key][item.subKey]) && item.value(data[item.key][item.subKey]) > 0 && "㎡") : item.value(data[item.key]) > 0 && item.unit}
                                  </TableCell>
                                ))
                              }
                            </TableRow>
                          ))
                        }
                      </TableBody>
                      <TableFooter>
                        <TableRow className="table-row">
                          <TableCell className="totalfirst" style={{ width: '83px'}} >합계</TableCell>                          
                          {
                            floor_summary
                              .map((item: object, index: number) =>
                                index > 1 &&
                                <TableCell key={`${index}합계`} className={'totaldata'}>{calSum(item, this.props.results[resultIndex].householdAreaTable)}</TableCell>
                                || index === 1 && <TableCell className={'totaldata'}>-</TableCell>
                              )
                          }
                        </TableRow>
                      </TableFooter>
                    </Table>     
                  </div>                        
                )
              }
              {
                this.props.project.building_type === '다세대주택' && ( 
                
                  _.sortBy(this.props.results[resultIndex].householdAreaTable, 'name').filter((item: any) => item.households !== 0).map((data: any, index: number) => {
                    if (data.households > 0) {
                      return (
                        <>
                          {
                            (index === 1) && 
                            <div className="moreview" onClick={e=>this.setState({ moreview: !this.state.moreview })}>더보기
                            {
                              this.state.moreview &&
                              <UpArrowIcon className="icon" /> ||
                              <DownArrowIcon className="icon" />
                            }
                            </div>
                            
                          }
                          {
                            (index < 1) && (
                              <div>
                                <div className="floortitle">
                                {`세대별 개요 - ${data.name}`}
                              </div>
                              <HouseholdsSummaryTable props={data} key={data.households + Math.random()} 
                              isLast={index === (this.props.results[resultIndex].householdAreaTable || []).filter((item: any) => item.households !== 0).length - 1 ? true : false} />   
                            </div>      
                            ) ||
                            (index >= 1) && this.state.moreview && (
                              <div>
                                <div className="floortitle">
                                {`세대별 개요 -  ${data.name}`}
                              </div>
                              <HouseholdsSummaryTable props={data} key={data.households + Math.random()} 
                              isLast={index === (this.props.results[resultIndex].householdAreaTable || []).filter((item: any) => item.households !== 0).length - 1 ? true : false} />   
                            </div>                            
                            )
                          }
                          
                        </>
                      );
                    } 
                  })
                )
              }
            
            </div>
            </div>
            
          </div>
        </div>
      )
    }
  }
  copyKeyCode = () => {
    this.setState({
      openDesginerTooltip: true
    }, () => {
      setTimeout(() => {
        this.setState({
          openDesginerTooltip: false
        })
      }, 1500);
    })
  }

  getAllResultZip = async (reportId: number, callback?: Function) => {
    const zip = new JSZip();
    const currProject = this.props.results.find(elem => elem.real_report_number === reportId)
    App.tempStage !== "PROD" ? console.log(currProject, currProject.base_report_file_path['constructionReport']) : null;
    
    try {
      const pr = await App.API_Axios.post('/PriceReport', {
        stage: App.tempStage,
        project_id: this.props.project.project_id,
      });
      const priceResult = pr.data;
      const priceFile = await App.s3!.getObject({
        Bucket: priceResult.bucket,
        Key: priceResult.key,     
      }).promise();          
      zip.file(`${currProject.base_user_project_id}_사업성 분석자료.xlsx`, priceFile.Body as Buffer);
    } catch(e)  {
      console.log(e);
      alert('사업성 분석자료 파일이 없습니다.')
    }

    try {
      if (currProject.base_report_file_path['constructionReport']) {
        // S3 O
        const buildingFile = await App.s3!.getObject({
          Bucket: App.S3Bucket.EngineResult,
          Key: currProject.base_report_file_path['constructionReport'].split(`${App.S3Bucket.EngineResult}/`)[1],     
        }).promise();
        zip.file(`${currProject.base_user_project_id}_${reportId}번_건축개요 보고서(${App.session.email}).xlsx`, buildingFile.Body as Buffer);
      } else {
        // S3 X
        const br = await App.API_Axios.post('/BuildingReport', {
          stage: App.tempStage,
          project_id: this.props.project.project_id,
          report_number: currProject.base_project_id,
        });
        
        const buildingResult = br.data;
        const buildingFile = await App.s3!.getObject({
          Bucket: buildingResult.bucket,
          Key: buildingResult.key,     
        }).promise();
        zip.file(`${currProject.base_user_project_id}_${reportId}번_건축개요보고서(${App.session.email}).xlsx`, buildingFile.Body as Buffer);
      }
    } catch(e)  {
      console.log(e);
      alert('건축개요 보고서 파일이 없습니다.')
    }
    
    try {
      const b: any = await App.s3!.getObject({
        Bucket: App.S3Bucket.EngineResult,
        Key: currProject.base_report_file_path['cad'].split(`${App.S3Bucket.EngineResult}/`)[1],
      }).promise();
      
      zip.file(`${currProject.base_user_project_id}_${reportId}번_CAD(${App.session.email}).dxf`, b.Body as Buffer);
    } catch(e) {
      console.log(e);
      alert('CAD 파일이 없습니다.')
    }

    if(this.props.project.building_type !== '다세대주택') {     // 다세대주택은 현재 시간 기준 해당 리포트 없음
      try {
        const b: any = await App.s3!.getObject({
          Bucket: App.S3Bucket.EngineResult,
          Key: currProject.base_report_file_path['lightReport'].split(`${App.S3Bucket.EngineResult}/`)[1],
        }).promise();
        
        zip.file(`${currProject.base_user_project_id}_${reportId}번_세대별_일조시간(${App.session.email}).xlsx`, b.Body as Buffer);
      } catch(e) {
        console.error(e);
        alert('세대별 일조시간 파일이 없습니다.')
      }
    }
    
    if(this.props.project.building_type !== '다세대주택') {     // 다세대주택은 현재 시간 기준 해당 리포트 없음
      try {
        const b: any = await App.s3!.getObject({
          Bucket: App.S3Bucket.EngineResult,
          Key: currProject.base_report_file_path['viewReport'].split(`${App.S3Bucket.EngineResult}/`)[1],
        }).promise();
        
        zip.file(`${currProject.base_user_project_id}_${reportId}번_세대별_조망점수(${App.session.email}).xlsx`, b.Body as Buffer);
      } catch(e) {
        console.log(e);
        alert('세대별 조망점수 파일이 없습니다.')
      }
    }
      
    zip.generateAsync({ type: 'arraybuffer' }).then(function (content) {
      FileSaver.saveAs(new Blob([content]), `${currProject.base_user_project_id}_${reportId}번 결과(${App.session.email}).zip`);
      callback && callback();      
    });  
  }

  downloadPriceReport = async (callback?: Function) => {
    const lambda = await new AWSModule("LAMBDA").connect();

    const r1 = await App.API_Axios.post('/PriceReport', {
      stage: App.tempStage,
      project_id: this.props.project.project_id,
    });

    const rr = r1.data;
    App.s3!.getSignedUrl('getObject', {
      Bucket: rr.bucket,
      Key: rr.key,
      Expires: 43200,
      ResponseContentDisposition: `attachment; filename="${encodeURIComponent(`${this.props.project.user_project_id}_사업성 분석자료.xlsx`)}"`,
    }, (err, data) => {
      if (err) {
        console.log(err);
      } else {
        window.location.href = data;
      }
      callback && callback();
    })
  }

  downloadBuildingReport = async (reportId: number, callback?: Function) => {
    /* 건축개요 보고서(xlsx) 다운로드 함수 */
    let currProject = this.props.results.find(elem => elem.real_report_number === reportId)
    // 1. 건축개요 보고서 S3 다운로드
    const lambda = await new AWSModule("LAMBDA").connect();
    await App.s3!.getSignedUrl('getObject', {
      Bucket: App.S3Bucket.EngineResult, 
      Key: currProject.base_report_file_path['constructionReport'].split(`${App.S3Bucket.EngineResult}/`)[1],
      ResponseContentDisposition: `attachment; filename="${encodeURIComponent(`${currProject.base_user_project_id}_${reportId}번_건축개요 보고서.xlsx`)}"`,
    }, async (err, data) => {
      if (err) {
        // 2. 실패시 constructionReport.json으로 엑셀 생성 & 다운로드 API 호출
        try {
          const r1 = await App.API_Axios.post('/BuildingReport', {
            stage: App.tempStage,
            project_id: this.props.project.project_id,
            report_number: currProject.base_report_id,
          });
          const rr = r1.data;
          App.s3!.getSignedUrl('getObject', { 
            Bucket: rr.bucket,
            Key: rr.key,
            Expires: 43200,
            ResponseContentDisposition: `attachment; filename="${encodeURIComponent(`${currProject.base_user_project_id}_${reportId}번_건축개요 보고서.xlsx`)}"`,
          }, (err, data) => {
            if (err) {
              console.log(err);
            } else {
              window.location.href = data;
            }
            callback && callback();
          })
        }
        catch (e) {
          // await API호출 실패 시 에러처리와 무한로딩 정지
          alert('건축개요 보고서 파일을 다운로드 받지 못하였습니다.')
          callback && callback();
        }
      } else {
        window.location.href = data;
        callback && callback();
      }
    })
  }


  downloadReport = (reportId: number, filePath: string, fileName: string, callback?: Function) => {
    if (this.props.results && this.props.results[reportId] && this.props.results[reportId].base_report_file_path && this.props.results[reportId].base_report_file_path[filePath]) {
      App.s3!.getSignedUrl("getObject", {
        Bucket: App.S3Bucket.EngineResult,
        Key: this.props.results[reportId].base_report_file_path[filePath].split(`${App.S3Bucket.EngineResult}/`)[1],
        Expires: 43200,
        ResponseContentDisposition: `attachment; filename="${encodeURIComponent(`${this.props.results[reportId].base_user_project_id}_${reportId+1}번_${fileName}`)}"`
      }, (err, data) => {
        if (err) {
          console.log(err);
        } else {
          window.location.href = data;
        }
        callback && callback();
      })
    } else {
      alert('파일 없음');
      callback && callback();
    }
  }

  downloadDesigner = (callback?: Function) => {
    const fileName = `BUILDIT DESIGNER(${this.props.project.user_project_id})`;

    let keyName = '';
    switch (App.stage) {
      case "prod":
        keyName = "buildit_designer_installer_release.exe";
        break;
      case "test":
        keyName = "buildit_designer_installer_test.exe";
        break;
      default: // dev
        keyName = "buildit_designer_installer_dev.exe"
    }

    App.s3!.getSignedUrl("getObject", {
      Bucket: "teneleven-application-registry-apn2",
      Key: `buildit-designer/v1/${keyName}`,
      Expires: 43200,
      ResponseContentDisposition: `attachment; filename="Buildit_Designer_Installer.exe"`
    }, async (err, data) => {
      if (err) {
        console.log(err);
      } else {
        window.location.href = data;
      }

      if (callback) {
        callback();
      }
    })
  }
}

export interface ProjectResultFooterProps {
  project: Project;
  setModal: (open: boolean, options?: ModalOptions) => void;
  copyProject: () => void;
  discardProject: () => void;
}
export interface ProjectResultFooterState {}

export class ProjectResultFooter extends Component<ProjectResultFooterProps, ProjectResultFooterState> {
  render() {
    return (
      <div className="ProjectResultFooter">
        <div className="wrapper">
          <div className="buttons">
            {
              (this.props.project.status === "WAITING" || this.props.project.status === "RUNNING") &&
              <Tooltip msg="분석이 완료되기 전에 삭제하실 수 없습니다." id="ProjectResult-bottom-Discard" className="m-r-sm">
                <Button
                  disabled={true}
                  onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel disabled"
                >
                  <DiscardIcon className="icon" />
                </Button>
              </Tooltip> ||
              <Tooltip msg="삭제하기" id="ProjectResult-bottom-Discard" className="m-r-sm">
                <Button
                  onClick={this.props.discardProject} disableRipple={true} className="bg-navy btn btn-cancel"
                >
                  <DiscardIcon className="icon" />
                </Button>
              </Tooltip>
            }
            {
              this.props.project.project_type === "CAL" &&
              <Tooltip msg="CAL 프로젝트는 복사할 수 없습니다" id="ProjectResult-bottom-Copy"  className="m-r-sm">
                <Button disabled onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary disabled"><CopyIcon className="icon" /></Button>
              </Tooltip> ||
              <Tooltip msg="복사하기" id="ProjectResult-bottom-copy"  className="m-r-sm">
                <Button onClick={this.props.copyProject} disableRipple={true} className="bg-navy btn btn-primary"><CopyIcon className="icon" /></Button>
              </Tooltip>
            }
            <Link to="/project/list">
              <Tooltip msg="목록으로" id="ProjectResult-bottom-List">
                <Button disableRipple={true} className="bg-navy btn btn-primary"><ToListIcon className="icon" /></Button>
              </Tooltip>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}


export interface ProjectCardHover {
  "건물종류": boolean,
  "건폐율": boolean,
  "용적률": boolean,
  "최저 층수": boolean,
  "최고 층수": boolean,
  "평균 층수": boolean,
  "건물 수": boolean,
  "총 세대수": boolean,
  "평균 일조시간": boolean,
  "최빈 일조시간": boolean,
  "2시간 미만 일조비율": boolean,
  "평균 조망점수": boolean,
  "추정 공사비": boolean,
}

export interface ProjectResultListProps {
  isCompare: boolean;
  checkedList: boolean[],
  results: any[],
  project: Project,
  setCheckedResult: (reportId: number) => void;
  setActiveResult: (n: number) => void;
  getAllResultZip: (reportID: number) => void;
}
export interface ProjectResultListState {
  hovers: ProjectCardHover,
}
export class ProjectResultList extends Component<ProjectResultListProps, ProjectResultListState> {
  state: ProjectResultListState = {
    hovers: {
      "건물종류": false,
      "건폐율" : false,
      "용적률" : false,
      "최저 층수": false,
      "최고 층수": false,
      "평균 층수": false,
      "건물 수" : false,
      "총 세대수": false,
      "평균 일조시간": false,
      "최빈 일조시간": false,
      "2시간 미만 일조비율": false,
      "평균 조망점수": false,
      "추정 공사비": false,
    }
  }

  render() {
    if (this.props.isCompare === false) {
      return (
        <div className="ProjectResultList NORMAL">
        {
          this.props.results &&
          this.props.results.map((r, i) => {
            return <ProjectResultCard
                setActiveResult={this.props.setActiveResult}
                project={this.props.project}
                hovers={this.state.hovers}
                result={r}
                className={`project-card`}
                setCheckedResult={this.props.setCheckedResult}
                checked={this.props.checkedList[i]}
                disableCheckedEffect={true}
                isCompare={this.props.isCompare}
                setHovers={this.setHovers}
                getAllResultZip={this.props.getAllResultZip}
              />
          })
        }
        </div>
      )
    } else {
      return (
        <div className="ProjectResultList COMPARE">
          <div className="project-card-header">
            <div className="title">결과 비교</div>
            <div className="project-site">
              <div className="info">
                <div className="title">대지영역 면적</div>
                <div className="area">{Number(Number(this.props.project.project_site_area).toFixed(2)).toLocaleString()}㎡</div>
              </div>
            </div>
            <div className="content">
            {
              ProjectCardData.map(r => {
                if (this.props.project.building_type! !== "다세대주택" && r.smallField) {
                  return
                } else if (this.props.project.building_type! === "다세대주택" && !r.showSmall) {
                  return;
                } else {
                  return (
                    <div className={`key ${this.state.hovers[r.fieldName as keyof ProjectCardHover] && "hover" || ""}`}>
                      {r.fieldName}
                    </div>
                  )
                }
              })
            }
            </div>
          </div>
        {
          this.props.results &&
          this.props.results.map((r, i) => {
            if (this.props.checkedList[i]) {
              return <ProjectResultCard
                setActiveResult={this.props.setActiveResult}
                project={this.props.project}
                hovers={this.state.hovers}
                result={r}
                className={`project-card`}
                setCheckedResult={this.props.setCheckedResult}
                checked={this.props.checkedList[i]}
                disableCheckedEffect={true}
                isCompare={this.props.isCompare}
                setHovers={this.setHovers}
                getAllResultZip={this.props.getAllResultZip}
              />
            }
          })
        }
        </div>
      )
    }
  }

  setHovers = (fieldName: keyof ProjectCardHover, hover: boolean) => {
    // @ts-ignore
    this.setState({ hovers: { [fieldName]: hover }})
  }
}


