import { Height } from '@material-ui/icons';
export type ProjectSiteType = "SELECT" | "DRAW" | "IMPORT";
export type ShapeType = "PROJECT_SITE" | "VACANCY_INSIDE" | "VACANCY_OUTSIDE" | "ROAD" | "SKYLINE_CIRCLE" | "SKYLINE_LINE" | "BOUNDARY_SITE" | "TOPOGRAPHY";
export type ProjectUseDistrict =
  | "제1종전용주거지역"
  | "제2종전용주거지역"
  | "제1종일반주거지역"
  | "제2종일반주거지역"
  | "제3종일반주거지역"
  | "준주거지역"
  | "중심상업지역"
  | "일반상업지역"
  | "근린상업지역"
  | "유통상업지역"
  | "전용공업지역"
  | "일반공업지역"
  | "준공업지역"
  | "보전녹지지역"
  | "생산녹지지역"
  | "자연녹지지역"
  | "보전관리지역"
  | "생산관리지역"
  | "계획관리지역"
  | "농림지역"
  | "자연환경보전지역";
export type BuildingType = "미정" | "아파트" | "다세대주택" | "연립주택" | "도시형생활주택 - 원룸" | "도시형생활주택 - 다세대주택" | "도시형생활주택 - 연립주택" | "오피스텔" | "MULTIPLE";
export type DesignStage = "CONCEPT_DESIGN" | "SCHEMATIC_DESIGN" | "DESIGN_DEVELOPMENT" | "CONSTRUCTION_DESIGN";
export type BuildingStoriesAvgType = "NUMERICAL" | "AREA" | "HOUSE";
export type ProjectStatus = "CONFIG" | "WAITING" | "RUNNING" | "FINISHED" | "FAILED";
export type ProjectType = "AI" | "CAL" | "DESIGNER" | "SITE_PLAN" | "UNDEFINED";
export type SetbackRegulationType = "METER" | "HEIGHT";
export type SolverTypes = "대규모" | "중규모" | "소규모" | "오피스텔";
export type BuildingShapeType = "LINEAR_CORRIDOR_TYPE" | "FOLDED_CORRIDOR_TYPE" | "LINEAR_NON_CORRIDOR_TYPE" | "FOLDED_NON_CORRIDOR_TYPE" | "T_TOWER_TYPE" | "Y_TOWER_TYPE";
export type JimogType =
  | "대"
  | "임"
  | "전"
  | "답"
  | "과"
  | "목"
  | "잡"
  | "자"
  | "창"
  | "광"
  | "염"
  | "학"
  | "차"
  | "도"
  | "철"
  | "제"
  | "천"
  | "구"
  | "유"
  | "양"
  | "수"
  | "공"
  | "체"
  | "원"
  | "종"
  | "사"
  | "묘"
  | "주";

export enum ProjectUseDistrictEnum {
  "제1종전용주거지역" = 0,
  "제2종전용주거지역" = 1,
  "제1종일반주거지역" = 2,
  "제2종일반주거지역" = 3,
  "제3종일반주거지역" = 4,
  "준주거지역" = 5,
  "중심상업지역" = 6,
  "일반상업지역" = 7,
  "근린상업지역" = 8,
  "유통상업지역" = 9,
  "전용공업지역" = 10,
  "일반공업지역" = 11,
  "준공업지역" = 12,
  "보전녹지지역" = 13,
  "생산녹지지역" = 14,
  "자연녹지지역" = 15,
  "보전관리지역" = 16,
  "생산관리지역" = 17,
  "계획관리지역" = 18,
  "농림지역" = 19,
  "자연환경보전지역" = 20,
}

export enum DesignStageEnum {
  "CONCEPT_DESIGN" = 0,
  "SCHEMATIC_DESIGN" = 1,
  "DESIGN_DEVELOPMENT" = 2,
  "CONSTRUCTION_DESIGN" = 3,
}
export enum BuildingTypeEnum {
  // "미정" = 0,
  "아파트" = 1,
  "다세대주택" = 2,
  "연립주택" = 3,
  "도시형생활주택 - 원룸" = 4,
  "도시형생활주택 - 다세대주택" = 5,
  "도시형생활주택 - 연립주택" = 6,
  "오피스텔" = 7,
}
export enum BuildingStoriesAvgTypeEnum {
  "NUMERICAL" = 0,
  "AREA" = 1,
  "HOUSE" = 2,
}

export class Project {
  constructor(input?: Project) {
    if (input) {
      Object.keys(input).forEach((k:any) => {
        //@ts-ignore
        this[k as keyof Project] = input[k as keyof Project];
      });
    }
  }
  /**
   * DEV/TEST/PROD
   */
  "stage"?: string;
  /**
   * global project id
   */
  "project_id"?: number;
  /**
   * user project id
   */
  "user_project_id"?: number;
  /**
   * copy project id
   */
  "copy_project_id"?: number;
  /**
   * parent_project_id ( created at BUILDIT DESIGNER )
   */
  // 'parent_project_id'?: number;
  // /**
  // * parent_project_report_num ( created at BUILDIT DESIGNER )
  // */
  // 'parent_project_report_num'?: number;
  /**
   * user email
   */
  "email"?: string;
  /**
   * version
   */
  "version"?: string = "0.0.1";
  /**
   * 프로젝트 상태 (설정중/대기중/분석중/완료/확인필요/취소됨)
   */
  "status"?: ProjectStatus = "CONFIG";
  "finished_ai"?: Array<boolean> = [];
  /**
   * 프로젝트 타입 (AI/CAL/DESIGNER/SITEPLAN/UNDEFINED)
   */
  "project_type"?: ProjectType = "AI";
  /**
   * 프로젝트 이름
   */
  "project_name"?: string = "";
  /**
   * 대지영역 주소
   */
  "project_address"?: string = "";
  /**
   * 대지영역 용도지역
   */
  "project_use_district"?: ProjectUseDistrict;
  /**
   * 건축물 주용도 (아파트/다세대/연립/오피스텔/도시형생활주택/미정)
   */
  "building_type"?: BuildingType;
  /**
   * 설명
   */
  "project_explanation"?: string = "";
  /**
   * 발주처
   */
  "ordering_companay"?: string = "";
  /**
   * 발주처 담당자
   */
  "ordering_manager"?: string = "";
  /**
   * 작성자
   */
  "drafter"?: string = "";
  /**
   * 작성자 연락처
   */
  "drafter_contact_number"?: string = "";
  /**
   * 건축설계 단계
   */
  "design_stage"?: DesignStage = "CONCEPT_DESIGN";
  /**
   * 관련 건축설계사무소
   */
  "design_office"?: string = "";
  /**
   * 시공사
   */
  "builder"?: string = "";
  /**
   * 대지영역 설정 타입
   */
  "project_site_type"?: ProjectSiteType = "SELECT";
  // 2021.03.15 지목은 필드는 생성하였으나, 어떻게 활용할 계획이 없어 값을 넣지는 않음
  "jimog"?: Array<JimogType> = [];
  "project_site"?: Array<string> = [];
  "project_site_center"?: string; // project_site 들의 center
  "boundary_site"?: Array<string | null> = [];
  "road_value"?: Array<Array<number>> = [];
  "road_site"?: Array<string | null> = [];
  "vacancy_outside"?: Array<string> = [];
  "vacancy_inside"?: Array<string> = [];
  "skyline_circle"?: Array<string> = [];
  "skyline_line"?: Array<string> = [];
  "field_info_pnu"?: Array<string> = [];
  "building_shape_type"?: Array<ProjectBuildingShapeType> = [];
  "housing_plan_type"?: Array<ProjectHousingPlanType> = [];
  "my_building_shape_type"?: Array<ProjectMyBuildingShapeType> = []; //
  "project_site_area"?: number = 0;
  "vacancy_inside_area"?: number = 0;
  "vacancy_outside_area"?: number = 0;
  /**
   * 건폐율
   */
  "default_building_land_ratio"?: number = 0;
  /**
   * 용적률
   */
  "default_floor_area_ratio"?: number = 0;
  /**
   * 건폐율
   */
  "building_land_ratio"?: number = 0;
  /**
   * 용적률
   */
  "floor_area_ratio"?: number = 0;
  /**
   * 층고
   */
  "floor_height"?: number = 2.8;
  /**
   * 보고서 수
   */
  "reports_number"?: number = 10;
  /**
   * 최고층수
   */
  "building_stories_max"?: number = 0;
  /**
   * 최저층수
   */
  "building_stories_min"?: number = 0;
  /**
   * 평균층수
   */
  "building_stories_avg"?: number = 0;
  /**
   * 평균층수 산정 타입
   */
  "building_stories_avg_type"?: BuildingStoriesAvgType = "AREA";
  /**
   * 정북일조 9m 미만
   */
  "setback_regulation_from_north_less_9m"?: number = 0;
  /**
   * 정북일조 9m 미만 단위 ( METER or HEIGHT )
   */
  "setback_regulation_from_north_less_9m_type"?: SetbackRegulationType = "METER";
  /**
   * 정북일조 9m 이상
   */
  "setback_regulation_from_north_more_9m"?: number = 0;
  /**
   * 정북일조 9m 이상 단위 ( METER or HEIGHT )
   */
  "setback_regulation_from_north_more_9m_type"?: SetbackRegulationType = "HEIGHT";
  /**
   * 인접대지경계선 인동거리 ( unit: HEIGHT)
   */
  "setback_regulation_from_site_boundary"?: number = 0;
  /**
   * 채광창 벽면 인동거리 ( unit: HEIGHT )
   */
  "distance_between_window_opaque_walls"?: number = 0;
  /**
   * 벽면 측벽 인동거리 ( unit: METER )
   */
  "distance_between_side_opaque_walls"?: number = 0;
  /**
   * 측벽 측벽 인동거리 ( unit: METER )
   */
  "distance_between_side_walls"?: number = 0;
  /**
   * 건축선 후퇴 - 아파트
   */
  "setback_building_line_apartment"?: number = 0;
  /**
   * 건축선 후퇴 - 연립주택
   */
  "setback_building_line_row_house"?: number = 0;
  /**
   * 건축선 후퇴 - 다세대주택
   */
  "setback_building_line_multi_house"?: number = 0;
  /**
   * 건축선 후퇴 - 오피스텔
   */
  "setback_building_line_officetel"?: number = 0;
  /**
   * 인접대지선 후퇴 - 아파트
   */
  "setback_site_boundary_apartment"?: number = 0;
  /**
   * 인접대지선 후퇴 - 연립주택
   */
  "setback_site_boundary_row_house"?: number = 0;
  /**
   * 인접대지선 후퇴 - 다세대주택
   */
  "setback_site_boundary_multi_house"?: number = 0;
  /**
   * 인접대지선 후퇴 - 오피스텔
   */
  "setback_site_boundary_officetel"?: number = 0;
  /*
    도시생활형 원룸/연립/다세대의 인접대지선후퇴 / 건축선후퇴
  */
  "setback_building_line_city_apartment"?: number = 0;
  "setback_building_line_city_row_house"?: number = 0;
  "setback_building_line_city_multi_house"?: number = 0;
  "setback_site_boundary_city_apartment"?: number = 0;
  "setback_site_boundary_city_row_house"?: number = 0;
  "setback_site_boundary_city_multi_house"?: number = 0;
  /**
   * 하천 조망점수
   */
  "view_type_river"?: number = 25;
  /**
   * 녹지 조망점수
   */
  "view_type_green"?: number = 25;
  /**
   * 하늘 조망점수
   */
  "view_type_sky"?: number = 25;
  /**
   * 랜드마크 조망점수
   */
  "view_type_landmark"?: number = 25;
  /**
   * 요구 평균일조시간
   */
  "daylight_hours_demanded_avg"?: number = 2;
  /**
   * 요구 평균일조시간 최소 비율
   */
  "daylight_hours_proportion_less_avg"?: number = 50;
  /**
   * project created
   */
  "created_at"?: string = new Date().toISOString();
  /**
   * project modified
   */
  "modified_at"?: string = new Date().toISOString();
  /**
   * project deleted
   */
  "deleted_at"?: string = "";
  /**
   * project last viewed
   */
  "viewed_at"?: string = new Date().toISOString();
  /**
   * cancel analyzer
   */
  "canceled_at"?: string = "";
  /**
   * before starting analyzer
   */
  "queued_at"?: string = "";
  /**
   * start analyzer
   */
  "run_at"?: string = "";
  /**
   * finish analyzer
   */
  "finished_at"?: string = "";
  /**
   * 할인 적용 대상
   */
  "discountable"?: boolean = false;
  /**
   * 삭제
   */
  "deleted"?: boolean = false;
  /**
   * 휴지통
   */
  "discarded"?: boolean = false;
  /**
   * 즐겨찾기
   */
  "favorite"?: boolean = false;
  /**
   * 자동설정 options
   */
  "auto_setting"?: boolean = true;
  /**
   * 제목 자동설정 options
   */
  "auto_name"?: boolean = true;
  "auto_road"?: boolean = false;
  "sigungu"?: string;
  "solver_type"?: SolverTypes;
  /**
   * 선택된 배치안 id
   */
  "selected_my_building_plan_id"?: number; // item's global_id
  "selected_my_site_id"?: number; // item's global_id
  "multi_select_project_site"?: boolean = false;

  "housing_rate"?: number;
  "topography"?: Array<{polygon: Array<string>, height: number}>
}

export class ProjectMyBuildingShapeType {
  constructor(input?: ProjectMyBuildingShapeType) {
    if (input) {
      Object.keys(input).forEach((k) => {
        // @ts-ignore
        this[k as keyof ProjectMyBuildingShapeType] = input[k as keyof ProjectMyBuildingShapeType];
      });
    }
  }

  id?: number;
  proportion?: number = 0;
}

export class ProjectBuildingShapeType {
  constructor(input?: ProjectBuildingShapeType) {
    if (input) {
      Object.keys(input).forEach((k) => {
        // @ts-ignore
        this[k as keyof ProjectBuildingShapeType] = input[k as keyof ProjectBuildingShapeType];
      });
    }
  }
  /**
   * 동 타입 종류
   */
  "figures"?: string;
  /**
   * 동 타입의 세대 수
   */
  "houses_number"?: number;
}

export class ProjectHousingPlanType {
  constructor(input?: ProjectHousingPlanType) {
    if (input) {
      Object.keys(input).forEach((k) => {
        // @ts-ignore
        this[k as keyof ProjectHousingPlanType] = input[k as keyof ProjectHousingPlanType];
      });
    }
  }
  /**
   * 세대 타입 면적
   */
  "area"?: number;
  /**
   * 베이수
   */
  "bay"?: number = 3;
  /**
   * 비율
   */
  "proportion"?: number = 0;
  /**
   * 최소 세대수
   */
  "minimum_houses_number"?: number;

  "userdefarea"?: boolean | undefined;
}
