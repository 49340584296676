import React, { Component, Fragment } from "react";
import { InlineIcon } from "@iconify/react";
import downloadOutlined from "@iconify/icons-ant-design/download-outlined";
import OpenInNewRounded from "@material-ui/icons/OpenInNewRounded";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Tooltip from "./Tooltip";
import BuilditTemplate from "./BuilditTemplate";
import "./css/CustomerSupport.scss";
import { UpdateOutlined } from "@material-ui/icons";
import Clipboard from "react-clipboard.js";

export default class CustomerSupport extends Component {
  render() {
    return (
      <BuilditTemplate footer={true} {...this.props}>
        <div className="CustomerSupport">
          <CustomerSupportHeader></CustomerSupportHeader>
          <CustomerSupportBody></CustomerSupportBody>
        </div>
      </BuilditTemplate>
    );
  }
}

class CustomerSupportHeader extends Component {
  render() {
    return (
      <div className="CustomerSupportHeader">
        <div className="wrapper">
          <div className="content">
            <div className="header">
              <div className="breadcrumb">{"HOME > 고객지원&FAQ"}</div>
            </div>
            <div className="body">
              <div className="page-title">{"고객지원 & FAQ"}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface content_info {
  category: string;
  title: string;
  content: JSX.Element;
}

class CustomerSupportBody extends Component {
  state = {
    openTooltip: false,
  };

  copyMailAddress = () => {
    this.setState(
      {
        openTooltip: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            openTooltip: false,
          });
        }, 1500);
      }
    );
  };

  render() {
    const table_content: Array<content_info> = [
      { category: "계정", title: "한 아이디 당 동시 접속이 가능한가요?", content: <div>네. 가능합니다.</div> },
      {
        category: "계정",
        title: "비밀번호를 잊어버리면 어떻게 해야 하나요?",
        content: (
          <div>
            로그인 페이지에서 비밀번호 재설정이 가능합니다. <br /> {`로그인 페이지 > 비밀번호 재설정 > 이메일`}
          </div>
        ),
      },
      { category: "계정", title: "가입했던 이메일을 잊어버리면 어떻게 해야 하나요?", content: <div>02-375-1011으로 전화주시기 바랍니다.</div> },
      { category: "보안", title: "프로젝트 보안은 어떻게 되나요?", content: <div>로그인 한 계정 이외에는 어떠한 접근도 허용되지 않습니다.</div> },
      { category: "기능", title: "원클릭실행이 뭔가요?", content: <div>BUILDIT AI 실행을 위 입력에 필요한 모든 파라미터를 자동을 최적화 해주어 결과를 내어주는 기능입니다.</div> },
    ];
    return (
      <div className="CustomerSupportBody">
        <div className="wrapper">
          <div className="support">
            <div className="support-header">고객 지원</div>
            <div
              className="button guide-btn"
              onClick={(e) => {
                window.open("https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf");
              }}
            >
              <div className="title">
                <div className="content">📖BUILDIT 매뉴얼 바로가기</div>
                <OpenInNewRounded className="icon" />
              </div>
              <div className="detail">
                <div className="content">
                  빠른 출발을 위해 단계별 지침서를
                  <br />
                  확인해주세요 (용어, 사용법)
                </div>
              </div>
            </div>
            <div className="button guide-btn" onClick={() => window.open("https://www.notion.so/1011x/BUILDIT-9ceadc2cd44549d290c554410798cd29")}>
              <div className="title">
                <div className="content">🕒업데이트 안내 바로가기</div>
                <OpenInNewRounded className="icon" />
              </div>
              <div className="detail">
                <div className="content">
                  BUILDIT의 업데이트 내역을
                  <br />
                  확인하시려면 클릭해주세요!
                </div>
              </div>
            </div>
            <div className="button guide-btn-no-link">
              <div className="title">
                <div className="content">📨 1:1 문의 하기</div>
              </div>
              <div className="detail">
                <div className="content">
                  문의 사항을 아래 메일로 보내주시면 빠른 시간 안에 답변을 드리겠습니다:) <br />
                  <Clipboard data-clipboard-text={"support@1011.co.kr"} className="email-copy-btn" onClick={this.copyMailAddress}>
                    <Tooltip on={this.state.openTooltip} msg="클립보드에 저장 되었습니다" className="tooltip" place="right">
                      <span className="accent">support@1011.co.kr</span>
                    </Tooltip>
                  </Clipboard>
                </div>
              </div>
            </div>
          </div>
          <div className="FAQ">
            <div className="faq-header">FAQ</div>
            <div className="faq-table">
              <div className="table-header">
                <div className="division">구분</div>
                <div className="content">내용</div>
              </div>
              <div className="table-content">
                <FAQExpansionPanel
                  defaultExpanded={true}
                  category={"공지"}
                  title={"런칭을 기다려주세요."}
                  content={
                    <div>
                      텐일레븐 빌드잇 서비스에 관심 가져주셔서 감사합니다. 현재 빌드잇 인터넷 서비스는 런칭 이전이라 사용하실 수 없으신 상황입니다만, 사업타당성검토는 컨설팅 형태로 서비스를 제공하고
                      있습니다. 검토하실 필지를 알려주시면, 1차 (개략)검토안의 경우 30만원, 2차부터는 회당 70만원으로 컨설팅 서비스를 제공하고 있습니다. (컨설팅 내용에 따라 비용은 달라질 수 있습니다.)
                      메일 주소를 알려주시면 1차, 2차 보고서 샘플을 보내드릴 수 있을 것 같습니다. 기타 궁금하신 사항이 있으시면, 1011@1011.co.kr로 언제든지 문의주세요. 감사합니다.
                    </div>
                  }
                ></FAQExpansionPanel>
                {table_content.map((e) => {
                  return <FAQExpansionPanel category={e.category} title={e.title} content={e.content} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

interface FAQExpansionPanelProps extends content_info {
  defaultExpanded?: boolean;
}

interface FAQExpansionPanelState {
  isExpand: boolean;
}

class FAQExpansionPanel extends Component<FAQExpansionPanelProps, FAQExpansionPanelState> {
  state: FAQExpansionPanelState = {
    isExpand: this.props.defaultExpanded ? true : false,
  };

  clickExpandPannel = () => {
    this.setState({ isExpand: !this.state.isExpand });
  };

  render() {
    return (
      <ExpansionPanel classes={{ root: "table-row", expanded: "expanded" }} expanded={this.state.isExpand} onChange={this.clickExpandPannel}>
        <ExpansionPanelSummary classes={{ root: "row-header", expanded: "expanded" }} expandIcon={<ExpandMoreIcon className="icon" />}>
          <div className="category">{this.props.category}</div>
          <div className="title">{this.props.title}</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className="row-content">
          <div className="content_column">{this.props.content}</div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}
