import React, { Component } from 'react';
import { Info } from '@material-ui/icons';
import { updateDynamoDBData, getSettingData } from './DBManager';
import Tooltip from '../Tooltip';
import { Button } from '@material-ui/core';

export interface settingData {
  enable: boolean,
  value: number,
}

export class myTypeSettingData {
  windowOffset: settingData; // 창문 이격거리
  areaOffset: settingData; // 면적
  layerOffset: settingData; // 레이어 이격
  layerOverlap: settingData; // 레이어/폴리곤 교차
  unClosedPolygon: settingData; // 닫혀있지 않은 폴리곤
  polygonOffset: settingData; // 폴리곤 이격
  settingDBTable = 'platform-buildit-cad-converter-user-setting';

  constructor() {
    this.windowOffset = { enable: false, value: 1 } //200
    this.areaOffset = { enable: false, value: 10 }
    this.layerOffset = { enable: false, value: 200 }
    this.layerOverlap = { enable: false, value: 10 }
    this.unClosedPolygon = {enable: false, value: 0}
    this.polygonOffset = {enable: false, value: 0};
  }

  updateDataFromDB = (settingData: myTypeSettingData) => {
    this.windowOffset.enable = settingData.windowOffset ? settingData.windowOffset.enable : this.windowOffset.enable;
    this.windowOffset.value = settingData.windowOffset ? settingData.windowOffset.value : this.windowOffset.value;
    this.areaOffset.enable = settingData.areaOffset ? settingData.areaOffset.enable : this.areaOffset.enable;
    this.areaOffset.value = settingData.areaOffset ? settingData.areaOffset.value : this.areaOffset.value;
    this.layerOffset.enable = settingData.layerOffset ? settingData.layerOffset.enable : this.layerOffset.enable;
    this.layerOffset.value = settingData.layerOffset ? settingData.layerOffset.value : this.layerOffset.value;
    this.layerOverlap.enable = settingData.layerOverlap ? settingData.layerOverlap.enable : this.layerOverlap.enable;
    this.layerOverlap.value = settingData.layerOverlap ? settingData.layerOverlap.value : this.layerOverlap.value;
    this.unClosedPolygon.enable = settingData.unClosedPolygon ? settingData.unClosedPolygon.enable : this.unClosedPolygon.enable;
    this.polygonOffset.enable = settingData.polygonOffset ? settingData.polygonOffset.enable : this.polygonOffset.enable;
  }

  updatePolygonOffsetEnable = async(value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.polygonOffset.enable = value;
    this.polygonOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);

  }
  updateUnClosedPolygonEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.unClosedPolygon.enable = value;
    this.unClosedPolygon.enable = value;
    updateDynamoDBData(this.settingDBTable, data);

  }

  updateWindowOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.windowOffset.enable = value;
    this.windowOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateWindowOffsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.windowOffset.value = value;
    this.windowOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateAreaOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.areaOffset.enable = value;
    this.areaOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateAreaOffsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.areaOffset.value = value;
    this.areaOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOffsetEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOffset.enable = value;
    this.layerOffset.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOffsetsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOffset.value = value;
    this.layerOffset.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOverlapEnable = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOverlap.enable = value;
    this.layerOverlap.enable = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateLayerOverlapsetValue = async (value: number) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.myType.layerOverlap.value = value;
    this.layerOverlap.value = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  getData = () => {
    return {
      windowOffset: this.windowOffset,
      areaOffset: this.areaOffset,
      layerOffset: this.layerOffset,
      layerOverlap: this.layerOverlap,
      unClosedPolygon: this.unClosedPolygon, // 닫혀있지 않은 폴리곤
      polygonOffset: this.polygonOffset, // 폴리곤 이격
    }
  }
}

interface myTypeSettingProps {
  myTypeSettingData: myTypeSettingData,
  changeSettingData: (optionName: string, value: any) => void,
}

interface myTypeSettingState {
  windowOffsetEnable: boolean,
  windowOffsetValue: number,
  areaOffsetEnable: boolean,
  areaOffsetValue: number,
  layerOffsetEnable: boolean,
  layerOffsetValue: number,
  layerOverlapEnable: boolean,
  layerOverlapValue: number,
  unClosedPolygonEnable: boolean,
  polygonOffsetEnable: boolean,
}

export class MyTypeSettingDataUI extends Component<myTypeSettingProps, myTypeSettingState>{

  state: myTypeSettingState = {
    areaOffsetEnable: this.props.myTypeSettingData.areaOffset.enable,
    areaOffsetValue: this.props.myTypeSettingData.areaOffset.value,
    layerOffsetEnable: this.props.myTypeSettingData.layerOffset.enable,
    layerOffsetValue: this.props.myTypeSettingData.layerOffset.value,
    layerOverlapEnable: this.props.myTypeSettingData.layerOverlap.enable,
    layerOverlapValue: this.props.myTypeSettingData.layerOverlap.value,
    windowOffsetEnable: this.props.myTypeSettingData.windowOffset.enable,
    windowOffsetValue: this.props.myTypeSettingData.windowOffset.value,
    unClosedPolygonEnable: this.props.myTypeSettingData.unClosedPolygon.enable,
    polygonOffsetEnable: this.props.myTypeSettingData.polygonOffset.enable,
  }

  render = () => {
    return (
      <React.Fragment>
        <div className='item'>
          <div className='mainContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>창문 - 세대간 이격거리 오차 보정</span>
            <Tooltip arrowOn={false} msg="설정 하신 창문 - 세대 간 이격거리를 입력 범위 내에서 보정합니다.">
              <Info className='icon info' />
            </Tooltip>
            </span>
            <input className='inputBox' type='number' value={this.state.windowOffsetValue} 
            onChange={e => {
              this.setState({ windowOffsetValue: Number(e.target.value) }); 
              this.props.changeSettingData('windowOffsetValue', Number(e.target.value));
            }} />
            {/*  ${!this.state.windowOffsetEnable && 'active'} */}
            <span className={`text`} style={{ margin: 'auto auto auto 9px' }}>mm 이내일 경우</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.windowOffsetEnable} 
            onClick={(e) => { this.setState({ windowOffsetEnable: e.currentTarget.checked }); 
            this.props.changeSettingData('windowOffsetEnable', e.currentTarget.checked);
            //this.props.myTypeSettingData.updateWindowOffsetEnable(e.currentTarget.checked) 
            }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.windowOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 보정</span>
          </div>
          {
            (this.state.windowOffsetValue > 100 || this.state.windowOffsetValue < 1) &&
            <div className={`description ${!this.state.windowOffsetEnable && 'hidden'}`}><span>1~100mm이하의 값을 입력해주세요.</span></div>
          }
          {/* <div className={`description ${!this.state.windowOffsetEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div> */}
          
        </div>
        {/* <div className='item'>
          <div className='mainContent'>
            <span style={{ width: '217px', display: 'flex' }}>
              <span className='name'>면적오차 허용 범위</span>
              <Tooltip msg="1% ~ 200% 입력 가능">
                <Info className='icon' />
              </Tooltip>
            </span>
            <input className='inputBox' type='number' value={this.state.areaOffsetValue} 
            onChange={e => { 
              this.setState({ areaOffsetValue: Number(e.target.value) }); 
              if (Number(e.target.value) >= 1 && Number(e.target.value) <= 200) {
                this.props.myTypeSettingData.updateAreaOffsetValue(Number(e.target.value)) 
              }
            }} />
            <span className={`text ${!this.state.areaOffsetEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>% 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.areaOffsetEnable} onClick={(e) => { this.setState({ areaOffsetEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateAreaOffsetEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.areaOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 보정</span>
          </div>
          { <div className={`description ${!this.state.areaOffsetEnable && 'hidden'}`}><span>면적의 오차가 보정되지 않습니다.</span></div> }
        </div> */}
        {/* <div className='item'>
          <div className='mianContent'>
            <span style={{ width: '217px', display: 'flex' }}><span className='name'>레이어 간의 이격 거리</span><Info className='icon' /></span>
            <input className='inputBox' type='number' value={this.state.layerOffsetValue} onChange={e => { this.setState({ layerOffsetValue: Number(e.target.value) }); this.props.myTypeSettingData.updateLayerOffsetsetValue(Number(e.target.value)) }} />
            <span className={`text ${!this.state.layerOffsetEnable && 'active'}`} style={{ margin: 'auto auto auto 9px' }}>mm 초과 시 알림</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.layerOffsetEnable} onClick={(e) => { this.setState({ layerOffsetEnable: e.currentTarget.checked }); this.props.myTypeSettingData.updateLayerOffsetEnable(e.currentTarget.checked) }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.layerOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>항상 알림</span>
          </div>
          <div className={`description ${!this.state.layerOffsetEnable && 'hidden'}`}><span>레이어 간의 이격 거리가 보정되지 않습니다.</span></div>
        </div> */}
        <div className='item'>
          <div className='mainContent'>
            <span style={{ width: '217px', display: 'flex' }}>
              <span className='name'>폴리곤 간 교차 보정</span>
              <Tooltip arrowOn={false} msg="설정 하신 폴리곤 사이의 교차범위를 해당 범위 내에서 보정합니다.">
                <Info className='icon info' />
              </Tooltip>
            </span>
            <span className={`text`} style={{ margin: 'auto auto auto 9px' }}>0.1% 이하 오차 범위 내</span>
            {/* <input className='inputBox' type='number' value={this.state.layerOverlapValue}
              onChange={e => {
                this.setState({ layerOverlapValue: Number(e.target.value) });
                if (Number(e.target.value) >= 1 && Number(e.target.value) <= 10) {
                  this.props.myTypeSettingData.updateLayerOverlapsetValue(Number(e.target.value));
                }
              }} />
            <span className={`text active`} style={{ margin: 'auto auto auto 9px' }}>% 초과 시</span> */}

            <label className="switch"> <input type="checkbox" defaultChecked={this.state.layerOverlapEnable}
              onClick={(e) => {
                this.setState({ layerOverlapEnable: e.currentTarget.checked }); 
                this.props.changeSettingData('layerOverlapEnable', e.currentTarget.checked);
             //   this.props.myTypeSettingData.updateLayerOverlapEnable(e.currentTarget.checked)
              }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.layerOverlapEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 보정</span>
          </div>
          {/* <div className={`description ${!this.state.layerOverlapEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div> */}
        </div>
        <div className='item'>
          <div className='mainContent'>
            <span style={{ width: '217px', display: 'flex' }}>
              <span className='name'>폴리곤 간 이격 보정</span>
              <Tooltip arrowOn={false} msg="설정 하신 폴리곤 사이의 미세한 이격 거리를 해당 범위 내에서 보정합니다.">
                <Info className='icon info' />
              </Tooltip>
            </span>
            <span className={`text`} style={{ margin: 'auto auto auto 9px' }}>1mm 이하 오차 범위 내</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.polygonOffsetEnable}
              onClick={(e) => {
                this.setState({ polygonOffsetEnable: e.currentTarget.checked });
                this.props.changeSettingData('PolygonOffsetEnable', e.currentTarget.checked);
              }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.polygonOffsetEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 보정</span>
          </div>
          {/* <div className={`description ${!this.state.layerOverlapEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div> */}
        </div>
        <div className='item'>
          <div className='mainContent'>
            <span style={{ width: '217px', display: 'flex' }}>
              <span className='name'>닫혀 있지 않은 폴리곤 오차 보정</span>
              <Tooltip arrowOn={false} msg="설정 하신 폴리곤이 닫혀 있지 않을 시 해당 범위 내에서 보정합니다.">
                <Info className='icon info' />
              </Tooltip>
            </span>
            <span className={`text`} style={{ margin: 'auto auto auto 9px' }}>1mm 이하 오차 범위 내</span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.unClosedPolygonEnable}
              onClick={(e) => {
                this.setState({ unClosedPolygonEnable: e.currentTarget.checked });
                this.props.changeSettingData('unClosedPolygonEnable', e.currentTarget.checked);
              }} /> <span className="slider" /> </label>
            <span className={`text ${this.state.unClosedPolygonEnable && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 보정</span>
          </div>
          {/* <div className={`description ${!this.state.layerOverlapEnable && 'hidden'}`}><span>이격 거리가 보정되지 않습니다.</span></div> */}
        </div>
      
      </React.Fragment>
    )
  }
}