import React, { Component } from 'react';
import Refresh from '@material-ui/icons/Refresh';
import Settings from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import '../css/CADConverter/ConverterHeader.scss';
import { Button } from '@material-ui/core';
import { ConverterType } from './DataTypes';
import { buttonNum } from './Modal';
import { Home, Info, NavigateNext } from '@material-ui/icons';
import Tooltip from '../Tooltip';
import { ReactComponent as OpenIcon } from '../img/icon/ic-open.svg';
import MapDownloadModal from './MapDownloadModal';
import HeaderDropBox from './HeaderDropBox';
import '../css/CADConverter/DropBox.scss';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ReactComponent as TaskAlt } from '../img/icon/taskAlt.svg';

interface HeadProps {
  type: ConverterType,
  email: string,
  errorSize: number,
  warningSize: number,
  infoSize?: number,
  isFileOpen: boolean,
  loadFile: (e: React.ChangeEvent<HTMLInputElement>) => void,
  saveFile: () => void,
  reset: () => void,
  showErrorLog?: () => void,
  openSettingModal: () => void,
  showModal: (title: string, content: string[], buttonNum: buttonNum, func: () => void) => void,
}

interface HeadState {
  showChangePageBox: boolean,
  showModal: boolean,
}

export class ConverterHeader extends Component<HeadProps, HeadState> {
  state: HeadState = {
    showChangePageBox: false,
    showModal: false,
  }


  SwitchPage = (converterType: ConverterType) => {
    switch (converterType) {
      case ConverterType.myType: // layer 버전: "/cad/MyBuildingType"
        this.props.showModal!('알림', ['나의 동평면 페이지로 이동 하겠습니까?'], buttonNum.twoButton, () => { window.open("/cad/myTypeBlock", "_self") });
        break;
      case ConverterType.mySite: // layer 버전 : "/cad/MySite"
        this.props.showModal!('알림', ['나의 사업영역 페이지로 이동 하겠습니까?'], buttonNum.twoButton, () => { window.open("/cad/mySiteBlock", "_self") });
        break;
      case ConverterType.myPlane: // layer 버전 : "/cad/MyBuildingPlan"
        this.props.showModal!('알림', ['나의 배치안 페이지로 이동 하겠습니까?'], buttonNum.twoButton, () => { window.open("/cad/myPlaneBlock", "_self") });
        break;
      default:
        break;
    }
  }

  turnOffModal() {
    this.setState({ showModal: false })
  }
  render = () => {
    let goFileSetting; 
      
    if (this.props.type === "사업영역") {
      goFileSetting = 
          <Link to={`/myPage/file/mySite`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>
    }  
    else if (this.props.type === "동평면") {
      goFileSetting =
          <Link to={`/myPage/file/mybuildingType`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>
    }
    else {
      goFileSetting =
          <Link to={`/myPage/file/myPlan`} className="text-deco-none">
            <div className="file-setting font font-18px font-primary">파일 관리</div>
          </Link>

    }

    return (
      <header className="font font-roboto">
        <MapDownloadModal
          isShow={this.state.showModal}
          turnOffModal={() => this.turnOffModal()}
        />
        <div className='Head'>
          <div className="breadScrum">
          <Tooltip arrowOn={false} msg="BUILDIT PLATFORM으로 이동">

            <div className='logoDiv' onClick={() => window.open("/", "_self")} >
           
                <img src="/img/buildit_platform_logo.svg" className="logoImg" />

            </div>
            </Tooltip>

            <NavigateNext className="icon next-icon first" />
            <Tooltip arrowOn={false} msg="파일 관리 페이지로 이동">
            {goFileSetting}
            </Tooltip>

            <NavigateNext className="icon next-icon" />
            <Tooltip arrowOn={false} msg="CAD CONVERTER 홈으로 이동">
              <Link to="/cad/cadConverterHome">
                <IconButton className="home-btn">
                  <Home className="m-l-md icon font font-emphasis home-icon" />
                </IconButton>
              </Link>
            </Tooltip>
          </div>

          <div className="middle-wrap">
            <HeaderDropBox type={this.props.type} />
            {
              (this.props.type === "사업영역" || this.props.type === "배치안") &&
              <Tooltip msg="사업대상지를 포함한 지정영역을 dxf로 다운로드 할 수 있습니다." arrowOn={false}>
                <Button className="btn bg-navy btn-primary download-cadastral-map-btn font font-noto m-l-md" onClick={() => { // 
                  this.setState({ showModal: true });
                }}>지적도 다운로드</Button>
              </Tooltip>
            }

          </div>

          {/* <div className='dropdownButton' >
            <div className='mainText' onClick={() => this.setState({ showChangePageBox: !this.state.showChangePageBox })}>
                <span className='name'>{`CAD Converter ${this.props.type}`}</span>
                <ExpandMore className={`arrowDown ${this.state.showChangePageBox && 'rotate'}`} />
            </div>
            {
              (this.props.type === "사업영역" || this.props.type === "배치안") &&
              <Tooltip msg="사업대상지를 포함한 지정영역을 dxf로 다운로드 할 수 있습니다." arrowOn={false}>
                <Button className="btn bg-navy btn-primary download-cadastral-map-btn font font-noto" onClick={() => { // 
                  this.setState({ showModal: true });
                }}>지적도 다운로드</Button>
              </Tooltip>
              }
            <div className={`dropbox ${this.state.showChangePageBox && 'show'}`}>
              <div className='cell' onClick={e => this.SwitchPage(ConverterType.mySite)} >
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.mySite && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.mySite}</span>
              </div>
              <div className='cell' onClick={e => this.SwitchPage(ConverterType.myType)}>
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.myType && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.myType}</span>
              </div>
              <div className='cell' onClick={e => this.SwitchPage(ConverterType.myPlane)}>
                <CheckIcon className={`checkIcon ${this.props.type !== ConverterType.myPlane && 'hidden'}`} />
                <span className='name'>CAD Converter</span><span className='typeName'>{ConverterType.myPlane}</span>
              </div>
            </div>
          </div> */}
          <div className='Tools'>
            <div className={`errorMessage`}>
              <span className="errorInfo">
                <TaskAlt className={`errorIcon icon completeIcon ${(this.props.errorSize > 0 || !this.props.isFileOpen) ? "disabled" : ""}`}/>
              </span>
              <span className={`errorInfo`}>
                <ErrorIcon className={`errorIcon icon ${this.props.errorSize === 0 && 'disabled'}`} /><span className='value font font-primary'>{this.props.errorSize}</span>
              </span>
              <span className={`errorInfo `}>
                <WarningIcon className={`warningIcon icon ${this.props.warningSize === 0 && 'disabled'}`} /><span className='value font font-primary'>{this.props.warningSize}</span>
              </span>
              <span className={`errorInfo `}>
                <Info className={`infoIcon icon ${this.props.infoSize === 0 && 'disabled'}`} /><span className='value font font-primary'>{this.props.infoSize}</span>
              </span>
            </div>
            <Tooltip msg={this.props.email} arrowOn={false}>
              <div className="btn btn-navy btn-primary account">
                {/* <div className='account tooltip'> */}
                {/* <span className="tooltiptext">{this.props.email}</span> */}
                <AccountCircle className='icon primary' />
              </div>
              {/* </div> */}
            </Tooltip>
            <Tooltip msg="파일 불러오기" arrowOn={false}>
              <label className='btn bg-navy btn-primary imageBox' >
                <input type="file" id="ex_file" style={{ display: 'none' }} onChange={this.props.loadFile} onClick={e => e.currentTarget.value = ''} accept='.dxf' multiple />
                <OpenIcon className="icon open-icon"></OpenIcon>
              </label>
            </Tooltip>
            <Tooltip msg="리셋" arrowOn={false}>
              <Button className='btn bg-navy btn-primary imageBox' onClick={this.props.reset}>
                <Refresh className='icon reset-icon' />
              </Button>
            </Tooltip>
            <Tooltip msg="설정" arrowOn={false}>
              <Button className='btn bg-navy btn-primary imageBox' onClick={this.props.openSettingModal}>
                <Settings className='icon setting-icon' />
              </Button>
            </Tooltip>
            {/* <Button className={`btn bg-navy btn-run font font-noto saveButton
                ${!this.props.isFileOpen ? 'disabled' : ''}`

            } 
              onClick={this.props.saveFile} >
              {this.props.type} 저장
            </Button> */}
            {
              // 0903 수정
              <Button className={`btn bg-navy btn-run font font-noto saveButton 
                ${(this.props.isFileOpen && this.props.errorSize > 0) || !this.props.isFileOpen ? 'disabled' : ''}`}
                onClick={this.props.saveFile} >
                {this.props.type} 저장
              </Button>
            }
          </div>
        </div>
      </header>
    )
  }
}