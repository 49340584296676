import React, { Component } from 'react';
import App from './App';
import naver from '@teneleven/navermaps-v3';
import DrawingManager from './DrawingManager';
import { default as _ } from 'lodash';
import { TextureFilter } from 'three';
import * as turf from '@turf/turf';
import { CoordinateJS } from '@teneleven/protocols-ts-web/lib/map';
import { ListProjectRequestJS } from '@teneleven/protocols-ts-web/lib/db_manager';
import { Project } from './model/Project';
import DrawingManager2 from './DrawingManager2';
import { Map, Polygon, Circle, Polyline, Marker, MapOptions } from './Shape';
import Tabs from './Tabs';
import { Button } from '@material-ui/core';
const $ = require("jquery");

export interface MapViewOptions {
  projectSite: boolean;
  road: boolean;
  boundarySite: boolean;
  vacancyInside: boolean;
  vacancyOutside: boolean;
  topography: boolean;
  skyline: boolean;
}

interface MapViewProps {
  project?: Project;
  height?: number;
  viewOptions?: MapViewOptions;
  useWheel?: boolean;
  interaction?: boolean;
  size?: "SMALL" | "NORMAL";
  controlCadastral?: boolean;
  errorHandler?: () => void;
}
interface MapViewState {
  mapHeight: number;
  cadastralLayer: boolean;
}


export default class MapView extends Component<MapViewProps, MapViewState> {
  state: MapViewState = {
    mapHeight: (this.props.height && this.props.height) || 140,
    cadastralLayer: true,
    // mapHeight: 300
  };

  map?: Map;
  cadastralLayer?: any;
  dm?: DrawingManager2;

  projectSite: Polygon[] = [];
  roadSite: Polygon[] = [];
  vacancyInside: Polygon[] = [];
  vacancyOutside: Polygon[] = [];
  topographyPolygon: Polygon[] = [];
  skylineCircle: Circle[] = [];
  skylineLine: Polyline[] = [];
  startMarker: Marker[] = [];
  endMarker: Marker[] = [];
  boundarySite: Polygon[] = [];

  componentWillMount = () => {};

  componentDidMount = () => {
    console.log('pp', this.props.project);
    const options: MapOptions = {
      ref: this.refs.map,
      maxZoom: 21,
      cadastral: this.state.cadastralLayer,
    };
    if (this.props.interaction === false) {
      options.draggable = false;
      options.pinchZoom = false;
      options.scrollWheel = false;
      options.keyboardShortcuts = false;
      options.disableDoubleTapZoom = true;
      options.disableDoubleClickZoom = true;
      options.disableTwoFingerTapZoom = true;
    }

    if (this.props.useWheel !== undefined && this.props.useWheel === false) {
      options.zoomControl = true;
      options.wheel = false;
    }

    if (this.props.size && this.props.size === "SMALL") {
      options.logoControl = false;
      options.mapDataControl = false;
      options.scaleControl = false;
    } else {
    }
    try {
      this.dm = new DrawingManager2();
      this.map = this.dm!.createMap(options);
      this.addOverlays();
    } catch (e) {
      console.log(e);
      this.props.errorHandler && this.props.errorHandler();
    }
  };

  componentDidUpdate = async (pp: Readonly<MapViewProps>) => {
    if (_.isEqual(pp, this.props) === false) {
      try {
        this.addOverlays();
      } catch (e) {
        console.log(e);
        this.props.errorHandler && this.props.errorHandler();
      }
    }
    /* this.map = new App.naver.maps.Map(this.refs.map as HTMLElement, {
      mapTypes: App.naver.maps.StyleMapTypeOption.getMapTypes({initLayers: [
        App.naver.maps.StyleMapLayerId.BACKGROUND_DETAIL,
        App.naver.maps.StyleMapLayerId.POI_KOREAN,
        App.naver.maps.StyleMapLayerId.CADASTRAL,
    ]}),
      mapTypeId: App.naver.maps.StyleMapTypeId.NORMAL,
      center: this.props.project!.projectSiteCenter,
      zoom: 19,
    }); 

    // @ts-ignore

    this.drawingManager = new DrawingManager(this.map!); */

    // window.addEventListener('resize', e => this.setState({ mapHeight: window.innerHeight - 64}));

    /* test */
    /* let markers:any = [];
    this.props.projects && this.props.projects.map((r, i) => {
      const marker = new App.naver.maps.Marker({
        position: new App.naver.maps.LatLng(r.projectSiteCenter.lat, r.projectSiteCenter.lng),
        map: this.map
      });
      marker.setVisible(false);
      marker.pid = r.pid;
      markers.push(marker);
    });
    
    const htmlMarker1 = {
      content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:12px;color:white;text-align:center;font-weight:bold;background:url(/img/cluster.png);background-size:contain;"></div>',
      size: App.naver.maps.Size(40, 40),
      anchor: App.naver.maps.Point(20, 20)
    },
    htmlMarker2 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:12px;color:white;text-align:center;font-weight:bold;background:url/img/cluster.png);background-size:contain;"></div>',
        size: App.naver.maps.Size(40, 40),
        anchor: App.naver.maps.Point(20, 20)
    },
    htmlMarker3 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:12px;color:white;text-align:center;font-weight:bold;background:url(/img/cluster.png);background-size:contain;"></div>',
        size: App.naver.maps.Size(40, 40),
        anchor: App.naver.maps.Point(20, 20)
    },
    htmlMarker4 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:12px;color:white;text-align:center;font-weight:bold;background:url(/img/cluster.png);background-size:contain;"></div>',
        size: App.naver.maps.Size(40, 40),
        anchor: App.naver.maps.Point(20, 20)
    },
    htmlMarker5 = {
        content: '<div style="cursor:pointer;width:40px;height:40px;line-height:42px;font-size:12px;color:white;text-align:center;font-weight:bold;background:url(/img/cluster.png);background-size:contain;"></div>',
        size: App.naver.maps.Size(40, 40),
        anchor: App.naver.maps.Point(20, 20)
    };
    // @ts-ignore
    const markerClustering = new window.MarkerClustering({
      minClusterSize: 1,
      maxZoom: 22,
      map: this.map,
      markers: markers,
      disableClickZoom: true,
      gridSize: 500,
      icons: [htmlMarker1],
      indexGenerator: [1],
      stylingFunction: function(clusterMarker: any, count: any) {
        clusterMarker.addListener('click', (e: any) => {
          const id = clusterMarker._nmarker_id;
          const index = markerClustering._clusters.findIndex((e: any) => e._clusterMarker._nmarker_id === id);
          const pidList = markerClustering._clusters[index]._clusterMember.map((m: any) => m.pid);
        })
        $(clusterMarker.getElement()).find('div:first-child').text(count);
      }
    });

    // markerClustering.
    */
    /* test end */
  };

  render = () => {
    return (
      // @ts-ignore
      <div className="map-wrap">
        <div
          ref="map"
          className="map-view"
          style={{
            width: (this.props.size && this.props.size === "SMALL" && "279px") || "100%", // "100%",
            height: (this.props.size && this.props.size === "SMALL" && "279px") || this.state.mapHeight, // this.state.mapHeight,
            margin: "3px",
            backgroundColor: "#232732",
            pointerEvents: (this.props.size && this.props.size === "SMALL" && "none") || "auto",
          }}
        ></div>
        {this.props.controlCadastral && (
          <Tabs className="map-converter tabs bg-navy tabs-small" align="vertical">
            <Button onClick={() => this.setCadastralMap(true)} disableRipple={true} className={`tab bg-navy tab-primary tab-small ${this.state.cadastralLayer && "active"}`}>
              지적도
            </Button>
            <Button onClick={() => this.setCadastralMap(false)} disableRipple={true} className={`tab bg-navy tab-primary tab-small ${!this.state.cadastralLayer && "active"}`}>
              지도
            </Button>
          </Tabs>
        )}
      </div>
    );
  };

  setCadastralMap = (show: boolean) => {
    this.setState({ cadastralLayer: show }, () => {
      this.dm!.setCadastralLayer(show);
    });
  };

  clearOverlays = () => {
    this.projectSite.map((p) => p.remove());
    this.roadSite.map((p) => p.remove());
    this.vacancyInside.map((p) => p.remove());
    this.vacancyOutside.map((p) => p.remove());
    this.skylineCircle.map((p) => p.remove());
    this.skylineLine.map((p) => p.remove());
    this.startMarker.map((p) => p.remove());
    this.endMarker.map((p) => p.remove());
    this.boundarySite.map((p) => p.remove());
    this.topographyPolygon.map((p) => p.remove());
  };

  addOverlays = () => {
    this.clearOverlays();
    const pp = this.props.project;
    if (pp) {
      // project_site
      if (pp.project_site && pp.project_site.length > 0) {
        if (this.props.viewOptions && this.props.viewOptions.projectSite) {
          pp.project_site.map((p) => {
            const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
            this.projectSite.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.PROJECT_SITE));
          });

          let center: number[] = this.projectSite
            .map((s) => turf.center({ type: "Polygon", coordinates: s.getPath() }).geometry!.coordinates)
            .reduce((a: number[], b: number[]) => [a[0] + b[0], a[1] + b[1]]);
          const len = this.projectSite.length;
          center = [center[0] / len, center[1] / len];
          this.map!.setCenter(center as [number, number]);
          // this.map!.setZoom(DrawingManager2.getZoomLevel(this.props.project!.project_site_area!, this.props.size && this.props.size || undefined));
          const mm = this.dm!.getAABB(this.props.project!.project_site!);
          mm && this.map!.fitBounds(mm.min, mm.max);
        }
      }

      // road
      if (pp.road_site && pp.road_site.length) {
        this.props.viewOptions &&
          this.props.viewOptions.road &&
          pp.road_site.map((p) => {
            if (p) {
              if (p.includes("MULTIPOLYGON")) {
                let path: any = [];
                DrawingManager2.toGeom(p, "MultiPolygon").coordinates.map((c: any) => path.push(c[0]));
                this.roadSite.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.ROAD_SITE));
              } else {
                const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
                this.roadSite.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.ROAD_SITE));
              }
            }
          });
      }

      if (pp.boundary_site && pp.boundary_site.length) {
        this.props.viewOptions &&
          this.props.viewOptions.boundarySite &&
          pp.boundary_site.map((p) => {
            if (p) {
              const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
              this.boundarySite.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.BOUNDARY_SITE));
            }
          });
      }

      // vacancy_inside
      if (pp.vacancy_inside && pp.vacancy_inside.length > 0) {
        this.props.viewOptions &&
          this.props.viewOptions.vacancyInside &&
          pp.vacancy_inside.map((p) => {
            const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
            this.vacancyInside.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.VACANCY_INSIDE));
          });
      }

      // vacancy_outside
      if (pp.vacancy_outside && pp.vacancy_outside.length > 0) {
        this.props.viewOptions &&
          this.props.viewOptions.vacancyOutside &&
          pp.vacancy_outside.map((p) => {
            const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
            this.vacancyOutside.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.VACANCY_OUTSIDE));
          });
      }

      // topography
      if (pp.topography && pp.topography.length > 0) {
        this.props.viewOptions &&
          this.props.viewOptions.topography &&
          pp.topography.map((p) => {
            if (p) {
              const path = DrawingManager2.toGeom(p.polygon[0]!, "Polygon").coordinates;
              this.topographyPolygon.push(this.dm!.addPolygon(path, DrawingManager2.DrawingOption.TOPOGRAPHY));
            }
          });
      }

      if (pp.skyline_circle && pp.skyline_circle.length > 0) {
        this.props.viewOptions &&
          this.props.viewOptions.skyline &&
          pp.skyline_circle.map((p) => {
            const path = DrawingManager2.toGeom(p, "Polygon").coordinates;
            this.skylineCircle.push(this.dm!.addCircle(path, DrawingManager2.DrawingOption.SKYLINE_CIRCLE));
          });
      }

      if (pp.skyline_line && pp.skyline_line.length > 0) {
        this.props.viewOptions &&
          this.props.viewOptions.skyline &&
          pp.skyline_line.map((p) => {
            const path = DrawingManager2.toGeom(p, "LineString").coordinates;
            this.startMarker.push(this.dm!.addMarker(path[0], DrawingManager2.DrawingOption.MAX_FLOOR_MARKER));
            this.endMarker.push(this.dm!.addMarker(path[1], DrawingManager2.DrawingOption.MIN_FLOOR_MARKER));
            this.skylineLine.push(this.dm!.addPolyline(path, DrawingManager2.DrawingOption.SKYLINE_LINE));
          });
      }
    }

    // const p = this.props.project!;
    // const d = this.drawingManager;
    // if (p.projectSite.path.length > 0) {
    //   const opt = _.cloneDeep(DrawingManager.DrawingOption.PROJECT_SITE);
    //   opt.clickable = false;
    //   d!.addPolygon([p.projectSite.path], opt, false);
    // }

    // if (p.road.length > 0) {
    //   const path = p.projectSite.path;
    //   const clockwise = this.drawingManager!.clockwise([path]);
    //   for (let i = 0; i <p.road.length; i++) {
    //     const pathSet = this.drawingManager!.getRoadSetOfPath(path[i % path.length], path[(i + 1) % path.length], p.road[i], clockwise);
    //     d!.addPolygon([pathSet.roadSpacePolygonPath], DrawingManager.DrawingOption.ROAD_SPACE, false);
    //     d!.addPolyline(pathSet.roadHorizontalPolylinePath, DrawingManager.DrawingOption.ROAD_HORIZONTAL, false);
    //     d!.addPolyline(pathSet.roadVeritcalPolylinePath, DrawingManager.DrawingOption.ROAD_VERTICAL, false);
    //   }
    // }

    // if (p.vacancyInside.length > 0) {
    //   const opt = _.cloneDeep(DrawingManager.DrawingOption.VACANCY_INSIDE);
    //   opt.clickable = false;
    //   p.vacancyInside.map(p => d!.addPolygon([p.path], opt, false));
    // }

    // if (p.vacancyOutside.length > 0) {
    //   const opt = _.cloneDeep(DrawingManager.DrawingOption.VACANCY_OUTSIDE);
    //   opt.clickable = false;
    //   p.vacancyOutside.map(p => d!.addPolygon([p.path], opt, false));
    // }
  };
}