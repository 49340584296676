import React, { Component } from "react";
import { ProjectType } from "./model/Project";
import "./css/ProjectTypeTag.scss";
import AWSModule from "./AWSModule";
import App from "./App";

export interface ProjectTypeTagProps {
  type?: "NOCONFIG" | "NOTSELECTED" | ProjectType;
  background: "WHITE" | "DARK";
  className?: string;
  projectId?: number; // 개발 버전용 값
  isForTable?: boolean;
}

export default class ProjectTypeTag extends Component<ProjectTypeTagProps> {
  render() {
    return (
      <div
        className={`ProjectTypeTag ${this.props.background} ${this.props.className} ${(this.props.type && this.props.type) || "UNDEFINED"}`}
        // style={{height: this.props.isForTable ? "20px" : "16px", borderRadius: this.props.isForTable ? "20px" : "6px"}}
        onClick={async (e) => {
          // console.log("ONCLICK!", App.stage, this.props.projectId);
          // if (App.stage === 'dev' && this.props.projectId) {
          //   const ddb = await new AWSModule('DDB').connect();
          //   await ddb.Ddb!.update({
          //     TableName: "platform-buildit-project",
          //     Key: {
          //       stage: App.stage,
          //       project_id: this.props.projectId,
          //     },
          //     UpdateExpression: "SET #status = :status",
          //     ExpressionAttributeNames: {
          //       '#status': "status",
          //     },
          //     ExpressionAttributeValues: {
          //       ':status': 'CONFIG',
          //     },
          //   }).promise();
          //   window.location.reload();
          // }
        }}
      >
        {this.props.type === "AI" && "AI"}
        {this.props.type === "CAL" && "AI.CAL"}
        {this.props.type === "DESIGNER" && "DESIGNER"}
        {this.props.type === "SITE_PLAN" && "DESIGNER"}
        {this.props.type === "UNDEFINED" && "미실행"}
        {this.props.type === "NOCONFIG" && "전체"}
        {this.props.type === "NOTSELECTED" && "타입 선택"}
      </div>
    );
  }
}
