import * as THREE from '@teneleven/three';
import { degrees2radians, distance, radiansToDegrees } from '@turf/turf';
import { BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { CheckVerticesArrayisCCW, getPolygonType} from './CoreAndHouseController';
import { CompletenessType, ConverterUnit, LineType, makeHouseState, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell2, ErrorType, makeInfoInformation, makeWarningInformation2 } from './ErrorLog';
import { switchLineDashedState } from './FileParser';
import { checkPointOnLine, checkWindowLength, PolygonSimplification } from './PolygonManager';
import userSettingData from './SettingModal';
import { default as _ } from 'lodash';
import { getCircle, getCurveErrorCircleBlock, getLineErrorCircleBlock } from './MeshMaker';
import * as jsts from 'jsts';
import { changeLineColorRed, WindowInfoType } from './CheckTypeBlockError';
import { ErrorList, TypeError } from './Error';

export interface WindowType {
  polygon: Polygon,
  lineType: LineType,
  maked: boolean
}

export class BuildingHouseUnit extends BuildingComponent {
  readonly componentType: 'core' | 'house';
  exclusiveArea: number; // 전용 면적
  serviceArea: number; // 발코니 면적
  balconyOver150cm: number;
  balconyLess150cm: number;
  commonWallArea: number;
  levelHeights: number[];
  piloti: number;
  complete: CompletenessType;
  ErrorLog: ErrorLogCell2[];
  ErrorPolygonGroup: THREE.Group;
  name: string;
  isFirstMadenHouse: boolean;
  lineState: CompletenessType;
  errorList: ErrorList;
  private makeState: makeHouseState;

  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'house';
    this.houseNumber = 1;
    this.complete = CompletenessType.complete;//.warning;

    this.exclusiveArea = 0;
    this.serviceArea = 0;
    this.balconyLess150cm = 0;
    this.balconyOver150cm = 0;
    this.commonWallArea = 0;
    this.levelHeights = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    this.levelCount = this.level.length;
    this.piloti = 0;
    this.makeState = makeHouseState.Finish;
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
    this.name = block.name;
    this.isFirstMadenHouse = false;
    this.lineState = CompletenessType.complete;
    this.errorList = new ErrorList();
    

    block!.entities.forEach(en => { 
      let p = (en as ConverterUnit).polygon;

      let newLineMesh = p.lineMesh.clone(); // material 참조방지
      newLineMesh.material = p.lineMesh.material.clone();
      //let newInnerMesh = p.innerMesh.clone(); // material 참조방지
      //@ts-ignore
      p.innerMesh.material.side = THREE.DoubleSide;

      let newPolygon: Polygon = {
        area: p.area,
        hasCurve: p.hasCurve,
        innerMesh: p.innerMesh.clone(),
        lineMesh: newLineMesh,
        selected: p.selected,
        shape: p.shape,
        type: p.type,
        vertices: p.vertices,
        layer: en.layer,
      }

      this.AddNewPolygon(newPolygon);
    })
    this.delOverlapZeroLayer();
    
  }

  setErrorList = (errorList: ErrorList) => {
    this.errorList = errorList;
  }

  delOverlapZeroLayer = () =>  { // 0레이어가 블록 내 다른 라인과 겹치면 삭제
    let zeroLayer: Polygon[] = [];
    let otherLayer: Polygon[] = [];

    this.polygon.forEach(poly => {
      if (poly.layer === "0") zeroLayer.push(poly);
      else otherLayer.push(poly);
    })

    let geoFac = new jsts.geom.GeometryFactory;
    let newPolygon = otherLayer;

    for (let i = 0; i < zeroLayer.length; i++) {
      let coords: jsts.geom.Coordinate[] = [];
      zeroLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
      let line1 = geoFac.createLineString(coords);
      let line2;
      for (let j = 0; j < otherLayer.length; j++) {
        let coords: jsts.geom.Coordinate[] = [];
        otherLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
        line2 = geoFac.createLineString(coords)
      }
      if (line2 && !line1.intersects(line2)) {
        this.renderGroup.add(zeroLayer[i].lineMesh);
        newPolygon.push(zeroLayer[i]);
      }
    }
    this.polygon = newPolygon;
  }

  getJSTSGeometryPolygon = () => {
    // this.renderGroup.updateWorldMatrix(true, true);

    // let matrixWorld = this.renderGroup.matrixWorld;
    // let coords: jsts.geom.Coordinate[] = [];

    // this.polygon.forEach(polygon => {
    //   if (polygon.shape) {
    //     polygon.vertices.forEach(v => {
    //       let newV = v.clone().applyMatrix4(matrixWorld);
    //       coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
    //     });

    //     let geoFac = new jsts.geom.GeometryFactory();
    //     let linearRing = geoFac.createLinearRing(coords);

    //     //@ts-ignore
    //     return geoFac.createPolygon(linearRing, []).buffer(0);
    //   }
    // })
    // return new jsts.geom.Geometry;
  }

  setCenterOfAllLine = () => {
    
    
   //  this.centerOfAllLine = GetPolygonCentroid2(block);
  }

  checkNoExistArea = (errorList: ErrorList) => {
    // 면적이 들어오지 않은 경우
    let splitName = this.name.split('_');

    if ((splitName.length >= 2 && !isNaN(Number(splitName[1])) && Number(splitName[1]) === 0) || splitName.length === 1) {

      
      // 1) 블록 이름에 면적 기입이 안된 경우, (예: H1, H2, C1 뒤에 면적이 안 적혔을 때
      errorList.addError(new TypeError({
        title: `[면적 오류] 저장이 불가합니다.`,
        msg: `${this.name} 에 면적이 존재하지 않습니다.`,
        type: ErrorType.Error,
        id: [this.uuid],
        components: [this],
      }));

      // // 에러
      // this.ErrorLog.push(makeErrorInformation2(`[면적 오류] 저장이 불가합니다.`,
      //   `${this.name} 에 면적이 존재하지 않습니다.`,
      //   new THREE.Group(), undefined, {
      //   components: [this],
      // }))
      this.complete = CompletenessType.error;
    }
    else {
      let sumArea = 0;
      this.polygon.forEach(poly => {
        // 2) 코어, 유닛 블록에 폴리곤은 있지만 계산된 면적이 0일 때
        sumArea += poly.area;
      })

      if (sumArea === 0) {
        errorList.addError(new TypeError({
          title: `[면적 오류] 저장이 불가합니다.`,
          msg: `${this.name} 에 면적이 존재하지 않습니다.`,
          type: ErrorType.Error,
          id: [this.uuid],
          components: [this],
        }));
        
        // this.ErrorLog.push(makeErrorInformation2(`[면적 오류] 저장이 불가합니다.`,
        //   `${this.name} 에 면적이 존재하지 않습니다.`,
        //   new THREE.Group(), undefined, {
        //   components: [this],
        // }))
        this.complete = CompletenessType.error;
      }
    }
  }

  checkZCoord = () => {
    let layerName = new Set();
    let polygonType = new Set();

    for (let i = 0; i < this.block.entities.length; i++) {
      let entity = this.block.entities[i];
      if ((entity as ConverterUnit).hasZCoord) {
        layerName.add(entity.layer);
        let findPolygon = this.polygon.filter(poly => entity.layer === poly.layer);
        findPolygon.forEach(poly => {
          polygonType.add(getPolygonType(poly.type, poly.shape));
        })
      }
    }
    if (layerName.size) {
      //@ts-ignore
      this.ErrorLog.push(makeWarningInformation2(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
        `${Array.from(layerName).join('/')} 의 Z값에 불필요한 ${Array.from(polygonType).join('/')}은 미반영 처리되었습니다.`,
        new THREE.Group(), [], { components: [this] }
      ));
      if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
    }
  }
  
  CheckCompleteness = (errorList: ErrorList) => {
    // 면적
    this.checkAreaError(errorList);
    // z값
    this.checkZCoord();
    
    /* (U/C블록) 안에 N개의 (라인/폴리라인) 이 있을 경우 */
    // shape이 있을때
    this.polygon.forEach(poly => {
      let layer = poly.layer.toUpperCase();
      if (!poly.shape && ["CON"].indexOf(layer) > -1) {
        errorList.addError(new TypeError({
          title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          msg:  `${this.name}은 하나의 Polygon 만을 허용합니다. `,
          type: ErrorType.Warning,
          id: [this.uuid],
          //@ts-ignore
          polygons: [poly],
        }));

        // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        //   `${this.name}은 하나의 Polygon 만을 허용합니다. `,
        //   new THREE.Group(), undefined, {
        //   windows: [poly],
        // }))
      }
    });

    /* 면적이 들어오지 않은 경우 */
    this.checkNoExistArea(errorList);
    
    /* 창문이 폴리곤으로 들어온 경우 */
    this.polygon.forEach(poly => {
      let layer = poly.layer.toUpperCase();
      
      if ((layer === "WIN1" || layer === "WIN2") && poly.shape) {
        let group = new THREE.Group();
        this.renderGroup.updateWorldMatrix(true, true);
        let worldVerts: THREE.Vector3[] = [];
        poly.vertices.forEach(v => worldVerts.push(v.clone().applyMatrix4(this.renderGroup.matrixWorld)));
        
        let tmpDist = worldVerts[0].distanceTo(worldVerts[worldVerts.length-1]);
        let lastVert = worldVerts[worldVerts.length-1];

        for (let i = 1; i < worldVerts.length; i++) {
          if (worldVerts[0].distanceTo(worldVerts[i]) > tmpDist) {
            lastVert = worldVerts[i];
            tmpDist =worldVerts[0].distanceTo(worldVerts[i]);            
          }
        }

        let circleMesh = getCircle(worldVerts[0], lastVert, new THREE.Color(1, 0, 0), 5);
        
        group.add(circleMesh);
        this.ErrorPolygonGroup.add(group);
        errorList.addError(new TypeError({
          title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          msg: `${poly.layer}에 Polygon 이 있습니다.`,
          type: ErrorType.Error,
          id: [this.uuid],
          //@ts-ignore
          polygons: [poly],
        }));

        // this.ErrorLog.push(makeErrorInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        //   `${poly.layer}에 Polygon 이 있습니다.`,
        //   group, undefined, {
        //   windows: [poly],
        // }))
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
      }
    })


    //* 창문 최소길이
    this.checkWindows(errorList);
    
    // this.polygon.forEach(p => {
    //   if (p.layer && p.layer.toUpperCase().startsWith("WIN") && p.shape) {
    //     this.ErrorLog.push(makeWarningInformation2(`${this.name}블록의 창문 레이어에 폴리곤이 존재합니다.`, '', new THREE.Group(), [],
    //       { windows: [p] }));
    //     warning = true;
    //   }
    // })

    //라운드
    this.checkRounded(errorList);

    //@ts-ignore
    if (this.complete === CompletenessType.error) {
      this.complete = CompletenessType.error;
      this.polygon.forEach(poly => {
        if (/^CON$/i.test(poly.layer)) {
          poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
        }
        switchLineDashedState(poly.lineMesh.material, true);
      })
    }
    //@ts-ignore
    else if (this.complete === CompletenessType.warning) {
      this.polygon.forEach(poly => {
        switchLineDashedState(poly.lineMesh.material, true);
      })
    }   
  }

  checkAreaError = (errorList: ErrorList) => {
    // 사용자가 입력한 전용 면적과
    // 계산면적 (= 최외각선 계산 면적값 - 사용자 서비스 면적 입력값 - 사용자 벽체 공용 면적 입력값) 비교
    // inputExclusiveArea === calcArea - 서비스면적 - 벽체공용
    const polygonArea = Number(this.polygon.map(p => p.area).reduce((a, b) => a + b, 0).toFixed(2));
    const inputArea = {
      exclusiveArea: 0,
      serviceArea: 0,
      commonWallArea: 0,
    };

    const splitName = this.name.split('_');
    if (splitName.length >= 2 && !isNaN(Number(splitName[1]))) {
      inputArea.exclusiveArea = Number(Number(splitName[1]).toFixed(4));
    }
    if (splitName.length >= 3 && !isNaN(Number(splitName[2]))) {
      inputArea.serviceArea = Number(Number(splitName[2]).toFixed(4));
    }
    if (splitName.length <= 4 && !isNaN(Number(splitName[3]))) {
      inputArea.commonWallArea = Number(Number(splitName[3]).toFixed(4));
    }

    let calcArea = Number((polygonArea - inputArea.serviceArea - inputArea.commonWallArea).toFixed(4));
    if (inputArea.exclusiveArea !== calcArea) {
      errorList.addError(new TypeError({
        title: `[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
        msg: `${this.name}의 실제 폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다.
        폴리곤 전체 입력 면적: ${inputArea.exclusiveArea.toFixed(4)} ㎡, 계산 면적: ${calcArea.toFixed(4)} ㎡
        차이: ${Math.abs(inputArea.exclusiveArea - calcArea).toFixed(4)} ㎡`,
        type: ErrorType.Warning,
        id: [this.uuid],
        components: [this],
      }));


      // this.ErrorLog.push(
      //   makeWarningInformation2(`[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
      //     `${this.name}의 실제 폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다.
      //   폴리곤 전체 입력 면적: ${inputArea.exclusiveArea.toFixed(4)} ㎡, 계산 면적: ${calcArea.toFixed(4)} ㎡, 
      //   차이: ${Math.abs(inputArea.exclusiveArea - calcArea).toFixed(4)} ㎡`,
      //     new THREE.Group(), [this.block], { components: [this]}
      // ));
    }
  }

  getInputArea = () => {
    return this.exclusiveArea + this.serviceArea;
  }
  
  AddNewPolygon = (polygon: Polygon) => {
    this.polygon.push(polygon);
    let line = polygon.lineMesh.clone();
 //   line.material.opacity = 0.5;
    if ((!polygon.layer!.toUpperCase().startsWith("WIN") && !polygon.shape)) {      
      polygon.lineMesh.renderOrder = 1;
      switchLineDashedState(line.material, true);
    }
    else {
      switchLineDashedState(line.material, false);
    }

    let mesh = polygon.innerMesh//.clone();
    mesh.applyMatrix4(this.renderGroup.matrixWorld);
    
    if (polygon.layer !== "0") {
      this.renderGroup.add(mesh);
      this.renderGroup.add(line);
    }

    this.renderGroup.updateWorldMatrix(true, true);

   // mesh.visible = true;

  }

  SetName = (name: string) => { this.name = name; }
  GetName = () => { return this.name; }

  
  SetLevel = (level: number) => {
    let height = this.levelHeights[0];
    this.level = [];
    this.levelHeights = [];
    for (let i = 0; i < level; i++) {
      this.level.push(true);
      this.levelHeights.push(height);
    }
  }


  SetLevelHeight = (value: number) => {
    for (let i = 0; i < this.levelHeights.length; i++) {
      this.levelHeights[i] = value;
    }
  }

  SetPiloti = (level: number) => {
    level = level > this.level.length ? this.level.length : level;

    for (let i = 0; i < this.level.length; i++) {
      this.level[i] = i < level ? false : true;
    }

    this.piloti = level;
  }

  SetExclusiveArea = (value: number) => {
    this.exclusiveArea = value;
//    this.CheckCompleteness();
  }

  SetServiceArea = (value: number) => {
    this.serviceArea = value;
    this.balconyLess150cm = value;
    this.balconyOver150cm = 0;
  //  this.CheckCompleteness();
  }

  SetBalconyOver150cm = (value: number) => {
    if (value > this.serviceArea) {
      value = this.serviceArea;
    }

    this.balconyOver150cm = value;
    this.balconyLess150cm = Number((this.serviceArea - this.balconyOver150cm).toFixed(4));
   // this.CheckCompleteness();
  }

  SetCommonWallArea = (value: number) => {
    this.commonWallArea = value;
  //  this.CheckCompleteness();
  }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  UpdateArea = () => {
    this.totalServiceAreas = this.balconyLess150cm;
    this.totalExclusiveAreas = this.exclusiveArea + this.balconyOver150cm;
    this.totalCommonWallAreas = this.commonWallArea;
  }

  checkRounded = (errorList: ErrorList) => {
      let hasCurve = false;
      this.polygon.forEach(p => {
        if (p.hasCurve) {
          hasCurve = true;
          this.errorBlock = true;
        }
      })
      
      if (this.componentType === "house" || this.componentType === "core") {
        if (hasCurve) {
          errorList.addError(new TypeError({
            title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다. ',
            msg: `${this.name} 에 ARC가 있습니다.`,
            type: ErrorType.Error,
            id: [this.uuid],
            components: [this],
            //@ts-ignore
            hilightPolygon: getCurveErrorCircleBlock(this),
          }));

          // this.ErrorLog.push(makeErrorInformation2('(형태적 오류) 유효하지 않은 데이터가 존재합니다. ',
          //   `${this.name} 에 ARC가 있습니다.`,
          //   getCurveErrorCircleBlock(this), [], {
          //     components: [this],
          //   }));
          this.complete = CompletenessType.error;
        }
      }
  }


  checkWindows = (errorList: ErrorList) => {
    let warning = false;
    let error = false;

    let windowPolygons: Array<Polygon> = [];
    this.polygon.forEach(p => {
      if (p.layer.toUpperCase() === 'WIN1') {
        windowPolygons.push(p);
      }
      else if (p.layer.toUpperCase() === 'WIN2') {
        windowPolygons.push(p);
      }
    })
    
    /* n개 "폴리곤" 존재 (지정된 레이어) */
    let polygons = this.polygon.filter(poly => poly.shape && ["CON", "WIN1", "WIN2"].indexOf(poly.layer.toUpperCase()) > -1);
    if (polygons.length > 1) {
      errorList.addError(new TypeError({
        title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
        msg:   `${this.name}은 하나의 Polygon만을 허용합니다.`,
        type: ErrorType.Error,
        id: [this.uuid],
       //@ts-ignore
        polygons
      }));

      // this.ErrorLog.push(makeErrorInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
      //   `${this.name}은 하나의 Polygon만을 허용합니다.`, new THREE.Group(), undefined, {
      //   windows: polygons,
      // }));
      changeLineColorRed(this.polygon, this.name);
      this.complete = CompletenessType.error;
    }


    /* 창문 레이어에 같은 좌표로 창문 (라인/폴리라인/폴리곤) 이 존재할 경우 */
    let lines: jsts.geom.LineString[] = [];
    let geoFactory = new jsts.geom.GeometryFactory();
    windowPolygons.forEach(wp => {
      let coords: jsts.geom.Coordinate[] = [];
      wp.vertices.forEach(vert => {
        coords.push(new jsts.geom.Coordinate(vert.x, vert.y));
      })
      lines.push(geoFactory.createLineString(coords));
    })

    for (let i = 0; i < lines.length; i++) {
      for (let j = i + 1; j < lines.length; j++) {
        if (lines[i].equals(lines[j])) {
          //TODO
          let group = getLineErrorCircleBlock(this.renderGroup, windowPolygons[i], this.ErrorPolygonGroup);

          errorList.addError(new TypeError({
            title:`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
            msg: `${windowPolygons[i].layer} 겹쳐진 ${getPolygonType(windowPolygons[i].type, windowPolygons[i].shape)} 이 있습니다. `, 
            type: ErrorType.Warning,
            id: [this.uuid],
            //@ts-ignore
            polygons: [windowPolygons[i]],
          }));

          // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          //   `${windowPolygons[i].layer} 겹쳐진 ${getPolygonType(windowPolygons[i].type, windowPolygons[i].shape)} 이 있습니다. `, 
          //   group, undefined, {
          //   windows: [windowPolygons[i]],
          // }));
          if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
//          error = true;
        }
      }
    }

    
    if (error) return "error";
    else if (warning) return "warning";
    else return "";
  
  }
  

  isWindowInWall = (window: Polygon, line: THREE.Line3, direction: string) => {
    let geoFac = new jsts.geom.GeometryFactory();
    
    if (direction === "vertical") {
      // 창문 라인
      let windowLine = new jsts.geom.LineSegment(new jsts.geom.Coordinate(line.start.x, window.vertices[0].y), new jsts.geom.Coordinate(line.start.x, window.vertices[1].y));
      let windowLinePoly = windowLine.toGeometry(geoFac);

      // 벽 라인
      let wallLine = new jsts.geom.LineSegment(new jsts.geom.Coordinate(line.start.x, line.start.y), new jsts.geom.Coordinate(line.end.x, line.end.y));
      let wallLinePoly = wallLine.toGeometry(geoFac);
      let minWin = Math.min(window.vertices[0].y, window.vertices[1].y);
      let minWall = Math.min(line.start.y, line.end.y);
      let maxWin = Math.max(window.vertices[0].y, window.vertices[1].y);
      let maxWall = Math.max(line.start.y, line.end.y);
      return wallLinePoly.covers(windowLinePoly) || (Number(minWin.toFixed(2)) >= Number(minWall.toFixed(2)) && Number(maxWin.toFixed(2)) <= Number(maxWall.toFixed(2)));
    }
    else if (direction === "horizon") {
      // 창문 라인
      let windowLine = new jsts.geom.LineSegment(new jsts.geom.Coordinate(window.vertices[0].x, line.start.y), new jsts.geom.Coordinate(window.vertices[1].x, line.start.y));
      let windowLinePoly = windowLine.toGeometry(geoFac);

      // 벽 라인
      let wallLine = new jsts.geom.LineSegment(new jsts.geom.Coordinate(line.start.x, line.start.y), new jsts.geom.Coordinate(line.end.x, line.start.y));
      let wallLinePoly = wallLine.toGeometry(geoFac);

      let minWin = Math.min(window.vertices[0].x, window.vertices[1].x);
      let minWall = Math.min(line.start.x, line.end.x);
      let maxWin = Math.max(window.vertices[0].x, window.vertices[1].x);
      let maxWall = Math.max(line.start.x, line.end.x);      
      return wallLinePoly.covers(windowLinePoly) || (Number(minWin.toFixed(2)) >= Number(minWall.toFixed(2)) && Number(maxWin.toFixed(2) )<=Number( maxWall.toFixed(2)));
    }
  }

  RebuildOutputPolygon = () => {
    let lines: PolylineInfo[] = [];
    let windowPolygons: WindowType[] = [];
    let matrix = new THREE.Matrix4().identity();// this.renderGroup.matrixWorld;

    
    this.polygon.forEach(p => {
      let verts = PolygonSimplification(p.vertices);

      if (p.layer === 'CON' && p.shape === true && p.vertices.length >= 4 && verts.length >= 4) {        
        
        let worldVerts: THREE.Vector3[] = [];
        
        for (let i = 0; i < verts.length; i++) {
          worldVerts.push(verts[i].clone().applyMatrix4(matrix));
        }
        if (!CheckVerticesArrayisCCW(worldVerts)) {
          worldVerts = worldVerts.reverse();
        }
        if (this.renderGroup.matrixWorld.elements[0] * this.renderGroup.matrixWorld.elements[5] < 0) {
          worldVerts = worldVerts.reverse();
        }
        
        
        for (let i = 0; i < worldVerts.length - 1; i++) {
          worldVerts[i].z = 0;
          worldVerts[i+1].z = 0;
          lines.push({
            line: new THREE.Line3(worldVerts[i], worldVerts[i + 1]),
            thickness: 0.6,
            type: LineType.LT_OUTERWALL,
          })
        }
      }
      else if (/^win[1-2]$/i.test(p.layer)) { // win1, win2일 때
        if (!p.shape) {
          if (checkWindowLength(p)) { // 창문 길이 체크
            windowPolygons.push({
              polygon: p,
              lineType: /^win1$/i.test(p.layer) ? LineType.LT_LIGHTWINDOW : LineType.LT_OUTERWINDOW,
              maked: false,
            })
          }
        }
      }
    })
    
    
    let makeState: makeHouseState = makeHouseState.Finish;
    let windowOffset = userSettingData.myTypeSettingData.windowOffset;
    let offset = windowOffset.value;//: 0; // 0.01;


    let win1Group = new THREE.Group();
    let win2Group = new THREE.Group();
    let winConfirmGroup = new THREE.Group();
    let win1Poly: Polygon[] = [];
    let win2Poly: Polygon[] = [];
    let windowInfoPoly: Polygon[] = [];
    
    windowPolygons.forEach(wp => { // 창문 3D폴리곤 만들기
      for (let wpi = 0; wpi < wp.polygon.vertices.length - 1; wpi++) {
        let v1 = wp.polygon.vertices[wpi].clone().applyMatrix4(matrix);
        let v2 = wp.polygon.vertices[wpi + 1].clone().applyMatrix4(matrix);
        let maked = false;

        let distance = v1.clone().distanceTo(v2) / 2; // 창문길이 절반
        let center = v1.clone().add(v2).divideScalar(2); // 창문의 센터 좌표
        let errorDistance = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].type === LineType.LT_OUTERWALL && v1.distanceTo(v2) > 0.01) { // 외벽일때
            let p1 = new THREE.Vector3();
            let p2 = new THREE.Vector3();

            lines[i].line.closestPointToPoint(v1, true, p1); // 외벽라인에서 가장 가까운 점 리턴
            lines[i].line.closestPointToPoint(v2, true, p2);

            let p3 = new THREE.Vector3();
            lines[i].line.closestPointToPoint(center, true, p3); // 창문중점과 벽 가장가까운지점을 p3에 넣음

            let preP1 = p1.clone();
            let preP2 = p2.clone();
  
            let direction = "horizon";
            // 수직방향인지 수평방향인지 체크
            if (Math.abs(p1.x - p2.x) > Math.abs(p1.y - p2.y)) {
              if (p1.x > p2.x) { // 수평
                p1.x = p3.x + distance;
                p2.x = p3.x - distance;
              }
              else {
                p1.x = p3.x - distance;
                p2.x = p3.x + distance;
              }
            }
            else { // 수직
              if (p1.y > p2.y) {
                p1.y = p3.y + distance;
                p2.y = p3.y - distance;
              }
              else {
                p1.y = p3.y - distance;
                p2.y = p3.y + distance;
              }
              direction = "vertical";
            }

            this.renderGroup.updateWorldMatrix(true, true);
            let data: WindowInfoType = {
              windowPoly: wp,
              p1,
              p2,
              line: lines[i].line,
              matrixWorld: this.renderGroup.matrixWorld,
              errorLog: this.ErrorLog,
              errorPolygonGroup: this.ErrorPolygonGroup,
            }
            
            if ((v1.distanceTo(p1) <= 100 && v2.distanceTo(p2) <= 100) || (v1.distanceTo(preP1) <= 100 && v2.distanceTo(preP2) <= 100)) {
              
              let l1: PolylineInfo, l2: PolylineInfo, l3: PolylineInfo;

              
              if (!this.isWindowInWall(wp.polygon, lines[i].line, direction)) {                
                this.renderGroup.updateWorldMatrix(true, true);
                  let matrixWorld = this.renderGroup.matrixWorld;
                  let worldVerts: THREE.Vector3[] = [];
                  wp.polygon.vertices.forEach(v => {
                    worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                  });
                  let group = new THREE.Group();
                  let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                  group.add(circleMesh);
                  this.ErrorPolygonGroup.add(group)
                
                // this.ErrorLog.push(makeWarningInformation2('(형태적 오류) 유효하지 않은 데이터가  존재합니다.', 
                // `설정하신 ${wp.lineType === LineType.LT_LIGHTWINDOW ? "WIN1" : "WIN2"} 이 ${this.name} 영역을 벗어나 생성되지 않습니다.`, group, undefined,
                // { windows: [wp.polygon] }));
                // if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
                return;
              }
               //*
              // 창문점이 벽 라인위 || 이격 거리가 0.1이하면 생성
              if (checkPointOnLine(p1, new THREE.Line3(lines[i].line.start, p2)) && checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end)) || (v1.distanceTo(p1) <= 0.1 && v2.distanceTo(p2) <= 0.1)) {
                // window mesh 뒤집힘 방지
                if ((p1.x - p2.x) * (lines[i].line.start.x - lines[i].line.end.x) < 0 || (p1.y - p2.y) * (lines[i].line.start.y - lines[i].line.end.y) < 0) { // 부호가 다르면
                  let tmp = p1.clone();
                  p1 = p2.clone();
                  p2 = tmp;
                }

                //TODO
                // 창문의 길이가 유닛 라인(영역)을 넘어갈 경우

//                if (this.isWindowInWall(wp.polygon, lines[i].line, direction)) {
                  l1 = { line: new THREE.Line3(lines[i].line.start, p1), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  l2 = { line: new THREE.Line3(p1, p2), thickness: 0.6, type: wp.lineType };
                  l3 = { line: new THREE.Line3(p2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  lines.splice(i, 1, l1, l2, l3);
                  i += 2;
                  maked = true;  
  //              }

              }
              else if (checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end)) || (v1.distanceTo(preP1) <= 0.1 && v2.distanceTo(preP2) <= 0.1)) { // 위에방법안될때
                if ((preP1.x - preP2.x) * (lines[i].line.start.x - lines[i].line.end.x) < 0 || (preP1.y - preP2.y) * (lines[i].line.start.y - lines[i].line.end.y) < 0) { // 부호가 다르면
                  let tmp = preP1.clone();
                  preP1 = preP2.clone();
                  preP2 = tmp;
                }
                  l1 = { line: new THREE.Line3(lines[i].line.start, preP1), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  l2 = { line: new THREE.Line3(preP1, preP2), thickness: 0.6, type: wp.lineType };
                  l3 = { line: new THREE.Line3(preP2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  lines.splice(i, 1, l1, l2, l3);
                  i += 2;
                  maked = true;  
              }
              else { // 이격
                
                // 자동보정ON, 사용자 설정 범위 이내 => INFO (0.1초과~사용자 설정)
                if (windowOffset.enable && v1.distanceTo(p1) <= offset && v2.distanceTo(p2) <= offset) {
                  if (!this.isFirstMadenHouse) {
                    let windowName = "";
                    if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
                    else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
                    this.renderGroup.updateWorldMatrix(true, true);
                    let matrixWorld = this.renderGroup.matrixWorld;
                    let worldVerts: THREE.Vector3[] = [];
                    wp.polygon.vertices.forEach(v => {
                      worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                    });
                    let group = new THREE.Group();
                    let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                    group.add(circleMesh);
                    winConfirmGroup.add(group);
                    windowInfoPoly.push(wp.polygon);
                  }

                    l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };
                    l2 = { line: new THREE.Line3(p2, p1), thickness: 0.6, type: wp.lineType };
                    l3 = { line: new THREE.Line3(p1, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                    lines.splice(i, 1, l1, l2, l3);
                    i += 2;
                    maked = true;  
                  //자동보정알람

                }
                else if (windowOffset.enable && v1.distanceTo(p1) > offset && v2.distanceTo(p2) > offset) {
                  // 자동보정 ON 사용자범위 초과 => Warnning
                  if (!this.isFirstMadenHouse) {
                    // let windowName = "";
                    // if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
                    // else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
                    // this.renderGroup.updateWorldMatrix(true, true);
                    // let matrixWorld = this.renderGroup.matrixWorld;
                    // let worldVerts: THREE.Vector3[] = [];
                    // wp.polygon.vertices.forEach(v => {
                    //   worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                    // });
                    // let group = new THREE.Group();
                    // this.renderGroup.updateWorldMatrix(true, true);
                    // group.applyMatrix4(this.renderGroup.matrixWorld)

                    // let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                    // group.add(circleMesh);
                    // //*NEW
                    // this.ErrorPolygonGroup.add(group);
                    // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
                    //   `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN2" : "WIN1"} 라인이 ${p1.distanceTo(preP1).toFixed(2)}mm 이격 되어 있습니다. `,
                    //   group, undefined, {
                    //   windows: win2Poly,
                    // }));
                    if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
                  }
                }
              }
            }

            //창문생성이 안됐을때 거리넣기
            if (!maked) {
              errorDistance = Math.min(Math.max(v1.distanceTo(p1), v2.distanceTo(p2)), errorDistance);
            }
          }


        }

        if (!this.isFirstMadenHouse && !maked && lines.length) {
          let windowName = "";
          if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
          else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
          this.renderGroup.updateWorldMatrix(true, true);
          // let matrixWorld = this.renderGroup.matrixWorld;
          // let worldVerts: THREE.Vector3[] = [];
          // wp.polygon.vertices.forEach(v => {
          //   worldVerts.push(v.clone().applyMatrix4(matrixWorld));
          // });
          // let group = new THREE.Group();
          // let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
          // group.add(circleMesh);

          // //*NEW
          // this.ErrorPolygonGroup.add(group);
          // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
          //   `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN1" : "WIN2"} 라인이 ${errorDistance.toFixed(2)}mm 이격 되어 있습니다. `,
          //   group, undefined, {
          //   windows: [wp.polygon],
          // }));
        }
        wp.maked = maked;
      }
    })

    // if (lines.length) {
    //   // 사용자 설정 범위 내 오차
    //   if (winConfirmGroup.children.length) {
    //     this.ErrorLog.push(makeInfoInformation('[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.',
    //       `${this.name} 의 이격된 창문 라인이 자동 보정 되었습니다.`, winConfirmGroup, undefined, {
    //       windows: windowInfoPoly,
    //     }));
    //   }
    // }

    windowPolygons.forEach(wp => {
      if (!wp.maked) {
        if (wp.lineType === LineType.LT_LIGHTWINDOW) {
          makeState = makeState === makeHouseState.outerWindowError ? makeHouseState.allWindowError : makeHouseState.lightWindowError;
        }
        else if (wp.lineType === LineType.LT_OUTERWINDOW) {
          makeState = makeState === makeHouseState.lightWindowError ? makeHouseState.allWindowError : makeHouseState.outerWindowError;
        }
      }
    })


    this.outputPolygon = lines;
    this.makeState = makeState;
    this.isFirstMadenHouse = true;
  }

  getHouseError = (errorList: ErrorList) => {
    while (this.outputPolygon.length > 0) {
      this.outputPolygon.splice(0, 1);
    }

    let lines: PolylineInfo[] = [];
    let windowPolygons: WindowType[] = [];
    let matrix = new THREE.Matrix4().identity();// this.renderGroup.matrixWorld;

    
    this.polygon.forEach(p => {
      let verts = PolygonSimplification(p.vertices);

      if (p.layer === 'CON' && p.shape === true && p.vertices.length >= 4 && verts.length >= 4) {        
        
        let worldVerts: THREE.Vector3[] = [];
        
        for (let i = 0; i < verts.length; i++) {
          worldVerts.push(verts[i].clone().applyMatrix4(matrix));
        }
        if (!CheckVerticesArrayisCCW(worldVerts)) {
          worldVerts = worldVerts.reverse();
        }
        if (this.renderGroup.matrixWorld.elements[0] * this.renderGroup.matrixWorld.elements[5] < 0) {
          worldVerts = worldVerts.reverse();
        }
        
        
        for (let i = 0; i < worldVerts.length - 1; i++) {
          worldVerts[i].z = 0;
          worldVerts[i+1].z = 0;
          lines.push({
            line: new THREE.Line3(worldVerts[i], worldVerts[i + 1]),
            thickness: 0.6,
            type: LineType.LT_OUTERWALL,
          })
        }
      }
      else if (/^win[1-2]$/i.test(p.layer)) { // win1, win2일 때
        if (!p.shape) {
          if (checkWindowLength(p)) { // 창문 길이 체크
            
            windowPolygons.push({
              polygon: p,
              lineType: /^win1$/i.test(p.layer) ? LineType.LT_LIGHTWINDOW : LineType.LT_OUTERWINDOW,
              maked: false,
            })
          }
          else {
            
              let group = getLineErrorCircleBlock(this.renderGroup, p, this.ErrorPolygonGroup);
              errorList.addError(new TypeError({
                title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
                msg: `설정 하신 창문의 폭이 최소 길이 300mm에 충족하지 않아 생성되지 않습니다.`,
                type: ErrorType.Warning,
                id: [this.uuid],
                //@ts-ignore
                polygons: [p],
                //@ts-ignore
                hilightPolygon: group,
              }));

              // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
              // `설정 하신 창문의 폭이 최소 길이 300mm에 충족하지 않아 생성되지 않습니다.`, group, undefined, {windows: [p]}));
              if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
          }
        }
      }
    })
    
    
    let makeState: makeHouseState = makeHouseState.Finish;
    console.log("userSettingDAta", userSettingData.myTypeSettingData);
    let windowOffset = userSettingData.myTypeSettingData.windowOffset;
    let offset = windowOffset.value;//: 0; // 0.01;



    let winConfirmGroup = new THREE.Group();
    let win1Poly: Polygon[] = [];
    let win2Poly: Polygon[] = [];
    let windowInfoPoly: Polygon[] = [];

    
    windowPolygons.forEach(wp => { // 창문 3D폴리곤 만들기
      for (let wpi = 0; wpi < wp.polygon.vertices.length - 1; wpi++) {
        let v1 = wp.polygon.vertices[wpi].clone().applyMatrix4(matrix);
        let v2 = wp.polygon.vertices[wpi + 1].clone().applyMatrix4(matrix);
        let maked = false;

        let distance = v1.clone().distanceTo(v2) / 2; // 창문길이 절반
        let center = v1.clone().add(v2).divideScalar(2); // 창문의 센터 좌표
        let errorDistance = Number.MAX_SAFE_INTEGER;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].type === LineType.LT_OUTERWALL && v1.distanceTo(v2) > 0.01) { // 외벽일때
            let p1 = new THREE.Vector3();
            let p2 = new THREE.Vector3();

            lines[i].line.closestPointToPoint(v1, true, p1); // 외벽라인에서 가장 가까운 점 리턴
            lines[i].line.closestPointToPoint(v2, true, p2);

            let p3 = new THREE.Vector3();
            lines[i].line.closestPointToPoint(center, true, p3); // 창문중점과 벽 가장가까운지점을 p3에 넣음

            let preP1 = p1.clone();
            let preP2 = p2.clone();
  
            let direction = "horizon";
            // 수직방향인지 수평방향인지 체크
            if (Math.abs(p1.x - p2.x) > Math.abs(p1.y - p2.y)) {
              if (p1.x > p2.x) { // 수평
                p1.x = p3.x + distance;
                p2.x = p3.x - distance;
              }
              else {
                p1.x = p3.x - distance;
                p2.x = p3.x + distance;
              }
            }
            else { // 수직
              if (p1.y > p2.y) {
                p1.y = p3.y + distance;
                p2.y = p3.y - distance;
              }
              else {
                p1.y = p3.y - distance;
                p2.y = p3.y + distance;
              }
              direction = "vertical";
            }

            this.renderGroup.updateWorldMatrix(true, true);
            let data: WindowInfoType = {
              windowPoly: wp,
              p1,
              p2,
              line: lines[i].line,
              matrixWorld: this.renderGroup.matrixWorld,
              errorLog: this.ErrorLog,
              errorPolygonGroup: this.ErrorPolygonGroup,
            }
            
            if ((v1.distanceTo(p1) <= 100 && v2.distanceTo(p2) <= 100) || (v1.distanceTo(preP1) <= 100 && v2.distanceTo(preP2) <= 100)) {
              
              let l1: PolylineInfo, l2: PolylineInfo, l3: PolylineInfo;
              
              if (!this.isWindowInWall(wp.polygon, lines[i].line, direction)) {
                this.renderGroup.updateWorldMatrix(true, true);
                  let matrixWorld = this.renderGroup.matrixWorld;
                  let worldVerts: THREE.Vector3[] = [];
                  wp.polygon.vertices.forEach(v => {
                    worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                  });
                  let group = new THREE.Group();
                  let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                  group.add(circleMesh);
                  this.ErrorPolygonGroup.add(group);
                  
                  errorList.addError(new TypeError({
                    title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.', 
                    msg:  `설정하신 ${wp.lineType === LineType.LT_LIGHTWINDOW ? "WIN1" : "WIN2"} 이 ${this.name} 영역을 벗어나 생성되지 않습니다.`, 
                    type: ErrorType.Warning,
                    id: [this.uuid],
                    //@ts-ignore
                    polygons: [wp.polygon],
                    //@ts-ignore
                    hilightPolygon: group,
                  }));
              
                // this.ErrorLog.push(makeWarningInformation2('(형태적 오류) 유효하지 않은 데이터가  존재합니다.', 
                // `설정하신 ${wp.lineType === LineType.LT_LIGHTWINDOW ? "WIN1" : "WIN2"} 이 ${this.name} 영역을 벗어나 생성되지 않습니다.`, group, undefined,
                // { windows: [wp.polygon] }));
                if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
                return;
              }
               //*
              // 창문점이 벽 라인위 || 이격 거리가 0.1이하면 생성
              if (checkPointOnLine(p1, new THREE.Line3(lines[i].line.start, p2)) && checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end)) || (v1.distanceTo(p1) <= 0.1 && v2.distanceTo(p2) <= 0.1)) {
                // window mesh 뒤집힘 방지
                if ((p1.x - p2.x) * (lines[i].line.start.x - lines[i].line.end.x) < 0 || (p1.y - p2.y) * (lines[i].line.start.y - lines[i].line.end.y) < 0) { // 부호가 다르면
                  let tmp = p1.clone();
                  p1 = p2.clone();
                  p2 = tmp;
                }

                //TODO
                // 창문의 길이가 유닛 라인(영역)을 넘어갈 경우

//                if (this.isWindowInWall(wp.polygon, lines[i].line, direction)) {
                  l1 = { line: new THREE.Line3(lines[i].line.start, p1), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  l2 = { line: new THREE.Line3(p1, p2), thickness: 0.6, type: wp.lineType };
                  l3 = { line: new THREE.Line3(p2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  lines.splice(i, 1, l1, l2, l3);
                  i += 2;
                  maked = true;  
  //              }

              }
              else if (checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end)) || (v1.distanceTo(preP1) <= 0.1 && v2.distanceTo(preP2) <= 0.1)) { // 위에방법안될때
                if ((preP1.x - preP2.x) * (lines[i].line.start.x - lines[i].line.end.x) < 0 || (preP1.y - preP2.y) * (lines[i].line.start.y - lines[i].line.end.y) < 0) { // 부호가 다르면
                  let tmp = preP1.clone();
                  preP1 = preP2.clone();
                  preP2 = tmp;
                }
                  l1 = { line: new THREE.Line3(lines[i].line.start, preP1), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  l2 = { line: new THREE.Line3(preP1, preP2), thickness: 0.6, type: wp.lineType };
                  l3 = { line: new THREE.Line3(preP2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                  lines.splice(i, 1, l1, l2, l3);
                  i += 2;
                  maked = true;  
              }
              else { // 이격

                // 자동보정ON, 사용자 설정 범위 이내 => INFO (0.1초과~사용자 설정)
                if (windowOffset.enable && v1.distanceTo(p1) <= offset && v2.distanceTo(p2) <= offset) {
                  if (!this.isFirstMadenHouse) {
                    let windowName = "";
                    if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
                    else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
                    this.renderGroup.updateWorldMatrix(true, true);
                    let matrixWorld = this.renderGroup.matrixWorld;
                    let worldVerts: THREE.Vector3[] = [];
                    wp.polygon.vertices.forEach(v => {
                      worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                    });
                    let group = new THREE.Group();
                    let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                    group.add(circleMesh);
                    winConfirmGroup.add(group);
                    windowInfoPoly.push(wp.polygon);
                  }

                    l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };
                    l2 = { line: new THREE.Line3(p2, p1), thickness: 0.6, type: wp.lineType };
                    l3 = { line: new THREE.Line3(p1, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
                    lines.splice(i, 1, l1, l2, l3);
                    i += 2;
                    maked = true;  
                  //자동보정알람

                }
                else if (windowOffset.enable && v1.distanceTo(p1) > offset && v2.distanceTo(p2) > offset) {
                  // 자동보정 ON 사용자범위 초과 => Warnning
                  if (!this.isFirstMadenHouse) {
                    let windowName = "";
                    if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
                    else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
                    this.renderGroup.updateWorldMatrix(true, true);
                    let matrixWorld = this.renderGroup.matrixWorld;
                    let worldVerts: THREE.Vector3[] = [];
                    wp.polygon.vertices.forEach(v => {
                      worldVerts.push(v.clone().applyMatrix4(matrixWorld));
                    });
                    let group = new THREE.Group();
                    this.renderGroup.updateWorldMatrix(true, true);
                    group.applyMatrix4(this.renderGroup.matrixWorld)

                    let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
                    group.add(circleMesh);
                    //*NEW
                    this.ErrorPolygonGroup.add(group);

                    
    errorList.addError(new TypeError({
      title: `[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
      msg: `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN2" : "WIN1"} 라인이 ${p1.distanceTo(preP1).toFixed(2)}mm 이격 되어 있습니다. `,
      type: ErrorType.Warning,
      id: [this.uuid],
      //@ts-ignore
      hilightPolygon: group,
      
      //@ts-ignore
      polygons: win2Poly,
    }));
                    // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
                    //   `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN2" : "WIN1"} 라인이 ${p1.distanceTo(preP1).toFixed(2)}mm 이격 되어 있습니다. `,
                    //   group, undefined, {
                    //   windows: win2Poly,
                    // }));
                    if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
                  }
                }
              }
            }

            //창문생성이 안됐을때 거리넣기
            if (!maked) {
              errorDistance = Math.min(Math.max(v1.distanceTo(p1), v2.distanceTo(p2)), errorDistance);
            }
          }


        }

        if (!this.isFirstMadenHouse && !maked && lines.length) {
          let windowName = "";
          if (wp.lineType === "LT_OUTERWINDOW") windowName = "일반창";
          else if (wp.lineType === "LT_LIGHTWINDOW") windowName = "채광창";
          this.renderGroup.updateWorldMatrix(true, true);
          let matrixWorld = this.renderGroup.matrixWorld;
          let worldVerts: THREE.Vector3[] = [];
          wp.polygon.vertices.forEach(v => {
            worldVerts.push(v.clone().applyMatrix4(matrixWorld));
          });
          let group = new THREE.Group();
          let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
          group.add(circleMesh);

          //*NEW
          this.ErrorPolygonGroup.add(group);
          errorList.addError(new TypeError({
            title: `[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
            msg: `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN1" : "WIN2"} 라인이 ${errorDistance.toFixed(2)}mm 이격 되어 있습니다. `,
            type: ErrorType.Warning,
            id: [this.uuid],
            //@ts-ignore
            hilightPolygon: group,
            //@ts-ignore
            polygons: [wp.polygon],
          }));
      
          // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.`,
          //   `${this.name}에 ${wp.lineType === LineType.LT_OUTERWALL ? "WIN1" : "WIN2"} 라인이 ${errorDistance.toFixed(2)}mm 이격 되어 있습니다. `,
          //   group, undefined, {
          //   windows: [wp.polygon],
          // }));
        }
        wp.maked = maked;
      }
    })

    if (lines.length) {
      // 사용자 설정 범위 내 오차
      if (winConfirmGroup.children.length) {

        errorList.addError(new TypeError({
          title:'[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.',
          msg: `${this.name} 의 이격된 창문 라인이 자동 보정 되었습니다.`,
          type: ErrorType.Info,
          id: [this.uuid],
          //@ts-ignore
          polygons: windowInfoPoly,
          //@ts-ignore
          hilightPolygon: winConfirmGroup,
        }));


        // this.ErrorLog.push(makeInfoInformation('[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.',
        //   `${this.name} 의 이격된 창문 라인이 자동 보정 되었습니다.`, winConfirmGroup, undefined, {
        //   windows: windowInfoPoly,
        // }));
      }
    }

    windowPolygons.forEach(wp => {
      if (!wp.maked) {
        if (wp.lineType === LineType.LT_LIGHTWINDOW) {
          makeState = makeState === makeHouseState.outerWindowError ? makeHouseState.allWindowError : makeHouseState.lightWindowError;
        }
        else if (wp.lineType === LineType.LT_OUTERWINDOW) {
          makeState = makeState === makeHouseState.lightWindowError ? makeHouseState.allWindowError : makeHouseState.outerWindowError;
        }
      }
    })


    this.outputPolygon = lines;
    this.makeState = makeState;
    this.isFirstMadenHouse = true;
  }
  


  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
      componentType: this.componentType,
      level: this.level,
      exclusiveArea: this.exclusiveArea, // 전용 면적
      serviceArea: this.serviceArea, // 발코니 면적
      balconyOver150cm: this.balconyOver150cm,
      balconyLess150cm: this.balconyLess150cm,
      commonWallArea: this.commonWallArea,
      levelHeights: this.levelHeights,
      piloti: this.piloti,
    }

    return text;
  }
}