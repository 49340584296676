import React, { Component } from 'react';
import '../css/CADConverter/ErrorLog.scss';
import CloseIcon from '@material-ui/icons/Close';
import ListOutlined from '@material-ui/icons/ListOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import * as THREE from '@teneleven/three';
import { brightenAllField, darkenAllField, setBlockOpacity } from './CoreAndHouseController';
import { ConverterBlock } from './ConverterBlock';
import { ErrorLogCell2 } from './ErrorLog';
import { ChevronRight, ExpandLess, ExpandMore, SpeakerNotes } from '@material-ui/icons';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
// import { Resizable } from 're-resizable';
import { Info } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { ConverterField } from './BuildingPart';
import { ReactComponent as TaskAlt } from '../img/icon/taskAlt.svg';



export enum ErrorType {
  All = 'all',
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Info',
}


export interface ErrorLogCell {
  Type: ErrorType,
  Information: string,
  subInfomation: string
  hilightPolygon: THREE.Group,
  hilightBlock: ConverterBlock[],
}

interface ErrorLogState {
  ErrorType: ErrorType,
  expandLog: boolean,
}

interface ErrorLogProp {
  show: boolean,
  errorSize: number,
  warningSize: number,
  confirmSize: number,
  logs: ErrorLogCell2[][],
  allBuildings?: any, // Array<BuildingCoreUnit | BuildingHouseUnit>
  classifyLogs?: any, 
  errorList?: any,
  allFields?: ConverterField[],
  currentLog: string,
  showErrorByType: (type: ErrorType) => void,
  closeLog: () => void,
  closeCurrentLog: () => void,
}


export function makeErrorInformation(information: string, subInfomation?: string, hilightPolygon?: THREE.Group, hilightBlock?: ConverterBlock[]): ErrorLogCell {
  return {
    Information: information,
    Type: ErrorType.Error,
    subInfomation: subInfomation ? subInfomation : '',
    hilightPolygon: hilightPolygon ? hilightPolygon : new THREE.Group(),
    hilightBlock: hilightBlock ? hilightBlock : [],
  }
}



export function makeWarningInformation(information: string, subInfomation?: string, hilightPolygon?: THREE.Group, hilightBlock?: ConverterBlock[]): ErrorLogCell{
  return {
    Information: information,
    Type: ErrorType.Warning,
    subInfomation: subInfomation ? subInfomation : '',
    hilightPolygon: hilightPolygon ? hilightPolygon : new THREE.Group(),
    hilightBlock: hilightBlock ? hilightBlock : [],
  }
}
export class ErrorLogBlock extends Component<ErrorLogProp, ErrorLogState> {
  state: ErrorLogState = {
    ErrorType: ErrorType.All,
    expandLog: true,
  }
  
  keys: Array<string> = [];
  errors: Array<ErrorLogCell2[]> = [];
  //  styles = (theme:any) => ({
  //   root: {
  //     width: "100%"
  //   },
  //   heading: {
  //     fontSize: theme.typography.pxToRem(15),
  //     fontWeight: theme.typography.fontWeightRegular
  //   },
  //   expandIcon: {
  //     "&$expanded": {
  //       transform: "translateY(-50%) rotate(45deg)"
  //     }
  //   },
  //   expanded: {}
  // });

  

  showErrorPolygon = (errorGroup: THREE.Group) => {  
    errorGroup.children.forEach(c => {
      c.visible = true;
      c.children.forEach(cc => {
        cc.visible = true;
      })
    })
  }

  hideErrorPolygon = (errorGroup: THREE.Group) => {
    errorGroup.children.forEach(c => {
      c.visible = false;
    })
  }

  makeErrorLogs = () => {
    let classifyLogs = new Map();

    this.props.logs.forEach(errors => {
      errors.forEach(error => {
        if (!classifyLogs.get(error.Information)) classifyLogs.set(error.Information, [error]);
        else classifyLogs.set(error.Information, classifyLogs.get(error.Information).concat(error));
      })
    })

    this.errors = [];
    this.keys = [];
    classifyLogs.forEach((errors: ErrorLogCell2[], key: any) => {
      this.errors.push(errors);
      this.keys.push(key);
    })
  }

  render = () => {
    
    if (this.props.errorList) {

      return (
        <div className={`errorLog`}>
          <div className='errorLogHead'>
            <div className="leftSide font font-emphasis">
              <SpeakerNotes className="m-l-md icon speaker-icon font font-emphasis" />
              <span className='headText font font-emphasis font-12px'>Logs</span>
            </div>
            <IconButton className="log-expand-btn" onClick={() => { this.props.closeLog() }}>
              {this.props.show ?
                <ExpandMore className="icon expand-icon" /> :
                <ExpandLess className="icon expand-icon" />
              }
            </IconButton>
            {/* <CloseIcon className='icon close-icon' onClick={this.props.closeLog} /> */}
          </div>
          <div className={`errorLogBody ${this.state.expandLog ? "" : "display-none"}`}>
            <div className='category'>

              
            <div className={`font font-secondary font-12px logName`}>
              {
                this.props.currentLog === "ALL" ? <span className="text">전체</span> :
                <>
                  <span className="text">{this.props.currentLog}</span>
                  <CloseIcon className="icon closeIcon" onClick={() => {
                    this.props.closeCurrentLog();
                  }}/>
                </>
              }
            </div>
          
              <div className={`cell ${this.state.ErrorType === ErrorType.All && 'active'} ${this.props.errorSize + this.props.warningSize + this.props.confirmSize === 0 && 'hidden'}`} 
              onClick={() => {
                this.props.showErrorByType(ErrorType.All);
                this.setState({ ErrorType: ErrorType.All });
              }}
              >
                <ListOutlined className='icon' />
                <span className='text'>{this.props.errorSize + this.props.warningSize + this.props.confirmSize} messages</span>
              </div>
              
              <div className={`cell completion ${this.props.errorSize !== 0 && 'hidden'}`}>
                <TaskAlt className='icon complete-icon' />
                <span className='text'>completion</span>
              </div>

              <div className={`cell ${this.state.ErrorType === ErrorType.Error && 'active'} ${this.props.errorSize === 0 && 'hidden'}`} onClick={() => 
                //this.setState({ ErrorType: ErrorType.Error })
                {
                  this.setState({ ErrorType: ErrorType.Error })
                  this.props.showErrorByType(ErrorType.Error);
                }
                }>
                <ErrorIcon className='icon red' />
                <span className='text'>{this.props.errorSize} errors</span>
              </div>
              <div className={`cell ${this.state.ErrorType === ErrorType.Warning && 'active'} ${this.props.warningSize === 0 && 'hidden'}`} onClick={() => 
                //this.setState({ ErrorType: ErrorType.Warning })
              {
                this.setState({ ErrorType: ErrorType.Warning })
                this.props.showErrorByType(ErrorType.Warning);

              }
                }>
                <WarningIcon className='icon yellow' />
                <span className='text'>{this.props.warningSize} warnings</span>
              </div>
              <div className={`cell ${this.state.ErrorType === ErrorType.Info && 'active'} ${this.props.confirmSize === 0 && 'hidden'}`} onClick={() => 
                //this.setState({ ErrorType: ErrorType.Info })
                {
                  this.setState({ ErrorType: ErrorType.Info })
                  this.props.showErrorByType(ErrorType.Info);
                }
                }>
                <Info className="icon green" />
                <span className='text'>{this.props.confirmSize} info</span>
              </div>
            </div>
            <div className='errorList'>
              {
                this.props.errorSize === 0 &&
                <div className="completeLog"><TaskAlt className="icon complete-icon" />동평면 저장이 가능합니다.</div>
              }
              {
                this.props.errorList.map((item: any) => {
                  return (
                    <div>
 
                      <ExpansionPanel className="font font-emphasis font-14px expansion"
                        classes={{
                          root: "exp-root",
                          expanded: "expanded",
                        }}
                        // expanded={}
                        onChange={() => {
                        }}
                      >

                        <ExpansionPanelSummary
                          disableRipple={true}
                          // expandIcon={
                          //   // <ExpandMore className="icon arrow-right" />
                          // }
                          classes={{
                            root: "summary-root",
                            expanded: "expanded",
                            content: "content",
                            //  expandIcon: "translateY(-50%) rotate(45deg)"
                          }}
                          className="expansion-title"
                        >
                          <IconButton className="arrow-btn"><ChevronRight className="icon arrow-right" /></IconButton>{item[0]}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className="expansion-content">
                          {
                            item[1].map((val: any) =>
                              <>
                                <div
                                  className={`font font-primary font-12px info ${val.type === ErrorType.Error && 'error'} ${val.type === ErrorType.Warning && 'warning'}
                                  ${val.type === ErrorType.Info && 'info-log'}
                                  `}
                                  onMouseEnter={() => {
                                    val.hilightPolygon && this.showErrorPolygon(val.hilightPolygon);
                                    this.props.allFields && darkenAllField(this.props.allFields);
                                    val.targetFields && brightenAllField(val.targetFields);
                                    // 동펴염ㄴ
                                    this.props.allBuildings && setBlockOpacity(this.props.allBuildings, 0.2);
                                    val.components && setBlockOpacity(val.components, 1);
                                    
                                    val.polygons && setBlockOpacity(val.components, 1, val.polygons);
                                  }}
                                  onMouseLeave={() => {
                                    val.hilightPolygon && this.hideErrorPolygon(val.hilightPolygon);
                                    this.props.allFields && brightenAllField(this.props.allFields);
                                    // 동펴염ㄴ
                                    this.props.allBuildings && setBlockOpacity(this.props.allBuildings, 1);
                                  }}
                                >
                                  {val.type === ErrorType.Error && <ErrorIcon className='icon red' />}
                                  {val.type === ErrorType.Warning && <WarningIcon className='icon yellow' />}
                                  {val.type === ErrorType.Info && <Info className='icon green' />}
                                  {val.msg}</div>
                              </>
                            )
                          }
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </div>
                  )
                })

              }


            </div>
          </div>
        </div>
      )
    }
    else {
      return (
        // <Resizable
        // className="resizable"
        //   defaultSize={{
        //     width: 1500,
        //     height: 222,
        //   }}
        // >
  
      
        <div className={`errorLog`}>
          <div className='errorLogHead'>
            <div className="leftSide font font-emphasis">
              <SpeakerNotes className="m-l-md icon speaker-icon font font-emphasis"/>
              <span className='headText font font-emphasis font-12px'>Logs</span>
            </div>
            <IconButton className="log-expand-btn" onClick={() => {this.props.closeLog()}}>
            { this.props.show ?
              <ExpandMore  className="icon expand-icon" /> :
              <ExpandLess className="icon expand-icon"/>
            }
            </IconButton>
            {/* <CloseIcon className='icon close-icon' onClick={this.props.closeLog} /> */}
          </div>
          <div className={`errorLogBody ${this.state.expandLog ? "" : "display-none"}`}>
            <div className='category'>
              <div className={`cell ${this.state.ErrorType === ErrorType.All && 'active'} ${this.props.errorSize + this.props.warningSize + this.props.confirmSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.All })}>
                <ListOutlined className='icon' />
                <span className='text'>{this.props.errorSize + this.props.warningSize + this.props.confirmSize} messages</span>
              </div>
              <div className={`cell ${this.state.ErrorType === ErrorType.Error && 'active'} ${this.props.errorSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.Error })}>
                <ErrorIcon className='icon red' />
                <span className='text'>{this.props.errorSize} errors</span>
              </div>
              <div className={`cell ${this.state.ErrorType === ErrorType.Warning && 'active'} ${this.props.warningSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.Warning })}>
                <WarningIcon className='icon yellow' />
                <span className='text'>{this.props.warningSize} warnings</span>
              </div>
              <div className={`cell ${this.state.ErrorType === ErrorType.Info && 'active'} ${this.props.confirmSize === 0 && 'hidden'}`} onClick={() => this.setState({ ErrorType: ErrorType.Info })}>
                <Info className="icon green"/>
                <span className='text'>{this.props.confirmSize} info</span>
              </div>
  
            </div>
            <div className='errorList'>
  
              {this.makeErrorLogs()}
  
              {
                this.keys.map((key, i) => {
                  if (this.state.ErrorType === ErrorType.All || this.errors[i].filter(error => error.Type === this.state.ErrorType).length)
                  return (<div>
                    <ExpansionPanel className="font font-emphasis font-14px expansion"
                      classes={{
                        root: "exp-root",
                        expanded: "expanded",
                      }}
                    // expanded={}
                  
                    onChange={() => {
                    }}
                    >
  
                        <ExpansionPanelSummary
                        disableRipple={true}
                        // expandIcon={
                        //   // <ExpandMore className="icon arrow-right" />
                        // }
                        classes={{
                          root: "summary-root",
                          expanded: "expanded",
                          content: "content",
                        //  expandIcon: "translateY(-50%) rotate(45deg)"
                        }}
                        className="expansion-title"
                      >
                        <IconButton className="arrow-btn"><ChevronRight className="icon arrow-right" /></IconButton>{key}
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className="expansion-content">
                      {
                      this.errors[i].map((error, idx) => {
                        if (error.Type === this.state.ErrorType || this.state.ErrorType === ErrorType.All) {
                          return <div
                          key={`${error.hilightPolygon.uuid}${idx}${i}`}
                          className={`font font-primary font-12px info ${error.Type === ErrorType.Error && 'error'} ${error.Type === ErrorType.Warning && 'warning'} 
                          ${error.Type === ErrorType.Info && 'confirm'} `}
                          onMouseOver={() => {
                            
                            this.showErrorPolygon(error.hilightPolygon);
                            if (error.buildingTypeError && Object.keys(error.buildingTypeError).length) { // 동평면 1
                              if (error.buildingTypeError.windows) {
                                setBlockOpacity(this.props.allBuildings, 0.2);
                                setBlockOpacity(undefined, 1, error.buildingTypeError.windows);
                              }
                              else {
                                setBlockOpacity(this.props.allBuildings, 0.2);
                                setBlockOpacity(error.buildingTypeError.components, 1);
                              }
                            }
                            if (error.fieldError) { // 사업영역
                              if (error.fieldError.fields && error.fieldError.field) {
                                darkenAllField(error.fieldError.fields);
                                brightenAllField(error.fieldError.field);
                              }
                              else if (error.fieldError.parts && error.fieldError.fields) {
                                darkenAllField(error.fieldError.fields);
                                error.fieldError.parts.forEach(part => {
                                  part.polygon.lineMesh.material.visible = true;
                                })
                              }
                            }
                          }}
                          onMouseOut={() => {
                            this.hideErrorPolygon(error.hilightPolygon);
                            if (error.buildingTypeError && Object.keys(error.buildingTypeError).length) { // 동평면   
                              setBlockOpacity(this.props.allBuildings, 1); 
                              if (error.buildingTypeError.windows) {
  
                                setBlockOpacity(undefined,1, error.buildingTypeError.windows); 
                              }
                            }
                            if (error.fieldError) { // 사업영역 
                              if (error.fieldError.fields) {
                                brightenAllField(error.fieldError.fields);
                              }
                              if (error.fieldError.parts) {
                                error.fieldError.parts.forEach(part => {
                                  part.polygon.lineMesh.material.visible = false;
                                })
                              }
                            }
                          }}
                        >
  
                          {error.Type === ErrorType.Error && <ErrorIcon className='icon red' />}
                          {error.Type === ErrorType.Warning && <WarningIcon className='icon yellow' />}
                          {error.Type === ErrorType.Info && <Info className='icon green' />}
                          {error.subInfomation}
                        </div>
                        }
                      })
                    }
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                      
  
  
  
  {/* 0903 */}
                    {/* <div className="font font-emphasis font-14px log-header">
                      <ChevronRight className="icon arrow-right" />{key}
                    </div>
                    {
                      this.errors[i].map((error, idx) => {
                        return <div
                             key={`${error.hilightPolygon.uuid}${idx}${i}`}
                          className={`font font-primary font-12px info text ${error.Type === ErrorType.Error && 'error'} ${error.Type === ErrorType.Warning && 'warning'} ${error.Type === ErrorType.Info && 'confirm'} `}
                          onMouseOver={() => {
                            this.showErrorPolygon(error.hilightPolygon);
  
                            if (error.buildingTypeError && Object.keys(error.buildingTypeError).length) { // 동평면 
                              if (error.buildingTypeError.windows) {
                                darkenAllBlock([], this.props.allBuildings);
                                brightenWindow(error.buildingTypeError.windows, error.buildingTypeError.component as BuildingHouseUnit, error.buildingTypeError.windows[0], error.Type)
                              }
                              else if (error.buildingTypeError.components) {
                                darkenAllBlock([], this.props.allBuildings);
                                brightenAllBlock(error.buildingTypeError.components, error.buildingTypeError.name);
                              }
                            }
                            else if (error.fieldError) { // 사업영역
                              if (error.fieldError.fields && error.fieldError.field) {
                                darkenAllField(error.fieldError.fields);
                                brightenAllField([error.fieldError.field]);
                              }
                              else if (error.fieldError.parts && error.fieldError.fields) {
                                darkenAllField(error.fieldError.fields);
                                error.fieldError.parts.forEach(part => {
                                  part.polygon.lineMesh.material.visible = true;
                                })
                              }
                            }
                          }}
                          onMouseOut={() => {
                            this.hideErrorPolygon(error.hilightPolygon);
                            if (error.buildingTypeError && Object.keys(error.buildingTypeError).length) { // 동평면                  
                              error.buildingTypeError.windows && darkenPolygon(error.buildingTypeError.windows);
                              brightenAllBlock(this.props.allBuildings);
                            }
                            else if (error.fieldError) { // 사업영역
                              if (error.fieldError.fields) {
                                brightenAllField(error.fieldError.fields);
                              }
                              if (error.fieldError.parts) {
                                error.fieldError.parts.forEach(part => {
                                  part.polygon.lineMesh.material.visible = false;
                                })
                              }
                            }
                            this.props.brightenAllLayer();
                          }}
                        >
  
                          {error.Type === ErrorType.Error && <ErrorIcon className='icon red' />}
                          {error.Type === ErrorType.Warning && <WarningIcon className='icon yellow' />}
                          {error.Type === ErrorType.Info && <InfoOutlined className='icon white' />}
  
                          {error.subInfomation}
                        </div>
                      })
                    } */}
                  </div>)
                })
              }
  
  
   
            </div>
          </div>
        </div>
        // </Resizable>
  
      )
    }

  }
}