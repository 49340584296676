import * as THREE from '@teneleven/three';
import { DEMData, getDemHeight } from './DEMManager';
import App from '../App';
import { IoT1ClickProjects } from 'aws-sdk';
const earcut = require('earcut');

interface BuildingMesh {
  wall: THREE.Geometry,
  window: THREE.Geometry,
  core: THREE.Geometry,
  piloti: THREE.Geometry,
  parkingLine: THREE.Vector2[],
  bottomLine: THREE.Geometry,
}

export function makePlane(verts: THREE.Vector3[]) {
  const geometry = new THREE.Geometry();
  geometry.vertices = verts;

  for (let verNum = 0, faceNum = 0; verNum < geometry.vertices.length - 3; faceNum++, verNum += 2) {
    geometry.faces.push(new THREE.Face3(verNum, verNum + 1, verNum + 2));
    geometry.faces.push(new THREE.Face3(verNum + 1, verNum + 3, verNum + 2));

    geometry.faceVertexUvs[0].push([
      new THREE.Vector2(0, 0),
      new THREE.Vector2(0, 1),
      new THREE.Vector2(1, 0),
    ]);
    geometry.faceVertexUvs[0].push([
      new THREE.Vector2(0, 1),
      new THREE.Vector2(1, 1),
      new THREE.Vector2(1, 0),
    ]);
  }

  geometry.computeFaceNormals();
  return geometry;
}

function makeRoofWithVertices(verts: THREE.Vector3[], inverse: boolean = false) {
  const geometry = new THREE.Geometry();

  geometry.vertices = verts;
  const triVerts = new Array();
  verts.forEach(v => {
    triVerts.push(v.x);
    triVerts.push(v.z);
  });
  const triangles = earcut(triVerts);

  for (let i = 0; i < triangles.length; i += 3) {
    if (inverse)
      geometry.faces.push(new THREE.Face3(triangles[i + 1], triangles[i + 2], triangles[i]));
    else
      geometry.faces.push(new THREE.Face3(triangles[i + 2], triangles[i + 1], triangles[i]));

    geometry.faceVertexUvs[0].push([
      new THREE.Vector2(0, 0),
      new THREE.Vector2(0, 1),
      new THREE.Vector2(1, 0),
    ]);
  }

  geometry.computeFaceNormals();
  return geometry;
}


function MakeWall(start: THREE.Vector2, end: THREE.Vector2, roomHeight: number, wallHeight: number) {
  let verts = [];
  verts.push(new THREE.Vector3(start.x, roomHeight, start.y));
  verts.push(new THREE.Vector3(start.x, roomHeight + wallHeight, start.y));

  verts.push(new THREE.Vector3(end.x, roomHeight, end.y));
  verts.push(new THREE.Vector3(end.x, roomHeight + wallHeight, end.y));

  return makePlane(verts);
}

function MakeWindow(windows: any[], nodes: any, winGeo: THREE.Geometry, wallGeo: THREE.Geometry, roomHeight: number, wallHeight: number) {
  if (windows.length > 0) {
    let windowLength = 0;
    windows.forEach(w => {
      windowLength += (new THREE.Vector2(nodes[w.start].x, nodes[w.start].y)).distanceTo(nodes[w.end] as THREE.Vector2);
    });
    let windowOffset = windowLength > 2.6 ? 0.2 : 0.6;
    windows.forEach(w => {
      wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight, windowOffset));
      winGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight + windowOffset, wallHeight - windowOffset * 2));
      wallGeo.merge(MakeWall(nodes[w.start], nodes[w.end], roomHeight + wallHeight - windowOffset, windowOffset));
    });
  }
}

function MakeLevelLine(start: THREE.Vector2, end: THREE.Vector2, height: number) {
  let lineGeo = new THREE.Geometry();

  lineGeo.vertices.push(new THREE.Vector3(start.x, height, start.y));
  lineGeo.vertices.push(new THREE.Vector3(end.x, height, end.y));

  return lineGeo;
}

function MakeLine(start: THREE.Vector3, end: THREE.Vector3) {
  let lineGeo = new THREE.Geometry();

  lineGeo.vertices.push(start);
  lineGeo.vertices.push(end);

  return lineGeo;
}

function makeHouse(buildingMesh: BuildingMesh, houseType: string, lineInfo: any, nodes: any, roomHeight: number, wallHeight: number, withRoof: boolean) {
  let verts: THREE.Vector3[] = [];
  let groundVerts: THREE.Vector3[] = [];
  let windows: any[] = [];
  lineInfo.forEach((l: any) => {
    switch (l.lineType) {
      case 'LT_COREOUTERWALL':
        buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
        buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
        MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.core, roomHeight, wallHeight);
        windows = [];
        break;

      case 'LT_OUTERWALL':
      case 'LT_INNERWALL':
      case 'LT_ENTRANCE':
      case 'LT_SIDEWALL':
      case 'LT_DOOR':
      case 'LT_SLIDE':
      case 'LT_GATE':
      case 'LT_COREINNERWALL':
        if (houseType === 'FC_CORE') {
          buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
        }
        else {
          buildingMesh.wall.merge(MakeWall(nodes[l.start], nodes[l.end], roomHeight, wallHeight));
        }
        buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
        MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight); // 창문생성
        windows = [];
        break;

      case 'LT_OUTERWINDOW':
      case 'LT_LIGHTWINDOW':
      case 'LT_INNERWINDOW':
      case 'LT_WINDOW':
      case 'LT_VIEWWINDOW':
      case 'LT_LVWINDOW':
        windows.push(l);
        buildingMesh.bottomLine.merge(MakeLevelLine(nodes[l.start], nodes[l.end], roomHeight));
        break;

      default:
        App.stage !== "prod" && console.log(l.lineType);
        break;
    }
    verts.push(new THREE.Vector3(nodes[l.start].x, roomHeight + wallHeight, nodes[l.start].y));
    groundVerts.push(new THREE.Vector3(nodes[l.start].x, roomHeight, nodes[l.start].y));
  })

  if (houseType === 'FC_HOUSE') {
    if (withRoof)
      buildingMesh.wall.merge(makeRoofWithVertices(verts));
    buildingMesh.wall.merge(makeRoofWithVertices(groundVerts, true));
    MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.wall, roomHeight, wallHeight);
    windows = [];
  }
  else if (houseType === 'FC_CORE') {
    if (withRoof)
      buildingMesh.core.merge(makeRoofWithVertices(verts));
    buildingMesh.core.merge(makeRoofWithVertices(groundVerts, true));
    MakeWindow(windows, nodes, buildingMesh.window, buildingMesh.core, roomHeight, wallHeight);
    windows = [];
  }
}

function makePiloti() {

}

function makeParkingLineLevel(buildingMesh: BuildingMesh, category: string, type: string, lineInfo: any, nodes: any, wallHeight: number) {
  lineInfo.forEach((l: any) => {
    switch (l.lineType) {
      case 'LT_COREOUTERWALL':
        buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], 0, wallHeight));
        break;

      case 'LT_OUTERWALL':
      case 'LT_PARKINGLINE':
        if (category === "PCT_PARKING") {
          buildingMesh.parkingLine.push(new THREE.Vector2(nodes[l.start].x, nodes[l.start].y));
          buildingMesh.parkingLine.push(new THREE.Vector2(nodes[l.end].x, nodes[l.end].y));
        }
        else if (category === 'PCT_CORE' && type === 'PT_CORE') {
          buildingMesh.core.merge(MakeWall(nodes[l.start], nodes[l.end], 0, wallHeight));
        }
        break;

      default:
        App.stage !== "prod" && console.log(l.lineType);
        break;
    }
  })
}

export function MakeBuildingMesh(buildingData: any, demData: DEMData[] = []) {
  App.stage !== "prod" && console.log(buildingData);

  let buildingMesh: BuildingMesh = {
    core: new THREE.Geometry(),
    piloti: new THREE.Geometry(),
    wall: new THREE.Geometry(),
    window: new THREE.Geometry(),
    parkingLine: [],
    bottomLine: new THREE.Geometry(),
  }

  let heights = buildingData.floorHeight;
  buildingData.building.outline.forEach((outline: any) => {
    let nodes = outline.node.data;
    let category = outline.category;
    let cIndex = outline.categoryIndex;
    let group = outline.group;
    let floor;

    switch (category) {
      case 'PCT_CORE':
        floor = buildingData.floorStatus[group].coreFloorInfo[cIndex].floorCategory;
        break;
      case 'PCT_HOUSE':
        floor = buildingData.floorStatus[group].houseFloorInfo[cIndex].floorCategory;
        break;
      default:
        floor = ["FC_VOID"]
        break;
    }

    if (category == "PCT_PARKING") {
      makeParkingLineLevel(buildingMesh, outline.category, outline.type, outline.lineInfo, nodes, heights[0]);
    }
    else {
      let roomHeight = 0;
      for (let i = 0; i < floor.length; i++) {
        switch (floor[i]) {
          case 'FC_CORE':
          case 'FC_HOUSE':
            let withRoof = false;
            if (i === floor.length - 1) {
              withRoof = true;
            }
            else if (i !== floor.length - 1 && floor[i + 1] === 'FC_VOID') {
              withRoof = true;
            }

            makeHouse(buildingMesh, floor[i], outline.lineInfo, nodes, roomHeight, heights[i], withRoof);
            break;

          case 'FC_VOID':
            break;
          case 'FC_PILOTI':
            App.stage !== "prod" && console.log('piloti');
            break;
          default:
            break;
        }
        roomHeight += heights[i];
      }
    }
  })

  // centerWindowLowerTexture = new THREE.TextureLoader().load('/img/centerWindowLower.png');
  // centerWindowTexture = new THREE.TextureLoader().load('/img/centerWindow.png');

  // lowerWindowMat = new THREE.MeshLambertMaterial({ map: this.centerWindowLowerTexture, color: '#ffffff' });

  let coreMaterial = new THREE.MeshPhongMaterial({ color: '#999999' });
  let wallMaterial = new THREE.MeshPhongMaterial({ color: '#eeeeee' });
  let windowMaterial = new THREE.MeshPhongMaterial({ color: '#60C5CB' });
  let parkingLineMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
  let bottomLineMaterial = new THREE.LineBasicMaterial({ color: 0x999999 });

  let building = new THREE.Group();

  building.add(new THREE.Mesh(buildingMesh.core, coreMaterial));
  building.add(new THREE.Mesh(buildingMesh.wall, wallMaterial));
  building.add(new THREE.Mesh(buildingMesh.window, windowMaterial));
  building.add(new THREE.LineSegments(buildingMesh.bottomLine, bottomLineMaterial));

  let parkingLine = new THREE.Geometry();
  for (let i = 0; i < buildingMesh.parkingLine.length - 1; i += 2) {
    let lineGeo = new THREE.Geometry();
    let sph = getDemHeight(demData, new THREE.Vector2(buildingData.position.x + buildingMesh.parkingLine[i].x, buildingData.position.y + buildingMesh.parkingLine[i].y));
    let eph = getDemHeight(demData, new THREE.Vector2(buildingData.position.x + buildingMesh.parkingLine[i + 1].x, buildingData.position.y + buildingMesh.parkingLine[i + 1].y));

    lineGeo.vertices.push(new THREE.Vector3(buildingMesh.parkingLine[i].x, sph - buildingData.position.z + 0.1, buildingMesh.parkingLine[i].y));
    lineGeo.vertices.push(new THREE.Vector3(buildingMesh.parkingLine[i + 1].x, eph - buildingData.position.z + 0.1, buildingMesh.parkingLine[i + 1].y));
    parkingLine.merge(lineGeo);
  }
  building.add(new THREE.LineSegments(parkingLine, parkingLineMaterial));

  building.scale.set(0.1, 0.1, -0.1);
  // building.castShadow = true;
  building.children.forEach(c => {
    c.castShadow = true;
    c.receiveShadow = true;
  })

  // rotate
  building.rotateY(THREE.MathUtils.degToRad(buildingData.angle));

  let inversBuilding = new THREE.Group();
  inversBuilding.add(building);

  if (buildingData.rotStatus === 'INV_X') {
    inversBuilding.scale.setX(-1);
  }

  if (buildingData.rotStatus === 'INV_Y') {
    inversBuilding.scale.setZ(-1);
  }

  if (buildingData.rotStatus === 'INV_XY') {
    inversBuilding.scale.setX(-1);
    inversBuilding.scale.setZ(-1);
  }

  App.stage !== "prod" && console.log(inversBuilding);
  return inversBuilding;
}

function MakeBorderBox(building: any, startHeight: number, endHeight: number) {
  let lineGeo = new THREE.Geometry();

  building.building.outline.forEach((l: any) => {
    if (l.type === 'PT_BORDER') {
      let node = l.node.data;
      l.lineInfo.forEach((li: any) => {
        lineGeo.merge(MakeLevelLine(node[li.start], node[li.end], startHeight));
        lineGeo.merge(MakeLevelLine(node[li.start], node[li.end], endHeight));
      })
      node.forEach((n: any) => {
        lineGeo.merge(MakeLine(new THREE.Vector3(n.x, startHeight, n.y), new THREE.Vector3(n.x, endHeight, n.y)));
      });
    }
  })

  return lineGeo;
}

function GetNewPointInLine(p1: THREE.Vector3, p2: THREE.Vector3, newY: number) {
  let t = (newY - p1.y) / (p2.y - p1.y);

  let x = p1.x + t * (p2.x - p1.x);
  let y = p1.y + t * (p2.y - p1.y);
  let z = p1.z + t * (p2.z - p1.z);

  console.log(x, y, z, t);
  return new THREE.Vector3(x, y, z);
}

export function MakeBuildingOuterLine(validMass: any[]) {
  let lineGeo = new THREE.Geometry();

  validMass.forEach(vm => {
    let lowerPoly = vm.lowerPoly;
    lowerPoly.push(lowerPoly[0])
    let upperPoly = vm.upperPoly;
    upperPoly.push(upperPoly[0]);
    let edges = vm.edges;

    for (let i = 0; i < lowerPoly.length - 1; i++) {
      let start = new THREE.Vector3(lowerPoly[i].x, lowerPoly[i].z, lowerPoly[i].y);
      let end = new THREE.Vector3(lowerPoly[i + 1].x, lowerPoly[i + 1].z, lowerPoly[i + 1].y);
      lineGeo.merge(MakeLine(start, end));
    }
    for (let i = 0; i < upperPoly.length - 1; i++) {
      let start = new THREE.Vector3(upperPoly[i].x, upperPoly[i].z, upperPoly[i].y);
      let end = new THREE.Vector3(upperPoly[i + 1].x, upperPoly[i + 1].z, upperPoly[i + 1].y);
      lineGeo.merge(MakeLine(start, end));
    }

    for (let i = 0; i < edges.length; i++) {
      let start = new THREE.Vector3(edges[i].from.x, edges[i].from.z, edges[i].from.y);
      let end = new THREE.Vector3(edges[i].to.x, edges[i].to.z, edges[i].to.y);
      lineGeo.merge(MakeLine(start, end));
    }
  })

  let lineMesh = new THREE.LineSegments(lineGeo, new THREE.LineBasicMaterial({ color: 0x000000 }));
  lineMesh.scale.set(0.1, 0.1, -0.1);
  return lineMesh;
}
