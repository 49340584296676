import React, { Component } from 'react';
import { Button, Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Info } from '@material-ui/icons';
import '../css/CADConverter/SettingModal.scss'
import { ConverterType, Unit } from './DataTypes';
import { saveDataToDynamoDB, updateDynamoDBData, getSettingData } from './DBManager';
import { myTypeSettingData, MyTypeSettingDataUI } from './detialSetting';
import App from '../App';
import Tooltip from '../Tooltip';
import '../css/BuilditTemplate.scss';

interface defaultSettingData {
  autoSetting: boolean,
  dataUnit: Unit,
}

export class userSettingData extends Component implements defaultSettingData {
  static myTypeSettingData: myTypeSettingData;
  autoSetting = true;
  dataUnit = Unit.Millimeters;
  settingDBTable = 'platform-buildit-cad-converter-user-setting';

  componentDidMount = async () => {

  }

  getDataFromDB = async () => {
    let data = await getSettingData();
    if (!data) {
      let newData = {
        myType: {
          windowOffset: { enable: false, value: 200 },
          areaOffset: { enable: false, value: 10 },
          layerOffset: { enable: false, value: 200 },
          layerOverlap: { enable: false, value: 10 },
          unClosedPolygon: {enable: false, value: 0}, // 닫혀있지 않은 폴리곤 오차 보정
          polygonOffset: {enable: false, value: 0}, //   폴리곤간 이격보정
        },
        mySite: {},
        myPlane: {},
        autoSetting: true, /* this.autoSetting, 21.06.24 일단 자동설정만 가능하게 처리 */ 
        dataUnit: this.dataUnit,
        stage: App.tempStage,
        email: App.session.email,
      }
      data = newData;

      saveDataToDynamoDB(newData, this.settingDBTable);
    }
    if (!userSettingData.myTypeSettingData)
      userSettingData.myTypeSettingData = new myTypeSettingData();
    userSettingData.myTypeSettingData.updateDataFromDB(data.myType);
    this.autoSetting = true; //data.autoSetting !== undefined ? data.autoSetting : this.autoSetting;
    this.dataUnit = data.dataUnit !== undefined ? data.dataUnit : this.dataUnit;

  }

  updateAutoSetting = async (value: boolean) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.autoSetting = value;
    this.autoSetting = value;
    updateDynamoDBData(this.settingDBTable, data);
  }

  updateUnit = async (value: Unit) => {
    let data = await getSettingData();
    if (!data)
      return;
    data.dataUnit = value;
    this.dataUnit = value;
    updateDynamoDBData(this.settingDBTable, data);
  }
}

export default userSettingData;

interface SettingProps {
  closeModal: () => void,
  settingData: userSettingData,
  open: boolean,
}

interface AutoCorrectionType {
  windowOffsetValue: number,
  layerOverlapEnable: boolean,
  polygonOffsetEnable: boolean,
  unClosedPolygonEnable: boolean,
}

interface SettingState {
  unit: Unit,
  showUnit: boolean,
  autoSetting: boolean,
  converterType: ConverterType,
  autoCorrection: AutoCorrectionType,
}
export class Setting extends Component<SettingProps, SettingState>{
  state: SettingState = {
    unit: this.props.settingData.dataUnit,
    showUnit: false,
    autoSetting: this.props.settingData.autoSetting,
    converterType: ConverterType.myType,
    autoCorrection: {
      windowOffsetValue:0,
      layerOverlapEnable:false, 
      polygonOffsetEnable:false,
      unClosedPolygonEnable: false, 
    }
  }

  settingDBTable = 'platform-buildit-cad-converter-user-setting';
  componentDidUpdate = (previousProps: Readonly<SettingProps>, previousState: Readonly<SettingState>) => {
    if (previousProps.open !== this.props.open) {
      this.setState({
        unit: this.props.settingData.dataUnit,
        showUnit: false,
        autoSetting: this.props.settingData.autoSetting,
        converterType: ConverterType.myType
      })
    }
  }

  autoSettingUpdate = (value: boolean) => {
    this.props.settingData.updateAutoSetting(value);
    this.setState({ autoSetting: value })
  }

  unitUpdate = (value: Unit) => {
  //  this.props.settingData.updateUnit(value);
    this.setState({ showUnit: false, unit: value });
  }

  changeSettingData = (optionName: string, value: any) => {
    switch (optionName) {
      case 'windowOffsetValue':
        if (value >= 1 && value <= 100) userSettingData.myTypeSettingData.windowOffset.value = Number(value);
        break;
      case 'layerOverlapEnable':
        userSettingData.myTypeSettingData.layerOverlap.enable = value;
        break;
      case 'PolygonOffsetEnable':
        userSettingData.myTypeSettingData.polygonOffset.enable = value;
        break;
      case 'unClosedPolygonEnable':
        userSettingData.myTypeSettingData.unClosedPolygon.enable = value;
        break;
      case 'windowOffsetEnable':
        userSettingData.myTypeSettingData.windowOffset.enable = value;
        break;
    }
  }

  render = () => {
    return (
      <Dialog className='SettingModalBody  buildit-template' aria-labelledby="simple-dialog-title" open={this.props.open}>
        <div className='title'>
          <div className="setting">설정</div>
          <div className="right-btns">
            <Button className={`btn btn-primary bg-navy save-btn`} onClick={async () => {            
              let data = await getSettingData();
              if (!data)
                return;
              data.dataUnit = this.state.unit;
              data.myType.windowOffset.value = userSettingData.myTypeSettingData.windowOffset.value;
              data.myType.windowOffset.enable = userSettingData.myTypeSettingData.windowOffset.enable;
              data.myType.layerOverlap.enable = userSettingData.myTypeSettingData.layerOverlap.enable;
              data.myType.polygonOffset.enable = userSettingData.myTypeSettingData.polygonOffset.enable;
              data.myType.unClosedPolygon.enable = userSettingData.myTypeSettingData.unClosedPolygon.enable;
              updateDynamoDBData(this.settingDBTable, data);
              
            }}>저장</Button>
            <CloseIcon className='closeIcon' onClick={this.props.closeModal} />

        </div>
        </div>
        <div className='contentTitle'><span>자동 설정</span>
          <Tooltip arrowOn={false} msg={<>캐드컨버터에서는 사용자의 별도 설정없이 자동으로 파일 설정이 가능합니다.<br />
            미설정시 수동으로 직접 설정 해야 합니다.</>} place="right">
            <Info className='icon info' />
          </Tooltip>
        </div>
        <div className='item'>
          <div className='mainContent'>
            <span className='name'>파일 업로드 시 자동설정</span>
            <span className={`text ${!this.state.autoSetting && 'active'}`} style={{ margin: 'auto 0 auto auto' }}>
              {/* 설정 안함 */}
            </span>
            <label className="switch"> <input type="checkbox" defaultChecked={this.state.autoSetting} 
              disabled={true}
              // onClick={(e) => this.autoSettingUpdate(e.currentTarget.checked)} 
              // 21.06.24 자동설정만 가능하도록 처리
            /> <span className="slider" /> </label>
            <span className={`text ${this.state.autoSetting && 'active'}`} style={{ margin: 'auto 7px auto 0' }}>자동 설정</span>
          </div>
        </div>
        <div className='item'>
          <div className='mainContent'>
            <span className='name' style={{ marginRight: 'auto' }}>캐드 파일 입력 단위</span>
            <div className="unitDropdown">
              <div className="default" onClick={() => this.setState({ showUnit: !this.state.showUnit })}>
                <span className='text'>{this.state.unit}</span> {this.state.showUnit && <ExpandLess className='icon' /> || <ExpandMore className='icon' />}
              </div>
              <div className={`menu ${this.state.showUnit && 'show'}`}>
                <div className="item" onClick={() => this.unitUpdate(Unit.Millimeters)}>{Unit.Millimeters}</div>
                <div className="item" onClick={() => this.unitUpdate(Unit.Meters)}>{Unit.Meters}</div>
                <div className="item" onClick={() => this.unitUpdate(Unit.Inches)}>{Unit.Inches}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='contentTitle'><span>자동 보정</span>
        <Tooltip arrowOn={false} msg="캐드컨버터에서는 캐드 설정상 나올 수 있는 오차에 대한 자동 보정이 가능합니다." place="right">
          <Info className='icon info' />
        </Tooltip>
        </div>
        <div className='switchTabs'>
          <div className={`switchButton disable`} ><span className='text'>사업영역</span></div>
          <div className={`switchButton ${this.state.converterType === ConverterType.myType && 'enable'}`} onClick={() => this.setState({ converterType: ConverterType.myType })}><span className='text'>동평면</span></div>
          <div className={`switchButton disable`} ><span className='text'>배치안</span></div>
        </div>
        <MyTypeSettingDataUI 
          myTypeSettingData={userSettingData.myTypeSettingData} 
          changeSettingData={this.changeSettingData}
        />
        {/* <div className="bottom m-t-xl">
          <Button className={`btn btn-primary bg-navy save-btn ${this.state.windowOffsetValue < 1 || this.state.windowOffsetValue > 100 ? 'disabled' : ''}'}`} onClick={() => {
            if (this.state.windowOffsetValue >= 1 && this.state.windowOffsetValue <= 100) {
              this.props.myTypeSettingData.updateWindowOffsetValue(this.state.windowOffsetValue);
              this.props.myTypeSettingData.updateLayerOverlapEnable(this.state.layerOverlapEnable);
              this.props.myTypeSettingData.updatePolygonOffsetEnable(this.state.polygonOffsetEnable);
              this.props.myTypeSettingData.updateUnClosedPolygonEnable(this.state.unClosedPolygonEnable);
            }
          }
          }>저장</Button>
        </div> */}
        
      </Dialog >
    )
  }
}
