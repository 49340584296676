import * as THREE from '@teneleven/three';
import { BuildingTypeData, ConverterLayer, ConverterUnit, mapProjectionData, Polygon, screenInfo } from "./DataTypes";
import { getScale, switchLineDashedState } from './FileParser';
import { areaProportion } from './resultLocationDataStruct';
import { loc_building_stories_avg } from './resultDataStruct';
import * as jsts from 'jsts';
import App from '../App';
import { Field } from './Field';
import { House } from './House';
import { Core } from './Core';
import { NaverPoint } from './NaverMapManager';
import { tm2latlng } from './SceneManager';
import { BuildingComponent, BuildingPart, ConverterBuilding, ConverterField } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { default as _, isArray, union } from 'lodash';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingGroup } from './BuildingGroup';
import * as turf from '@turf/turf';
import { LineSegmentsGeometry } from '@teneleven/three/examples/jsm/lines/LineSegmentsGeometry';
import { getCircle, getErrorLine, getFilledCircle } from './MeshMaker';
import { Unit } from './DataTypes';
import { ErrorType } from './ErrorLog';


const uuid4 = require('uuid/v4');
const earcut = require('earcut');

export function MakeANewBuilding(name: string, hideList = false) {
  let building: BuildingTypeData = {
    id: uuid4(),
    cores: [],
    houses: [],
    name: name,
    showList: false,
    totalExclusiveAreas: 0,
    totalServiceAreas: 0,
    totalCoreAreas: 0,
    buildingArea: 0,
    groundArea: 0,
    hideList: hideList,
  }
  return building;
}

export function deleteHouseFromHouseList(houses: House[], house: House) {
  let i = houses.indexOf(house)
  if (i > -1) {
    house.deleteHouse();
    houses.splice(i, 1);
  }
}

export function deleteCoreFromCoreList(cores: Core[], core: Core) {
  let i = cores.indexOf(core);
  if (i > -1) {
    core.deleteCore();
    cores.splice(i, 1);
  }
}

export function deleteFieldFromFieldList(fields: Field[], field: Field) {
  let i = fields.indexOf(field);
  if (i > -1) {
    if (field.getLayer()) { switchLayerState(field.getLayer()) }
    fields.splice(i, 1);
  }
}

export function deleteBuildingFromBuildingList(buildings: BuildingTypeData[], building: BuildingTypeData) {
  let i = buildings.indexOf(building);
  if (i > -1) {
    while (building.houses.length > 0) {
      deleteHouseFromHouseList(building.houses, building.houses[0]);
    }

    while (building.cores.length > 0) {
      deleteCoreFromCoreList(building.cores, building.cores[0]);
    }
    buildings.splice(i, 1);
  }
}

export function switchLayerState(l: ConverterLayer | null, isSinglePolygon: boolean = false) {
  if (!l)
    return;

  if (isSinglePolygon) {
    let p = l.polygons[0];
    for (let i = 1; i < l.polygons.length; i++) {
      p = p.area < l.polygons[i].area ? l.polygons[i] : p;
    }
    p.selected = !l.selected;
    App.stage !== "prod" && console.log(p, l);
    switchLineDashedState(p.lineMesh.material, l.selected);
  }
  else {
    l.polygons.forEach(p => {
      p.selected = !l.selected;
      switchLineDashedState(p.lineMesh.material, l.selected);
    });
  }

  l.selected = !l.selected;

  if (!l.selected) {
    l.polygons.forEach(p => {
      p.lineMesh.renderOrder = 0;
      p.innerMesh.visible = false;
    })
  }

  l.polygons.forEach(p => {
    p.lineMesh.material.color = new THREE.Color().set(l.color);
  })
}

export function getFieldsArea(fields: Field[]) {
  let totalArea = 0;

  fields.forEach(f => {
    if (f.getLayer())
      totalArea += f.getArea();
  })

  return Number(totalArea.toFixed(4));
}

export function getHouseArea(house: House) {
  if (house.wall) {
    return getConverterLayerArea(house.wall);
  }
  else
    return 0;
}

export function getFieldArea(field: Field) {
  let layer = field.getLayer();
  if (layer) {
    return getConverterLayerArea(layer);
  }
  else {
    return 0;
  }
}

export function getConverterLayerArea(layer: ConverterLayer) {
  return Number(GetJSTSUnionPolygonFormLayer(layer).getArea().toFixed(2));
}

export function GetJSTSUnionPolygonFormLayer(layer: ConverterLayer) {
  let polygons = GetJSTSPolygonFormLayer(layer);
  let unionPolygon = GetEmptyJSTSGeometry();
  polygons.forEach((p: jsts.geom.Geometry) => {
    unionPolygon = unionPolygon.union(p);
  });
  return unionPolygon;
}

export function getBlockTotalHouseHold(buildings: ConverterBuilding[]) {
  let totalHousehold = 0;
  buildings.forEach(b => {
    b.parts.forEach(p => {
      totalHousehold += p.houseNumber;
    })
  })

  return totalHousehold;
}

export function getTotalHousehold(buildings: ConverterBuilding[]) {
  let totalHousehold = 0;
  buildings.forEach(blockF => {
    blockF.parts.forEach(blockD => {
      blockD.parts.forEach(component => {
        if ((component as BuildingComponent).componentType === "house") {
          totalHousehold += ((component as BuildingHouseUnit).level.length - (component as BuildingHouseUnit).piloti);
        }
      })
    })
  })

  return totalHousehold;
}
export function getTotalHouseholdLayer(buildings: BuildingTypeData[]) {
  let totalHousehold = 0;
  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        totalHousehold += (h.level.length - h.piloti);
      })
    })
  });
  return totalHousehold;
}


export function calculateBlockAreaProPortion(buildings: ConverterBuilding[]) {
  let areaProportions: areaProportion[] = [];

  buildings.forEach(b => {
    b.parts.forEach(p => {
      // let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
    })
  });

  let totalHousehold = getBlockTotalHouseHold(buildings);
  areaProportions.push({
    housingPlanTypeArea: 84,
    housingPlanTypeNumberMin: totalHousehold,
    housingPlanTypeProportion: 0,
    numberOfBay: 0,
    templateName: '',
  })
  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });

  return areaProportions;
}

export function calculateAreaProPortionLayer(buildings: BuildingTypeData[]){
  let areaProportions: areaProportion[] = [];
  buildings.forEach(b => {
    b.cores.forEach(c => {
      c.houses.forEach(h => {
        let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
        if (!ap) {
          areaProportions.push({
            housingPlanTypeArea: h.exclusiveArea,
            housingPlanTypeNumberMin: (h.level.length - h.piloti),
            housingPlanTypeProportion: 0,
            numberOfBay: 0,
            templateName: '',
          })
        }
        else {
          ap.housingPlanTypeNumberMin += (h.level.length - h.piloti);
        }
      })
    })
  });
  let totalHousehold = getTotalHouseholdLayer(buildings);
  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });
  return areaProportions;

}
export function calculateAreaProPortion(buildings: ConverterBuilding[]){//buildings: BuildingTypeData[]) {
  let areaProportions: areaProportion[] = [];

  buildings.forEach(blockF => {
    blockF.parts.forEach(blockB => {
      blockB.parts.forEach(component=>{
        if ((component as BuildingComponent).componentType === "house") {
          let ap = areaProportions.find(e => e.housingPlanTypeArea === component.totalExclusiveAreas)
          if (!ap) {
            areaProportions.push({
              housingPlanTypeArea: component.totalExclusiveAreas,// exclusiveArea,
              housingPlanTypeNumberMin: ((component as BuildingHouseUnit).level.length - (component as BuildingHouseUnit).piloti),
              housingPlanTypeProportion: 0,
              numberOfBay: 0,
              templateName: '',
            })
          }
          else {
            ap.housingPlanTypeNumberMin += ((component as BuildingHouseUnit).level.length - (component as BuildingHouseUnit).piloti);
          }
        }
      })
    })
  })
  // buildings.forEach(b => {
  //   b.cores.forEach(c => {
  //     c.houses.forEach(h => {
  //       let ap = areaProportions.find(e => e.housingPlanTypeArea === h.exclusiveArea)
  //       if (!ap) {
  //         areaProportions.push({
  //           housingPlanTypeArea: h.exclusiveArea,
  //           housingPlanTypeNumberMin: (h.level.length - h.piloti),
  //           housingPlanTypeProportion: 0,
  //           numberOfBay: 0,
  //           templateName: '',
  //         })
  //       }
  //       else {
  //         ap.housingPlanTypeNumberMin += (h.level.length - h.piloti);
  //       }
  //     })
  //   })
  // });

  let totalHousehold = getTotalHousehold(buildings);

  areaProportions.forEach(a => {
    a.housingPlanTypeProportion = Number((a.housingPlanTypeNumberMin / totalHousehold).toFixed(12));
  });
  return areaProportions;
}

function fixedNumber(n: number, length: number) {
  return Number(n.toFixed(length));
}


export function buildingStoriesAvg(buildings: BuildingTypeData[]) {
  let totalHouseLine = 0;
  let totalHouseHold = 0;
  let totalLevel = 0;
  let totalBaseArea = 0;
  let totalBuildingArea = 0;

  buildings.forEach(b => {
    let maxLevel = 0;
    let baseArea = 0;
    b.cores.forEach(c => {
      totalHouseLine += c.houses.length;
      c.houses.forEach(h => {
        totalHouseHold += (h.level.length - h.piloti);
        maxLevel = Math.max(maxLevel, h.level.length);
        baseArea += h.exclusiveArea;
        totalBuildingArea += h.exclusiveArea + h.serviceArea;
      })
      baseArea += c.area;
      totalBuildingArea += c.area;
    })
    totalLevel += maxLevel;
    totalBaseArea += baseArea / maxLevel;
  });

  let floorAvg_totalHouse = fixedNumber(totalHouseHold / totalHouseLine, 12);
  let floorAvg_Math = fixedNumber(totalLevel / buildings.length, 12);
  let floorAvg_area = fixedNumber(totalBuildingArea / totalBaseArea, 12);

  let buildingAvg: loc_building_stories_avg = {
    AREA: floorAvg_area,
    HOUSE: floorAvg_totalHouse,
    NUMERICAL: floorAvg_Math,
  }

  return buildingAvg;
}

export function blockBuildingStoriesAvg(buildings: ConverterBuilding[]) {
  let totalHouseLine = 0;
  let totalHouseHold = 0;
  let totalLevel = 0;
  let totalBaseArea = 0;
  let totalBuildingArea = 0;

  
  buildings.forEach(b => {
    let maxLevel = 0;
    let baseArea = 0;
    b.parts.forEach(blockB => {
      blockB.parts.forEach(component => {
        totalBuildingArea += component.totalExclusiveAreas + component.totalCoreAreas + component.totalServiceAreas;
        baseArea += component.totalCoreAreas;
        maxLevel = Math.max(maxLevel, component.levelCount);
        totalHouseHold += component.houseNumber;
        if (component.buildingType === 'component') {
          if ((component as BuildingComponent).componentType === 'house') {
            totalHouseLine++;
          }
        }
  
      })
    })
    // b.parts.forEach(p => {
    //   totalBuildingArea += p.totalExclusiveAreas + p.totalCoreAreas + p.totalServiceAreas;
    //   baseArea += p.totalCoreAreas;
    //   maxLevel = Math.max(maxLevel, p.levelCount);
    //   totalHouseHold += p.houseNumber;
    //   if (p.buildingType === 'component') {
    //     if ((p as BuildingComponent).componentType === 'house') {
    //       totalHouseLine++;
    //     }
    //   }
    // })

    totalLevel += maxLevel;
    totalBaseArea += baseArea / 10;
  });

  let floorAvg_totalHouse = fixedNumber(totalHouseHold / totalHouseLine, 12);
  let floorAvg_Math = fixedNumber(totalLevel / buildings.length, 12);
  let floorAvg_area = fixedNumber(totalBuildingArea / totalBaseArea, 12);

  let buildingAvg: loc_building_stories_avg = {
    AREA: floorAvg_area,
    HOUSE: floorAvg_totalHouse,
    NUMERICAL: floorAvg_Math,
  }

  return buildingAvg;
}

export function mouseOverLayerTag(layers: ConverterLayer[], layer: ConverterLayer) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if (l.name !== layer.name) {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
        p.lineMesh.renderOrder = 0;
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
        p.lineMesh.renderOrder = 1;
      }
    })
  })
}

export function mouseOutLayerTag(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 1 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.5;
      p.lineMesh.renderOrder = l.z_index;
    })
  })
}

export function darkenAllLayer(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 0.2 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.1;
    })
  })
}
export function darkenAllBlock( component?: BuildingCoreUnit | BuildingHouseUnit | BuildingPart | [], allComponent?: Array<any>) {
  allComponent && allComponent.forEach(a => setOpacity(a))
  
  function setOpacity (block: any) {
    block.polygon && block.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 0.2 })
    block.parts && block.parts.forEach((p: any) => setOpacity(p))
  }
}

export function darkenAllBlockLegacy(component?: BuildingCoreUnit | BuildingHouseUnit | BuildingPart | [], allComponent?: Array<any>) {
  if (allComponent) {
    allComponent.forEach((blockF: any) => {
      if (blockF.polygon) {
        blockF.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 0.2 })
      }
      blockF.parts.forEach((blockB: any) => {
        if (blockB.polygon) {
          blockB.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 0.2 })
        }
        blockB.parts.forEach((block: any) => {
          block.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 0.2 });
          if (block.buildingType === "group") {
            block.parts.forEach((part: any) => {
              part.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 0.2 });
            })
          }
        })
      })
    })
  }
}



export function brightenAllLayer(layers: ConverterLayer[] ) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: 1 };
      //@ts-ignore
      p.innerMesh.material.opacity = 0.5;

    })
  })
}

export function brightenAllField(fields: ConverterField[]) {
  fields.forEach(f => {
    f.parts.forEach(p => {      
      p.polygon.lineMesh.material.uniforms.opacity = { value: 1 };
      //@ts-ignore
      p.polygon.innerMesh.material.opacity = 0.5;
    })
  })
}

export function darkenAllField(fields: ConverterField[]) {
  fields.forEach(f => {
    f.parts.forEach(p => {
      p.polygon.lineMesh.material.uniforms.opacity = { value: 0.2 };
      //@ts-ignore
      p.polygon.innerMesh.material.opacity = 0;
    })
  })
}


export function setBlockOpacity(blocks: any, opacity: number, polygon?: Polygon[]) {
  blocks && blocks.forEach((a: any) => setOpacity(a));  
  if (polygon) {
    if (isArray(polygon)) polygon.forEach(p=> p.lineMesh.material.uniforms.opacity = {value: opacity});
    //@ts-ignore
    else polygon.lineMesh.material.uniforms.opacity = {value: opacity};
  }
  
  function setOpacity (block: any) {
    if (block.polygon) {
      if (isArray(block.polygon)) block.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: opacity })
      //@ts-ignore
      else block.polygon.lineMesh.material.uniforms.opacity = {value: opacity};
    }
    block.parts && block.parts.forEach((p: any) => setOpacity(p));
  }
}

// export function brightenAllBlockLegacy(blocks: Array<BuildingCoreUnit | BuildingHouseUnit | BuildingPart> | undefined, name?: string) {
//   if (!blocks || blocks.length === 0) return;

//   // 특정 네임의 레이어만 brighten
//   if (name) { 
//     blocks.forEach(block => {
//       (block as BuildingHouseUnit).polygon.forEach(polygon => {
//         if (polygon.layer === name) {
//           polygon.lineMesh.material.uniforms.opacity = {value: 1};
//         }
//       })
//     })
//     return;
//   }
  
//   // B일때 처리
//   if (blocks[0]!.name && blocks[0].name!.toUpperCase().indexOf('B') >= 0) {    
//     blocks[0].polygon.forEach((polygon: Polygon) => {
//       polygon.lineMesh.material.uniforms.opacity = { value: 1 };
//     })
//     blocks = blocks[0].parts;
//   }
//   // F일때
//   blocks.forEach(block => {
    
//     //@ts-ignore
//     if (block.polygon) {
//       block.polygon.forEach((polygon: any) => {    
//         polygon.lineMesh.material.uniforms.opacity = { value: 1 };
//       })
  
//     }
//   })

//   // H, C일때
//   blocks.forEach(block => {
    
//     //@ts-ignore
//     if (block.polygon) {
//     block.polygon.forEach((polygon: any) => {    
//         polygon.lineMesh.material.uniforms.opacity = { value: 1 };
//       })
  
//     }
//   })
// }

// export function brightenAllBlock(blocks: Array<BuildingCoreUnit | BuildingHouseUnit | BuildingPart> | undefined, name?: string) {
//   if (!blocks || blocks.length === 0) return;

//   blocks && blocks.forEach(b => setBrighten(b));

//   function setBrighten(block: any) {
//     block.polygon && block.polygon.forEach((p: any) => p.lineMesh.material.uniforms.opacity = { value: 1 });
//     block.part
//   }
// }

export function mouseOverHouseTag(layers: ConverterLayer[], house: House) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      if ((house.wall && l.name === house.wall.name) ||
        (house.lightWindow && l.name === house.lightWindow.name) ||
        (house.normalWindow && l.name === house.normalWindow.name)) {
        p.lineMesh.material.uniforms.opacity = { value: 0.8 };
        //@ts-ignore
        p.innerMesh.material.opacity = 0.5;
      }
      else {
        p.lineMesh.material.uniforms.opacity = { value: 0.2 };
        //@ts-ignore
        p.innerMesh.material.opacity = 0.1;
      }
    })
  })
}

export function allLayerSetToBaseColor(layers: ConverterLayer[]) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color().set(l.color);
    })
  })
}

export function changeAllLayerOpacity(layers: ConverterLayer[], opacity: number) {
  layers.forEach(l => {
    l.polygons.forEach(p => {
      p.lineMesh.material.uniforms.opacity = { value: opacity };
    })
  })
}

export function findBuilding(buildings: BuildingTypeData[], name: string): BuildingTypeData | null {
  let building: BuildingTypeData | null = null;
  buildings.forEach(b => {
    building = b.name === name ? b : null;
  })

  return building;
}

export function GetPolygonCentroid(layer: ConverterLayer) {
  let center = new THREE.Vector3(0, 0, 0);

  if (layer.polygons.length <= 0) {
    return center;
  }

  let polygons = GetJSTSPolygonFormLayer(layer);
  polygons.forEach(p => {
    // buffer(-1)에 try catch 필요
    try {
      //@ts-ignore
      let c = p.buffer(-1).getCentroid();
      center.add(new THREE.Vector3(c.getX(), c.getY(), 0));
    } catch (error) {

    }
  })
  return center.divideScalar(polygons.length);
}

export function GetLayerOverlapState(layer: ConverterLayer) {
  
  let polygons: jsts.geom.Geometry[] = [];
  polygons = polygons.concat(GetJSTSPolygonFormLayer(layer));
  return polygons.length > 1 ? CheckPolygonOverlap(polygons) : false;
}



//
export function CheckPolygonOverlap(polygons: jsts.geom.Geometry[]) {
  let overlapInter = null;
  for (let i = 0; i < polygons.length - 1; i++) {
    for (let j = i + 1; j < polygons.length; j++) {      
      let interPoly = polygons[i].intersection(polygons[j]);
      if (interPoly.getArea() > 0.0001) {
        overlapInter = interPoly;
      }
    }
  }
  
  return overlapInter
}

export function fieldToTHREEGeo(field: ConverterField) {
  let jstsGeo: jsts.geom.Geometry = field.getUnionJstsPolygon();

  let vertsTri: number[] = [];
  let vertsGeo: THREE.Vector3[] = [];
  jstsGeo.getCoordinates().forEach(coord => {
    vertsTri.push(coord.x, coord.y);
    vertsGeo.push(new THREE.Vector3(coord.x, coord.y));
  });

  let triangles = earcut(vertsTri);
  console.log(triangles, 'tri')
  const geo = new THREE.Geometry();
  if (jstsGeo.getCoordinates().length > 2) {
    geo.vertices = vertsGeo;
    for (let i = 0; i < triangles.length - 2; i += 3) {
      geo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));
    }
  }

    return new THREE.Mesh(geo,  new THREE.MeshBasicMaterial({ color: new THREE.Color(1, 1, 1) }))


}

export function JSTSGeoToTHREEGeo(jstsGeo: jsts.geom.Geometry) {
  let vertsTri: number[] = [];
  let vertsGeo: THREE.Vector3[] = [];
  jstsGeo.getCoordinates().forEach(coord => {
    vertsTri.push(coord.x, coord.y);
    vertsGeo.push(new THREE.Vector3(coord.x, coord.y, 0));
  });

  let triangles = earcut(vertsTri);
  const geo = new THREE.Geometry();
  if (jstsGeo.getCoordinates().length > 2) {
    geo.vertices = vertsGeo;
    for (let i = 0; i < triangles.length - 2; i += 3) {
      geo.faces.push(new THREE.Face3(triangles[i], triangles[i + 1], triangles[i + 2]));
    }
  }

    return new THREE.Mesh(geo,  new THREE.MeshBasicMaterial({ color: new THREE.Color(1, 0, 0) }))


}

export function CheckPolygonsApart(core: jsts.geom.Geometry[], houses: jsts.geom.Geometry[]) {
  let apart = false;
  if (core.length > 0 && houses.length > 0) {
    //@ts-ignore
    let geoNumber = core[0].union(houses[0].buffer(0)).getNumGeometries(); // 0.02
    if (geoNumber > 1) apart = true;
  } 
  return apart;
}

export function GetJSTSPolygonFormLayer(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = [];
  layer.polygons.forEach(p => {
    if (p.shape) {
      let coords: jsts.geom.Coordinate[] = [];
      p.vertices.forEach(v => {
        coords.push(new jsts.geom.Coordinate(v.x, v.y));
      })
      let geoFac = new jsts.geom.GeometryFactory();
      let linearRing = geoFac.createLinearRing(coords);
      //@ts-ignore
      polygons.push(geoFac.createPolygon(linearRing, []).buffer(0));
    }
  })
  return polygons;
}





export function isOverlapBlock(polyList: Array<{ polygon: any, uuid: string, name: string }>) {
  let overlapList = [];
  for (let i = 0; i < polyList.length; i++) {
    for (let j = i + 1; j < polyList.length; j++) {
      let interPoly = polyList[i].polygon.intersection(polyList[j].polygon);           
      if (interPoly.getArea() >= 0.0001) { // 0.00001    //1   
        overlapList.push({first: polyList[i].uuid, second: polyList[j].uuid});
      }
    }
  }
  return overlapList;
}
//component or building(uuid), veretices

export function checkPolygonError(buildings: Array<BuildingCoreUnit | BuildingHouseUnit | BuildingGroup | BuildingPart | ConverterBuilding>, dataUnit: Unit) {
  let resList: {
    polygon: {
      polygon: jsts.geom.Polygon[],
      line: jsts.geom.LineSegment[]
    },
    building: BuildingCoreUnit | BuildingHouseUnit | BuildingGroup | ConverterBuilding | BuildingPart,
    notWindowVertex?: Array<any>
  }[] = [];
  
  let blockBList: BuildingGroup[] = [];

  buildings.forEach(building => {
    if ((building.name.toUpperCase().startsWith("F")) && building.polygon.length > 0) {
      resList.push({polygon: GetJSTSPolygonFromBuilding(building), building: building,}); // jsts로 만들기   
    }
    //@ts-ignore
    else if (building.buildingType === "group" ) {
      if (building.polygon && building.polygon.length > 0){
        resList.push({polygon: GetJSTSPolygonFromBuilding(building), building: building,});         
      }
      
      blockBList.push(building as BuildingGroup);
    }
    //@ts-ignore
    else if (building.buildingType === "component" && building.componentType === "house") {
      resList.push({polygon: GetJSTSPolygonFromBuilding(building), building: building});     
    }
    //@ts-ignore
    else if (building.buildingType === "component" && building.componentType === "core") {
      resList.push({polygon: GetJSTSPolygonFromBuilding(building), building: building});    
    }
  })
  
  //창문 아닌 레이어 구하기
  resList.forEach(res => {
    res.notWindowVertex = [];
    let notWindowVertex:Array<any> = [];

    if (notWindowVertex.length > 0)
    res.notWindowVertex = notWindowVertex;  
  })
  let res = isOverlap(resList); // 교차 체크
  let apart = isApart(blockBList, resList, dataUnit); // 이격 체크
  //todo 창문레이어 쉐입 체크

  // 끊어짐 체크
  let disConnected:any[] = [];
  blockBList.forEach(blockB => {
    blockB.parts.forEach((component) => {
      
      //@ts-ignore
      if (component.block.entities.filter(e => e.shape).length < 1){
        component.block.entities.forEach((entity, i) => {

          if (entity.layer.toUpperCase() === "CON") {
            //@ts-ignore
            if (entity.editedShape === true) {
              let building = resList.filter(item => item.building.uuid === component.uuid)[0];
              console.log(building.polygon.polygon[0].getCoordinates(), '빌딩');
  
              let coords = building.polygon.polygon[0].getCoordinates();
              let v1 = coords[0];
              let v2 = coords[coords.length-1];          
              let line = new jsts.geom.LineSegment(new jsts.geom.Coordinate(v1), new jsts.geom.Coordinate(v2));
  
              disConnected.push({building, jsts: line, confirm: true});
            }
          }
        })
        
        //@ts-ignore
        if (component.componentType === "core") { // 코어 끊김
          component.polygon.forEach((p: Polygon, i: number) => {          
  
            if (!p.shape && p.layer.toUpperCase() === "CON") {            
              let building = resList.filter(item => item.building.uuid === component.uuid)[0];           
              let unit = getScale(dataUnit);
              if (building.polygon.line[i]) {
                let v1 = new THREE.Vector3(building.polygon.line[i].p0.x, building.polygon.line[i].p0.y, 0);
                let v2 = new THREE.Vector3(building.polygon.line[i].p1.x, building.polygon.line[i].p1.y, 0);
                if (v1.distanceTo(v2) / unit >= 0) {
                  disConnected.push({building, jsts: building.polygon.line[i], confirm: false});
                }
    
              } 
            }
          })
        }
        //@ts-ignore
        else if (component.componentType === "house") { // 세대 끊김
          let k = 0;
          component.polygon.forEach((p: Polygon, i: number) => {
            if (p.layer.toUpperCase() === "CON" && !p.shape) {
              let unit = getScale(dataUnit);
              let building = resList.filter(item => item.building.uuid === component.uuid)[0];    
  
              let v1 = new THREE.Vector3(building.polygon.line[k].p0.x, building.polygon.line[k].p0.y, 0);
              let v2 = new THREE.Vector3(building.polygon.line[k].p1.x, building.polygon.line[k].p1.y, 0);
              
              if (v1.distanceTo(v2) / unit >= 0) {
                disConnected.push({building, jsts: building.polygon.line[k]});
  
              }
            }
            if (p.shape!==true) k++;
          })
        }
  
      }
    })    
  })
  return {overlap: res, apart, disConnected};  
}
export function isApart(blockBList: any, resList: any, dataUnit: Unit) {
  let res: any[] = [];
  blockBList.forEach((b: BuildingGroup) => {
    let group: BuildingComponent[] = [];
    let cores: any[] = [];
    let houses: any[] = [];

    resList.forEach((item: any) => {
      if (item.building.buildingType === "component" && item.building.renderGroup.parent.uuid === b.renderGroup.uuid) {
        group.push(item);
        if (item.building.componentType === "core")
          cores.push(item);
        else if (item.building.componentType === "house")
          houses.push(item);
      }
    });

    let coreUnionPolygon = GetEmptyJSTSGeometry();
    cores.forEach((core, i) => {
      if (i === 0) {
        coreUnionPolygon = core.unionPoly;
      }
      else {
        coreUnionPolygon.union(core.unionPoly);
      }
    })

    if (coreUnionPolygon.getCoordinates().length > 0) {
      let coreCentroid = coreUnionPolygon.getCentroid();

      let coreCenter = new THREE.Vector3(coreCentroid.getX(), coreCentroid.getY());
      houses.forEach(house => {
        if (CheckPolygonsApart([coreUnionPolygon], [house.unionPoly])) {
          let unit = getScale(dataUnit);
          let distance = house.unionPoly.distance(coreUnionPolygon) / unit;
          console.log(distance, '거리');
          
          if (distance > 0.01) { // distance / 0.001 >= 1 : hosue-core 1mm이상일때  
            let wallCenter = house.unionPoly.getCentroid();
            wallCenter = new THREE.Vector3(wallCenter.getX(), wallCenter.getY());

            let group = new THREE.Group();
            let line = getErrorLine(coreCenter, wallCenter);
            let circle1 = getFilledCircle(coreCenter); // 폴리곤 중심에 빨간 동그라미
            let circle2 = getFilledCircle(wallCenter); // 폴리곤 중심에 빨간 동그라미

            group.add(line);
            group.add(circle1); // 폴리곤 중심에 빨간 동그라미
            group.add(circle2); // 폴리곤 중심에 빨간 동그라미

            res.push({ core: cores[0], house, line: group, distance });
          }
        }
      });

    }
  })

  return res;
}

export function isOverlap(resList: any) {
  
  // 같은 블럭 안에서 polygon과 polygon, line과 line, 
  // 다른 블럭간 polygon과 polygon, line과 line, polygon과 line
  let result = [];
  const geoFactory = new jsts.geom.GeometryFactory();

  for (let i = 0; i < resList.length; i++) {
    let unionPolygon = GetEmptyJSTSGeometry();

    if (resList[i].polygon.polygon.length >= 1) 
      unionPolygon = resList[i].polygon.polygon[0];
    
    for (let k = 1; k < resList[i].polygon.polygon.length; k++) {
      unionPolygon.union(resList[i].polygon.polygon[k]); // polygon union
    }
    resList[i].unionPoly = unionPolygon; // 각 블럭(B, C, H)의 polygon 다 union
  }

  // 서로 다른 component polygon intersect
  for (let i = 0; i < resList.length; i++) {
    for (let j = i + 1; j < resList.length; j++) {
      let interPoly = resList[i].unionPoly.intersection(resList[j].unionPoly);
      
      if (interPoly.getArea() >= 0.1) { // 폴리곤간 교차
        let overlapPercent = interPoly.getArea() / (resList[i].unionPoly.getArea()) * 100;
          result.push([
            { type: 'POLYGON', info: resList[i], jsts: interPoly, scale: resList[i].scale, overlapPercent },
            { type: 'POLYGON', info: resList[j], jsts: interPoly, scale: resList[j].scale,  overlapPercent }
          ]);
      }
    }
  }

  /* 다른 블럭간 line과 polygon */
  for (let i = 0; i < resList.length; i++) {
    for (let j = i + 1; j < resList.length; j++) {
      let check = false;
      
      for (let k = 0; k < resList[j].polygon.line.length; k++) {
        if (resList[i].unionPoly.buffer(0.001).intersects(resList[j].polygon.line[k].toGeometry(geoFactory))) {
          result.push([
            {
              type: 'POLYGON',
              info: resList[i],
              jsts: resList[i].unionPoly,
              scale: resList[i].scale
            },
            {
              type: 'LINE',
              info: resList[j],
              jsts: resList[j].polygon.line[k].toGeometry(geoFactory),
              scale: resList[j].scale,
              lineIdx: k,
              name: resList[j].polygon.lineName[k],
            }
          ])
          check = true;
        }
      }
      if (!check) {
        for (let k = 0; k < resList[i].polygon.line.length; k++) {
          if (resList[j].unionPoly.intersects(resList[i].polygon.line[k].toGeometry(geoFactory))) {
            result.push([
              {
                type: 'LINE',
                info: resList[i],
                jsts: resList[i].polygon.line[k].toGeometry(geoFactory),
                scale: resList[i].scale,
                lineIdx: k,
                name: resList[i].polygon.lineName[k],
              },
              {
                type: 'POLYGON',
                info: resList[j],
                jsts: resList[j].unionPoly,
                scale: resList[j].scale,
              }
            ])
          }
        }
      }
    }
  }

  for (let i = 0; i < resList.length; i++) { // 컴포넌트    
    for (let j = 0; j < resList[i].polygon.notWindowVertex.length; j++) {
      let idx = resList[i].polygon.notWindowVertex[j];

      if (resList[i].unionPoly.intersects(resList[i].polygon.line[idx].toGeometry(geoFactory))) {
        result.push([
          {
            type: 'LINE',
            info: resList[i],
            jsts: resList[i].polygon.line[idx].toGeometry(geoFactory),
            scale: resList[i].scale,
            lineIdx: idx,
            name: resList[i].polygon.lineName[idx]
          },
          {
            type: 'POLYGON',
            info: resList[i],
            jsts: resList[i].unionPoly,
            scale: resList[i].scale
          }
        ])
      }
    }
  }



  // /* 자기자신 line과 line */
  for (let i = 0; i < resList.length; i++) {
    for (let j = 0; j < resList[i].polygon.line.length; j++) {
      for (let k = j + 1; k < resList[i].polygon.line.length; k++) {
        // 창문이 아닌 겹친 라인
        if (resList[i].polygon.line[j].equals(resList[i].polygon.line[k]) && !(/^WIN[1-2]$/i.test(resList[i].polygon.lineName[j]))) {
          result.push([
            {
              type: 'LINE',
              info: resList[i],
              jsts: resList[i].polygon.line[j].toGeometry(geoFactory),
              scale: resList[i].scale,
              lineIdx: j, name: resList[i].polygon.lineName[j]
            },
            {
              type: 'LINE',
              info: resList[i],
              jsts: resList[i].polygon.line[k].toGeometry(geoFactory),
              scale: resList[i].scale,
              lineIdx: k,
              name: resList[i].polygon.lineName[k]
            }
          ])
          break;
        }
      }
    }
  }

  return result;
}

export function GetJSTSPolygonFromField(field: ConverterField) {
  field.renderGroup.updateWorldMatrix(true, true);
  let matrixWorld = field.renderGroup.matrixWorld;
  let polygons: jsts.geom.Polygon[] = [];
  let coords: jsts.geom.Coordinate[] = [];


  field.parts.forEach(part => {
    if (part.shape) {
      part.verts.forEach(v => {
        let newV = v.clone().applyMatrix4(matrixWorld);
        coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
        let geoFac = new jsts.geom.GeometryFactory();
        let linearRing = geoFac.createLinearRing(coords);

        //@ts-ignore
        polygons.push(geoFac.createPolygon(linearRing, []).buffer(0));
      })
    }
    // else {
    //   let newFirstV = part.verts[0].clone().applyMatrix4(matrixWorld);
    //   let newLastV = part.verts[part.verts.length - 1].clone().applyMatrix4(matrixWorld);

    //   let firstV = { x: newFirstV.x, y: newFirstV.y };
    //   let lastV = { x: newLastV.x, y: newLastV.y };

    //   let line = new jsts.geom.LineSegment(new jsts.geom.Coordinate(firstV.x, firstV.y), new jsts.geom.Coordinate(lastV.x, lastV.y));
    // }
  });
  return polygons;
}

export function GetJSTSPolygonFromBuilding(component: BuildingCoreUnit | BuildingHouseUnit | ConverterBuilding | BuildingGroup | BuildingPart) {
  let polygons: { polygon: jsts.geom.Polygon[], line: jsts.geom.LineSegment[], notWindowVertex: Array<number>[], lineName: Array<string>}
    = { polygon: [], line: [], notWindowVertex: [], lineName: [] };

  component.renderGroup.updateWorldMatrix(true, true);

  let matrixWorld = component.renderGroup.matrixWorld;
  let lineIdx = 0;

  //@ts-ignore
  component.polygon.forEach((polygon, i) => {
    
    if (polygon.shape === true && polygon.vertices[0].x === polygon.vertices[polygon.vertices.length-1].x && 
      
      polygon.vertices[0].y === polygon.vertices[polygon.vertices.length-1].y 
      && polygon.vertices.length >= 4
      ) {
      let coords: jsts.geom.Coordinate[] = [];

      //@ts-ignore
      polygon.vertices.forEach(v => {
        let newV = v.clone().applyMatrix4(matrixWorld);
        coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
      });

      let geoFac = new jsts.geom.GeometryFactory();
      let linearRing = geoFac.createLinearRing(coords);

      //@ts-ignore
      polygons.polygon.push(geoFac.createPolygon(linearRing, []).buffer(0));
    }
    else {
      let newFirstV = polygon.vertices[0].clone().applyMatrix4(matrixWorld);
      let newLastV = polygon.vertices[polygon.vertices.length - 1].clone().applyMatrix4(matrixWorld);

      let firstV = { x: newFirstV.x, y: newFirstV.y };
      let lastV = { x: newLastV.x, y: newLastV.y };

      let line = new jsts.geom.LineSegment(new jsts.geom.Coordinate(firstV.x, firstV.y), new jsts.geom.Coordinate(lastV.x, lastV.y));

      //@ts-ignore
      polygons.line.push(line);
      polygons.lineName.push(polygon.layer);
      if (polygon.layer !== undefined && !(polygon.layer!.toUpperCase().startsWith("WIN"))) {
        polygons.notWindowVertex.push([lineIdx]);
      }
      lineIdx++;
    }
  })
  return polygons;
}




export function GetUnionJSTSPolygonFormLayer(layer: ConverterLayer) {
  let polygons: jsts.geom.Geometry[] = GetJSTSPolygonFormLayer(layer);
  //@ts-ignore
  let polygon = polygons[0].buffer(0.2);
  polygons.forEach(p => {
    //@ts-ignore
    polygon = polygon.union(p.buffer(0.2));
  })

  return polygon;
}

export function CheckVerticesArrayisCCW(verts: THREE.Vector3[]) {
  let coords: jsts.geom.Coordinate[] = [];
  verts.forEach(v => {
    coords.push(new jsts.geom.Coordinate(v.x, v.y));
  })

  return jsts.algorithm.Orientation.isCCW(coords);
}

export function GetEmptyJSTSGeometry() {
  let geoFac = new jsts.geom.GeometryFactory();
  let linearRing = geoFac.createLinearRing([]);
  //@ts-ignore
  return geoFac.createPolygon(linearRing, []).buffer(0);
}

export function CheckColsedPolygon(layer: ConverterLayer) {
  let shape = true;
  layer.polygons.forEach(p => {
    if (!p.shape)
      shape = false;
  })
  return shape;
}

export function setErrorColorForLayer(layer: ConverterLayer | null) {
  if (layer) {
    layer.polygons.forEach(p => {
      p.lineMesh.material.color = new THREE.Color(0xff0000);
    })
  }
}

export function getLatLonPosition(point: THREE.Vector3, mapProjData: mapProjectionData, screenInfo: screenInfo) {
  let np = point.clone().applyMatrix4(screenInfo.mvp);
  let nx = (np.x + 1) * 0.5 * screenInfo.rendererSize.x;
  let ny = (1 - (np.y + 1) * 0.5) * screenInfo.rendererSize.y;
  return mapProjData.projection.fromPageXYToCoord(NaverPoint(nx + screenInfo.offset.left, ny + screenInfo.offset.top));
}

export function wkt2LatLngs(wkt: string, benchmark: THREE.Vector3, benchmarkInMap: THREE.Vector2) {
  let reader = new jsts.io.WKTReader();
  let polygon = reader.read(wkt);

  let latlngs: any[] = [];
  let bodyPolygon: any[] = [];
  polygon.getCoordinates().forEach(c => {
    let dir = new THREE.Vector3(c.x, c.y, 0).sub(benchmark);
    let latlng = tm2latlng(benchmarkInMap.clone().add(new THREE.Vector2(dir.x, dir.y)));
    bodyPolygon.push([latlng.x, latlng.y]);
  })
  latlngs.push(bodyPolygon);

  return latlngs;
}

export function getPolygonType(type: string, shape: boolean) {
  let polygonType = "";
  if (shape) polygonType = "Polygon";
  else if (type === "LWPOLYLINE") polygonType = "Poly line";
  else polygonType = "LINE";
  return polygonType
}