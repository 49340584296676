import { building } from './resultLocationDataStruct';
import { CompletenessType, Unit } from './DataTypes';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BlockParsingData, BuildingPart, ConverterBuilding } from "./BuildingPart";
import { ErrorLogCell2, ErrorType } from "./ErrorLog";
import * as THREE from '@teneleven/three';
import { BuildingHouseUnit, WindowType } from './BuildingHouseUnit';
import { Polygon } from './DataTypes';
import { getCircle } from './MeshMaker';
import { checkPolygonError, CheckPolygonOverlap, CheckPolygonsApart, getPolygonType, JSTSGeoToTHREEGeo } from './CoreAndHouseController';
import { checkTypeBlockName, switchLineDashedState } from './FileParser';
import { BuildingGroup } from './BuildingGroup';
import userSettingData from './SettingModal';
import { ErrorList, TypeError } from './Error';

type buildingsParam = {
  buildings: ConverterBuilding[],
  blockBs: BuildingPart[],
  cores: BuildingCoreUnit[],
  houses: BuildingHouseUnit[],
}
let errorLogs: ErrorLogCell2[] = [];



export type WindowInfoType= {
  windowPoly: WindowType, 
  p1: THREE.Vector3, 
  p2: THREE.Vector3, 
  line: THREE.Line3, 
  matrixWorld:THREE.Matrix4,
  errorLog: ErrorLogCell2[],
  errorPolygonGroup: THREE.Group,
}

export function checkTypeError(loadFileErrorLogs: ErrorLogCell2[], buildings: buildingsParam, dataUnit: Unit, errorList: ErrorList) {
  let isConnected = checkConnectionComponent(buildings, loadFileErrorLogs, errorList);
  if (!isConnected) {
    return;
  }

  buildings.houses && buildings.houses.forEach(house => house.RebuildOutputPolygon());
//  checkBlockName(buildings, loadFileErrorLogs);
  // 폴리곤 오류 체크
  let res = checkPolygonError([...buildings.buildings, ...buildings.blockBs, ...buildings.cores, ...buildings.houses,], dataUnit);
  checkPolygonOverlap(res.overlap, loadFileErrorLogs, errorList);
  checkApartError(res.apart, errorList);
  checkDisconnectedError(res.disConnected, errorList);
}
function checkPolygonOverlap(overlap: any, loadFileErrorLogs: ErrorLogCell2[], errorList: ErrorList) {
        //* 중복 한번에
        overlap.forEach((res: any, i: number) => {
          let first = res[0];
          let groupAll = new THREE.Group();
          let second = res[1];

          //* 그룹1
          {
            let group = new THREE.Group();
            if (first.type === "POLYGON") {
              let mesh = JSTSGeoToTHREEGeo(first.jsts);
          
              mesh.visible = false;
              group.add(mesh);
              groupAll.children.push(group);
              first.info.building.ErrorPolygonGroup.add(group);
            }
            else if (first.type === "LINE") {
              let mesh = JSTSGeoToTHREEGeo(first.jsts);

              //@ts-ignore
              let vertices = first.info.polygon.line[first.lineIdx];
              let v1 = new THREE.Vector3(vertices.p0.x, vertices.p0.y, 0);
              let v2 = new THREE.Vector3(vertices.p1.x, vertices.p1.y, 0);

              let circleMesh = getCircle(v1, v2, new THREE.Color(1, 0, 0), 5);

              group.add(mesh);
              group.add(circleMesh);
              first.info.building.ErrorPolygonGroup.add(group);
              groupAll.children.push(group);
            }
          }

          //* 그룹2
          {
            let group = new THREE.Group();

            if (second.type === "POLYGON") {
              let mesh = JSTSGeoToTHREEGeo(second.jsts);
              mesh.visible = false;
            
              group.add(mesh);
              groupAll.children.push(group);
              second.info.building.ErrorPolygonGroup.add(group);
            }
            else if (second.type === "LINE") {
              let mesh = JSTSGeoToTHREEGeo(second.jsts);
              mesh.visible = false;

              //@ts-ignore
              let vertices = second.info.polygon.line[second.lineIdx];
              let v1 = new THREE.Vector3(vertices.p0.x, vertices.p0.y, 0);
              let v2 = new THREE.Vector3(vertices.p1.x, vertices.p1.y, 0);

              let circleMesh = getCircle(v1, v2, new THREE.Color(1, 0, 0), 5)
              group.add(circleMesh);
              second.info.building.ErrorPolygonGroup.add(group);
              groupAll.children.push(group);
            }
          }

          let msg = "";
          //@ts-ignore
          //@ts-ignore
          msg = `${first.type === "LINE"? first.name : first.info.building.name}과 ${second.type === "LINE"? second.name : second.info.building.name}이 중복되어 있습니다.`;
          // 폴리곤 - 폴리곤의 중복
          if (first.type === "POLYGON" && second.type === "POLYGON") {
            //@ts-ignore
            let overlapPercent = first.overlapPercent ? first.overlapPercent : 100;

            // INFO
            if (overlapPercent <= 0.1 && userSettingData.myTypeSettingData.layerOverlap.enable) {

              errorList.addError(new TypeError({
                title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
                msg:  `교차 된 ${first.info.building.name} 과 ${first.info.building.name} 이 자동 보정 되었습니다. `,
                type: ErrorType.Info,
                id: [first.info.building.uuid, second.info.building.uuid],
                //@ts-ignore
                hilightPolygon: groupAll,
              }));

              // loadFileErrorLogs.push(makeInfoInformation('[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
              //   `교차 된 ${first.info.building.name} 과 ${first.info.building.name} 이 자동 보정 되었습니다. `,
              //   groupAll));
            }
            // WARNING
            else if (overlapPercent > 0.1 && overlapPercent <= 1 && userSettingData.myTypeSettingData.layerOverlap.enable) {
              // first.info.building.completeness = CompletenessType.warning;
              // second.info.building.completeness = CompletenessType.warning;
                
              errorList.addError(new TypeError({
                title: "[형태적 오류] 유효하지 않은 데이터가 존재합니다. ",
                msg:  `${first.info.building.name}과 ${second.info.building.name}의 면적이 서로 교차되어 있습니다`,
                type: ErrorType.Warning,
                id: [first.info.building.uuid, second.info.building.uuid],
                //@ts-ignore
                hilightPolygon: groupAll,
              }));


              // loadFileErrorLogs.push(makeWarningInformation2("[형태적 오류] 유효하지 않은 데이터가 존재합니다. ",
              //   `${first.info.building.name}과 ${second.info.building.name}의 면적이 서로 교차되어 있습니다`, groupAll));
              if (first.info.building.completeness !== CompletenessType.error) first.info.building.completeness = CompletenessType.warning;

            }
            else {
              first.info.building.completeness = CompletenessType.error;
              second.info.building.completeness = CompletenessType.error;  
              errorList.addError(new TypeError({
                title: "[형태적 오류] 유효하지 않은 데이터가 존재합니다. ",
                msg: `${first.info.building.name}과 ${second.info.building.name}의 면적이 서로 교차되어 있습니다`,
                type: ErrorType.Error,
                id: [first.info.building.uuid, second.info.building.uuid],
                //@ts-ignore
                hilightPolygon: groupAll,
              }));
        
                
              first.info.building.complete = CompletenessType.error;
              second.info.building.complete = CompletenessType.error;
            }
          }
          //* 삭제
          // 폴리곤 - 라인,
          // -라인-라인 중복
          // else {
          //   first.info.building.completeness = CompletenessType.warning;
          //   second.info.building.completeness = CompletenessType.warning; 
         
          //   let mesh = JSTSGeoToTHREEGeo(first.jsts);
          
          //   this.loadFileErrorLogs.push(makeWarningInformation2(msg, '', groupAll, undefined, {
          //     // windows: [first.info.building.polygon, second.info.buildnig.polygon]
          //   }));
          // }
        })
}
function checkApartError(apart: any, errorList: ErrorList) {
 //* 이격
 apart.forEach((res:any) => {
  if (userSettingData.myTypeSettingData.polygonOffset.enable && res.distance < 1) { // info
    res.core.building.ErrorPolygonGroup.add(res.line);
    errorList.addError(new TypeError({
      title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      msg:  `${res.core.building.name} 과 ${res.house.building.name}의 이격된 라인이 자동 보정 되었습니다`,
      type: ErrorType.Info,
      id: [res.core.building.uuid, res.house.building.uuid],
      //@ts-ignore
      hilightPolygon: res.line,
    }));

    // loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
    //   `${res.core.building.name} 과 ${res.house.building.name}의 이격된 라인이 자동 보정 되었습니다`, res.line, undefined,
    // ));
  }
  else if (userSettingData.myTypeSettingData.polygonOffset.enable && res.distance >= 1 && res.distance < 10) { // warning
    res.core.building.ErrorPolygonGroup.add(res.line);
    errorList.addError(new TypeError({
      title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
      msg:   `${res.core.building.name}과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
      type: ErrorType.Warning,
      id: [res.core.building.uuid, res.house.building.uuid],
      //@ts-ignore
      hilightPolygon: res.line,
    }));
    // loadFileErrorLogs.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
    //   `${res.core.building.name}과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`, res.line));
    //   if (res.core.building.completeness !== CompletenessType.error) res.core.info.building.completeness = CompletenessType.warning;
    //   if (res.house.building.completeness !== CompletenessType.error) res.house.info.building.completeness = CompletenessType.warning;
  }
  else { // error
    res.core.building.completeness = CompletenessType.error;
    res.house.building.completeness = CompletenessType.error;    
    res.core.building.ErrorPolygonGroup.add(res.line);
    errorList.addError(new TypeError({
      title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
      msg:   `${res.core.building.name}과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`, 
      type: ErrorType.Error,
      id: [res.core.building.uuid, res.house.building.uuid],
      //@ts-ignore
      hilightPolygon: res.line,
    }));
    // loadFileErrorLogs.push(makeErrorInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다. `,
    //   `${res.core.building.name}과 ${res.house.building.name}간의 ${res.distance.toFixed(2)}mm 의 이격 오차가 있습니다.`, res.line));
    res.core.building.complete = CompletenessType.error;
    res.house.building.complete = CompletenessType.error;
  }
})
}
function checkDisconnectedError(disConnected: any, errorList: ErrorList) {
  //* 끊김
  disConnected.forEach((res: any) => {
    let group = new THREE.Group();
    let v1 = new THREE.Vector3(res.jsts.p0.x, res.jsts.p0.y, 0);
    let v2 = new THREE.Vector3(res.jsts.p1.x, res.jsts.p1.y, 0);

    let circleMesh1 = getCircle(v1, v1, new THREE.Color(1, 0, 0), 5);
    let circleMesh2 = getCircle(v2, v2, new THREE.Color(1, 0, 0), 5);
    group.add(circleMesh1);
    group.add(circleMesh2);

    res.building.building.ErrorPolygonGroup.add(group);

    if (res.confirm && userSettingData.myTypeSettingData.unClosedPolygon.enable) {
      let conLayerName: string[] = [];
      res.building.building.polygon.map((poly: Polygon) => {
        if (poly.layer === "CON" || poly.layer === "con") {
          conLayerName.push(poly.layer);
        }
      })

      errorList.addError(new TypeError({
        title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
        msg: `${conLayerName[0]}의 Poly line이 닫힌 Poly line으로 자동보정 되었습니다.`,
        components: [res.building.building],
        type: ErrorType.Info,
        id: [res.building.building.uuid],
      }))
      // loadFileErrorLogs.push(makeInfoInformation(
      //   `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      //   `${conLayerName[0]}의 Poly line이 닫힌 Poly line으로 자동보정 되었습니다.`,
      //   new THREE.Group(), undefined, {
      //   components: [res.building.building],
      // }
      // ));
    }
    else {
      errorList.addError(new TypeError({
        title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        msg: `${res.building.building.name}에 닫혀 있지 않은 Poly line이 있습니다.`,
        type: ErrorType.Error,
        id: [res.building.building.uuid],
        //@ts-ignore
        hilightPolygon: group,

      }))
      // loadFileErrorLogs.push(makeErrorInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
      //   `${res.building.building.name}에 닫혀 있지 않은 Poly line이 있습니다.`, group));

      // res.building.building.complete = CompletenessType.error;
    }
  })
}

function checkConnectionComponent(buildings: buildingsParam, loadFileErrorLogs: ErrorLogCell2[], errorList: ErrorList) {
  let houses: BuildingHouseUnit[] = [];
  let cores: BuildingCoreUnit[] = [];
  let isConnected = true;
  buildings.blockBs.forEach(blockB => {
    //@ts-ignore
    houses = blockB.parts.filter(part => part.componentType === "house");
    //@ts-ignore
    cores = blockB.parts.filter(part => part.componentType === "core");
  })

  if (buildings.cores.length < 1 && buildings.houses.length >= 1) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg: `${buildings.houses.map(house => house.name).join('/')}에 연결 코어 C가 존재 하지 않습니다.`,
      type: ErrorType.Error,
      id: buildings.houses.map(h => h.uuid),
      components: buildings.houses,
    }));


    // loadFileErrorLogs.push(makeErrorInformation2(
    //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
    //   `${buildings.houses.map(house => house.name).join('/')}에 연결 코어 C가 존재 하지 않습니다.`, new THREE.Group(),
    //   [], { components: buildings.houses }
    // ));
    isConnected = false;
  }
  if (cores.length >= 1 && houses.length < 1) {
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg:  `${cores.map(core => core.name).join('/')}에 연결 유닛 U가 존재 하지 않습니다.`,
      type: ErrorType.Error,
      id: cores.map(core => core.uuid),
      components: cores,
    }));
    // loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
    //   `${cores.map(core => core.name).join('/')}에 연결 유닛 U가 존재 하지 않습니다.`, new THREE.Group(),
    //   [], { components: cores }
    // ));
    isConnected = false;
  }
  return isConnected;
}

export function entityNotInBlock(name: string, errorList: ErrorList) {
  errorList.addError(new TypeError({
    title: '[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
    msg:  `${name} 블록으로 지정되지 않은 Line/Poly line/Polygon 이 존재하여 자동 삭제 되었습니다. `,
    type: ErrorType.Info,
    id: [],
  }));

  
  // errorLogs.push(makeInfoInformation(`[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
  //   `${name} 블록으로 지정되지 않은 Line/Poly line/Polygon 이 존재하여 자동 삭제 되었습니다. `,
  // ));

}

// 레이어 이름 에러 체크
export function checkLayerNameError(parsingData: Array<ConverterBuilding | BuildingPart>, errorList: ErrorList) {
  // 캐드컨버터가 요구하는 layer Naming을 지키지 않은 경우  

  parsingData.forEach(block => {
    let layerHash = new Map();
    let correctName = ["CON", "WIN1", "WIN2"];  
    
    //@ts-ignore
    if (block.buildingType && block.buildingType === "component" && block.componentType === "core") {      
      correctName = ["CON"];
    }
  
    //@ts-ignore
    block.polygon.forEach((poly: Polygon) => {      
      if (poly.layer && correctName.indexOf(poly.layer.toUpperCase()) === -1) {
        poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
        if (!layerHash.get(poly.layer)) layerHash.set(poly.layer, [poly]);
        else {
          let n = layerHash.get(poly.layer);
          n.push(poly);
          layerHash.set(poly.layer, n);
        }
      }
    })

    layerHash.forEach((polygons, layerName) => {      
      errorList.addError(new TypeError({
        title: '[레이어 구조오류] 잘못된 레이어 구조로 저장 할 수 없습니다. ',
        msg: `${layerName}은(는) 유효하지 않은 레이어 이름입니다. ${correctName.join('/')} 레이어만 유효합니다.`,
        type: ErrorType.Error,
        id: [],
        polygons: polygons,
      }));

      changeLineColorRed(polygons, layerName);
    })    
  })

}


export function changeLineColorRed(polygons: Polygon[], layerName: string) {

  layerName = layerName.toUpperCase();

  if (layerName === "CON") {
    polygons.forEach(poly => {
      if (/^CON$/i.test(poly.layer)) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
  else if (layerName === "WIN1" || layerName === "WIN2") {
    polygons.forEach(poly => {
      if (/^WIN[1-2]$/i.test(poly.layer)) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
  else {
    polygons.forEach(poly => {
      if (poly.layer === layerName) {
        switchLineDashedState(poly.lineMesh.material, true);
        poly.lineMesh.color = new THREE.Color(1, 0, 0);
      }
    })
  }
}



/* F블록/B블록 안에 WIN1/WIN2 레이어의 라인/폴리라인/폴리곤이 있을 경우 */
export function checkLayerInBuilding(polygons: Polygon[], block: any, errorList: ErrorList) {

  
  let win1: Polygon[] = [];
  let win2: Polygon[] = [];
  let concrete: Polygon[] = [];
  let hilightPolygons: Polygon[] = [];

  polygons.forEach(poly => {
    let layerName = poly.layer.toUpperCase();
    if (layerName === "WIN1") win1.push(poly);
    else if (layerName === "WIN2") win2.push(poly);
    else if (layerName === "CON") concrete.push(poly);
    switchLineDashedState(poly.lineMesh.material, true);
  })

  if (win1.length || win2.length || concrete.length) {
    let layerName = [];
    let layerType = [];
    let layerTypeSet = new Set();

    concrete.length > 0 && layerName.push("CON");
    win1.length > 0 && layerName.push('WIN1');
    win2.length > 0 && layerName.push('WIN2');

    hilightPolygons = win1.concat(win2).concat(concrete);
    hilightPolygons.map(r => layerTypeSet.add(getPolygonType(r.type, r.shape)));
    layerType = Array.from(layerTypeSet);
    
    errorList.addError({
      type: ErrorType.Warning,
      title: '[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
      msg: `${block.name} 안에 ${layerName.join('/')}로 설정된 ${layerType.join('/')} 이 있습니다.`,
      id: [block.uuid],
      polygons: hilightPolygons,
    })
  }
}

export function checkBlockStruct(buildings: ConverterBuilding[], cores: BuildingCoreUnit[], houses: BuildingHouseUnit[], loadFileErrorLogs: ErrorLogCell2[], errorList: ErrorList) {
  const blockFs = buildings; //블록 F

  // F블록레벨에서 F블록이 아닌 블록
  const notBlockF = blockFs.filter(b => !/^[F][\d]+$/i.test(b.name));
  if (notBlockF.length > 0) {
    const errorPolygons: Polygon[] = [];
    notBlockF.map(block => {
      block.polygon.forEach(poly => {
        errorPolygons.push(poly);
        switchLineDashedState(poly.lineMesh.material, true);
      })
    })
//    console.log(errorPolygon);
    errorList.addError(new TypeError({
      title: '[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
      msg: `F블록으로 설정되지 않은 ${notBlockF.length}개의 블록이 있습니다. `,
      type: ErrorType.Error,
      id: notBlockF.map(block => block.uuid),
      //@ts-ignore
      components: notBlockF,
    }));

    // loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
    //   `F블록으로 설정되지 않은 ${notBlockF.length}개의 블록이 있습니다. `,
    //   new THREE.Group(), undefined, {
    //     components: notBlockF, 
    //  //   windows: errorPolygons,
    // }));

    return false;
  }

  let blockFError = false; // 블록 F 존재여부
  let blockBError = false;

  if (blockFs.length === 0) {
    blockFError = true;
    blockBError = true;
  }

  let blockFName = "";
  let coreHouseError = false;

  // TODO F블록으로 지정되지 않은 N개 폴리라인/폴리곤이 있을 경우 (모든 레이어)
  let polygonTypes = new Set();
  let polygons:Polygon[] = [];
  blockFs.length && blockFs[0].wrongFBlock.forEach(poly => { 
    polygonTypes.add(getPolygonType(poly.type, poly.shape));
    switchLineDashedState(poly.lineMesh.material,true);
    polygons.push(poly)
    blockFs[0].renderGroup.add(poly.lineMesh);
  })
  if (blockFs.length && blockFs[0].wrongFBlock.length > 0) {
    errorList.addError(new TypeError({
      title: '[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
      msg: `F블록으로 지정되지 않은 ${Array.from(polygonTypes).join('')}이 존재하여 자동 삭제 되었습니다.`,
      type: ErrorType.Info,
      id: [],
      //@ts-ignore
      polygons,
    }));

    
    // loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.', 
    //   `F블록으로 지정되지 않은 ${Array.from(polygonTypes).join('')}이 존재하여 자동 삭제 되었습니다.`, new THREE.Group(), 
    //   undefined, {
    //     windows: polygons,
    //   }));
    // - I_detail
    //     **(자동보정) 캐드컨버터 설정 값에 따라 자동보정 되었습니다.** 
      
    //     - (F/B블록)으로 지정되지 않은 (Line/Poly line/Polygon) 이 존재하여 자동 삭제 되었습니다..
  }


  blockFs.forEach(building => { // blockF
    if (!building.name.toUpperCase().startsWith("F")) {
      blockFError = true;
    }
    else blockFName = building.name;

    const notBlockB = building.parts.filter(b => !/^[B][\d]+$/i.test(b.name));
    if (notBlockB.length > 0) {
      const errorPolygons: Polygon[] = [];
      notBlockB.map(block => {
        block.polygon.forEach((poly: Polygon) => {
          errorPolygons.push(poly);
          switchLineDashedState(poly.lineMesh.material, true);
        });
      })

      errorList.addError(new TypeError({
        title:'[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
        msg:  `B블록으로 설정되지 않은 ${notBlockB.length}개의 블록이 있습니다. `,
        type: ErrorType.Error,
        id: [],
        //@ts-ignore
        polygons: errorPolygons,
      }));

      // loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
      //   `B블록으로 설정되지 않은 ${notBlockB.length}개의 블록이 있습니다. `,
      //   new THREE.Group(), undefined, {
      //   windows: errorPolygons,
      // }));

      return false;
    }

    let notCoreAndHouse = 0;
    let notCoreAndHousePart: any = [];
    building.parts.forEach((part: BuildingPart) => { // blockB  
      let core = 0, house = 0;
      if (!part || !/^[B][\d]+$/i.test(part.name)) {
        return;
      }
      part.parts.forEach(component => {
        //@ts-ignore
        if (component.componentType === "core" && component.name.toUpperCase().startsWith("C")) core++;
        //@ts-ignore
        else if (component.componentType === "house" && component.name.toUpperCase().startsWith("U")) house++;
        else {
          notCoreAndHouse++;
          notCoreAndHousePart.push(component);
        }
      })

      let noExistBlock = [];
      if (core === 0) noExistBlock.push('코어 블록 C');
      if (house === 0) noExistBlock.push('유닛 블록 U');
      if (noExistBlock.length) {
        errorList.addError(new TypeError({
          title:'[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `${part.name} 안에 ${noExistBlock.join('/')}가 없습니다.`,
          type: ErrorType.Error,
          id: [part.uuid],
          components: [part],
        }));
    
        // loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        //   `${part.name} 안에 ${noExistBlock.join('/')}가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
      }
      // if (core === 0) {
      //   loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //     `${part.name} 안에 코어 블록 C가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));

      // }
      // if (house === 0) {
      //   loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //     `${part.name} 안에 유닛 블록 U가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
      // }

      if (part.buildingType !== "group") {
        blockBError = true;
      }

      // B블록 안에 WIN1/WIN2/CON레이어가 있는경우
      checkLayerInBuilding(part.polygon, part, errorList);

     
    })

    //U/C블록) 에 U/C블록)으로 설정되지 않은 블록이 있을 경우 (모든 블록)
    if (notCoreAndHouse) {
      errorList.addError(new TypeError({
        title: '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        msg: `U/C블록 으로 설정되지 않은 ${notCoreAndHouse}개의 블록이 있습니다.`,
        type: ErrorType.Error,
        id: notCoreAndHousePart.map((part: any) => part.uuid),
        components: notCoreAndHousePart,
      }));
  
      // loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //   `U/C블록 으로 설정되지 않은 ${notCoreAndHouse}개의 블록이 있습니다.`, new THREE.Group(), undefined, { components: notCoreAndHousePart }));
    }

    // F블록 안에 WIN1/WIN2/CON레이어가 있는경우
    checkLayerInBuilding(building.polygon, building, errorList);

    // F안에 B블록 으로 지정되지 않은 N개의 (폴리라인/폴리곤) 이 있을 경우 (WIN레이어 제외)
    if (building.polygon.length > 0) {
      let polygonType = new Set();
      let polygons: Polygon[] = [];
      building.polygon.forEach((poly: Polygon) => {
        if (!/^WIN[1-2]$/i.test(poly.layer)) {
          polygonType.add(getPolygonType(poly.type, poly.shape));
          polygons.push(poly);
        }
      })
      errorList.addError(new TypeError({
        title: '[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
        msg: `B블록으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
        type: ErrorType.Info,
        id: [],
        //@ts-ignore
        polygons,
      }));

      // loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다. ',
      //   `B블록으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
      //   new THREE.Group(), undefined, {
      //   windows: polygons,
      // }));
    }
  })


  if (blockFError) {
    //TODO
    
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg:`동 평면 블록 F1이 없습니다. `,
      type: ErrorType.Error,
      id: [],
      components: [...cores, ...houses]
    }));

    // loadFileErrorLogs.push(
    //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `동 평면 블록 F1이 없습니다. `,
    //     new THREE.Group(), [], { components: [...cores, ...houses] }));
    return false;
  }
  if (blockBError) {
    //TODO
    
    errorList.addError(new TypeError({
      title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
      msg:  `${blockFName} 안에 코어 공유세대 블록 B1이 없습니다.`,
      type: ErrorType.Error,
      id: [],
      components: [...cores, ...houses]
    }));

    // loadFileErrorLogs.push(makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
    //   `${blockFName} 안에 코어 공유세대 블록 B1이 없습니다.`,
    //   new THREE.Group(), [], { components: [...cores, ...houses] }));
    return false;
  }
  if (coreHouseError) return false;
  return true;
}
