import { ChevronRight, ExpandLess, ExpandMore, Help, Home, HowToVoteRounded, OpenInNew, Pageview, Publish } from '@material-ui/icons';
import React, { Component, createElement } from 'react';
import { Link } from 'react-router-dom';
import '../css/BuilditTemplate.scss';
import '../css/CADConverter/CadConverterHome.scss';
import HeaderDropBox from './HeaderDropBox';
import { buttonNum } from './Modal';
import { Button } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '../Tooltip';
import { FileDrop } from 'react-file-drop';
import MapDownloadModal from './MapDownloadModal';
import JSZip from 'jszip';
import { ReactComponent as FileDownload } from '../img/icon/file_download.svg';
import { ReactComponent as FileUpload } from '../img/icon/file_upload.svg';
import _ from 'lodash';

interface HomeProps {

}

interface HomeState {
  showChangePageBox: boolean,
  typeImgSrc: string,
  siteImgSrc: string,
  planImgSrc: string,
  showTypeLight: boolean,
  showSiteLight: boolean,
  showPlanLight: boolean,
  showGuide: boolean,
  showModal: boolean, // 지적도 다운로드
  showSampleToggle: boolean, // 샘플 파일 다운로드
  showTooltip: boolean,
}

class CadConverterHome extends Component<HomeProps, HomeState> {
  state: HomeState = {
    showChangePageBox: false,
    typeImgSrc: "./img/cad_converter/myTypeThumbnail.png",
    siteImgSrc: "./img/cad_converter/mySiteThumbnail.png",
    planImgSrc: "./img/cad_converter/mySiteThumbnail.png",
    showTypeLight: false,
    showSiteLight: false,
    showPlanLight: false,
    showGuide: false,
    showSampleToggle: false,
    showModal: false,
    showTooltip: false,
  }

  tooltip: null | HTMLDivElement = null;
  tooltipMsg = "";
  mySiteUploader = { left: 0, top: 0, maxX: 0, maxY: 0 }
  myTypeUploader = { left: 0, top: 0, maxX: 0, maxY: 0 }
  myPlanUploader = { left: 0, top: 0, maxX: 0, maxY: 0 }

  guideRef: React.RefObject<any> = React.createRef();
  guideToggleRef: React.RefObject<any> = React.createRef();

  componentDidMount() {
    this.tooltip = document.querySelector('#tooltip');
    const mySite = (this.refs["my-site"] as HTMLInputElement);
    this.mySiteUploader = {
      left: mySite.offsetLeft,
      top: mySite.offsetTop,
      maxX: mySite.offsetLeft + mySite.offsetWidth,
      maxY: mySite.offsetTop + mySite.offsetHeight,
    }
    const myType = (this.refs["my-type"] as HTMLInputElement);
    this.myTypeUploader = {
      left: myType.offsetLeft,
      top: myType.offsetTop,
      maxX: myType.offsetLeft + myType.offsetWidth,
      maxY: myType.offsetTop + myType.offsetHeight,
    }
    const myPlan = (this.refs["my-plan"] as HTMLInputElement);
    this.myPlanUploader = {
      left: myPlan.offsetLeft,
      top: myPlan.offsetTop,
      maxX: myPlan.offsetLeft + myPlan.offsetWidth,
      maxY: myPlan.offsetTop + myPlan.offsetHeight,
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
  }

  turnOffModal() {
    this.setState({ showModal: false })
  }

  handle(value: boolean) {
    this.setState({ showSiteLight: value });
  }

  render() {

    return (
      <div className="CadConverterHome buildit-template"

        onMouseDown={(e) => {
          if (this.state.showGuide && this.guideRef.current.contains(e.target) === false) {
            this.setState({ showGuide: false });
          }
          else if (this.guideToggleRef.current.contains(e.target)) {
            this.setState({ showGuide: !this.state.showGuide });
          }
        }}
        onDragOver={(e) => {
          if (e.pageX < this.mySiteUploader.left || e.pageX > this.mySiteUploader.maxX ||
            e.pageY < this.mySiteUploader.top || e.pageY > this.mySiteUploader.maxY) {
            this.setState({ showSiteLight: false });
          }
          if (e.pageX < this.myTypeUploader.left || e.pageX > this.myTypeUploader.maxX ||
            e.pageY < this.myTypeUploader.top || e.pageY > this.myTypeUploader.maxY) {
            this.setState({ showTypeLight: false });
          }
          if (e.pageX < this.myPlanUploader.left || e.pageX > this.myPlanUploader.maxX ||
            e.pageY < this.myPlanUploader.top || e.pageY > this.myPlanUploader.maxY) {
            this.setState({ showPlanLight: false });
          }
        }}>
        <div id="tooltip" className={`mouse-tooltip font font-12px ${!this.state.showTooltip ? "display-none" : ""}`}>{this.tooltipMsg}</div>
        <MapDownloadModal
          isShow={this.state.showModal}
          turnOffModal={() => this.turnOffModal()}
        />
        <nav className="bgc-navy font font-emphasis app-bar">
          <div className="logo">
            <Link to="/">
              <img src="/img/buildit_platform_logo.svg" style={{ height: "25px" }} />
            </Link>
          </div>
          <HeaderDropBox type="home" showModal={this.showModal} />
        </nav>
        <section className="content-wrap">
          <article className="button-wrap">
            <div className="using-explain-wrap" >
              <div ref={this.guideToggleRef}>
                <Button className="btn bg-navy btn-secondary m-r-md font font-18px width-590px using-explain">
                  <div className="icon-wrap m-r-sm " >{
                    this.state.showGuide ? <ExpandMore className="icon arrow-icon" /> : <ChevronRight className="icon arrow-icon" />
                  }</div>CAD CONVERTER 사용 안내 <span className="m-l-md glass-icon">🔍</span>
                </Button>
              </div>
              <div ref={this.guideRef} className={`cad-converter-guide font font-emphasis font-14px ${!this.state.showGuide ? "hidden" : ""}`}>
                <article className="article-wrap ">
                  <div className="title m-b-sm"><div className="title-icon m-r-sm">ℹ</div>CAD CONVERTER란?</div>
                  <div className="content">
                    <div className="m-b-sm">AutoCAD에서 작성된 도면을 BUILDIT 포맷으로 변환해주는 서비스입니다. <br />
                      변환된 도면은 BUILDIT AI, DESIGNER 서비스에서 활용할 수 있습니다.</div>
                    <div>변환 가능한 종류는 아래와 같습니다.</div>
                    <div>• 동평면</div>
                    <div>• 사업영역</div>
                    <div>• 배치안</div>
                  </div>
                </article>
                <article className="m-t-xl article-wrap">
                  <div className="title m-b-sm"><div className="title-icon m-r-sm">💡</div>사용 순서</div>
                  <div className="content">
                    1. CAD에서 목적에 맞는 도면을 작성합니다. (단, 한 파일에는 한 종류만 가능) <br />
                    2. CAD에서 작성된 'Entity, Layer, Block'을 매뉴얼에 따라 설정합니다.<br />
                    3. 완료된 CAD파일(dxf)을 캐드컨버터에 불러와 나의 파일에 저장합니다.
                  </div>
                </article>
                <article className="m-t-xl article-wrap">
                  <div className="title m-b-sm"><div className="title-icon m-r-sm">🚨</div>'지적도 다운로드' 기능 참고 사항</div>
                  <div className="content">
                    <div>• 사업영역을 포함한 주변 영역의 지적도를 다운로드 받을 수 있습니다.</div>
                    <div>• 사업영역과 배치안 작업시, <span className="font font-special">정확한 위치 설정을 위해 사용을 권장합니다.</span></div>
                    <div>• '지적도 다운로드'는 공공데이터를 기반으로 제공되며, 업데이트가 되지 않은 지역이 있을 수 있습니다.<br />
                      <span className="m-l-xl">(지도에서 한번 더 위치 확인 후 업로드하시길 부탁드립니다.)</span>
                    </div>
                  </div>
                </article>
              </div>
            </div>
            <Button className="btn bg-navy btn-secondary m-r-md" onClick={() => this.setState({ showModal: true })}>지적도 다운로드<Pageview className="icon pageView-icon" /></Button>
            <Button className="btn bg-navy btn-secondary m-r-md">CAD CONVERTER 매뉴얼<OpenInNew className="icon open-icon" /></Button>
            <div className="dropdown sample-file-downloads-wrap">
              <div className="sample-download-btns dropbtn">
                <Button className="btn bg-navy btn-secondary btn-left "
                  onClick={() => {
                    window.location.href = "../img/sample_file/나의 파일들 샘플.zip";
                  }}>샘플 파일 전체 받기<FileDownload className="icon download-icon" />
                </Button>
                <div className="vertical-line"></div>
                <Button className="btn bg-navy btn-secondary btn-right"
                  onMouseEnter={() => this.setState({ showSampleToggle: !this.state.showSampleToggle })}
                onMouseLeave={() => this.setState({ showSampleToggle: !this.state.showSampleToggle })}
                
                >
                  {this.state.showSampleToggle ? <ExpandLess className="icon expand-icon" /> : <ExpandMore className="icon expand-icon" />}
                </Button>
              </div>

              <div className={`dropdown-content ${this.state.showSampleToggle ? "" : "display-none"}`}
                            onMouseEnter={() => this.setState({ showSampleToggle: this.state.showSampleToggle })}
                            onMouseLeave={() => this.setState({ showSampleToggle: !this.state.showSampleToggle })}
              >
                <div className="select-menu">
                  <div className={`dropbox font font-emphasis sample-file-downloads `}>
                    <div className="font font-16px item"
                      onClick={() => {
                        window.location.href = "../img/sample_file/나의 파일들 샘플.zip";
                      }}> 샘플 파일 전체 받기
                      <FileDownload className="icon download-icon" />
                    </div>
                    <div className="font font-16px item"
                      onClick={() => {
                        window.location.href = "../img/sample_file/my_type/나의 동평면 샘플.dxf";
                      }}
                    >동평면 샘플 파일 받기
                      <FileDownload className="icon download-icon" />
                    </div>
                    <div className="font font-16px item"
                      onClick={() => {
                        window.location.href = "../img/sample_file/my_site/나의 사업영역 샘플.dxf";
                      }}
                    >사업영역 샘플 파일 받기
                      <FileDownload className="icon download-icon" />
                    </div>
                    <div className="font font-16px item">배치안 샘플 파일 받기
                      <FileDownload className="icon download-icon" /></div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article className="converter-type-wrap"

          >
            <FileDrop
              className="file-drop"
              onDrop={(files, event) => {
                //@ts-ignore
                this.props.history.push({
                  pathname: '/cad/myTypeblock',
                  state: { file: files }
                });
              }}

              onDragOver={(e) => {
                this.setState({ showTypeLight: true })
              }}

              onFrameDragLeave={() => {
                this.setState({ showTypeLight: false })
              }}
              onFrameDrop={() => {
                this.setState({ showTypeLight: false })
              }}
            >
              <article className={`converter-type m-r-30px`} ref="my-type" >
                <div className="font font-special type-title">나의 동평면
                  <Tooltip msg={<>직접 설정한 동평면 CAD 파일을 '나의 동평면'에 저장하면<br />다양한 BUILDIT 서비스를 경험해 보실 수 있습니다.</>} arrowOn={false}>
                    <div className="icon-wrap"><Help className="icon help-icon" /></div>
                  </Tooltip>
                </div>
                <img className="thumb-img" src={this.state.typeImgSrc}
                  onMouseMoveCapture={(e) => {
                    const mouseX = e.clientX;
                    const mouseY = e.clientY;
                    this.tooltip!.style.left = `${mouseX + 10}px`;
                    this.tooltip!.style.top = `${mouseY + 10}px`;
                  }}
                  onMouseEnter={() => {
                    this.tooltipMsg = "나의 동평면으로 이동";
                    this.setState({ showTooltip: true });
                    this.setState({ typeImgSrc: "./img/cad_converter/myTypeThumbnailHover.png" })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showTooltip: false });
                    this.setState({ typeImgSrc: "./img/cad_converter/myTypeThumbnail.png" })
                  }}
                  onClick={() => window.location.href = "/cad/myTypeBlock"}
                />

                <Button className="btn bg-navy btn-run font font-18px m-t-md" component="label">
                  <input type="file" accept='.dxf' id="ex_file" style={{ display: 'none' }} onClick={e => e.currentTarget.value = ''}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length === 1) {
                        //@ts-ignore
                        this.props.history.push({
                          pathname: '/cad/myTypeblock',
                          state: { file: e.target.files }
                        });
                      }
                    }}
                  ></input>
                  CAD 파일 불러오기 <span className="font font-14px m-l-md">dxf</span> <FileUpload className="icon upload-icon" />
                </Button>
                <span className="font font-secondary bottom-text font-14px m-t-md">또는 파일을 여기로 끌어 놓으세요.</span>
                <div className={`drag-cover ${this.state.showTypeLight ? "my-type" : "display-none"} `}></div>
              </article>
            </FileDrop>
            <FileDrop
              onDrop={(files, event) => {
                //@ts-ignore
                this.props.history.push({
                  pathname: '/cad/mySiteblock',
                  state: { file: files }
                });
              }}
              onDragOver={(e) => {
                this.setState({ showSiteLight: true })
              }}
              onFrameDragLeave={() => {
                this.setState({ showSiteLight: false })
              }}
              onFrameDrop={() => {
                this.setState({ showSiteLight: false })
              }}
            >
              <article ref="my-site" className={`converter-type m-r-30px`}>
                <div className="font font-special type-title">나의 사업영역
                  <Tooltip msg={<>직접 설정한 사업영역 CAD 파일을 '나의 사업영역'에 저장하면<br />다양한 BUILDIT 서비스를 경험해 보실 수 있습니다.</>} arrowOn={false}>
                    <div className="icon-wrap"><Help className="icon help-icon" /></div>
                  </Tooltip>
                </div>
                <img src={this.state.siteImgSrc}
                  className="thumb-img"
                  onMouseMoveCapture={(e) => {
                    const mouseX = e.clientX;
                    const mouseY = e.clientY;
                    this.tooltip!.style.left = `${mouseX + 10}px`;
                    this.tooltip!.style.top = `${mouseY + 10}px`;
                  }}
                  onMouseEnter={(e) => {
                    this.tooltipMsg = "나의 사업영역으로 이동";
                    this.setState({ showTooltip: true });
                    this.setState({ siteImgSrc: "./img/cad_converter/mySiteThumbnailHover.png" })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showTooltip: false });
                    this.setState({ siteImgSrc: "./img/cad_converter/mySiteThumbnail.png" })
                  }}
                  onClick={() => window.location.href = "/cad/mySiteBlock"}
                />
                <Button className="btn bg-navy btn-run font font-18px m-t-md" component="label">
                  <input type="file" accept='.dxf' id="ex_file" style={{ display: 'none' }} onClick={e => e.currentTarget.value = ''}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length === 1) {
                        //@ts-ignore
                        this.props.history.push({
                          pathname: '/cad/mySiteblock',
                          state: { file: e.target.files }
                        });
                      }
                    }}
                  ></input>
                  CAD 파일 불러오기 <span className="font font-14px m-l-md">dxf</span>
                  <FileUpload className="icon upload-icon" /></Button>
                <span className="font font-secondary bottom-text font-14px m-t-md">또는 파일을 여기로 끌어 놓으세요.</span>
                <div className={`drag-cover ${this.state.showSiteLight ? "my-type" : "display-none"}`}></div>
              </article>
            </FileDrop>
            <FileDrop
              onDrop={(files, event) => {
                //@ts-ignore
                this.props.history.push({
                  pathname: '/cad/myPlaneblock',
                  state: { file: files }
                });
              }}
              onDragOver={(e) => {
                this.setState({ showPlanLight: true })
              }}
              onFrameDragLeave={() => {
                this.setState({ showPlanLight: false })
              }}
              onFrameDrop={() => {
                this.setState({ showPlanLight: false })
              }}
            >

            <article ref="my-plan" className="converter-type">
              {/* <div className="dark-wrap"><span className="font font-special text">Coming soon!</span></div> */}
              <div className="font font-special type-title">나의 배치안
                <Tooltip msg={<>직접 설정한 배치안 CAD 파일을 '나의 배치안'에 저장하면<br />다양한 BUILDIT 서비스를 경험해 보실 수 있습니다.</>} arrowOn={false}>
                  <div className="icon-wrap"><Help className="icon help-icon" /></div>
                </Tooltip>

              </div>
              {/* <img src="./img/cad_converter/mySiteThumbnail.png" /> */}
              <img src={this.state.planImgSrc}
                  className="thumb-img"
                  onMouseMoveCapture={(e) => {
                    const mouseX = e.clientX;
                    const mouseY = e.clientY;
                    this.tooltip!.style.left = `${mouseX + 10}px`;
                    this.tooltip!.style.top = `${mouseY + 10}px`;
                  }}
                  onMouseEnter={(e) => {
                    this.tooltipMsg = "나의 배치안으로 이동";
                    this.setState({ showTooltip: true });
                    this.setState({ planImgSrc: "./img/cad_converter/mySiteThumbnailHover.png" })
                  }}
                  onMouseLeave={() => {
                    this.setState({ showTooltip: false });
                    this.setState({ planImgSrc: "./img/cad_converter/mySiteThumbnail.png" })
                  }}
                  onClick={() => window.location.href = "/cad/myPlaneBlock"}
                />
                
              {/* <Button className="btn bg-navy btn-run font font-18px m-t-md">CAD 파일 불러오기 <span className="font font-14px m-l-md">dxf</span>
                <FileUpload className="icon upload-icon" /></Button> */}

                <Button className="btn bg-navy btn-run font font-18px m-t-md" component="label">
                  <input type="file" accept='.dxf' id="ex_file" style={{ display: 'none' }} onClick={e => e.currentTarget.value = ''}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length === 1) {
                        //@ts-ignore
                        this.props.history.push({
                          pathname: '/cad/myPlaneblock',
                          state: { file: e.target.files }
                        });
                      }
                    }}
                  ></input>
                  CAD 파일 불러오기 <span className="font font-14px m-l-md">dxf</span>
                  <FileUpload className="icon upload-icon" /></Button>
              <span className="font font-secondary bottom-text font-14px m-t-md">또는 파일을 여기로 끌어 놓으세요.</span>
              <div className={`drag-cover ${this.state.showPlanLight ? "my-type" : "display-none"}`}></div>
            </article>
            </FileDrop>
          </article>
        </section>
      </div>
    );
  }
}

export default CadConverterHome;