import * as THREE from '@teneleven/three';
import { degrees2radians, radiansToDegrees } from '@turf/turf';
import { BuildingComponent } from './BuildingPart';
import { ConverterBlock } from './ConverterBlock';
import { CompletenessType, ConverterUnit, LineType, Polygon, PolylineInfo } from './DataTypes';
import { switchLineDashedState } from './FileParser';
import { PolygonSimplification } from './PolygonManager';
import { default as _ } from 'lodash';
import { ErrorLogCell2, ErrorType } from './ErrorLog';
import { CheckVerticesArrayisCCW, getPolygonType } from './CoreAndHouseController';
import * as jsts from 'jsts';
import { getCircle, getCurveErrorCircleBlock, getLineErrorCircleBlock } from './MeshMaker';
import { ErrorList, TypeError } from './Error';

export class BuildingCoreUnit extends BuildingComponent {
  readonly componentType: 'core' | 'house';
  // core: BuildingCoreUnit | null;
  private area: number;
  //outputPolygon: any[];
  complete: CompletenessType;
  ErrorLog: ErrorLogCell2[];
  ErrorPolygonGroup: THREE.Group;
  core: any;
  readonly initialArea: number;

  constructor(block: ConverterBlock) {
    super(block);
    this.componentType = 'core';
    this.area = 0;
    this.coreNumber = 1;
    if (block.name.split('_').length === 2 && !isNaN(Number(block.name.split('_')[1]))) {
      this.initialArea = Number(block.name.split('_')[1]);
    }
    else {
//      console.log(this.initialArea, '초기면적');
      this.initialArea = 0;
      
    }
    console.log(this.initialArea, '초깃값');
    
//    this.outputPolygon = this.makeOutputPolygons(block);

    this.complete = CompletenessType.complete;//.warning;
   
    block!.entities.forEach(en => {
      let p = (en as ConverterUnit).polygon;
      let newLineMesh = p.lineMesh.clone(); // material 참조방지
      newLineMesh.material = p.lineMesh.material.clone();
      let newInnerMesh = p.innerMesh.clone(); // material 참조방지;
      
      //@ts-ignore
      newInnerMesh.material.side = THREE.DoubleSide;
    //  newInnerMesh.material = p.innerMesh.material.clone();

      let newPolygon: Polygon = {
        area: p.area,
        hasCurve: p.hasCurve,
        innerMesh: newInnerMesh,//p.innerMesh.clone(),
        lineMesh: newLineMesh,
        selected: p.selected,
        shape: p.shape,
        type: p.type,
        vertices: p.vertices,
        layer: en.layer,
      }

      
 //     (en as ConverterUnit).polygon.layer = en.layer;
      //@ts-ignore
      this.AddNewPolygon(newPolygon);
    })
    this.delOverlapZeroLayer();
    
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
  }

  delOverlapZeroLayer = () =>  { // 0레이어가 블록 내 다른 라인과 겹치면 삭제
    let zeroLayer: Polygon[] = [];
    let otherLayer: Polygon[] = [];

    this.polygon.forEach(poly => {
      if (poly.layer === "0") zeroLayer.push(poly);
      else otherLayer.push(poly);
    })

    let geoFac = new jsts.geom.GeometryFactory;
    let newPolygon = otherLayer;

    for (let i = 0; i < zeroLayer.length; i++) {
      let coords: jsts.geom.Coordinate[] = [];
      zeroLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
      let line1 = geoFac.createLineString(coords);
      let line2;
      for (let j = 0; j < otherLayer.length; j++) {
        let coords: jsts.geom.Coordinate[] = [];
        otherLayer[i].vertices.forEach(v => { coords.push(new jsts.geom.Coordinate(v.x, v.y)) });
        line2 = geoFac.createLineString(coords)
      }
      if (line2 && !line1.intersects(line2)) {
        this.renderGroup.add(zeroLayer[i].lineMesh);
        newPolygon.push(zeroLayer[i]);
      }
    }
    this.polygon = newPolygon;
  }

  checkAreaError = (errorList: ErrorList) => {
    let calcArea = Number(this.polygon.map(p => p.area).reduce((a, b) => a + b, 0).toFixed(4));
    // 면적 오차 오류 체크
    const splitName = this.name.split('_');
    let inputArea = 0;
    if (splitName.length === 2 && !isNaN(Number(splitName[1]))) inputArea = Number(Number(splitName[1]).toFixed(4));

    if (inputArea !== calcArea) {
      errorList.addError(new TypeError({
        title: `[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
        msg:  `${this.name}의 실제 폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다.  
            폴리곤 전체 입력 면적: ${inputArea.toFixed(4)} ㎡, 계산 면적: ${calcArea.toFixed(4)} ㎡, 차이: ${Math.abs(calcArea - inputArea).toFixed(4)} ㎡`,
        type: ErrorType.Warning,
        id: [this.uuid],
        components: [this],
      }));
      // this.ErrorLog.push(
      //   makeWarningInformation2(
      //     `[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
      //     `${this.name}의 실제 폴리곤의 입력 면적과 계산된 면적이 서로 상이합니다.
          
      //     폴리곤 전체 입력 면적: ${inputArea.toFixed(4)} ㎡, 계산 면적: ${calcArea.toFixed(4)} ㎡, 차이: ${Math.abs(calcArea - inputArea).toFixed(4)} ㎡`,
      //     new THREE.Group(), [this.block], { components: [this] }
      //   ));
    }
  }

  checkNoExistArea = (errorList: ErrorList) => {
    // 면적이 들어오지 않은 경우
    let splitName = this.name.split('_');
    if (splitName.length >= 2 && !isNaN(Number(splitName[1])) && Number(splitName[1]) === 0) {
      // 1) 블록 이름에 면적 기입이 안된 경우, (예: H1, H2, C1 뒤에 면적이 안 적혔을 때
      // 에러
      errorList.addError({
        type: ErrorType.Error,
        title: `[면적 오류] 저장이 불가합니다.`,
        msg: `${this.name} 에 면적이 존재하지 않습니다.`,
        id: [this.uuid],
        components: [this],
      })
      // this.ErrorLog.push(makeErrorInformation2(`[면적 오류] 저장이 불가합니다.`,
      //   `${this.name} 에 면적이 존재하지 않습니다.`,
      //   new THREE.Group(), undefined, {
      //   components: [this],
      // }))
      this.complete = CompletenessType.error;
    }
    else {
      let sumArea = 0;
      this.polygon.forEach(poly => {            
        // 2) 코어, 유닛 블록에 폴리곤은 있지만 계산된 면적이 0일 때
        sumArea += poly.area;
      })

      if (sumArea === 0) {
        errorList.addError({
          type: ErrorType.Error,
          title: `[면적 오류] 저장이 불가합니다.`,
          msg: `${this.name} 에 면적이 존재하지 않습니다.`,
          id: [this.uuid],
          components: [this],
        })
  
        // this.ErrorLog.push(makeErrorInformation2(`[면적 오류] 저장이 불가합니다.`,
        //   `${this.name} 에 면적이 존재하지 않습니다.`,
        //   new THREE.Group(), undefined, {
        //   component: this,
        // }))
        this.complete = CompletenessType.error;
      }
    }
  }

  checkZCoord = (errorList: ErrorList) => {
    let layerName = new Set();
    let polygonType = new Set();

    for (let i = 0; i < this.block.entities.length; i++) {
      let entity = this.block.entities[i];
      if ((entity as ConverterUnit).hasZCoord) {
        layerName.add(entity.layer);
        let findPolygon = this.polygon.filter(poly => entity.layer === poly.layer);
        findPolygon.forEach(poly => {
          polygonType.add(getPolygonType(poly.type, poly.shape));
        })
      }
    }
    if (layerName.size) {
      errorList.addError({
        type: ErrorType.Warning,
        title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
        msg: `${Array.from(layerName).join('/')} 의 Z값에 불필요한 ${Array.from(polygonType).join('/')}은 미반영 처리되었습니다.`,
        id: [this.uuid],
        components: [this],
      })

      // //@ts-ignore
      // this.ErrorLog.push(makeWarningInformation2(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
      //   `${Array.from(layerName).join('/')} 의 Z값에 불필요한 ${Array.from(polygonType).join('/')}은 미반영 처리되었습니다.`,
      //   new THREE.Group(), [], { components: [this] }
      // ));
      if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning; 
    }
  }

  CheckCompleteness = (errorList: ErrorList) => {
    let warning = false;

   // this.complete = CompletenessType.complete;

    // 면적 오류
    this.checkAreaError(errorList);
    // z값
    this.checkZCoord(errorList);

    // "CON" 레이어 폴리곤 면적 0
    this.polygon.forEach(poly => {
      if (poly.layer.toUpperCase() === "CON" && poly.shape && poly.area === 0) {
        errorList.addError(new TypeError({
          title:`[면적 오류] 저장 시 입력 면적으로 반영 됩니다. `,
          msg:  `${this.name} 에 속한 ${poly.layer} 의 면적 값이 '0' 입니다.`,
          type: ErrorType.Warning,
          id: [this.uuid],
          components: [this],
          //@ts-ignore
          polygons,
        }));

        // this.ErrorLog.push(makeWarningInformation2(`[면적 오류] 저장 시 입력 면적으로 반영 됩니다. `,
        //   `${this.name} 에 속한 ${poly.layer} 의 면적 값이 '0' 입니다.`,
        //   new THREE.Group(), undefined, {
        //   component: this,
        //   windows: [poly],
        // }))
        if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;
      }
    });


    /* 코어 블록에 WIN1/WIN2 레이어로 라인/폴리라인/폴리곤이 있을 경우 */
    this.polygon.forEach(poly => {
      let layer = poly.layer.toUpperCase();

      if (layer === "WIN1" || layer === "WIN2") {
        let group = getLineErrorCircleBlock(this.renderGroup, poly, this.ErrorPolygonGroup);
        
    errorList.addError(new TypeError({
      title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
      msg:      `${this.name} 에 누락될 수 있는 ${getPolygonType(poly.type, poly.shape)}이 존재합니다.`,
      type: ErrorType.Warning,
      id: [this.uuid],
      //@ts-ignore
      polygons: [poly],
      //@ts-ignore
      hilightPolygon: group,
    }));

        // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        //   `${this.name} 에 누락될 수 있는 ${getPolygonType(poly.type, poly.shape)}이 존재합니다.`,
        //   group, undefined, {
        //   windows: [poly],
        // }))
        if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;
      }
    });

    /* (U/C블록) 안에 N개의 (라인/폴리라인) 이 있을 경우 (지정된 레이어) */
    this.polygon.forEach(poly => {
      let layer = poly.layer.toUpperCase();
      if (!poly.shape && ["CON"].indexOf(layer) > -1) {
        let group = getLineErrorCircleBlock(this.renderGroup, poly, this.ErrorPolygonGroup);

        errorList.addError(new TypeError({
          title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
          msg: `${this.name} 에 하나의 Polygon 만을 허용합니다. `,
          type: ErrorType.Warning,
          id: [this.uuid],
          //@ts-ignore
          polygons: [poly],
          //@ts-ignore
          hilightPolygon: group,
        }));
        // this.ErrorLog.push(makeWarningInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        //   `${this.name} 에 하나의 Polygon 만을 허용합니다. `,
        //   group, undefined, {
        //   windows: [poly],
        // }))
        if (this.complete !== CompletenessType.error) this.complete = CompletenessType.warning;
      }
    });


    /* n개 "폴리곤" 존재 (지정된 레이어) */
    let polygons = this.polygon.filter(poly => poly.shape && ["CON", "WIN1", "WIN2"].indexOf(poly.layer.toUpperCase()) > -1);
    if (polygons.length > 1) {
      errorList.addError(new TypeError({
        title: `[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
        msg:`${this.name}은 하나의 Polygon만을 허용합니다.`,
        type: ErrorType.Info,
        id: [this.uuid],
        //@ts-ignore
        polygons,
      }));

      // this.ErrorLog.push(makeErrorInformation2(`[형태적 오류] 유효하지 않은 데이터가 존재합니다.`,
      //   `${this.name}은 하나의 Polygon만을 허용합니다.`, new THREE.Group(), undefined, {
      //   windows: polygons,
      // }));
      this.complete = CompletenessType.error;
    }





    this.checkRounded(errorList);

    /* 코어(C),유닛(U) 면적이 들어오지 않은 경우*/
   this.checkNoExistArea(errorList);

  //  let CONLayer = this.polygon.filter(poly => /^CON$/i.test(poly.layer))

    //@ts-ignore
    if (this.complete === CompletenessType.error) {
      this.polygon.forEach(poly => {
        this.polygon.forEach(poly => {
          if (/^CON$/i.test(poly.layer)) {
           poly.lineMesh.material.color = new THREE.Color(1, 0, 0);
          }

        })
        switchLineDashedState(poly.lineMesh.material, true);
      });
    }
    // //@ts-ignore
    else if (this.complete === CompletenessType.warning ) {
      this.polygon.forEach(poly => {

        switchLineDashedState(poly.lineMesh.material, true);
      });
    }

  }

  checkRounded = (errorList: ErrorList) => {
    let hasCurve = false;
    this.polygon.forEach(p => {
      if (p.hasCurve) {
        hasCurve = true;
        this.errorBlock = true;
      }
    })

    if (hasCurve) {
      errorList.addError(new TypeError({
        title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
        msg: `${this.name} 에 ARC가 있습니다.`,
        type: ErrorType.Error,
        id: [this.uuid],
        components: [this],
        //@ts-ignore
        hilightPolygon: getCurveErrorCircleBlock(this),
      }));
  
      // this.ErrorLog.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 데이터가 존재합니다. ',
      //   `${this.name} 에 ARC가 있습니다.`,
      //   getCurveErrorCircleBlock(this), [], this));
      this.complete = CompletenessType.error;
    }
  }



  setCenterOfAllLine = (polygon: any) => {


  }

  AddNewPolygon = (polygon: Polygon) => {
    this.polygon.push(polygon);
    let line = polygon.lineMesh.clone();
    polygon.lineMesh.renderOrder = 1;

    if ((!polygon.layer!.toUpperCase().startsWith("WIN") && !polygon.shape)) {
//      polygon.lineMesh.renderOrder = 1;
      switchLineDashedState(line.material, true);
    }
    else {
      switchLineDashedState(line.material, false);
    }

    let mesh = polygon.innerMesh;
    if (polygon.layer !== "0") {
      this.renderGroup.add(mesh);
      this.renderGroup.add(line);
  
    }
    this.renderGroup.updateWorldMatrix(true, true);
    //  mesh.visible = true;

  }

  showInnerMesh = (show: boolean) => {
    if (show) {
    
      this.polygon.forEach(p => {
        p.innerMesh.visible = false;
        p.lineMesh.visible = true;
        p.lineMesh.material.uniforms.opacity = { value: 1 };
        
        p.lineMesh.material.color = new THREE.Color(0xffffff);
        p.lineMesh.material.needUpdate = true;
      })  
    }
    else {
      this.polygon.forEach(p => {
        p.innerMesh.visible = false;
        p.lineMesh.material.color = new THREE.Color().set(this.core.color);
        
      })  
    }
  }

  SetName = (name: string) => { this.name = name; }

  SetPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  SetScale = (scale: THREE.Vector3) => {
    this.scale = scale
    this.renderGroup.scale.set(scale.x, scale.y, 0);
  }

  RotateWithRadians = (radians: number) => {
    this.rotate = radiansToDegrees(radians);
    this.renderGroup.rotateZ(radians);
  }

  RotateWithDegrees = (degress: number) => {
    this.rotate = degress;
    this.renderGroup.rotateZ(degrees2radians(degress));
  }

  SetArea = (area: number) => { this.area = area; }
  GetArea = () => { return this.area; }

  UpdateArea = () => {
    this.totalCoreAreas = this.area;
  }

  // RebuildOutputPolygon = () => {
  //   while (this.outputPolygon.length > 0) {
  //     this.outputPolygon.splice(0, 1);
  //   }
  //   let matrix = new THREE.Matrix4().identity();// this.renderGroup.matrixWorld.clone();
  //   this.polygon.forEach(p => {
  //     let verts = PolygonSimplification(p.vertices);
  //     for (let i = 0; i < verts.length - 1; i++) {
  //       this.outputPolygon.push({
  //         line: new THREE.Line3(verts[i].clone().applyMatrix4(matrix), verts[i + 1].clone().applyMatrix4(matrix)),
  //         thickness: 0.6,
  //         type: LineType.LT_OUTERWALL,
  //       })
  //     }
  //   })
  // }
  RebuildOutputPolygon = () => {

    this.polygon.forEach(p=>{
      let verts = PolygonSimplification(p.vertices);
      while (this.outputPolygon.length > 0) {
        this.outputPolygon.splice(0, 1);
      }
      let matrix = new THREE.Matrix4().identity();// this.renderGroup.matrixWorld.clone();
              
      let worldVerts: THREE.Vector3[] = [];
          
      for (let i = 0; i < verts.length; i++) {
        worldVerts.push(verts[i].clone().applyMatrix4(matrix));
      }
  
      if (!CheckVerticesArrayisCCW(worldVerts)) {
        worldVerts = worldVerts.reverse();
      }
      if (this.renderGroup.matrixWorld.elements[0] * this.renderGroup.matrixWorld.elements[5] < 0) {
        worldVerts = worldVerts.reverse();
      }
      
      for (let i = 0; i < worldVerts.length - 1; i++) {
        worldVerts[i].z = 0;
        worldVerts[i+1].z = 0;
        this.outputPolygon.push({
          line: new THREE.Line3(worldVerts[i], worldVerts[i + 1]),
          thickness: 0.6,
          type: LineType.LT_OUTERWALL,
        })
      }
    })


    // this.polygon.forEach(p => {
    //   let verts = PolygonSimplification(p.vertices);
    //   for (let i = 0; i < verts.length - 1; i++) {
    //     this.outputPolygon.push({
    //       line: new THREE.Line3(verts[i].clone().applyMatrix4(matrix), verts[i + 1].clone().applyMatrix4(matrix)),
    //       thickness: 0.6,
    //       type: LineType.LT_OUTERWALL,
    //     })
    //   }
    // })
  }
  makeOutputPolygons = (block: ConverterBlock) => {
    let lines = new Array<PolylineInfo>();

    if (block && block.entities.length <= 0) return lines;
    //@ts-ignore
    const polygon = block.entities[0].polygon;
    //@ts-ignore
    const verts = block.entities[0].polygon.vertices;

    if (polygon && verts.length > 2) {
      for (let i = 0; i < verts.length - 1; i++) {

        lines.push({
            line: new THREE.Line3(verts[i], verts[i + 1]),
            thickness: 0.6,
            type: LineType.LT_COREOUTERWALL,
          })
        }
    }
    // this.core.polygons.forEach(p => {
    //   if (p.vertices.length > 2) {
    //     for (let i = 0; i < p.vertices.length - 1; i++) {
    //       lines.push({
    //         line: new THREE.Line3(p.vertices[i], p.vertices[i + 1]),
    //         thickness: 0.6,
    //         type: LineType.LT_COREOUTERWALL,
    //       })
    //     }
    //   }
    // })
    
//    this.outputPolygon = lines;
    return lines;
  }

  toJson = () => {
    let text = {
      name: this.name,
      buildingType: this.buildingType,
      block: this.block.name,
      position: this.position,
      scale: this.scale,
      rotate: this.rotate,
      parts: this.parts.map(p => p.toJson()),
      componentType: this.componentType,
      level: this.level,
      area: this.area,
    }

    return text;
  }
}