import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '../css/BuilditUI.scss';
import '../css/CADConverter/BlockPanel.scss';
import * as jsts from 'jsts';
import '@teneleven/protocols-ts-web';
import { Button, ListItemSecondaryAction } from '@material-ui/core'

import { ConverterLayer, buildingTypeStruct, ListType, saveState, CompletenessType, ConverterType, PartOfSelect, entityType, Polygon, ConverterInsert, ConverterUnit, BuildingTypeAlertType } from './DataTypes';
import { asyncFileRead, blockParsing, loadMetaFile, switchLineDashedState } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, makeBlockBuildingData, getMetaData } from './DBManager';
import { SceneManager } from './SceneManager';
import { brightenAllLayer, darkenAllLayer, darkenAllBlock, isOverlapBlock, checkPolygonError, JSTSGeoToTHREEGeo, getPolygonType, setBlockOpacity } from './CoreAndHouseController';
import { Modal, ModalProps, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import { MakeBuildingMesh } from '../Viewer/MeshManager'
import App from '../App';
import queryString from 'query-string';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { SaveViewerModal } from './SaveViewerModal';
import { LoadingPage } from './LoadingPage';
import { userSettingData, Setting } from './SettingModal';
import { House } from './House';
import { ErrorLog, ErrorLogCell, ErrorLogCell2, ErrorType, makeErrorInformation2} from './ErrorLog';
import _, { divide } from 'lodash';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, BuildingComponent, BuildingPart, ConverterBuilding } from './BuildingPart';
import { BuildingPartUI } from './BuildingPartUI';
import { BlockPanelUI } from './BlockPanel'
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { ErrorLogBlock } from './ErrorLogBlock';
import { getCircle, getCurveErrorCircle, getCurveErrorCircleBlock, getErrorLine } from './MeshMaker';
import { group } from 'console';
import BlockTreeList from './BlockTreeList';
import { ReactComponent as BlockIcon } from '../img/icon/blockIcon.svg';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { myTypeSettingData } from './detialSetting';
import { FileDrop } from 'react-file-drop';
import { changeLineColorRed, checkBlockStruct, checkLayerNameError, checkTypeError } from './CheckTypeBlockError';
import { ChevronLeft, Info, NavigateNext } from '@material-ui/icons';
//import { Resizable } from 're-resizable';
import {ReactComponent as HierarchyIcon} from '../img/icon/hierarchyIcon.svg';
import { ErrorList, TypeError } from './Error';
import Tooltip from '../Tooltip';

const uuid4 = require('uuid/v4');

enum houseCoreTabs {
  house = '세대',
  core = '코어'
}

interface SceneProps {

}

interface SceneState {
  layers: ConverterLayer[];
  blocks: ConverterBlock[];
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  listType: ListType;
  houseCoreTabs: houseCoreTabs;
  selectLayer: ConverterLayer | null;
  selectHouse: House | null;
  settingID: string,
  partOfHouse: PartOfSelect,
  totalExclusiveAreas: string;
  totalServiceAreas: string;
  totalCoreAreas: string;
  totalCommonWallAreas: string;
  buildingArea: string,
  groundArea: string,
  userID: string,
  showModal: boolean,
  showSaveModal: boolean,
  hideAuto: boolean,
  loadFile: boolean,
  showAutoSetting: boolean,
  loadingPage: boolean,
  showSample: boolean,
  showSettingModal: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  houseNumber: number,
  loadDB: any,
  clickedBuildingPart: any,
  isSaved: boolean,
  errorLogs: any[],
  currentLog: string,
  showBlockInfo: boolean,
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    blocks: [],
    is2D: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    fileName: '선택된 파일 없음',
    showBlock: false,
    selectLayer: null,
    settingID: '',
    totalCoreAreas: '0',
    totalExclusiveAreas: '0',
    totalServiceAreas: '0',
    totalCommonWallAreas: '0',
    buildingArea: '0',
    groundArea: '0',
    listType: ListType.layer,
    houseCoreTabs: houseCoreTabs.house,
    selectHouse: null,
    saveName: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showSaveModal: false,
    hideAuto: false,
    loadFile: false,
    showAutoSetting: false,
    partOfHouse: PartOfSelect.wall,
    loadingPage: false,
    showSample: true,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    houseNumber: 0,
    loadDB: null,
    clickedBuildingPart: null,
    isSaved: false,
    errorLogs: [],
    currentLog: "ALL",
    showBlockInfo: false,
  };

  mount: HTMLDivElement | null = null;

  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  S3BucketName = 'teneleven-platform-my-building-type-v2';
  DBTableName = 'platform-buildit-my-building-type-v2';
  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }
  fileData: string = '';

  houseCount = 0;
  coreCount = 0;
  blockBs: Array<BuildingPart> = [];
  houses: Array<BuildingHouseUnit> = [];
  cores: Array<BuildingCoreUnit> = [];
  errorLogs: ErrorLogCell2[][] = [];
  loadFileErrorLogs: ErrorLogCell2[] = [];
  globalErrorLogs: ErrorLogCell2[] = [];
  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [] }
  block: ConverterBlock[] = [];
  settingData = new userSettingData(App.tempStage, App.session.email);
  classifyLogs = new Map();
  notComponentBlocks: Array<any> = [];
  errorList = new ErrorList();

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();

    this.houses.forEach(h => {
      this.updateInfoPosition(h.uuid, h.complete, h);
    });
    this.cores.forEach(c => {
      this.updateInfoPosition(c.uuid, c.complete, c);
    });
  }

  constructor(props: SceneProps) {
    super(props);
  }

  updateInfoPosition = (id: string, complete: CompletenessType, component: BuildingHouseUnit | BuildingCoreUnit) => {
    let s = document.querySelector(`.TT${component.uuid}`) as HTMLInputElement;

    if (s) { 
      
      s.style.visibility = 'hidden';
      if (true) {//(complete === CompletenessType.complete) {
        component.renderGroup.updateWorldMatrix(true, true);

        let matrixWorld = component.renderGroup.matrixWorld;
        let coords: jsts.geom.Coordinate[] = [];
      
        if (
            this.globalErrorLogs.filter(log=>log.Type === ErrorType.Error).length === 0 &&
            component.ErrorLog.filter(log=>log.Type === ErrorType.Error).length === 0 
            && this.loadFileErrorLogs.filter(log=>log.Type === ErrorType.Error).length === 0
      //    component.complete === CompletenessType.complete
      //     component.polygon.filter(polygon => polygon.shape).length === 1 && 
      //   component.ErrorLog.filter(log => log.Type === ErrorType.Error).length === 0  
      //  && this.globalErrorLogs.filter(error => error.Type === ErrorType.Error).length === 0 && this.loadFileErrorLogs.filter(error => error.Type === ErrorType.Error).length === 0
        ) {
          s.style.visibility = 'display';
          
          component.polygon.forEach(polygon => {
            
            try {
              if (polygon.shape && polygon.area > 0 && polygon.vertices.length >= 4 &&
                polygon.vertices[0].x === polygon.vertices[polygon.vertices.length - 1].x 
                && polygon.vertices[0].y === polygon.vertices[polygon.vertices.length - 1].y) {
                  
                  
                  polygon.vertices.forEach(v => {
                  let newV = v.clone().applyMatrix4(matrixWorld);
                  coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
                });

                let geoFac = new jsts.geom.GeometryFactory();
                let linearRing = geoFac.createLinearRing(coords);

                //@ts-ignore
                let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);

                let center = new THREE.Vector3(newPolygon.getCentroid().getX(), newPolygon.getCentroid().getY(), 0);
                let c = this.sceneManager.getScreenPosition(center);

                let rect = s.getBoundingClientRect();
                let min = new THREE.Vector2(c.x - rect.width / 2, c.y - rect.height / 2);
                let max = new THREE.Vector2(c.x + rect.width / 2, c.y + rect.height / 2);


                if (!this.sceneManager.canvasBox2.containsPoint(min) || !this.sceneManager.canvasBox2.containsPoint(max)) {
                  //s.style.visibility = 'hidden';

                }
                else
                  s.style.visibility = 'visible';
                // if (completeness === CompletenessType.error) {
                //   s.style.visibility = 'hidden';
                // }
                s.style.top = c.y.toString() + 'px';
                s.style.left = c.x.toString() + 'px';
              }
            }
            catch {
              App.stage !== "prod" && console.log(' update position info error');
            }
          })
        }

      }


    }
  }

  componentDidMount = async () => {    



    await this.settingData.getDataFromDB(); // 사용자 설정값 
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();
//    this.sceneManager.renderer.sortObjects = false;
    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);

    this.sceneManager.addObjectToScene(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.sceneManager.addObjectToScene(light);
    this.sceneManager.addObjectToScene(light.target);

    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    App.stage !== "prod" && console.log(this.sceneManager.canvasElement.getBoundingClientRect());



    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    const qs = queryString.parse(window.location.search);
    if (qs.id) {
      let item = await getMetaData(this.DBTableName, qs.id as string);
      this.block = await loadMetaFile(item.meta_path, this.parsingOutput.buildings, this.settingData.dataUnit);

      this.parsingOutput.buildings.forEach(b => {
        this.polygon2DGroup.add(b.renderGroup);
      })

      this.recalculateResult();

      this.bbox.makeEmpty();
      this.bbox.setFromObject(this.polygon2DGroup);
      let center = new THREE.Vector3();
      this.bbox.getCenter(center);

      this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
      this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
      this.sceneManager.orthoCamera.zoom = 1;

      let aspect = this.state.screenWidth / this.state.screenHeight;
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

      this.setState({
        fileName: item.name,
        saveName: item.name,
        loadingPage: false,
        loadFile: true,
        loadDB: item,
      }, () => this.onWindowResize())

    }

    // var axesHelper = new THREE.AxesHelper(5);
    // this.sceneManager.addObjectToScene(axesHelper);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })

    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      //@ts-ignore
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/myTypeblock');
        }
      })
    }
    // this.cores.forEach(c => {
    //   let center = new THREE.Vector3();
    //   this.bbox.getCenter(center);

    //   this.updateInfoPosition(c.uuid, c.centerOfAllLine, CompletenessType.complete, c);
    // }); // c.complete
  }


  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
    
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);
      this.sceneManager.renderer.setSize(width, height);

    }
    
    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }

    // let error = 0, warning = 0, info = 0;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) warning++;
    //     if (el.Type === ErrorType.Info) info++;

    //   })
    // })
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':

        // this.autoSetHouse();
        break;
      default:
        break;
    }
  }

  // checkBlockStruct = () => {
  //   const buildings = this.parsingOutput.buildings; // D



  //   // F블록레벨에서 F블록이 아닌 블록
  //   const notBlockF = buildings.filter(b => !/^[F][\d]+$/i.test(b.name));
  //   if (notBlockF.length > 0) {
  //     const errorPolygons: Polygon[] = [];
  //     notBlockF.map(block => {
  //       block.polygon.forEach(poly => {
  //         errorPolygons.push(poly);
  //         switchLineDashedState(poly.lineMesh.material, true);
  //       })
  //     })

  //     this.loadFileErrorLogs.push(makeWarningInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
  //       `F블록으로 설정되지 않은 ${notBlockF.length}개의 블록이 있습니다. `,
  //       new THREE.Group(), undefined, {
  //       windows: errorPolygons,
  //     }));
      
      
  //     return false;
  //   }

  //   let blockFError = false; // 블록 F 존재여부
  //   let blockBError = false;

  //   if (buildings.length === 0) {
  //     blockFError = true;
  //     blockBError = true;
  //   }    
  //   let blockFName = "";

  //   let coreHouseError = false;

  //   buildings.forEach(building => { // blockF
  //     if (!building.name.toUpperCase().startsWith("F")) {
  //       blockFError = true;
  //     }
  //     else blockFName = building.name;

  //     const notBlockB = building.parts.filter(b => !/^[B][\d]+$/i.test(b.name));
  //     if (notBlockB.length > 0) {
  //       const errorPolygons: Polygon[] = [];
  //       notBlockB.map(block => {
  //         block.polygon.forEach((poly: Polygon) => {
  //           errorPolygons.push(poly);
  //           switchLineDashedState(poly.lineMesh.material, true);
  //         });
  //       })        

  //       this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 누락 될 수 있습니다.',
  //         `B블록으로 설정되지 않은 ${notBlockB.length}개의 블록이 있습니다. `,
  //         new THREE.Group(), undefined, {
  //         windows: errorPolygons,
  //       }));
  
  //       return false;
  //     }
      
  //     let notCoreAndHouse = 0;
  //     let notCoreAndHousePart: any = [];
  //     building.parts.forEach((part: BuildingPart) => { // blockB  
  //       let core = 0, house = 0;
  //       if (!part || !/^[B][\d]+$/i.test(part.name)) {
  //         return;
  //       }
  //       part.parts.forEach(component => {
  //         console.log(component);

  //         //@ts-ignore
  //         if (component.componentType === "core" && component.name.toUpperCase().startsWith("C")) core++;
  //         //@ts-ignore
  //         else if (component.componentType === "house" && component.name.toUpperCase().startsWith("U")) house++;
  //         else {
            
  //           notCoreAndHouse++;
  //           notCoreAndHousePart.push(component);
  //         }
  //       })

  //       if (core === 0) {
  //         this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //           `${part.name} 안에 코어 블록 C가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
            
  //       }
  //       if (house === 0) {
  //         this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //           `${part.name} 안에 유닛 블록 U가 없습니다.`, new THREE.Group(), undefined, { components: [part] }));
  //       }

  //       if (part.buildingType !== "group") {
  //         blockBError = true;
  //       }

  //       // B블록 안에 WIN1/WIN2레이어가 있는경우
  //      //!지우지말기 
  //      this.checkWindowInBuilding(part.polygon, part.name);

  //       // B블록 안에 폴리곤/폴리라인 이 있는경우 (WIN레이어 제외)
  //       if (part.polygon.length > 0) {
  //         //let polygons: Polygon[] = [];
  //         let polygonType = new Set();
  //         part.polygon.forEach((poly: Polygon) => {      
  //           if (!/^WIN[1-2]$/i.test(poly.layer)) {
  //             polygonType.add(getPolygonType(poly.type, poly.shape));
  //            // polygons.push(poly);  
  //           }
  //         })
  //         polygonType && this.loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
  //           `${part.name}으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`, new THREE.Group(), undefined, {
  //          // windows: polygons,
  //         }));
  //       }
  //     })
  //     console.log(notCoreAndHouse, '3333');
      
  //     //U/C블록) 에 U/C블록)으로 설정되지 않은 블록이 있을 경우 (모든 블록)
  //     if (notCoreAndHouse) {
  //       this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
  //       `U/C블록 으로 설정되지 않은 ${notCoreAndHouse}개의 블록이 있습니다.`, new THREE.Group(), undefined, { components: notCoreAndHousePart }));
  //     }

  //     // F블록 안에 WIN1/WIN2레이어가 있는경우
  //  //!지우지말기  
  //   this.checkWindowInBuilding(building.polygon, building.name);

  //     // (F블록) 으로 지정되지 않은 N개의 (폴리라인/폴리곤) 이 있을 경우 (WIN레이어 제외)
  //     if (building.polygon.length > 0) {
  //       let polygonType = new Set();
  //       let polygons: Polygon[] = [];
  //       building.polygon.forEach((poly: Polygon) => {
  //         if (!/^WIN[1-2]$/i.test(poly.layer)) {
  //           polygonType.add(getPolygonType(poly.type, poly.shape));
  //           polygons.push(poly);
  //         }
  //       })

  //       this.loadFileErrorLogs.push(makeInfoInformation('[자동보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다. ',
  //         `${building.name}으로 지정되지 않은 ${Array.from(polygonType).join('/')} 이 존재하여 자동 삭제 되었습니다.`,
  //         new THREE.Group(), undefined, {
  //         windows: polygons,
  //       }));
  //     }
  //   })

  //   if (blockFError) {
  //     this.loadFileErrorLogs.push(
  //       makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `동 평면 블록 F1이 없습니다. `,
  //         new THREE.Group(), [], {components: [...this.cores, ...this.houses]}));
  //     return false;
  //   }
  //   if (blockBError) {
  //     this.loadFileErrorLogs.push(makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
  //     `${blockFName} 안에 코어 공유세대 블록 B1이 없습니다.`,
  //       new THREE.Group(), [], {components: [...this.cores, ...this.houses]}));
  //     return false;
  //   }
  //   if (coreHouseError) return false;
  //   return true;
  // }


  /* F블록/B블록 안에 WIN1/WIN2 레이어의 라인/폴리라인/폴리곤이 있을 경우 */
  // checkWindowInBuilding = (polygons: Polygon[], blockName: string) => {
  //   let win1: Polygon[] = [];
  //   let win2: Polygon[] = [];
  //   let win: Polygon[] = [];

  //   polygons.forEach(poly => {
  //     let layerName = poly.layer.toUpperCase();
  //     layerName === "WIN1" && win1.push(poly);
  //     layerName === "WIN2" && win2.push(poly);
  //     switchLineDashedState(poly.lineMesh.material, true);
  //   })
  //  }


  checkBlockName = () => {
    // D숫자 이름 체크
    const buildings = this.parsingOutput.buildings;
    for (const building of buildings) {
      if (building.name.toUpperCase() === "F") {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg: `${building.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [],
          components: [...this.cores, ...this.houses],
        }));
        // this.loadFileErrorLogs.push(
        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
        //   `${building.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], {
        //     components: [...this.cores, ...this.houses],
        //   }
        //   ));
      }
      else if (!/^[F]\d+$/i.test(building.name)) {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg:  `${building.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
              수정이 필요한 블록: ${building.name}`,
          type: ErrorType.Error,
          id: [building.uuid],
          components: [...this.cores, ...this.houses],
        }));
        // this.loadFileErrorLogs.push(makeErrorInformation2(
        //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        //   `${building.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
        //   수정이 필요한 블록: ${building.name}`,
        //   new THREE.Group(), [], {
        //   components: [...this.cores, ...this.houses],
        // }
        // ));
      }
    }

    for (const b of this.blockBs) {
      if (b.name.toUpperCase() === "B") {
        this.errorList.addError(new TypeError({
          title: `([블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
          msg:   `${b.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [b.uuid],
          components: [b.parts],
        }));

        // this.loadFileErrorLogs.push(
        //   makeErrorInformation2(`([블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
        //   `${b.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: b.parts }
        //   ));
      }
      else if (!/^[B]\d+$/i.test(b.name)) {
        this.errorList.addError(new TypeError({
          title:  '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg:  `${b.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
          수정이 필요한 블록: ${b.name}`,
          type: ErrorType.Error,
          id: [b.uuid],
          components: b.parts,
        }));

        // this.loadFileErrorLogs.push(makeErrorInformation2(
        //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        //   `${b.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
        //   수정이 필요한 블록: ${b.name}`,
        //   new THREE.Group(), [], 
        //   {
        //     components: b.parts,
        //   }
        //   ));
      }
    }
    // C숫자 이름체크
    for (const core of this.cores) {
      let coreNameRight = true;
      let splitedName = core.name.toUpperCase().split('_');

      if (splitedName.length === 2) {
        if (splitedName[0].toUpperCase() === "C") {
          this.errorList.addError(new TypeError({
            title: `([블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
            msg:   `${core.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
            type: ErrorType.Error,
            id: [core.uuid],
            components: [core],
          }));

        
        }
        else if (!/^[C]\d+$/i.test(splitedName[0])) {
          coreNameRight = false;
        }
        let coreArea = Number(core.name.split('_')[1]);
        if (isNaN(coreArea)) coreNameRight = false;
      }
      else {
        coreNameRight = false;
      }

      if (coreNameRight === false) {
        core.complete = CompletenessType.error;
        this.errorList.addError(new TypeError({
          title: '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
          msg: `${core.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
          수정이 필요한 블록: ${core.name}`,
          type: ErrorType.Error,
          id: [core.uuid],
          components: [core],
        }));

      //   core.ErrorLog.push(makeErrorInformation2(
      //     '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
      //     `${core.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
      //  수정이 필요한 블록: ${core.name}`,
      //     new THREE.Group(), [], {
      //     components: [core],
      //   }
      //   ));
      }
    }

    // U숫자 이름체크
    for (const house of this.houses) {
      let houseNameRight = true;
  
      let splitedName = house.name.split('_');
      if (splitedName[0] === "U") {
        this.errorList.addError(new TypeError({
          title: `([블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, 
          msg: `${house.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house],
        }));

        // house.ErrorLog.push(


        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `${house.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: [house] }
        //   ));
      }
      else if (!/^[U]\d+$/i.test(splitedName[0])) { // H숫자가 아닌경우
        houseNameRight = false;
      }
      if (splitedName.length === 4) { // h1_숫자_숫자_숫자
        for (let i = 1; i < 3; i++) {
          if (Number(splitedName[i]) === NaN) { houseNameRight = false; break; }
        }
      }
      else if (splitedName.length === 2) { // h1_숫자
        let houseArea = splitedName[1];
        if (Number(houseArea) === NaN) houseNameRight = false;
        else {
          // 전용면적만 들어온 경우
          // 0909
          // house.ErrorLog.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
          //   `${house.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
          //   new THREE.Group(), [], {
          //   components: [house],
          // }
          // ));
        }
      }
      else {
        houseNameRight = false;
      }

      if (houseNameRight === false) {
        house.complete = CompletenessType.error;
        this.errorList.addError(new TypeError({
          title:  '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
          msg:  `${house.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
          수정이 필요한 블록: ${house.name}`, 
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house],
        }));

        // house.ErrorLog.push(makeErrorInformation2(
        //   '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
        //   `${house.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
        //   수정이 필요한 블록: ${house.name}`,          
        //   new THREE.Group(), [], {
        //   components: [house],
        // }
        // ));
      }
      else if (house.name.toUpperCase() === "U") {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg:  `${house.name}의 블록 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house]
        }));
        // house.ErrorLog.push(
        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `(블록 이름)의 블록 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: [house]}
        //   ));
      }
    }

    // 그외    
    this.notComponentBlocks.forEach((block) => {
      this.errorList.addError(new TypeError({
        title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
        msg:  `${block.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
        수정이 필요한 블록: ${block.name}`,
        type: ErrorType.Error,
        id: [block.uuid],
        components: [block],
      }));

      // this.loadFileErrorLogs.push(makeErrorInformation2(
      //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //   `${block.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
      //   수정이 필요한 블록: ${block.name}`,
      //   new THREE.Group(), [], {
      //   components: [block],
      // }
      // ));
    })

    return true;
  }



  //@ts-ignore
  checkConnectionComponent = () => {
    let houses: BuildingHouseUnit[] = [];
    let cores: BuildingCoreUnit[] = [];
    let error = true;

    this.blockBs.forEach(blockB => {
      //@ts-ignore
      houses = blockB.parts.filter(part => part.componentType === "house");
      //@ts-ignore
      cores = blockB.parts.filter(part => part.componentType === "core");
    })

    if (cores.length < 1 && houses.length >= 1) {
      this.errorList.addError(new TypeError({
        title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
        msg:   `${houses.map(house => house.name).join('/')}에 연결 코어 C가 존재 하지 않습니다.`,
        type: ErrorType.Error,
        id: [],
        components: houses,
      }));

      // this.loadFileErrorLogs.push(makeErrorInformation2(
      //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //   `${houses.map(house => house.name).join('/')}에 연결 코어 C가 존재 하지 않습니다.`, new THREE.Group(),
      //   [], { components: houses }
      // ));
      error = false;
    }
    if (cores.length >= 1 && houses.length < 1) {
      this.errorList.addError(new TypeError({
        title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
        msg:  `${cores.map(core => core.name).join('/')}에 연결 유닛 U가 존재 하지 않습니다.`,
        type: ErrorType.Error,
        id: [],
        components: cores,
      }));
      // this.loadFileErrorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //   `${cores.map(core => core.name).join('/')}에 연결 유닛 U가 존재 하지 않습니다.`, new THREE.Group(),
      //   [], { components: cores }
      // ));
      error = false;
    }
    return error;
  }

  // checkZCoord = (components: Array<BuildingHouseUnit | BuildingCoreUnit>) => {
  //   components.forEach(component => {
  //     for (let i = 0; i < component.block.entities.length; i++) {
  //       let entity = component.block.entities[i];
  //       if ((entity as ConverterUnit).hasZCoord) {
  //         //@ts-ignore
  //         this.loadFileErrorLogs.push(makeWarningInformation2(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
  //         `(레이어 이름) 의 Z값에 불필요한 Poly line/Polygon은 미반영 처리되었습니다.`, 
  //         //`${component.name} 의 Z값에 불필요한 Poly line/Polygon이 존재하여 자동 삭제 되었습니다.`, 
  //         new THREE.Group(), [], 
  //         {
  //           components: [component],
  //         }
  //         ));
  //         break;          
  //       }
  //     }
  //   })
  // }

  dataInitialize = () => {    
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length  > 0) {
        value.splice(0, 1);
      }
    })
    
 
    while (this.blockBs.length > 0) {
      this.blockBs.splice(0, 1);
    }
    while (this.cores.length > 0) {
      this.cores.splice(0, 1);
    }
    while (this.houses.length > 0) {
      this.houses.splice(0, 1);
    }

    this.polygon2DGroup.children = [];
    this.polygon3DGroup.children = [];

    this.bbox = new THREE.Box3();
    this.errorList.resetError();
    this.setState({
      showBlock: false,
      is2D: true,
      layers: [],
      clickedBuildingPart: null,
      isSaved: false,
      loadFile: false,
      currentLog: "ALL",
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
    })
    
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }
    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }
    while (this.errorLogs.length > 0) {
      this.errorLogs.splice(0, 1);
    }
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs)    
    Array.from(this.classifyLogs.keys()).forEach(key => {
      this.classifyLogs.delete(key);
    })
   
//    this.sumAllArea = 0;
  }


  loadFilebyFile = async (file: any) => {
    this.dataInitialize();

    this.setState({ loadingPage: true })

    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;

    this.fileData = data;
    


    

   let errorLayerList = { errorNameLayers: [], pointError: [] };

    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit, ConverterType.myType, this.errorList, errorLayerList,);
    App.stage !== "prod" && console.log(this.parsingOutput, 'parsing 후');
    

    //! START
    { //* 블럭 구조 체크
      // 블럭 구조 B, D가 없는 경우 & blockBs 값 셋팅
      const isBlockStructOK = checkBlockStruct(this.parsingOutput.buildings, this.cores, this.houses, this.loadFileErrorLogs, this.errorList);

      if (isBlockStructOK) {
        this.parsingOutput.buildings.forEach(blockD => {

          blockD.parts.forEach(blockB => {
            this.blockBs.push(blockB);

            blockB.parts.forEach((component) => {
              //@ts-ignore
              if (component !== undefined && component.componentType === "core") {
                this.cores.push(component as BuildingCoreUnit);
                this.errorLogs.push((component as BuildingCoreUnit).ErrorLog);
              }
              //@ts-ignore
              else if (component !== undefined && component.componentType === "house") {
                this.houses.push(component as BuildingHouseUnit);
                this.errorLogs.push((component as BuildingHouseUnit).ErrorLog);
              } 
              else {
                this.notComponentBlocks.push(component);
              }
            })
          })
  
        })
        // 블럭 이름 체크  
        this.checkBlockName();

        // 레이어 이름 체크
        checkLayerNameError([...this.houses, ...this.cores,...this.parsingOutput.buildings, ...this.blockBs], this.errorList)
      
        // 전용면적만 들어온경우
        this.block.forEach(block => {
          let splitedName = block.name.toUpperCase().split('_');
          if (block.type === "house" && (/^[U](\d+)[_](\d+)$/i.test(block.name) || /^[U]\d[_](\d*)[.](\d*)$/i.test(block.name))) { // 'u_정수', 'u_소수'의 경우
            this.errorList.addError(new TypeError({
              title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
              msg: `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
              type: ErrorType.Info,
              id: this.houses.filter(h => h.name === block.name).map(h => h.uuid),
              components: this.houses.filter(h => h.name === block.name),
            }));
            
            // this.loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
            //   `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
            //   new THREE.Group(), [], {
            //   components: this.houses.filter(h => h.name === block.name)
            // }
            // ));
          }
        })
        
        // 유효 레이어에 존재하는 점: 알람
        errorLayerList.pointError.forEach(layerName => {
          this.errorList.addError(new TypeError({
            title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
            msg:   `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `,
            type: ErrorType.Info,
            id: [],
            
          }));
          // this.loadFileErrorLogs.push(makeInfoInformation('[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.', 
          // `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `, new THREE.Group()))
        })
        this.setState({
          blocks: this.block
        })
      }
    }


      [...this.cores, ...this.houses, ...this.blockBs, ...this.parsingOutput.buildings].forEach(block => {
        this.polygon2DGroup.add(block.ErrorPolygonGroup);
      })

          // 연결세대, 코어확인
      //blockStruct = this.checkConnectionComponent(); // checkTypeError();
    checkTypeError(this.loadFileErrorLogs,
      {
        buildings: this.parsingOutput.buildings,
        blockBs: this.blockBs,
        cores: this.cores,
        houses: this.houses,
      }, this.settingData.dataUnit, this.errorList);
      
      this.setState({
        errorLogs: this.errorList.getError(),
      });
      
    // 완료된 컴포넌트에 면 표현
    {
      let globalErrorCnt = 0;
      let loadFileErrorCnt = 0;
      this.globalErrorLogs.forEach(log => {if (log.Type === ErrorType.Error) globalErrorCnt++; })
      this.loadFileErrorLogs.forEach(log => {if (log.Type === ErrorType.Error) loadFileErrorCnt++; })
      
      this.cores.forEach(core => {
        core.CheckCompleteness(this.errorList);
        if (globalErrorCnt === 0 && core.ErrorLog.filter(core => core.Type === ErrorType.Error).length === 0 && loadFileErrorCnt === 0) {
          core.polygon.forEach(poly => { 
            if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; 
            poly.lineMesh.material.uniforms.opacity = {value: 1};
            poly.lineMesh.renderOrder = 2;
          }
          )
        }
      });
      this.houses.forEach(house => {
        house.getHouseError(this.errorList);
        house.CheckCompleteness(this.errorList);
        if (globalErrorCnt === 0 && house.ErrorLog.filter(house => house.Type === ErrorType.Error).length === 0 && loadFileErrorCnt === 0) {
          house.polygon.forEach(poly => { 
            if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; 
            poly.lineMesh.material.uniforms.opacity = {value: 1};
            poly.lineMesh.renderOrder = 2;
          })
        }
      });
    }

    this.parsingOutput.buildings.forEach(b => {
      this.polygon2DGroup.add(b.renderGroup);
    })
    this.errorLogs.forEach(errors => {
      errors.forEach(error=>{ 
        if (!this.classifyLogs.get(error.Information)) this.classifyLogs.set(error.Information, [error]);
        else this.classifyLogs.set(error.Information, this.classifyLogs.get(error.Information).concat(error));
      })
    })
    if (this.state.errorSize === 0) {
      this.houses.forEach(h => {

        h.polygon.forEach(poly => {
          poly.lineMesh.opacity = 1;
        })
      })
      this.cores.forEach(h => {
        h.polygon.forEach(poly => {
          poly.lineMesh.opacity = 1;
        })
      })
    }

    
    this.setState({
      errorLogs: this.errorList.getError(),
    })
//    this.errorLogs.forEach();

    this.recalculateResult();

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.setState({
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadingPage: false,
      showBlock: true,
      loadFile: true,
      clickedBuildingPart: this.parsingOutput.buildings.length > 0 ? this.parsingOutput.buildings[0] : null,
      
    }, () => {
      this.onWindowResize();

    })
    
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

    this.houses.forEach(h => {
      h.polygon.forEach(p => {
        p.lineMesh.material.uniforms.resolution.value = new THREE.Vector2(this.sceneManager.canvasElement.clientWidth, this.sceneManager.canvasElement.clientHeight);
        p.lineMesh.material.needUpdate = true;
        //        p.lineMesh.resolution.set(this.state.screenWidth, this.state.screenWidth,);
      })
    })
  }

  getFrustumSize = (aspect: number) => {
    let bboxSize = new THREE.Vector3(0);
    this.bbox.getSize(bboxSize);
    let frustumSize = bboxSize.x / 2 * 1.1;
    if (aspect > bboxSize.x / bboxSize.y) {
      let height = bboxSize.y / 2 * 1.1;
      frustumSize = height * aspect;
    }
    return frustumSize;
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }
  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }
  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });
  }


  onWindowResize = () => {   
    this.setState({
      screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0), //- (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 282 : 26) + (!this.state.loadFile ? 26 : 0),
    });
  }

  recalculateResult = () => {
    let totalExclusiveAreas = 0;
    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.UpdateArea();
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
      })
    })

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }
    // let error = 0, waring = 0, info = 0;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) waring++;
    //     if (el.Type === ErrorType.Info) info++;
    //   })
    // })
    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();
    
    

    this.setState({
      totalExclusiveAreas: totalExclusiveAreas.toFixed(4),
      totalServiceAreas: totalServiceAreas.toFixed(4),
      totalCoreAreas: totalCoreAreas.toFixed(4),
      totalCommonWallAreas: totalCommonWallAreas.toFixed(4),
      buildingArea: (totalExclusiveAreas + totalServiceAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      groundArea: (totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      houseNumber: houseNumber,
      errorSize: error,
      warningSize: warning,
      infoSize: info,
      errorLog: error + warning + info > 0 ? true : false,
    })
  }

  Switch2D3D = (is2D: boolean) => {
    if (this.state.errorSize > 0) {
      this.showModal('알림', ['코어와 세대의 세팅을 완성해 주세요.'], buttonNum.oneButton, () => { });
      return;
    }

    this.sceneManager.switchRenderCamera(!is2D);

    if (is2D) {
      this.polygon3DGroup.children = [];


      this.polygon3DGroup.add(this.makeReviewBuilding());
      let bbox = new THREE.Box3();
      bbox.expandByObject(this.polygon3DGroup);
      let bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      this.sceneManager.set3DViewerCameraPosition(bboxCenter);
    }

    this.polygon2DGroup.children.forEach(c => {
      c.visible = !is2D;
    })

    this.polygon3DGroup.children.forEach(c => {
      c.visible = is2D;
    })

    this.setState({
      is2D: !is2D,
    })
  }

  makeReviewBuilding = () => {
    let tmpGroup = new THREE.Group();
    this.parsingOutput.buildings.forEach((block, i) => {
      block.parts.forEach(p => {
        p.RebuildOutputPolygon();
      })
  
      let building = makeBlockBuildingData(block);
  
      building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
      building.floorStatus.forEach(fs => {
        fs.coreFloorInfo.forEach(fi => {
          fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"]
        })
        fs.houseFloorInfo.forEach(hi => {
          hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"]
        })
      })
//      return MakeBuildingMesh(building);
      let result =  MakeBuildingMesh(building);
     // result.position.set(building.position.x * 0.001, 0, building.position.y * 0.001 * 1);
      //@ts-ignore
   //   result= new THREE.Vector3(building.x, building.y, building.z);
      tmpGroup.add(result);
    })
    return tmpGroup
    // this.parsingOutput.buildings[0].parts.forEach(p => {
    //   p.RebuildOutputPolygon();
    // })

    // let building = makeBlockBuildingData(this.parsingOutput.buildings[0]);

    // building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    // building.floorStatus.forEach(fs => {
    //   fs.coreFloorInfo.forEach(fi => {
    //     fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"]
    //   })
    //   fs.houseFloorInfo.forEach(hi => {
    //     hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"]
    //   })
    // })
    // return MakeBuildingMesh(building);
  }


  makeSaveMetaFile = async (savePath: string) => {
    let meta = {
      blocks: this.block.map(b => b.toJson()),
      entities: this.parsingOutput.buildings.map(b => b.toJson()),
    }

    saveDataToS3(JSON.stringify(meta), savePath, 'meta.json', 'application/json');
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;
    this.setState({
      showModal: !this.state.showModal,
    })
  }


  saveToAWS = async (fn: string) => {
    try {

      let uuid = uuid4();
      App.stage !== "prod" && console.log(uuid);
      let globalID, privateID;
      if (this.state.loadDB) {
        globalID = this.state.loadDB.global_id;
        privateID = this.state.loadDB.user_id;
      }
      else {
        let id = await incrementCounter("my_building_type_id", this.state.userID);
        globalID = id.globalId;
        privateID = id.userId;
      }
      App.stage !== "prod" && console.log(globalID, privateID);

      let imageName = `img_large.png`;
      let buildingTemplateName = `${uuid}.json`;
      let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;
      this.makeSaveMetaFile(S3SavePath);

      let captureBbox = new THREE.Box3();
      captureBbox.expandByObject(this.parsingOutput.buildings[0].renderGroup);

      // capture image
      if (!this.state.is2D) { this.Switch2D3D(false); }

      let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
      let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
      let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

      saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
      saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
      saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

      // template data
      this.parsingOutput.buildings[0].parts.forEach(p => {
        p.RebuildOutputPolygon();
        p.UpdateArea();
      })

      let outputData = makeBlockBuildingData(this.parsingOutput.buildings[0]);
      outputData.building.name = uuid;
      App.stage !== "prod" && console.log(outputData.building);

      // dynamoDB data    
      let data = new Date().toISOString();
      let dbItem: buildingTypeStruct = {
        stage: App.tempStage,
        global_id: globalID,
        user_id: privateID,
        uuid: uuid,
        name: fn,
        email: this.state.userID,
        total_exclusive_area: Number(this.state.totalExclusiveAreas),
        total_service_area: Number(this.state.totalServiceAreas),
        core_area: Number(this.state.totalCoreAreas),
        building_area: Number(this.state.buildingArea),
        floor_area: Number(this.state.groundArea),
        common_wall_area: Number(this.state.totalCommonWallAreas),
        houses_number: this.state.houseNumber,
        img_path: `s3://${S3SavePath}/${imageName}`,
        file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
        meta_path: `s3://${S3SavePath}`,
        created_at: data,
        modified_at: data,
        deleted: false
      }
      App.stage !== "prod" && console.log(dbItem);
      saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');
      saveDataToS3(JSON.stringify(outputData.building), S3SavePath, buildingTemplateName, 'application/json');
      saveDataToDynamoDB(dbItem, this.DBTableName);
      this.setState({ isSaved: true });
      // this.showModal('알림', ['나의 동평면을 저장했습니다.'], buttonNum.oneButton, () => {App.stage !== "prod" && console.log('save done')});
      return true;
    }
    catch {
      // this.showModal('알림', ['나의 동평면을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
      // })
      return false;
     
    }
  }

  clickBlockUI = (buildingPart: BuildingPart | ConverterBuilding) => {
    this.setState({ clickedBuildingPart: buildingPart })
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    });
  }

  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  reset = () => {
    this.setState({
      loadFile: false,
    })
    this.dataInitialize();
  }

  changeCurrentLog = (id: string) => {
    console.log(id, 'iddddddddddd');
  console.log(this.errorList.getErrorById(id), '????????????');
      
    this.setState({
        errorLogs: this.errorList.getErrorById(id),
        currentLog: id,
      })
    
  }

  findUUIDBlock(uuid: string) {
    let result: any = undefined;
    for (let i = 0; i < this.parsingOutput.buildings.length; i++) {
      checkUUID(this.parsingOutput.buildings[i]);
      if (result) return result;
    }

    function checkUUID(block: any) {
      if (block.uuid === uuid) {
        result = block;
        return;
      }
      else {
        if (block.parts) {
          for (let i = 0; i < block.parts.length; i++) {
            checkUUID(block.parts[i]);
            if (result) break;
          }
        } 
      }
    }
  }


  render = () => {
    return (
      <div className="buildit-template">
        <MySiteBlockSaveModal
          DBTableName={this.DBTableName}
          converterType={ConverterType.myType}
          userId={this.state.userID}
          showModal={this.state.showSaveModal}
          onShowSaveModal={() => this.showSaveModal()}
          fileName={this.state.saveName}
          onSave={async () => {
            const result = await this.saveToAWS(this.state.saveName);
            return result;
          }}
          typeArea={{
            totalHouseholds: this.state.houseNumber,
            totalExclusiveArea: this.state.totalExclusiveAreas,
            totalCommonWallArea: this.state.totalCommonWallAreas,
            totalCoreArea: this.state.totalCoreAreas,
            totalBuildingArea: this.state.buildingArea,
          }}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
          buildingObject={this.makeReviewBuilding}
        />
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        {/* <SaveViewerModal open={this.state.showSaveModal} buildingObject={this.makeReviewBuilding} positive={this.checkSaveState} /> */}
        <LoadingPage show={this.state.loadingPage} loadingMsg="동평면 파일을 불러오는 중입니다." />
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.myType}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => {this.reset()}}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          isFileOpen={this.state.loadFile}
          saveFile={() => {
            if (this.state.warningSize > 0) {
              let msg: string[] = [];
              [...this.loadFileErrorLogs, ...this.globalErrorLogs].forEach(log => {
                if (log.Type === "Warning") msg.push(log.Information);
              });
              this.setState({ showSaveModal: true })
              // this.showModal('알림', [...msg, '계속 진행하시겠습니까?'], buttonNum.twoButton, 
              // () => { 
              //   this.setState({ showSaveModal: true }) });
            }
            else {
              this.setState({ showSaveModal: true });
            }
          }}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className='MainBody'>
          <div className='areaInfo' hidden={!this.state.is2D}>
            {this.houses.map(h =>
              <div className={`textTest TT${h.uuid}`} key={h.uuid}>
                 <div className="key-value">
                  <li className="font font-12px key house">단위 세대</li>
                  <span className="font font-12px value">{`: ${h.name.split('_')[0]}`}</span>
                </div>
                <div className="key-value">
                  <li className="font font-12px key house">전용 면적</li>
                  <span className="font font-12px value">{`: ${h.exclusiveArea}`}㎡</span>
                </div>
                <div className="key-value">
                  <li className="font font-12px key house">발코니 면적</li>
                  <span className="font font-12px value">{`: ${h.serviceArea}`}㎡</span>  
                </div>
                <div className="key-value">
                  <li className="font font-12px key house">벽체공용 면적</li>
                  <span className="font font-12px value">{`: ${h.commonWallArea}`}㎡</span>
                </div>
              </div>
            )}
            {this.cores.map(c =>
              <div className={`textTest TT${c.uuid}`} key={c.uuid}>
                <div className="key-value">
                  <li className="font font-12px core key">코어</li>
                  <span className="font font-12px value">{`: ${c.name.split('_')[0]}`}</span>
                </div>
                <div className="key-value">
                  <li className="font font-12px core key">코어 면적</li>
                  <span className="font font-12px value">{`: ${c.GetArea()}㎡`}</span>
                </div>
              </div>
            )}
          </div>

          <div className='information'>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>총 세대수</div><div className='inforValue font font-emphasis font-14px'>{this.state.houseNumber}세대</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>총 전용 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalExclusiveAreas}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>총 서비스 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalServiceAreas}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>총 벽체공용 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalCommonWallAreas}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>총 코어 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalCoreAreas}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>건축 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.buildingArea}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>바닥 면적(연면적산출용)</div><div className='inforValue font font-emphasis font-14px'>{this.state.groundArea}㎡</div></div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
              {/* <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showBlock: !this.state.showBlock })}>블록 보기</span> */}
            </div>
          </div>

          <div className='RenderView'>
            <div className='Scene'>
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.myType}
                  showSample={this.state.showSample}
                />
                {
                  this.state.loadFile &&
                  <Button className="btn bg-navy btn-primary fold-btn" onClick={() => { this.setState({ showBlock: !this.state.showBlock }) }}>
                    {this.state.showBlock ?
                      <NavigateNext className="icon icon-next" /> :
                      <ChevronLeft className="icon icon-next" />
                    }
                  </Button>

                }
                
                {/* <div className={`toolBar ${!this.state.loadFile && 'hidden'} 
                     ${this.state.showBlock ? '' : 'showBlock'}  
                     `
                }> */}

                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className="rightButtons">
                    <div className='switchTabs'>
                      <Button className={`switchButton ${this.state.is2D && 'enable'}`} onClick={() => this.Switch2D3D(false)}>CAD</Button>
                      <Button className={`switchButton ${!this.state.is2D && 'enable'}`} onClick={() => this.Switch2D3D(true)}>3D VIEW</Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`} onClick={() => { this.setState({ showBlockInfo: !this.state.showBlockInfo }) }}>
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                  {/* <div className={`autosetDiv ${!this.state.showAutoSetting && 'hidden'}`}>
                  <Button className='autosetButton' onClick={() => { }} >자동 추가</Button>
                  <div className='autosetMessage'>정해진 규칙에 따라 생성된 레이어는 변환 설정값을 자동으로 처리합니다 (상세한 규칙은 메뉴얼을 참고해주세요) </div>
                </div> */}

                  {/* </div> */}
                </div>
              </div>
              <ErrorLogBlock
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                confirmSize={this.state.infoSize}
                classifyLogs={this.classifyLogs}
                errorList={this.state.errorLogs}
                showErrorByType={this.setErrorByType}
                currentLog={this.state.currentLog === "ALL" ? "ALL" : this.findUUIDBlock(this.state.currentLog)!.name}
                allBuildings={this.parsingOutput.buildings}
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none z-index-back"}`}>
                <div className="header">
                  <HierarchyIcon className="icon hierarchy-icon" />
                  <span className="text">Hierarchy</span>
                </div>
                <div className={`description`}>
                  {
                    this.state.showBlock &&
                    <BlockTreeList 
                      errorList={this.errorList}
                      parsingOutput={this.parsingOutput}
                      houses={this.houses}
                      cores={this.cores}
                      components={[...this.parsingOutput.buildings, ...this.blockBs, ...this.houses, ...this.cores]}
                      clickComponent={this.clickBlockUI}
                      clickBuildingPart={this.state.clickedBuildingPart}
                      currentLog={this.state.currentLog}
                      handleCurLog={() => {
                        this.setState({
                          errorLogs: this.errorList.getError(),
                          currentLog: "ALL"
                        })
                      }
                      }
                      handleCurLogById={this.changeCurrentLog}
                      // handleCurLogById={(uuid: ) => {
                      //   this.setState({
                      //     errorLogs: this.props.errorList.getErrorById(block.uuid),
                      //     currentLog: block.uuid
                      //   })
                      // }}
                    />
                  }
                </div>
              </div>
    
              <div className='functionalLayout' >
                <div className="header">
                  <div className="wrapper">
                    <Info className="icon info-icon font font-emphasis" />
                    <span className="font font-primary">Inspector</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>
                  <div className="font font-emphasis font-14px selectedBlockName">
                    {
                      (this.state.clickedBuildingPart &&
                        <div className="title">
                          <span><BlockIcon className="block-icon font font-emphasis block-icon" />{this.state.clickedBuildingPart.name}</span>
                          <span className="font font-secondary font-12px field-type">{this.state.clickedBuildingPart.typeName}</span>
                        </div>)
                    }
                  </div>
                  {/* {(this.state.clickedBuildingPart === null || this.state.clickedBuildingPart.buildingType !== "component") && <div className="selected-not-block">세대 Block 또는 코어 Block을 클릭하시면 면적 입력이 가능합니다.</div>} */}

                  <BuildingPartUI 
                type={ConverterType.myType}
                updateArea={this.recalculateResult} compoent={this.state.clickedBuildingPart} />
                {/* </div> */}
                </div>

             
              </div>
            </div>
          </div>

  {
            this.state.loadFile &&
            <div className="marker-info">
              
              <ul>
                <li className="font font-secondary title">나의 동평면</li>
                <li className="m-b-sm font font-12px layer con"
                  onMouseEnter={() => {
                    // setBlockOpacity(this.parsingOutput.fields, 0.4);
                    // setBlockOpacity(this.state.field.vacancyInside, 1);
                  }}
                  // onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" /><span className="font font-primary">CON 레이어</span>
                </li>
                <li className="m-b-sm font font-12px layer unit"
                  // onMouseEnter={() => {
                  //   setBlockOpacity(this.parsingOutput.fields, 0.4);
                  //   setBlockOpacity(this.state.field.site, 1);
                  // }}
                  // onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" /><span className="font font-primary">단위 세대</span>
                </li>
                <li className="m-b-sm font font-12px layer core"
                  // onMouseEnter={() => {
                  //   setBlockOpacity(this.parsingOutput.fields, 0.4);
                  //   setBlockOpacity(this.state.field.roadCenterLine, 1);
                  // }}
                  // onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" /><span className="font font-primary">코어</span>
                </li>
                <li className="m-b-sm font font-12px layer light-window"
                  // onMouseEnter={() => {
                  //   setBlockOpacity(this.parsingOutput.fields, 0.4);
                  //   setBlockOpacity(this.state.field.road, 1);
                  // }}
                  // onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" /><span className="font font-primary">채광창</span>
                </li>
                <li className="m-b-sm font font-12px layer normal-window"
                  // onMouseEnter={() => {
                  //   setBlockOpacity(this.parsingOutput.fields, 0.4);
                  //   setBlockOpacity(this.state.field.vacancyOutside, 1);
                  // }}
                  // onMouseLeave={() => { setBlockOpacity(this.parsingOutput.fields, 1); }}
                ><div className="line m-r-sm" /><span className="font font-primary">일반창</span>
                </li>
              </ul>
            </div>
          }

        </div>
      </ div>
    )
  }
}
