import React, { Component, Fragment } from "react";
import "./css/BuilditInput.scss";
import { ClickAwayListener } from "@material-ui/core";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import { default as _ } from "lodash";
import { SvgIcon } from "material-ui";
import ProjectIcon from "./ProjectIcon";

type SelectLabel = React.ReactText | JSX.Element;

export interface InputAttr {
  error: boolean;
  msg?: string;
  requiredCheck?: boolean;
}

export interface BuilditInputProps {
  className?: string;
  type?: "text" | "number" | "password";
  select?: boolean;
  list?: Array<{ value: React.ReactText; label: SelectLabel }>;
  value?: React.ReactText;
  defaultValue?: React.ReactText;
  startAdornment?: React.ReactNode | HTMLObjectElement;
  endAdornment?: React.ReactNode | HTMLObjectElement;
  error?: boolean;
  errorMsg?: string;
  min?: number;
  max?: number;
  length?: number;
  step?: number;
  multiline?: boolean;
  rows?: number;
  autocomplete?: boolean;
  placeholder?: SelectLabel;
  useJSXPlaceholder?: boolean;
  autoFocus?: boolean;
  align?: "left" | "right" | "center";
  id?: string;
  pattern?: string;
  title?: string;
  refName?: string;
  tabIndex?: number;
  forceBlur?: boolean;
  hoverDisable?: boolean;
  tableSelect?: boolean;
  onKeyDown?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onKeyPress?: (e: any) => void;
  onSubmit?: (e: any) => void;
  onChange: (val: React.ReactText) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
  onClickAway?: () => void;
}
export interface BuilditInputState {
  openSelect: boolean;
  focus: boolean;
}

export default class BuilditInput extends Component<BuilditInputProps, BuilditInputState> {
  state: BuilditInputState = {
    openSelect: false,
    focus: false,
  };

  componentDidUpdate = (pp: Readonly<BuilditInputProps>) => {
    if (this.props.refName) {
      if (!_.isEqual(this.props.value, pp.value)) {
        const a = this.refs[this.props.refName] as HTMLInputElement;
        this.props.onFocus && this.props.onFocus();
      }
    }

    if (this.props.forceBlur) {
      this.setState({
        focus: false,
      });
    }
  };

  render() {
    let value: SelectLabel;
    if (this.props.select) {
      if (this.props.list && this.props.list.length > 0) {
        const index = this.props.list.findIndex((e: { value: React.ReactText; label: SelectLabel }) => e.value == this.props.value);
        if (index < 0) {
          value = this.props.placeholder ? this.props.placeholder : "선택";
        } else {
          value = this.props.placeholder ? this.props.placeholder : this.props.list[index].label;
        }
      } else {
        value = "";
      }
    } else {
      if (this.props.value === undefined || this.props.value === null) {
        if (this.props.type === "number") {
          value = 0;
        } else {
          value = "";
        }
      } else {
        value = this.props.value;
      }
    }

    return (
      <ClickAwayListener onClickAway={this.onClickAway}>
        {
          <Fragment>
            <div
              className={`BuilditInput ${(this.props.select && " select") || ""} ${this.props.className || "default"} ${(this.state.openSelect && " opened") || ""} ${
                (this.props.error && " error") || ""
              } ${this.props.multiline && "textarea"} ${this.props.tableSelect && "table"}`}
            >
              <div className={`input-wrapper ${(this.props.hoverDisable && "hover-disable") || ""} ${(this.state.focus && "focus") || ""}`}>
                {/* input's start icon */}
                {this.props.startAdornment && <div className="start-adornment">{this.props.startAdornment && this.props.startAdornment}</div>}

                {/* input tag */}
                {(this.props.multiline && (
                  <textarea
                    onKeyDown={this.props.onKeyDown}
                    onKeyUp={this.props.onKeyUp}
                    onKeyPress={this.props.onKeyPress}
                    id={this.props.id}
                    rows={this.props.rows === undefined ? 5 : this.props.rows > 10 ? 10 : 5}
                    value={this.props.value !== undefined ? (value as number) : undefined}
                    defaultValue={this.props.defaultValue !== undefined ? this.props.defaultValue.toString() : undefined}
                    onChange={this.onChange}
                    placeholder={this.props.placeholder ? this.props.placeholder.toString() : undefined}
                    autoFocus={this.props.autoFocus}
                    title={this.props.title}
                    onClick={(e) => this.props.onClick && this.props.onClick()}
                    maxLength={this.props.length}
                  />
                )) ||
                  (this.props.useJSXPlaceholder && (
                    <div
                      className="custom-placeholder"
                      onClick={(e) => {
                        if (this.props.select) {
                          this.onOpenMenu(!this.state.openSelect);
                          this.props.onClick && this.props.onClick();
                        } else {
                          this.props.onClick && this.props.onClick();
                        }
                      }}
                    >
                      {(this.props.placeholder && this.props.placeholder) || value}
                    </div>
                  )) || (
                    <input
                      tabIndex={this.props.tabIndex}
                      ref={this.props.refName}
                      onKeyDown={this.props.onKeyDown}
                      onKeyUp={this.props.onKeyUp}
                      onKeyPress={this.props.onKeyPress}
                      onSubmit={this.props.onSubmit}
                      id={this.props.id}
                      className={`${(this.props.type === undefined && "text") || this.props.type} ${this.props.align || ""}`}
                      type={(this.props.type === undefined && "text") || this.props.type}
                      value={this.props.value !== undefined ? (value as number) : undefined}
                      defaultValue={this.props.defaultValue !== undefined ? this.props.defaultValue.toString() : ""}
                      onChange={this.onChange}
                      readOnly={this.props.select}
                      onClick={(e) => {
                        if (this.props.select) {
                          this.onOpenMenu(!this.state.openSelect);
                          this.props.onClick && this.props.onClick();
                        } else {
                          this.props.onClick && this.props.onClick();
                        }
                      }}
                      min={this.props.min === undefined ? 0 : this.props.min}
                      max={this.props.max}
                      maxLength={this.props.length}
                      step={this.props.step}
                      autoComplete={(this.props.autocomplete && "on") || "new-password"}
                      onFocus={(e) => {
                        this.setState({ focus: true }, () => {
                          this.props.onFocus && this.props.onFocus();
                        });
                      }}
                      onBlur={(e) => {
                        this.setState({ focus: false }, () => {
                          this.props.onBlur && this.props.onBlur();
                        });
                      }}
                      placeholder={(this.props.placeholder && this.props.placeholder.toString()) || ""}
                      autoFocus={this.props.autoFocus}
                      spellCheck={false}
                      title={this.props.title}
                    />
                  )}

                {/* select input's end icon */}
                {this.props.select && (
                  <div
                    className="select-icon"
                    onClick={(e) => {
                      this.onOpenMenu(!this.state.openSelect);
                    }}
                  >
                    {" "}
                    <DownArrowIcon className="icon" />{" "}
                  </div>
                )}

                {/* input's end icon */}

                {this.props.endAdornment !== undefined && <div className="end-adornment">{this.props.endAdornment}</div>}
              </div>
              {/* select options */}
              {this.props.select && this.props.list && this.props.list.length > 0 && this.state.openSelect && (
                <div className={`select-wrapper ${this.props.tableSelect && "select-table"}`}>
                  {this.props.list.map((option: { value: React.ReactText; label: SelectLabel | JSX.Element }) => {
                    return (
                      <div className={`option ${this.props.tableSelect && "option-table"}`} onClick={(e) => this.onSelectChange(option.value)}>
                        {option.label}
                      </div>
                    );
                  })}
                </div>
              )}
              {this.props.error && this.props.errorMsg && this.props.errorMsg.length > 0 && <div className="buildit-input-error">{this.props.errorMsg}</div>}
            </div>
          </Fragment>
        }
      </ClickAwayListener>
    );
  }

  onSelectChange = (value: React.ReactText) => {
    this.props.onChange(value);
    this.onClickAway();
  };
  onChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    let value;
    if (this.props.type === "number") {
      this.props.onChange(Number(e.target.value));
    } else {
      if (this.props.pattern) {
        const reg = new RegExp(this.props.pattern);
        if (Boolean(e.target.value.match(reg))) {
          this.props.onChange(e.target.value);
        }
      } else {
        this.props.onChange(e.target.value);
      }
    }
  };
  onClickAway = () => {
    this.onOpenMenu(false);
    if (this.props.onClickAway) {
      this.props.onClickAway();
    }
  };
  onOpenMenu = (open: boolean) => {
    this.setState({ openSelect: open });
  };
}
