import * as THREE from '@teneleven/three';
import { LineType, PolylineInfo, wallMaterial, windowMaterial, coreMaterial, Polygon, makeHouseState, ConverterLayer } from './DataTypes';
import { PolygonSimplification, checkPointOnLine } from './PolygonManager';
import { LineGeometry } from '@teneleven/three/examples/jsm/lines/LineGeometry';
import { LineMaterial } from '@teneleven/three/examples/jsm/lines/LineMaterial';
import { Line2 } from '@teneleven/three/examples/jsm/lines/Line2';
import { House } from './House';
import { ConverterBlock } from './ConverterBlock';
import { BuildingComponent, ConverterField, FieldPart } from './BuildingPart';

// export function makeMeshWithLine(line: PolylineInfo): THREE.Mesh | THREE.Group {
//   let lineStart = new THREE.Vector3(-line.line.start.x, 0, line.line.start.y);
//   let lineEnd = new THREE.Vector3(-line.line.end.x, 0, line.line.end.y);
//   let wallHeight = 2.8;

//   let heightVector = new THREE.Vector3(0, wallHeight, 0);
//   let lineTopStart = lineStart.clone().add(heightVector);
//   let lineTopEnd = lineEnd.clone().add(heightVector);

//   if (line.type === LineType.LT_OUTERWINDOW || line.type === LineType.LT_LIGHTWINDOW) {
//     // line.line.distance();
//     let windowWidth = 2.4;
//     // if (line.line.distance() < 2.4) {
//     //   windowWidth = line.line.distance();
//     // }
//     if (line.type === LineType.LT_OUTERWINDOW) {
//       windowWidth = 1.2;
//     }

//     let start = (wallHeight - windowWidth) / 2;

//     let windowBottomStart = new THREE.Vector3(-line.line.start.x, start, line.line.start.y);
//     let windowTopStart = new THREE.Vector3(-line.line.start.x, start + windowWidth, line.line.start.y);

//     let windowBottomEnd = new THREE.Vector3(-line.line.end.x, start, line.line.end.y);
//     let windowTopEnd = new THREE.Vector3(-line.line.end.x, start + windowWidth, line.line.end.y);

//     let wallBottomGeo = new THREE.Geometry();
//     wallBottomGeo.vertices = [lineStart, lineEnd, windowBottomStart, windowBottomEnd];
//     wallBottomGeo.faces.push(new THREE.Face3(0, 1, 2));
//     wallBottomGeo.faces.push(new THREE.Face3(1, 3, 2));
//     wallBottomGeo.faces.push(new THREE.Face3(0, 2, 1));
//     wallBottomGeo.faces.push(new THREE.Face3(1, 2, 3));
//     wallBottomGeo.computeFaceNormals();

//     let windowGeo = new THREE.Geometry();
//     windowGeo.vertices = [windowBottomStart, windowBottomEnd, windowTopStart, windowTopEnd];
//     windowGeo.faces.push(new THREE.Face3(0, 1, 2));
//     windowGeo.faces.push(new THREE.Face3(1, 3, 2));
//     windowGeo.faces.push(new THREE.Face3(0, 2, 1));
//     windowGeo.faces.push(new THREE.Face3(1, 2, 3));

//     windowGeo.computeFaceNormals();

//     let wallTopGeo = new THREE.Geometry();
//     wallTopGeo.vertices = [windowTopStart, windowTopEnd, lineTopStart, lineTopEnd];
//     wallTopGeo.faces.push(new THREE.Face3(0, 1, 2));
//     wallTopGeo.faces.push(new THREE.Face3(1, 3, 2));
//     wallTopGeo.faces.push(new THREE.Face3(0, 2, 1));
//     wallTopGeo.faces.push(new THREE.Face3(1, 2, 3));
//     wallTopGeo.computeFaceNormals();

//     let windowGroup = new THREE.Group();
//     windowGroup.add(new THREE.Mesh(wallBottomGeo, wallMaterial));
//     windowGroup.add(new THREE.Mesh(windowGeo, windowMaterial));
//     windowGroup.add(new THREE.Mesh(wallTopGeo, wallMaterial));
//     return windowGroup;
//   }
//   else {
//     let geometry = new THREE.Geometry();
//     geometry.vertices = [lineStart, lineEnd, lineTopStart, lineTopEnd];
//     geometry.faces.push(new THREE.Face3(0, 1, 2));
//     geometry.faces.push(new THREE.Face3(1, 3, 2));
//     geometry.faces.push(new THREE.Face3(0, 2, 1));
//     geometry.faces.push(new THREE.Face3(1, 2, 3));
//     geometry.computeFaceNormals();

//     if (line.type === LineType.LT_COREOUTERWALL)
//       return new THREE.Mesh(geometry, coreMaterial);
//     else
//       return new THREE.Mesh(geometry, wallMaterial);
//   }
// }

export async function remakeHousePolygons(house: House, offset: number) {
  if (!house.wall || (!house.lightWindow && !house.normalWindow))
    return makeHouseState.Error;
  let lines = new Array<PolylineInfo>();
  house.wall.polygons.forEach(p => {
    let verts = PolygonSimplification(p.vertices);

    for (let i = 0; i < verts.length - 1; i++) {
      lines.push({
        line: new THREE.Line3(verts[i], verts[i + 1]),
        thickness: 0.6,
        type: LineType.LT_OUTERWALL,
      })
    }
  })

  let windowPolygons: { polygon: Polygon, lineType: LineType, maked: boolean }[] = [];
  if (house.lightWindow) {
    house.lightWindow.polygons.forEach(p => {
      windowPolygons.push({
        polygon: p,
        lineType: LineType.LT_LIGHTWINDOW,
        maked: false,
      })
    })
  }

  if (house.normalWindow) {
    house.normalWindow.polygons.forEach(p => {
      windowPolygons.push({
        polygon: p,
        lineType: LineType.LT_OUTERWINDOW,
        maked: false,
      })
    })
  }

  // for (let i = 0; i < windowPolygons.length; i++) {
  //   let v1 = windowPolygons[i].polygon.vertices[0];
  //   let v2 = windowPolygons[i].polygon.vertices[1];
  //   let l1 = new THREE.Line3(v1, v2);

  //   for (let j = i + 1; j < windowPolygons.length; j++) {
  //     if (checkPointOnLine(windowPolygons[j].polygon.vertices[0], l1) || checkPointOnLine(windowPolygons[j].polygon.vertices[1], l1)) {
  //       App.stage !== "prod" && console.log(house.wall.name);
  //     }
  //   }
  // }

  let makeState: makeHouseState = makeHouseState.Finish;
  windowPolygons.forEach(wp => {
    // let wp = windowPolygons[0];
    let v1, v2;
    for (let wpi = 0; wpi < wp.polygon.vertices.length - 1; wpi++) {
      v1 = wp.polygon.vertices[wpi];
      v2 = wp.polygon.vertices[wpi + 1];
      let maked = false;
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].type === LineType.LT_OUTERWALL && v1.distanceTo(v2) > 0.01) {
          // if (wp.maked) {
          //   return;
          // }

          let p1 = new THREE.Vector3();
          let p2 = new THREE.Vector3();

          lines[i].line.closestPointToPoint(v1, true, p1);
          lines[i].line.closestPointToPoint(v2, true, p2);

          if ((v1.distanceTo(p1) < offset && checkPointOnLine(p1, lines[i].line)) && (v2.distanceTo(p2) < offset && checkPointOnLine(p2, lines[i].line))) {
            let l1: PolylineInfo, l2: PolylineInfo, l3: PolylineInfo;
            if (checkPointOnLine(p2, new THREE.Line3(p1, lines[i].line.end))) {
              l1 = { line: new THREE.Line3(lines[i].line.start, p1), thickness: 0.6, type: LineType.LT_OUTERWALL };
              l2 = { line: new THREE.Line3(p1, p2), thickness: 0.6, type: wp.lineType };
              l3 = { line: new THREE.Line3(p2, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
            }
            else {
              l1 = { line: new THREE.Line3(lines[i].line.start, p2), thickness: 0.6, type: LineType.LT_OUTERWALL };
              l2 = { line: new THREE.Line3(p2, p1), thickness: 0.6, type: wp.lineType };
              l3 = { line: new THREE.Line3(p1, lines[i].line.end), thickness: 0.6, type: LineType.LT_OUTERWALL };
            }
            lines.splice(i, 1, l1, l2, l3);
            i += 2;
            maked = true;
          }
        }
      }
      wp.maked = maked;
    }
  });

  windowPolygons.forEach(wp => {
    if (!wp.maked) {
      if (wp.lineType === LineType.LT_LIGHTWINDOW) {
        makeState = makeState === makeHouseState.outerWindowError ? makeHouseState.allWindowError : makeHouseState.lightWindowError;
      }
      else if (wp.lineType === LineType.LT_OUTERWINDOW) {
        makeState = makeState === makeHouseState.lightWindowError ? makeHouseState.allWindowError : makeHouseState.outerWindowError;
      }
    }
  })

  for (let i = 0; i < lines.length;) {
    if (lines[i].line.start.distanceTo(lines[i].line.end) === 0) {
      lines.splice(i, 1);
    }
    else {
      i++;
    }
  }

  // App.stage !== "prod" && console.log('make house', makeState);
  house.outputPolygon = lines;
  house.makeState = makeState;
  return makeState;
}

export function getCurveErrorCircle(layer: ConverterLayer) {
  let circleGroup = new THREE.Group();

  layer.polygons.forEach(p => {
    if (p.hasCurve) {
      if (p.vertices.length > 2)
        for (let i = 0; i < p.vertices.length; i++) {
          if (p.vertices[i].z != 0) {
            let start = p.vertices[i];
            let end = p.vertices[i + 1];

            if (p.vertices[i].z < 0)
              end = p.vertices[i - 1];

            if (!end) {
              end = p.vertices[0];
            }

            circleGroup.add(getCircle(start, end, new THREE.Color(1, 0, 0), 5))
          }
        }
      else if (p.vertices.length === 2) {
        circleGroup.add(getCircle(p.vertices[0], p.vertices[1], new THREE.Color(1, 0, 0), 5));
      }
    }
  })
  return circleGroup;
}
export function getCurveErrorCircleBlock(block: BuildingComponent) {
  let circleGroup = new THREE.Group();

  block.polygon.forEach(p => {
    if (p.hasCurve) {
      if (p.vertices.length > 2)
        for (let i = 0; i < p.vertices.length; i++) {
          if (p.vertices[i].z != 0) {
            let start = p.vertices[i];
            let end = p.vertices[i + 1];

            if (p.vertices[i].z < 0)
              end = p.vertices[i - 1];

            if (!end) {
              end = p.vertices[0];
            }

            circleGroup.add(getCircle(start, end, new THREE.Color(1, 0, 0), 5))
          }
        }
      else if (p.vertices.length === 2) {
        circleGroup.add(getCircle(p.vertices[0], p.vertices[1], new THREE.Color(1, 0, 0), 5));
      }
    }
  })
  block.renderGroup.add(circleGroup);

  return circleGroup;
}

export function getCurveErrorCircleField(part: FieldPart) {
  let circleGroup = new THREE.Group();

  let p = part.polygon;
  part.renderGroup.updateWorldMatrix(true, true);
  let matrixWorld = part.renderGroup.matrixWorld;
    if (p.hasCurve) {
      if (p.vertices.length > 2)
        for (let i = 0; i < p.vertices.length; i++) {
          if (p.vertices[i].z != 0) {
            let start = new THREE.Vector3(p.vertices[i].x, p.vertices[i].y, 0).applyMatrix4(matrixWorld);
            let end = new THREE.Vector3(p.vertices[i + 1].x, p.vertices[i+1].y, 0).applyMatrix4(matrixWorld);
            if (p.vertices[i].z < 0)
              end = new THREE.Vector3(p.vertices[i - 1].x, p.vertices[i+1].y, 0).applyMatrix4(matrixWorld);
            if (!end) {
              end = new THREE.Vector3(p.vertices[0].x, p.vertices[0].y, 0).applyMatrix4(matrixWorld);
            }
            circleGroup.add(getCircle(start, end, new THREE.Color(1, 0, 0), 5))
          }
        }
      else if (p.vertices.length === 2) {
        circleGroup.add(getCircle(p.vertices[0], p.vertices[1], new THREE.Color(1, 0, 0), 5));
      }
    }
  circleGroup.renderOrder = 2;
  return circleGroup;
}

export function getWindowErrorCircle(layer: ConverterLayer) {
  let circleGroup = new THREE.Group();

  layer.polygons.forEach(p => {
    for (let i = 0; i < p.vertices.length - 1; i++) {
      circleGroup.add(getCircle(p.vertices[i], p.vertices[i + 1], new THREE.Color(1, 0, 0), 5));
    }
  })

  return circleGroup;
}

export function getLineErrorCircleBlock(renderGroup: THREE.Group, polygon: Polygon, ErrorPolygonGroup: THREE.Group) {
  renderGroup.updateWorldMatrix(true, true);
  let matrixWorld = renderGroup.matrixWorld;
  let worldVerts: THREE.Vector3[] = [];

  polygon.vertices.forEach((v: any) => {
    worldVerts.push(v.clone().applyMatrix4(matrixWorld));
  });
  let group = new THREE.Group();
  let circleMesh = getCircle(worldVerts[0], worldVerts[1], new THREE.Color(1, 0, 0), 5);
  group.add(circleMesh);
  ErrorPolygonGroup.add(group);
  return group;
//  return circleGroup;
}

export function getErrorLine(start: THREE.Vector3, end: THREE.Vector3) {  
  let group = new THREE.Group();
  let geometry = new LineGeometry();
  geometry.setPositions([start.x, start.y, 0, end.x, end.y, 0]);
  geometry.setColors([1, 0, 0, 1, 0, 0]);
  let matLine = new LineMaterial({
    linewidth: 5, //8, // in pixels
    vertexColors: true,
    dashed: true,
    dashSize: 1,
    gapSize: 1,
    dashScale: 2,
  });
  matLine.resolution.set(window.innerWidth, window.innerHeight);
  matLine.transparent = true;
  let mesh = new Line2(geometry, matLine).computeLineDistances();
  
  mesh.visible = false;
  group.add(mesh);
  return group;
}

export function makePolygonApartLine(polygon1: jsts.geom.Geometry, polygon2: jsts.geom.Geometry) {
  let groundCentroid = polygon1.getCentroid();
  let groundCenter = new THREE.Vector3(groundCentroid.getX(), groundCentroid.getY());
  let siteCentroid = polygon2.getCentroid();
  let siteCenter = new THREE.Vector3(siteCentroid.getX(), siteCentroid.getY());

  let group = new THREE.Group();
  let line = getErrorLine(groundCenter, siteCenter);
  let circle1 = getFilledCircle(groundCenter);
  let circle2 = getFilledCircle(siteCenter);

  group.add(line);
  group.add(circle1);
  group.add(circle2);
  return group;
}

export function getFilledCircle(center: THREE.Vector3, color: THREE.Color | string | number = "#ec0014") { // 빨간 동그라미 점
  let geometry = new THREE.CircleGeometry( 0.2, 32 );
  let material = new THREE.MeshBasicMaterial( { color: color } );
  let circle = new THREE.Mesh( geometry, material );
  circle.position.set(center.x, center.y, center.z);
  circle.visible = false;
  return circle;
}
export function getCircle(start: THREE.Vector3, end: THREE.Vector3, color: THREE.Color, lineWidth: number, diameterParam?: number) {
  let vertsGeo = new Array<number>();
  let colorGeo = new Array<number>();
  
  let diameter = new THREE.Vector2(start.x, start.y).distanceTo(new THREE.Vector2(end.x, end.y));

  if (diameter === 0 && diameterParam) {
    diameter = diameterParam;
  }
  let radius = diameter / 2 * 1.1;
  let center = new THREE.Vector2(start.x, start.y).add(new THREE.Vector2(end.x, end.y)).divideScalar(2);

  radius = Math.max(radius, 0.5);

  for (let i = 0; i < 31; i++) {
    vertsGeo.push(Math.sin(i * Math.PI / 15) * radius + center.x, Math.cos(i * Math.PI / 15) * radius + center.y, 0);
    colorGeo.push(color.r, color.g, color.b);
  }

  let geometry = new LineGeometry();
  geometry.setPositions(vertsGeo);
  geometry.setColors(colorGeo);
  let matLine = new LineMaterial({
    linewidth: lineWidth, //8, // in pixels
    vertexColors: true,
    dashed: true,
    dashSize: 1,
    gapSize: 1,
    dashScale: 2,
  });

  matLine.resolution.set(window.innerWidth, window.innerHeight);
  matLine.transparent = true;
  let mesh = new Line2(geometry, matLine).computeLineDistances();

  mesh.visible = false;
  mesh.renderOrder = 1;
  return mesh;
}
