import React, { Component, Fragment } from 'react';
import './css/ProjectImport.scss';

import BackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/HelpOutline';
import Tabs from './Tabs';
import { Button, IconButton, InputAdornment } from '@material-ui/core';
import AWSModule from './AWSModule';
import { ProjectFormPropsBase } from './ProjectInput';
import { Map } from './Shape';
import { Polygon } from '@teneleven/protocols-ts-web/lib/public_api_pb';
import DrawingManager2 from './DrawingManager2';
import Modal, { ModalOptions } from './Modal';

import AddIcon from '@material-ui/icons/AddCircle';
import SubIcon from '@material-ui/icons/RemoveCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import RefreshIcon from '@material-ui/icons/Refresh';

import * as turf from '@turf/turf';
import { Project } from './model/Project';
import App from './App';
import S3Image from './S3Image';
import { Link } from 'react-router-dom';
import * as URI from "uri-js";

import wkx, { Geometry } from 'wkx';
// @ts-ignore
import { reproject } from 'reproject';

import ArrowForward from '@material-ui/icons/ArrowForward';
import BuilditInput from './BuilditInput';

import jquery from 'jquery';

import { default as _ } from 'lodash';
import Tooltip from './Tooltip';
const jsts = require('jsts');
const $ = jquery;


export type ProjectImportType = "MY_SITE" | "MY_PLAN";

export interface ProjectImportProps extends ProjectFormPropsBase {
  map: Map | undefined;
  importType: ProjectImportType;
  setImportType: (type: ProjectImportType) => void;
  setOpenImport: (open: boolean) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  onResetProject: (fixField?: Partial<Project>, callback?: Function) => void;
  openImport: boolean;
}

export interface ProjectImportState {
  mySiteList: Array<any>;
  myPlanList: Array<any>;
  searchText: string;
  selectedMySiteId?: number,
  selectedMyPlanId?: number,
  selectedMySiteItem?: any;
  selectedMyPlanItem?: any;
  unselectHover: boolean;
}

export default class ProjectImport extends Component<ProjectImportProps, ProjectImportState> {
  state: ProjectImportState = {
    mySiteList: [],
    myPlanList: [],
    searchText: "",
    unselectHover: false,
  }

  componentWillMount = async () => {

    this.searchMyTypes = _.debounce(this.searchMyTypes, 200);
    let query = {
      query_string: {
        query: ""
      }
    };

    let table = "";
    let type;
    
    if (this.props.currentProject.selected_my_building_plan_id) {
      type = "MY_PLAN";
      table = App.DDBTable.MyBuildingPlan;
      query.query_string.query = `deleted:false AND global_id:${this.props.currentProject.selected_my_building_plan_id}`;
    } else if (this.props.currentProject.selected_my_site_id) {
      type = "MY_SITE";
      table = App.DDBTable.MyBuildingSite;
      query.query_string.query = `deleted:false AND global_id:${this.props.currentProject.selected_my_site_id}`
    }


    if (table !== "") {
      const r = await App.search({
        table: table,
        query: query.query_string.query,
      });
  
      const rr = r.data;
  
      if (rr.hits && rr.hits.hits) {
        switch (type) {
          case "MY_PLAN": this.setState({ selectedMyPlanItem: rr.hits.hits[0]._source}); break;
          case "MY_SITE": this.setState({ selectedMySiteItem: rr.hits.hits[0]._source}); break;
        }
      }
    }

  }

  componentDidMount = async () => {
    this.searchMyTypes();
    // this.getMyDataList(this.props.importType);
  }

  componentDidUpdate = (pp: Readonly<ProjectImportProps>, ps: Readonly<ProjectImportState>) => {
    if ((ps.searchText !== this.state.searchText) || (this.props.importType !== pp.importType)) {
      this.searchMyTypes();
    }
  }

  

  searchMyTypes = () => {
    this.setState({ searchText: this.state.searchText.trim() }, async () => {
      const r = await App.search({
        table: this.props.importType === "MY_PLAN" && App.DDBTable.MyBuildingPlan || App.DDBTable.MyBuildingSite,
            query: `deleted:false AND 
            (name:*${this.state.searchText}* 
            ${this.state.searchText.length > 0 && `OR user_id:${this.state.searchText}` || "" } 
            ${this.state.searchText.length > 0 && `OR address:*${this.state.searchText}*` || "" })`,
        from: 0,
        size: 10000,
        sort: [{ 'created_at': "desc" }]
      });
  
      const rr = r.data;
      
      if (rr.hits && rr.hits.hits) {
        switch(this.props.importType) {
          case "MY_PLAN": this.setState({ myPlanList:  rr.hits.hits.map((r: any) => r._source) }); break;
          case "MY_SITE": this.setState({ mySiteList:  rr.hits.hits.map((r: any) => r._source) }); break;
        }
      }
    })
  }

  render () {
    return (
      <div className="ProjectImport">
        <div className="header">
          <div className="title bg-navy font font-primary font-18px">
            사업영역 불러오기
            <Tooltip id="ProjectImport-MySite"
              className="m-l-sm"
              msg={
                <Fragment>
                  '마이페이지 > 나의 파일'에서<br />
                  업로드 후 사용 가능합니다
                </Fragment>
              }
            >
              <HelpIcon className="icon" />
            </Tooltip>
            <IconButton onClick={e => this.props.setOpenImport(false)} disableRipple={true} className="m-l-a close-btn"><CloseIcon className="icon" /></IconButton>
          </div>
          <div className="tab-wrap">
            <Tabs className="tabs bg-navy">
              <Button className={`tab bg-navy tab-primary ${this.props.importType === "MY_SITE" && "active"}`}
              onClick={e => this.getMyDataList('MY_SITE')} disableRipple={true} 
              >나의 사업영역</Button>
              {
                ["AI", "UNDEFINED", "CAL"].includes(this.props.currentProject.project_type!) &&
                // <Tooltip msg="DESIGNER 타입만 설정 가능합니다" className="tab">
                  <Button className={`tab bg-navy tab-primary ${this.props.importType === "MY_PLAN" && "active"} 
                  ${this.props.currentProject.project_type}
                }`}
                    onClick={e => this.getMyDataList('MY_PLAN')} disableRipple={true} 
                    disabled={["AI", "UNDEFINED", "CAL"].includes(this.props.currentProject.project_type!)}
                  >나의 배치안</Button>
                // </Tooltip>
                ||
                <Button className={`tab bg-navy tab-primary ${this.props.importType === "MY_PLAN" && "active"}`}
                  onClick={e => this.getMyDataList('MY_PLAN')} disableRipple={true} 
                  disabled={["AI", "UNDEFINED", "CAL"].includes(this.props.currentProject.project_type!)}
                >나의 배치안</Button>
              }
            </Tabs>
            <div className={`import-description   
            ${(this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN") ? 
    "DESIGNER" : "AI"}`}>
              나의 배치안 불러오기는 <span>BUILDIT DESIGNER project</span>에서 가능합니다.
            </div>
            <div className="button-wrapper">
              <Button className="upload-btn btn bg-navy btn-primary" disableRipple={true} onClick={e => window.open(this.props.importType === "MY_SITE" ? "/cad/MySite" : "/cad/MyBuildingPlan", "_blank")}>
                업로드 바로가기
                <ArrowForward className="icon"/>
              </Button>
              <Button className="refresh-btn btn bg-navy btn-primary" disableRipple={true} onClick={e => this.searchMyTypes()}>
                <RefreshIcon className="icon" />
              </Button>
            </div>
            
            <div className="search-field">
              <BuilditInput
                align="left"
                placeholder="검색"
                value={this.state.searchText}
                onChange={(v: React.ReactText) => this.setState({ searchText: v as string })}
                endAdornment={<InputAdornment position="end">
                  {
                    this.state.searchText.length > 0 &&
                    <IconButton className="icon-btn" disableRipple={true} onClick={e => { this.setState({ searchText: "" }) }}>
                      <CloseIcon className="icon remove-icon" />
                    </IconButton>
                  }
                  <IconButton className="icon-btn" disableRipple={true} onClick={e => { }}>
                    <SearchIcon className="icon" />
                  </IconButton>
                </InputAdornment>}
              />
            </div>
          </div>
        </div>
        <div className="content">
        {
          this.props.importType === "MY_SITE" &&
          this.state.selectedMySiteItem !== undefined &&
          this.state.mySiteList.findIndex(e => e.global_id === this.state.selectedMySiteItem.global_id) > -1 &&
          <ProjectImportItem key={`site-select`} {...this.props}
            item={this.state.selectedMySiteItem} 
            type="MY_SITE"
            className="item"
          /> 
        }
        {
          (this.props.importType === "MY_SITE" && this.state.mySiteList.length > 0) &&
          this.state.mySiteList.map((l, i) => {
            if (this.state.selectedMySiteItem !== undefined) {
              if (l.global_id !== this.state.selectedMySiteItem.global_id) {
                return  <ProjectImportItem key={`site-${i}`} {...this.props} item={l} type="MY_SITE" className="item"
                  onHover={() => { this.setState({ unselectHover: true })}}
                  onBlur={() => { this.setState({ unselectHover: false })}}
                 />
              }
            } else {
              return  <ProjectImportItem key={`site-${i}`} {...this.props} item={l} type="MY_SITE" className="item" />
            }
          })
          ||
          (
            this.props.importType === "MY_SITE" &&
            <ProjectImportItem {...this.props} item={null} type="MY_SITE" isEmpty={true} className="item" />
          )
        }
        {
          this.props.importType === "MY_SITE" && this.state.selectedMySiteItem && this.state.unselectHover &&
          <ProjectImportItem key={`site-compare`} {...this.props}
            item={this.state.selectedMySiteItem} 
            type="MY_SITE"  className="item compare"
            compare={true}
          /> 
        }


        {/* 나의 동평면 아이템들  */}

        {/* 선택된 나의 동평면  */}
        {
          ( this.props.importType === "MY_PLAN" &&
            this.state.selectedMyPlanItem !== undefined &&
            this.state.myPlanList.findIndex(e => e.global_id === this.state.selectedMyPlanItem.global_id) > -1
          ) &&
          <ProjectImportItem key={`plan-select`} {...this.props}
            item={this.state.selectedMyPlanItem} 
            type="MY_PLAN"  className="item"
          /> 
        }
        {
          (this.props.importType === "MY_PLAN" && this.state.myPlanList.length > 0) &&
          this.state.myPlanList.map((l, i) => {
            if (this.state.selectedMyPlanItem !== undefined) {
              if (l.global_id !== this.state.selectedMyPlanItem.global_id) {
                return  (
                  <ProjectImportItem key={`plan-${i}`} {...this.props} item={l} type="MY_PLAN" className="item" 
                    onHover={() => { this.setState({ unselectHover: true })}}
                    onBlur={() => { this.setState({ unselectHover: false })}}
                  />
                )
              }
            } else {
              return  ( <ProjectImportItem key={`plan-${i}`} {...this.props} item={l} type="MY_PLAN" className="item" /> )
            }
          })
          ||
          (
            this.props.importType === "MY_PLAN" &&
            <ProjectImportItem {...this.props} item={null} type="MY_PLAN" isEmpty={true} className="item" />
          )
        }
        {/* 선택된 나의 동평면 비교용 */}
        {
          this.props.importType === "MY_PLAN" && this.state.selectedMyPlanItem && this.state.unselectHover &&
          <ProjectImportItem key={`plan-compare`} {...this.props}
            item={this.state.selectedMyPlanItem} 
            type="MY_PLAN"  className="item compare"
            compare={true}
          /> 
        }

        </div>  
      </div>
    );
  }

  getMyDataList = async (type: ProjectImportType) => {
    const r = await App.search({
      table: type === "MY_SITE" && App.DDBTable.MyBuildingSite || App.DDBTable.MyBuildingPlan,
      query: {
        query_string: {
          query: `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`,
        }
      },
      from: 0, size: 10000,
      sort: [ {created_at: 'desc'} ]
    });

    const rr = r.data;
    if (rr.hits.hits) {
      switch (type) {
        case 'MY_SITE': this.setState({ mySiteList: rr.hits.hits.map((r: any) => r._source) }, () => this.props.setImportType(type))
        case 'MY_PLAN': this.setState({ myPlanList: rr.hits.hits.map((r: any) => r._source) }, () => this.props.setImportType(type))
      }
    } else {
      // 에러
    }
  }
}

export interface ProjectImportItemProps extends ProjectFormPropsBase {
  map: Map | undefined;
  item: any;
  type: ProjectImportType;
  isEmpty?: boolean;
  className?: string;
  compare?: boolean;
  onHover?: () => void;
  onBlur?: () => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  onResetProject: (fixField?: Partial<Project>, callback?: Function) => void;
  setOpenImport: (open: boolean) => void;
}
export interface ProjectImportItemState {
  selected: boolean;
}
export class ProjectImportItem extends Component<ProjectImportItemProps, ProjectImportItemState> {
  state: ProjectImportItemState = {
    selected: false
  }
  dm?: DrawingManager2;

  componentWillMount = () => {
    if (!this.props.isEmpty) {
      if (this.props.type === "MY_PLAN") {
        if (this.props.item.global_id === this.props.currentProject.selected_my_building_plan_id) {
          this.setState({ selected: true })
        }
      } else {
        if (this.props.item.global_id === this.props.currentProject.selected_my_site_id) {
          this.setState({ selected: true })
        }
      }
    }
  }

  render() {
    return (
      (this.props.isEmpty === false || this.props.isEmpty === undefined) &&
      <div className={`ProjectImportItem ${this.state.selected && "selected"} ${this.props.className && this.props.className || ""}`}
        onClick={e => this.state.selected === false && this.setProject()}
        onMouseEnter={() => { 
          if (this.props.onHover !== undefined) {
            this.props.onHover && this.props.onHover()
          }
        }}
        onMouseLeave={() => {
          if (this.props.onBlur !== undefined) {
            this.props.onBlur && this.props.onBlur()
          }
        }}
      >
        <div className="header">
        {
          this.props.item.user_id
        }
        {
          this.props.compare === true &&
          <div className="m-l-a checked">
            <CheckIcon className="check-icon icon" />
            선택됨
          </div>
        }
        </div>
        <div className="img-wrap">
          <S3Image s3={{
            Bucket: this.props.type === "MY_SITE" && App.S3Bucket.MyBuildingSite || App.S3Bucket.MyBuildingPlan,
            Key: (URI.parse(this.props.item.img_path, { absolutePath: true }).path as string).substring(1),
          }}/>
          <div className="active">
            {
              this.props.compare === undefined && 
              ( this.state.selected &&
              <CheckCircleIcon className="check-icon" /> ||
              <AddIcon className="add-icon" /> )
              || <Fragment></Fragment>
            }
          </div>
        </div>
        <div className="info">
          <div className="name" title={this.props.item.name}>{this.props.item.name}</div>
          <div className="key-value">
            <div className="key">사업영역 주소</div>
            <div className="value">{this.props.item.address}</div>
          </div>
          <div className="key-value">
            <div className="key">대지영역 면적</div>
            <div className="value">{Number(this.props.item.project_site_area.toFixed(2)).toLocaleString()}㎡</div>
          </div>
          <div className="key-value">
            <div className="key">특수영역 면적</div>
            <div className="value">{Number(this.props.item.vacancy_outside_area.toFixed(2)).toLocaleString()}㎡</div>
          </div>
          {
            this.props.type === "MY_PLAN" &&
            <div className="key-value">
              <div className="key">용적률</div>
              <div className="value">
                {
                  (this.props.item.total_floor_area !== undefined && this.props.item.project_site_area !== undefined) &&
                  Number((this.props.item.total_floor_area / this.props.item.project_site_area * 100).toFixed(2)).toLocaleString() 
                  || 
                  "-"
                }
                %
              </div>
            </div>
          }
        </div>
      </div> 
      ||
      <div className={`ProjectImportItem empty ${this.props.className && this.props.className || ""}`}>
        나의 {this.props.type === "MY_PLAN" && "배치안" || "사업영역"}이 없습니다
      </div>
    )
  }

  setProject = async () => {
    const aws = await new AWSModule("DDB").connect();
    const tableName = this.props.type === "MY_SITE" && App.DDBTable.MyBuildingSite || App.DDBTable.MyBuildingPlan;
    let item: any = undefined;
    const r = await aws.Ddb!.get({
      TableName: tableName,
      Key: {
        global_id: this.props.item.global_id,
        stage: App.tempStage //App.stage
      },
    }).promise();  

    if (r.$response.error) {
      console.log(r.$response.error);
      alert(r.$response.error);
      return;
    } else {
      item = r.Item;
      App.stage !== 'prod' && console.log('item', item)
    }

    if (item !== undefined) {
      if (item.project_site !== undefined && item.project_site.length > 0) {
        try {
          item.project_site.map((r: string) => {
            const reader = new jsts.io.WKTReader();
            const poly = reader.read(r);
          })
        } catch (e) {
          let msg = "입력된 폴리곤 데이터가 유효하지 않습니다.\nCAD 데이터의 확인이 필요합니다.";
          alert(msg);
          return;
        }
      } else if (item.project_site.length > 1) {
        alert('1개의 대지영역만 적용 가능합니다.\nCAD 데이터의 확인이 필요합니다');
        return;
      } else {
        alert('파일에 대지영역이 존재하지 않습니다.\nCAD 데이터의 확인이 필요합니다');
        return;
      }
    }

    if (this.props.currentProject.project_site!.length > 0 ||
      this.props.currentProject.vacancy_inside!.length > 0 ||
      this.props.currentProject.vacancy_outside!.length > 0 ||
      this.props.currentProject.skyline_circle!.length > 0 ||
      this.props.currentProject.skyline_line!.length > 0 ) {
        this.props.setModal(true, {
          title: "설정 안내",
          type: "SIMPLE",
          negative: () => {this.props.setModal(false)},
          positive: () => {
            let center: [number, number] = item.project_site.map((s: string) => turf.center(DrawingManager2.toGeom(s, "Polygon")).geometry!.coordinates)
            .reduce((a: [number, number], b: [number, number]) => [a[0] + b[0], a[1] + b[1]]);
            const len = item.project_site.length;
            center = [center[0] / len, center[1] / len];
            const centerWKT = wkx.Geometry.parseGeoJSON(reproject({
              type: 'Point',
              coordinates: center,
            }, DrawingManager2.nProj, DrawingManager2.bProj)).toWkt();


            this.props.onResetProject({
                project_site: item.project_site,
                vacancy_inside: item.vacancy_inside,
                vacancy_outside: item.vacancy_outside,
                road_site: item.road_site,
                project_site_area: item.project_site_area,
                vacancy_inside_area: item.vacancy_inside_area,
                vacancy_outside_area: item.vacancy_outside_area,
                project_site_type: "IMPORT",
                project_type: this.props.type === "MY_PLAN" && "SITE_PLAN" || this.props.currentProject.project_type,
                boundary_site: item.centerOfRoad,
                // project_type: "UNDEFINED",
                selected_my_building_plan_id: this.props.type === "MY_PLAN" && this.props.item.global_id || undefined,
                selected_my_site_id: this.props.type === "MY_SITE" && this.props.item.global_id || undefined,
                project_site_center: centerWKT,
                topography: item.topography_lines
              }, () => {
                if (this.props.map) {
                  this.props.map.setCenter(center);
                  this.props.map.setZoom(DrawingManager2.getZoomLevel(item.project_site_area));
                }
                this.props.setOpenImport(false);
                this.props.setModal(false);
            })
          },
          content: <div>
            적용시, 이미 설정된 내용이 있다면<br />
            설정이 초기화 됩니다.<br /><br />
            변경 하시겠습니까?</div>
        }) 
    } else {
      let center: [number, number] = item.project_site.map((s: string) => turf.center(DrawingManager2.toGeom(s, "Polygon")).geometry!.coordinates)
      .reduce((a: [number, number], b: [number, number]) => [a[0] + b[0], a[1] + b[1]]);
      const len = item.project_site.length;
      center = [center[0] / len, center[1] / len];
      const centerWKT = wkx.Geometry.parseGeoJSON(reproject({
        type: 'Point',
        coordinates: center,
      }, DrawingManager2.nProj, DrawingManager2.bProj)).toWkt()

      this.props.onResetProject({
        project_site: item.project_site,
        vacancy_inside: item.vacancy_inside,
        vacancy_outside: item.vacancy_outside,
        road_site: item.road_site,
        project_site_area: item.project_site_area,
        vacancy_inside_area: item.vacancy_inside_area,
        vacancy_outside_area: item.vacancy_outside_area,
        project_site_type: "IMPORT",
        project_type: this.props.type === "MY_PLAN" && "SITE_PLAN" || this.props.currentProject.project_type,
        boundary_site: item.centerOfRoad,
        // project_type: "UNDEFINED",
        selected_my_building_plan_id: this.props.type === "MY_PLAN" && this.props.item.global_id || undefined,
        selected_my_site_id: this.props.type === "MY_SITE" && this.props.item.global_id || undefined,
        project_site_center: centerWKT,
        topography: item.topography_lines
      }, () => {
        if (this.props.map) {
          this.props.map.setCenter(center);
          this.props.map.setZoom(DrawingManager2.getZoomLevel(item.project_site_area));
        }
        this.props.setOpenImport(false);
      })
    }
  }
}