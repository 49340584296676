import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplate.scss';
import '@teneleven/protocols-ts-web';
import '../css/BuilditUI.scss';
import { ConverterLayer, siteStruct, Unit, saveState, ConverterType, ListType, PartOfSelect} from './DataTypes';
import { dataParsing, asyncFileRead, parsingUpdateFile } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, checkFileName, checkSpecialSymbolInName, getRoadLine } from './DBManager';
import { NaverMapManager, NaverPoint, NaverLatLng } from './NaverMapManager';
import { GeotoWKT, jstsPolygontoWKT, latlng2tm, SceneManager, tm2latlng, } from './SceneManager';
import { Field, FieldType } from './Field';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search'
import { deleteFieldFromFieldList, getFieldsArea, brightenAllLayer, darkenAllLayer, wkt2LatLngs, GetUnionJSTSPolygonFormLayer } from './CoreAndHouseController';
import { shapeInfoData } from './ProjectDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import App from '../App';
import { getCurveErrorCircle } from './MeshMaker';
import { FieldUI } from './FieldUI';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { ErrorLog, ErrorLogCell, ErrorType, makeErrorInformation, makeWarningInformation } from './ErrorLog';
import { LayerPanel } from './LayerPanel';
import { House } from './House';
import { DXFColor, DXFMaker } from './DXFMaker';
import * as jsts from 'jsts';
import wkx from 'wkx'

export interface SceneProps {

}

export interface SceneState {
  layers: ConverterLayer[];
  handle: number;
  isCad: boolean;
  isCadastral: boolean;
  screenWidth: number;
  screenHeight: number;
  address: string;
  canvasAlpha: number;
  fileName: string;
  saveName: string;
  showLayer: boolean;
  selectLayer: ConverterLayer | null;
  siteArea: number,
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,
  settingID: string,
  userID: string,
  showModal: boolean,
  loadFile: boolean,
  dragCover: boolean,
  fileUnit: Unit,
  showSettingModal: boolean,
  showAutoSetting: boolean,
  showSample: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    handle: 0,
    isCad: true,
    isCadastral: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    address: '',
    canvasAlpha: 50,
    fileName: '선택된 파일 없음',
    saveName: '',
    showLayer: true,
    selectLayer: null,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    settingID: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showAutoSetting: false,
    loadFile: false,
    dragCover: true,
    fileUnit: Unit.Millimeters,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    showSample: true,
  };

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  mount: HTMLDivElement | null = null;
  polygon2DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  siteFields: Field[] = [];
  roadFields: Field[] = [];
  vacancyOutsideFields: Field[] = [];
  vacancyInsideFields: Field[] = [];
  centerLineOfRoadFields: Field[] = []; // 도로중심선 
  topographyFields: Field[] = []; //성절토 라인

  DBTableName = 'platform-buildit-my-site-v2';
  S3BucketName = 'teneleven-platform-my-site-v2';
  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;

  settingData = new userSettingData(App.tempStage, App.session.email);

  errorLogs: ErrorLogCell[][] = [];
  loadFileErrorLogs: ErrorLogCell[] = [];
  globalErrorLogs: ErrorLogCell[] = [];
  fileSizeErrorLogs: ErrorLogCell[] = [];

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => console.log('test'),
    title: '제목'
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();
  }

  componentDidMount = async () => {

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.addObjectToScene(this.polygon2DGroup);

    this.sceneManager.SceneInit();

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);
    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);

    this.animate();
    await this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);
    this.errorLogs.push(this.fileSizeErrorLogs);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
  }

  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showLayer !== previousState.showLayer) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth - 235 * (!this.state.showLayer ? 1 : 0) - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 222 : 0),
    });
  }

  removeFieldFromFieldList = (field: Field) => {
    let i = this.siteFields.indexOf(field);
    if (i > -1) {
      this.mapManager.clearAllPolygon();
    }

    deleteFieldFromFieldList(this.siteFields, field);
    deleteFieldFromFieldList(this.roadFields, field);
    deleteFieldFromFieldList(this.vacancyOutsideFields, field);
    deleteFieldFromFieldList(this.vacancyInsideFields, field);
    deleteFieldFromFieldList(this.centerLineOfRoadFields, field);
    deleteFieldFromFieldList(this.topographyFields, field);

    this.recalculateArea();
  }

  addNewField = (fields: Field[], type: FieldType) => {
    let newField = new Field(fields.length.toString(), type);
    let uuid = newField.id;

    if (type === FieldType.site) {
      if (fields.length > 0) {
        this.showModal('알림', ['사업영역은 하나만 허용합니다!!!!'], buttonNum.oneButton, () => console.log('enable only one site layer'));
        return;
      }
    }

    fields.push(newField);
    this.errorLogs.push(newField.ErrorLog);
    this.polygon2DGroup.add(newField.ErrorPolygonGroup);

    this.setState({
      settingID: uuid,
      showLayer: false,
    })

    this.recalculateArea();
  }

  dataInitialize = () => {
    this.polygon2DGroup.children = [];
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyOutsideFields = [];
    this.vacancyInsideFields = [];

    this.bbox = new THREE.Box3();
    this.mapManager.clearAllPolygon();

    this.setState({
      layers: [],
    })
  }

  loadFilebyFile = async (file: any) => {
    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    this.dataInitialize();
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }

    let layer = dataParsing(data, this.state.fileUnit);

    this.bbox.makeEmpty();
    layer.forEach(l => {
      let hasCurve = false;
      l.polygons.forEach(p => {
        this.polygon2DGroup.add(p.lineMesh);
        if (p.hasCurve) {
          hasCurve = true;
          l.errorLayer = true;
        }

        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
      if (hasCurve)
        this.loadFileErrorLogs.push(makeWarningInformation(`${l.name} 레이어에 라운드가 있습니다.`, '', getCurveErrorCircle(l), [l]))
      if (l.polygons.length > 1)
        this.loadFileErrorLogs.push(makeWarningInformation(`${l.name} 레이어에 하나 이상의 폴리곤이 있습니다.`, '', new THREE.Group(), [l]))
    });

    let center = new THREE.Vector3(0);
    let size = new THREE.Vector3(0);
    this.bbox.getCenter(center);
    this.bbox.getSize(size);
    let frustumSize = size.x / 2 * 1.1;
    if (this.state.screenHeight < this.state.screenWidth) {
      let height = size.y / 2 * 1.1;
      frustumSize = height / this.state.screenHeight * this.state.screenWidth;
    }
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.loadFileErrorLogs.forEach(lfel => {
      this.polygon2DGroup.add(lfel.hilightPolygon);
    })


    this.setState({
      layers: layer,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
      showAutoSetting: true,
      isCad: true,
    }, () => {
      this.onWindowResize();
      if (this.settingData.autoSetting) {
        this.autoSetting();
      }
      else {
        this.addNewField(this.siteFields, FieldType.site);
      }
    })
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  recalculateArea = () => {
    let error = 0, waring = 0;
    this.errorLogs.forEach(els => {
      els.forEach(el => {
        if (el.Type === ErrorType.Error) error++;
        if (el.Type === ErrorType.Warning) waring++;
      })
    })

    this.setState({
      siteArea: getFieldsArea(this.siteFields),
      roadArea: getFieldsArea(this.roadFields),
      vacancyInsideArea: getFieldsArea(this.vacancyInsideFields),
      vacancyOusideArea: getFieldsArea(this.vacancyOutsideFields),
      errorSize: error,
      warningSize: waring,
      errorLog: error + waring > 0 ? true : false,
    })
  }

  showLayerList = (id: string) => {
    this.setState({
      showLayer: false,
      settingID: id,
    })
  }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        break;
      default:
        break;
    }
  }

  objectRotate(degree: number) {
    this.polygon2DGroup.rotateZ(THREE.MathUtils.degToRad(degree));
  }

  addSiteAreaToMap = async () => {
    if (!this.mapManager.isCreate() || this.siteFields.length === 0 || !this.siteFields[0].getLayer())
      return;

    while (this.fileSizeErrorLogs.length > 0) {
      this.fileSizeErrorLogs.splice(0, 1);
    }

    let benchmark = this.siteFields[0].getFirstPoint()!;
    this.mapManager.setBenchmarkPosition(this.sceneManager.getScreenPosition(benchmark.clone().applyMatrix4(this.polygon2DGroup.matrix)));
    let benchmarkInMap = latlng2tm(this.mapManager.getBenchmarkPosition());

    let sitePolygon = this.siteFields[0].getUnionJstsPolygon();
    let outPolygon = this.siteFields[0].getUnionJstsPolygon();

    let siteLatlngs: any[] = [];
    let wktSize = 0;
    this.siteFields.forEach(f => {
      siteLatlngs = siteLatlngs.concat(f.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap));
      wktSize += f.wktSize;
    });

    let insideLatLngs: any[] = [];
    this.vacancyInsideFields.forEach(f => {
      insideLatLngs = insideLatLngs.concat(f.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap));
      wktSize += f.wktSize;
    })

    let outsideLatLngs: any[] = [];
    this.vacancyOutsideFields.forEach(f => {
      //@ts-ignore
      outPolygon = outPolygon.union(f.getUnionJstsPolygon().buffer(0.1));
      outsideLatLngs = outsideLatLngs.concat(f.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap));
      wktSize += f.wktSize;
    })

    let roadLatLngs: any[] = [];
    this.roadFields.forEach(f => {
      //@ts-ignore
      outPolygon = outPolygon.union(f.getUnionJstsPolygon().buffer(0.1));
      roadLatLngs = roadLatLngs.concat(f.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap));
      wktSize += f.wktSize;
    })
    let centerRoadlatlngs: any[] = [];
    if (this.centerLineOfRoadFields.length > 0) {
      this.centerLineOfRoadFields.forEach(f => {
        centerRoadlatlngs = centerRoadlatlngs.concat(f.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap));
        wktSize += f.wktSize;
      })
    } else {
      let centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon), jstsPolygontoWKT(outPolygon)]);
      wktSize += new Blob([centerOfRoadWKT]).size;
      centerRoadlatlngs = wkt2LatLngs(centerOfRoadWKT, benchmark, benchmarkInMap);
    }

    wktSize /= 1000;
    console.log(wktSize);
    if (wktSize > 380) {
      this.fileSizeErrorLogs.push(makeErrorInformation(`dxf 파일의 Polyline 수가 많아 파일 업로드 할 수 없습니다.`,
        `(최대 사이즈: 400kb, 현재 사이즈: ${wktSize + 20}kb) 향후 업데이트를 통해 개선 예정입니다.
      대안) 캐드 Polyline의 간소화를 통해 업로드를 할 수 있습니다.`));
    }
    this.recalculateArea();

    this.mapManager.setNaverSitePolygon(siteLatlngs);
    this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
    this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
    this.mapManager.setNaverRoadPolygon(roadLatLngs);
    this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
  }

  getFrustumSizeWithMapSize = () => {
    let mapProj = this.mapManager.getMapProjectionData();
    let rect = this.mount!.getBoundingClientRect();
    let p1 = mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    let width = this.getFrustumSizeWithMapSize();
    width = this.areaBias !== 1 ? width / this.areaBias : width;
    this.sceneManager.CameraFrustumResize(width / this.areaBias, aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);

    this.setState({
      isCadastral: isCadastral
    })
  }

  checkSaveState = async () => {
    let fn = this.state.saveName === '' ? 'my_site' : this.state.saveName;
    let information: string[] = [];
    let state: saveState = saveState.complete;

    if (checkSpecialSymbolInName(fn)) {
      information.push('제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.');
      state = saveState.error;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName) {
      information.push('이미 같은 제목을 가진 나의 사업영역이 존재합니다.');
      state = saveState.error;
    }

    if (this.mapManager.getMapSettingState()) {
      information.push('사업영역을 맵에 표시해 주세요.');
      state = saveState.error;
    }

    if (this.roadFields.length < 1 || this.state.roadArea <= 0) {
      information.push('최소 하나의 도로영역이 선택되어야 하며, 면적은 0㎡ 보다 커야합니다.');
      state = saveState.error;
    }

    if (this.state.isCad) {
      information.push('MAP화면에만 저장 가능합니다.');
      state = saveState.error;
    }

    switch (state as saveState) {
      case saveState.complete:
        this.saveToAWS(fn);
        break;
      case saveState.warning:
        information.push('계속 진행 하시겠습니까??')
        this.showModal('알림', information, buttonNum.twoButton, () => this.saveToAWS(fn));
        break;
      case saveState.error:
        this.showModal('경고', information, buttonNum.oneButton, () => console.log('error'));
        break;
      default:
        break;
    }
  }

  saveToAWS = async (fn: string) => {
    this.showModal('알림', ['나의 사업영역을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

    let { globalId: globalID, userId: privateID } = await incrementCounter("my_site_id", this.state.userID);

    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;
    let imageName = `img_large.png`;
    let shapeInfoName = 'shapeInfo.json';
    let date = new Date().toISOString();

    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible && p.selected;
        })
      })
      resolve(0);
    })
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

    let projectSiteWKT: string[] = [];
    let roadSiteWKT: string[] = [];
    let vacancyInsideWKT: string[] = [];
    let vacancyOutsideWKT: string[] = [];
    let centerOfRoadWKT: string[] = [];
    let topographyWKT: { polygon: string[], height: number }[] = [];
    let topographyWKTPlatform: { polygon: string[], height: number }[] = [];

    let sitePolygon = this.siteFields[0].getUnionJstsPolygon();
    let outPolygon = this.siteFields[0].getUnionJstsPolygon();

    let benchmark = this.siteFields[0].getFirstPoint()!;
    let benchmarkInMap = latlng2tm(this.mapManager.getBenchmarkPosition());

    this.siteFields.forEach(f => {
      projectSiteWKT = projectSiteWKT.concat(f.getWKTArrayByBenchmark(benchmark, benchmarkInMap));
    });

    this.roadFields.forEach(f => {
      outPolygon = outPolygon.union(f.getUnionJstsPolygon());
      roadSiteWKT = roadSiteWKT.concat(f.getWKTArrayByBenchmark(benchmark, benchmarkInMap));
    })

    this.vacancyInsideFields.forEach(f => {
      vacancyInsideWKT = vacancyInsideWKT.concat(f.getWKTArrayByBenchmark(benchmark, benchmarkInMap));
    })

    this.vacancyOutsideFields.forEach(f => {
      outPolygon = outPolygon.union(f.getUnionJstsPolygon());
      vacancyOutsideWKT = vacancyOutsideWKT.concat(f.getWKTArrayByBenchmark(benchmark, benchmarkInMap));
    })

    if (this.centerLineOfRoadFields.length > 0) {
      this.centerLineOfRoadFields.forEach(f => {
        centerOfRoadWKT = centerOfRoadWKT.concat(f.getWKTArrayByBenchmark(benchmark, benchmarkInMap));
      })
    }
    else {
      let wkt = await getRoadLine([jstsPolygontoWKT(sitePolygon), jstsPolygontoWKT(outPolygon)]);
      let centerRoadlatlngs = wkt2LatLngs(wkt, benchmark, benchmarkInMap);

      let geoJSON = {
        type: 'Polygon',
        coordinates: centerRoadlatlngs,
      }
      centerOfRoadWKT.push(GeotoWKT(geoJSON));
    }

    this.topographyFields.forEach(f => {
      topographyWKT.push({ polygon: f.getWKTArrayByBenchmark(benchmark, benchmarkInMap), height: f.getHeight() });
    })

    let captureBbox = new THREE.Box3();
    this.siteFields.forEach(f => {
      f.getLayer()!.polygons.forEach(p => {
        if (p.selected) {
          p.vertices.forEach(v => {
            captureBbox.expandByPoint(v);
          })
        }
      })
    })

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    //image 
    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    let shapeInfo: shapeInfoData = {
      boundaryLine: [],
      projectSite: projectSiteWKT,
      projectSiteArea: this.state.siteArea,
      projectSiteRoad: [],
      road: roadSiteWKT,
      centerOfRoad: centerOfRoadWKT,
      setbackLineApartment: [],
      setbackLineMultiHouse: [],
      setbackLineOfficetel: [],
      setbackLineRowHouse: [],
      skylineCircle: [],
      skylineLine: [],
      vacancyInsie: vacancyInsideWKT,
      vacancyOutsie: vacancyOutsideWKT,
      topographyLines: topographyWKT,
    }
    App.stage !== "prod" && console.log(shapeInfo);

    let dbItem: siteStruct = {
      stage: App.tempStage,
      global_id: globalID,
      user_id: privateID,
      name: fn,
      email: this.state.userID,
      address: await getAddressByProjectSite(projectSiteWKT),
      project_site_area: this.state.siteArea,
      project_site: projectSiteWKT,
      road_site_area: this.state.roadArea,
      road_site: roadSiteWKT,
      vacancy_outside_area: this.state.vacancyOusideArea,
      vacancy_outside: vacancyOutsideWKT,
      vacancy_inside_area: this.state.vacancyInsideArea,
      vacancy_inside: vacancyInsideWKT,
      topography_lines: topographyWKTPlatform,
      centerOfRoad: centerOfRoadWKT,
      img_path: `s3://${S3SavePath}/${imageName}`,
      shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
      created_at: date,
      modified_at: date,
      deleted: false,
    };
    App.stage !== "prod" && console.log(dbItem);
    saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
    saveDataToDynamoDB(dbItem, this.DBTableName).catch(() => {
      this.showModal('알림', ['나의 사업영역을 저장에 실패 했습니다.'], buttonNum.oneButton, () => console.log('save fail'));
    });
    this.showModal('알림', ['나의 사업영역을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
  }

  setSelectLayer = (click: ConverterLayer) => {
    if (click.selected) {
      this.showModal('알림', ['이미 선택되어 있는 레이어입니다.'], buttonNum.oneButton, () => console.log('selected layer'));
      return;
    }

    if (click.errorLayer) {
      this.showModal('알림', ['문제 있는 레이어를 선택했습니다.'], buttonNum.oneButton, () => console.log('error layer'));
      return;
    }

    if (click.polygons.length > 1 && this.siteFields.length > 1 && this.siteFields[0].id === this.state.settingID) {
      this.showModal('알림', ['여러 개의 대지영역이 동시에 입력될 수 없습니다. 가장 큰 면적의 대지영역이 자동으로 선택됩니다.'], buttonNum.oneButton, () => console.log('add multipolygon to site layer'));
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateArea);
    }
    else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateArea));
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  autoSetting = () => {
    this.clearSettingData()

    this.state.layers.forEach(l => {
      if (l.errorLayer)
        return;

      let splits = l.name.split('_');

      switch (splits[0].toUpperCase()) {
        case 'SITE':
          if (this.siteFields.length < 1) {
            let newField = new Field(this.siteFields.length.toString(), FieldType.site);
            newField.setLayer(l);
            if (Number(splits[1]))
              newField.setArea(Number(splits[1]));
            this.siteFields.push(newField);
          }
          break;
        case 'ROAD':
          {
            let newField = new Field(this.roadFields.length.toString(), FieldType.road);
            newField.setLayer(l);
            this.roadFields.push(newField);
          }
          break;
        case 'PARK':
          {
            let newField = new Field(this.vacancyOutsideFields.length.toString(), FieldType.vacancyOutside);
            newField.setLayer(l);
            this.vacancyOutsideFields.push(newField);
          }
          break;
        case 'SPECIAL':
          {
            let newField = new Field(this.vacancyInsideFields.length.toString(), FieldType.vacancyInside);
            newField.setLayer(l);
            if (Number(splits[1]))
              newField.setArea(Number(splits[1]));
            this.vacancyInsideFields.push(newField);
          }
          break;
        case 'SETOFF':
          {
            let newField = new Field(this.centerLineOfRoadFields.length.toString(), FieldType.centerLineOfRoad);
            newField.setLayer(l);
            this.centerLineOfRoadFields.push(newField);
          }
          break;
        case 'LEVEL':
          {
            let newField = new Field(this.topographyFields.length.toString(), FieldType.topography);
            newField.setLayer(l);
            if (Number(splits[1]))
              newField.setHeight(Number(splits[1]));
            this.topographyFields.push(newField);
          }
          break;
        default:
          break;
      }
    })

    this.recalculateArea();
    if (this.state.errorSize === 0 && this.siteFields.length > 0 && this.roadFields.length > 0) {
      this.canvasModeChanged(false);
    }
    this.setState({ showAutoSetting: false })
  }

  clearSettingData = () => {
    while (this.siteFields.length > 0)
      deleteFieldFromFieldList(this.siteFields, this.siteFields[0]);

    while (this.roadFields.length > 0)
      deleteFieldFromFieldList(this.roadFields, this.roadFields[0]);

    while (this.vacancyInsideFields.length > 0)
      deleteFieldFromFieldList(this.vacancyInsideFields, this.vacancyInsideFields[0]);

    while (this.vacancyOutsideFields.length > 0)
      deleteFieldFromFieldList(this.vacancyOutsideFields, this.vacancyOutsideFields[0]);

    while (this.centerLineOfRoadFields.length > 0)
      deleteFieldFromFieldList(this.centerLineOfRoadFields, this.centerLineOfRoadFields[0]);

    while (this.topographyFields.length > 0)
      deleteFieldFromFieldList(this.topographyFields, this.topographyFields[0]);

    this.recalculateArea();

    this.setState({
      showAutoSetting: true,
    })
  }

  getSettingName = () => {
    let field = this.siteFields.find(f => f.id === this.state.settingID)
    if (field) return field.name;

    let rField = this.roadFields.find(f => f.id === this.state.settingID)
    if (rField) return rField.name;

    let oField = this.vacancyOutsideFields.find(f => f.id === this.state.settingID)
    if (oField) return oField.name;

    let iField = this.vacancyInsideFields.find(f => f.id === this.state.settingID)
    if (iField) return iField.name;

    let cField = this.centerLineOfRoadFields.find(f => f.id === this.state.settingID)
    if (cField) return cField.name;

    let tField = this.topographyFields.find(f => f.id === this.state.settingID)
    if (tField) return tField.name;

    return '';
  }

  getCurrentSelected = () => {
    return null;
  }

  render = () => {
    return (
      <div className="buildit-template">
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.mySite}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={this.clearSettingData}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          isFileOpen={this.state.loadFile}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.checkSaveState)}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className='MainBody'>
          <div className='information'>
            <div className='info'><div className='infoLabel'>사업영역 면적</div><div className='inforValue'>{this.state.siteArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>인접도로 면적</div><div className='inforValue'>{this.state.roadArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>공지영역 면적</div><div className='inforValue'>{this.state.vacancyOusideArea}㎡</div></div>
            <div className='info'><div className='infoLabel'>특수영역 면적</div><div className='inforValue'>{this.state.vacancyInsideArea}㎡</div></div>
            <div>
            </div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
              <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showLayer: !this.state.showLayer })}>레이어 보기</span>
            </div>
          </div>

          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.mySite}
                  showSample={this.state.showSample}
                />
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className='searchDiv' hidden={this.state.isCad}>
                    <input
                      type='text'
                      className='addressSearch'
                      placeholder='주소 검색'
                      value={this.state.address}
                      onChange={e => this.setState({ address: e.target.value })}
                      onKeyUp={e => this.textInputKeyUp(e)}
                    />
                    <SearchIcon className='icon' onClick={() => this.mapManager.searchAddressToCoordinate(this.state.address)} />
                  </div>
                  <div className='rightButtons'>
                    <div className='switchTabs'>
                      <Button className={`switchButton ${this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(true)}>CAD</Button>
                      <Button className={`switchButton ${!this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(false)}>MAP</Button>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && 'hidden'}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                    </div>
                    <input
                      className={`slider ${this.state.isCad && 'hidden'}`}
                      type='range'
                      min='0'
                      max='100'
                      value={this.state.canvasAlpha}
                      onChange={(e) => this.setState({ canvasAlpha: Number(e.target.value) })}
                    />
                  </div>
                </div>
                <div className={`autosetDiv ${!this.state.showAutoSetting && 'hidden'}`}>
                  <Button className='autosetButton' onClick={this.autoSetting} >자동 추가</Button>
                  <div className='autosetMessage'>정해진 규칙에 따라 생성된 레이어는 변환 설정값을 자동으로 처리합니다 (상세한 규칙은 메뉴얼을 참고해주세요) </div>
                </div>
              </div>
              <ErrorLog
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                darkenAllLayer={() => darkenAllLayer(this.state.layers)}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <LayerPanel
                layers={this.state.layers}
                houses={[]}
                showlayerPanle={this.state.showLayer} closePanel={() => this.setState({ showLayer: true })}
                setLayer={this.setSelectLayer}
                setHouse={(click: House) => { }}
                settingName={this.getSettingName()}
                currentSelected={this.getCurrentSelected()}
                listType={ListType.layer}
                hideHeadSwitch={true}
                partOfSelect={PartOfSelect.field}
              />
              <div className='functionalLayout' >
                <div className='fileNameLayout lbrBorder'>
                  <div className='titleText'>나의 사업영역 제목</div>
                  <input type='text' value={this.state.saveName} onChange={e => this.setState({ saveName: e.target.value })} placeholder='나의 사업영역 제목 입력'></input>
                </div>
                <div className='bodyLayout lbrBorder'>
                  <div className='list'>{this.siteFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.siteFields, FieldType.site)}>+ 대지영역 추가</Button>
                  <div className='list'>{this.roadFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.roadFields, FieldType.road)}>+ 인접도로 추가</Button>
                  <div className='list'>{this.vacancyOutsideFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.vacancyOutsideFields, FieldType.vacancyOutside)}>+ 공지영역 추가</Button>
                  <div className='list'>{this.vacancyInsideFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.vacancyInsideFields, FieldType.vacancyInside)}>+ 특수영역 추가</Button>
                  <div className='list'>{this.centerLineOfRoadFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.centerLineOfRoadFields, FieldType.centerLineOfRoad)}>+ 인접대지경계선 추가</Button>
                  <div className='list'>{this.topographyFields.map(f =>
                    <div key={f.id}>
                      <FieldUI
                        field={f}
                        showLayerList={this.showLayerList}
                        selectLayer={this.state.selectLayer}
                        settingID={this.state.settingID}
                        removeField={this.removeFieldFromFieldList}
                        valueChanged={this.recalculateArea}
                        showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                      />
                    </div>)}
                  </div>
                  <Button onClick={() => this.addNewField(this.topographyFields, FieldType.topography)}>+ 성절토 라인 추가</Button>
                </div>
                <div className='saveLayout lbrBorder TwoButtonHeight'>
                  <Button className='navyButton' onClick={() => this.addSiteAreaToMap()}>사업영역 맵 표시</Button>
                  <Button className='sideMargin' onClick={() => this.showModal('나의 사업영역 저장', ['나의 사업영역을 저장하시겠습니까?'], buttonNum.twoButton, this.checkSaveState)}>나의 사업영역 저장</Button>
                  <div className='extraText'><span>서비스 이용약관</span><span className='end'>Copyright © 2019 BUILDIT</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
