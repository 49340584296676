import React, { Component } from 'react';
import { setBlockOpacity } from './CoreAndHouseController';
import '../css/CADConverter/BlockColorInfo.scss';


interface BlockColorInfoProps {
  fields?: any,
  allFields?: any,
  components?: any,
  allComponents?: any,
}

interface BlockColorInfoState {

}


class BlockColorInfo extends Component<BlockColorInfoProps, BlockColorInfoState> {
  state: BlockColorInfoState = {

  }

  render() {
    return (
      <div className="BlockColorInfo">
            <div className="site-marker-info">
              <ul>
                <li className="title font font-secondary">나의 사업영역</li>
                <li className="m-b-sm font font-14px field-marker vacancy-inside"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.vacancyInside, 1);
                  }}
                  onMouseLeave={() => { 
                    setBlockOpacity(this.props.allFields, 1); 
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">배치 제한 영역</span>
                </li>
                <li className="m-b-sm font font-14px field-marker site"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.site, 1);
                  }}
                  onMouseLeave={() => { 
                    setBlockOpacity(this.props.allFields, 1); 
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">대지 영역</span>
                </li>
                <li className="m-b-sm font font-14px field-marker center-of-road"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.roadCenterLine, 1);
                  }}
                  onMouseLeave={() => { 
                    setBlockOpacity(this.props.allFields, 1); 
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">인접대지경계선</span>
                </li>
                <li className="m-b-sm font font-14px field-marker road"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.road, 1);
                  }}
                  onMouseLeave={() => { 
                    setBlockOpacity(this.props.allFields, 1); 
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">도로 영역</span>
                </li>
                <li className="m-b-sm font font-14px field-marker vacancy-outside"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.vacancyOutside, 1);
                  }}
                  onMouseLeave={() => { 
                    setBlockOpacity(this.props.allFields, 1); 
                  
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">공지 영역</span>
                </li>
                <li className="m-b-sm font font-14px field-marker elevation"
                  onMouseEnter={() => {
                    setBlockOpacity(this.props.allFields, 0.4);
                    setBlockOpacity(this.props.fields.topography, 1);
                  }}
                  onMouseLeave={() => { 
                    
                    setBlockOpacity(this.props.allFields, 1); 
                  }}
                ><div className="line m-r-sm" /><span className="line-name font font-12px font-primary">대지 레벨</span>
                </li>
              </ul>
            </div>
                
      </div>
    );
  }
}


export default BlockColorInfo;