import * as THREE from '@teneleven/three';
import { ConverterBlock } from './ConverterBlock';
import { BlockType, ConverterEntity, ConverterUnit, Polygon, PolylineInfo } from './DataTypes';
import { ErrorLogCell, ErrorLogCell2 } from './ErrorLog';
import { Field, FieldType } from './Field';
import * as jsts from 'jsts';
import * as turf from '@turf/turf';
import { tm2latlng } from './SceneManager';
import { switchLineDashedState } from './FileParser';
import { CheckPolygonsApart } from './CoreAndHouseController';
import App from '../App';
import wkx from 'wkx'

const uuid4 = require('uuid/v4');

export interface BlockParsingData {
  buildings: ConverterBuilding[];
  fields: ConverterField[];
  wrongBlocks: any[],
  cadastralMap?: ConverterMap[];
}

export class ConverterMap {
  boundary: any;
  constructor() {
    
  }
}
export abstract class BuildingPart {
  readonly uuid: string;
  readonly buildingType: 'group' | 'component';
  name: string;
  readonly block: ConverterBlock;
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  renderGroup: THREE.Group;
  parts: BuildingPart[];
  ErrorLog: ErrorLogCell2[];

  totalExclusiveAreas: number;
  totalServiceAreas: number;
  totalCommonWallAreas: number;
  totalCoreAreas: number;
  houseNumber: number;
  coreNumber: number;
  levelCount: number;

  polygon?: any;
  ErrorPolygonGroup: THREE.Group;
  abstract RebuildOutputPolygon(): void;
  abstract ResetPartElement(): void;
  abstract UpdateArea(): void;
  abstract toJson(): {};

  constructor(block: ConverterBlock) {
    this.uuid = uuid4();
    this.block = block;
    this.buildingType = block.type === BlockType.group ? 'group' : 'component';
    this.name = block.name;
    this.position = new THREE.Vector3();
    this.scale = new THREE.Vector3();
    this.rotate = 0;
    this.renderGroup = new THREE.Group();
    this.renderGroup.name = block.name;
    this.parts = [];
    this.ErrorLog = [];
    
    this.totalExclusiveAreas = 0;
    this.totalServiceAreas = 0;
    this.totalCommonWallAreas = 0;
    this.totalCoreAreas = 0;

    this.houseNumber = 0;
    this.coreNumber = 0;
    this.levelCount = 0;
    //@ts-ignore
    this.polygon = block.entities.filter(entity => entity.type === "LWPOLYLINE" || entity.type === "LINE").map(entity => entity.polygon);
    this.ErrorPolygonGroup = new THREE.Group();
    
    // this.polygon.forEach((polygon: Polygon) => {
    //  polygon.lineMesh.material.uniforms.opacity = { value: 0.2 }; //0830 라인 투명도 조절

    // })
    

  }
}

export abstract class BuildingComponent extends BuildingPart {
  componentType: 'core' | 'house';
  outputPolygon: PolylineInfo[];
  polygon: Polygon[];
  centerOfAllLine: THREE.Vector3;
  ErrorLog: ErrorLogCell2[];
  level: boolean[];
  errorBlock: boolean;
  constructor(block: ConverterBlock) {
    super(block);

    this.componentType = 'core';
    this.outputPolygon = [];
    this.polygon = [];
    this.centerOfAllLine = new THREE.Vector3();
    this.ErrorLog = [];
    this.level = [true, true, true, true, true, true, true, true, true, true];
    this.errorBlock = false;
  }

  RebuildOutputPolygon = () => {
    this.parts.forEach(p => {
      p.RebuildOutputPolygon();
    })
  }

  ResetPartElement = () => {

  }
}

export class ConverterBuilding {
  parts: BuildingPart[];
  position: THREE.Vector3;
  scale: THREE.Vector3;
  rotate: number;
  name: string;
  readonly uuid: string;
  renderGroup: THREE.Group;
  polygon: Polygon[];
  ErrorPolygonGroup: THREE.Group;
  ErrorLog: ErrorLogCell2[];
  levelHeights: number[];
  wrongFBlock: any[];

  constructor() {
    this.parts = [];
    this.position = new THREE.Vector3(0);
    this.scale = new THREE.Vector3(1, 1, 1);
    this.rotate = 0;
    this.name = '';
    this.renderGroup = new THREE.Group();
    this.uuid = uuid4();
    this.polygon = [];
    this.ErrorPolygonGroup = new THREE.Group();
    this.ErrorLog = [];
    this.levelHeights = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    this.wrongFBlock = [];
  }

  SetLevelHeight = (value: number) => {
    for (let i = 0; i < this.levelHeights.length; i++) {
      this.levelHeights[i] = value;
    }
  }

  setPosition = (pos: THREE.Vector3) => {
    this.position = pos;
    this.renderGroup.position.set(pos.x, pos.y, pos.z);
  }

  setScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    this.renderGroup.scale.set(scale.x, scale.y, scale.z);
  }

  setUnitScale = (unitScale: number) => {
    unitScale = unitScale;
    let newScale = this.renderGroup.scale.clone().multiplyScalar(unitScale);
    this.setScale(newScale);
    let pos = this.renderGroup.position.clone().multiplyScalar(unitScale);
    this.setPosition(pos);
  }

  setRotation = (rotate: number) => {
    this.rotate = rotate;
    this.renderGroup.rotateZ(turf.degrees2radians(rotate));
  }

  resetPartsElements = () => {
    if (this.parts.length > 0) {
      if (this.parts[0].buildingType === 'component') {
        let coreIndex = this.parts.findIndex(p => (p as BuildingComponent).componentType === 'core');
        if (coreIndex >= 0) {
          let corePart = this.parts[coreIndex];
          this.parts.splice(coreIndex, 1);
          this.parts.unshift(corePart);
          console.log('reset');
        }
      }
      else {
        for (let i = 0; i < this.parts.length; i++) {
          this.parts[i].ResetPartElement();
        }
        console.log('not component');
      }
    }
  }

  toJson() {
    let json = {
      parts: this.parts.map(p => p.toJson()),
      position: this.position,
      name: this.name,
    }

    return json;
  }
}

export class FieldPart {
  // entity: ConverterEntity;
  area: number;
  renderGroup: THREE.Group;
  verts: THREE.Vector3[];
  position: THREE.Vector3;
  shape: boolean;
  errorPolygonGroup: THREE.Group;
  hasCurve: boolean;
  polygon: Polygon;
  unused: boolean;

  constructor(entity: ConverterEntity) {
    this.verts = (entity as ConverterUnit).verts;
    this.position = new THREE.Vector3(0);
    this.renderGroup = new THREE.Group();
    this.shape = true;
    this.hasCurve = false;
    let lineMesh = (entity as ConverterUnit).polygon.lineMesh;
    this.renderGroup.add(lineMesh);    
    //@ts-ignore
    let pol = this.getJSTSPolygon(this.renderGroup.matrixWorld);
    this.area = pol.getArea();    
    this.errorPolygonGroup = new THREE.Group();
    this.polygon = (entity as ConverterUnit).polygon;
    this.unused = false;
    this.polygon.lineMesh.material.uniforms.opacity = {value: 1};
  }

  getJSTSPolygon = (matrix: THREE.Matrix4) => {
    let coords: jsts.geom.Coordinate[] = [];
    this.verts.forEach(v => {
      let newV = v.clone().applyMatrix4(matrix);
      coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
      if (v.z !== 0) {
        this.hasCurve = true;
      }
    })
    if (this.verts.length >= 2) {
      let firstVert = this.verts[0];
      let lastVert = this.verts[this.verts.length-1];
    
      if (firstVert.x !== lastVert.x || firstVert.y !== lastVert.y || this.verts.length < 4) { // firstVert.z !== lastVer.z
        this.shape = false;
      }
    }

    let geoFac = new jsts.geom.GeometryFactory();
    let linearRing = geoFac.createLineString(coords);
    //@ts-ignore
    return geoFac.createPolygon(linearRing, []).buffer(0);
  }

}

export class ConverterField {
  readonly uuid: string;
  parts: FieldPart[];
  renderGroup: THREE.Group;
  ErrorLog: ErrorLogCell2[];
  name: string;
  typeName: FieldType;
  private area: number;
  private height: number;
  ErrorPolygonGroup: THREE.Group;
  private position: THREE.Vector3;
  private scale: THREE.Vector3;
  unitScale: number;
  isMultiple: boolean;
  calcArea: number;
  unused: boolean;
  readonly defaultInput: number;
 // block: ConverterBlock;


  constructor(block: ConverterBlock, type: FieldType) {    
    this.uuid = uuid4();
    this.defaultInput = 0;
    this.ErrorLog = [];
    this.ErrorPolygonGroup = new THREE.Group();
    this.renderGroup = new THREE.Group();
    this.name = block.name;
    this.parts = [];
    this.area = 0;
    this.height = 0;
    this.typeName = type;
    this.position = new THREE.Vector3(0);
    this.scale = new THREE.Vector3(1, 1, 1);
    this.unitScale = 1;
    this.isMultiple = false; // 같은 필드 블록이 여러개
    this.calcArea = 0;
    this.unused = false;
//    this.block = block;
    
    let maxArea = -1;
    let maxPart: FieldPart | undefined = undefined;

    let unusedPart = [];

    // if (this.typeName === FieldType.site || this.typeName === FieldType.road) {
      block.entities.forEach(e => {
        this.getUnionJstsPolygon();
        const newPart = new FieldPart(e);
        if (Math.abs(newPart.area - maxArea) < 0.01) {
          this.renderGroup.add(newPart.renderGroup.clone());
          this.parts.push(newPart);
        }
        else if ( newPart.area > maxArea) {
          if (maxArea > 0 && maxPart) { // 대지/도로영역이 여러개
            this.isMultiple = true;
            this.renderGroup.add(maxPart.renderGroup.clone());
            maxPart.polygon.lineMesh.material.visible = false;
            switchLineDashedState(maxPart.polygon.lineMesh.material, true);
          //   if (App.session.email !== "eenql00@1011.co.kr" && App.session.email !== "test@1011.co.kr")// 0903 나중에 삭제하기
              maxPart.unused = true; 
            this.parts.push(maxPart);
          }
          maxArea = newPart.area;
          maxPart = newPart;
        }
        else {
          newPart.polygon.lineMesh.material.visible = false;
          this.isMultiple = true;
          this.renderGroup.add(newPart.renderGroup.clone());
          switchLineDashedState(newPart.polygon.lineMesh.material, true);
          // if (App.session.email !== "eenql00@1011.co.kr"&& App.session.email !== "test@1011.co.kr")// 0903 나중에 삭제하기
            newPart.unused = true;

          this.parts.push(newPart);
        }
      })

      if (maxPart) {
        if (this.typeName === FieldType.site && this.name.split('_').length === 2 && !isNaN(Number(this.name.split('_')[1]))) {
          this.area = Number(this.name.split('_')[1]);
        }
        else {
          this.area = Number((maxPart as FieldPart).area)
        }    
        this.renderGroup.add((maxPart as FieldPart).renderGroup.clone());
        this.parts.push(maxPart);
    
      }
    // }
    // else {
    //   block.entities.forEach(e => {
    //     this.getUnionJstsPolygon();
    //     const newPart = new FieldPart(e);      
    //     this.area = Number(newPart.area.toFixed(2));    
    //     this.renderGroup.add(newPart.renderGroup.clone());
    //     this.parts.push(newPart);
    //   })
    // }
  //   // else {
  //   //   const newPart = new FieldPart(e);   
  //   //   this.area = Number(newPart.area.toFixed(2));    
      
  //   //   this.renderGroup.add(newPart.renderGroup.clone());
  //   //   this.parts.push(newPart);
  //   // }
 

  // //  else {
    
  //     // let tmpArea = -1;
  
  //     // if (block.entities.length > 1) {
  //     //   this.isMultiple = true;
  //     // }
  //     block.entities.forEach((e) => {
  //     //   console.log(e, 'ETTT');        
  //     //   //@ts-ignore
  //     //   if (e.polygon.area > tmpArea) {
  //     //     console.log(tmpArea, 'tmpArea');
          

  //     //     //@ts-ignore
  //     //     tmpArea = e.polygon.area;
  //     //     const newPart = new FieldPart(e);
  //     //     this.area = newPart.area;  
  //     //     this.renderGroup.remove(); 
  //     //     this.renderGroup.add(newPart.renderGroup);
  //     //     this.parts[0] = newPart;  
  //     //   }

  //     //*임시주석
  //       // const newPart = new FieldPart(e);   
  //       // if (this.typeName === FieldType.site && this.name.split('_').length === 2 && !isNaN(Number(this.name.split('_')[1]))) {
  //       //   this.area = Number(this.name.split('_')[1]);
  //       // } 
  //       // else {
  //       //   this.area = Number(newPart.area.toFixed(2));    
  //       // }
        
  //       // this.renderGroup.add(newPart.renderGroup.clone());
  //       // this.parts.push(newPart);
  //     })

  // render Order
  let renderOrder = 0;
  switch(this.typeName) {
    case FieldType.vacancyInside:
      this.parts.forEach(part => {
        part.polygon.lineMesh.material.color = new THREE.Color(0x8800C8); 
        part.renderGroup.renderOrder = 5;
      })    
    break;
    case FieldType.site:
      this.parts.forEach(part => {
        part.polygon.lineMesh.material.color = new THREE.Color(0xFF3333); 
        part.renderGroup.renderOrder = 4;
      })  
      if (this.name.split('_').length === 2) {
        this.defaultInput = Number(this.name.split('_')[1]);
      }
      break;
      case FieldType.centerLineOfRoad:
        this.parts.forEach(part => {
          part.polygon.lineMesh.material.color = new THREE.Color(0x1045FF); 
          part.renderGroup.renderOrder = 3;
        })    
        break;
    case FieldType.road:
      this.parts.forEach(part => {
        part.polygon.lineMesh.material.color = new THREE.Color(0x555555); 
        part.renderGroup.renderOrder = 2;
      })    
      break;
    case FieldType.vacancyOutside:
      this.parts.forEach(part => {
        part.polygon.lineMesh.material.color = new THREE.Color(0x0F6800); 
        part.renderGroup.renderOrder = 2;
      })    
    break;
    case FieldType.topography:
      this.parts.forEach(part => {
        part.polygon.lineMesh.material.color = new THREE.Color(0x898989); 
        part.renderGroup.renderOrder = 1;
      })  
      if (this.name.split('_').length === 2) {
        this.defaultInput = Number(this.name.split('_')[1]);
      }  
      break;
  }
 

  }

  setPosition = (position: THREE.Vector3) => {
    this.position = position;
    this.renderGroup.position.set(position.x, position.y, position.z);
  }

  getPosition = () => { return this.position; }

  setScale = (scale: THREE.Vector3) => {
    this.scale = scale;
    this.renderGroup.scale.set(scale.x, scale.y, scale.z);
  }

  getArea = () => { 
    // if (this.typeName === FieldType.site || this.typeName === FieldType.road) {
    //   let area = 0;
    //   this.parts.forEach(part => {
    //     if (!part.unused) {
    //       this.setArea(part.area);
    //       return this.area;    
    //     }        
    //   })
    // }
    // // else {
      
      return this.area; 
    //}  
  }
  setArea = (area: number) => { 
    this.area = Number(area); 
  }

  getHeight = () => { return this.height; }
  setHeight = (height: number) => { this.height = Number(height); }
  
  setUnitScale = (unitScale: number) => {
    this.unitScale = unitScale;
    let newScale = this.renderGroup.scale.multiplyScalar(unitScale);
    this.setScale(newScale);
    let pos = this.renderGroup.position.multiplyScalar(unitScale);
    this.setPosition(pos);
    let area = 0;
    this.parts.forEach(p => {
      if (!p.unused) area += p.area})
    area *= unitScale;
    area *= unitScale;
    if (this.typeName !== FieldType.site || (this.typeName === FieldType.site && this.area === 0))
      this.area = Number(area.toFixed(4));
    this.calcArea = area;
  }

  getUnionJstsPolygon = (matrix: THREE.Matrix4 = this.renderGroup.matrixWorld): jsts.geom.Geometry => {
    // let matrix = this.renderGroup.matrixWorld;
    let polygon: jsts.geom.Geometry | undefined = undefined;
    // if (this.parts[0]) {
    // polygon = this.parts[0].getJSTSPolygon(matrix);
    
    this.parts.forEach((p, i) => {
      if (!p.unused && polygon === undefined && p.shape) {
        polygon = this.parts[i].getJSTSPolygon(matrix); 
      }
      else if (!p.unused && p.shape) {
        polygon = polygon!.union(p.getJSTSPolygon(matrix));
      }
    })

    return polygon!;
    //    }
    //   return new jsts.geom.Geometry();
  }

  getLatLngList = () => {
    let matrix = this.renderGroup.matrixWorld;
    let outputPolygons: any[] = [];
    this.parts.forEach(p => {
      if (!p.unused) {
        let polygon: any[] = [];
        p.verts.forEach(v => {
          let newV = v.clone().applyMatrix4(matrix);
          let latlng = tm2latlng(new THREE.Vector2(newV.x, newV.y));
          polygon.push([latlng.x, latlng.y]);
        })
        outputPolygons.push(polygon);
  
      }
    })
    return outputPolygons;
  }
}
