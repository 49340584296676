import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplateBlock.scss';
import '@teneleven/protocols-ts-web';
import '../css/BuilditUI.scss';
import { ConverterLayer, buildingPlanStruct, SiteType, BuildingTypeData, LayerType, selectHouse, mapProjectionData, DEMData, ListType, ConverterType, CompletenessType, PartOfSelect } from './DataTypes';
import { ReactComponent as MySite } from '../img/CADConverter/mySite.svg';
import { ReactComponent as BuildingTypeIcon } from '../img/CADConverter/buildingTypeIcon.svg';
import { dataParsing, asyncFileRead, blockParsing } from './FileParser';
import { NaverMapManager, NaverPoint, NaverLatLng, NaverPolygon } from './NaverMapManager';
import { jstsPolygontoWKT, SceneManager, tm2latlng } from './SceneManager';
import { saveDataToS3, saveDataToDynamoDB, getAddressByProjectSite, polygonBufferWithDistance, getDEM, getBuildingData, checkFileName, checkSpecialSymbolInName, getRoadLine, makeBlockBuildingData } from './DBManager';
import { MakeANewBuilding, deleteFieldFromFieldList, getFieldsArea, deleteBuildingFromBuildingList, getTotalHousehold, calculateAreaProPortion, buildingStoriesAvg, mouseOutLayerTag, mouseOverLayerTag, mouseOverHouseTag, switchLayerState, findBuilding, wkt2LatLngs, blockBuildingStoriesAvg, getBlockTotalHouseHold, calculateBlockAreaProPortion, brightenAllLayer, darkenAllLayer, brightenAllField, darkenAllField, setBlockOpacity } from './CoreAndHouseController';
import { any } from 'prop-types';
import { Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ForwardArrow from '@material-ui/icons/ArrowForward';
import { Field, FieldType } from './Field';
import { BuildingType } from './BuildingType';
import { buildingData, building, resultLocation } from './resultLocationDataStruct';
import { shapeInfoData } from './ProjectDataStruct';
import { resultS3Json, base_report_file_path, base_result_file_path, housing_type_ratio } from './resultDataStruct';
import { ModalProps, Modal, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import AWSModule from '../AWSModule';
import App from '../App';
import { FieldUI } from './FieldUI';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { Setting, userSettingData } from './SettingModal';
import { House } from './House';
import { Core } from './Core';
import { ConverterBlock } from './ConverterBlock';
import { BlockParsingData, ConverterBuilding, BuildingComponent, BuildingPart, FieldPart, ConverterField } from './BuildingPart';
import MySiteBlockSaveModal from './MySiteBlockSaveModal';
import { checkSiteError } from './CheckSiteBlockError';
import { ErrorLogCell2, ErrorType, makeErrorInformation2, makeInfoInformation, makeWarningInformation2 } from './ErrorLog';
import { ErrorLogBlock } from './ErrorLogBlock';
import { default as _, divide } from 'lodash';
import { checkBlockStruct, checkLayerNameError, checkTypeError } from './CheckTypeBlockError';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { LoadingPage } from './LoadingPage';
import BlockTreeList from './BlockTreeList';
import { BuildingPartUI } from './BuildingPartUI';
import { ReactComponent as BlockIcon } from '../img/icon/blockIcon.svg';
import FieldPartUI from './FieldPartUI';
import { ErrorList, ErrorItem, SiteError, TypeError } from './Error';
import { ChevronLeft, Info, NavigateNext, SpeakerNotes } from '@material-ui/icons';
import { ReactComponent as HierarchyIcon } from '../img/icon/hierarchyIcon.svg';
import Tooltip from '../Tooltip';
import {ReactComponent as LevelIcon} from '../img/icon/levelIcon.svg';
import Tabs from '../Tabs';



const uuid4 = require('uuid/v4');
const jsts = require('jsts');

interface MyPlaneProps {

}

interface MyPlaneState {
  layers: ConverterLayer[];
  //  handle: number;
  isCad: boolean;
  //  layerListHidden: boolean;
  //  areaListHidden: boolean;
  //  buildingListHidden: boolean;
  // houseListHidden: boolean;
  screenWidth: number;
  screenHeight: number;
  canvasAlpha: number;
  address: string;
  fileName: string;
  saveName: string;
  showBlock: boolean;
  settingID: string;
  siteArea: number;
  roadArea: number,
  vacancyOusideArea: number,
  vacancyInsideArea: number,

  totalGroundArea: number;
  totalBuildingArea: number;
  exclusiveAverageArea: number;
  isSettingSite: boolean;
  listType: ListType;
  houses: House[];
  buildings: BuildingTypeData[];
  userID: string,
  showModal: boolean,
  autoset: boolean,
  isCadastral: boolean,
  loadFile: boolean,
  dragCover: boolean,
  showSettingModal: boolean,
  showSaveModal: boolean,
  isSaved: boolean,
  loadingPage: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
  infoSize: number,
  field: {
    site: ConverterField[],
    road: ConverterField[],
    roadCenterLine: ConverterField[],
    vacancyInside: ConverterField[],
    vacancyOutside: ConverterField[],
    topography: ConverterField[],
  },
  clickedField: any,
  clickedBuildingPart: any,
  totalExclusiveAreas: string,//number,
  totalCoreArea: string,
  totalServiceArea: string,
  houseNumber: number,
  totalCommonWallArea: string,
  buildingArea: string,
  groundArea: string,
  errorLogs: any[];
  currentLog: string;
  showBlockInfo: boolean,
  is2D: boolean;
  hierarchyMode: ConverterType;
}

export class Scene extends Component<MyPlaneProps, MyPlaneState> {
  state: MyPlaneState = {
    layers: [],
    //    handle: 0,
    isCad: true,
    //    areaListHidden: false,
    //    layerListHidden: true,
    //  buildingListHidden: false,
    // houseListHidden: false,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    canvasAlpha: 50,
    address: '',
    fileName: '선택된 파일 없음',
    settingID: '',
    showBlock: false,
    saveName: '',
    exclusiveAverageArea: 0,
    siteArea: 0,
    roadArea: 0,
    vacancyOusideArea: 0,
    vacancyInsideArea: 0,
    totalBuildingArea: 0,
    totalGroundArea: 0,
    isSettingSite: true,
    listType: ListType.layer,
    houses: [],
    buildings: [],
    userID: 'test@1011.co.kr',
    showModal: false,
    autoset: false,
    isCadastral: true,
    loadFile: false,
    dragCover: true,
    showSettingModal: false,
    showSaveModal: false, // 배치안 저장 모달
    isSaved: false, // 저장완료
    loadingPage: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
    infoSize: 0,
    clickedField: null,
    clickedBuildingPart: null,
    totalExclusiveAreas: '0',
    totalCoreArea: '0',
    totalServiceArea: '0',
    houseNumber: 0,
    totalCommonWallArea: '0',
    buildingArea: '0',
    groundArea: '0',
    errorLogs: [],
    currentLog: 'ALL',
    is2D: true,
    field: {
      site: [],
      road: [],
      roadCenterLine: [],
      vacancyInside: [],
      vacancyOutside: [],
      topography: [],
    },
    showBlockInfo: false,
    hierarchyMode: ConverterType.mySite,
  };
  classifyLogs = new Map();

  mount: HTMLDivElement | null = null;

  mapManager = new NaverMapManager();
  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  bbox = new THREE.Box3();

  mouseOverLayerColor = '#aaaaaa';
  baseLayerColor = '#909090';

  Buildings = new Array<BuildingTypeData>();

  siteFields: Field[] = [];
  roadFields: Field[] = [];
  vacancyOutsideFields: Field[] = [];
  vacancyInsideFields: Field[] = [];

  parsingOutput: BlockParsingData = { buildings: [], fields: [], wrongBlocks: [], cadastralMap: [] }
  block: ConverterBlock[] = [];

  naverPolygon: any;
  naverMapProj: any;
  basePosition: any;

  DEM: DEMData[] = [];

  mapProj: mapProjectionData = { mapOffset: new THREE.Vector2(), projection: any };
  addType = LayerType.site;
  S3BucketName = 'teneleven-platform-my-building-plan-v2';
  DBTableName = 'platform-buildit-my-building-plan-v2';

  fileData: string = '';
  areaBias = 1;
  makedAreaBias = false;
  centerOfRoadWKT = '';

  settingData = new userSettingData(App.tempStage, App.session.email);
  errorLogs: ErrorLogCell2[][] = [];
  loadFileErrorLogs: ErrorLogCell2[] = [];
  globalErrorLogs: ErrorLogCell2[] = [];

  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }
  blockBs: Array<BuildingPart> = [];
  houses: Array<BuildingHouseUnit> = [];
  cores: Array<BuildingCoreUnit> = [];
  notComponentBlocks: Array<any> = []; // 컴포넌트 레벨에서  빌딩타입이 group인 블록

  errorList = new ErrorList();

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();

    this.houses.forEach(h => {
      this.updateInfoPosition(h.uuid, h);
    });
    this.cores.forEach(c => {
      this.updateInfoPosition(c.uuid, c);
    });

    this.state.field.topography.forEach(topo => {
     this.updateInfoPosition(topo.uuid, undefined, topo);
    })
  }
  constructor(props: MyPlaneProps) {
    super(props);
    // this.changeSaveFileName = this.changeSaveFileName.bind(this);

  }
  
  updateInfoPosition = (uuid: string, component?: BuildingHouseUnit | BuildingCoreUnit, field?: ConverterField) => {
    let s = document.querySelector(`.TT${uuid}`) as HTMLInputElement;

    if (s) {
      s.style.visibility = 'hidden';
      if (component) {
        component.renderGroup.updateWorldMatrix(true, true);
        let matrixWorld = component.renderGroup.matrixWorld;
        let coords: jsts.geom.Coordinate[] = [];
        if (
          this.globalErrorLogs.filter(log => log.Type === ErrorType.Error).length === 0 &&
          component.ErrorLog.filter(log => log.Type === ErrorType.Error).length === 0 &&
          this.loadFileErrorLogs.filter(log => log.Type === ErrorType.Error).length === 0
        ) {
          s.style.visibility = 'display';

          component.polygon.forEach(polygon => {
            try {
              if (polygon.shape && polygon.area > 0 && polygon.vertices.length >= 4 &&
                polygon.vertices[0].x === polygon.vertices[polygon.vertices.length - 1].x && polygon.vertices[0].y === polygon.vertices[polygon.vertices.length - 1].y) {
                polygon.vertices.forEach(v => {
                  let newV = v.clone().applyMatrix4(matrixWorld);
                  coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
                });

                let geoFac = new jsts.geom.GeometryFactory();
                let linearRing = geoFac.createLinearRing(coords);

                //@ts-ignore
                let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);

                let center = new THREE.Vector3(newPolygon.getCentroid().getX(), newPolygon.getCentroid().getY(), 0);
                let c = this.sceneManager.getScreenPosition(center);

                let rect = s.getBoundingClientRect();
                let min = new THREE.Vector2(c.x - rect.width / 2, c.y - rect.height / 2);
                let max = new THREE.Vector2(c.x + rect.width / 2, c.y + rect.height / 2);


                if (!this.sceneManager.canvasBox2.containsPoint(min) || !this.sceneManager.canvasBox2.containsPoint(max)) {
                  //s.style.visibility = 'hidden';
                }
                else
                  s.style.visibility = 'visible';
                // if (completeness === CompletenessType.error) {
                //   s.style.visibility = 'hidden';
                // }
                s.style.top = c.y.toString() + 'px';
                s.style.left = c.x.toString() + 'px';
                if (this.sceneManager.orthoCamera.zoom > 4) {
                  s.style.width = "max-content";
                  s.style.height = "max-content";
                }
                else {
                  s.style.visibility = 'hidden';

                }
              }
            }
            catch {
              App.stage !== "prod" && console.log(' update position info error');
            }
          })
        }

      }
      else if (field) {
        if (this.state.showBlockInfo) {
          s.style.visibility = 'visible';
          field.renderGroup.updateWorldMatrix(true, true);
          let matrixWorld = field.renderGroup.matrixWorld;

          field.parts.forEach(part => {
            let coords: jsts.geom.Coordinate[] = [];
            part.polygon.vertices.forEach(v => {
              let newV = v.clone().applyMatrix4(matrixWorld);
              coords.push(new jsts.geom.Coordinate(newV.x, newV.y));
            });
            let geoFac = new jsts.geom.GeometryFactory();
            let linearRing = geoFac.createLinearRing(coords);

            //@ts-ignore
            let newPolygon = geoFac.createPolygon(linearRing, []).buffer(0);
            let center = new THREE.Vector3(newPolygon.getCentroid().getX(), newPolygon.getCentroid().getY(), 0);
            let c = this.sceneManager.getScreenPosition(center);
            let rect = s.getBoundingClientRect();
            s.style.top = c.y.toString() + 'px';
            s.style.left = c.x.toString() + 'px';
          });
        }
        else {
          s.style.visibility = 'hidden';
        }
      }
    }
  }

  componentDidMount = async () => {
    await this.settingData.getDataFromDB(); // 사용자 설정값 

    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();
    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.renderer.sortObjects = false;
    console.log("MY PLAN 1");

    this.mapManager.createMap(NaverLatLng(37.3595704, 127.105399), this.refs.map as HTMLElement);

    (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    console.log("MY PLAN 2");

    this.mapManager.addListener('zoom_changed', this.naverMapChanged);
    this.mapManager.addListener('center_changed', this.naverMapChanged);
    this.calculateMapProjectionData();
    console.log("MY PLAN 3");

    this.setState({
      userID: App.session.email,
    })
    this.animate();
    //    this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    //@ts-ignore
    if (this.props.location.state && this.props.location.state.file && this.props.location.state.file[0]) {
      //TODO this.load
      //@ts-ignore
      await this.loadDXFFileOutside(this.props.location.state.file[0]);
      window.addEventListener('keydown', (e) => {
        if (e.key === "F5") { // 새로고침시 파일 새로 열게
          //@ts-ignore
          this.props.location.state = undefined;
          //@ts-ignore
          this.props.history.push('/cad/myPlaneblock');
        }
      })
    }
  }

  componentDidUpdate = (previousProps: Readonly<MyPlaneProps>, previousState: Readonly<MyPlaneState>) => {

    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;
      let aspect = width / height;

      let frustumSize = this.sceneManager.orthoCamera.right;
      if (this.state.canvasAlpha !== 100 && !this.state.isCad) {
        frustumSize = this.getFrustumSizeWithMapSize();
      }

      this.sceneManager.CameraFrustumResize(frustumSize, aspect);
      this.sceneManager.renderer.setSize(width, height);
    }

    if (previousState.isCad !== this.state.isCad || previousState.canvasAlpha !== this.state.canvasAlpha) {
      if (this.state.isCad) {
        this.sceneManager.setRendererAlpha(1);
      }
      else {
        this.sceneManager.setRendererAlpha(this.state.canvasAlpha / 100);
      }
    }

    if (this.state.showBlock !== previousState.showBlock) {
      this.onWindowResize();
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {  
    if (this.sceneManager.canvasElement && this.mount) {
      this.mount!.removeChild(this.sceneManager.canvasElement);
    } 
  }

  loadDXFFileOutside = async (file: any) => {
    if (file) {
      this.loadFilebyFile([file]);
    }
  }

  onWindowResize = () => {
    this.setState({
      screenWidth: window.innerWidth - 640 * (this.state.showBlock ? 1 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 282 : 26) + (!this.state.loadFile ? 26 : 0),
    });
  }

  dataInitialize = () => {
    Object.values(this.parsingOutput).forEach(value => {
      while (value.length > 0) {
        value.splice(0, 1);
      }
    })

    while (this.blockBs.length > 0) {
      this.blockBs.splice(0, 1);
    }
    while (this.cores.length > 0) {
      this.cores.splice(0, 1);
    }
    while (this.houses.length > 0) {
      this.houses.splice(0, 1);
    }
    this.polygon2DGroup.children = [];

    this.bbox = new THREE.Box3();
    this.mapManager.clearAllPolygon();
    this.errorList.resetError();
    this.setState({
      layers: [],
      showBlock: false,
      clickedField: null,
      clickedBuildingPart: null,
      isSaved: false,
      loadFile: false,
      errorLog: false,
      errorSize: 0,
      warningSize: 0,
      infoSize: 0,
    })
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }
    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }
    while (this.errorLogs.length > 0) {
      this.errorLogs.splice(0, 1);
    }
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs)


  }


  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        this.saveBlockData();
        break;
      case 'b':
        this.parsingOutput.buildings.forEach(b => {
          App.stage !== "prod" && console.log(makeBlockBuildingData(b));
        })
        break;
      default:
        break;
    }
  }

  onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      default:
        break;
    }
  }

  checkBlockName = () => {
    // D숫자 이름 체크
    const buildings = this.parsingOutput.buildings;
    for (const building of buildings) {
      if (building.name.toUpperCase() === "F") {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg: `${building.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [],
          components: [...this.cores, ...this.houses],
        }));

        // this.loadFileErrorLogs.push(
        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `${building.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], {
        //     components: [...this.cores, ...this.houses],
        //   }
        //   ));
      }
      else if (!/^[F]\d+$/i.test(building.name)) {
        this.errorList.addError(new TypeError({
          title:  '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
          msg: `${building.name} 은(는) 배치안에 유효하지 않은 블록입니다.
          수정이 필요한 블록: ${building.name}`,
          type: ErrorType.Error,
          id: [building.uuid],
          components: [...this.cores, ...this.houses],
        }));

        // this.loadFileErrorLogs.push(makeErrorInformation2(
        //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        //   `${building.name} 은(는) 배치안에 유효하지 않은 블록입니다.
        //   수정이 필요한 블록: ${building.name}`,
        //   new THREE.Group(), [], {
        //   components: [...this.cores, ...this.houses],
        // }
        // ));
      }
    }

    for (const b of this.blockBs) {
      if (b.name.toUpperCase() === "B") {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg: `${b.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [b.uuid],
          components: [b.parts],
        }));
        // this.loadFileErrorLogs.push(
        //   makeErrorInformation2(`([블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `${b.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: b.parts }
        //   ));
      }
      else if (!/^[B]\d+$/i.test(b.name)) {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg:  `${b.name} 은(는) 배치안에 유효하지 않은 블록입니다.`,
          type: ErrorType.Error,
          id: [b.uuid],
          components: [b.parts],
        }));


        // this.loadFileErrorLogs.push(makeErrorInformation2(
        //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
        //   `${b.name} 은(는) 배치안에 유효하지 않은 블록입니다.
        //   수정이 필요한 블록: ${b.name}`,
        //   new THREE.Group(), [],
        //   //          {components: [b]}
        //   {
        //     components: b.parts,
        //   }
        // ));
      }
    }
    // C숫자 이름체크
    for (const core of this.cores) {
      let coreNameRight = true;
      let splitedName = core.name.toUpperCase().split('_');

      if (splitedName.length === 2) {
        if (splitedName[0].toUpperCase() === "C") {
          this.errorList.addError(new TypeError({
            title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
            msg: `${core.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
            type: ErrorType.Error,
            id: [core.uuid],
            components: [core],
          }));

          // core.ErrorLog.push(
          //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `${core.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          //     new THREE.Group(), [], { components: [core] }
          //   ));
        }
        else if (!/^[C]\d+$/i.test(splitedName[0])) {
          coreNameRight = false;
        }
        let coreArea = Number(core.name.split('_')[1]);
        if (isNaN(coreArea)) coreNameRight = false;
      }
      else {
        coreNameRight = false;
      }

      if (coreNameRight === false) {
        core.complete = CompletenessType.error;
        this.errorList.addError(new TypeError({
          title: '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
          msg:  `${core.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
          수정이 필요한 블록: ${core.name}`,
          type: ErrorType.Error,
          id: [core.uuid],
          components: [core],
        }));
      //   core.ErrorLog.push(makeErrorInformation2(
      //     '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
      //     `${core.name} 은(는) 유효하지 않은 블록입니다. 현재 블록은 C, U, B, F 만 유효합니다.
      //  수정이 필요한 블록: ${core.name}`,
      //     new THREE.Group(), [], {
      //     components: [core],
      //   }
      //   ));
      }
    }
    // H숫자 이름체크
    for (const house of this.houses) {
      let houseNameRight = true;

      let splitedName = house.name.split('_');
      if (splitedName[0] === "U") {
        this.errorList.addError(new TypeError({
          title: '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
          msg:  `${house.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house],
        }));

        // house.ErrorLog.push(
        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `${house.name}의 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: [house] }
        //   ));
      }
      else if (!/^[U]\d+$/i.test(splitedName[0])) { // H숫자가 아닌경우
        houseNameRight = false;
      }
      if (splitedName.length === 4) { // h1_숫자_숫자_숫자
        for (let i = 1; i < 3; i++) {
          if (Number(splitedName[i]) === NaN) { houseNameRight = false; break; }
        }
      }
      else if (splitedName.length === 2) { // h1_숫자
        let houseArea = splitedName[1];
        if (Number(houseArea) === NaN) houseNameRight = false;
      }
      else {
        houseNameRight = false;
      }

      if (houseNameRight === false) {
        house.complete = CompletenessType.error;
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg:  `${house.name} 은(는) 배치안에 유효하지 않은 블록입니다. 
          수정이 필요한 블록: ${house.name}`,
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house],
        }));
        // house.ErrorLog.push(makeErrorInformation2(
        //   '[블록 구조오류] 잘못된 블록 구조로 저장 할 수 없습니다. ',
        //   `${house.name} 은(는) 배치안에 유효하지 않은 블록입니다. 
        //   수정이 필요한 블록: ${house.name}`,
        //   new THREE.Group(), [], {
        //   components: [house],
        // }
        // ));
      }
      else if (house.name.toUpperCase() === "U") {
        this.errorList.addError(new TypeError({
          title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
          msg: `${house.name}의 블록 알파벳 뒤의 숫자가 누락되었습니다. `,
          type: ErrorType.Error,
          id: [house.uuid],
          components: [house],
        }));
        // house.ErrorLog.push(
        //   makeErrorInformation2(`[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`, `(블록 이름)의 블록 알파벳 뒤의 숫자가 누락되었습니다. `,
        //     new THREE.Group(), [], { components: [house] }
        //   ));
      }
    }

    // 그외
    this.notComponentBlocks.forEach(block => {
      this.errorList.addError(new TypeError({
        title: `[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.`,
        msg: `${block.name} 은(는) 배치안에 유효하지 않은 블록입니다.`,
        type: ErrorType.Error,
        id: [block.uuid],
        components: [block],
      }));
      // this.loadFileErrorLogs.push(makeErrorInformation2(
      //   '[블록 구조 오류] 잘못된 블록 구조로 저장 할 수 없습니다.',
      //   `${block.name} 은(는) 배치안에 유효하지 않은 블록입니다.
      //   수정이 필요한 블록: ${block.name}`,
      //   new THREE.Group(), [],
      // ));
    })
    return true;
  }



  loadFilebyFile = async (file: any) => {
    this.dataInitialize();

    this.setState({ loadingPage: true })
    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;
    this.fileData = data;
    //    this.polygon2DGroup.children = [];

    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }
    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }

    //    let layer = dataParsing(data);
    let parsingError: { errorNameLayers: Array<any>, pointError: Array<any> } = {
      errorNameLayers: [],
      pointError: []
    };

    let errorLayerList = { errorNameLayers: [], pointError: [] };
    this.block = blockParsing(data, this.parsingOutput, this.settingData.dataUnit, ConverterType.myPlane,this.errorList);

    // 점이 존재
    if (parsingError.pointError.length > 0) {
      parsingError.pointError.forEach(blockName => {
        this.errorList.addError(new TypeError({
          title: '[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.',
          msg: `${blockName} 의 불필요한 Point 가 자동 삭제 되었습니다.`,
          type: ErrorType.Info,
          id: [],
          components: [],
        }));
        // this.loadFileErrorLogs.push(makeInfoInformation('[자동 보정] 사용자 설정 값에 따라 자동보정 되었습니다.', 
        // `${blockName} 의 불필요한 Point 가 자동 삭제 되었습니다.`));
      })
    }
    
    this.parsingOutput.fields.forEach(f => {
      if (f.typeName === FieldType.site) {
        f.parts.forEach(p => {
          if (!p.unused) {
            const polygon = p.getJSTSPolygon(f.renderGroup.matrixWorld);
            const coords = polygon.getCoordinates();
            //@ts-ignore
            if (coords[0] !== coords[coords.length - 1] && polygon._geometries && polygon._geometries.length > 1) {
              this.errorList.addError(new SiteError({
                type: ErrorType.Error,
                title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
                msg: `${f.name}의 폭이 충분하지 않습니다.`,
                id: [f.uuid],
                targetFields: [f],
              }))
            }
          }
        })
      }
    })


    // //! START
    { //* 블럭 구조 체크
      // 블럭 구조 B, D가 없는 경우 & blockBs 값 셋팅
      const isBlockStructOK = checkBlockStruct(this.parsingOutput.buildings, this.cores, this.houses, this.loadFileErrorLogs, this.errorList);

      if (isBlockStructOK) {
        this.parsingOutput.buildings.forEach(blockD => {

          blockD.parts.forEach(blockB => {
            this.blockBs.push(blockB);

            blockB.parts.forEach((component) => {
              //@ts-ignore
              if (component !== undefined && component.componentType === "core") {
                this.cores.push(component as BuildingCoreUnit);
                this.errorLogs.push((component as BuildingCoreUnit).ErrorLog);
              }
              //@ts-ignore
              else if (component !== undefined && component.componentType === "house") {
                this.houses.push(component as BuildingHouseUnit);
                this.errorLogs.push((component as BuildingHouseUnit).ErrorLog);
              }
              else {
                this.notComponentBlocks.push(component);
              }
            })
          })
        })

        // 전용면적만 들어온경우
        this.block.forEach(block => {
          let splitedName = block.name.toUpperCase().split('_');
          if (block.type === "house" && (/^[U](\d+)[_](\d+)$/i.test(block.name) || /^[U]\d[_](\d*)[.](\d*)$/i.test(block.name))) { // 'u_정수', 'u_소수'의 경우
            this.errorList.addError(new TypeError({
              title: `[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
              msg: `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
              type: ErrorType.Info,
              id: this.houses.filter(h => h.name === block.name).map(h => h.uuid),
              components: this.houses.filter(h => h.name === block.name)
            }));
            
            // this.loadFileErrorLogs.push(makeInfoInformation(`[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.`,
            //   `${block.name}의 서비스 면적/벽체 공용 면적이 누락 되어 ‘0’으로 대체되었습니다. `,
            //   new THREE.Group(), [], {
            //   components: this.houses.filter(h => h.name === block.name)
            // }
            // ));
          }
        })

        // 블럭 이름 체크  
        this.checkBlockName();
        // 레이어 이름 체크
        checkLayerNameError([...this.houses, ...this.cores, ...this.parsingOutput.buildings, ...this.blockBs], this.errorList)

        // 유효 레이어에 존재하는 점: 알람
        errorLayerList.pointError.forEach(layerName => {
          this.errorList.addError(new TypeError({
            title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
            msg:  `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `,
            type: ErrorType.Error,
            id: [],
            components: [],
          }));
          // this.loadFileErrorLogs.push(makeInfoInformation('[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
          //   `${layerName} 의 불필요한 Point 가 자동 삭제 되었습니다. `, new THREE.Group()))
        })
        // this.setState({
        //   blocks: this.block
        // })
      }
    }
    [...this.cores, ...this.houses, ...this.blockBs, ...this.parsingOutput.buildings].forEach(block => {
      this.polygon2DGroup.add(block.ErrorPolygonGroup);

    })
    this.parsingOutput.fields.forEach(field => this.polygon2DGroup.add(field.ErrorPolygonGroup))
    ////////////////////////


    //* 사어여역 에러체크
    checkSiteError(this.parsingOutput, this.loadFileErrorLogs, ConverterType.myPlane, this.errorList);
    // * 동평면
    checkTypeError(this.loadFileErrorLogs,
      {
        buildings: this.parsingOutput.buildings,
        blockBs: this.blockBs,
        cores: this.cores,
        houses: this.houses,
      }, this.settingData.dataUnit, this.errorList);

    this.recalculateArea();


    // 완료된 컴포넌트에 면 표현
    {
      let globalErrorCnt = 0;
      let loadFileErrorCnt = 0;
      this.globalErrorLogs.forEach(log => { if (log.Type === ErrorType.Error) globalErrorCnt++; })
      this.loadFileErrorLogs.forEach(log => { if (log.Type === ErrorType.Error) loadFileErrorCnt++; })

      this.cores.forEach(core => {
        core.CheckCompleteness(this.errorList);
        // if (globalErrorCnt === 0 && core.ErrorLog.filter(core => core.Type === ErrorType.Error).length === 0 && loadFileErrorCnt === 0) {
        //   core.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
        // }
      });
      this.houses.forEach(house => {
        house.getHouseError(this.errorList);
        house.CheckCompleteness(this.errorList);
        // if (globalErrorCnt === 0 && house.ErrorLog.filter(house => house.Type === ErrorType.Error).length === 0 && loadFileErrorCnt === 0) {
        //   house.polygon.forEach(poly => { if (poly.layer.toUpperCase() === "CON") poly.innerMesh.visible = true; })
        // }
      });
    }

    this.parsingOutput.fields.forEach(f => {
      switch (f.typeName) {
        case FieldType.site:
          this.state.field.site.push(f);
          break;
        case FieldType.road:
          this.state.field.road.push(f);
          break;
        case FieldType.centerLineOfRoad:
          this.state.field.roadCenterLine.push(f);
          break;
        case FieldType.vacancyInside:
          this.state.field.vacancyInside.push(f);
          break;
        case FieldType.vacancyOutside:
          this.state.field.vacancyOutside.push(f);
          break;
        case FieldType.topography:
          this.state.field.topography.push(f);
          break;
      }
    })


    this.errorLogs.forEach(errors => {
      errors.forEach(error => {
        if (!this.classifyLogs.get(error.Information)) this.classifyLogs.set(error.Information, [error]);
        else this.classifyLogs.set(error.Information, this.classifyLogs.get(error.Information).concat(error));
      })
    })

    this.setState({
      warningSize: this.errorList.getWarningSize(),
      errorSize: this.errorList.getErrorSize(),
      infoSize: this.errorList.getInfoSize(),
    })

    if (this.state.errorSize > 0 || this.state.warningSize > 0 || this.state.infoSize > 0) {
      this.setState({ errorLog : true});
    }
    // ////////////////////////

    this.polygon2DGroup.children = [];
    this.siteFields = [];
    this.roadFields = [];
    this.vacancyInsideFields = [];
    this.vacancyOutsideFields = [];
    this.Buildings = [];
    this.deleteNaverPolygon();

    App.stage !== "prod" && console.log(this.parsingOutput);
    this.parsingOutput.buildings.forEach(b => {
      this.polygon2DGroup.add(b.renderGroup);
    })
    this.parsingOutput.fields.forEach(field => {
      this.polygon2DGroup.add(field.ErrorPolygonGroup);
    })



    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.RebuildOutputPolygon();
        p.UpdateArea();
      })
    })

    this.parsingOutput.fields.forEach(f => {
      this.polygon2DGroup.add(f.renderGroup);
    })

    this.bbox.makeEmpty();
    this.bbox.setFromObject(this.polygon2DGroup);
    let center = new THREE.Vector3();
    this.bbox.getCenter(center);

    let frustumSize = (this.bbox.max.x - this.bbox.min.x) / 2 * 1.1;
    if (this.mount!.scrollHeight < this.mount!.scrollWidth) {
      let height = (this.bbox.max.y - this.bbox.min.y) / 2 * 1.1;
      frustumSize = height / this.mount!.scrollHeight * this.mount!.scrollWidth;
    }

    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.CameraFrustumResize(frustumSize, aspect);

    this.sceneManager.orthoCamera.position.set(center.x, center.y, 1);
    this.sceneManager.orthoControl.target.set(center.x, center.y, 0);
    this.sceneManager.orthoCamera.zoom = 1;


    this.setState({
      // layers: layer,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadingPage: false,
      loadFile: true,
      showBlock: true,

    }, async () => {
      this.onWindowResize();
      // if (this.settingData.autoSetting) {
      //   this.autoSetPlan();
      // }
      let sitePolygon: any;
      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0);
          this.setState({
            siteArea: Number(sitePolygon!.getArea().toFixed(2)),
          })

        }
      });
      await this.AutoAdd2Map();
    })
    this.setState({
      errorLogs: this.errorList.getError(),
    })

  }

  AutoAdd2Map = async () => {

    if (this.parsingOutput.fields.length > 0) {
      let sitePolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      let unionPolygon = this.parsingOutput.fields.filter(f => f.typeName === FieldType.site)[0].getUnionJstsPolygon();
      // let center = new THREE.Vector2(0);
      let RoadLatlngs: any[] = [];
      let SiteLatlngs: any[] = [];
      let centerRoadlatlngs: any[] = [];
      let outsideLatLngs: any[] = [];
      let insideLatLngs: any[] = [];
      let topographyLatLngs: any[] = [];

      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site) {
          //@ts-ignore
          sitePolygon = f.getUnionJstsPolygon()!.buffer(0);
          // let cPoint = sitePolygon.getCentroid();
          // center.set(cPoint.getX(), cPoint.getY());
          //@ts-ignore
          unionPolygon = f.getUnionJstsPolygon()!.buffer(0.1);
          SiteLatlngs = SiteLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          RoadLatlngs = RoadLatlngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          insideLatLngs = insideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          outsideLatLngs = outsideLatLngs.concat(f.getLatLngList());
        }
        else if (f.typeName === FieldType.centerLineOfRoad && !f.unused) {
          centerRoadlatlngs = centerRoadlatlngs.concat(f.getLatLngList());
          f.parts.forEach(p => {
            this.centerOfRoadWKT = jstsPolygontoWKT(p.getJSTSPolygon(matrix));
          })
        }
        else if (f.typeName === FieldType.topography) {
          topographyLatLngs = topographyLatLngs.concat(f.getLatLngList());
        }
      })
      // this.parsingOutput.fields.forEach(f => {
      //   //@ts-ignore
      //   if (f.getUnionJstsPolygon()){
      //     //@ts-ignore
      //     unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
      //   }
      // })



      this.mapManager.setNaverSitePolygon(SiteLatlngs);
      this.mapManager.setNaverRoadPolygon(RoadLatlngs);
      this.mapManager.setNaverVacancyInsidePolygon(insideLatLngs);
      this.mapManager.setNaverVacancyOutsidePolygon(outsideLatLngs);
      this.mapManager.setNaverTopographyPolygon(topographyLatLngs);
      this.parsingOutput.fields.forEach(f => {
        if (f.typeName !== FieldType.vacancyInside && f.typeName !== FieldType.topography && f.getUnionJstsPolygon()) {
          //@ts-ignore
          unionPolygon = unionPolygon!.union(f.getUnionJstsPolygon()!.buffer(0.1));
        }
      })

      // let centerInLL = tm2latlng(center);
      // this.mapManager.setMapCenter(centerInLL.x, centerInLL.y);

      // const envelope = unionPolygon.getEnvelopeInternal();
      // let centerX = (envelope.getMinX() + envelope.getMaxX()) / 2;
      // let centerY = (envelope.getMinY() + envelope.getMaxY()) / 2;


      // const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      // this.mapManager.setMapCenter(centerCoord.x, centerCoord.y);


      if (centerRoadlatlngs.length === 0) {
        try {
          this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
          centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
          //* test code
          //@ts-ignore
          let coordinates = wkx.Geometry.parse(this.centerOfRoadWKT).toGeoJSON().coordinates; // 두개
          let newC = coordinates.map((coords: any) => {
            return coords.map((coord: any) => {
              return tm2latlng(new THREE.Vector2(coord[0], coord[1]));
            })
          })
          this.mapManager.setCenterofRoadPolygon(newC);
        }
        catch (e) {
          App.stage !== "prod" && console.log('auto add error', e)
        }

        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
        // this.centerOfRoadWKT = await getRoadLine([jstsPolygontoWKT(sitePolygon!), jstsPolygontoWKT(unionPolygon!)]);
        // centerRoadlatlngs = wkt2LatLngs(this.centerOfRoadWKT, new THREE.Vector3(0), new THREE.Vector2(0));
      }
      else {
        this.mapManager.setCenterofRoadPolygon(centerRoadlatlngs);
      }
      let centerX = (this.bbox.max.x + this.bbox.min.x) / 2;
      let centerY = (this.bbox.max.y + this.bbox.min.y) / 2;

      const centerCoord = tm2latlng(new THREE.Vector2(centerX, centerY));
      this.mapManager.setMapCenter(centerCoord.x, centerCoord.y);

      this.setState({
        siteArea: Number(sitePolygon!.getArea().toFixed(2)),
      })
    }
  }
  setCenterOfRoad = (value: string) => {
    this.centerOfRoadWKT = value;
  }

  saveBlockData = async () => {
    try {
      let fn = this.state.saveName === '' ? 'my_building_plan' : this.state.saveName;
      //   this.showModal('알림', ['나의 배치안을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));
      let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_plan_id", this.state.userID);

      let imageName = `img_large.png`;
      let shapeInfoName = 'shapeInfo.json';
      let resultLocationName = 'resultLocation.json';
      let resultJsonName = 'resultJson.json';
      let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

      await this.captureImage(imageName, S3SavePath);

      this.calculateMapProjectionData();

      //shape infomation
      let projectSiteWKT: string[] = [];
      let roadSiteWKT: string[] = [];
      let vacancyInsideWKT: string[] = [];
      let vacancyOutsideWKT: string[] = [];
      let topographyLines: { polygon: string[], height: number }[] = [];
      let topographyLinesPlatform: { polygon: string[], height: number }[] = [];

      this.parsingOutput.fields.forEach(f => {
        let matrix = f.renderGroup.matrixWorld;
        if (f.typeName === FieldType.site && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              projectSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.road && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              roadSiteWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.vacancyInside && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              vacancyInsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.vacancyOutside && !f.unused) {
          f.parts.forEach(p => {
            if (!p.unused)
              vacancyOutsideWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
        }
        else if (f.typeName === FieldType.topography && !f.unused) {
          let topographyWKT: string[] = [];

          f.parts.forEach(p => {
            if (!p.unused)
              topographyWKT.push(jstsPolygontoWKT(p.getJSTSPolygon(matrix)));
          })
          topographyLinesPlatform.push({polygon: [topographyWKT[0]], height: f.getHeight()});
          topographyLines.push({polygon: [...topographyWKT], height: f.getHeight()})
        }
      })
      let shapeInfo: shapeInfoData = {
        boundaryLine: polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment),
        projectSite: projectSiteWKT,
        projectSiteArea: this.state.siteArea,
        projectSiteRoad: [], //[''],
        road: roadSiteWKT,
        centerOfRoad: [this.centerOfRoadWKT],
        setbackLineApartment: [], // [''],
        setbackLineMultiHouse: [], //[''],
        setbackLineOfficetel: [], // [''],
        setbackLineRowHouse: [], //[''],
        skylineCircle: [], //[''],
        skylineLine: [], // [''],
        vacancyInsie: vacancyInsideWKT,
        vacancyOutsie: vacancyOutsideWKT,
        topographyLines: topographyLines,//topographyWKT,//[],
      }
      App.stage !== "prod" && console.log(shapeInfo);
      saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
      saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

      // result location
      let buildingData: buildingData[] = [];
      let building_Stories_Avg = blockBuildingStoriesAvg(this.parsingOutput.buildings);// buildingStoriesAvg(this.Buildings);
      let floorAreaRatio = Number(((this.state.totalGroundArea / this.state.siteArea) * 100).toFixed(12));
      let coverAreaRatio = Number(((this.state.totalBuildingArea / this.state.siteArea) * 100).toFixed(12));
      let totalHousehold = getBlockTotalHouseHold(this.parsingOutput.buildings);
      let cost = Math.floor(this.state.siteArea * floorAreaRatio * 0.0001 * 1450167);
      let areaProportion = calculateBlockAreaProPortion(this.parsingOutput.buildings);

      let lMax = 0;
      let lMin = 10000;
      this.parsingOutput.buildings.forEach(b => {
        b.parts.forEach(p => {
          p.parts.forEach(pp => {
            if (pp.buildingType === 'component') {
              lMax = Math.max((pp as BuildingComponent).level.length, lMax);
              lMin = Math.min((pp as BuildingComponent).level.length, lMin);
            }
          })
        })
      })

      this.parsingOutput.buildings.forEach(b => {
        buildingData.push(makeBlockBuildingData(b));
      })

      let templates: { [key: string]: building } = {};
      let buildingIndex = 0;
      buildingData.forEach(b => {
        // b.position = this.sceneManager.getPointToMap(b.position, this.mapProj);
        b.name = `building${buildingIndex}`;
        b.building.name = `building${buildingIndex}`;
        templates[b.building.name] = b.building;
        buildingIndex++;
      });

      let resultLocation: resultLocation = {
        buildingNumber: this.parsingOutput.buildings.length,
        areaProportion: areaProportion,
        buildingStoriesAvg: [building_Stories_Avg.AREA, building_Stories_Avg.HOUSE, building_Stories_Avg.NUMERICAL],
        buildingStoriesMax: lMax,
        buildingStoriesMin: lMin,
        buildings: buildingData,
        constructionCost: cost,
        coverAreaRatio: coverAreaRatio,
        exclusionRatio: 0,
        floorAreaRatio: floorAreaRatio,
        templateList: templates,
        totalHousehold: totalHousehold,
        topographyLines: topographyLines,
        //TODO 성절토
      }
      App.stage !== "prod" && console.log(resultLocation);
      saveDataToS3(JSON.stringify(resultLocation), S3SavePath, resultLocationName, 'application/json');

      //result --> dynamoDB
      let date = new Date().toISOString();
      let housingTypeRatio: housing_type_ratio[] = [];
      areaProportion.forEach(ap => {
        housingTypeRatio.push({
          area: ap.housingPlanTypeArea,
          bay: ap.numberOfBay,
          proportion: Math.floor(ap.housingPlanTypeProportion * 10000) / 100,
        })
      })

      let baseReportFilePath: base_report_file_path = {
        analysisReport: '',
        cad: '',
        constructionReport: '',
        exclusiveReport: '',
        lightReport: '',
        resImage: '',
        viewReport: '',
      }

      let baseResultFilePath: base_result_file_path = {
        light: '',
        location: '',
        view: ''
      }

      let resultJson: resultS3Json = {
        base_created_at: date,
        base_project_id: 0,
        base_report_file_path: baseReportFilePath,
        base_report_id: 0,
        base_result_status: 0,
        base_stage: 'dev',
        base_user_project_id: 0,
        base_uuid: '',
        base_result_file_path: baseResultFilePath,
        daylight_hours_avg: 0,
        daylight_hours_min: 0,
        daylight_hours_mode: 0,
        daylight_hours_proportion_less_n_hours: [100],
        loc_building_land_ratio: 0,
        loc_building_number: 0,
        loc_building_stores_avg: building_Stories_Avg,
        loc_building_stories_max: lMax,
        loc_building_stories_min: lMin,
        loc_construction_cost: cost,
        loc_exclusive_ratio_avg: 0,
        loc_floor_area_ratio: floorAreaRatio,
        loc_housing_type_ratio: housingTypeRatio,
        loc_my_building_type_ratio: [],
        loc_total_household: totalHousehold,
        view_point_avg: 0,
        view_point_skew: 0,
        view_skyarea_avg: 0,
        view_skyarea_min: 0,
        view_skyarea_mode: 0,
        view_skyarea_proportion_less_n_percent: [100],
      }
      App.stage !== "prod" && console.log(resultJson);
      saveDataToS3(JSON.stringify(resultJson), S3SavePath, resultJsonName, 'application/json');
      let uuid = uuid4();
      let buildingTemplateName = `${uuid}.json`;
      // let bufferSitePolygon = polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment);



      let dbItem: buildingPlanStruct = {
        stage: App.tempStage,//App.stage,
        global_id: globalID,
        user_id: privateID,
        email: this.state.userID,
        name: fn,
        shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
        resultJson: `s3://${S3SavePath}/${resultJsonName}`,
        resultLocation: `s3://${S3SavePath}/${resultLocationName}`,
        img_path: `s3://${S3SavePath}/${imageName}`,
        address: await getAddressByProjectSite(projectSiteWKT),
        project_site_area: this.state.siteArea,
        project_site: projectSiteWKT,
        road_site_area: this.state.roadArea,//0,//getFieldsArea(this.roadFields),
        road_site: roadSiteWKT,
        vacancy_outside_area: this.state.vacancyOusideArea,//0,//getFieldsArea(this.vacancyOutsideFields),
        vacancy_outside: vacancyOutsideWKT,
        vacancy_inside_area: this.state.vacancyInsideArea,//0,//getFieldsArea(this.vacancyInsideFields),
        vacancy_inside: vacancyInsideWKT,
        building_number: this.parsingOutput.buildings.length,// Buildings.length,
        total_building_area: this.state.totalBuildingArea,
        total_floor_area: this.state.totalGroundArea,
        average_exclusive_area: this.state.exclusiveAverageArea,
        cover_area_ratio: coverAreaRatio,
        floor_area_ratio: floorAreaRatio,
        created_at: date,
        modified_at: date,
        deleted: false,
        centerOfRoad: [this.centerOfRoadWKT],
        topography_lines: topographyLinesPlatform,


        // total_exclusive_area: Number(this.state.totalExclusiveAreas),
        // total_service_area: Number(this.state.totalServiceArea),
        // core_area: Number(this.state.totalCoreArea),
        // building_area: Number(this.state.totalBuildingArea), // buildingarea
        // floor_area: Number(this.state.totalGroundArea), // groundarea
        // common_wall_area: Number(this.state.totalCommonWallArea), // 
        // houses_number: this.state.houseNumber,

        // file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
        // meta_path: `s3://${S3SavePath}`,
      };

      App.stage !== "prod" && console.log(dbItem);
      saveDataToDynamoDB(dbItem, this.DBTableName);
      this.setState({ isSaved: true });
      // this.showModal('알림', ['나의 배치안을 저장했습니다.'], buttonNum.oneButton, () => {App.stage !== "prod" && console.log('save done')});
      return true;
    }
    catch (e) {
      // this.showModal('알림', ['나의 배치안을 저장에 실패 했습니다.'], buttonNum.oneButton, () => {
      //   App.stage !== "prod" && console.log('save fail', e);
      // })
      return false;
    }
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
  }

  addNewField = (type: FieldType) => {
    let uuid = uuid4();
    switch (type) {
      case FieldType.site:
        if (this.siteFields.length > 0) {
          this.showModal('알림', ['사업영역은 하나만 허용합니다.'], buttonNum.oneButton, () => { App.stage !== "prod" && console.log('enable only one site layer')});
          break;
        }
        else {
          this.siteFields.push(new Field(`대지영역 ${this.siteFields.length}`, FieldType.site))
        }
        break;
      case FieldType.road:
        this.roadFields.push(new Field(`도로영역 ${this.roadFields.length}`, FieldType.road))
        break;
      case FieldType.vacancyOutside:
        this.vacancyOutsideFields.push(new Field(`공지영역 ${this.vacancyOutsideFields.length}`, FieldType.vacancyOutside))
        break;
      case FieldType.vacancyInside:
        this.vacancyInsideFields.push(new Field(`특수영역 ${this.vacancyInsideFields.length}`, FieldType.vacancyOutside))
        break;
      default:
        break;
    }

    this.setState({
      settingID: uuid,
      showBlock: true, //false,
    })

    this.recalculateArea();
  }

  // addBuildings = (name: string = `동평면 ${this.Buildings.length + 1}`, hideList = false) => {
  //   this.Buildings.push(MakeANewBuilding(name, hideList));
  //   this.setState({ buildings: this.Buildings });
  // }

  textInputKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        this.mapManager.searchAddressToCoordinate(this.state.address);
        e.currentTarget.blur();
        break;

      default:
        break;
    }
  }

  getFrustumSizeWithMapSize = () => {
    let rect = this.mount!.getBoundingClientRect();
    let p1 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(0, rect.top));
    let p2 = this.mapProj.projection.fromPageXYToCoord(NaverPoint(this.state.screenWidth, rect.top));
    return this.mapProj.projection.getDistance(p1, p2) / 2;
  }

  resizeCanvasResolution = () => {
    let aspect = this.mount!.scrollWidth / this.mount!.scrollHeight;
    this.sceneManager.orthoCamera.zoom = 1;
    // let width = this.getFrustumSizeWithMapSize();
    // width = this.areaBias !== 1 ? width / this.areaBias : width;

    this.sceneManager.CameraFrustumResize(this.getFrustumSizeWithMapSize(), aspect);
  }

  naverMapChanged = () => {
    this.areaBias = 1;
    this.makedAreaBias = false;
    this.resizeCanvasResolution();
  }

  canvasModeChanged = (_isCAD: boolean) => {
    if (_isCAD) {
      this.sceneManager.getControl().enableKeys = true;
      this.sceneManager.canvasElement.style.pointerEvents = '';
      (this.refs.map as HTMLDivElement).style.visibility = 'hidden';
    }
    else {
      this.mapManager.getMap().autoResize();

      this.sceneManager.getControl().enableKeys = false;
      this.sceneManager.canvasElement.style.pointerEvents = 'none';
      (this.refs.map as HTMLDivElement).style.visibility = 'visible';
      this.mapManager.createDrawingManager();
      this.resizeCanvasResolution();
    }

    this.setState({
      isCad: _isCAD,
    });
  }

  mapTypeChanged = (isCadastral: boolean) => {
    this.mapManager.changeMapType(isCadastral);

    this.setState({
      isCadastral: isCadastral
    })
  }

  recalculateArea = () => {

    let totalBuildingArea = 0;
    let totalGroundArea = 0;
    let exclusiveAverageArea = 0;

    let totalExclusiveAreas = 0;

    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.pop();
    }

    let siteArea = 0;
    let roadArea = 0;
    let vacancyInsideArea = 0;
    let vacancyOusideArea = 0;


    this.parsingOutput.fields.forEach(f => {


      switch (f.typeName) {
        case FieldType.site:
          siteArea += f.getArea();

          let splited = f.name.split('_');
          if (splited.length >= 2 && !isNaN(Number(splited[1]))) {
            let inputArea = Number(Number(splited[1]).toFixed(4))
            if (Number(f.calcArea.toFixed(4)) !== inputArea) { // .toFixed(2)
              if (f.parts[0].shape && !f.parts[0].polygon.hasCurve) {
                
                this.errorList.addError(new SiteError({
                  title: `[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
                  msg: `${f.name} 에 사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다.
                  폴리곤 전체 입력면적: ${inputArea}㎡, 계산 면적: ${f.calcArea.toFixed(4)}㎡, 
                  차이: ${Math.abs(Number(inputArea) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`,
                  type: ErrorType.Warning,
                  id: [f.uuid],
                  targetFields: [f],
                }));


              //   this.globalErrorLogs.push(makeWarningInformation2(
              //     `[면적 오류] 저장 시 입력 면적으로 반영 됩니다. 원치 않을 시 수정 후 다시 진행해주세요.`,
              //     `${f.name} 에 사용자가 입력한 면적과 실제 폴리곤 면적이 서로 상이합니다.
              // 폴리곤 전체 입력면적: ${inputArea}㎡, 계산 면적: ${f.calcArea.toFixed(4)}㎡, 
              // 차이: ${Math.abs(Number(inputArea) - Number(f.calcArea.toFixed(4))).toFixed(4)}㎡`
              //     , new THREE.Group(),
              //     undefined, undefined, { field: [f], fields: this.parsingOutput.fields }));
              }

            }
          }
          break;
        case FieldType.road:
          roadArea += f.getArea();
          break;
        case FieldType.vacancyInside:
          vacancyInsideArea += f.getArea();
          break;
        case FieldType.vacancyOutside:
          vacancyOusideArea += f.getArea();
          break;
        default:
          break;
      }
    })

    // let error = this.state.errorSize, warning = this.state.warningSize, info = this.state.infoSize;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     console.log(el)

    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) warning++;
    //     if (el.Type === ErrorType.Info) info++;
    //   })
    // })
    // console.log(error, warning, info)

    this.setState({
      siteArea: siteArea,
      roadArea: roadArea,
      vacancyInsideArea: vacancyInsideArea,
      vacancyOusideArea: vacancyOusideArea,
      // errorSize: error,
      // warningSize: warning,
      // errorLog: error + warning + info > 0 ? true : false,
    })

    let msg = `[면적 오류] 저장 시 입력 면적으로 반영 됩니다.`;
    // this.classifyLogs.set(msg, [])
    // if (!this.classifyLogs.get(error.Information)) this.classifyLogs.set(error.Information, [error]);
    // else this.classifyLogs.set(error.Information, this.classifyLogs.get(error.Information).concat(error));

    // 건축 면적
    this.houses.forEach(h => {
      totalBuildingArea += h.exclusiveArea + h.serviceArea;
    })
    this.cores.forEach(c => {
      totalBuildingArea += c.GetArea();
    })

    // 바닥 면적
    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.UpdateArea();
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
      })
    })


    // this.Buildings.forEach(b => {
    //   b.cores.forEach(c => {
    //     let l = 1;
    //     let p = 100;
    //     c.houses.forEach(h => {
    //       totalGroundArea += h.exclusiveArea * (h.level.length - h.piloti);
    //       l = Math.max(l, h.level.length);
    //       p = Math.min(p, h.piloti);
    //     })
    //     totalGroundArea += c.area * (l - p);

    //     if (p > 0)
    //       totalGroundArea += c.area;
    //   })
    // })

    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();

    this.setState({
      errorSize: error,
      warningSize: warning,
      infoSize: info,
      //   siteArea: getFieldsArea(this.siteFields),
      totalExclusiveAreas: totalExclusiveAreas.toFixed(4),
      totalCoreArea: totalCoreAreas.toFixed(4),
      totalBuildingArea: totalBuildingArea,
      totalGroundArea: totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas,//totalGroundArea,
      exclusiveAverageArea: Number((totalExclusiveAreas / houseNumber).toFixed(4)),//Number(exclusiveAverageArea.toFixed(2)),
    })


  }

  recalculateResult = () => {
    let totalExclusiveAreas = 0;

    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;
    let houseNumber = 0;

    this.parsingOutput.buildings.forEach(b => {
      b.parts.forEach(p => {
        p.UpdateArea();
        totalExclusiveAreas += p.totalExclusiveAreas;
        totalServiceAreas += p.totalServiceAreas;
        totalCommonWallAreas += p.totalCommonWallAreas;
        totalCoreAreas += p.totalCoreAreas;
        houseNumber += p.houseNumber;
      })
    })

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }
    // let error = 0, waring = 0, info = 0;
    // this.errorLogs.forEach(els => {
    //   els.forEach(el => {
    //     if (el.Type === ErrorType.Error) error++;
    //     if (el.Type === ErrorType.Warning) waring++;
    //     if (el.Type === ErrorType.Info) info++;
    //   })
    // })
    const error = this.errorList.getErrorSize();
    const warning = this.errorList.getWarningSize();
    const info = this.errorList.getInfoSize();
    
    
    this.setState({

      totalExclusiveAreas: totalExclusiveAreas.toFixed(4),
      totalServiceArea: totalServiceAreas.toFixed(4),
      totalCoreArea: totalCoreAreas.toFixed(4),
      totalCommonWallArea: totalCommonWallAreas.toFixed(4),
      buildingArea: (totalExclusiveAreas + totalServiceAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      groundArea: (totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas).toFixed(4),
      houseNumber: houseNumber,
      errorSize: error,
      warningSize: warning,
      infoSize: info,
      errorLog: error + warning + info > 0 ? true : false,
    })
  }

  captureImage = async (imageName: string, savePath: string) => {
    // 선택된것만 켜기
    // await new Promise((resolve, reject) => {
    //   this.state.layers.forEach(l => {
    //     let visible = false;
    //     if (l.selected)
    //       visible = true;
    //     l.polygons.forEach(p => {
    //       p.lineMesh.visible = visible;
    //     })
    //   })
    //   resolve(0);
    // })

    let captureBbox = new THREE.Box3();
    this.parsingOutput.buildings.forEach(b => {
      captureBbox.expandByObject(b.renderGroup);
    })
    this.parsingOutput.fields.forEach(f => {
      captureBbox.expandByObject(f.renderGroup);
    })
    // this.siteFields.forEach(l => {
    //   l.getLayer()!.polygons.forEach(p => {
    //     if (p.selected) {
    //       p.vertices.forEach(v => {
    //         captureBbox.expandByPoint(v);
    //       })
    //     }
    //   })
    // })

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    App.stage !== "prod" && console.log('save image');
    saveDataToS3(lImgBuf, savePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, savePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, savePath, 'img_small.png', 'image/png');

    // 전체 켜기
    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })
  }

  getBuildingHight = async (build: building, pos: THREE.Vector3) => {
    let lambda = await new AWSModule("LAMBDA").connect();

    let points: THREE.Vector3[] = [];
    build.outline.forEach(o => {
      o.node.data.forEach(d => {
        points.push(pos.clone().sub(new THREE.Vector3(d.x, d.y, 0)));
      })
    })

    let tmPoints = this.sceneManager.getPointArrayToMap(points, this.mapProj);

    let heights: number[] = [];
    tmPoints.forEach(async p => {
      await lambda.Lambda!.invoke({
        FunctionName: "buildit-public-platform-GetDemHeight-v20200410",
        Payload: JSON.stringify({
          x: p.x,
          y: p.y,
        })
      }).promise().then(r => {
        heights.push(JSON.parse(r.Payload as string).body.value * 0.1);
      });
    });

    let maxHeight = 0;
    heights.forEach(h => {
      maxHeight = maxHeight < h ? h : maxHeight;
    });
    return maxHeight;
  }

  calculateMapProjectionData() {
    // this.mapProj.sceneOffset = this.mount!.getBoundingClientRect();
    this.mapProj.projection = this.mapManager.getProjection();
    if (this.basePosition) {
      let origin = this.sceneManager.getLatLonPosition(this.siteFields[0].getLayer()!.polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj);
      this.mapProj.mapOffset = new THREE.Vector2(this.basePosition.x, this.basePosition.y).sub(new THREE.Vector2(origin.x, origin.y));
    }
    else {
      this.mapProj.mapOffset = new THREE.Vector2(0, 0);
    }
  }

  checkAllBuilding = (names: string[]) => {
    this.Buildings.forEach(b => {
      let coreHouses = 0;
      b.cores.forEach(c => {
        coreHouses += c.houses.length;
        if (!c.CheckCompleteness()) {
          names.push(b.name + '_' + c.name);
        }
      })

      if (coreHouses > b.houses.length) {
        names.push(b.name);
      }

      // b.houses.forEach(h => {
      //   if (!h.checkCompleteness()) {
      //     names.push(b.name + '_' + h.name);
      //   }
      // })
    });

    if (names.length === 0) {
      return true;
    }
    else {
      return false;
    }
  }



  // saveToAWS = async () => {
  //   let names: string[] = [];
  //   // let finished = this.checkAllBuilding(names);
  //   // if (!finished) {
  //   //   this.showModal('알림', `${names.join(', ')}에 잘못된 입력값이 존재합니다.`, buttonNum.oneButton, () => console.log('not complate'));
  //   //   return;
  //   // }

  //   // if (!this.mapProj.projection || !this.basePosition) {
  //   //   this.showModal('알림', ['사업영역을 맵에 표시 해 주세요.'], buttonNum.oneButton, () => console.log('add site to map'));
  //   //   return;
  //   // }

  //   // if (this.roadFields.length < 1 || getFieldsArea(this.roadFields) <= 0) {
  //   //   this.showModal('알림', ['최소 하나의 도로영역이 선택되어야 하며, 면적은 0㎡ 보다 커야합니다.'], buttonNum.oneButton, () => console.log('no road polygon'));
  //   //   return;
  //   // }

  //   let fn = this.state.saveName === '' ? 'my_building_plan' : this.state.saveName;
  //   // if (checkSpecialSymbolInName(fn)) {
  //   //   this.showModal('알림', ['제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.'], buttonNum.oneButton, () => console.log('special symbol'));
  //   //   return;
  //   // }

  //   // let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
  //   // if (!checkedName) {
  //   //   this.showModal('알림', ['이미 같은 제목을 가진 나의 배치안이 존재합니다.'], buttonNum.oneButton, () => console.log('exist name'));
  //   //   return;
  //   // }

  // //  this.showModal('알림', ['나의 배치안을 저장 중입니다.'], buttonNum.noButton, () => console.log('saving'));

  //   // let id = '0'; //uuid4();
  //   let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_plan_id", this.state.userID);

  //   let imageName = `img_large.png`;
  //   let shapeInfoName = 'shapeInfo.json';
  //   let resultLocationName = 'resultLocation.json';
  //   let resultJsonName = 'resultJson.json';
  //   let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

  //   await this.captureImage(imageName, S3SavePath);

  //   this.calculateMapProjectionData();

  //   //shape infomation
  //   let projectSiteWKT: string[] = [];
  //   let roadSiteWKT: string[] = [];
  //   let vacancyInsideWKT: string[] = [];
  //   let vacancyOutsideWKT: string[] = [];

  //   this.siteFields.forEach(f => {
  //     projectSiteWKT = projectSiteWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
  //   });

  //   this.roadFields.forEach(f => {
  //     roadSiteWKT = roadSiteWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
  //   })

  //   this.vacancyInsideFields.forEach(f => {
  //     vacancyInsideWKT = vacancyInsideWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
  //   })

  //   this.vacancyOutsideFields.forEach(f => {
  //     vacancyOutsideWKT = vacancyOutsideWKT.concat(this.sceneManager.getFieldWKTFile(f, this.mapProj, this.polygon2DGroup.matrix));
  //   })

  //   let shapeInfo: shapeInfoData = {
  //     boundaryLine: polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment),
  //     projectSite: projectSiteWKT,
  //     projectSiteArea: this.state.siteArea,
  //     projectSiteRoad: [''],
  //     road: roadSiteWKT,
  //     centerOfRoad: [''],
  //     setbackLineApartment: [''],
  //     setbackLineMultiHouse: [''],
  //     setbackLineOfficetel: [''],
  //     setbackLineRowHouse: [''],
  //     skylineCircle: [''],
  //     skylineLine: [''],
  //     vacancyInsie: vacancyInsideWKT,
  //     vacancyOutsie: vacancyOutsideWKT,
  //     topographyLines: [],
  //   }
  //   App.stage !== "prod" && console.log(shapeInfo);
  //   saveDataToS3(JSON.stringify(shapeInfo), S3SavePath, shapeInfoName, 'application/json');
  //   saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');

  //   // result location
  //   let buildingData: buildingData[] = [];
  //   let building_Stories_Avg = buildingStoriesAvg(this.Buildings);
  //   let floorAreaRatio = Number(((this.state.totalGroundArea / this.state.siteArea) * 100).toFixed(12));
  //   let coverAreaRatio = Number(((this.state.totalBuildingArea / this.state.siteArea) * 100).toFixed(12));
  //   let totalHousehold = getTotalHousehold(this.parsingOutput.buildings);
  //   let cost = Math.floor(this.state.siteArea * floorAreaRatio * 0.0001 * 1450167);
  //   console.log(this.Buildings)
  //   let areaProportion = calculateAreaProPortion(this.parsingOutput.buildings);
  //   console.log(areaProportion, 'area proportion')
  //   let lMax = 0;
  //   let lMin = 10000;

  //   this.Buildings.forEach(b => {
  //     b.cores.forEach(c => {
  //       c.houses.forEach(h => {
  //         lMax = Math.max(h.level.length, lMax);
  //         lMin = Math.min(h.level.length, lMin);
  //       })
  //     })
  //   });

  //   this.Buildings.forEach(b => {
  //     buildingData.push(getBuildingData(b.cores));
  //   });

  //   // let templates: Map<string, building> = new Map();
  //   let templates: { [key: string]: building } = {};
  //   let buildingIndex = 0;
  //   buildingData.forEach(b => {
  //     let bHeight = this.getBuildingHight(b.building, b.position);
  //     b.position = this.sceneManager.getPointToMap(b.position, this.mapProj);
  //     // bHeight.then(v => {
  //     //   b.position.z = v.valueOf()
  //     // })
  //     b.name = `building${buildingIndex}`;
  //     b.building.name = `building${buildingIndex}`;
  //     templates[b.building.name] = b.building;
  //     buildingIndex++;
  //   });

  //   let resultLocation: resultLocation = {
  //     buildingNumber: this.Buildings.length,
  //     areaProportion: areaProportion,
  //     buildingStoriesAvg: [building_Stories_Avg.AREA, building_Stories_Avg.HOUSE, building_Stories_Avg.NUMERICAL],
  //     buildingStoriesMax: lMax,
  //     buildingStoriesMin: lMin,
  //     buildings: buildingData,
  //     constructionCost: cost,
  //     coverAreaRatio: coverAreaRatio,
  //     exclusionRatio: 0,
  //     floorAreaRatio: floorAreaRatio,
  //     templateList: templates,
  //     totalHousehold: totalHousehold,
  //   }
  //   App.stage !== "prod" && console.log(resultLocation);
  //   saveDataToS3(JSON.stringify(resultLocation), S3SavePath, resultLocationName, 'application/json');

  //   //result --> dynamoDB
  //   let date = new Date().toISOString();
  //   let housingTypeRatio: housing_type_ratio[] = [];
  //   areaProportion.forEach(ap => {
  //     housingTypeRatio.push({
  //       area: ap.housingPlanTypeArea,
  //       bay: ap.numberOfBay,
  //       proportion: Math.floor(ap.housingPlanTypeProportion * 10000) / 100,
  //     })
  //   })

  //   let baseReportFilePath: base_report_file_path = {
  //     analysisReport: '',
  //     cad: '',
  //     constructionReport: '',
  //     exclusiveReport: '',
  //     lightReport: '',
  //     resImage: '',
  //     viewReport: '',
  //   }

  //   let baseResultFilePath: base_result_file_path = {
  //     light: '',
  //     location: '',
  //     view: ''
  //   }

  //   let resultJson: resultS3Json = {
  //     base_created_at: date,
  //     base_project_id: 0,
  //     base_report_file_path: baseReportFilePath,
  //     base_report_id: 0,
  //     base_result_status: 0,
  //     base_stage: 'dev',
  //     base_user_project_id: 0,
  //     base_uuid: '',
  //     base_result_file_path: baseResultFilePath,
  //     daylight_hours_avg: 0,
  //     daylight_hours_min: 0,
  //     daylight_hours_mode: 0,
  //     daylight_hours_proportion_less_n_hours: [100],
  //     loc_building_land_ratio: 0,
  //     loc_building_number: 0,
  //     loc_building_stores_avg: building_Stories_Avg,
  //     loc_building_stories_max: lMax,
  //     loc_building_stories_min: lMin,
  //     loc_construction_cost: cost,
  //     loc_exclusive_ratio_avg: 0,
  //     loc_floor_area_ratio: floorAreaRatio,
  //     loc_housing_type_ratio: housingTypeRatio,
  //     loc_my_building_type_ratio: [],
  //     loc_total_household: totalHousehold,
  //     view_point_avg: 0,
  //     view_point_skew: 0,
  //     view_skyarea_avg: 0,
  //     view_skyarea_min: 0,
  //     view_skyarea_mode: 0,
  //     view_skyarea_proportion_less_n_percent: [100],
  //   }
  //   App.stage !== "prod" && console.log(resultJson);
  //   saveDataToS3(JSON.stringify(resultJson), S3SavePath, resultJsonName, 'application/json');
  //   let uuid = uuid4();
  //   let buildingTemplateName = `${uuid}.json`;
  //   // let bufferSitePolygon = polygonBufferWithDistance(projectSiteWKT, SiteType.Apartment);
  //   let dbItem: buildingPlanStruct = {
  //     stage: App.tempStage,
  //     global_id: globalID,
  //     user_id: privateID,
  //     email: this.state.userID,
  //     name: fn,
  //     shapeInfo: `s3://${S3SavePath}/${shapeInfoName}`,
  //     resultJson: `s3://${S3SavePath}/${resultJsonName}`,
  //     resultLocation: `s3://${S3SavePath}/${resultLocationName}`,
  //     img_path: `s3://${S3SavePath}/${imageName}`,
  //     address: await getAddressByProjectSite(projectSiteWKT),
  //     project_site_area: this.state.siteArea,
  //     project_site: projectSiteWKT,
  //     road_site_area: getFieldsArea(this.roadFields),
  //     road_site: roadSiteWKT,
  //     vacancy_outside_area: this.state.vacancyOusideArea,//getFieldsArea(this.vacancyOutsideFields),
  //     vacancy_outside: vacancyOutsideWKT,
  //     vacancy_inside_area: getFieldsArea(this.vacancyInsideFields),
  //     vacancy_inside: vacancyInsideWKT,
  //     building_number: this.Buildings.length,
  //     total_building_area: this.state.totalBuildingArea,
  //     total_floor_area: this.state.totalGroundArea,
  //     average_exclusive_area: this.state.exclusiveAverageArea,
  //     cover_area_ratio: coverAreaRatio,
  //     floor_area_ratio: floorAreaRatio,
  //     created_at: date,
  //     modified_at: date,
  //     deleted: false,
  //     centerOfRoad: [this.centerOfRoadWKT], 
  //     topography_lines: [],

  //     // total_exclusive_area: Number(this.state.totalExclusiveAreas),
  //     // total_service_area: Number(this.state.totalServiceArea),
  //     // core_area: Number(this.state.totalCoreArea),
  //     // building_area: Number(this.state.totalBuildingArea), // buildingarea
  //     // floor_area: Number(this.state.totalGroundArea), // groundarea
  //     // common_wall_area: Number(this.state.totalCommonWallArea), // 
  //     // houses_number: this.state.houseNumber,

  //     // file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
  //     // meta_path: `s3://${S3SavePath}`,
  //   };
  //   console.log(this.centerOfRoadWKT, ' centerofRoad')
  //   App.stage !== "prod" && console.log(dbItem);
  //   saveDataToDynamoDB(dbItem, this.DBTableName);

  //   this.showModal('알림', ['나의 배치안을 저장했습니다.'], buttonNum.oneButton, () => console.log('save done'));
  // }

  // addSiteAreaToMap = async () => {
  //   if (!this.mapManager.isCreate() || this.siteFields.length === 0 || !this.siteFields[0].getLayer())
  //     return;

  //   this.calculateMapProjectionData();

  //   this.basePosition = this.sceneManager.getLatLonPosition(this.siteFields[0].getLayer()!.polygons[0].vertices[0].clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj);

  //   let latlngs: any[] = [];
  //   this.siteFields.forEach(f => {
  //     f.getLayer()!.polygons.forEach(p => {
  //       let polygon: any[] = [];
  //       if (p.selected || (p.motherPolygon && p.motherPolygon.selected)) {
  //         p.vertices.forEach(v => {
  //           polygon.push(this.sceneManager.getLatLonPosition(v.clone().applyMatrix4(this.polygon2DGroup.matrix), this.mapProj));
  //         })
  //       }
  //       latlngs.push(polygon);
  //     });
  //   });

  //   let center = new THREE.Vector2(0, 0);
  //   let count = 0;
  //   latlngs.forEach(ll => {
  //     count += ll.length;
  //     //@ts-ignore
  //     ll.forEach(l => {
  //       center.add(new THREE.Vector2(l.x, l.y));
  //     });
  //   });
  //   center.divideScalar(count);

  //   let centerTM = this.sceneManager.getPointToMap(new THREE.Vector3(center.x, center.y, 0), this.mapProj);

  //   await getDEM(centerTM.x, centerTM.y).then(v => {
  //     this.DEM = v;
  //   });
  //   App.stage !== "prod" && console.log(this.naverPolygon);
  //   if (this.naverPolygon) {
  //     this.naverPolygon.setPaths(latlngs);
  //   }
  //   else {
  //     this.naverPolygon = NaverPolygon({
  //       map: this.mapManager.getMap(),
  //       paths: latlngs,
  //       fillColor: '#ff0000',
  //       fillOpacity: 0.3,
  //       strokeColor: '#ff0000',
  //       strokeOpacity: 0.6,
  //       strokeWeight: 3
  //     });
  //   }

  //   let wkt = this.sceneManager.getFieldWKTFile(this.siteFields[0], this.mapProj, this.polygon2DGroup.matrix)
  //   const reader = new jsts.io.WKTReader();
  //   let area = wkt.map(r => reader.read(r).getArea()).reduce((a, b) => a + b, 0)
  //   if (!this.makedAreaBias) {
  //     this.areaBias = 1 - (1 - this.state.siteArea / area) / 2;
  //     this.makedAreaBias = true;
  //   }
  //   this.resizeCanvasResolution();
  // }


  removeBuildingFromeBuildingList = (building: BuildingTypeData) => {
    deleteBuildingFromBuildingList(this.Buildings, building);
    this.recalculateArea();
  }

  reset = () => {
    this.setState({
      loadFile: false,
    })
    this.dataInitialize();    
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    });
  }

  deleteNaverPolygon = () => {
    if (this.naverPolygon) {
      this.naverPolygon.setMap(null);
      this.naverPolygon = undefined;
    }
  }

  showSaveModal() {
    this.setState({ 
      showSaveModal: false,
      isSaved: false,
    });
  }

  changeSaveFileName = (name: string) => {
    this.setState({ saveName: name });
  }

  clickBlockUI = (buildingPart: BuildingPart | ConverterBuilding) => {
    this.setState({ clickedBuildingPart: buildingPart, clickedField: null })
  }

  clickFieldUI = (field: ConverterField) => {
    this.setState({ clickedField: field });
  }

  setErrorByType = (type: ErrorType) => {
    this.setState({
      errorLogs: this.errorList.getErrorByType(type)
    });
  }

  
  changeCurrentLog = (id: string) => {
    console.log(id, 'idddddddddddddddddd');
    
    // this.setState({
    //   errorLogs: this.errorList.getErrorById(id),
    //   currentLog: id,
    // })
  
}

  render = () => {

    return (
      <div className="buildit-template">
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <LoadingPage show={this.state.loadingPage} loadingMsg="배치안 파일을 불러오는 중입니다." />
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <MySiteBlockSaveModal
          DBTableName={this.DBTableName}
          converterType={ConverterType.myPlane}
          userId={this.state.userID}
          showModal={this.state.showSaveModal}
          onShowSaveModal={() => this.showSaveModal()}
          planArea={{
            siteArea: `${this.state.siteArea.toFixed(4)}㎡`,
            buildingCnt: this.parsingOutput.buildings.length,
            totalBuildingArea: `${Math.round(this.state.totalBuildingArea)}㎡`,
            totalGroundArea: `${this.state.totalGroundArea.toFixed(4)}㎡`,
            exclusiveAverageArea: `${this.state.exclusiveAverageArea}㎡`,
          }}
          centerOfRoad={this.setCenterOfRoad}
          fileName={this.state.saveName}
          onSave={() => this.saveBlockData()}
          isSaved={this.state.isSaved}
          parsingOutput={this.parsingOutput}
          onChangeFileName={this.changeSaveFileName}
        />


        <ConverterHeader
          type={ConverterType.myPlane}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={() => { this.reset()}}//this.clearSettingData}
          isFileOpen={this.state.loadFile}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          infoSize={this.state.infoSize}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          saveFile={() => {
            if (this.state.warningSize > 0) {
              let msg: string[] = [];
              [...this.loadFileErrorLogs, ...this.globalErrorLogs].forEach(log => {
                if (log.Type === "Warning") msg.push(log.Information);
              });
              this.setState({ showSaveModal: true })
              // this.showModal('알림', [...msg, '계속 진행하시겠습니까?'], buttonNum.twoButton,
              //   () => {
              //     this.setState({ showSaveModal: true })
              //   });
            }
            else {
              this.setState({ showSaveModal: true });
            }
          }}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className='MainBody'>
        <div className="siteInfo">
            {
              this.state.field.topography.map(topo => 
                <div className={`fieldText TT${topo.uuid}`}>
                  <LevelIcon className="icon levelIcon"></LevelIcon>
                  <div className={`font font-14px`}>{topo.getHeight()}</div>
                </div>
              )
            }
          </div>
          <div className='areaInfo'>
            {this.houses.map(h =>
              <div className={`textTest TT${h.uuid}`} key={h.uuid}>
                <div className="title key-value">
                  {/* <li className="key">방</li> */}
                  <span className="value">{`${'<세대> '}`} {h.name.split('_')[0]}</span>
                </div>
                <div className="key-value">
                  <li className="key">전용 면적</li>
                  <span className="value">{h.exclusiveArea}㎡</span>
                </div>
                <div className="key-value">
                  <li className="key">서비스 면젹</li>
                  <span className="value">{h.serviceArea}㎡</span>
                </div>
                <div className="key-value">
                  <li className="key">벽체공용 면젹</li>
                  <span className="value">{h.commonWallArea}㎡</span>
                </div>
              </div>
            )}
            {this.cores.map(c =>
              <div className={`textTest TT${c.uuid}`} key={c.uuid}>
                <div className="title key-value">
                  <span className="value">{`${'<코어> '}`} {c.name.split('_')[0]}</span>
                  {/* <li className="key">코어</li>
                  <span className="value">{c.name}</span> */}
                </div>
                <div className="key-value">
                  <li className="key">코어 면적</li>
                  <span className="value">{c.GetArea()}㎡</span>
                </div>
              </div>
            )}
          </div>
          
          
          <div className='information'>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>사업영역 면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.siteArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>동타입 수</div><div className='inforValue font font-emphasis font-14px'>{this.parsingOutput.buildings.length}개</div></div> {/* {this.Buildings.length} */}
            <div className='info font font-secondary font-12px'><div className='infoLabel'>건축면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalBuildingArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>바닥면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.totalGroundArea.toFixed(4)}㎡</div></div>
            <div className='info font font-secondary font-12px'><div className='infoLabel'>평균 전용면적</div><div className='inforValue font font-emphasis font-14px'>{this.state.exclusiveAverageArea.toFixed(4)}㎡</div></div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
            </div>
          </div>
          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.myPlane}
                  showSample={true}
                />
                <div ref="map" style={{ width: `100%`, height: `100%`, position: "absolute" }} />
                {
                  this.state.loadFile &&
                  <Button className="btn bg-navy btn-primary fold-btn" onClick={() => { this.setState({ showBlock: !this.state.showBlock }) }}>
                    {this.state.showBlock ?
                      <NavigateNext className="icon icon-next" /> :
                      <ChevronLeft className="icon icon-next" />
                    }
                  </Button>

                }

                <div className={`toolBar ${!this.state.loadFile && 'hidden'} ${this.state.showBlock ? '' : 'showBlock'}`}>
                  <div className='rightButtons'>
                    <div className='switchTabs'>
                      {/* <Tooltip place='left' arrowOn={false} msg="캐드 도면으로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.CAD && 'enable'}`} onClick={e => 
                          {
                            this.Switch2D3D(false);
                            this.canvasModeChanged(CanvasMode.CAD)
                          }}>CAD</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.MAP && 'enable'}`} onClick={e => this.canvasModeChanged(CanvasMode.MAP)}>MAP</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="3D 모델로 확인">
                        <Button className={`switchButton ${this.state.canvasMode === CanvasMode.VIEW_3D && 'enable'}`} onClick={e => {
                          this.Switch2D3D(true);
                          this.canvasModeChanged(CanvasMode.VIEW_3D);
                          
                          }}>3D VIEW</Button>
                      </Tooltip> */}
                      <Tooltip place='left' arrowOn={false} msg="캐드 도면으로 확인">
                        <Button className={`switchButton ${this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(true)}>CAD</Button>
                      </Tooltip>
                      <Tooltip place='left' arrowOn={false} msg="지도에서 확인">
                        <Button className={`switchButton ${!this.state.isCad && 'enable'}`} onClick={e => this.canvasModeChanged(false)}>MAP</Button>
                      </Tooltip>
                    </div>
                    <div className={`switchTabs ${this.state.isCad && 'hidden'}`}>
                      <Button className={`switchButton ${!this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(false)}>지도</Button>
                      <Button className={`switchButton ${this.state.isCadastral && 'enable'}`} onClick={e => this.mapTypeChanged(true)}>지적도</Button>
                    </div>
                    <Tooltip place="left" msg="블록 정보 보기" arrowOn={false}>
                      <Button className={`btn bg-navy btn-secondary infoButton ${this.state.showBlockInfo ? "active" : ""}`} onClick={() => { this.setState({ showBlockInfo: !this.state.showBlockInfo }) }}>
                        <Info className={`icon info-icon font font-emphasis ${this.state.showBlockInfo ? "font-special" : ""}`} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </div>

              <ErrorLogBlock
                allBuildings={this.parsingOutput.buildings}//{[...this.houses, ...this.cores]}
                allFields={this.parsingOutput.fields}
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                confirmSize={this.state.infoSize}
                errorList={this.state.errorLogs}
                showErrorByType={this.setErrorByType}
                closeCurrentLog={() => {
                  this.setState({ errorLogs: this.errorList.getError() });
                  this.setState({ currentLog: "ALL" });
                }}
                currentLog={this.state.currentLog === "ALL" ? "ALL" : this.parsingOutput.fields.find(f => f.uuid === this.state.currentLog)!.name}
                //                classifyLogs={this.classifyLogs}
        
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <div className={`wrapBlockList ${this.state.showBlock ? "" : "display-none"}`}>
                <div className="header">
                    <HierarchyIcon className="icon hierarchy-icon" />
                    <span className="text">Hierarchy</span>
                </div>
                <div className="wrap-tab">
                  <Tabs className="tabs bg-navy">
                    <Button
                      onClick={e => { this.setState({ hierarchyMode: ConverterType.mySite }) }} disableRipple={true}
                      className={`img-btn tab bg-navy tab-primary first-child ${this.state.hierarchyMode === ConverterType.mySite && "active"}`}
                    >
                      사업영역
                    </Button>
                    <Button
                      onClick={e => this.setState({ hierarchyMode: ConverterType.myType })} disableRipple={true}
                      className={`img-btn tab bg-navy tab-primary ${this.state.hierarchyMode === ConverterType.myType && "active"}`}
                    >
                      동평면
                    </Button>
                  </Tabs>
                </div>
  
                <div className="description">
                  {
                    this.state.showBlock && this.state.hierarchyMode === ConverterType.mySite &&
                    this.parsingOutput.fields.map(f =>
                      <div
                        id={f.name}
                        className={`font field-item ${this.state.clickedField && f.name === this.state.clickedField.name ? "selected font-special" : ""}`}
                        onMouseOver={() => {
                          setBlockOpacity(this.parsingOutput.fields, 0.2);
                          setBlockOpacity([f], 1);
                        }}
                        onMouseLeave={() => {
                          //                  setBlockOpacity(this.parsingOutput.fields, 1);
                          brightenAllField(this.parsingOutput.fields)
                        }}
                        onClick={(e) => {

                          this.setState({
                            clickedBuildingPart: null,
                            clickedField: this.parsingOutput.fields.filter(f => f.name === e.currentTarget.id)[0],
                          });
                        }}
                      >
                        <div className="text"><BlockIcon className="block-icon" />{f.name}</div>
                        <Button
                          id={f.uuid}
                          className={`btn bg-navy btn-primary icon-wrap ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}
                          ${this.state.currentLog === f.uuid ? "active" : ""}
                          `}
                          onClick={(e) => {
                            //  e.stopPropagation();
                            if (this.state.currentLog === f.uuid) {
                              this.setState({
                                errorLogs: this.errorList.getError(),
                                currentLog: "ALL"
                              })
                            }
                            else {
                              this.setState({
                                errorLogs: this.errorList.getErrorById(f.uuid),
                                currentLog: f.uuid
                              })
                            }
                          }}
                        ><SpeakerNotes className={`icon speaker-icon ${this.errorList.getErrorById(f.uuid).length === 0 ? "disabled" : ""}`} /></Button>
                      </div>
                    )
                  }
                  {
                    this.state.showBlock && this.state.hierarchyMode === ConverterType.myType &&
                    <BlockTreeList
                    handleCurLogById={this.changeCurrentLog}
                    errorList={this.errorList}
                      parsingOutput={this.parsingOutput}
                      houses={this.houses}
                      cores={this.cores}
                      components={[...this.parsingOutput.buildings, ...this.blockBs, ...this.houses, ...this.cores]}
                      clickComponent={this.clickBlockUI}
                      clickBuildingPart={this.state.clickedBuildingPart}
                      currentLog={this.state.currentLog}
                      handleCurLog={() => {
                        this.setState({
                          errorLogs: this.errorList.getError(),
                          currentLog: "ALL"
                        })
                      }
                      }
                    />
                  }
                </div>
              </div>
              <div className={`functionalLayout ${this.state.showBlock ? "" : "display-none"}`} >
                <div className="header">
                  <div className="wrapper">
                    <Info className="icon info-icon font font-emphasis" />
                    <span className="font font-primary">Inspector</span>
                  </div>
                </div>
                <div className={`description ${this.state.showBlock ? "" : "display-none"}`}>
                  <div className="font font-emphasis font-14px selectedBlockName">
                    {
                      (this.state.clickedField &&
                        <div className="title">
                          <span><BlockIcon className="block-icon font font-emphasis block-icon" />{this.state.clickedField.name}</span>
                          <span className="font font-secondary font-12px field-type">{this.state.clickedField.typeName}</span>
                        </div>)
                    }
                    {
                                 (this.state.clickedBuildingPart &&
                                  <div className="title">
                                    <span><BlockIcon className="block-icon font font-emphasis block-icon" />{this.state.clickedBuildingPart.name}</span>
                                    <span className="font font-secondary font-12px field-type">
                                      {this.state.clickedBuildingPart.componentType === "core" && "코어"}
                                      {this.state.clickedBuildingPart.componentType === "house" && "단위세대"}
                                    </span>
                                  </div>)
          
          
                    }
                  </div>
                  {
                    this.state.clickedField !== null &&
                    <FieldPartUI
                      field={this.state.clickedField}
                      updateArea={() =>
                        this.recalculateArea()
                      }
                    ></FieldPartUI>
                  }
                  {(
                    ((this.state.clickedField === null) &&
                      (this.state.clickedBuildingPart === null)) ||
                    (this.state.clickedBuildingPart && this.state.clickedBuildingPart.building && this.state.clickedBuildingPart.buildingType !== "component"))
                    &&
                    <div className="font font-special">영역 Block 또는 세대/코어 Block을 클릭하시면 면적 입력이 가능합니다.</div>
                  }
                  {
                    this.state.clickedBuildingPart !== null &&
                    <BuildingPartUI
                      // field={this.state.clickedField}
                      type={ConverterType.myPlane}
                      updateArea={() => this.recalculateArea()}//this.recalculateResult()} 
                      compoent={this.state.clickedBuildingPart}
                      buildingPart={this.blockBs}
                    // field={this.state.clickedField}
                    />
                  }

                </div>

                {/* <div className="selectedBlockName">{
                  this.state.clickedBuildingPart && <><BlockIcon style={{ marginRight: 6 }} />{this.state.clickedBuildingPart.name}</>
                  || 
                  (this.state.clickedField && <><BlockIcon style={{ marginRight: 6 }} />{this.state.clickedField.name}
                  <span className="field-type">{this.state.clickedField.typeName}</span>
                  </>)
                  }
                </div> */}
                {/* {
                  (this.state.clickedField !== null && this.state.clickedBuildingPart === null) &&
                  <FieldPartUI
                    field={this.state.clickedField}
                    updateArea={() =>
                      this.recalculateArea()
                    }
                  ></FieldPartUI>
                } */}



              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}