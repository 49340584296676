import React, { Component } from 'react';
import { ConverterLayer, PartOfSelect } from './DataTypes';
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import DownIcon from '@material-ui/icons/KeyboardArrowDown'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import '../css/CADConverter/HouseAndCore.scss';
import { switchLayerState, CheckColsedPolygon, brightenAllLayer } from './CoreAndHouseController';
import { ConverterInputBox } from './ConverterInputBox';
import { House } from './House';
import { Core } from './Core';

interface coreState {
  selectCore: boolean,
  hideList: boolean,
  partOfHouse: PartOfSelect,
}

interface coreProp {
  Core: Core,
  selectLayer: ConverterLayer | null,
  selectHouse: House | null,
  showLayerList: (id: string, partOfHouse: PartOfSelect) => void,
  showHouseList: (id: string) => void,
  removeCore: (core: Core) => void,
  valueChanged: () => void,
  showModel: (information: string) => void,
  brightenAllLayer?: () => void,
  darkenAllLayer?: () => void,
  settingID: string,
  editable: boolean,
}

export class CoreUI extends Component<coreProp, coreState> {
  state: coreState = {
    selectCore: (this.props.Core.core && true) || false,
    partOfHouse: PartOfSelect.core,
    hideList: false,
  }

  componentDidMount = async () => {

  }

  componentDidUpdate = (previousProps: Readonly<coreProp>, previousState: Readonly<coreState>) => {
    if (this.props.Core.id !== this.props.settingID)
      return;

    if (previousProps.selectLayer !== this.props.selectLayer) {
      this.setCoreLayer(this.props.selectLayer);
    }

    if (previousProps.selectHouse !== this.props.selectHouse && this.props.selectHouse) {
      this.props.Core.addHouseToList(this.props.selectHouse);
      this.props.valueChanged();
    }
  }

  setCoreLayer = (layer: ConverterLayer | null) => {
    if (!layer) return;

    if (this.props.selectLayer!.selected && this.props.selectLayer !== this.props.Core.core) {
      this.props.showModel('이미 선택되어 있는 레이어입니다.');
      return;
    }

    if (!CheckColsedPolygon(layer)) {
      // setErrorColorForLayer(layer);
      this.props.showModel('닫힌 폴리곤이 아닙니다.');
      return;
    }

    this.props.Core.setCore(layer);
  }

  valueChanged = (value: number) => {
    this.props.Core.setCoreArea(value);
    this.props.valueChanged();
  }

  //to-do: need rebuild function
  checkSelectState = (partOfHouse: PartOfSelect) => {
    return this.props.settingID === this.props.Core.id && this.state.partOfHouse === partOfHouse;
  }

  deleteCore = () => {
    switchLayerState(this.props.Core.core);
    this.props.Core.core = null;

    this.props.valueChanged();
  }

  deleteHouse = (houseData: House) => {
    this.props.Core.deleteHouseWithHouseId(houseData.id);

    this.props.valueChanged();
  }

  render = () => {
    return (
      <div className='core' onMouseOver={() => { this.props.darkenAllLayer!(); brightenAllLayer(this.props.Core.getSettedLayer()) }} onMouseOut={() => this.props.brightenAllLayer!()}>
        <div className='nameDiv' onClick={() => this.setState({ hideList: !this.state.hideList })}>{(this.state.hideList && <DownIcon className='showIcon iconRight' />) || <UpIcon className='showIcon iconRight' />}{this.props.Core.name}
          <CloseIcon className='closeIcon' onClick={() => { if (this.props.editable) this.props.removeCore(this.props.Core) }} />
        </div>
        <div hidden={this.state.hideList}>
          <div className='buttonDiv'>
            <div className={`selectButton ${this.checkSelectState(PartOfSelect.core) && 'selected' || 'unselected'}`} onClick={e => { this.props.showLayerList(this.props.Core.id, PartOfSelect.core); this.setState({ partOfHouse: PartOfSelect.core }) }}>
              <span className='text'>코어 선택</span>
              <CheckIcon className={`CheckIcon ${this.props.Core.core && 'checked'}`} />
            </div>
            <div className='nameText'>
              {this.props.Core.core && this.props.Core.core.name}
              <CloseIcon className={`closeIcon ${(this.checkSelectState(PartOfSelect.core) && this.props.Core.core) && 'selected' || 'unselected'}`} onClick={this.deleteCore} />
            </div>
          </div>
          <div className='buttonDiv alignTop'>
            <div className={`selectButton ${this.checkSelectState(PartOfSelect.house) && 'selected' || 'unselected'}`} onClick={e => { this.props.showHouseList(this.props.Core.id); this.setState({ partOfHouse: PartOfSelect.house }) }}>
              <span className='text'>연결된 세대 선택</span>
              <CheckIcon className={`CheckIcon`} />
            </div>
            {(this.props.Core.houses.length > 0 && <div className='houseList'>
              {this.props.Core.houses.map(h =>
                <div className='cell' key={h.id} >
                  <CheckIcon className={`CheckIcon`} />
                  <div className='nameText'>
                    {h.name}
                  </div>
                </div>
              )}
            </div>)}
          </div>
          <ConverterInputBox name='코어 면적' value={this.props.Core.area} valueOnChange={this.valueChanged} unit='㎡' step={0.01} />

        </div>
        <div className='Line' />
      </div>
    )
  }
}