import React, { Component, Fragment } from 'react';
import { Project } from './model/Project';
import C3Chart from './C3Chart';
import AWSModule from './AWSModule';
import { MyBuildingShapeType } from './ProjectFormHousing';
import _ from 'lodash';
import './css/HousingChart.scss';
import { pad } from './Utils';
import App from './App';

export interface HousingChartProps {
  type?: "config" | "result";
  legend: "bottom" | "right";
  project: Project;
  result?: any;
  id: string;
  className?: string;
  width?: number;
  height?: number;
}
export interface HousingChartState {
  myBuildingShapeType: Array<MyBuildingShapeType>,
}

export default class HousingChart extends Component<HousingChartProps, HousingChartState> {
  state: HousingChartState = {
    myBuildingShapeType: [],
  }
  componentWillMount = async () => {
    this.getMyBuildingShapeType();
  }

  componentDidUpdate = async (pp: Readonly<HousingChartProps>) => {
    if (!_.isEqual(pp, this.props)) {
      this.getMyBuildingShapeType();
    }
  }

  getMyBuildingShapeType = async () => {
    const myBuildingShapeType: Array<any> = [];
    let getList: Array<any> = [];
    if (this.props.type === "result") {
      getList = this.props.result.loc_my_building_type_ratio || [];
    } else {
      getList = this.props.project.my_building_shape_type || [];
    }

    for (const t of getList) {
      if (t.id) {
        const r = await App.search({
          table: App.DDBTable.MyBuildingType,
          query: `global_id:${t.id}`,
        });

        const rr = r.data;
        if (rr.hits.hits && rr.hits.hits.length > 0) {
          myBuildingShapeType.push({
            proportion: t.proportion,
            ...rr.hits.hits[0]._source
          })
        }
        else if (t.proportion && t.name) {
          myBuildingShapeType.push({
            proportion: t.proportion,
            name: t.name
          })
        }
      }
    }
  
    this.setState({ myBuildingShapeType: myBuildingShapeType });
  }

  render() {
    let chartData: any[] = [];

    if (this.props.type === "result") {
      if (this.props.result && this.props.result.loc_housing_type_ratio) {
        for (let i = 0; i < this.props.result.loc_housing_type_ratio.length; ++i) {
          const r = this.props.result.loc_housing_type_ratio[i];
          chartData.push([`세대타입 ${pad((i + 1).toString(), 2)}(${r.area + "㎡"})`, r.proportion.toFixed(2)])
        }
      }
      this.state.myBuildingShapeType.map((r: any) => {
        if (r.name !== undefined && r.proportion !== undefined) {
          chartData.push([r.name, Number(r.proportion).toFixed(2)])
        }
      })
    } else {
      chartData = [['미선택', 100]];
      if (this.props.project) {
        if (this.props.project.housing_plan_type && this.props.project.housing_plan_type.length > 0) {
          this.props.project.housing_plan_type.map((r: any, i: number) => {
            chartData[0][1] -= r.proportion!;
            chartData.push([`세대타입 ${pad((i + 1).toString(), 2)}(${r.area === undefined ? '면적선택' : r.area + "㎡"})`, r.proportion])
          });
        }
        if (this.state.myBuildingShapeType && this.state.myBuildingShapeType.length > 0) {
          this.state.myBuildingShapeType.map(r => {
            chartData[0][1] -= r.proportion!;
            chartData.push([r.name, r.proportion])
          });
        }
        if (chartData[0] && chartData[0][1] === 0) {
          chartData.splice(0, 1);
        }
      }
    }

    return (
      <div className={`HousingChart ${this.props.className && this.props.className || ""}`}>
        <C3Chart
          className="t-a-c"
          type="pie"
          data={{
            columns: chartData,
            labels: true,
            colors: {
              '미선택': "#333",
            },
          }}
          config={{
            size: {
              width: this.props.width !== undefined ? this.props.width : 175,
              height: this.props.height !== undefined ? this.props.height : 175,
            },
            color: {
              pattern: ['#273945', '#2a4b58', '#2f5d6c', '#326f7f'],
            },
            legend: {
              show: false,
            },
            tooltip: {
              show: false,
            }
          }}
          customLegend={{
            show: true,
            position: this.props.legend,
            id: this.props.id,
          }}
        />
      </div>

    )
  }
}