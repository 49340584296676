import React, { Component } from 'react';
//import { ConverterType } from './DataTypes';
import '../css/BuilditUI.scss';
import '../css/CADConverter/LoadingPage.scss';

interface loadingPageProp {
  show: boolean,
  loadingMsg: string ,
  isModal?: boolean,
}

interface loadingPageState {

}

export class LoadingPage extends Component<loadingPageProp, loadingPageState>{
  render = () => {    

      return (
        <>
        <div className={`buildit-template loadingPage ${this.props.isModal ? 'modal-size' : ''}`} style={{ display: (this.props.show && 'flex' || 'none') }}>
          <div className='progress'>
            <img className="loading-img" src={'/img/icon/loading.png'} ></img>
          </div>
          <div className='centerWord'>
            <div className={`font ${this.props.isModal ? 'font-18px' : ''}`}>CAD</div>
            <div className={`font ${this.props.isModal ? 'font-18px' : ''}`}>Converter</div>
          </div>
          <div className='font font-special font-18px loadingMessage'>
            <span>{this.props.loadingMsg}</span>
          </div>
        </div>
        </>    
        )
  
  }
}
