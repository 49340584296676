import React, { Component, Fragment } from 'react';
import SessionComponent from './SessionComponent';
import BuilditTemplate from './BuilditTemplate';
import MyPageHead from './MyPageHead';
import MyCADDataPage from './MyCADDataPage';
import PageFoot from './pageFoot';
import './css/MyPage.scss';
import MyCostPage from './MyCostPage';
import MyInformationPage from './MyInformationPage';
import App from './App';

// export enum ServerTableName {
//   siteDBTable = 'platform-buildit-my-site',
//   siteS3Table = 'teneleven-platform-my-site',
//   planDBTable = 'platform-buildit-my-building-plan',
//   planS3Table = 'teneleven-platform-my-building-plan',
//   typeDBTable = 'platform-buildit-my-building-type',
//   typeS3Table = 'teneleven-platform-my-building-type',
// }

export enum pageContent {
  mySite = '사업영역',
  myPlan = '배치안',
  myBuildingType = '동평면',
  pointUsageList = '포인트',
  purchaseHistory = '구매 내역',
  myInformation = '계정관리'
}

export interface MyPageProps {
  changePageContent?: (pageState: pageContent) => void;
  startPageContent?: pageContent,
}

export interface MyPageState {
  DBTableName: string,
  S3BucketName: string,
  pageContent: pageContent,
  userEmail: string,
}

export default class MyPage extends SessionComponent<MyPageProps, MyPageState> {

  state: MyPageState = {
    DBTableName: App.DDBTable.MyBuildingSite,
    S3BucketName: App.S3Bucket.MyBuildingSite,
    pageContent: this.props.startPageContent ? this.props.startPageContent : pageContent.mySite,
    userEmail: App.session.email,
  }

  componentWillMount() {
    this.props.startPageContent && this.changePageContent(this.props.startPageContent);    
  }

  componentDidUpdate = (pp: MyPageProps) =>{
    if (this.props.startPageContent && (pp.startPageContent !== this.props.startPageContent)) {    
      this.changePageContent(this.props.startPageContent);    
    }
  }

  changePageContent = (_pageContent: pageContent) => {
    let dbTable = App.DDBTable.MyBuildingSite;
    let s3Bucket = App.S3Bucket.MyBuildingSite;
    switch (_pageContent) {
      case pageContent.myPlan:
        
        dbTable = App.DDBTable.MyBuildingPlan;
        s3Bucket = App.S3Bucket.MyBuildingPlan;
        break;
      case pageContent.mySite:
      
      dbTable = App.DDBTable.MyBuildingSite;
        s3Bucket = App.S3Bucket.MyBuildingSite;
        break;
      case pageContent.myBuildingType:
      dbTable = App.DDBTable.MyBuildingType;
        s3Bucket = App.S3Bucket.MyBuildingType;
        break;
      case pageContent.pointUsageList:
        break;
      case pageContent.purchaseHistory:
        break;
      default:
        break;
    }

    this.setState({
      DBTableName: dbTable,
      S3BucketName: s3Bucket,
      pageContent: _pageContent,
    })
  };

  render = () => {
    let bodyDiv = <div></div>

    switch (this.state.pageContent) {
      case pageContent.mySite:
      case pageContent.myPlan:
      case pageContent.myBuildingType:
        bodyDiv = <MyCADDataPage DBTableName={this.state.DBTableName} S3BucketName={this.state.S3BucketName} pageContent={this.state.pageContent} />
        break;
      case pageContent.pointUsageList:
      case pageContent.purchaseHistory:
        bodyDiv = <MyCostPage pageContent={this.state.pageContent} />
        break;
      case pageContent.myInformation:
        bodyDiv = <MyInformationPage userEmail={this.state.userEmail}/>
        break;
      default:
        break;
    }
 
    return (
      <BuilditTemplate footer={true}  {...this.props}>
        <div className='myPage'>
          <MyPageHead changePageContent={this.changePageContent} pageContent={this.state.pageContent} userEmail={this.state.userEmail}/>
          <div className='body'>
            {bodyDiv}
          </div>
        </div>
      </BuilditTemplate>
    )
  }
}