import { BlockParsingData, ConverterField, FieldPart } from "./BuildingPart";
import { ErrorLogCell2, ErrorType } from "./ErrorLog";
import { getCircle, getCurveErrorCircleField, makePolygonApartLine } from "./MeshMaker";
import * as THREE from '@teneleven/three';
import { FieldType } from "./Field";
import { CheckPolygonsApart } from './CoreAndHouseController';
import { switchLineDashedState } from './FileParser';
import { ConverterType } from './DataTypes';
import { ErrorList, SiteError } from './Error';

const jsts = require('jsts');
interface FieldsType {
  sites?: ConverterField[],
  roads?: ConverterField[],
  roadCenterLines?: ConverterField[],
  grounds?: ConverterField[],
  special?: ConverterField[],
  topographies?: ConverterField[],
}

export function checkSiteError(parsingOutput: BlockParsingData, loadFileErrorLogs: ErrorLogCell2[], type: ConverterType, errorList: ErrorList) {
  let sites = parsingOutput.fields.filter(field => field.typeName === FieldType.site);
  let roads = parsingOutput.fields.filter(f => f.typeName === FieldType.road);
  let roadCenterLines = parsingOutput.fields.filter(f => f.typeName === FieldType.centerLineOfRoad);
  let grounds = parsingOutput.fields.filter(field => field.typeName === FieldType.vacancyOutside);
  let special = parsingOutput.fields.filter(field => field.typeName === FieldType.vacancyInside);
  let topographies = parsingOutput.fields.filter(field => field.typeName === FieldType.topography);
  let cadastralMapError = checkInsideCadastralMap(parsingOutput, errorList);
  
  // if (cadastralMapError.length > 0) {
  //   return;
  // }

  checkSiteName(parsingOutput, type, errorList);
  checkSiteRound(parsingOutput.fields, errorList);
  checkSiteStructure({ sites, roads, roadCenterLines }, parsingOutput.fields, errorList);
  checkClosedPolygon(parsingOutput.fields, errorList);
  checkFieldsApart({sites, roads, roadCenterLines, grounds, special, topographies}, parsingOutput.fields, errorList);
  checkSameBlock(parsingOutput.fields, errorList);

  // *인접대지경계선은 대지영역을 포함하며 대지영역보다 커야됨 (같거나 작으면안됨)    
  checkFieldOverlap({sites, roads, roadCenterLines, grounds}, parsingOutput.fields, errorList);

}

function checkSameBlock(fields: ConverterField[], errorList: ErrorList) {
  let checkFind: Array<boolean> = Array.from({length: fields.length}, () => false);
  // 같은면적, 같은이름 체크
  fields.forEach((field: ConverterField, idx1: number) => {
    fields.forEach((anotherField: ConverterField, idx2: number) => {      
      if (idx1 !== idx2 && !checkFind[idx2] && field.name === anotherField.name && field.getArea() === anotherField.getArea()) {
        //@ts-ignore
        checkFind[idx1] = true;
        checkFind[idx2] = true;
        let msg = "";
        msg = `(${field.name}) ${field.typeName}에 저장 할수 없는 N개의 폴리곤이 존재합니다. 
          각 블록 별로 1개의 폴리곤만 설정 가능합니다. 캐드에서 재설정 후 다시 시도해 주세요.`
          errorList.addError(new SiteError({
            title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
            msg: `나의 사업영역은 하나의 ${field.typeName}만을 허용합니다.`,
            type: ErrorType.Error,
            id: [field.uuid],
            targetFields: [field],
            //@ts-ignore
            hilightPolygon: group,
          }));
          
        // errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
        // `나의 사업영역은 하나의 ${field.typeName}만을 허용합니다.`, new THREE.Group(), undefined,
        //   undefined, {
        //     fields: fields,
        //     field: [field],
        //   }
        // ));
      }
    })
  })

  fields.forEach((field: ConverterField, idx1: number) => {
      for (let i = 0; i < field.parts.length; i++) {
        for (let j = i + 1; j < field.parts.length; j++) {
          
          if (Math.abs(field.parts[i].area - field.parts[j].area) < 0.01) {
            errorList.addError(new SiteError({
              title: '저장 할수 없는 폴리곤이 존재합니다.',
              msg: `(${field.name}) ${field.typeName}에 저장 할수 없는 N개의 폴리곤이 존재합니다. 
              각 블록 별로 1개의 폴리곤만 설정 가능합니다.
              캐드에서 재설정 후 다시 시도해 주세요.`,
              type: ErrorType.Error,
              id: [field.uuid],
              targetFields: [field],
            }));
          //   errorLogs.push(makeErrorInformation2(
          //     '저장 할수 없는 폴리곤이 존재합니다.',
              // `(${field.name}) ${field.typeName}에 저장 할수 없는 N개의 폴리곤이 존재합니다. 
              // 각 블록 별로 1개의 폴리곤만 설정 가능합니다.
              // 캐드에서 재설정 후 다시 시도해 주세요.`
          //     , new THREE.Group(), undefined,
          //   undefined, {
          //     fields: fields,
          //     field: [field], 
          //   }
          // ));
          }
        }       

      }
  })
}

function checkFieldOverlap(fields: FieldsType, parsedFields: ConverterField[], errorList: ErrorList) {

  if (fields.sites!.length > 0) {

    fields.sites!.forEach(site => {
      let sitePoly = site.getUnionJstsPolygon();
      // 1. 대지 - 인접대지경계선이 겹쳐져 있을 경우

      if (fields.roadCenterLines && fields.roadCenterLines.length > 0) {
        fields.roadCenterLines.forEach(adjLine => {
          let adjLinePoly = adjLine.getUnionJstsPolygon();
          if (adjLinePoly && sitePoly) {

            if (sitePoly.covers(adjLinePoly) || Math.abs(sitePoly.union(adjLinePoly).getArea() - adjLinePoly.getArea()) >= 0.4) {
              errorList.addError(new SiteError({
                title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                msg: `대지 영역 내에 인접 대지 경계선 을 설정 할 수 없습니다.`,
                type: ErrorType.Error,
                id: [adjLine.uuid],
                targetFields: [adjLine],
              }));
            }
          }
        })

      }
    })
  }
}











function checkFieldsApart(fields: FieldsType, parsedFields: ConverterField[], errorList: ErrorList) {
  let errorLogs: ErrorLogCell2[] = [];
  if (fields.roads!.length === 0)
    fields.roads = fields.roadCenterLines;

  if (fields.sites!.length > 0) {
    fields.sites!.forEach(site => {
      let sitePolygon = site.getUnionJstsPolygon();
      let MULTIPLY_NUM = 1;
      if (site.unitScale === 0.001)
        MULTIPLY_NUM = 1000;
      else if (site.unitScale === 39.3701)
        MULTIPLY_NUM = 1 / 39.3701;
  
      let roadUnion: jsts.geom.Geometry;
      

      // 폴리곤 이격
      function testFieldsApart(fields: ConverterField[], fieldUnion: jsts.geom.Geometry) {
          // 에러처리
          fields.forEach((field, i) => {
            let polygon = field.getUnionJstsPolygon();
          
            if (polygon) {
              let distance = sitePolygon!.distance(polygon) * MULTIPLY_NUM;
              // raod union/polygon
              if (distance >= 1 && fieldUnion!.union(polygon).getNumGeometries() === 1) { // 연결된 도로블록이면
                let group = makePolygonApartLine(polygon, sitePolygon);
                errorList.addError(new SiteError({
                  title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
                  msg:  `${site.name}과 ${field.name} 간의 ${distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
                  type: ErrorType.Warning,
                  id: [field.uuid, site.uuid],
                  targetFields: [field, site],
                  //@ts-ignore
                  hilightPolygon: group,
                }));
                // errorLogs.push(makeWarningInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                //   `${site.name}과 ${field.name} 간의 ${distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
                //   group, undefined, undefined, {
                //   fields: parsedFields,
                //   field: [field, site],
                // }));

                field.ErrorPolygonGroup.add(group);
              }
              else if (distance >= 10) {
                let group = makePolygonApartLine(polygon, sitePolygon);
                errorList.addError(new SiteError({
                  title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
                  msg:  `${site.name}과 ${field.name}사이에 ${distance.toFixed(2)}mm이상의 이격 오차가 있습니다.`,
                  type: ErrorType.Info,
                  id: [field.uuid, site.uuid],
                  targetFields: [field, site],
                  //@ts-ignore
                  hilightPolygon: group,
                }));
                
                // errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                //   `${site.name}과 ${field.name}사이에 ${distance.toFixed(2)}mm이상의 이격 오차가 있습니다.`,
                //   group, undefined, undefined, {
                //   fields: parsedFields,
                //   field: [field, site],
                // }));

                field.ErrorPolygonGroup.add(group);
                field.unused = true;
                field.parts.forEach(part => {
                  part.unused = true;
                });
                field.parts.forEach(part => {
                  switchLineDashedState(part.polygon.lineMesh.material, true);
                })
              }
              else if (distance >= 1 && distance < 10) {
                let group = makePolygonApartLine(polygon, sitePolygon);
                errorList.addError(new SiteError({
                  title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                  msg: `${site.name}과 ${field.name} 간의 ${distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
                  type: ErrorType.Warning,
                  id: [field.uuid, site.uuid],
                  targetFields: [field, site],
                  //@ts-ignore
                  hilightPolygon: group,
                }));
                // errorLogs.push(makeWarningInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.',
                //   `${site.name}과 ${field.name} 간의 ${distance.toFixed(2)}mm 의 이격 오차가 있습니다.`,
                //   group, undefined, undefined, {
                //   fields: parsedFields,
                //   field: [field, site],
                // }));

                field.ErrorPolygonGroup.add(group);

                field.unused = true;
                field.parts.forEach(part => {
                  part.unused = true;
                });
                field.parts.forEach(part => {
                  switchLineDashedState(part.polygon.lineMesh.material, true);
                })
              }
              else if (distance > 0 && distance < 1) {
                errorList.addError(new SiteError({
                  title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.',
                  msg: `${site.name} 과 ${field.name}의 이격된 라인이 자동 보정 되었습니다. `,
                  type: ErrorType.Info,
                  id: [field.uuid, site.uuid],
                  targetFields: [field, site],
                }));

              }
            }
          });
      }

  
      if (sitePolygon) {
        // 폴리곤 이격 체크 1. 대지 - 도로
        if (fields.roads && fields.roads.length > 0) {
          let roadPoly = fields.roads[0].getUnionJstsPolygon();
          let polygon: jsts.geom.Polygon;
          if (roadPoly) {
            //@ts-ignore
            polygon = roadPoly.buffer(0.1);
            fields.roads.forEach((road, i) => {
              if (i !== 0) {
                let roadPoly = road.getUnionJstsPolygon();
                if (roadPoly) {
                  //@ts-ignore
                  polygon = polygon.union(roadPoly.buffer(0.1))
                }
              }
            })
            roadUnion = polygon;
            testFieldsApart(fields.roads, roadUnion);
          }



        }
        // 2. 대지-공지
        if (fields.grounds && fields.grounds.length > 0) {
          //@ts-ignore
          let unionPoly = sitePolygon;
          if (roadUnion!) {
            unionPoly = sitePolygon.union(roadUnion!);
          }
          else {
            fields.grounds.forEach(ground => {
              //@ts-ignore
              unionPoly = sitePolygon.union(ground.getUnionJstsPolygon().buffer(0.1));
            })
          }

          testFieldsApart(fields.grounds, unionPoly!);
        }
     
        // 도로영역 or 인접대지 경계선이 없을경우 error
        let isRoadAvaliable = false;
  
        if (fields.roads && fields.roads.filter(road => !road.unused).length > 0) {
          isRoadAvaliable = true;
        }
     
        if (!isRoadAvaliable) {
          errorList.addError(new SiteError({
            title: '저장 할수 없는 구조의 폴리곤이 존재 합니다.', 
            msg: `저장 할수 있는 도로영역 폴리곤이 존재하지 않습니다. 
            나의 사업영역 등록시 최소 하나 이상의 도로 영역이 (RA) 선택 되어야 합니다.
            캐드에서 재설정 후 다시 시도해 주세요. `,
            type: ErrorType.Error,
            id: [],
          }));

       
        }
      }
    })
  }

}



function checkInsideCadastralMap(parsingOutput: BlockParsingData, errorList: ErrorList) {


  // 지적도 밖의 폴리라인 체크
  if (parsingOutput.cadastralMap!.length > 0) {
    let boundaryVerts = parsingOutput.cadastralMap![0].boundary.vertices;
    let boundary: jsts.geom.Geometry;
    let coords: jsts.geom.Coordinate[] = [];

    boundaryVerts.forEach((v: { x: number, y: number }) => {
      coords.push(new jsts.geom.Coordinate(v.x, v.y));
    })

    let geoFac = new jsts.geom.GeometryFactory();
    let linearRing = geoFac.createLinearRing(coords);

    //@ts-ignore
    boundary = geoFac.createPolygon(linearRing, []).buffer(0);

    parsingOutput.fields.forEach((field: ConverterField) => {
      //let polygons = GetJSTSPolygonFromField(field);
      //let polygon = field.getUnionJstsPolygon();
      field.getUnionJstsPolygon()
      field.renderGroup.updateWorldMatrix(true, true);
      let matrix = field.renderGroup.matrixWorld;

      let polygon: jsts.geom.Geometry;
      if (field.parts[0]) {
        polygon = field.parts[0].getJSTSPolygon(matrix);
        field.parts.forEach((p, i) => {
          if (i !== 0)
            polygon = polygon.union(p.getJSTSPolygon(matrix));
        })

        if (polygon && CheckPolygonsApart([polygon], [boundary])) {
          

          //  경관(특수) 영역 / 공지영역
          if (field.typeName === FieldType.vacancyInside || field.typeName === FieldType.vacancyOutside) {
            field.parts.forEach(p => {
              p.renderGroup.children.forEach(lineMesh => {
                let obj = lineMesh;
                p.renderGroup.remove(obj);
              })
            })

            // 블록 제거
            parsingOutput.fields.forEach((f, i) => {
              if (f.uuid === field.uuid) parsingOutput.fields.splice(i, 1);
            })
            errorList.addError(new SiteError({
              title: '[자동 보정] 캐드컨버터 설정 값에 따라 자동보정 되었습니다.', 
              msg: `${field.name}의 경우 다운 받는 지적도 영역을 벗어나 자동 삭제 되었습니다.`,
              type: ErrorType.Info,
              id: [field.uuid],
            }));
         
          }
          else { // 대지,도로,인접대지,고저차
            errorList.addError(new SiteError({
              title: '[형태적 오류] 유효하지 않은 데이터가 존재합니다.', 
              msg: `${field.name}이 다운로드 범위에 없습니다.`,
              type: ErrorType.Error,
              id: [field.uuid],
            }));
         
         
            // errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 데이터가 존재합니다.',
            // `${field.name}이 다운로드 범위에 없습니다.`, new THREE.Group()));
      
          }

        }
      }
    })
  }
  else {
//    errorLogs.push(makeErrorInformation2('지적도 다운로드 후 나의사업영역을 그려주세요.'));
  }
}


function checkSiteName(parsingOutput: BlockParsingData, type: ConverterType, errorList: ErrorList) {
 
  if (type === ConverterType.mySite && parsingOutput.buildings.length > 0) {
    //let filtered = parsingOutput.buildings.filter(building => building.name !== 'A$Cd09eb8b0'&& building.name !== 'DIMDOT');
    if (parsingOutput.buildings.length){ //if (filtered.length > 0) {
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
        msg: `나의 사업영역은 블록 'SA_면적, RA, GA, LA, AA, EL_01_높이' 만을 허용합니다.`,
        type: ErrorType.Error,
        id: [],
      }));

      // errorLogs.push(makeErrorInformation2('[블록 구조 오류] 잘못된 블록 구조입니다.', 
      // `나의 사업영역은 블록 'SA_면적, RA, GA, LA, AA, EL_01_높이' 만을 허용합니다.`));
    }
  }

  const sites = parsingOutput.fields.filter(field => field.typeName === FieldType.site);
  sites.forEach(site => {
    let splitedName = site.name.split('_');
    if ((splitedName.length === 1) || Number(splitedName[1]) === 0) {
      errorList.addError(new SiteError({
        title: '[자동 보정] 원치 않을 시 캐드에서 재설정 후 다시 진행해 주세요.', 
        msg: `${site.name}의 면적이 누락 되어 계산 면적으로 입력됩니다.`,
        type: ErrorType.Info,
        id: [site.uuid],
      }));
      // errorLogs.push(makeInfoInformation('[자동 보정] 원치 않을 시 캐드에서 재설정 후 다시 진행해 주세요.',
      // `${site.name}의 면적이 누락 되어 계산 면적으로 입력됩니다. `));
    }
  });


}

function checkClosedPolygon(fields: ConverterField[], errorList: ErrorList) {
  let errorLogs: ErrorLogCell2[] = [];

  fields.forEach((field: ConverterField) => {
    let group = new THREE.Group();
    let diameter = 0;
    switch (field.unitScale) {
      case 0.001: // mm
        diameter = 5;
        break;
      case 1: // m
        diameter = 5000;
        break;
      case 39.3701: // inch
        diameter = 100000;
        break;
      default:
        break;
    }

    field.parts.forEach(part => {
      if (!part.shape) {
        part.renderGroup.updateWorldMatrix(true, true);
        let matrixWorld = part.renderGroup.matrixWorld;
        let v1 = new THREE.Vector3(part.verts[0].x, part.verts[0].y, 0).applyMatrix4(matrixWorld);
        let v2 = new THREE.Vector3(part.verts[part.verts.length - 1].x, part.verts[part.verts.length - 1].y, 0).applyMatrix4(matrixWorld);
        
        let circleMesh1 = getCircle(v1, v1, new THREE.Color(1, 0, 0), 5, diameter);
        let circleMesh2 = getCircle(v2, v2, new THREE.Color(1, 0, 0), 5, diameter);
        group.renderOrder = 1;
        group.add(circleMesh1);
        group.add(circleMesh2);
      }
      field.renderGroup.add(group);
    });
    
    if (group.children.length > 0) {
      errorList.addError(new SiteError({
        title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
        msg: `${field.name}에 닫혀 있지 않은 Poly line 이 있습니다.`,
        type: ErrorType.Error,
        id: [field.uuid],
        //@ts-ignore
        hilightPolygon: group,
      }));

    //  errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', `${field.name}에 닫혀 있지 않은 Poly line 이 있습니다. `, group));
    }
  })
  return errorLogs;
}

function checkSiteRound(fields: ConverterField[], errorList: ErrorList) {

  fields.forEach(field => {
    let group = new THREE.Group();
    field.renderGroup.updateWorldMatrix(true, true);
    let matrix = field.renderGroup.matrixWorld;
    group.applyMatrix4(matrix);
    field.parts.forEach(part => {
      if (part.hasCurve) {
        group.add(getCurveErrorCircleField(part));
        errorList.addError(new SiteError({
          title: '[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
          msg: `${field.name}에 ARC 가 있습니다.`,
          type: ErrorType.Error,
          id: [field.uuid],
          //@ts-ignore
          hilightPolygon: group,
        }));
        // errorLogs.push(makeErrorInformation2('[형태적 오류] 유효하지 않은 폴리곤이 존재합니다.', 
        // `${field.name}에 ARC 가 있습니다.`, group));
      }   
    })
    field.ErrorPolygonGroup.add(group);
  })

}

function checkSiteStructure(fields: { sites: ConverterField[], roads: ConverterField[], roadCenterLines: ConverterField[] }, allFields: ConverterField[], errorList: ErrorList) {

  if (fields.sites.length < 1) {
    errorList.addError(new SiteError({
      title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
      msg: `최소 하나의 대지 영역(SA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
      type: ErrorType.Error,
      id: [],
    }));
  }
  else {
    let siteAreaNotZero = fields.sites.filter(site => site.getArea() > 0);
    if (fields.sites.length > 0 && siteAreaNotZero.length === 0) {
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
        msg: `최소 하나의 대지 영역(SA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
        type: ErrorType.Error,
        id: [],
      }));
    }
  }

  if (fields.roads.length < 1) { // && fields.roadCenterLines.length < 1) {
    errorList.addError(new SiteError({
      title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
      msg: `최소 하나의 도로 영역(RA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
      type: ErrorType.Error,
      id: [],
    }));
  }
  else {
    // 면적이 0인 도로블록
    let roadAreaNotZero = fields.roads.filter(road => road.getArea() > 0);
    //  let roadCenterLineAreaNotZero = fields.roadCenterLines.filter(centerLine => centerLine.getArea() > 0);

    // if ((fields.roadCenterLines.length === 0 && fields.roads.length > 0 && roadAreaNotZero.length === 0) ||
    //   (fields.roads.length === 0 && fields.roadCenterLines.length > 0 && roadCenterLineAreaNotZero.length === 0)) {
    if (fields.roads.length === 0 || roadAreaNotZero.length === 0) {
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
        msg: `최소 하나의 도로 영역(RA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
        type: ErrorType.Error,
        id: [],
      }));
    }
  }


  allFields.forEach(field => {    
    if (field.isMultiple) {
      let mainMsg = "[자동 선택] 원치 않을 시 캐드에서 재설정 후 다시 진행해 주세요.";
      let subMsg = "";

      switch(field.typeName) {
        case FieldType.site:
          subMsg = "대지 영역 블록은 하나의 대지 영역 Polygon 만을 허용합니다. 가장 큰 면적의 대지 영역이 자동으로 선택됩니다.";
          break;
        case FieldType.road:
          subMsg = "도로 영역 블록은 하나의 도로 Polygon 만을 허용합니다. 가장 큰 면적의 도로 영역이 자동으로 선택됩니다. ";
          break;
        case FieldType.centerLineOfRoad:
          subMsg = "나의사업영역은 하나의 인접 대지 경계선만을 허용합니다. 가장 큰 면적의 인적 대지 경계선이 자동으로 선택됩니다.";
          break;
        case FieldType.vacancyInside:
          subMsg = "경관 영역 블록은 하나의 배치 제한 영역 Polygon 만을 허용합니다. 가장 큰 면적의 배치 제한 영역이 자동으로 선택됩니다.";
          break;
        case FieldType.vacancyOutside:
          subMsg = "공지영역 블록은 하나의 공지영역 Polygon 만을 허용합니다. 가장 큰 면적의 공지 영역이 자동으로 선택됩니다.";
          break;
        case FieldType.topography:
          subMsg = "고저차 블록은 하나의 고저차 (성절토라인) 만을 허용합니다. 가장 큰 면적의 고저차 영역이 자동으로 선택됩니다.";
          break;
        default:
          break;
      }
      let unusedParts: FieldPart[] = [];

      field.parts.forEach(part => {
        if (part.unused) {
          unusedParts.push(part);
        }
      })
      let filter = allFields.filter(f => f.uuid !== field.uuid);
      errorList.addError(new SiteError({
        title: '[블록 구조 오류] 잘못된 블록 구조입니다.', 
        msg: `최소 하나의 도로 영역(RA)이 선택 되어야 하며, 면적은 0㎡ 보다 커야합니다.`,
        type: ErrorType.Error,
        id: filter.map(f => f.uuid),
        targetFields: filter,
      }));

      // errorLogs.push(makeInfoInformation(mainMsg, subMsg, new THREE.Group(), [], undefined,
      //   {
      //     fields: filter,
      //     parts: unusedParts,
      //   }
      // ));

    }
  });

}

