import React, { Component, Fragment } from "react";
import "./css/Tooltip.scss";
import { default as RcTooltip } from "rc-tooltip";
import { default as _ } from "lodash";
import "rc-tooltip/assets/bootstrap_white.css";

/* 
  eventType: hover | click | undefined ( default hover )
  msg: string | JSX.Element | undefined
  delay: number | undefined ( default 0 )
  place: bottom | top | left | right ( default bottom )
  on: boolean | undefined ( default undefined )
 */

export interface TooltipProps {
  id?: string;
  className?: string;
  msg?: string | JSX.Element;
  eventType?: "mouseover" | "click";
  place?: "bottom" | "top" | "right" | "left";
  delay?: number;
  on?: boolean;
  arrowOn?: boolean; // 화살표 표시 여부
}
export interface TooltipState {}

export default class Tooltip extends Component<TooltipProps, TooltipState> {
  componentDidUpdate = (pp: Readonly<TooltipProps>) => {};

  render() {
    if (this.props.msg) {
      return (
        (this.props.on !== undefined && (
          <RcTooltip
            id={this.props.id}
            overlay={this.props.msg}
            placement={this.props.place || "bottom"}
            trigger={((this.props.eventType === "mouseover" || this.props.eventType === undefined) && "hover") || "click"}
            arrowContent={<div className={`rc-tooltip-arrow-inner ${(this.props.place === undefined && "bottom top") || this.props.place}`}></div>}
            visible={this.props.on}
            mouseLeaveDelay={this.props.delay === undefined ? 0 : this.props.delay / 1000}
            destroyTooltipOnHide={true}
          >
            <span className={`${this.props.className} tooltip-wrap ${this.props.eventType === "click" && "tooltip-click"}`}>{this.props.children}</span>
          </RcTooltip>
        )) || (
          <RcTooltip
            id={this.props.id}
            overlay={this.props.msg}
            overlayStyle={{ display: (this.props.msg === undefined && "none") || "black" }}
            placement={this.props.place || "bottom"}
            trigger={((this.props.eventType === "mouseover" || this.props.eventType === undefined) && "hover") || "click"}
            arrowContent={(this.props.arrowOn === false && <div></div>) || <div className={`rc-tooltip-arrow-inner ${(this.props.place === undefined && "bottom top") || this.props.place}`}></div>}
            destroyTooltipOnHide={true}
          >
            <span className={`${this.props.className} tooltip-wrap ${this.props.eventType === "click" && "tooltip-click"}`}>{this.props.children}</span>
          </RcTooltip>
        )
      );
    } else {
      return this.props.children;
    }
  }
}
