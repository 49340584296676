import React, { Component, Fragment } from "react";
import SessionComponent from "./SessionComponent";
import App from "./App";
import "./css/ProjectForm.scss";
import "./css/BuilditUI.scss";
import BuilditTemplate from "./BuilditTemplate";
import { TextField, Input, InputAdornment, IconButton, Button, ClickAwayListener, CircularProgress, Snackbar } from "@material-ui/core";
import Tooltip from "./Tooltip";
import ProjectFormMap from "./ProjectFormMap";
import ProjectFormDetail from "./ProjectFormDetail";
import { Project, BuildingType, ProjectUseDistrict, ProjectHousingPlanType, ProjectBuildingShapeType, BuildingShapeType, SolverTypes, ProjectType } from "./model/Project";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { default as _ } from "lodash";
import ProjectInput, { ProjectFormPropsBase } from "./ProjectInput";
import AWS from "aws-sdk";

import Axios from "axios";
import { List_Element } from "./BuilditSelect";

import InfoIcon from "@material-ui/icons/InfoOutlined";
import SyncAltRoundedIcon from "@material-ui/icons/SyncAltRounded";
import RefreshIcon from "@material-ui/icons/Refresh";
import SaveIcon from "@material-ui/icons/Save";
import ExitIcon from "@material-ui/icons/ExitToApp";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DownArrowIcon from "@material-ui/icons/KeyboardArrowDown";
import AWSModule from "./AWSModule";
import Modal, { ModalOptions } from "./Modal";

import moment, { min } from "moment";

import queryString from "query-string";
import ProjectFieldInput from "./ProjectFieldInput";
import DrawingManager2 from "./DrawingManager2";
import { projectInputValidCheck, ConstraintErrors, projectInputValidCheckSome } from "./Constraints";
import BuilditInput from "./BuilditInput";
import ProjectSelectOptions from "./SelectOptions";
import DownloadButton from "./DownloadButton";
import SwapIcon from "@material-ui/icons/SwapHoriz";
import DiscountTag from "./DiscountTag";
import { Home, KeyboardArrowRight, OpenInNew } from "@material-ui/icons";
import { Link } from "react-router-dom";
import CopyProjectInfo from "./CopyProjectInfo";

export interface JiguInfo {
  pnu?: string;
  address?: string;
  wkt?: string;
  jimog?: string;
  // use_district?: string,
  active?: boolean;
  hover?: boolean;
  // url?: string,
  // info?: {
  //   "국토법": {
  //     "저촉": string[],
  //     "포함": string[],
  //     "접함": string[],
  //   },
  //   "기타법": {
  //     "저촉": string[],
  //     "포함": string[],
  //     "접함": string[],
  //   }
  // }
}

export interface 프로젝트_기본값 {
  가능여부?: boolean;
  건폐율?: number;
  대지안의_공지_건축선후퇴?: number;
  대지안의_공지_인접대지경계선후퇴?: number;
  동타입_기본값?: Array<string>;
  세대타입_기본값?: Array<number>;
  용적률?: number;
  인동간격_벽면_측벽?: number;
  인동간격_채광창_다른건물?: number;
  인동간격_측벽_측벽?: number;
  정북일조_9m이하?: number;
  정북일조_9m초과?: number;
  채광사선_채광창_인접대지경계선?: number;
  최고층수?: number;
  최저층수?: number;
  평균층수?: number;
  허용여부?: boolean;
}

export interface 표기_기본값 extends 프로젝트_기본값 {
  용도지역?: ProjectUseDistrict;
  건물종류?: BuildingType;
  층고?: number;
}

export interface ProjectFormProps {
  edit: boolean;
  match: any;
}
export interface ProjectFormState {
  openMapConfig: boolean;
  openDetailConfig: boolean;
  currentProject: Project;
  jiguInfo: Array<Array<JiguInfo>>;
  openModal: boolean;
  modalOptions?: ModalOptions;
  resetNum: number;
  convertNum: number;
  openRoadConfig: boolean;
  openImport: boolean;
  openSelectedBuildingShape: boolean;
  openBuildingDrawer: boolean;
  openHousingChart: boolean;
  openJiguInfo: boolean;
  openSaveTooltip: boolean;
  loading: boolean;
  cadastralMap: boolean;
  projectErrors: ConstraintErrors;
  projectDefault?: Project;
  openSnack: boolean;
  snackMsg: JSX.Element;
  configTutorial: boolean;
  editProjectName: boolean;
  editProjectExplanation: boolean;
  buildingDefault: Map<string, 프로젝트_기본값>;
  initDefault?: 표기_기본값;
  canRunProject: boolean;
}

export type reset_type = "All" | "Value";

export default class ProjectForm extends SessionComponent<ProjectFormProps, ProjectFormState> {
  state: ProjectFormState = {
    openMapConfig: true,
    openDetailConfig: false,
    currentProject: new Project({
      email: App.session.email,
      stage: App.tempStage,
      project_type: App.stage === "prod" ? "DESIGNER" : this.props.match.params.projectType, // "DESIGNER": "AI"
      building_type: App.stage === "prod" ? "아파트" : undefined,
    }),
    jiguInfo: [],
    openModal: false,
    resetNum: 0,
    convertNum: 0,
    openRoadConfig: false,
    openImport: false,
    openSelectedBuildingShape: false,
    openBuildingDrawer: false,
    openHousingChart: false,
    openJiguInfo: false,
    openSaveTooltip: false,
    loading: false,
    cadastralMap: true,
    projectErrors: { errs: {} },
    openSnack: false,
    snackMsg: <div></div>,
    configTutorial: this.props.edit ? false : window.localStorage.getItem("config_tutorial") === null ? true : window.localStorage.getItem("config_tutorial") === "true",
    editProjectName: false,
    editProjectExplanation: false,
    buildingDefault: new Map<string, 프로젝트_기본값>(),
    initDefault: undefined,
    canRunProject: true,
  };

  createProject: boolean = this.props.edit;

  componentDidUpdate = (pp: Readonly<ProjectFormProps>, ps: Readonly<ProjectFormState>) => {
    // console.log("project_address", this.state.currentProject.project_address);

    let update: Project = {};
    if (!_.isEqual(this.state.currentProject.building_shape_type, ps.currentProject.building_shape_type)) {
      if (this.state.currentProject.building_shape_type!.length === 0) {
        update.housing_plan_type = [];
      }
    }
    if (!_.isEqual(ps.currentProject.building_type, this.state.currentProject.building_type)) {
      if (ps.currentProject.building_type !== undefined) {
        update.building_shape_type = [];
        update.my_building_shape_type = [];
        update.housing_plan_type = [];
      }
    }

    if (Object.keys(update).length > 0) {
      if (this.state.currentProject.auto_setting === false) {
        // auto setting이면 빈 값을 넣어주지않고 default 값으로 설정
        this.onUpdateProject(update);
      }
    }
  };

  componentWillMount = async () => {
    this.onSaveProject = _.debounce(this.onSaveProject, 500);
    this.onUpdateAddress = _.debounce(this.onUpdateAddress, 500);
    if (this.props.edit) {
      const qs = queryString.parse(location.search);
      if (qs && qs.pid) {
        try {
          const l_res = await App.search({
            table: "platform-buildit-project",
            query: `stage:${App.tempStage} AND email.keyword:${App.session.email} AND user_project_id:${Number(qs.pid)} AND deleted:false`,
            from: 0,
            size: 1,
          });

          const l_result = l_res.data;
          const project = l_result.hits.hits[0]._source;
          const ddb = await new AWSModule("DDB").connect();
          const d_res = await ddb
            .Ddb!.get({
              ConsistentRead: true,
              TableName: "platform-buildit-project",
              Key: {
                stage: App.tempStage,
                project_id: project.project_id,
              },
            })
            .promise();
          if (d_res.$response.error) {
            // console.log("err", d_res.$response.error);
          } else {
            const project = d_res.Item!;
            if (["WAITING", "RUNNING", "FINISHED", "FAILED"].includes(project.status)) {
              // @ts-ignore
              this.props.history.push(`/project/payment?pid=${project.user_project_id}`);
            } else {
              this.setState({ currentProject: new Project(project) }, () => {
                // if (this.state.currentProject.auto_setting) {
                //   this.onUpdateDefaults();
                // }
                this.onUpdateDefaults(undefined, undefined, "All");
              });
            }
          }
        } catch (e) {
          console.log("err", e);
        }
      }
    }
  };
  setEditProjectName = (edit: boolean) => {
    this.setState({ editProjectName: edit });
  };
  setEditProjectExplanation = (edit: boolean) => {
    this.setState({ editProjectExplanation: edit });
  };

  handleSnackClose = () => {
    this.setState({ openSnack: false, snackMsg: <div></div> });
  };

  render() {
    // console.log(this.state.currentProject);
    if (this.props.edit && this.state.currentProject.project_id === undefined) {
      return <div></div>;
    } else {
      //   App.stage !=="prod" && console.log(this.state.currentProject);
      return (
        <BuilditTemplate footer={false} loading={this.state.loading} header={false} {...this.props}>
          {this.state.openModal && (
            <Modal
              open={this.state.openModal}
              type={(this.state.modalOptions && this.state.modalOptions.type && this.state.modalOptions.type) || "SIMPLE"}
              positive={this.state.modalOptions && this.state.modalOptions.positive}
              negative={this.state.modalOptions && this.state.modalOptions.negative}
              title={this.state.modalOptions && this.state.modalOptions.title}
              negativeTitle={this.state.modalOptions && this.state.modalOptions.negativeTitle}
              positiveTitle={this.state.modalOptions && this.state.modalOptions.positiveTitle}
              secondSelect={this.state.modalOptions && this.state.modalOptions.secondSelect}
              secondSelectTitle={this.state.modalOptions && this.state.modalOptions.secondSelectTitle}
              content={this.state.modalOptions && this.state.modalOptions.content}
            />
          )}
          <div className="ProjectForm">
            {(this.state.editProjectExplanation || this.state.editProjectName) && <div className="transparent-dim" />}
            {this.state.configTutorial && (
              <div className="tutorial-wrap">
                <div className="tutorial">
                  <img src="/img/tutorial.png" />
                  <Button
                    onClick={() => {
                      this.setState({ configTutorial: false });
                    }}
                    disableRipple={true}
                    className="close-btn"
                  >
                    <CloseIcon className="close-icon" />
                  </Button>
                  <Button
                    onClick={() => {
                      window.localStorage.setItem("config_tutorial", "false");
                      this.setState({ configTutorial: false });
                    }}
                    disableRipple={true}
                    className="never-close-btn"
                  >
                    다시 보지않기 <CloseIcon className="close-icon" />
                  </Button>
                  <DownloadButton
                    className="guide-btn"
                    onClick={(callback?: Function) => {
                      if (callback) {
                        callback();
                      }
                    }}
                  >
                    <a download target="_blank" href="https://teneleven-public-download-data.s3.ap-northeast-2.amazonaws.com/Manual/BUILDIT_MANUAL.pdf">
                      <div>사용자 가이드</div>
                      <span className="m-l-a" style={{ textTransform: "none" }}>
                        pdf
                      </span>
                    </a>
                  </DownloadButton>
                </div>
              </div>
            )}
            <div className="head">
              <ProjectFormHead
                setModal={this.setModal}
                resetNum={this.state.resetNum}
                setLoading={this.setLoading}
                onResetProject={this.onResetProject}
                currentProject={this.state.currentProject}
                onUpdateProject={this.onUpdateProject}
                openSaveTooltip={this.state.openSaveTooltip}
                onSaveProject={this.onSaveProject}
                edit={this.props.edit}
                onExit={this.onExit}
                projectErros={this.state.projectErrors}
                checkProjectErros={this.checkProjectErros}
                projectDefault={this.state.projectDefault}
                editProjectName={this.state.editProjectName}
                editProjectExplanation={this.state.editProjectExplanation}
                setEditProjectName={this.setEditProjectName}
                setEditProjectExplanation={this.setEditProjectExplanation}
                disableComplete={!this.state.canRunProject}
                convertProjectType={this.convertProjectType}
                {...this.props}
              />
            </div>
            <div className={`content`}>
              <ProjectFormMap
                resetNum={this.state.resetNum}
                convertNum={this.state.convertNum}
                onResetProject={this.onResetProject}
                setModal={this.setModal}
                openMapConfig={this.state.openMapConfig}
                setOpenMapConfig={this.setOpenMapConfig}
                setOpenImport={this.setOpenImport}
                setOpenRoadConfig={this.setOpenRoadConfig}
                currentProject={this.state.currentProject}
                onUpdateProject={this.onUpdateProject}
                onUpdateAddress={this.onUpdateAddress}
                onCreateProject={this.onCreateProject}
                setOpenDetailConfig={this.setOpenDetailConfig}
                onSaveProject={this.onSaveProject}
                edit={this.props.edit}
                setLoading={this.setLoading}
                cadastralMap={this.state.cadastralMap}
                setCadastralMap={this.setCadastralMap}
                openJiguInfo={this.state.openJiguInfo}
                setOpenJiguInfo={this.setOpenJiguInfo}
                updateJiguInfo={this.updateJiguInfo}
                onUpdateDefaults={this.onUpdateDefaults}
                projectErros={this.state.projectErrors}
                {...this.state}
                {...this.props}
              />
              <ProjectFormDetail
                openDetailConfig={this.state.openDetailConfig}
                setOpenDetailConfig={this.setOpenDetailConfig}
                currentProject={this.state.currentProject}
                onUpdateProject={this.onUpdateProject}
                openBuildingDrawer={this.state.openBuildingDrawer}
                setOpenBuildingDrawer={this.setOpenBuildingDrawer}
                openHousingChart={this.state.openHousingChart}
                setOpenHousingChart={this.setOpenHousingChart}
                openJiguInfo={this.state.openJiguInfo}
                setOpenJiguInfo={this.setOpenJiguInfo}
                jiguInfo={this.state.jiguInfo}
                onUpdateAddress={this.onUpdateAddress}
                onUpdateDefaults={this.onUpdateDefaults}
                updateJiguInfo={this.updateJiguInfo}
                projectErros={this.state.projectErrors}
                buildingDefault={this.state.buildingDefault}
                setModal={this.setModal}
                initDefault={this.state.initDefault}
              />
            </div>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.openSnack}
              onClose={this.handleSnackClose}
              className="snack-bar"
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<div className="msg">{this.state.snackMsg}</div>}
            />
          </div>
        </BuilditTemplate>
      );
    }
  }

  checkProjectErros = (callback?: Function) => {
    this.setState({ projectErrors: projectInputValidCheck(this.state.currentProject) }, () => {
      if (callback) {
        callback();
      }
    });
  };

  setLoading = (on: boolean, callback?: Function) => {
    this.setState({ loading: on }, () => {
      callback && callback();
    });
  };

  setModal = (open: boolean, options?: ModalOptions) => {
    this.setState({
      openModal: open,
      modalOptions: options,
    });
  };

  /* TODO: open object로 묶어서 처리하는걸로 나중에 바꾸기 */

  setOpenRoadConfig = (open: boolean) => {
    this.setState({ openRoadConfig: open });
  };
  setOpenImport = (open: boolean) => {
    this.setState({ openImport: open });
  };
  // setSelectedBuildingShape = (open: boolean) => { this.setState({ openSelectedBuildingShape: open }) }
  setOpenMapConfig = (open: boolean) => {
    this.setState({ openMapConfig: open });
  };
  setOpenDetailConfig = (open: boolean) => {
    this.setState({ openDetailConfig: open });
  };
  setOpenBuildingDrawer = (open: boolean) => {
    this.setState({ openBuildingDrawer: open });
  };
  setOpenHousingChart = (open: boolean) => {
    this.setState({ openHousingChart: open });
  };
  setOpenJiguInfo = (open: boolean) => {
    this.setState({ openJiguInfo: open });
  };

  onUpdateProject = (update: Project, callback?: Function) => {
    if (this.createProject === false && update.project_site) {
      // only once
      this.createProject = true;
      this.onCreateProject();
    }

    const newProject = new Project({
      ..._.cloneDeep(this.state.currentProject),
      ..._.cloneDeep(update),
    });

    // console.log('onUpdateProject', projectInputValidCheck(newProject), this.state.projectDefault);
    this.setState(
      {
        projectErrors: this.state.projectDefault !== undefined ? projectInputValidCheck(newProject) : this.state.projectErrors,
        currentProject: newProject,
        canRunProject: true,
      },
      async () => {
        callback && callback();
        this.createProject && this.onSaveProject();
      }
    );
  };

  onResetProject = (fixField?: Partial<Project>, callback?: Function) => {
    if (this.createProject === false && fixField && fixField.project_site) {
      // only once
      this.createProject = true;
      this.onCreateProject();
    }

    // reset시 디자이너 프로젝트 항상 building_type 아파트
    if (this.state.currentProject.project_type !== "AI") {
      if (fixField) {
        fixField.building_type = "아파트";
      } else {
        fixField = { building_type: "아파트" };
      }
    }

    const newProject = new Project({
      stage: this.state.currentProject.stage,
      project_id: this.state.currentProject.project_id,
      project_name: "",
      copy_project_id: this.state.currentProject.copy_project_id,
      user_project_id: this.state.currentProject.user_project_id,
      created_at: _.cloneDeep(this.state.currentProject.created_at),
      discountable: this.state.currentProject.discountable,
      email: _.cloneDeep(this.state.currentProject.email),
      favorite: this.state.currentProject.favorite,
      auto_name: true,
      auto_setting: true,
      ...fixField,
    });

    this.setState(
      {
        resetNum: this.state.resetNum + 1,
        currentProject: newProject,
        projectErrors: { errs: {} },
        projectDefault: undefined,
      },
      () => {
        callback && callback();
      }
    );
  };

  onSaveProject = (callback?: Function) => {
    const newProject = _.cloneDeep(this.state.currentProject);
    newProject.modified_at = new Date().toISOString();
    newProject.viewed_at = new Date().toISOString();
    this.setState({ currentProject: newProject }, async () => {
      const lambda = await new AWSModule("LAMBDA").connect();
      const cp = _.cloneDeep(this.state.currentProject);

      const saveProject: Project = {};
      Object.keys(cp).forEach((e) => {
        if (cp[e as keyof Project] !== "" && cp[e as keyof Project] !== undefined) {
          // @ts-ignore
          saveProject[e as keyof Project] = cp[e as keyof Project];
        }
      });

      const r = await lambda
        .Lambda!.invoke({
          FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
          Payload: JSON.stringify([
            {
              TableName: "platform-buildit-project",
              Item: {
                stage: App.tempStage,
                ...saveProject,
                project_name: (cp.project_name && cp.project_name) || "제목없는 프로젝트",
              },
            },
          ]),
        })
        .promise();

      const r_res = JSON.parse(r.Payload as string);
      if (r_res.success) {
        callback && callback();
        return;
      } else {
        // console.log(r_res.reason);
      }
    });
  };

  onCreateProject = async () => {
    const ddb = await new AWSModule("DDB").connect();
    const project_id = await ddb
      .Ddb!.update({
        TableName: "platform-buildit-counter",
        Key: { name: `${App.tempStage}/project_id` },
        ExpressionAttributeNames: { "#counter": "counter" },
        ExpressionAttributeValues: { ":counter": 1 },
        UpdateExpression: "SET #counter = #counter + :counter",
        ReturnValues: "UPDATED_OLD",
      })
      .promise();

    const is_exist_user_project_id = await ddb
      .Ddb!.get({
        TableName: "platform-buildit-counter",
        Key: {
          name: `${App.tempStage}/project_id/${this.state.currentProject.email}`,
        },
      })
      .promise();

    // counter db update at creating first project
    if (is_exist_user_project_id.Item === undefined) {
      await ddb
        .Ddb!.put({
          TableName: "platform-buildit-counter",
          Item: {
            name: `${App.tempStage}/project_id/${this.state.currentProject.email}`,
            counter: 1,
          },
        })
        .promise();
    }

    const user_project_id = await ddb
      .Ddb!.update({
        TableName: "platform-buildit-counter",
        Key: { name: `${App.tempStage}/project_id/${this.state.currentProject.email}` },
        ExpressionAttributeNames: { "#counter": "counter" },
        ExpressionAttributeValues: { ":counter": 1 },
        UpdateExpression: "SET #counter = #counter + :counter",
        ReturnValues: "UPDATED_OLD",
      })
      .promise();

    if (!project_id.Attributes || !user_project_id.Attributes) {
      // error
      return;
    }

    this.onUpdateProject(
      {
        stage: App.tempStage, //App.stage,
        project_id: Number(project_id.Attributes.counter),
        user_project_id: Number(user_project_id.Attributes.counter),
      },
      () => {
        this.onSaveProject();
      }
    );
  };

  onUpdateAddress = async (callback?: Function) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    const jiguInfo: Array<Array<JiguInfo>> = [];

    // project_site가 변경되었는지 확인해서 처리하기

    for (let i = 0; i < this.state.currentProject.project_site!.length; i++) {
      try {
        const r = await App.API_Axios.post(
          "/JiguByArea",
          {
            project_site: [this.state.currentProject.project_site![i]],
            ratio: 80,
          },
          {
            headers: {
              Authorization: `Bearer ${App.jwt}`,
            },
          }
        );
        const rr = r.data;
        const result = rr.result.map((info: any) => {
          return {
            url: info.url,
            pnu: info.pnu,
            wkt: info.geom,
            address: info["주소"],
            jimog: info["지목"],
            info: {
              국토법: info["국토법"],
              기타법: info["기타법"],
            },
          };
        });
        jiguInfo.push(result);
      } catch (e) {
        // console.log("buildit-public-platform-JiguByArea-v20200306 - err", e);
      }
    }
    jiguInfo.map((j) => j.sort((a, b) => (a.pnu! <= b.pnu! ? -1 : 1)));
    this.setState({ jiguInfo: jiguInfo });

    if (this.state.currentProject.project_site!.length > 0) {
      let address = "";
      switch (this.state.currentProject.project_site_type!) {
        case "DRAW":
        case "IMPORT": {
          const site = this.state.currentProject.project_site![0]; // 첫번째것 기준
          const center = DrawingManager2.getPolygonCenter(site);
          const r = await App.API_Axios.post(`${App.API_URL}/GetFieldInfoAtPoint`, {
            ...center,
          });

          address = r.data.address + "일대";
          break;
        }
        case "SELECT": {
          const r = await App.search({
            table: "parcel-info",
            query: `pnu.keyword:${this.state.currentProject.field_info_pnu![0]}`,
          });

          const rr = r.data;
          if (rr.hits.hits && rr.hits.hits.length > 0) {
            if (this.state.currentProject.field_info_pnu!.length > 1) {
              address = rr.hits.hits[0]._source["주소"] + ` 외 ${this.state.currentProject.field_info_pnu!.length - 1}필지`;
            } else {
              address = rr.hits.hits[0]._source["주소"];
            }
          }
          break;
        }
      }

      const projectName = this.state.currentProject.auto_name ? `${address} - 프로젝트` : this.state.currentProject.project_name;
      this.onUpdateProject(
        {
          ...this.state.currentProject,
          project_address: address,
          project_name: projectName,
        },
        async () => {
          callback && callback();
          // if (this.state.currentProject.auto_setting) {
          //   this.onUpdateDefaults((success: boolean, defaults?: Project) => {
          //     if (success) {
          //       this.setState({
          //         openSnack: true,
          //         snackMsg: <div><CheckIcon className="icon m-r-sm" style={{color: "#95e4b3"}} />현재 대지영역에 적합한 값으로 설정 되었습니다.</div>
          //       });
          //       this.onUpdateProject({
          //         ...defaults
          //       });
          //     } else {
          //       this.setState({
          //         openSnack: true,
          //         snackMsg: <div><InfoIcon className="icon m-r-sm" style={{color: "#E81C1C"}} />"상세 설정"의 직접 설정이 필요합니다.</div>
          //       })
          //     }
          //   });
          //   // this.onUpdateDefaults2();
          // }
        }
      );
    }
  };
  updateJiguInfo = (jiguInfo: Array<Array<JiguInfo>>) => {
    this.setState({
      jiguInfo: jiguInfo,
    });
  };

  onUpdateDefaults = async (set_buildingType?: BuildingType, set_use_distirct?: ProjectUseDistrict, reset?: reset_type) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    let buildingType: BuildingType | undefined;

    switch (this.state.currentProject.project_type) {
      case "AI":
        if (set_buildingType) {
          buildingType = set_buildingType;
        } else {
          buildingType = this.state.currentProject.building_type;
        }
        break;
      case "DESIGNER":
      case "SITE_PLAN":
        buildingType = "아파트";
        break;
    }

    try {
      const r = await App.API_Axios.post(
        "/ProjectDefault",
        {
          project_site: this.state.currentProject.project_site,
          // project_use_district: this.state.currentProject.project_use_district,
          용도지역: set_use_distirct,
        },
        {
          headers: {
            ...App.headers,
          },
        }
      );

      // 정북일조<9: 1.5 9미터 이하
      // 정북일조>9: 0.5 9미터 초과
      let 기본값_건물종류 = ["아파트", "다세대", "오피스텔"];
      let return_default = new Map<string, 프로젝트_기본값>();
      let canRun = false;

      const rr = r.data;

      App.stage !== "prod" && console.log(rr);

      const d = rr;
      const dd = rr.result;
      for (let key of 기본값_건물종류) {
        if (dd[key]) {
          return_default.set(key, dd[key]);
          canRun = canRun || dd[key]["가능여부"];
        }
      }
      if (return_default.size < 1) {
        canRun = false;
      }
      // building type 결정 현재 솔버는 없어서 임시로 처리함
      let default_building_type: BuildingType = "아파트";
      let dr = dd["아파트"];

      if (this.state.currentProject.project_type === "AI") {
        if (set_buildingType) {
          default_building_type = set_buildingType;
          if (set_buildingType === "다세대주택") {
            dr = dd["다세대"];
          } else {
            dr = dd[set_buildingType];
          }
        } else {
          // 기본 값 자동 완성으로 건물 종류 결정
          /* 
            우선순위: 아파트 -> 오피스텔 -> 다세대
            아파트 가능? -> 아파트
            오피스텔 가능? -> 오피스텔
            다세대 가능? 다세대
          */
          if (!dd["아파트"]["가능여부"]) {
            if (dd["다세대"]["가능여부"]) {
              default_building_type = "다세대주택";
              dr = dd["다세대"];
            } else if (dd["오피스텔"]["가능여부"]) {
              default_building_type = "오피스텔";
              dr = dd["오피스텔"];
            } else {
              if (!dd["아파트"]["허용여부"]) {
                if (dd["다세대"]["허용여부"]) {
                  default_building_type = "다세대주택";
                  dr = dd["다세대"];
                } else if (dd["오피스텔"]["허용여부"]) {
                  default_building_type = "오피스텔";
                  dr = dd["오피스텔"];
                }
              }
            }
          }
        }
      } else if (this.state.currentProject.project_type === "DESIGNER" || this.state.currentProject.project_type === "SITE_PLAN") {
        default_building_type = "아파트";
        dr = dd["아파트"];
      } else {
        default_building_type = "아파트";
        dr = dd["아파트"];
      }

      switch (reset) {
        case "All":
          this.setState({ initDefault: { 용도지역: d["용도지역"], 건물종류: default_building_type, 층고: 2.8, ...dr } });
          break;
        case "Value":
          this.setState({ initDefault: { 용도지역: this.state.initDefault!.용도지역, 건물종류: this.state.initDefault!.건물종류, 층고: 2.8, ...dr } });
          break;
        case undefined:
          // do nothing
          break;
        default:
          // do nothing
          break;
      }

      const buildingStoriesValue = [dr["최고층수"], dr["최저층수"], dr["평균층수"]];
      buildingStoriesValue.sort((a: number, b: number) => a - b);

      let defaults: Project = {};
      if (!canRun) {
        defaults = {
          building_type: undefined,
          project_use_district: undefined,
          building_shape_type: [],
          housing_plan_type: [],
          my_building_shape_type: [],
          //solver_type: dr['솔버']
        };
        // doNothing
      } else if (this.state.currentProject.project_type! === "AI") {
        defaults = {
          distance_between_side_opaque_walls: dr["인동간격_벽면_측벽"],
          distance_between_side_walls: dr["인동간격_측벽_측벽"],
          distance_between_window_opaque_walls: dr["인동간격_채광창_다른건물"],
          setback_regulation_from_site_boundary: dr["채광사선_채광창_인접대지경계선"],
          setback_regulation_from_north_less_9m: dr["정북일조_9m이하"],
          setback_regulation_from_north_less_9m_type: "METER",
          setback_regulation_from_north_more_9m: dr["정북일조_9m초과"],
          setback_regulation_from_north_more_9m_type: "HEIGHT",
          default_building_land_ratio: dr["건폐율"], // 기본 건폐율 추가 by hypark - with jmkim
          default_floor_area_ratio: dr["용적률"], // 기본 용적율 추가 by hypark - with jmkim
          building_land_ratio: dr["건폐율"],
          floor_area_ratio: dr["용적률"],
          building_type: default_building_type,
          project_use_district: d["용도지역"],
          building_stories_max: buildingStoriesValue[2],
          building_stories_min: buildingStoriesValue[0],
          building_stories_avg: buildingStoriesValue[1],
          building_stories_avg_type: "AREA",

          setback_building_line_apartment: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_multi_house: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_row_house: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_city_apartment: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_city_row_house: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_city_multi_house: dr["대지안의_공지_건축선후퇴"],
          setback_building_line_officetel: dr["대지안의_공지_건축선후퇴"],

          setback_site_boundary_apartment: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_multi_house: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_row_house: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_city_apartment: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_city_row_house: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_city_multi_house: dr["대지안의_공지_인접대지경계선후퇴"],
          setback_site_boundary_officetel: dr["대지안의_공지_인접대지경계선후퇴"],
          housing_plan_type: housingPlanConverter(dr["세대타입_기본값"]),
          building_shape_type: buildingShapeConverter(dr["동타입_기본값"]),
          my_building_shape_type: [],
          //solver_type: dr['솔버'],
          housing_rate: housingPlanConverter(dr["세대타입_기본값"])
            .map((r) => r.proportion!)
            .reduce((a, b) => a + b, 0),
          floor_height: 2.8,
        };
      } else {
        // canRun === true인 조건
        //if (buildingTypeConverter(dr['건물종류'], dr['솔버']) === "아파트") {
        if (dd["아파트"]) {
          defaults = {
            distance_between_side_opaque_walls: dr["인동간격_벽면_측벽"],
            distance_between_side_walls: dr["인동간격_측벽_측벽"],
            distance_between_window_opaque_walls: dr["인동간격_채광창_다른건물"],
            setback_regulation_from_site_boundary: dr["채광사선_채광창_인접대지경계선"],
            setback_regulation_from_north_less_9m: dr["정북일조_9m이하"],
            setback_regulation_from_north_less_9m_type: "METER",
            setback_regulation_from_north_more_9m: dr["정북일조_9m초과"],
            setback_regulation_from_north_more_9m_type: "HEIGHT",

            default_building_land_ratio: dr["건폐율"], // 기본 건폐율 추가 by hypark - with jmkim
            default_floor_area_ratio: dr["용적률"], // 기본 용적율 추가 by hypark - with jmkim
            building_land_ratio: dr["건폐율"],
            floor_area_ratio: dr["용적률"],
            building_type: default_building_type,
            project_use_district: d["용도지역"],
            building_stories_max: buildingStoriesValue[2],
            building_stories_min: buildingStoriesValue[0],
            building_stories_avg: buildingStoriesValue[1],
            building_stories_avg_type: "AREA",

            setback_building_line_apartment: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_multi_house: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_row_house: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_city_apartment: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_city_row_house: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_city_multi_house: dr["대지안의_공지_건축선후퇴"],
            setback_building_line_officetel: dr["대지안의_공지_건축선후퇴"],

            setback_site_boundary_apartment: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_multi_house: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_row_house: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_city_apartment: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_city_row_house: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_city_multi_house: dr["대지안의_공지_인접대지경계선후퇴"],
            setback_site_boundary_officetel: dr["대지안의_공지_인접대지경계선후퇴"],
            housing_plan_type: housingPlanConverter(dr["세대타입_기본값"]),
            building_shape_type: buildingShapeConverter(dr["동타입_기본값"]),
            my_building_shape_type: [],
            //solver_type: dr['솔버'],
            housing_rate: housingPlanConverter(dr["세대타입_기본값"])
              .map((r) => r.proportion!)
              .reduce((a, b) => a + b, 0),
            floor_height: 2.8,
          };
        } else {
          defaults = {
            building_type: "아파트",
            project_use_district: d["용도지역"],
            building_stories_avg_type: "AREA",
            building_shape_type: [],
            housing_plan_type: [],
            my_building_shape_type: [],
            //solver_type: dr['솔버']
          };
        }
      }

      // console.log('기본값', defaults);

      if (canRun) {
        this.setState(
          {
            projectDefault: defaults,
            buildingDefault: return_default,
            canRunProject: canRun,
            openSnack: this.state.currentProject.auto_setting!,
            snackMsg: (
              <div>
                <CheckIcon className="icon m-r-sm" style={{ color: "#95e4b3" }} />
                현재 대지영역에 적합한 값으로 설정 되었습니다.
              </div>
            ),
          },
          () => {
            if (this.state.currentProject.auto_setting) {
              this.onUpdateProject({ ...defaults });
            }
          }
        );
      } else {
        this.setState(
          {
            projectDefault: {} as Project,
            buildingDefault: return_default,
            canRunProject: canRun,
            openSnack: this.state.currentProject.auto_setting!,
            snackMsg: (
              <div>
                <InfoIcon className="icon m-r-sm" style={{ color: "#E81C1C" }} />
                "상세 설정"의 직접 설정이 필요합니다.
              </div>
            ),
          },
          () => {
            if (this.state.currentProject.auto_setting) {
              this.onUpdateProject({ ...defaults });
            }
          }
        );
        //        console.log(rr.reason);
      }
    } catch (e) {
      this.setState({ projectDefault: {} as Project, canRunProject: false });
      // console.log(e);
    }

    function officetelHeight(blr: number, far: number) {
      return Math.floor(far / blr);
    }

    // 현재는 솔버타입으로 역연산 (아파트 - 중규모)
    function buildingTypeConverter(type: string, solverType: SolverTypes) {
      let buildingType: BuildingType | undefined;
      switch (type) {
        case "아파트":
          switch (solverType) {
            case "대규모":
              buildingType = "아파트";
              break;
            // case "중규모": buildingType = "연립주택"; break;
            case "중규모":
              buildingType = "미정";
              break;
          }
          break;
        // case "연립": buildingType = "연립주택"; break;
        case "다세대":
          buildingType = "다세대주택";
          break;
        // case "도시형원룸": buildingType = "도시형생활주택 - 원룸"; break;
        // case "도시형연립": buildingType = "도시형생활주택 - 연립주택"; break;
        case "도시형다세대":
          buildingType = "도시형생활주택 - 다세대주택";
          break;
        case "오피스텔":
          buildingType = "오피스텔";
          break;
        default:
          buildingType = "미정";
      }
      return buildingType;
    }
    function buildingShapeConverter(type: string[]) {
      const r = type.filter((b: any) => {
        return !(b === "선택불가" || b === "-");
      });
      const bs = r.map((rr) => {
        return new ProjectBuildingShapeType(typeConverter(rr));
      });

      return bs;

      function typeConverter(type: string) {
        let figures: BuildingShapeType = "LINEAR_CORRIDOR_TYPE";

        const house_number = type.slice(2) ? Number(type.slice(2)) : 0;
        switch (type.slice(0, 2)) {
          case "LC":
            figures = "LINEAR_NON_CORRIDOR_TYPE";
            break;
          case "RC":
            figures = "FOLDED_NON_CORRIDOR_TYPE";
            break;
          case "TT":
            figures = "T_TOWER_TYPE";
            break;
          case "TY":
            figures = "Y_TOWER_TYPE";
            break;
          case "LH":
            figures = "LINEAR_CORRIDOR_TYPE";
            break;
          case "RH":
            figures = "FOLDED_CORRIDOR_TYPE";
            break;
        }

        return {
          figures: figures,
          houses_number: house_number,
        };

        // if (type === "복도식") {
        //   return {
        //     figures: "LINEAR_CORRIDOR_TYPE",
        //     houses_number: 4,
        //   }
        // } else {
        //   const houses = Number(type.split('호')[0]);
        //   const rType = type.split('호')[1];
        //   let buildingShapeType: BuildingShapeType = "FOLDED_CORRIDOR_TYPE";
        //   switch (rType) {
        //     case "판상": buildingShapeType = "LINEAR_NON_CORRIDOR_TYPE"; break;
        //     case "ㄱ판상": buildingShapeType = "FOLDED_NON_CORRIDOR_TYPE"; break;
        //     case "T탑상": buildingShapeType = "T_TOWER_TYPE"; break;
        //     case "Y탑상": buildingShapeType = "Y_TOWER_TYPE"; break;
        //   }

        //   return {
        //     figures: buildingShapeType,
        //     houses_number: houses
        //   }
        // }
        return {
          figures: "LINEAR_CORRIDOR_TYPE",
          houses_number: 4,
        };
      }
    }

    function housingPlanConverter(type: string[]) {
      const r = type.filter((h: any) => {
        return h !== "-";
      });
      const length = r.length;
      const hp = r.map((rr) => {
        return new ProjectHousingPlanType({
          area: Number(rr),
          proportion: Number((100 / length).toFixed(0)),
        });
      });

      if (hp.length > 0 && 100 % length > 0) {
        let remain = 100 % 3;
        for (let i = 0; remain; remain--) {
          hp[i].proportion!++;
        }
      }
      return hp;
    }
  };

  getDefaultRegulation = async (pnu: string) => {
    const lambda = await new AWSModule("LAMBDA").connect();
    const r = await lambda
      .Lambda!.invoke({
        FunctionName: "platform-buildit-GetRegulationByPNU-r1-v20190830",
        Payload: JSON.stringify({ pnu: pnu }),
      })
      .promise();

    const rr = JSON.parse(r.Payload as string);

    if (rr.success) {
      return rr.body;
    } else {
      // console.log(rr.reason);
      return undefined;
    }
  };

  getJiguInfo = async (pnu: string) => {
    const r = await App.API_Axios.post(
      "/JiguByPnu",
      {
        pnu,
      },
      {
        headers: {
          Authorization: `Bearer ${App.jwt}`,
        },
      }
    );

    const rr = r.data;

    if (rr.success) {
      return rr.body;
    } else {
      // console.log(rr.reason);
      return undefined;
    }
  };

  onExit = (path?: string) => {
    if (this.state.currentProject.project_id === undefined) {
      if (path) {
        // @ts-ignore
        this.props.history.push(path);
      } else {
        // @ts-ignore
        // this.props.history.goBack();
        // @ts-ignore
        this.props.history.push("/project/list");
      }
    } else {
      this.setModal(true, {
        color: "DARK",
        content: <div>프로젝트 설정을 나가시겠습니까?</div>,
        positive: () => {
          this.setModal(false);
          // @ts-ignore
          //          this.props.history.push('/project/list');
          if (path) {
            // @ts-ignore
            this.props.history.push(path);
          } else {
            // @ts-ignore
            this.props.history.push("/project/list");
            //            this.props.history.goBack();
          }
        },
        negative: () => {
          this.setModal(false);
        },
      });
    }
  };

  setCadastralMap = (cadastralMap: boolean) => {
    this.setState({ cadastralMap });
  };

  convertProjectType = (fixField?: Partial<Project>, callback?: Function) => {
    // AI <=> DESIGNER project_type 변환 함수
    if (this.createProject === false && fixField && fixField.project_site) {
      // only once
      this.createProject = true;
      this.onCreateProject();
    }
    let isEqualBuildingType: boolean = false;
    this.state.buildingDefault.forEach((value: any, key: any) => {
      if (key === "다세대") {
        if (fixField!.building_type === "다세대주택" && value.가능여부 === true) {
          isEqualBuildingType = true;
          return;
        }
      } else {
        if (fixField!.building_type === key && value.가능여부 === true) {
          isEqualBuildingType = true;
          return;
        }
      }
    });

    const newProject = new Project({
      stage: this.state.currentProject.stage,
      project_id: this.state.currentProject.project_id,
      project_name: this.state.currentProject.project_name,
      copy_project_id: this.state.currentProject.copy_project_id,
      user_project_id: this.state.currentProject.user_project_id,
      created_at: _.cloneDeep(this.state.currentProject.created_at),
      discountable: this.state.currentProject.discountable,
      email: _.cloneDeep(this.state.currentProject.email),
      favorite: this.state.currentProject.favorite,
      auto_name: true,
      auto_setting: true,
      ...this.state.currentProject,
      ...fixField,
      skyline_circle: [],
      skyline_line: [],
      building_type: isEqualBuildingType ? fixField!.building_type : undefined,
    });
    // console.log("newProject", newProject);

    const beforeProjectDefault = {
      ...this.state.projectDefault,
    };

    this.setState(
      {
        convertNum: this.state.convertNum + 1,
        currentProject: newProject,
        projectErrors: { errs: {} },
        projectDefault: beforeProjectDefault,
      },
      () => {
        callback && callback();
      }
    );
  };
}

export interface ProjectFormHeadProps extends ProjectFormPropsBase {
  onResetProject: (fixField?: Partial<Project>, callback?: Function) => void;
  setModal: (open: boolean, options?: ModalOptions) => void;
  onSaveProject: (callback?: Function) => void;
  onExit: (path?: string) => void;
  setLoading: (loading: boolean) => void;
  checkProjectErros: (callback?: Function) => void;
  setEditProjectName: (edit: boolean) => void;
  setEditProjectExplanation: (edit: boolean) => void;
  convertProjectType: (fixField: Partial<Project>, callback?: Function) => void;
  resetNum: number;
  openSaveTooltip: boolean;
  edit: boolean;
  projectDefault?: Project;
  editProjectName: boolean;
  editProjectExplanation: boolean;
  disableComplete: boolean;
}
export interface ProjectFormHeadState {
  resetNum: number;
  onSave: boolean;
  projectName?: string;
  projectType?: string | undefined;
}

export class ProjectFormHead extends SessionComponent<ProjectFormHeadProps, ProjectFormHeadState> {
  state: ProjectFormHeadState = {
    resetNum: this.props.resetNum,
    onSave: false,
    projectName: this.props.currentProject.project_name,
    projectType: this.props.currentProject.project_type,
  };

  componentDidUpdate = (pp: ProjectFormHeadProps) => {
    if (!_.isEqual(this.props.currentProject.modified_at, pp.currentProject.modified_at)) {
      this.setState({ onSave: true }, () => {
        setTimeout(() => {
          this.setState({ onSave: false });
        }, 1000);
      });
    }

    if (!_.isEqual(this.props.currentProject.project_name, pp.currentProject.project_name)) {
      this.setState({ projectName: this.props.currentProject.project_name });
    }
  };

  render() {
    const open = this.props.openSaveTooltip;

    return (
      <div
        className={`ProjectFormHead 
      ${this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN" ? "DESIGNER" : "AI"}
    `}
      >
        <Home className="home-icon" onClick={() => this.props.onExit("/")} />
        <KeyboardArrowRight className="arrow-right-icon" />
        {(App.stage === "prod" && (
          <div className="ProjectTypeSelect">
            <div className={`select-input none-hover ${this.props.currentProject.discountable! ? "discountable" : ""}`}>BUILDIT DESIGNER project</div>
          </div>
        )) || (
          <div className="ProjectTypeSelect">
            <div className={`select-input none-hover ${this.props.currentProject.discountable! ? "discountable" : ""}`}>{`BUILDIT  ${
              this.props.currentProject.project_type === "DESIGNER" || this.props.currentProject.project_type === "SITE_PLAN" ? "DESIGNER" : "AI"
            } project`}</div>
          </div>
        )}
        <Tooltip id="ProjectFormHead-convert-icon" msg="DESINGER project로 전환" place="bottom">
          <Button
            onClick={(e) => {
              this.props.setModal(true, {
                type: "SIMPLE",
                negative: () => {
                  this.props.setModal(false);
                },
                positive: () => {
                  this.props.setModal(false);
                  // const curr_project_type = this.state.projectType;
                  let fixedField: Project = {};
                  if (this.props.currentProject.project_type === "AI") {
                    (fixedField.project_type = "DESIGNER"), (fixedField.building_type = "아파트");
                  } else {
                    fixedField.project_type = "AI";
                    fixedField.building_type = this.props.currentProject.building_type;
                  }
                  this.props.convertProjectType(fixedField);
                },
                content: (
                  <div>
                    현재 {this.props.currentProject.project_type} 프로젝트를 <br />
                    {this.props.currentProject.project_type === "AI" ? "DESIGNER" : "AI"}으로 전환하시겠습니까?
                    <br />
                    동타입, 나의 동평면 데이터가 사라질 수 있습니다.
                  </div>
                ),
              });
            }}
          >
            <SyncAltRoundedIcon className="convert-icon" />
          </Button>
        </Tooltip>
        <div className="title">
          <div className="project-name">
            {
              // <Tooltip id="project-type-tooltip" msg={`${this.props.currentProject.discountable && "할인이 적용된 프로젝트는 타입을 변경하실 수 없습니다" || "프로젝트 타입을 변경합니다."}`} >
              //   <div className="ProjectTypeSelect" onClick={() => {
              //     if (this.props.currentProject.discountable) {
              //       return;
              //     }
              //     this.props.setModal(true, {
              //       title: "프로젝트 타입 변경 안내",
              //       content:
              //       this.props.currentProject.project_type === "DESIGNER" &&
              //       <div>
              //         프로젝트 타입을 변경하시겠습니까?
              //         <br />변경시 현재 설정된 내용이 초기화 됩니다.
              //       </div>
              //       ||
              //       <div>
              //         DESIGNER 프로젝트는 아파트만 가능합니다.
              //         <br />프로젝트 타입을 변경하시겠습니까?
              //         <br />변경시 현재 설정된 내용이 초기화 됩니다.
              //       </div>
              //       ,
              //       positive: () => {
              //         let fixedField: Project = {};
              //         const type = this.props.currentProject.project_type;
              //         if (type === "AI") {
              //           fixedField.project_type = "DESIGNER";
              //           fixedField.building_type = "아파트";
              //         } else {
              //           fixedField.project_type = "AI";
              //         }
              //         this.props.onResetProject(fixedField);
              //         this.props.setModal(false);
              //       },
              //       negative: () => {
              //         this.props.setModal(false);
              //       },
              //       positiveTitle: `${this.props.currentProject.project_type === "AI" && "DESIGNER" || "AI"}로 변경`,
              //       negativeTitle: `현재 타입 유지하기`
              //     })
              //   }}>
              //     <div className="select-input">
              //       {
              //         ["DESIGNER", "SITE_PLAN"].includes(this.props.currentProject.project_type!) && "DESIGNER" || "AI"
              //       }
              //       <SwapIcon className="icon" />
              //     </div>
              //   </div>
              // </Tooltip>
            }
            {this.props.currentProject.discountable! && <DiscountTag className="m-r-sm" />}
            {(this.props.editProjectName && (
              <ClickAwayListener
                onClickAway={() => {
                  if (this.state.projectName !== undefined) {
                    this.props.onUpdateProject({ project_name: (this.state.projectName.length === 0 && "제목없는 프로젝트") || this.state.projectName });
                  }
                  this.props.setEditProjectName(false);
                }}
              >
                <div className="project-name-explanation-wrap">
                  <ProjectFieldInput
                    autoFocus={true}
                    className="project_name"
                    fieldName="project_name"
                    length={255}
                    fieldValue={this.state.projectName}
                    placeholder="프로젝트 제목 / 대지영역 설정시 제목이 입력되며 수정 가능합니다."
                    align="left"
                    disabledAutoSetting={true}
                    onChange={(value) => {
                      if (this.props.currentProject.auto_name) {
                        this.props.onUpdateProject({ auto_name: false });
                      }
                      this.setState({ projectName: value as string });
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        if (this.state.projectName !== undefined) {
                          this.props.onUpdateProject({ project_name: (this.state.projectName.length === 0 && "제목없는 프로젝트") || this.state.projectName });
                        }
                        this.props.setEditProjectName(false);
                      }
                    }}
                    {...this.props}
                  />
                </div>
              </ClickAwayListener>
            )) || (
              <Tooltip id="ProjectForm-project-name" msg="프로젝트 제목(클릭 시 수정 가능)">
                <div className="project-name-no-edit" onClick={() => this.props.setEditProjectName(true)}>
                  <div className="value">
                    {(this.props.currentProject.project_name && this.props.currentProject.project_name) || (
                      <span className="font font-14px font-secondary">프로젝트 제목 / 대지영역 설정시 제목이 입력되며 수정 가능합니다</span>
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>

        {this.props.currentProject.copy_project_id !== undefined && <CopyProjectInfo copyProjectId={this.props.currentProject.copy_project_id} showArrowIcon={false}></CopyProjectInfo>}
        <div className="right">
          <div className="project-config-btns">
            <Tooltip msg={`${this.props.session && this.props.session!.email}`} id="ProjectForm-Header-Account" className="m-l-sm account-tooltip">
              <AccountCircle className="icon primary" />
            </Tooltip>
            <Tooltip msg="프로젝트 메모" id="ProjectForm-Header-memo" className="m-l-sm">
              <Button
                className="btn bg-navy btn-primary"
                disableRipple={true}
                onClick={() => {
                  this.props.setEditProjectExplanation(!this.props.editProjectExplanation);
                }}
              >
                <DescriptionOutlinedIcon className="icon" />
              </Button>
            </Tooltip>
            <Tooltip
              msg={
                (this.props.currentProject.modified_at && (
                  <div>
                    자동 저장되었습니다 <br />
                    {moment(this.props.currentProject.modified_at).format("YYYY-MM-DD HH:mm:ss")}
                  </div>
                )) ||
                "저장하기"
              }
              id="ProjectForm-Header-Save"
              className="m-l-sm"
            >
              <Button onClick={(e) => this.props.onSaveProject()} disableRipple={true} className="btn bg-navy btn-primary">
                {(this.state.onSave && <CircularProgress className="icon" />) || <SaveIcon className="icon" />}
              </Button>
            </Tooltip>
            <Tooltip msg="초기화" id="ProjectForm-Header-Init" className="m-l-sm">
              <Button
                disableRipple={true}
                className="btn bg-navy btn-primary"
                onClick={(e) => {
                  this.props.setModal(true, {
                    type: "SIMPLE",
                    negative: () => {
                      this.props.setModal(false);
                    },
                    positive: () => {
                      this.props.setModal(false);

                      let fixedField: Project = {};
                      fixedField.project_type = this.props.currentProject.project_type;

                      if (this.props.currentProject.discountable) {
                        fixedField.project_site_area = this.props.currentProject.project_site_area;
                        fixedField.project_site = this.props.currentProject.project_site;
                        fixedField.project_site_center = this.props.currentProject.project_site_center;
                        fixedField.boundary_site = this.props.currentProject.boundary_site;
                        fixedField.field_info_pnu = this.props.currentProject.field_info_pnu;
                        fixedField.road_site = this.props.currentProject.road_site;
                        fixedField.road_value = this.props.currentProject.road_value;
                        fixedField.auto_road = this.props.currentProject.auto_road;
                        fixedField.project_site_type = this.props.currentProject.project_site_type;
                      }

                      this.props.onResetProject(fixedField);
                    },
                    content: <div>설정이 초기화 됩니다.</div>,
                  });
                }}
              >
                <RefreshIcon className="icon" />
              </Button>
            </Tooltip>

            <Tooltip msg="목록으로 나가기" id="ProjectForm-Header-Exit" className="m-l-sm">
              <Button className="btn bg-navy btn-primary" disableRipple={true} onClick={() => this.props.onExit("/project/list")}>
                <ExitIcon className="icon" />
              </Button>
            </Tooltip>
            <Tooltip msg={`${(this.props.disableComplete && "설정을 완료 할 수 없습니다. 설정을 확인해주세요.") || "설정을 완료하고 결제를 진행합니다."}`}>
              <Button
                onClick={async (e) => {
                  // constraints 검색
                  if (this.props.currentProject.project_id !== undefined && this.props.currentProject.user_project_id !== undefined) {
                    this.props.checkProjectErros(async () => {
                      if (Object.keys(this.props.projectErros!.errs).length === 0) {
                        this.props.setLoading(true);
                        const lambda = await new AWSModule("LAMBDA").connect();
                        const cp = _.cloneDeep(this.props.currentProject);
                        const project: Project = {};
                        Object.keys(cp).forEach((e) => {
                          if (cp[e as keyof Project] !== "" && cp[e as keyof Project] !== undefined) {
                            // @ts-ignore
                            project[e as keyof Project] = cp[e as keyof Project];
                          }
                        });

                        // if (this.props.currentProject.project_type === "UNDEFINED") {
                        //   if (this.props.currentProject.project_site_type === "IMPORT") {
                        //     project.project_type = "DESIGNER";
                        //   } else {
                        //     project.project_type = "AI";
                        //   }
                        // }

                        const r = await lambda
                          .Lambda!.invoke({
                            FunctionName: "arn:aws:lambda:ap-northeast-2:331053433621:function:buildit-public-platform-UpdateDynamoDB-dev",
                            Payload: JSON.stringify({
                              TableName: "platform-buildit-project",
                              Item: project,
                            }),
                          })
                          .promise();

                        if (JSON.parse(r.Payload as string).success) {
                          // @ts-ignore
                          this.props.history.push(`/project/payment?pid=${this.props.currentProject.user_project_id}`);
                        } else {
                          // console.log(JSON.parse(r.Payload as string).reason);
                        }
                        this.props.setLoading(false);
                      } else {
                        this.props.setModal(true, {
                          title: "프로젝트 설정 안내",
                          content: (
                            <div>
                              {Object.values(this.props.projectErros!.errs).filter((r) => r!.configType === "SITE").length > 0 && <div>"사업영역 설정" 필수사항 설정이 필요합니다</div>}
                              {Object.values(this.props.projectErros!.errs).filter((r) => r!.configType === "DETAIL" && r!.subDetailType === "BASIC").length > 0 && (
                                <div>"상세 설정 - 기본설정" 필수사항 설정이 필요합니다</div>
                              )}
                              {Object.values(this.props.projectErros!.errs).filter((r) => r!.configType === "DETAIL" && r!.subDetailType === "VALUE").length > 0 && (
                                <div>"상세 설정 - 건축개요" 필수사항 설정이 필요합니다</div>
                              )}
                              {Object.values(this.props.projectErros!.errs).filter((r) => r!.configType === "DETAIL" && r!.subDetailType === "BUILDING").length > 0 && (
                                <div>"상세 설정 - 동타입" 필수사항 설정이 필요합니다</div>
                              )}
                              {Object.values(this.props.projectErros!.errs).filter((r) => r!.configType === "DETAIL" && r!.subDetailType === "HOUSING").length > 0 && (
                                <div>"상세 설정 - 세대타입" 필수사항 설정이 필요합니다</div>
                              )}
                            </div>
                          ),
                          positive: () => {
                            this.props.setModal(false);
                          },
                          negative: () => {
                            this.props.setModal(false);
                          },
                        });
                      }
                    });
                  } else {
                    this.props.setModal(true, {
                      title: "프로젝트 설정 안내",
                      content: <div>"사업영역 설정" 필수사항 설정이 필요합니다</div>,
                      positive: () => {
                        this.props.setModal(false);
                      },
                      negative: () => {
                        this.props.setModal(false);
                      },
                    });
                    // console.log("err");
                    // err 처리
                  }
                }}
                disableRipple={false}
                className={`btn btn-run bg-navy run-btn m-l-md ${this.props.disableComplete && "disabled"} 
                ${
                  this.props.currentProject.project_site &&
                  this.props.currentProject.project_site[0] != null &&
                  ((this.props.currentProject.road_site && this.props.currentProject.road_site[0] != null) ||
                    (this.props.currentProject.boundary_site && this.props.currentProject.boundary_site[0] != null))
                    ? ""
                    : "disabled"
                }`}
                disabled={this.props.disableComplete}
              >
                설정 완료
              </Button>
            </Tooltip>
          </div>
        </div>
        <ClickAwayListener
          onClickAway={(e: React.ChangeEvent<{}>) => {
            e.stopPropagation();
            this.props.setEditProjectExplanation(false);
          }}
        >
          <div className="project-memo">
            {this.props.editProjectExplanation && (
              <div className="project-explanation">
                <ProjectFieldInput
                  autoFocus={true}
                  fieldName="project_explanation"
                  className="project_explanation width-full"
                  fieldValue={this.props.currentProject.project_explanation}
                  multiline={true}
                  placeholder="프로젝트와 관련된 내용을 정리하세요"
                  disabledAutoSetting={true}
                  rows={15}
                  length={10000}
                  {...this.props}
                />
              </div>
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}
