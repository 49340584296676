import React, { Component } from 'react';
import { ConverterBuilding, BuildingComponent, BuildingPart } from './BuildingPart';
import { ConverterInputBox } from './ConverterInputBox';
import { BuildingHouseUnit } from './BuildingHouseUnit';
import { BuildingCoreUnit } from './BuildingCoreUnit';
import { ConverterType } from './DataTypes';

interface componentState {
  extraMenu: boolean;
  update: number;
}

interface componentProp {
  type: ConverterType;
  compoent: BuildingComponent | ConverterBuilding;
  buildingPart?: BuildingPart;
  updateArea: () => void;
}


export class ComponentUI extends Component<componentProp, componentState> {
  state: componentState = {
    extraMenu: true,
    update: 0,
  }

  pageUpdate = () => {
    this.props.updateArea();
    this.setState({ update: this.state.update + 1 })
  }

  render = () => {
    if (this.props.type === ConverterType.myPlane && this.props.compoent.name.toUpperCase().startsWith("F")) {
      return (
        //@ts-ignore
        <ConverterInputBox name='층고(m)' value={(this.props.compoent as ConverterBuilding).levelHeights[0]}
          valueUpdate={this.pageUpdate} valueOnChange={
            (this.props.compoent as ConverterBuilding).SetLevelHeight
          } step={0.01} buildingPart={this.props.buildingPart} />
      )
    }
    //@ts-ignore
    else if (this.props.compoent.buildingType! === "component")
      return (
        <div key={this.props.compoent.uuid}>

          {
            //@ts-ignore
            this.props.compoent.componentType! === 'core' &&
            // 코어
            <div className='coreUI'>
              <div>
                <ConverterInputBox name='코어 면적(㎡)' value={(this.props.compoent as BuildingCoreUnit).GetArea()} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingCoreUnit).SetArea} step={0.0001} />
              </div>
              <div className='Line' />
            </div> ||
            // 세대
            <div className='houseUI'>
              <div className='inputValues'>
                <ConverterInputBox name='전용 면적(㎡)' value={(this.props.compoent as BuildingHouseUnit).exclusiveArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetExclusiveArea} step={0.0001} />
                <ConverterInputBox name='벽체 공용 면적(㎡)' value={(this.props.compoent as BuildingHouseUnit).commonWallArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetCommonWallArea} step={0.0001} />
              </div>
              <div className="border"></div>

              <div style={{ position: 'relative', width: 'fit-content' }}>
                {/* <div className='extraMenu' onClick={() => this.setState({ extraMenu: !this.state.extraMenu })}>자세히+</div> */}
                <ConverterInputBox name='발코니 면적(㎡)' value={(this.props.compoent as BuildingHouseUnit).serviceArea} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetServiceArea} step={0.0001} />
              </div>
              <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
                <ConverterInputBox name='서비스 면적(㎡)' value={(this.props.compoent as BuildingHouseUnit).balconyLess150cm} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetBalconyOver150cm} disable={true} step={0.0001} />
                <ConverterInputBox name='용적률 포함 면적(㎡)' value={(this.props.compoent as BuildingHouseUnit).balconyOver150cm} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetBalconyOver150cm} step={0.0001} />
              </div>
              {
                this.props.type === ConverterType.myPlane &&
                <>
                  <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
                    <ConverterInputBox name='층수(층)' value={(this.props.compoent as BuildingHouseUnit).level.length} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetLevel} step={1} />
                    {/* <ConverterInputBox name='층고(m)' value={(this.props.compoent as BuildingHouseUnit).levelHeights[0]} 
                valueUpdate={this.pageUpdate} valueOnChange={
                  (this.props.compoent as BuildingHouseUnit).SetLevelHeight
                  } step={0.01} buildingPart={this.props.buildingPart}/>  */}
                  </div>
                  <div className={`inputValues ${!this.state.extraMenu && 'hidden'}`} style={{ margin: '5px 0 0 0' }}>
                    <ConverterInputBox name='필로티(층)' value={(this.props.compoent as BuildingHouseUnit).piloti} valueUpdate={this.pageUpdate} valueOnChange={(this.props.compoent as BuildingHouseUnit).SetPiloti} step={1} />
                  </div>
                </>
              }
            </div>
          }
        </div>
      )
    else {
      return <div></div>
    }
  }
}

