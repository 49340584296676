import React, { Component } from 'react';
import * as THREE from '@teneleven/three';
import '../css/CADConverter/CadConverterTemplate.scss';
import '@teneleven/protocols-ts-web';
import { Button } from '@material-ui/core'
import '../css/BuilditUI.scss';
import { ConverterLayer, buildingTypeStruct, ListType, saveState, CompletenessType, ConverterType, PartOfSelect } from './DataTypes';
import { dataParsing, asyncFileRead, asyncOneFileRead } from './FileParser';
import { saveDataToS3, saveDataToDynamoDB, checkFileName, getBuildingData, checkSpecialSymbolInName } from './DBManager';
import { SceneManager } from './SceneManager';
import { deleteCoreFromCoreList, CheckPolygonOverlap, GetJSTSUnionPolygonFormLayer, brightenAllLayer, darkenAllLayer, JSTSGeoToTHREEGeo } from './CoreAndHouseController';
import { HouseUI } from './HouseUI';
import { CoreUI } from './CoreUI';
import { Modal, ModalProps, buttonNum } from './Modal';
import { incrementCounter } from '../Utils';
import { MakeBuildingMesh } from '../Viewer/MeshManager'
import App from '../App';
import { getCurveErrorCircle } from './MeshMaker';
import { ConverterHeader } from './ConverterHeader';
import { DropFileBox } from './DropFileToLoadBox';
import { LayerPanel } from './LayerPanel';
import { SaveViewerModal } from './SaveViewerModal';
import { LoadingPage } from './LoadingPage';
import { userSettingData, Setting } from './SettingModal';
import { House } from './House';
import { Core } from './Core';
import { ErrorLog, ErrorLogCell, ErrorType, makeWarningInformation } from './ErrorLog';

const uuid4 = require('uuid/v4');

enum houseCoreTabs {
  house = '세대',
  core = '코어'
}

interface SceneProps {

}

interface SceneState {
  layers: ConverterLayer[];
  is2D: boolean;
  screenWidth: number;
  screenHeight: number;
  fileName: string;
  saveName: string;
  showLayer: boolean;
  listType: ListType;
  houseCoreTabs: houseCoreTabs;
  selectLayer: ConverterLayer | null;
  selectHouse: House | null;
  settingID: string,
  partOfHouse: PartOfSelect,
  totalExclusiveAreas: string;
  totalServiceAreas: string;
  totalCoreAreas: string;
  totalCommonWallAreas: string;
  buildingArea: string,
  groundArea: string,
  userID: string,
  showModal: boolean,
  showSaveModal: boolean,
  hideAuto: boolean,
  loadFile: boolean,
  showAutoSetting: boolean,
  loadingPage: boolean,
  showSample: boolean,
  showSettingModal: boolean,
  errorLog: boolean,
  errorSize: number,
  warningSize: number,
}

export class Scene extends Component<SceneProps, SceneState> {
  state: SceneState = {
    layers: [],
    is2D: true,
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight - 122,
    fileName: '선택된 파일 없음',
    showLayer: true,
    selectLayer: null,
    settingID: '',
    totalCoreAreas: '0',
    totalExclusiveAreas: '0',
    totalServiceAreas: '0',
    totalCommonWallAreas: '0',
    buildingArea: '0',
    groundArea: '0',
    listType: ListType.layer,
    houseCoreTabs: houseCoreTabs.house,
    selectHouse: null,
    saveName: '',
    userID: 'test@1011.co.kr',
    showModal: false,
    showSaveModal: false,
    hideAuto: false,
    loadFile: false,
    showAutoSetting: false,
    partOfHouse: PartOfSelect.wall,
    loadingPage: false,
    showSample: true,
    showSettingModal: false,
    errorLog: false,
    errorSize: 0,
    warningSize: 0,
  };

  mount: HTMLDivElement | null = null;

  sceneManager = new SceneManager();

  polygon2DGroup = new THREE.Group();
  polygon3DGroup = new THREE.Group();

  bbox = new THREE.Box3();

  houses: House[] = [];
  cores: Core[] = [];

  S3BucketName = 'teneleven-platform-my-building-type-v2';
  DBTableName = 'platform-buildit-my-building-type-v2';
  ModalProps: ModalProps = {
    content: ['내용'],
    buttonNum: buttonNum.twoButton,
    open: true,
    positive: () => App.stage !== "prod" && console.log('test'),
    title: '제목'
  }
  fileData: string = '';

  houseCount = 0;
  coreCount = 0;
  errorLogs: ErrorLogCell[][] = [];
  loadFileErrorLogs: ErrorLogCell[] = [];
  globalErrorLogs: ErrorLogCell[] = [];

  settingData = new userSettingData(App.tempStage, App.session.email);

  animate = () => {
    requestAnimationFrame(this.animate);
    this.sceneManager.render();

    this.houses.forEach(h => { this.updateInfoPosition(h.id, h.centerOfAllLine, h.complete); });
    this.cores.forEach(c => { this.updateInfoPosition(c.id, c.centerOfAllLine, c.complete); });
  }

  updateInfoPosition = (id: string, center: THREE.Vector3, completeness: CompletenessType) => {

    let s = document.querySelector(`.TT${id}`) as HTMLInputElement;
    if (s) {
      let c = this.sceneManager.getScreenPosition(center);    
    
      let rect = s.getBoundingClientRect();
      let min = new THREE.Vector2(c.x - rect.width / 2, c.y - rect.height / 2);
      let max = new THREE.Vector2(c.x + rect.width / 2, c.y + rect.height / 2);
  
      
      if (!(completeness !== CompletenessType.error) || !this.sceneManager.canvasBox2.containsPoint(min) || !this.sceneManager.canvasBox2.containsPoint(max))
        s.style.visibility = 'hidden';
      else
        s.style.visibility = 'visible';

      s.style.top = c.y.toString() + 'px';
      s.style.left = c.x.toString() + 'px';
    }
  }

  componentDidMount = async () => {
    this.mount!.appendChild(this.sceneManager.canvasElement);

    this.sceneManager.SceneInit();

    this.sceneManager.addObjectToScene(this.polygon2DGroup);
    this.sceneManager.addObjectToScene(this.polygon3DGroup);

    this.sceneManager.addObjectToScene(new THREE.AmbientLight(0xffffff, 0.5));
    let light = new THREE.DirectionalLight();
    light.position.set(10, 10, 10);
    this.sceneManager.addObjectToScene(light);
    this.sceneManager.addObjectToScene(light.target);

    this.animate();

    window.addEventListener('resize', this.onWindowResize, false);
    window.addEventListener("keyup", this.onKeyUp, false);
    App.stage !== "prod" && console.log(this.sceneManager.canvasElement.getBoundingClientRect());

    await this.settingData.getDataFromDB();
    this.errorLogs.push(this.globalErrorLogs);
    this.errorLogs.push(this.loadFileErrorLogs);

    const r = await App.search({
      "table": this.DBTableName,
      "query": `stage.keyword:${App.tempStage} AND email.keyword:${App.session.email} AND deleted:false`
    });

    this.setState({
      userID: App.session.email,
      showSample: r.data.hits.total > 0 ? true : false,
    })
  }

  componentDidUpdate = (previousProps: Readonly<SceneProps>, previousState: Readonly<SceneState>) => {
  

    if (previousState.screenWidth !== this.state.screenWidth || previousState.screenHeight !== this.state.screenHeight || previousState.showLayer !== this.state.showLayer) {
      let width = this.state.screenWidth;
      let height = this.state.screenHeight;

      if (!this.state.showLayer) {
        width -= 235;
      }

      let aspect = width / height;

      this.sceneManager.renderer.setSize(width, height);
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);
    }

    if (previousState.errorLog !== this.state.errorLog) {
      this.onWindowResize();
    }
  }

  componentWillUnmount = () => {
    this.mount!.removeChild(this.sceneManager.canvasElement);
  }

  onKeyUp = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'a':
        // this.autoSetHouse();
        break;
      default:
        break;
    }
  }

  loadFilebyFile = async (file: any) => {
    this.setState({ loadingPage: true })

    if (!file[0].name.endsWith('.dxf')) {
      return;
    }

    let data = await asyncFileRead(file);
    if (!data)
      return;

    this.fileData = data;
    let layer = dataParsing(data, this.settingData.dataUnit);

    if (layer.length === 0) {
      this.showModal('알림', ['no data in file'], buttonNum.oneButton, () => { this.setState({ loadingPage: false, }) })
      return;
    }

    this.polygon2DGroup.children = [];
    this.polygon3DGroup.children = [];
    while (this.loadFileErrorLogs.length > 0) {
      this.loadFileErrorLogs.splice(0, 1);
    }

    this.clearSettingData();
    this.bbox.makeEmpty();
    layer.forEach(l => {
      let hasCurve = false;
      l.polygons.forEach(p => {
        let meshGroup = new THREE.Group();
        meshGroup.add(p.lineMesh);
        meshGroup.add(p.innerMesh);
        this.polygon2DGroup.add(meshGroup);
        if (p.hasCurve) {
          l.errorLayer = true;
          hasCurve = true;
          // setErrorColorForLayer(l);
        }

        p.vertices.forEach(v => {
          this.bbox.expandByPoint(v);
        });
      });
      if (hasCurve)
        this.loadFileErrorLogs.push(makeWarningInformation(`${l.name} 레이어에 라운드가 있습니다.`, '', getCurveErrorCircle(l), [l]));
    });

    this.loadFileErrorLogs.forEach(lfel => {
      this.polygon2DGroup.add(lfel.hilightPolygon);
    })
    this.errorLogs= [];

    let aspect = this.state.screenWidth / this.state.screenHeight;
    this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

    this.sceneManager.orthoCamera.position.set(0, 0, 1);
    this.sceneManager.orthoControl.target.set(0, 0, 0);
    this.sceneManager.orthoCamera.zoom = 1;

    this.Switch2D3D(false);

    this.setState({
      layers: layer,
      hideAuto: false,
      fileName: file[0].name,
      saveName: file[0].name.substring(0, file[0].name.length - 4),
      loadFile: true,
      showAutoSetting: true,
      loadingPage: false,
    }, () => {
      this.onWindowResize();
      if (this.settingData.autoSetting) {
        this.autoSetHouse();
      }
      else {
        this.addHouse(`세대 ${this.houseCount + 1}`);
      }
    })
  }

  getFrustumSize = (aspect: number) => {
    let bboxSize = new THREE.Vector3(0);
    this.bbox.getSize(bboxSize);
    let frustumSize = bboxSize.x / 2 * 1.1;
    if (aspect > bboxSize.x / bboxSize.y) {
      let height = bboxSize.y / 2 * 1.1;
      frustumSize = height * aspect;
    }
    return frustumSize;
  }

  loadDXFFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files![0]) {
      this.loadFilebyFile(e.target.files);
    }
    // this.saveBuilditType(e.target.files);
  }

  saveBuilditType = async (file: any) => {
    console.log('start');
    for (let i = 0; i < file.length; i++) {
      let data = await asyncOneFileRead(file[i]);
      let layer = dataParsing(data, this.settingData.dataUnit);
      this.polygon2DGroup.children = [];
      this.polygon3DGroup.children = [];
      while (this.loadFileErrorLogs.length > 0) {
        this.loadFileErrorLogs.splice(0, 1);
      }

      this.clearSettingData();
      this.bbox.makeEmpty();
      layer.forEach(l => {
        let hasCurve = false;
        l.polygons.forEach(p => {
          let meshGroup = new THREE.Group();
          meshGroup.add(p.lineMesh);
          meshGroup.add(p.innerMesh);
          this.polygon2DGroup.add(meshGroup);
          if (p.hasCurve) {
            l.errorLayer = true;
            hasCurve = true;
          }

          p.vertices.forEach(v => {
            this.bbox.expandByPoint(v);
          });
        });
        if (hasCurve)
          this.loadFileErrorLogs.push(makeWarningInformation(`${l.name} 레이어에 라운드가 있습니다.`, '', getCurveErrorCircle(l), [l]))
      });

      this.loadFileErrorLogs.forEach(lfel => {
        this.polygon2DGroup.add(lfel.hilightPolygon);
      })

      let aspect = this.state.screenWidth / this.state.screenHeight;
      this.sceneManager.CameraFrustumResize(this.getFrustumSize(aspect), aspect);

      this.sceneManager.orthoCamera.position.set(0, 0, 1);
      this.sceneManager.orthoControl.target.set(0, 0, 0);
      this.sceneManager.orthoCamera.zoom = 1;

      this.Switch2D3D(false);

      this.setState({
        layers: layer,
        hideAuto: false,
        loadFile: true,
        showAutoSetting: true,
        loadingPage: false,
      }, () => {
        this.onWindowResize();
        if (this.settingData.autoSetting) {
          this.autoSetHouse();
        }
      })

      let bucket = 'teneleven-interaction-buildit-building-type';
      let dbTable = 'platform-buildit-building-type';
      let name = file[i].name.split('_');
      // name[0] = name[0].replace('2', '6');
      let buildingName = `${name[0]}_${(name[1] as number).toString().padStart(3, '0')}_${name[2]}_${name[3].substring(0, name[3].length - 4)}`;
      let buildingTemplateName = `${(name as []).join('_').substring(0, file[i].name.length - 4)}.json`;
      let S3SavePath = `${bucket}/${name[0]}/${name[1]}`;
      let numberOfBay = name[2].match(/[\d|,|.|e|E|\+]+/g);
      if (this.state.errorSize > 0) {
        console.log(file[i].name);
      }
      else {
        let outputData = getBuildingData(this.cores, buildingName, parseInt(numberOfBay[0]), 1);
        saveDataToS3(JSON.stringify(outputData.building), S3SavePath, buildingTemplateName, 'application/json');
        console.log('save');

        // let captureBbox = new THREE.Box3();
        // await new Promise((resolve, reject) => {
        //   this.state.layers.forEach(l => {
        //     let visible = false;
        //     if (l.selected) {
        //       l.polygons.forEach(p => {
        //         p.vertices.forEach(v => {
        //           captureBbox.expandByPoint(v);
        //         })
        //       })
        //       visible = true;
        //     }
        //     l.polygons.forEach(p => {
        //       p.lineMesh.visible = visible;
        //     })
        //   })
        //   resolve(0);
        // })
        // let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);

        // this.state.layers.forEach(l => {
        //   l.polygons.forEach(p => {
        //     p.lineMesh.visible = true;
        //   })
        // })
        // saveDataToS3(lImgBuf, S3SavePath, `${(name as []).join('_').substring(0, file[i].name.length - 4)}.png`, 'image/png');

        let dbItem = {
          name: buildingName,
          numberOfBay: parseInt(numberOfBay[0]),
          numberOfHouse: parseInt(name[0].match(/\d/g)[0]),
          shapeType: name[0].substring(0, 2),
          housingPlantypeArea: parseInt(name[1]),
          file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
        }
        saveDataToDynamoDB(dbItem, dbTable);
      }
    }
  }

  showLayerList = (id: string, partofhouse: PartOfSelect = PartOfSelect.wall) => {
    this.setState({
      showLayer: false,
      listType: ListType.layer,
      settingID: id,
      partOfHouse: partofhouse,
    })
  }

  showHouseList = (id: string) => {
    this.setState({
      showLayer: false,
      listType: ListType.house,
      settingID: id,
      partOfHouse: PartOfSelect.house,
    })
  }

  addHouse = (name: string) => {
    let newHouse = new House(name);
    this.errorLogs.push(newHouse.ErrorLog);
    this.polygon2DGroup.add(newHouse.ErrorPolygonGroup);
    this.houses.push(newHouse);
    this.houses.sort((a, b) => Number(a.name.match(/\d+/g)) - Number(b.name.match(/\d+/g)));
    this.houseCount++;
    this.recalculateResult();
  }

  addCore = (name: string) => {
    let core = new Core(name);
    this.errorLogs.push(core.ErrorLog);
    this.polygon2DGroup.add(core.ErrorPolygonGroup);
    this.cores.push(core);
    this.coreCount++;
    this.recalculateResult();
  }

  onWindowResize = () => {
    let width = window.innerWidth < 1200 ? 1200 : window.innerWidth;

    this.setState({
      screenWidth: width - (this.state.loadFile ? 420 : 0),
      screenHeight: window.innerHeight - 122 - (this.state.errorLog ? 222 : 0),
    });
  }

  removeHouseFromHouseList = (house: House) => {
    let index = this.houses.findIndex(h => h.id === house.id)
    this.houses.splice(index, 1);
    house.deleteHouse();
    this.cores.forEach(c => {
      c.deleteHouseWithHouseId(house.id);
    })

    this.recalculateResult();
  }

  removeCoreFromCoreList = (core: Core) => {
    deleteCoreFromCoreList(this.cores, core);

    this.recalculateResult();
  }

  recalculateResult = () => {
    let totalExclusiveAreas = 0;
    let totalServiceAreas = 0;
    let totalCommonWallAreas = 0;
    let totalCoreAreas = 0;

    this.cores.forEach(c => {
      totalCoreAreas += c.area;
      c.CheckCompleteness();
    });

    this.houses.forEach(h => {
      totalExclusiveAreas += h.exclusiveArea + h.balconyOver150cm;
      totalServiceAreas += h.balconyLess150cm;
      totalCommonWallAreas += h.commonWallArea;
      h.checkCompleteness();
    });

    while (this.globalErrorLogs.length > 0) {
      this.globalErrorLogs.splice(0, 1);
    }

    for (let i = 0; i < this.houses.length; i++) {
      for (let j = i + 1; j < this.houses.length; j++) {
        if ((this.houses[i].wall && this.houses[j].wall!)) {
          let overlap = CheckPolygonOverlap([GetJSTSUnionPolygonFormLayer(this.houses[i].wall!), (GetJSTSUnionPolygonFormLayer(this.houses[j].wall!))]);
          if (overlap) {
            let areaOffset = userSettingData.myTypeSettingData.layerOverlap.enable ? 0 : userSettingData.myTypeSettingData.layerOverlap.value / 100;
            if (overlap.getArea() / this.houses[i].getHouseArea() > areaOffset) {
              let group = new THREE.Group();
              let mesh = JSTSGeoToTHREEGeo(overlap);
              mesh.visible = false;
              group.add(mesh);
              this.polygon2DGroup.add(group);
              this.globalErrorLogs.push(makeWarningInformation(`${this.houses[i].name}와 ${this.houses[j].name}의 폴리곤이 중복되어 있습니다. `, '', group));
            }
          }
        }
      }
    }

    let error = 0, waring = 0;
    this.errorLogs.forEach(els => {
      els.forEach(el => {
        if (el.Type === ErrorType.Error) error++;
        if (el.Type === ErrorType.Warning) waring++;
      })
    })

    this.setState({
      totalExclusiveAreas: totalExclusiveAreas.toFixed(2),
      totalServiceAreas: totalServiceAreas.toFixed(2),
      totalCoreAreas: totalCoreAreas.toFixed(2),
      totalCommonWallAreas: totalCommonWallAreas.toFixed(2),
      buildingArea: (totalExclusiveAreas + totalServiceAreas + totalCoreAreas + totalCommonWallAreas).toFixed(2),
      groundArea: (totalExclusiveAreas + totalCoreAreas + totalCommonWallAreas).toFixed(2),
      errorSize: error,
      warningSize: waring,
      errorLog: error + waring > 0 ? true : false,
    })
  }

  Switch2D3D = (is2D: boolean) => {
    if (this.state.errorSize > 0) {
      this.showModal('알림', ['코어와 세대의 세팅을 완성해 주세요.'], buttonNum.oneButton, () => { });
      return;
    }

    this.sceneManager.switchRenderCamera(!is2D);

    if (is2D) {
      this.polygon3DGroup.children = [];
      this.setState({
        showLayer: true
      })

      this.polygon3DGroup.add(this.makeReviewBuilding());
      let bbox = new THREE.Box3();
      bbox.expandByObject(this.polygon3DGroup);
      let bboxCenter = new THREE.Vector3();
      bbox.getCenter(bboxCenter);
      this.sceneManager.set3DViewerCameraPosition(bboxCenter);
    }

    this.polygon2DGroup.children.forEach(c => {
      c.visible = !is2D;
    })

    this.polygon3DGroup.children.forEach(c => {
      c.visible = is2D;
    })

    this.setState({
      is2D: !is2D,
    })
  }

  makeReviewBuilding = () => {
    let building = getBuildingData(this.cores);
    building.floorHeight = [2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8, 2.8];
    building.floorStatus.forEach(fs => {
      fs.coreFloorInfo.forEach(fi => {
        fi.floorCategory = ["FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE", "FC_CORE"]
      })
      fs.houseFloorInfo.forEach(hi => {
        hi.floorCategory = ["FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE", "FC_HOUSE"]
      })
    })

    return MakeBuildingMesh(building);
  }

  checkSaveState = async () => {
    let fn = this.state.saveName === '' ? 'my_building_type' : this.state.saveName;
    let information: string[] = [];
    let state: saveState = saveState.complete;
    if (checkSpecialSymbolInName(fn)) {
      information.push('제목에 사용할 수 없는 특수 문자("\/)가 포함되어 있습니다.');
      state = saveState.error;
    }

    let checkedName = await checkFileName(fn, this.state.userID, this.DBTableName);
    if (!checkedName) {
      information.push('이미 같은 제목을 가진 나의 동평면이 존재합니다.');
      state = saveState.error;
    }

    if (this.cores.length <= 0) {
      information.push('코어 정보를 추가해 주세요.');
      state = saveState.error;
    }
    else {
      this.errorLogs.forEach(els => {
        els.forEach(el => {
          information.push(el.Information);
        })
      })
      if (this.state.errorSize > 0) {
        state = saveState.error;
      }
      else if (this.state.warningSize > 0) {
        state = saveState.warning;
      }
    }

    switch (state as saveState) {
      case saveState.complete:
        this.saveToAWS(fn);
        break;
      case saveState.warning:
        information.push('계속 진행 하시겠습니까??')
        this.showModal('알림', information, buttonNum.twoButton, () => this.saveToAWS(fn));
        break;
      case saveState.error:
        this.showModal('경고', information, buttonNum.oneButton, () => console.log('error'));
        break;
      default:
        break;
    }
  }

  saveToAWS = async (fn: string) => {
    let uuid = uuid4();
    App.stage !== "prod" && console.log(uuid);
    let { globalId: globalID, userId: privateID } = await incrementCounter("my_building_type_id", this.state.userID);
    App.stage !== "prod" && console.log(globalID, privateID);

    let imageName = `img_large.png`;
    let buildingTemplateName = `${uuid}.json`;
    let S3SavePath = `${this.S3BucketName}/${App.tempStage}/${globalID}`;

    let captureBbox = new THREE.Box3();
    await new Promise((resolve, reject) => {
      this.state.layers.forEach(l => {
        let visible = false;
        if (l.selected) {
          l.polygons.forEach(p => {
            p.vertices.forEach(v => {
              captureBbox.expandByPoint(v);
            })
          })
          visible = true;
        }
        l.polygons.forEach(p => {
          p.lineMesh.visible = visible;
        })
      })
      resolve(0);
    })

    // capture image
    if (!this.state.is2D) { this.Switch2D3D(false); }

    let lImgBuf = this.sceneManager.getScreenCapture(256, 256, captureBbox);
    let mImgBuf = this.sceneManager.getScreenCapture(128, 128, captureBbox);
    let sImgBuf = this.sceneManager.getScreenCapture(64, 64, captureBbox);

    this.state.layers.forEach(l => {
      l.polygons.forEach(p => {
        p.lineMesh.visible = true;
      })
    })

    saveDataToS3(lImgBuf, S3SavePath, imageName, 'image/png');
    saveDataToS3(mImgBuf, S3SavePath, 'img_middle.png', 'image/png');
    saveDataToS3(sImgBuf, S3SavePath, 'img_small.png', 'image/png');

    // template data
    let outputData = getBuildingData(this.cores);
    outputData.building.name = uuid;
    App.stage !== "prod" && console.log(outputData.building);

    // dynamoDB data    
    let data = new Date().toISOString();
    let dbItem: buildingTypeStruct = {
      stage: App.tempStage,
      global_id: globalID,
      user_id: privateID,
      uuid: uuid,
      name: fn,
      email: this.state.userID,
      total_exclusive_area: Number(this.state.totalExclusiveAreas),
      total_service_area: Number(this.state.totalServiceAreas),
      core_area: Number(this.state.totalCoreAreas),
      building_area: Number(this.state.buildingArea),
      floor_area: Number(this.state.groundArea),
      common_wall_area: Number(this.state.totalCommonWallAreas),
      houses_number: this.houses.length,
      img_path: `s3://${S3SavePath}/${imageName}`,
      file_path: `s3://${S3SavePath}/${buildingTemplateName}`,
      meta_path: `s3://${S3SavePath}`,
      created_at: data,
      modified_at: data,
      deleted: false
    }
    App.stage !== "prod" && console.log(dbItem);
    saveDataToS3(this.fileData, S3SavePath, 'file.dxf', '');
    saveDataToS3(JSON.stringify(outputData.building), S3SavePath, buildingTemplateName, 'application/json');
    saveDataToDynamoDB(dbItem, this.DBTableName);

    this.showModal('알림', ['나의 동평면을 저장했습니다.'], buttonNum.oneButton, () => console.log('saved'));
  }

  setSelectLayer = (click: ConverterLayer) => {
    if (click.errorLayer) {
      this.showModal('알림', ['문제 있는 레이어를 선택했습니다.'], buttonNum.oneButton, () => console.log('error layer'));
      return;
    }

    if (!this.state.selectLayer || click !== this.state.selectLayer) {
      this.setState({ selectLayer: click }, this.recalculateResult);
    }
    else {
      this.setState({ selectLayer: null }, () => this.setState({ selectLayer: click }, this.recalculateResult));
    }
  }

  setSelectHouse = (click: House) => {
    if (!this.state.selectHouse || click !== this.state.selectHouse) {
      this.setState({ selectHouse: click });
    }
    else {
      this.setState({ selectHouse: null }, () => this.setState({ selectHouse: click }));
    }
  }

  showModal = (title: string, content: string[], buttonNum: buttonNum, func: () => void) => {
    this.ModalProps.title = title;
    this.ModalProps.content = content;
    this.ModalProps.buttonNum = buttonNum;
    this.ModalProps.positive = func;

    this.setState({
      showModal: !this.state.showModal,
    })
  }

  autoSetHouse = () => {
    this.clearSettingData();

    this.state.layers.forEach(l => {
      if (l.errorLayer)
        return;

      let splits = l.name.split('_');

      let coreName = splits[0];
      if (!this.cores.find(c => c.name === coreName)) {
        this.addCore(coreName);
      }

      let cd = this.cores.find(c => c.name === coreName);
      if (splits.length === 2) { // 코어
        if (cd) {
          cd.setCore(l);
          cd.setCoreArea(Number(splits[1]) ? Number(splits[1]) : 0);
        }
      }
      else if (splits.length >= 4) {
        let houseName = splits[1];
        if (!this.houses.find(h => h.name === houseName)) {
          this.addHouse(houseName);
        }

        let house = this.houses.find(h => h.name === houseName);
        if (house) {
          if (cd) {
            if (!cd.houses.find(h => h.name === houseName)) {
              cd.addHouseToList(house);
            }
          }
          if (Number(splits[2])) { //벽
            house.setWallLayer(l);
            house.setExclusiveArea(Number(splits[2]) ? Number(splits[2]) : 0);
            house.setServiceArea(Number(splits[3]) ? Number(splits[3]) : 0);
            house.setCommonWallArea(Number(splits[4]) ? Number(splits[4]) : 0);
          }
          else if (splits[3] === "A" || splits[3] === "a") { //채광 창문
            house.setLightWindow(l);
          }
          else if (splits[3] === "B" || splits[3] === "b") { //일반 창문
            house.setNormalWindow(l);
          }
        }
      }
    });

    this.recalculateResult();
    this.setState({ showAutoSetting: false })
  }

  clearSettingData = () => {
    while (this.houses.length > 0) {
      this.houses[0].deleteHouse();
      this.houses.splice(0, 1);
    }

    while (this.cores.length > 0) {
      this.cores[0].deleteCore();
      this.cores.splice(0, 1);
    }

    this.recalculateResult();

    this.setState({
      showAutoSetting: true,
    })
  }

  getSettingName = () => {
    let name = '';
    let house = this.houses.find(h => h.id === this.state.settingID);
    if (house) {
      return house.name;
    }

    let core = this.cores.find(c => c.id === this.state.settingID);
    if (core) {
      return core.name;
    }

    return name;
  }

  getCurrentSelected = () => {
    let layer = null;
    let house = this.houses.find(h => h.id === this.state.settingID);
    if (house) {
      if (this.state.partOfHouse === PartOfSelect.wall)
        return house.wall;
      else if (this.state.partOfHouse === PartOfSelect.lightWindow)
        return house.lightWindow;
      else if (this.state.partOfHouse === PartOfSelect.normalWindow)
        return house.normalWindow;
    }

    let core = this.cores.find(c => c.id === this.state.settingID);
    if (core) {
      if (this.state.partOfHouse === PartOfSelect.core)
        return core.core;
      else if (this.state.partOfHouse === PartOfSelect.house)
        return core.houses;
    }

    return layer;
  }

  render = () => {
    return (
      <div className="buildit-template">
        <Modal content={this.ModalProps.content} title={this.ModalProps.title} open={this.state.showModal} buttonNum={this.ModalProps.buttonNum} positive={this.ModalProps.positive}></Modal>
        <SaveViewerModal open={this.state.showSaveModal} buildingObject={this.makeReviewBuilding} positive={this.checkSaveState} />
        <LoadingPage show={this.state.loadingPage} loadingMsg="동평면 파일을 불러오는 중입니다." />
        <Setting
          closeModal={() => this.setState({ showSettingModal: false })}
          open={this.state.showSettingModal}
          settingData={this.settingData}
        />
        <ConverterHeader
          type={ConverterType.myType}
          email={App.session.email}
          loadFile={this.loadDXFFile}
          reset={this.clearSettingData}
          errorSize={this.state.errorSize}
          warningSize={this.state.warningSize}
          isFileOpen={this.state.loadFile}
          saveFile={() => this.setState({ showSaveModal: !this.state.showSaveModal })}
          openSettingModal={() => this.setState({ showSettingModal: true })}
          showErrorLog={() => this.setState({ errorLog: !this.state.errorLog })}
          showModal={this.showModal}
        />
        <div className='MainBody'>
          <div className='areaInfo' hidden={!this.state.is2D}>
            {this.houses.map(h =>
              <div className={`textTest TT${h.id}`} key={h.id}>
                <div>22방:{h.name}</div>
                <div>전용 면적: {h.exclusiveArea}㎡</div>
                <div>서비스 면젹: {h.serviceArea}㎡</div>
                <div>벽체공용 면젹: {h.commonWallArea}㎡</div>
              </div>
            )}
            {this.cores.map(c =>
              <div className={`textTest TT${c.id}`} key={c.id}>
                <div>코어:{c.name}</div>
                <div>코어 면젹: {c.area}㎡</div>
              </div>
            )}
          </div>

          <div className='information'>
            <div className='info shotWidth'><div className='infoLabel'>세대수</div><div className='inforValue'>{this.houses.length}</div></div>
            <div className='info'><div className='infoLabel'>총 전용 면적</div><div className='inforValue'>{this.state.totalExclusiveAreas}㎡</div></div>
            <div className='info'><div className='infoLabel'>총 서비스 면적</div><div className='inforValue'>{this.state.totalServiceAreas}㎡</div></div>
            <div className='info'><div className='infoLabel'>총 벽체공용 면적</div><div className='inforValue'>{this.state.totalCommonWallAreas}㎡</div></div>
            <div className='info'><div className='infoLabel'>코어 면적</div><div className='inforValue'>{this.state.totalCoreAreas}㎡</div></div>
            <div className='info'><div className='infoLabel'>건축 면적</div><div className='inforValue'>{this.state.buildingArea}㎡</div></div>
            <div className='info longWidth'><div className='infoLabel'>바닥 면적(연면적산출용)</div><div className='inforValue'>{this.state.groundArea}㎡</div></div>
            <div className='fileInfo'>
              <span className={`dxfIcon ${this.state.loadFile && 'loaded'}`}><span className={`text ${this.state.loadFile && 'loaded'}`}>dxf</span></span>
              <span className={`fileName ${this.state.loadFile && 'loaded'}`}>{this.state.loadFile && this.state.fileName || '선택된 파일이 없습니다.'}</span>
              <span className={`layerToggle ${this.state.loadFile && 'loaded'}`} onClick={() => this.state.loadFile && this.setState({ showLayer: !this.state.showLayer })}>레이어 보기</span>
            </div>
          </div>
          <div className='RenderView'>
            <div className='Scene' >
              <div className='Canvas' ref={(mount) => { this.mount = mount }}>
                <DropFileBox
                  functionForButton={this.loadDXFFile}
                  functionForDrop={this.loadFilebyFile}
                  loadFile={this.state.loadFile}
                  type={ConverterType.myType}
                  showSample={this.state.showSample}
                />
                <div className={`toolBar ${!this.state.loadFile && 'hidden'}`}>
                  <div className='switchTabs'>
                    <Button className={`switchButton ${this.state.is2D && 'enable'}`} onClick={() => this.Switch2D3D(false)}>2D</Button>
                    <Button className={`switchButton ${!this.state.is2D && 'enable'}`} onClick={() => this.Switch2D3D(true)}>3D</Button>
                  </div>
                </div>
                <div className={`autosetDiv ${!this.state.showAutoSetting && 'hidden'}`}>
                  <Button className='autosetButton' onClick={this.autoSetHouse} >자동 추가</Button>
                  <div className='autosetMessage'>정해진 규칙에 따라 생성된 레이어는 변환 설정값을 자동으로 처리합니다 (상세한 규칙은 메뉴얼을 참고해주세요) </div>
                </div>
              </div>
              {/* <Help className='helpIcon' onClick={() => console.log('help')} /> */}
              <ErrorLog
                show={this.state.errorLog}
                closeLog={() => this.setState({ errorLog: !this.state.errorLog })}
                logs={this.errorLogs}
                warningSize={this.state.warningSize}
                errorSize={this.state.errorSize}
                brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                darkenAllLayer={() => darkenAllLayer(this.state.layers)}
              />
            </div>
            <div className={`mainLayout ${!this.state.loadFile && 'hidden'}`}>
              <LayerPanel houses={this.houses} layers={this.state.layers}
                showlayerPanle={this.state.showLayer} closePanel={() => this.setState({ showLayer: true })}
                setLayer={this.setSelectLayer} setHouse={this.setSelectHouse}
                listType={this.state.listType}
                partOfSelect={this.state.partOfHouse}
                settingName={this.getSettingName()}
                currentSelected={this.getCurrentSelected()}
              />
              <div className='functionalLayout' >
                <div className='fileNameLayout lbrBorder'>
                  <div className='titleText'>나의 동평면 제목</div>
                  <input type='text' value={this.state.saveName} onChange={e => this.setState({ saveName: e.target.value })} placeholder='나의 동평면 제목 입력'></input>
                  <div className='switchTabs'>
                    <div className={`switchButton ${this.state.houseCoreTabs === houseCoreTabs.house && 'enable'}`} onClick={() => this.setState({ houseCoreTabs: houseCoreTabs.house })}><span className='text'>세대</span></div>
                    <div className={`switchButton ${this.state.houseCoreTabs === houseCoreTabs.core && 'enable'}`} onClick={() => this.setState({ houseCoreTabs: houseCoreTabs.core })}><span className='text'>코어</span></div>
                  </div>
                </div>
                <div className='bodyLayout lbrBorder'>
                  {(this.state.houseCoreTabs === houseCoreTabs.house &&
                    <div>
                      <div className='list'>{this.houses.map(h =>
                        <HouseUI
                          key={h.id}
                          showLayerList={this.showLayerList}
                          house={h}
                          selectLayer={this.state.selectLayer}
                          settingID={this.state.settingID}
                          removeHouse={this.removeHouseFromHouseList}
                          valueChanged={this.recalculateResult}
                          editable={this.state.is2D}
                          showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                          brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                          darkenAllLayer={() => darkenAllLayer(this.state.layers)}
                        />)}
                      </div>
                      <div className='addButton' onClick={() => this.addHouse(`세대 ${this.houseCount + 1}`)}><span className='text'>+ 세대 추가</span></div>
                    </div>) ||
                    <div>
                      <div className='list'>{this.cores.map(c =>
                        <CoreUI
                          key={c.id}
                          Core={c}
                          showLayerList={this.showLayerList}
                          selectLayer={this.state.selectLayer}
                          removeCore={this.removeCoreFromCoreList}
                          valueChanged={this.recalculateResult}
                          settingID={this.state.settingID}
                          showHouseList={this.showHouseList}
                          selectHouse={this.state.selectHouse}
                          editable={this.state.is2D}
                          showModel={(information: string) => this.showModal('알림', [information], buttonNum.oneButton, () => { })}
                          brightenAllLayer={() => brightenAllLayer(this.state.layers)}
                          darkenAllLayer={() => darkenAllLayer(this.state.layers)}
                        />)}
                      </div>
                      <div className='addButton' onClick={() => this.addCore(`코어 ${this.coreCount + 1}`)}><span className='text'>+ 코어 추가</span></div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
