import React, { Component, Fragment } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "./Tooltip";
import HelpIcon from "@material-ui/icons/HelpOutline";

import "./css/Modal.scss";
export type ModalType = "SIMPLE" | "NORMAL" | "BIG" | "MOBILE";
export type ModalColorType = "WHITE" | "DARK";
export interface ModalOptions {
  type?: ModalType;
  title?: string;
  positive?: () => void;
  negative?: Function | "hidden";
  otherBtns?: string;
  positiveTitle?: string;
  negativeTitle?: string;
  secondSelect?: () => void;
  secondSelectTitle?: string;
  titleHover?: JSX.Element | string;
  additionalInfo?: JSX.Element | string;
  content?: JSX.Element;
  color?: ModalColorType; // deprecated;
}

export interface ModalProps extends ModalOptions {
  open: boolean;
}

export interface ModalState {
  open: boolean;
}

export default class Modal extends Component<ModalProps, ModalState> {
  state: ModalState = {
    open: this.props.open,
  };

  componentWillMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
  };
  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  handleKeyDown = (e: any) => {
    if (e.keyCode === 27) {
      // esc
      this.handleClose("NEGATIVE");
    } else if (e.keyCode === 13) {
      // enter
      this.handleClose("POSITIVE");
    }
    e.preventDefault();
  };

  render() {
    switch (this.props.type) {
      case "SIMPLE":
        return (
          <Dialog className={`Modal simple`} open={this.state.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <div className="modal-title" id="alert-dialog-title">
              {(this.props.title && this.props.title) || "안내"}
              <div className="modal-title-hover">{(this.props.titleHover && this.props.titleHover) || ""}</div>
              <Button
                className="close-btn"
                onClick={() => {
                  console.log();

                  if (this.props.positiveTitle === "선택 취소") {
                    this.handleClose("POSITIVE");
                  } else {
                    this.handleClose("NEGATIVE");
                  }
                }}
              >
                <CloseIcon className="icon" />
              </Button>
            </div>
            <div className="modal-content">{(this.props.content && this.props.content) || ""}</div>
            <div className="modal-action">
              <Button className="btn positive-btn" disableRipple={true} onClick={(e) => this.handleClose("POSITIVE")}>
                {(this.props.positiveTitle && this.props.positiveTitle) || "확인"}
              </Button>
              {this.props.secondSelect !== undefined && this.props.secondSelectTitle !== undefined && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("SECOND_SELECT")}>
                  {this.props.secondSelectTitle}
                </Button>
              )}
              {this.props.negative !== "hidden" && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("NEGATIVE")}>
                  {(this.props.negativeTitle && this.props.negativeTitle) || "취소"}
                </Button>
              )}
            </div>
          </Dialog>
        );
      case "NORMAL":
        return (
          <Dialog className={`Modal normal`} open={this.state.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <div className="modal-title" id="alert-dialog-title">
              {(this.props.title && this.props.title) || "안내"}
              <div className="modal-title-hover">{(this.props.titleHover && this.props.titleHover) || ""}</div>
              <Button className="close-btn" onClick={() => this.handleClose("NEGATIVE")}>
                <CloseIcon className="icon" />
              </Button>
            </div>
            <div className="modal-content">{(this.props.content && this.props.content) || ""}</div>
            <div className="modal-action">
              <Button className="btn positive-btn" disableRipple={true} onClick={(e) => this.handleClose("POSITIVE")}>
                {(this.props.positiveTitle && this.props.positiveTitle) || "확인"}
              </Button>
              {this.props.secondSelect !== undefined && this.props.secondSelectTitle !== undefined && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("SECOND_SELECT")}>
                  {this.props.secondSelectTitle}
                </Button>
              )}
              {this.props.negative !== "hidden" && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("NEGATIVE")}>
                  {(this.props.negativeTitle && this.props.negativeTitle) || "취소"}
                </Button>
              )}
            </div>
          </Dialog>
        );
      case "BIG":
        return (
          <Dialog className={`Modal big`} open={this.state.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <div className="modal-title" id="alert-dialog-title">
              {(this.props.title && this.props.title) || "안내"}
              <div className="modal-title-hover">{(this.props.titleHover && this.props.titleHover) || ""}</div>
              <Button className="close-btn" onClick={() => this.handleClose("NEGATIVE")}>
                <CloseIcon className="icon" />
              </Button>
            </div>
            <div className="modal-content">{(this.props.content && this.props.content) || ""}</div>
            <div className="modal-action">
              <Button className="btn positive-btn" disableRipple={true} onClick={(e) => this.handleClose("POSITIVE")}>
                {(this.props.positiveTitle && this.props.positiveTitle) || "확인"}
              </Button>
              {this.props.secondSelect !== undefined && this.props.secondSelectTitle !== undefined && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("SECOND_SELECT")}>
                  {this.props.secondSelectTitle}
                </Button>
              )}
              {this.props.negative !== "hidden" && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("NEGATIVE")}>
                  {(this.props.negativeTitle && this.props.negativeTitle) || "취소"}
                </Button>
              )}
              <div className="add-info">{this.props.additionalInfo && this.props.additionalInfo}</div>
            </div>
          </Dialog>
        );
      case "MOBILE":
        return (
          <Dialog className={`Modal mobile`} open={this.state.open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <div className="modal-title" id="alert-dialog-title">
              {(this.props.title && this.props.title) || "안내"}
              <div className="modal-title-hover">{(this.props.titleHover && this.props.titleHover) || ""}</div>
              <Button className="close-btn" onClick={() => this.handleClose("NEGATIVE")}>
                <CloseIcon className="icon" />
              </Button>
            </div>
            <div className="modal-content">{(this.props.content && this.props.content) || ""}</div>
            <div className="modal-action">
              <Button className="btn positive-btn" disableRipple={true} onClick={(e) => this.handleClose("POSITIVE")}>
                {(this.props.positiveTitle && this.props.positiveTitle) || "확인"}
              </Button>
              {this.props.secondSelect !== undefined && this.props.secondSelectTitle !== undefined && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("SECOND_SELECT")}>
                  {this.props.secondSelectTitle}
                </Button>
              )}
              {this.props.negative !== "hidden" && (
                <Button className="btn negative-btn" disableRipple={true} onClick={(e) => this.handleClose("NEGATIVE")}>
                  {(this.props.negativeTitle && this.props.negativeTitle) || "취소"}
                </Button>
              )}
            </div>
          </Dialog>
        );
    }
  }

  handleClose = (type: "POSITIVE" | "NEGATIVE" | "SECOND_SELECT") => {
    this.setState({ open: false }, () => {
      if (type === "POSITIVE") {
        this.props.positive && this.props.positive();
      } else if (type === "SECOND_SELECT") {
        this.props.secondSelect && this.props.secondSelect();
      } else {
        if (this.props.negative && typeof this.props.negative === "function") {
          this.props.negative();
        } else {
          this.props.positive && this.props.positive();
        }
      }
    });
  };
}
