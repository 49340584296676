import * as THREE from '@teneleven/three';
import { ConverterLayer, mapProjectionData, screenInfo } from './DataTypes';
import { CheckColsedPolygon, getFieldArea, GetJSTSPolygonFormLayer, getLatLonPosition, GetUnionJSTSPolygonFormLayer, switchLayerState } from './CoreAndHouseController';
import { NaverPoint } from './NaverMapManager';
import { GeotoWKT, tm2latlng } from './SceneManager';
import { ErrorLogCell, ErrorLogCell2, makeErrorInformation } from './ErrorLog';
import * as jsts from 'jsts';

const uuid4 = require('uuid/v4');

export enum FieldType {
  site = '대지영역',
  road = '도로영역',
  vacancyOutside = '공지영역',
  vacancyInside = '특수영역',
  centerLineOfRoad = '인접대지경계선',
  topography = '성절토라인',
}

//data controller
export class Field {
  name: string;
  typeName: FieldType;
  private layer: ConverterLayer | null;
  singlePolygon: boolean;
  private area: number;
  wktSize: number;
  private height: number;
  id: string;
  ErrorLog:  ErrorLogCell[];
  ErrorLogBlock: ErrorLogCell2[];
  ErrorPolygonGroup: THREE.Group;

  constructor(name: string, fieldType: FieldType) {
    this.name = fieldType + ' ' + name;
    this.typeName = fieldType;
    this.layer = null;
    this.singlePolygon = fieldType === FieldType.site ? true : false;
    this.area = 0;
    this.height = 0;
    this.wktSize = 0;
    this.ErrorLog = [];
    this.ErrorLogBlock = [];
    this.id = uuid4();
    this.ErrorPolygonGroup = new THREE.Group();
  }

  setLayer = (layer: ConverterLayer | null) => {
    if (!layer) return;
    if (!CheckColsedPolygon(layer)) return;

    let exLayer = this.layer;
    this.layer = layer;

    if (exLayer)
      switchLayerState(exLayer);

    switchLayerState(layer);

    this.area = getFieldArea(this);
    this.wktSize = new Blob(this.getWKTArrayByBenchmark(new THREE.Vector3(0), new THREE.Vector2(0))).size;
  }

  checkCompleteness = () => {
    while (this.ErrorLog.length > 0) {
      this.ErrorLog.splice(0, 1);
    }
    while (this.ErrorPolygonGroup.children.length > 0) {
      this.ErrorPolygonGroup.children.splice(0, 1);
    }

    if (!this.layer) {
      this.ErrorLog.push(makeErrorInformation(`${this.name}세대의 레이어를 추가해 주세요.`, '', new THREE.Group(), []));
    }
    else {
      let polygonArea = getFieldArea(this);
      let offset = Math.abs(polygonArea - this.area) / polygonArea;
      if (offset >= 0.5) {
        this.ErrorLog.push(makeErrorInformation(`${this.name}의 입력면적과 실제 폴리곤 면적이 서로 상이합니다. 단위를 확인 하신 후 다시 진행해 주세요.`,
          `입력 면적: ${this.area.toFixed(2)}㎡, 계산 면젹: ${polygonArea.toFixed(2)}㎡, 차이: ${Math.abs(polygonArea - this.area).toFixed(2)}㎡`,
          new THREE.Group(),
          [this.layer]
        ))
      }

      if (this.layer.polygons.length > 1) {
        this.ErrorLog.push(makeErrorInformation(`${this.name}에 선택된 레이어에 하나 이상의 폴리곤이 있습니다.`, '',
          new THREE.Group(),
          [this.layer]
        ))
      }
    }
  }

  setNullLayer = () => {
    this.layer = null;
  }

  getJstsPolygons = () => {
    if (this.layer)
      return GetJSTSPolygonFormLayer(this.layer);
  }

  getUnionJstsPolygon = () => {
    if (this.layer)
      return GetUnionJSTSPolygonFormLayer(this.layer);
    else
      return new jsts.geom.Geometry();
  }

  getFieldLayerName = () => {
    if (this.layer)
      return this.layer.name;
    else
      return null;
  }

  getLayer = () => { return this.layer }

  setArea = (area: number) => {
    this.area = area;
    this.checkCompleteness();
  }

  getArea = () => { return this.area; }

  setHeight = (height: number) => {
    this.height = height;
  }

  getHeight = () => { return this.height; }

  getFieldLatLngsByBenchmark = (benchmark: THREE.Vector3, benchmarkInMap: THREE.Vector2) => {
    let latlngs: any[] = [];
    if (this.layer) {
      this.layer.polygons.forEach(basePolygon => {
        if (basePolygon.selected && !basePolygon.motherPolygon) {
          let bodyPolygon: any[] = [];
          let polygon: any[] = [];
          basePolygon.vertices.forEach(v => {
            let dir = v.clone().sub(benchmark);
            let latlng = tm2latlng(benchmarkInMap.clone().add(new THREE.Vector2(dir.x, dir.y)));
            bodyPolygon.push([latlng.x, latlng.y]);
          })

          polygon.push(bodyPolygon);
          this.layer!.polygons.forEach(holePolygon => {
            if (holePolygon.motherPolygon === basePolygon) {
              let hole: any[] = [];
              holePolygon.vertices.forEach(v => {
                let dir = v.clone().sub(benchmark);
                let latlng = tm2latlng(benchmarkInMap.clone().add(new THREE.Vector2(dir.x, dir.y)));
                hole.push([latlng.x, latlng.y]);
              })
              polygon.push(hole);
            }
          })
          latlngs = latlngs.concat(polygon);
        }
      })
    }
    return latlngs;
  }

  getWKTArrayByBenchmark(benchmark: THREE.Vector3, benchmarkInMap: THREE.Vector2) {
    let polygonWKTArray: string[] = [];
    let latlngs = this.getFieldLatLngsByBenchmark(benchmark, benchmarkInMap);

    let geoJSON = {
      type: 'Polygon',
      coordinates: latlngs,
    }

    polygonWKTArray.push(GeotoWKT(geoJSON));

    return polygonWKTArray;
  }

  getFirstPoint = () => {
    if (this.layer) {
      return this.layer.polygons[0].vertices[0];
    }
  }
}
